var _ = require('lodash-core');
var promises = require('../../async/promises');
var camelCaseKeysRecursive = require('camelcase-keys-recursive');
var EventEmitter = require('events').EventEmitter;
var MarkdownIt;
var frontMatter;

// @ngInject
function ContentPageService($http, githubService, localStorageService, preloadService,
  userService, staticAssetService, apiService) {

  var events = this.events = new EventEmitter();

  this.getPage = promises.memoize(function(name) {
    var self = this;
    var url = '/content/pages/' + name + '.html';
    return promises.extend($http.get(url).then(function(response) {
      var html = response.data;
      var page = self.extractMetadata(html);

      page.name = name.replace('/', '-');
      page.html = html;

      var user = userService.getUser();
      if (user && user.roles && (user.roles.indexOf('admin') >= 0 ||
        user.roles.indexOf('editor') >= 0)) {
        // find images for the asset list
        var imgSrcRegex = /<img[^>]+src=['"]?([^'"\s>]+)/ig;
        var match = imgSrcRegex.exec(html);
        while (match) {
          self.addToContentPageStaticAssets(match[1]);
          match = imgSrcRegex.exec(html);
        }
      }

      self.preparePageMetadata(page);

      return page;
    }));
  });

  this.extractMetadata = function(html) {
    var metaData = {};

    // extract metadata from <script class=metadata> tag
    var match = /<script[^>]*class="metadata"[^>]*>([\s\S]*?)<\/script>/gim.exec(html);
    if (match) {
      try {
        metaData = JSON.parse(match[1]);
      } catch (err) {
        metaData = {};
        console.error('error parsing page metadata:', err);
      }
    }
    return metaData;
  };

  this.getTemplate = promises.memoize(function(name) {
    var url = '/content/pages/templates/' + name + '.html';
    var self = this;
    return promises.extend($http.get(url).then(function(response) {
      return Object.assign(self.extractMetadata(response.data), { html: response.data });
    }));
  });

  this.getSource = function(name) {
    return githubService.read('orchard-mile-content',
      'public/pages/' + name + '.md');
  };

  this.saveSource = function(name, data) {
    return githubService.write('orchard-mile-content',
      'public/pages/' + name + '.md', unicodeToHtmlEntities(data));
  };

  this.liveEditChange = function(name, compiledPage) {
    if (!compiledPage || !compiledPage.html) {
      localStorageService.removeItem('live-edit/pages/' + name);
    }
    localStorageService.setItem('live-edit/pages/' + name, JSON.stringify(compiledPage));
  };

  this.listeningForLiveEditChanges = false;

  this.compile = function(md) {
    var self = this;
    return preloadService.bundle('editor').then(function() {

      // remove "front matter" block
      if (!frontMatter) {
        if (!(window.orchardMileWebClient.editor &&
          window.orchardMileWebClient.editor.frontMatter)) {
          throw new Error('yaml-front-matter is not loaded');
        }
        frontMatter = window.orchardMileWebClient.editor.frontMatter;
      }

      var data;
      try {
        data = frontMatter(md);
      } catch (err) {
        data = null;
      }
      var page = {};
      if (data && data.attributes) {
        page = camelCaseKeysRecursive(data.attributes);
        md = data.body;
      }

      if (!MarkdownIt) {
        if (!(window.orchardMileWebClient.editor &&
          window.orchardMileWebClient.editor.MarkdownIt)) {
          throw new Error('markdown compiler is not loaded');
        }
        MarkdownIt = window.orchardMileWebClient.editor.MarkdownIt;
      }
      var markdownIt = new MarkdownIt({
        html: true
      });
      page.html = markdownIt.render(md);

      self.preparePageMetadata(page);

      return page;
    });
  };

  this.listenForLiveEditChanges = function() {
    if (this.listeningForLiveEditChanges) {
      return;
    }
    var self = this;
    var keyPrefix = /^live-edit\/pages\//;
    localStorageService.onKeyChange(keyPrefix, function(e) {
      var page = JSON.parse(e.newValue);
      page.name = e.key.replace(keyPrefix, '');
      self.preparePageMetadata(page);
      events.emit('change', {
        liveEdit: true,
        name: page.name,
        value: page
      });
    });
    this.listeningForLiveEditChanges = true;
  };

  this.listAll = function() {
    return githubService.getTree('orchard-mile-content').then(function(tree) {
      return tree.map(function(file) {
        return file.path;
      }).filter(function(filename) {
        return /^public\/pages\/.+\.md$/.test(filename);
      }).map(function(filename) {
        return filename.replace(/^public\/pages\//, '')
          .replace(/\.md$/, '')
          .replace(/^README$/, 'static-pages-readme');
      });
    });
  };

  this.fileUploaded = function(url) {
    return this.addToContentPageStaticAssets(url);
  };

  this.addToContentPageStaticAssets = function(url) {
    var contentPageStaticAssets = this.getContentPageStaticAssets();
    for (var i = 0; i < contentPageStaticAssets.length; i++) {
      if (contentPageStaticAssets[i] === url) {
        contentPageStaticAssets.splice(i, 1);
        i--;
      }
    }
    contentPageStaticAssets.push(url);
    while (contentPageStaticAssets.length > 20) {
      contentPageStaticAssets.splice(0, 1);
    }
    if (window.localStorage) {
      window.localStorage.setItem('content-page-static-assets', JSON.stringify(contentPageStaticAssets));
    }
  };

  this.getContentPageStaticAssets = function() {
    var contentPageStaticAssets;
    try {
      contentPageStaticAssets = JSON.parse(window.localStorage.getItem('content-page-static-assets') || '[]');
    } catch (err) {
      contentPageStaticAssets = [];
    }
    return contentPageStaticAssets;
  };

  this.preparePageMetadata = function(page) {
    if (!page.category) {
      if ((page.tags && page.tags.indexOf('walk-the-mile') >= 0) ||
        (page.name && page.name.indexOf('walk-the-mile') >= 0)) {
        page.category = 'walk-the-mile';
      } else if (/inspiration/i.test(page.name)) {
        page.category = 'trend';
      }
    }
  };

  this.getActivePopUps = function() {
    var popups = window.orchardMileContent.pages;
    return popups.filter(function(popup) {
      return popup.name.indexOf('popups/') > -1 && popup.enabled;
    });
  };

  this.getLatestStories = function(includeFuture) {
    var self = this;
    var stories = window.orchardMileContent.pages;
    stories.forEach(function(story) {
      self.preparePageMetadata(story);
    });
    stories = stories.filter(function(story) {
      if (story.storiesList === false) {
        return false;
      }
      if (!story.published) {
        return false;
      }
      try {
        story.publishDate = new Date(story.published);
        return includeFuture || story.publishDate.getDate() <= new Date();
      } catch (err) {
        return false;
      }
    });
    stories.reverse();
    return stories;
  };

  this.search = function(params) {
    var pages = this.getLatestStories().filter(function(story) {
      if (params.filters && params.filters.length > 0) {
        return _.some(params.filters, function(filter) {
          if (typeof filter === 'string') {
            if (filter.slice(0, 1) === '#') {
              filter = filter.slice(1);
              // by tags or category
              return story.category === filter ||
                story.tags && story.tags.indexOf(filter) >= 0;
            }
            if (filter.indexOf('*') >= 0) {
              // by path with wildcards
              filter = new RegExp(filter.replace(/\*/g, '.*'));
            }
          }
          if (filter.test) {
            // by path regex
            return filter.test(story.name);
          }
          // by exact path
          return filter === story.name;
        });
      }
      return true;
    });
    return params.limit ? pages.slice(0, params.limit) : pages;
  };

  this.getEmailFromPage = function(page) {
    var pageData = page;
    if (page.emailOverrides) {
      pageData = _.merge(_.cloneDeep(page), page.emailOverrides);
    }
    var data = {
      title: pageData.title || '',
      subtitle: pageData.dek || pageData.subtitle || '',
      heroImageUrl: getAssetUrl(pageData.heroImageUrl) || '',
      heroLink: pageData.heroLink,
      sections: getProductListSections(page.contentNode),
      template: 'editorial'
    };
    return apiService.put('/editorials/{name}/email',{
      pathParams: {
        name: encodeURIComponent(pageData.name)
      },
      body: data
    });
  };

  // private

  function unicodeToHtmlEntities(str) {
    var out = '';
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) < 0x80) {
        out += str.charAt(i);
      } else {
        var u = '' + str.charCodeAt(i).toString(16);
        out += '&#x' + (u.length === 2 ? '00' + u : u.length === 3 ? '0' + u : u) + ';';
      }
    }
    return out;
  }

  function getAssetUrl(url) {
    if (!url) {
      return url;
    }
    url = staticAssetService.url(url);
    if (!/^\/\//.test(url)) {
      // it has protocol already or its relative
      return url;
    }
    return 'https:' + url;
  }

  function getProductListSections(content) {
    var sections = [];
    content.find('.product-list,div>a[href]>img[width][src]').each(function(i, section) {
      section = $(section);
      var sectionInfo = {
        title: section.parent().prev().find('h2').text(),
        subtitle: section.parent().prev().find('p').text(),
      };
      if (section.is('.product-list')) {
        var styleIds = [];
        section.find('a.product-link').each(function(i, link) {
          styleIds.push($(link).attr('href').split('-').pop());
        });
        sectionInfo.styleIds = styleIds;
        sections.push(sectionInfo);
      } else if (section.is('img') && !section.closest('.product-list').length) {
        sectionInfo.href = section.closest('a[href]').attr('href');
        sectionInfo.image = section.attr('src');
        sectionInfo.type = 'image';
        sections.push(sectionInfo);
      }
    });
    return sections;
  }
}


module.exports = ContentPageService;
