
// @ngInject
function PasswordResetController($scope, $state, $stateParams, formsService, userService) {

  $scope.setPageMetadata({ title: 'Reset your password | THE MILE' });

  $scope.$onEmitter(userService.events, 'loggedIn', function() {
    $state.go('root.home');
  });

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.resetPassword = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var params = {
      email: $stateParams.email,
      code: $stateParams.code,
      password: $scope.password,
      trigger: 'password-reset'
    };

    formPromise.handle(
      userService.resetPassword(params.email, params.code, params.password)
    ).then(function() {
      // login using new credentials
      userService.login(params)
        .then(function() {
          $state.go('root.home');
        }, function() {
          $scope.loggedInFailed = true;
          $state.go('root.home');
        });
    });
  };

  $scope.resendResetLink = function() {
    $scope.modal.show('forgot-password', {
      email: $stateParams.email,
      trigger: 'password-reset'
    });
  };
}

module.exports = PasswordResetController;
