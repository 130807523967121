// @ngInject
function LoadingOverlayDirective() {

  return {
    templateUrl: '/views/partials/loading-overlay.html',
    restrict: 'E'
  };
}

module.exports = LoadingOverlayDirective;
