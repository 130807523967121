var shippingMethods = require('shared-business-rules').shippingMethods.shippingMethods.shippingMethods;
var _ = require('lodash-core');

// jshint maxparams:10
// @ngInject
function AdminPromoController($scope, $state, $stateParams, $uibModal, $rootScope, promoService, brandService,
                              fulfillerService, formsService, config) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();
  $scope.shippingMethods = shippingMethods;
  $scope.promos = null;
  $scope.featureFlags = config.featureFlags;

  $scope.reload = function() {
    if ($stateParams.id === 'create') {
      $scope.promoData = {
        isGoogle: true,
        overItems: {},
        onOrders: {},
        overPromos: {},
        discountType: 'percentage'
      };
      $scope.promoFlags = {};
    } else {
      var promise = promoService.getById($stateParams.id);
      $scope.promoData = promise.lazyObject();
      $scope.promoFlags = {};
      promise.then(function() {
        var promoData = $scope.promoData;
        if (typeof promoData.beginsAt === 'string') {
          promoData.beginsAt = new Date(promoData.beginsAt);
        }
        if (typeof promoData.expiresAt === 'string') {
          promoData.expiresAt = new Date(promoData.expiresAt);
        }
        if (promoData.percentage) {
          promoData.discountType = 'percentage';
        } else if (promoData.credit) {
          promoData.credit = promoData.credit / 100;
          promoData.discountType = 'credit';
        } else {
          promoData.discountType = 'freeGifts';
        }

        promoData.overItems = promoData.overItems || {};
        promoData.userInList = promoData.userInList || {};
        promoData.onOrders = promoData.onOrders || {};
        promoData.overPromos = promoData.overPromos || {};
        if (promoData.overItems.fromBrands) {
          $scope.promoFlags.fromBrands = true;
          promoData.overItems.fromBrands = promoData.overItems.fromBrands.join(',');
        }
        if (promoData.overItems.fromInfluencers) {
          $scope.promoFlags.fromInfluencers = true;
          promoData.overItems.fromInfluencers = promoData.overItems.fromInfluencers.join(',');
        }
        if (promoData.overItems.fromShows) {
          $scope.promoFlags.fromShows = true;
          promoData.overItems.fromShows = promoData.overItems.fromShows.join(',');
        }
        if (promoData.overItems.notFromBrands) {
          $scope.promoFlags.notFromBrands = true;
          promoData.overItems.notFromBrands = promoData.overItems.notFromBrands.join(',');
        }
        if (promoData.overItems.fromFulfillers) {
          $scope.promoFlags.fromFulfillers = true;
          promoData.overItems.fromFulfillers = promoData.overItems.fromFulfillers.join(',');
        }
        if (promoData.overItems.notFromFulfillers) {
          $scope.promoFlags.notFromFulfillers = true;
          promoData.overItems.notFromFulfillers = promoData.overItems.notFromFulfillers.join(',');
        }
        if (promoData.overItems.styleIds) {
          $scope.promoFlags.styleIds = true;
          promoData.overItems.styleIds = promoData.overItems.styleIds.join(',');
        }
        if (promoData.overItems.withTags) {
          $scope.promoFlags.withTags = true;
          promoData.overItems.withTags = promoData.overItems.withTags.join(',');
        }
        if (promoData.overItems.withoutTags) {
          $scope.promoFlags.withoutTags = true;
          promoData.overItems.withoutTags = promoData.overItems.withoutTags.join(',');
        }
        if (promoData.overItems.withPriceOver) {
          $scope.promoFlags.withPriceOver = true;
          promoData.overItems.withPriceOver = promoData.overItems.withPriceOver / 100;
        }
        if (promoData.onOrders.greaterThanOrEqual) {
          $scope.promoFlags.onOrdersGreaterThanOrEqual = true;
          promoData.onOrders.greaterThanOrEqual = promoData.onOrders.greaterThanOrEqual / 100;
        }
        if (promoData.onOrders.fromApp) {
          $scope.promoFlags.onOrdersFromApp = true;
        }
        if (promoData.onOrders.lesserThan) {
          $scope.promoFlags.onOrdersLesserThan = true;
          promoData.onOrders.lesserThan = promoData.onOrders.lesserThan / 100;
        }
        if (promoData.onOrders.greaterThanOrEqualOnSale) {
          $scope.promoFlags.onOrdersGreaterThanOrEqualOnSale = true;
          promoData.onOrders.greaterThanOrEqualOnSale = promoData.onOrders.greaterThanOrEqualOnSale / 100;
        }
        if (promoData.onOrders.lesserThanOnSale) {
          $scope.promoFlags.onOrdersLesserThanOnSale = true;
          promoData.onOrders.lesserThanOnSale = promoData.onOrders.lesserThanOnSale / 100;
        }
        if (promoData.freeShippingMethods) {
          promoData.freeShippingMethods = promoData.freeShippingMethods.split(',');
        }
        if (promoData.signedUpUser === true) {
          $scope.promoFlags.signedUpUser = true;
        }
        if (promoData.subscribedUser === true) {
          $scope.promoFlags.subscribedUser = true;
        }
        if (promoData.activeMile === true) {
          $scope.promoFlags.activeMile = true;
        }
        if (promoData.activeMileTimestamp === true) {
          $scope.promoFlags.activeMileTimestamp = true;
        }
        if (promoData.newUser === true) {
          $scope.promoFlags.newUser = true;
        }
        if (promoData.overPromos.notCombinable === true) {
          $scope.promoFlags.overPromosNotCombinable = true;
        }
        if (promoData.userInList) {
          $scope.promoFlags.userInList = true;
        }
        $scope.isCodeEmpty = !!(promoData.code);
      });
    }
  };

  $scope.reload();
  $scope.fulfillers = fulfillerService.getAll().lazyArray();
  $scope.brands = brandService.getAll().lazyArray();

  $scope.isExpired = function() {
    return $scope.promoData.expiresAt && $scope.promoData.expiresAt < new Date();
  };

  $scope.hasntStarted = function() {
    return $scope.promoData.beginsAt && $scope.promoData.beginsAt > new Date();
  };

  $scope.isActive = function() {
    return !($scope.isExpired() || $scope.hasntStarted());
  };

  $scope.save = function() {
    $scope.form.fromBrands.$setValidity('sameBrands', true);
    $scope.form.fromFulfillers.$setValidity('sameFulfillers', true);
    $scope.form.withTags.$setValidity('sameTags', true);

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var fromBrands = ($scope.promoFlags.fromBrands) ?
      $scope.promoData.overItems.fromBrands.split(',') : [];
    var notFromBrands = ($scope.promoFlags.notFromBrands) ?
      $scope.promoData.overItems.notFromBrands.split(',') : [];
    if (_.intersection(fromBrands, notFromBrands).length) {
      $scope.form.fromBrands.$setValidity('sameBrands', false);
      return;
    }

    var fromFulfillers = ($scope.promoFlags.fromFulfillers) ?
      $scope.promoData.overItems.fromFulfillers.split(',') : [];
    var notFromFulfillers = ($scope.promoFlags.notFromFulfillers) ?
      $scope.promoData.overItems.notFromFulfillers.split(',') : [];
    if (_.intersection(fromFulfillers, notFromFulfillers).length) {
      $scope.form.fromFulfillers.$setValidity('sameFulfillers', false);
      return;
    }

    var withTags = ($scope.promoFlags.withTags) ?
      $scope.promoData.overItems.withTags.split(',') : [];
    var withoutTags = ($scope.promoFlags.withoutTags) ?
      $scope.promoData.overItems.withoutTags.split(',') : [];
    if (_.intersection(withTags, withoutTags).length) {
      $scope.form.withTags.$setValidity('sameTags', false);
      return;
    }

    var promoData = _.cloneDeep($scope.promoData);
    var promoFlags = $scope.promoFlags;
    if (promoData.discountType === 'credit') {
      promoData.credit = Math.round(promoData.credit * 100);
      delete promoData.percentage;
      delete promoData.giftsDescription;
    } else if (promoData.discountType === 'percentage') {
      delete promoData.credit;
      delete promoData.giftsDescription;
    } else {
      delete promoData.percentage;
      promoData.credit = 0;
    }
    delete promoData.discountType;

    if (promoData.freeShippingMethods) {
      promoData.freeShippingMethods = promoData.freeShippingMethods.join(',');
    }
    if (promoFlags.signedUpUser === true) {
      promoData.signedUpUser = true;
    }
    if (promoFlags.subscribedUser === true) {
      promoData.subscribedUser = true;
    }
    if (promoFlags.activeMile === true) {
      promoData.activeMile = true;
    }
    if (promoFlags.activeMileTimestamp === true) {
      promoData.activeMileTimestamp = true;
    }
    if (promoFlags.newUser === true) {
      promoData.newUser = true;
    }

    promoData.overItems = {};
    promoData.onOrders = {};
    promoData.overPromos = {};

    if (promoFlags.fromBrands === true) {
      promoData.overItems.fromBrands = $scope.promoData.overItems.fromBrands.toLowerCase().split(',');
    }
    if (promoFlags.notFromBrands === true) {
      promoData.overItems.notFromBrands = $scope.promoData.overItems.notFromBrands.toLowerCase().split(',');
    }
    if (promoFlags.fromFulfillers === true) {
      promoData.overItems.fromFulfillers = $scope.promoData.overItems.fromFulfillers.toLowerCase().split(',');
    }
    if (promoFlags.notFromFulfillers === true) {
      promoData.overItems.notFromFulfillers = $scope.promoData.overItems.notFromFulfillers.toLowerCase().split(',');
    }
    if (promoFlags.fromInfluencers === true) {
      promoData.overItems.fromInfluencers = $scope.promoData.overItems.fromInfluencers.toLowerCase().split(',');
    }
    if (promoFlags.fromShows === true) {
      promoData.overItems.fromShows = $scope.promoData.overItems.fromShows.toLowerCase().split(',');
    }
    if (promoFlags.styleIds === true) {
      promoData.overItems.styleIds = $scope.promoData.overItems.styleIds.toLowerCase().split(',');
    }
    if (promoFlags.withTags === true) {
      promoData.overItems.withTags = $scope.promoData.overItems.withTags.toLowerCase().split(',');
    }
    if (promoFlags.withoutTags === true) {
      promoData.overItems.withoutTags = $scope.promoData.overItems.withoutTags.toLowerCase().split(',');
    }
    if (promoFlags.withPriceOver === true) {
      promoData.overItems.withPriceOver = Math.round($scope.promoData.overItems.withPriceOver * 100);
    }
    if ($scope.promoData.overItems.inSale === true) {
      promoData.overItems.inSale = true;
    }
    if ($scope.promoData.overItems.fullPriced === true) {
      promoData.overItems.fullPriced = true;
    }
    if ($scope.promoData.overItems.inFinalSale === true) {
      promoData.overItems.inFinalSale = true;
    }
    if ($scope.promoData.overItems.notInFinalSale === true) {
      promoData.overItems.notInFinalSale = true;
    }
    if (promoFlags.onOrdersGreaterThanOrEqual === true) {
      promoData.onOrders.greaterThanOrEqual = Math.round($scope.promoData.onOrders.greaterThanOrEqual * 100);
    }
    if (promoFlags.onOrdersFromApp === true) {
      promoData.onOrders.fromApp = true;
    }
    if (promoFlags.onOrdersLesserThan === true) {
      promoData.onOrders.lesserThan = Math.round($scope.promoData.onOrders.lesserThan * 100);
    }
    if (promoFlags.onOrdersLesserThanOnSale === true) {
      promoData.onOrders.lesserThanOnSale = Math.round($scope.promoData.onOrders.lesserThanOnSale * 100);
    }
    if (promoFlags.onOrdersGreaterThanOrEqualOnSale === true) {
      promoData.onOrders.greaterThanOrEqualOnSale =
        Math.round($scope.promoData.onOrders.greaterThanOrEqualOnSale * 100);
    }
    if (promoFlags.overPromosNotCombinable === true) {
      promoData.overPromos.notCombinable = true;
    }

    if ($scope.promoData.code === '') {
      delete promoData.code;
    }

    delete promoData.createdAt;
    delete promoData.modifiedAt;

    $scope.formPromise.sending = true;

    var saveCallback = function() {
      sendSaveForm(promoData);
    };

    if (promoData.overItems && promoData.overItems.fromBrands) {
      if ($scope.promos === null) {
        promoService
          .get({
            includeInactive: true,
            includeSecret: true
          })
          .then(function(result) {
            $scope.promos = result.items;
            checkConcurrentPromos(saveCallback);
          });
      } else {
        checkConcurrentPromos(saveCallback);
      }
    } else {
      saveCallback();
    }
  };

  $scope.sameDiscount = function(promo1, promo2) {
    return (promo1.credit !== undefined && promo1.credit === promo2.credit) ||
      (promo1.percentage !== undefined && promo1.percentage === promo2.percentage) ||
      (promo1.giftsDescription !== undefined && promo1.giftsDescription === promo2.giftsDescription);
  };

  function sendSaveForm(promoData) {
    formPromise.handle(promoService.save(promoData).then(function() {
      $state.go('admin.promos');
    }));
  }

  function checkConcurrentPromos(saveCallback) {
    var filteredPromos = _.filter($scope.promos, function(promo) {
      var arrayMatch = function(array1, array2) {
        array1 = typeof array1 === 'string' ? array1.split(',') : array1;
        array2 = typeof array2 === 'string' ? array2.split(',') : array2;
        return array1 &&
          array2 &&
          array1.some(function(value1) {
            return array2.indexOf(value1) >= 0;
          });
      };
      return promo.overItems &&
        (arrayMatch(promo.overItems.fromBrands, $scope.promoData.overItems.fromBrands) ||
          arrayMatch(promo.overItems.fromFulfillers, $scope.promoData.overItems.fromFulfillers)) &&
        new Date(promo.beginsAt) <= $scope.promoData.expiresAt &&
        new Date(promo.expiresAt) >= $scope.promoData.beginsAt &&
        promo.id !== $scope.promoData.id;
    });
    if (filteredPromos.length > 0) {
      showConfirmationModal(filteredPromos, saveCallback);
    } else {
      saveCallback();
    }
  }

  function showConfirmationModal(filteredPromos, confirmCallback) {
    $scope.filteredPromos = filteredPromos;

    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/concurrent-promos-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });

    $rootScope.addModalToStack();

    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });

    $scope.closeModal = function() {
      instance.dismiss();
      formPromise.sending = false;
    };

    $scope.submitModal = function() {
      confirmCallback();
      $scope.closeModal();
    };
  }

}

module.exports = AdminPromoController;
