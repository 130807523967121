// @ngInject
function ProductSortSelectorDirective() {
  var linker = function($scope, element, attrs) {
    $scope.defaultText = attrs.defaultText || 'Newest';

    $scope.isUserAdmin = function() {
      return ($scope.getUser() || {}).isAdmin;
    };
  };
  return {
    link: linker,
    templateUrl: '/views/partials/product-sort-selector.html',
    restrict: 'E'
  };
}

module.exports = ProductSortSelectorDirective;
