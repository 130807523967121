var _ = require('lodash-core');

// @ngInject
function AdminProductRankingsController($scope, $rootScope, productRankingsService, $uibModal) {
  $scope.rankings = [];
  var attributes = [];

  productRankingsService.getRankings().then(function(response) {
    $scope.rankings = response.items;
  });

  productRankingsService.getAttributes().then(function(response) {
    attributes = response.items;
  });

  $scope.getAttributeHelpText = function(attributeId) {
    var help = _.find(attributes, { id: attributeId }) || {};
    help = help.helpText || '';
    return help;
  };

  $scope.editRanking = function(ranking) {

    var instance = $uibModal.open({
      templateUrl: '/views/admin/product-ranking-modal.html',
      windowClass: 'product-ranking-modal',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    $scope.ranking = {
      id: ranking.id,
      active: ranking.active,
      weights: _(attributes).map(function(att) {
        return {
          attribute: att.id,
          weight: (_.find(ranking.weights, { attribute: att.id }) || {}).weight || 0
        };
      }).sortBy(function(item) {
        return -item.weight;
      }).value()
    };
    $scope.closeEdition = function(save) {
      if (save) {
        $scope.saving = true;
        var toUpdate = {
          id: ranking.id,
          active: $scope.ranking.active,
          weights: _.filter($scope.ranking.weights, function(item) { return item.weight; })
        };
        productRankingsService.updateRanking(toUpdate).then(function() {
          ranking.active = toUpdate.active;
          ranking.weights = toUpdate.weights;
          $scope.saving = false;
          instance.dismiss();
        });
      } else {
        instance.dismiss();
      }
    };
  };

}

module.exports = AdminProductRankingsController;
