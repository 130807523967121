// @ngInject
function PartnerProviderService(apiService) {

  this.updateBrandOrderNumber = function(order) {
    return apiService.put('/partner-providers/convictional/{number}/brand-order-number', {
      pathParams: {
        number: order.number
      }
    });
  };
}

module.exports = PartnerProviderService;
