function isLocalStorageSupported() {
  try{
    if (!window.localStorage) {
      return false;
    }
  }catch(e){
      return false;
  }
  try {
    window.localStorage.setItem('local-storage-write-test', 'test-value');
    if (window.localStorage.getItem('local-storage-write-test') !== 'test-value') {
      throw new Error('localStorage read value doesn\'t match');
    }
    window.localStorage.removeItem('local-storage-write-test');
  } catch(err) {
    console.log('localStorage not supported: ' + err.message);
    return false;
  }
  return true;
}

function initialize() {
  if (!process.browser) {
    return;
  }
  if (!isLocalStorageSupported()) {
    // apply polyfill
    /* jshint ignore: start */
    window.iframeStorage = {
     _data       : {},
     setItem     : function(id, val) {
       return this._data[id] = String(val);
     },
     getItem     : function(id) {
       return this._data.hasOwnProperty(id) ? this._data[id] : false;
     },
     removeItem  : function(id) { return delete this._data[id]; },
     clear       : function() { return this._data = {}; }
    };
    /* jshint ignore: end */
  }
}

exports.initialize = initialize;
