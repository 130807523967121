var _ = require('lodash-core');

// @ngInject
function AdminOrdersController($scope, $rootScope, orderService, $state, $stateParams) {
  $scope.searchTerm = '';
  $scope.explainMessage = false;
  $scope.hideTestOrders = $rootScope.hideTest === undefined || $rootScope.hideTest;
  $scope.loading = false;
  var search = _.debounce(function(term, prev) {
    $scope.explainMessage = false;
    if (!$scope.onlyUncompletedOrders && term === prev) {
      return;
    }

    $state.go($state.current.name, { search: term }, { notify: false });

    if (!$scope.onlyUncompletedOrders && !term) {
      delete $scope.orders;
      $scope.explainMessage = true;
      return;
    }

    $scope.loading = true;
    var params = {
      term: term,
      hideTestOrders: $scope.hideTestOrders,
      onlyUncompletedOrders: $scope.onlyUncompletedOrders
    };
    var promise = orderService.searchOrders(params);

    promise.then(function(response) {
      _.forEach(response.items, function(order) {
        order.fulfillerOrders = _(order.events)
          .filter(function(event) {
            return event.type === 'fulfiller-order';
          })
          .map('details')
          .compact()
          .map('id')
          .compact()
          .join(', ');
      });
      $scope.loading = false;
    });
    $rootScope.onlyUncompleted = $scope.onlyUncompletedOrders;
    $rootScope.hideTest = $scope.hideTestOrders;
    $scope.orders = promise.lazyObject();

  }, 1000);

  $scope.$watch('onlyUncompletedOrders', function() {
    search($scope.searchTerm, '');
  });
  $scope.$watch('hideTestOrders', function() {
    search($scope.searchTerm, '');
  });
  $scope.$watch(function() { return JSON.stringify($stateParams); }, function() {
    $scope.searchTerm = $stateParams.search;
    $scope.onlyUncompletedOrders = $stateParams.onlyUncompletedOrders === undefined ?
      $rootScope.onlyUncompleted === undefined || $rootScope.onlyUncompleted: $stateParams.onlyUncompletedOrders;
  });
  $scope.keypress = function($event) {
    if ($event.which === 13) {
      search($scope.searchTerm, '');
    }
  };
}

module.exports = AdminOrdersController;
