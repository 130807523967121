// @ngInject
function ScrollNavDirective() {
  var linker = function() {
    angular.element(document.body).scrollspy({ target: '.scroll-nav' });
  };

  return {
    link: linker,
    templateUrl: '/views/partials/scroll-nav.html',
    restrict: 'E',
    transclude: true
  };
}

module.exports = ScrollNavDirective;

