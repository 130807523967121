var _ = require('lodash-core');
var sharedBusinessRules = require('shared-business-rules');

/* jshint maxparams: 13 */

// @ngInject
function AdminOrderController($scope, $rootScope, $state, $stateParams, $uibModal, orderService, productService,
                              formsService, userService, config, globalizationService, partnerProviderService,
                              influencerService) {

  loadOrder();

  $scope.authorizationUrl = null;
  $scope.expandDiscount = true;
  $scope.loadOrder = loadOrder;
  $scope.orderType = 'order';
  $scope.orderRisk = false;
  $scope.forms = {};
  $scope.featureFlags = config.featureFlags;
  $scope.cancelItemReasons = [
    'OOS - Order processed - Brand unable to fulfill',
    'OOS - Order not processed - OOS in the brand page',
    'OOS - Order not processed - Checkout failed due OOS',
    'OOS - Order not processed - Unable to add to cart',
    'OOS - Not enough units',
    'Risk decline',
    'Customer request',
    'Duplicate',
    'Brand site limitation'
  ];

  $scope.forms = {};
  $scope.return = {};
  $scope.returnItemReasons = productService.returnItemReasons;

  $scope.toggleDiscount = function() {
    $scope.expandDiscount = !$scope.expandDiscount;
  };

  $scope.canRequestReturn = function(item) {
    return !item.orderReturn && !item.requestingReturn && getOrderedQty(item) > 0 &&
      (!item.undeliverableQuantity || item.quantity > item.undeliverableQuantity);
  };

  $scope.canFlagUndeliverable = function(item) {
    return item.quantity > (item.undeliverableQuantity || 0);
  };

  $scope.$watch('order.loading', function() {
    globalizationService.setExtraOrderCurrency($scope.order);
  });

  $scope.showRequestReturnModal = function(item) {

    $scope.return.item = item;
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/request-return-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });
    $scope.closeModal = function() {
      instance.dismiss();
    };
    $scope.submitModal = function() {
      if (!$scope.formPromise.validate($scope.forms.return)) {
        if (!$scope.return.reason || !$scope.return.reason.trim()) {
          $scope.formPromise.setError('reason must be specified');
        }
        return;
      }
      $scope.closeModal();
      var reason = $scope.return.reason;
      if (reason === 'Other' && $scope.return.otherReason) {
        reason += ': ' + $scope.return.otherReason.trim();
      }
      $scope.sendReturnRequest(item, reason);
    };
  };

  $scope.sendReturnRequest = function(item, returnReason) {
    if (item.orderReturn || item.requestingReturn) {
      return;
    }
    var itemData = _.cloneDeep(item);
    orderService.returnItems($scope.order, itemData, returnReason, $scope.loadOrder, function(result) {
      item.requestingReturn = false;
      item.orderReturn = result.number;
      $scope.returnNumber = result.number;
    });
    item.requestingReturn = true;
  };

  $scope.showFlagUndeliverableModal = function(item) {
    item.undeliverableQuantity = item.undeliverableQuantity || 0;
    $scope.event = {
      type: 'item-cancelled',
      details: {
        item: {
          brand: item.brand,
          partner: item.partner,
          productId: item.productId,
          quantity: item.quantity,
          sku: item.sku,
          style: item.style,
          hash: item.hash,
          deliverableQuantity: item.quantity - item.undeliverableQuantity,
          deliverableQuantityInternationally: item.quantity - item.undeliverableQuantity,
          undeliverableQuantity: item.undeliverableQuantity
        },
        quantity: item.quantity - item.undeliverableQuantity
      }
    };
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/item-cancelled-event-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });
    $scope.closeModal = function() {
      instance.dismiss();
    };
    $scope.submitModal = function() {
      if (!$scope.formPromise.validate($scope.forms.event)) {
        if (!$scope.event.details.reason || !$scope.event.details.reason.trim()) {
          $scope.formPromise.setError('reason must be specified');
        }
        return;
      }
      var event = _.cloneDeep($scope.event);

      var success = function() {
        $scope.closeModal();
        $scope.loadOrder();
      };

      var error = function(error) {
        console.log(error);
      };

      if (['flag-undeliverable', 'item-cancelled'].indexOf(event.type) >= 0) {
        delete event.details.item.deliverableQuantity;
        delete event.details.item.deliverableQuantityInternationally;
        delete event.details.item.undeliverableQuantity;
      }

      $scope.formPromise.handle(orderService.addEvent($scope.order, event)).then(success).catch(error);
    };
  };

  $scope.canSubmit = function() {
    return $scope.event.details.reason &&
      $scope.event.details.quantity >= 1 &&
      $scope.event.details.quantity <= $scope.event.details.item.deliverableQuantity;
  };

  $scope.getPartnersInOrder = function() {
    return _.map($scope.fulfillers, 'id');
  };

  $scope.getItems = function() {
    return $scope.order.items;
  };

  $scope.getProductImage = function(imageUrl, options) {
    return productService.getImageUrl(imageUrl, options);
  };

  $scope.getItemLink = function(item) {

    var sourceUrl = (item.style && item.style.sourceUrl) || '';

    if (sourceUrl.indexOf('pdt.tradedoubler.com') === -1 && sourceUrl.indexOf('click.linksynergy') === -1) {
      var utmParameters = {
        'utm_source': 'orchardmile',
        'utm_medium': 'referral',
        'utm_campaign': 'orchardmile'
      };
      var customUtm = sharedBusinessRules.customUtm;
      if (item.brand && Object.keys(customUtm).indexOf(item.brand.id) !== -1) {
        _.merge(utmParameters, customUtm[item.brand.id]);
      }
      if (item.partner && Object.keys(customUtm).indexOf(item.partner.id) !== -1) {
        _.merge(utmParameters, customUtm[item.partner.id]);
      }

      sourceUrl += (sourceUrl.indexOf('?') < 0 ? '?' : '&') +
        Object.keys(utmParameters).map(function(key) {
          return [key + '=' + utmParameters[key]];
        }).join('&');
    }

    return sourceUrl;
  };

  $scope.deliverableItemsByPartner = null;

  $scope.getDeliverableItemsByPartners = function() {
    if ($scope.deliverableItemsByPartner === null) {
      var deliverableItemsByPartner = {};
      var fulfillerIds = _.map($scope.fulfillers, 'id');
      fulfillerIds.forEach(function(fulfillerId) {
        var items = $scope.getItemsFromFulfiller(fulfillerId);
        if (items.length > 0) {
          deliverableItemsByPartner[fulfillerId] = items;
        }
      });
      $scope.deliverableItemsByPartner = deliverableItemsByPartner;
    }
    return $scope.deliverableItemsByPartner;
  };

  $scope.getPartnersWithDeliverableItems = function() {
    return Object.keys($scope.getDeliverableItemsByPartners());
  };

  $scope.getItemsFromFulfiller = function(fulfillerId) {

    if ($scope.deliverableItemsByPartner && $scope.deliverableItemsByPartner[fulfillerId]) {
      return $scope.deliverableItemsByPartner[fulfillerId];
    }

    var fulfiller = _.find($scope.fulfillers, function(f) {
      return f.id === fulfillerId;
    });
    var items = fulfiller && fulfiller.items ? fulfiller.items : [];

    var shippingEvents = _.filter($scope.order.events, function(e) {
      return e.type.match(/^shipping-status$/) && e.provider === fulfillerId;
    });

    var shippingInternationalEvents = _.filter($scope.order.events, function(e) {
      return e.type.match(/international-shipping-status$/) && e.provider === fulfillerId;
    });

    var fulfillerOrderEvents = _.filter($scope.order.events, function(e) {
      return e.type.match(/fulfiller-order$/) && e.provider === fulfillerId;
    });

    var markedItems = [];
    var markedItemsInternationally = [];
    _.forEach(shippingEvents, function(event) {
      if (event.details.packedItems) {
        _.forEach(event.details.packedItems, function(item) {
          markedItems[item.id] = markedItems[item.id] || 0;
          markedItems[item.id] += item.quantity;
          _.forEach(shippingInternationalEvents, function(internationalEvent) {
            if (item.partnerId === internationalEvent.details.provider) {
              markedItemsInternationally[item.id] = markedItemsInternationally[item.id] || 0;
              markedItemsInternationally[item.id] += item.quantity;
            }
          });
        });
      } else {
        _.forEach(event.details.items, function(quantity, itemSku) {
          markedItems[itemSku] = markedItems[itemSku] || 0;
          markedItems[itemSku] += quantity;
        });
      }
    });


    _.forEach(shippingEvents, function(event) {
      if (event.details.packedItems) {
        _.forEach(event.details.packedItems, function(item) {
          if (item.partnerId === event.details.provider) {
            markedItemsInternationally[item.id] = markedItemsInternationally[item.id] || 0;
            markedItemsInternationally[item.id] += item.quantity;
          }
        });
      } else {
        _.forEach(event.details.items, function(quantity, itemSku) {
          markedItemsInternationally[itemSku] = markedItemsInternationally[itemSku] || 0;
          markedItemsInternationally[itemSku] += quantity;
        });
      }
    });

    var orderedItems = [];
    _.forEach(fulfillerOrderEvents, function(event) {
      if (event.details.orderedItems) {
        _.forEach(event.details.orderedItems, function(item) {
          if (!orderedItems[item.id]) {
            orderedItems[item.id] = {};
          }
          orderedItems[item.id].quantity = (orderedItems[item.id].quantity || 0) + item.quantity;
          orderedItems[item.id].subtotal = (orderedItems[item.id].subtotal || 0) + item.subtotal;
          orderedItems[item.id].discount = (orderedItems[item.id].discount || 0) + item.discount;
          orderedItems[item.id].tax = (orderedItems[item.id].tax || 0) + item.tax;
        });
      } else {
        _.forEach(event.details.items, function(quantity, itemSku) {
          orderedItems[itemSku] = orderedItems[itemSku] || 0;
          orderedItems[itemSku] += quantity;
        });
      }
    });

    items = _.map(items, function(item) {
      var oldId = item.productId + '-' + (item.sku || ''); // previous way to identify items
      item.id = item.style.id + '-' + item.hash;
      item.variant = JSON.stringify({
        id: item.id,
        productId: item.productId,
        sku: item.sku,
        styleId: item.style && item.style.id ? item.style.id : '',
        hash: item.hash
      });
      item.markedQuantity = markedItems[item.id] || markedItems[oldId] || 0;
      item.markedQuantityInternationally =
        markedItemsInternationally[item.id] || markedItemsInternationally[oldId] || 0;
      item.orderedQuantity = orderedItems[item.id] ?
        orderedItems[item.id].quantity : orderedItems[oldId] || 0;
      item.undeliverableQuantity = item.undeliverableQuantity || 0;
      item.deliverableQuantity = item.orderedQuantity - item.markedQuantity;
      item.deliverableQuantityInternationally = item.markedQuantityInternationally - item.deliverableQuantity;
      item.orderableQuantity = item.quantity - item.undeliverableQuantity - item.orderedQuantity;
      item.updatableQuantity = item.quantity - item.undeliverableQuantity;
      item.subtotal = orderedItems[item.id] ? orderedItems[item.id].subtotal : 0;
      item.discount = orderedItems[item.id] ? orderedItems[item.id].discount : 0;
      item.tax = orderedItems[item.id] ? orderedItems[item.id].tax : 0;
      return item;
    });
    return items;
  };

  $scope.getRefundableItems = function() {
    var items = [];
    if ($scope.fulfillers) {
      $scope.fulfillers.forEach(function(fulfiller) {
        items.push($scope.getItemsFromFulfiller(fulfiller.id));
      });
      return _.flatten(items);
    }
  };

  $scope.getPaymentMethod = function() {
    if ($scope.order) {
      if ($scope.order.billing && $scope.order.billing.paymentMethod.paymentMethodType) {
        return $scope.order.billing.paymentMethod.paymentMethodType;
      }
      if ($scope.order.events) {
        var authorizationEvent = _.find($scope.order.events, {type: 'authorization'});
        /* jshint camelcase: false */
        if (authorizationEvent && authorizationEvent.details.payment_method_types) {
          return authorizationEvent.details.payment_method_types[0].replace('_clearpay', '');
        } else {
          return 'card';
        }
      }
    }
  };

  function getOrderedQty(item) {
    var fulfillerOrderEvents = _.filter($scope.order.events, function(e) {
      return e.type.match(/fulfiller-order$/) && e.provider === item.partner.id;
    });
    var orderedQty = 0;
    _.forEach(fulfillerOrderEvents, function(fulfillerOrderEvent) {
      if (fulfillerOrderEvent.provider === item.partner.id) {
        _.forEach(fulfillerOrderEvent.details.orderedItems, function(orderedItem) {
          if (orderedItem.id === item.style.id + '-' + item.hash) {
            orderedQty += orderedItem.quantity;
          }
        });
      }
    });
    return orderedQty;
  }

  function loadOrder() {
    var orderPromise = $scope.orderPromise = orderService.get($stateParams.id);
    orderPromise.then(function(order) {
      $scope.authorizationUrl = getAuthorizationUrl(order);
      $scope.fulfillers = getItemsByFulfiller(order);
      setOrderRisk(order);
      setUndeliverableQuantity(order);
      $scope.deliverableItemsByPartner = null;

      var cleanEvents = order.events;
      var newEvents = [];
      _.forEach(cleanEvents, function(event) {
        newEvents.push(event);
        if (event.type === 'fulfiller-order') {
          if (event.details.changelog) {
            _.forEach(event.details.changelog, function(log) {
              if (log.id) {
                newEvents.push({
                  createdAt: log.modifiedAt,
                  type: 'fulfiller-order-update',
                  provider: event.provider,
                  user: log.user,
                  details: log
                });
              }
            });
          }
        }
      });
      order.events = newEvents;
    });
    $scope.order = orderPromise.lazyObject();
    orderPromise.then(function() {
      if ($scope.order.user) {
        var loyaltyPromise = userService.getLoyaltyByUserId($scope.order.user.id);
        loyaltyPromise.then(function(loyalty) {
          $scope.order.user.loyalty = loyalty;
        });
      }
      if ($scope.featureFlags.domesticShipmentSplit.enabled) {
        $scope.order.packagesInfo = orderService.getItemPackages($scope.order);
      }
      var convictionalOrderEventWithoutShopifyId = _.find($scope.order.events, function(event) {
        return event.type === 'fulfiller-order' && !event.details.shopifyId &&
          (event.details.provider === 'convictional' || (!event.details.provider && event.details.providerOrder));
      });
      if (convictionalOrderEventWithoutShopifyId) {
        partnerProviderService.updateBrandOrderNumber($scope.order).then(function() {
          convictionalOrderEventWithoutShopifyId.details.shopifyId = 'Shopify ID has been set, please refresh the page';
        }).catch(function() {
          convictionalOrderEventWithoutShopifyId.details.shopifyId = 'Shopify ID not found, ' +
            'please refresh the page to try again';
        });
      }
      $scope.influencersAttributed = {};
      _.forEach($scope.order.items, function (item) {
        if (item.attributedTo) {
          influencerService.getById(item.attributedTo).then(function (influ) {
            $scope.influencersAttributed[item.attributedTo] = {
              name: influ.username || influ.preferredName,
              userId: influ.userId
            };
          });
        }
      });
    });
  }

  function setUndeliverableQuantity(order) {
    var undeliverableFlagEvents = _.filter(order.events, function(event) {
      return ['flag-undeliverable', 'item-cancelled'].indexOf(event.type) >= 0;
    });
    _.forEach(undeliverableFlagEvents, function(event) {
      var flaggedItem = event.details.item;
      var item = _.find(order.items, function(item) {
        return item.productId === flaggedItem.productId &&
          item.style &&
          flaggedItem.style &&
          item.style.sku === flaggedItem.style.sku &&
          (!flaggedItem.hash || item.hash === flaggedItem.hash);
      });
      item.undeliverableQuantity = item.undeliverableQuantity || 0;
      item.undeliverableQuantity += event.details.quantity;
    });
  }

  function setOrderRisk(order) {
    if (!order || !order.events) {
      return null;
    }
    var authorization = _.find(order.events, {type: 'authorization'});
    if (!authorization) {
      return null;
    }

    /*jshint camelcase: false */
    if (authorization.details && authorization.details.outcome &&
      authorization.details.outcome.risk_level) {
      $scope.orderRisk = authorization.details.outcome.risk_level;
    }
  }

  function getItemsByFulfiller(order) {
    var fulfillers = [];
    order.items.forEach(function(item) {
      var partnerId = item.partner.id;
      var fulfiller = _.find(fulfillers, {id: partnerId});
      if (!fulfiller) {
        var orderFulfiller = _.find(order.fulfillers, {id: partnerId});
        fulfiller = {
          id: partnerId,
          name: item.partner.name,
          items: [],
          shippingMethod: orderFulfiller && orderFulfiller.shippingMethod,
          provider: item.provider
        };
        fulfillers.push(fulfiller);
      }
      fulfiller.items.push(item);
    });
    return fulfillers;
  }

  function getAuthorizationUrl(order) {
    if (!order || !order.events) {
      return null;
    }
    var authorization = _.find(order.events, {type: 'authorization'});
    if (!authorization) {
      return null;
    }
    var chargeId;
    if (authorization.provider === 'stripe') {
      var environment = order.testing ? 'test/' : '';
      chargeId = authorization.details.id;
      return 'https://dashboard.stripe.com/{ENVIRONMENT}payments/{CHARGEID}'
        .replace('{ENVIRONMENT}', environment)
        .replace('{CHARGEID}', chargeId);
    }
    if (authorization.provider === 'amazon') {
      chargeId = authorization.details.AuthorizationDetails &&
        authorization.details.AuthorizationDetails.AmazonAuthorizationId.slice(0, 19);
      return 'https://sellercentral.amazon.com/hz/me/pmd/payment-details?orderReferenceId={CHARGEID}'
        .replace('{CHARGEID}', chargeId);
    }
    if (authorization.provider === 'paypal') {
      chargeId = authorization.details.id;
      var urlPaypal = config.environment === 'production' ?
        'https://www.paypal.com/activity/payment/{id}' :
        'https://www.sandbox.paypal.com/activity/payment/{id}';
      return urlPaypal.replace('{id}', chargeId);
    }
    return null;
  }
}

module.exports = AdminOrderController;
