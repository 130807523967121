// @ngInject
function ViewValueSyncFixDirective($interval) {
  /*
  * View Value Sync Fix
  *
  * this directive is a fix for input elements that use autofill/autocomplete +
  * angular ng-model.
  * sometimes when using the browser autofill/autocomplete the
  * DOM element value (tipically a <select>) stops rendering the actual view value
  * (and model), this uses an interval to ensure it is.
  */
  var linker = function($scope, element, attrs, ngModel) {
    var pause = false;
    function syncValue() {
      if (pause) {
        return;
      }
      var expectedValue = ngModel.$isEmpty(ngModel.$viewValue) ? '' : ngModel.$viewValue.toString();
      var elementValue = element.val();
      elementValue = elementValue === null ? '' : elementValue;
      if (elementValue === expectedValue) {
        return;
      }
      element.val(expectedValue);
      element.trigger('valuesync');
    }
    element.on('click', function() {
      pause = true;
    });
    element.on('blur', function() {
      pause = false;
      syncValue();
    });
    element.on('mouseenter', function() {
      pause = true;
    });
    element.on('mouseleave', function() {
      pause = false;
      syncValue();
    });
    var renderInterval = $interval(syncValue, 100);
    $scope.$on('$destroy', function() {
      $interval.cancel(renderInterval);
    });
  };
  return {
    link: linker,
    restrict: 'A',
    require: '?ngModel'
  };
}

module.exports = ViewValueSyncFixDirective;
