
var validations = {
  shared: require('shared-business-rules').validations
};

// @ngInject
function FieldValidationDirective($parse) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {

      validations.scope = scope;
      var func = $parse(attrs.fieldValidation)(validations);

      if (typeof func !== 'function') {
        throw new Error(attrs.fieldValidation + ' is not a valid validation name');
      }

      function validate(viewValue) {
        if (attrs.fieldValidationDisabled && $parse(attrs.fieldValidationDisabled)(scope)) {
          return viewValue;
        }
        var result = func(viewValue);
        for (var index in result.errorTypes) {
          var error = result.errorTypes[index];
          ctrl.$setValidity(error, result.valid || error !== result.error);
        }
        return viewValue;
      }

      ctrl.$parsers.unshift(validate);
      ctrl.$formatters.unshift(validate);
    }
  };
}

module.exports = FieldValidationDirective;
