/* jshint maxparams: 15 */

// @ngInject
function ContentPopupService($cookies, $rootScope, $timeout, $location, viewportService,
                             contentPageService, preloadService, analyticsService, $uibModal, staticAssetService,
                             userService, config, platformService, $stateParams) {

  this.defaultBackgroundImage = '/img/value-proposition.jpg';

  var isFromUrl = false;
  this.initialize = function() {
    if (!config.featureFlags.showPopup.enabled || window.isOnWidget()) {
      return;
    }
    var user = userService.getUser();
    if (!user || !user.email) {
      userService.setDefaultEmail(userService.getEmailFromUrl());
    }
    var name = this.fromUrl();
    isFromUrl = !!name;
    if (name) {
      this.show(name);
    } else {
      if (!$rootScope.popups) {
        $rootScope.popups = contentPageService.getActivePopUps();
      }
      for (var i = 0; i < $rootScope.popups.length; i++) {
        $rootScope.popups[i].name = $rootScope.popups[i].name.replace('popups/', '');
        this.show($rootScope.popups[i].name);
      }
    }
  };

  this.fromUrl = function() {
    var popupMatch = /[\?&#]popup=([^\?&#]+)/i.exec(document.location.search);
    if (!popupMatch) {
      return;
    }

    return decodeURIComponent(popupMatch[1]);
  };

  this.show = function(name, popupData) {
    if (window.isOnWidget()) {
      return;
    }
    var popupsShown;
    var startTime = new Date().getTime();
    var self = this;

    function showPopup(name, popup) {
      popup.popupName = popup.popupName || name;

      var status = parseInt($stateParams.status) || 200;
      if (status !== 200) {
        return;
      }

      if (platformService.isPrerender() || platformService.isBot() ||
        platformService.isAutomatedBrowser()) {
        return;
      }

      if (popup.viewport) {
        if (!viewportService.is(popup.viewport)) {
          // this popup is not for this viewport
          analyticsService.track(popup.popupName + ' Popup Skipped For Viewport');
          return;
        }
      }

      if (popup.showOnlyOnce) {
        /*jshint sub:true*/
        popupsShown = ($cookies['_popups_shown'] || '').split(',');
        if (popupsShown.indexOf(name) >= 0) {
          // already shown this popup
          return;
        }
      }

      if (popup.popupShownOnThisSession) {
        return;
      }

      var isLoggedIn = userService.isLoggedIn();

      if (popup.loggedUsers && !isLoggedIn) {
        return;
      }

      if (popup.nonLoggedUsers && isLoggedIn) {
        return;
      }

      if (popup.urls && !isFromUrl) {
        var validUrl = popup.urls.indexOf($location.path()) > -1;
        if (!validUrl) {
          for (var i = 0; i < popup.urls.length; i++) {
            var popupUrl = popup.urls[i];
            if (popupUrl.indexOf('/*') > -1) {
              if ($location.path().indexOf(popupUrl.replace('*', '')) > -1) {
                validUrl = true;
              }
            }
          }
        }
        if (!validUrl) {
          return;
        }
      }

      if (popup.excludedUrls) {
        var invalidUrl = popup.excludedUrls.indexOf($location.path()) > -1;
        if (!invalidUrl) {
          for (var j = 0; j < popup.excludedUrls.length; j++) {
            var excludedUrl = popup.excludedUrls[j];
            if (excludedUrl.indexOf('/*') > -1) {
              if ($location.path().indexOf(excludedUrl.replace('*', '')) > -1) {
                invalidUrl = true;
              }
            }
          }
        }
        if (invalidUrl) {
          return;
        }
      }

      if ($rootScope.modalStack >= 1) {
        return;
      }

      popup.backgroundImage = staticAssetService.url(popup.backgroundImage) ||
        staticAssetService.url(self.defaultBackgroundImage);
      if (viewportService.is('xs') && popup.backgroundImageMobile) {
        popup.backgroundImage = staticAssetService.url(popup.backgroundImageMobile);
      }
      var preloadImages = popup.preloadImages || [];
      if (preloadImages) {
        if (typeof preloadImages === 'string') {
          preloadImages = [preloadImages];
        }
      }
      if (preloadImages.indexOf(popup.backgroundImage) <= 0) {
        preloadImages.push(popup.backgroundImage);
      }
      return preloadService.images(preloadImages).then(function() {
        var delay = popup.delay ? popup.delay * 1000 - 2000 : 0;

        delay = Math.max(0, delay - (new Date().getTime() - startTime));
        $timeout(function() {
          $timeout(function() {
            $rootScope.contentPopup = popup;

            popup.popupShownOnThisSession = true;
            var modalParameters = {
              templateUrl: '/views/partials/modals/content-popup.html',
              windowClass: 'content-popup popup-' + name.replace(/[^\w\d]/gi, '-'),
              size: popup.size || 'md',
              scope: $rootScope
            };
            if (popup.preventClose) {
              modalParameters.backdrop = 'static';
              modalParameters.keyboard = false;
            }

            $rootScope.modalInstance = $uibModal.open(modalParameters);
            $rootScope.addModalToStack();
            $rootScope.modalInstance.result.finally($rootScope.removeModalFromStack);

            analyticsService.track(name + ' Popup Viewed');

            // track popups shown
            /*jshint sub:true*/
            popupsShown = ($cookies['_popups_shown'] || '').split(',');
            popupsShown = popupsShown.filter(function(popupName) {
              return popupName && popupName !== name;
            });
            popupsShown.push(name);
            $cookies['_popups_shown'] = popupsShown.join(',');
          }, delay);
        }, 2000);
      });
    }

    if (popupData) {
      return showPopup(name, popupData);
    } else {
      return contentPageService.getPage('popups/' + name).then(function(popup) {
        return showPopup(name, popup);
      });
    }
  };

  this.hideAndOpenLogin = function() {
    this.hide().then(function() {
      $rootScope.modal.show('login', { trigger: 'loyalty-popup' });
    });
  };

  this.hideThenOpenRegistration = function(trigger) {
    this.hide().then(function() {
      $rootScope.modal.show('register', { trigger: trigger });
    });
  };
  this.hideThenOpenLogin = function(trigger) {
    this.hide().then(function() {
      $rootScope.modal.show('login', { trigger: trigger });
    });
  };

  this.hide = function() {
    $rootScope.modalInstance.close();
    $rootScope.closeModals();
    if ($rootScope.modalStack >= 1) {
      $rootScope.modal.hide();
    }
    if (!$rootScope.contentPopup) {
      return;
    }
    var popup = $rootScope.contentPopup;
    analyticsService.track(popup.popupName + ' Popup Dismissed', { nonInteraction: 1 });
    $rootScope.contentPopup = null;
    return new Promise(function(resolve) { // jshint ignore:line
      setTimeout(
        function() {

          if (!$rootScope.contentPopup) {
            return resolve(true);
          }
        }, 400);
    });
  };
}


module.exports = ContentPopupService;
