// @ngInject
function InfluencerBrandBannerDirective(influencerService, $state) {
  var linker = function ($scope, _element, attrs) {

    loadInfluencer(attrs.id);

    function loadInfluencer(id) {
      influencerService
        .getById(id)
        .then(function (influencer) {
          $scope.influencer = influencer;
          influencer.name = influencer.username || influencer.preferredName;
          influencer.photoUrl = influencer.profilePicture || '/img/the-mile/user-profile-black.svg';
        });
    }

    $scope.goToInfluencerPage = function() {
      $state.go('root.influencer', { influencer: $scope.influencer.name });
    };

  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/influencer-brand-banner.html',
    restrict: 'E',
    scope: false,
  };
}

module.exports = InfluencerBrandBannerDirective;
