var promises = require('../../async/promises');

// @ngInject
function DismissedProductService(apiService) {

  this.getById = function(userId) {
    return promises.extend(apiService.get('/dismissed-products', {
      urlParams: {
        user: userId
      }
    }));
  };

  this.save = function(dismissedProduct) {
    return promises.extend(apiService.post('/dismissed-products', {
      body: dismissedProduct
    }));
  };
}

module.exports = DismissedProductService;
