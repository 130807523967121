var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function FulfillerService(apiService) {

  this.getAll = promises.memoize(function(sort) {
    sort = sort || 'id';
    return apiService.get('/fulfillers').then(function(fulfillers) {
      if (!sort) {
        return fulfillers;
      }
      if (sort.substring(sort.length - 3) === '(d)') {
        return _.sortBy(fulfillers, sort.substring(0, sort.length - 3)).reverse();
      }
      fulfillers.forEach(expandShippingMethods);
      return _.sortBy(fulfillers, sort);
    });
  });

  this.getById = promises.memoize(function(id) {
    return apiService.get('/fulfillers/{id}', {
      pathParams: {
        id: id
      }
    }).then(function(fulfiller) {
      expandShippingMethods(fulfiller);
      return fulfiller;
    });
  });

  this.getByIdWithoutMemoize = function(id) {
    return apiService.get('/fulfillers/{id}', {
      pathParams: {
        id: id
      }
    }).then(function(fulfiller) {
      expandShippingMethods(fulfiller);
      return fulfiller;
    });
  };

  this.getOrders = function(fulfillerId, from, to) {
    return promises.extend(apiService.get('/fulfillers/{id}/orders', {
      pathParams: {
        id: fulfillerId
      },
      urlParams: {
        from: from,
        to: to
      }
    }));
  };

  this.getNewsletter = function(fulfillerId) {
    return promises.extend(apiService.get('/fulfillers/{id}/newsletter', {
      pathParams: {
        id: fulfillerId
      }
    }));
  };

  // private functions

  function expandShippingMethods(fulfiller) {
    if (!fulfiller || !fulfiller.shippingMethods) {
      return;
    }

    fulfiller.shippingMethods = _.sortBy(fulfiller.shippingMethods, 'cost');
    var today = new Date();

    // calculating ETA in client local time
    fulfiller.shippingMethods.forEach(function(method) {
      method.estimatesAvailable = method.timeOfDayThreshold > 0;
      if (!method.estimatesAvailable) {
        return;
      }
      // calculate from-to days
      method.daysFrom = method.daysToShip + method.daysToDeliver;
      method.daysToShipMax = method.daysToShipMax || method.daysToShip;
      method.daysToDeliverMax = method.daysToDeliverMax || method.daysToDeliver;
      method.daysTo = method.daysToShipMax + method.daysToDeliverMax;
      // calculate time-threshold if today is a business day
      if (isBusinessDay(today)) {
        var timeThreshold = new Date(
          Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), method.timeOfDayThreshold)
        );
        // if time threshold is expired or close to expire we just add one more day
        method.hoursToTimeThreshold = Math.round((timeThreshold - today) / 36e5);
        if (method.hoursToTimeThreshold <= 0) {
          ++method.daysFrom;
          ++method.daysTo;
        }
      }
      // save business days
      method.businessDaysFrom = method.daysFrom;
      method.businessDaysTo = method.daysTo;
      // convert business days to real days
      method.daysFrom = convertBusinessDays(today, method.daysFrom);
      method.daysTo = convertBusinessDays(today, method.daysTo);
      // set the specific dates from-to
      method.dateFrom = new Date(new Date(today).setDate(today.getDate() + method.daysFrom));
      method.dateTo = new Date(new Date(today).setDate(today.getDate() + method.daysTo));
      // if "from" === "to" then set "in"
      if (method.daysFrom === method.daysTo) {
        method.daysIn = method.daysFrom;
        method.dateIn = method.dateFrom;
      }
    });
  }

  function convertBusinessDays(startDate, amountOfDays) {
    startDate = new Date(startDate.toString()); // just clone the object
    var counter = isBusinessDay(startDate) ? 0 : 1;
    while (amountOfDays) {
      ++counter;
      startDate.setDate(startDate.getDate() + 1);
      if (isBusinessDay(startDate)) {
        --amountOfDays;
      }
    }
    return counter;
  }

  function isBusinessDay(date) {
    switch (date.getDay()) {
      case 0: // sunday
      case 6: // saturday
        return false;
      default:
        // TODO: solve the holidays
        return true;
    }
  }

  this.update = function(fulfiller) {
    if (fulfiller.id) {
      return promises.extend(apiService.put('/fulfillers/{id}', {
        pathParams: {
          id: fulfiller.id
        },
        body: fulfiller
      }));
    }
  };

}

module.exports = FulfillerService;
