
// @ngInject
function EmailSubscribeDirective($rootScope, formsService, userService) {

  var linker = function($scope, element, attrs) {

    var formPromise = $scope.formPromise = formsService.promiseHandler();
    $scope.inputs = $scope.inputs || {};
    //Default Values
    $scope.requireOptIn = false;
    $scope.showOptIn = false;
    $scope.inputs.optInValue = true;
    $scope.CTAText = 'Subscribe';
    $scope.optInText = 'I agree with terms and conditions';
    if (attrs.showsuccessmessage === 'false') {
      $scope.showSuccessMessage = false;
    }
    if (attrs.subscribetotopic) {
      $scope.subscribeToTopic = attrs.subscribetotopic;
    }

    if (attrs.ctatext) {
      $scope.CTAText = attrs.ctatext;
    }
    if (attrs.optintext) {
      $scope.optInText = attrs.optintext;
    }

    if (attrs.showoptin === 'true') {
      $scope.showOptIn = true;
      $scope.inputs.optInValue = false;
    }
    if (attrs.requireoptin === 'true') {
      $scope.requireOptIn = true;
    }
    var user = userService.getUser();
    $scope.userMail = user && user.email || '';

    $scope.subscribe = function() {
      var email = $scope.userMail;
      if (email === '') {
        $scope.form.email.$setValidity('required', false);
        return false;
      }
      if ($scope.requireOptIn && !$scope.inputs.optInValue) {
        return false;
      }

      if (!formPromise.validate($scope.form)) {
        return;
      }

      delete $rootScope.anonymousSubscriptionResult;
      var transactional = true;
      if ($scope.inputs.optInValue && $scope.showOptIn) {
        transactional = !$scope.inputs.optInValue;
      }
      var promise = userService.subscribeEmailTo(email, 'newsletter', {
        transactional: transactional
      }).then(function(result) {
        $rootScope.anonymousSubscriptionResult = result;
        return result;
      });
      if ($scope.subscribeToTopic) {
        promise = promise.then(function() {
          return userService.subscribeEmailTo(email, $scope.subscribeToTopic, {
            transactional: transactional
          });
        });
      }

      formPromise.handle(promise);
    };
  };

  return {
    link: linker,
    templateUrl: '/views/partials/email-subscribe.html',
    restrict: 'E'
  };

}

module.exports = EmailSubscribeDirective;
