var _ = require('lodash-core');

// @ngInject
function AdminReelsController($scope, $state, $stateParams, userService, reelService, showService, influencerService) {
  var usedParameters = {};

  $scope.statuses = ['drafted', 'scheduled', 'live', 'posted', 'any'];

  $scope.parameters = {
    term: $stateParams.term,
    status: $stateParams.status || 'any'
  };

  $scope.$watch(
    function() {
      return $stateParams;
    },
    function() {
      _.merge($scope.parameters, _.pick($stateParams, ['term', 'status']));
    }
  );

  $scope.$watch('reels', function () {
    if ($scope.reels && $scope.reels.length) {
      _.forEach($scope.reels, function (reel) {
        var count = (_.filter(reel.showProducts, function (product) { return product.timeToShow; }) || []).length;
        if (reel.showProducts && count === reel.showProducts.length) {
          reel.timeAssigned = '✓';
        } else if (count > 0) {
          reel.timeAssigned = 'O';
        } else {
          reel.timeAssigned = 'X';
        }
      });
    }
  });

  var search = function() {
    var parameters = $scope.parameters;
    if (_.isEqual(parameters, usedParameters) || !$scope.parameters.term) {
      return;
    }
    $scope.loading = true;
    usedParameters = _.cloneDeep(parameters);

    $state.go($state.current.name, parameters, { notify: false });

    if (!parameters.term) {
      delete $scope.reels;
      return;
    }

    var searchParams = {
      term: parameters.term
    };

    delete $scope.reels;
    $scope.reels = [];
    if (parameters.term && /[1234567890abcdef]{24}/.test(parameters.term)) {
      reelService
        .getById(parameters.term)
        .then(function(reel) {
          influencerService.getById(reel.influencerId).then(function(influencer) {
            reel.influencerName = influencer.preferredName;
            reel.userId = influencer.userId;
            if (
              !['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status) ||
              reel.status === $scope.parameters.status
            ) {
              $scope.reels.push(reel);
            }
          });
        })
        .finally(function() {
          $scope.loading = false;
        });
      reelService
        .query({'influencer-ids': parameters.term})
        .then(function(reels) {
          if (['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
            reels = reels.filter(function(reel) {
              return reel.status === $scope.parameters.status;
            });
          }
          if (reels.length) {
            influencerService.getById(parameters.term).then(function(influencer) {
              _.forEach(reels, function(reel) {
                reel.influencerName = influencer.preferredName;
                reel.userId = influencer.userId;
              });
              $scope.reels = _.concat($scope.reels, reels);
            });
          }
        })
        .finally(function() {
          $scope.loading = false;
        });
      showService
        .getReelsByShowId(parameters.term)
        .then(function(reels) {
          if (['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
            reels = reels.filter(function(reel) {
              return reel.status === $scope.parameters.status;
            });
          }
          if (reels.length) {
            influencerService.getById(parameters.term).then(function(influencer) {
              _.forEach(reels, function(reel) {
                reel.influencerName = influencer.preferredName;
                reel.userId = influencer.userId;
              });
              $scope.reels = _.concat($scope.reels, reels);
            });
          }
        })
        .finally(function() {
          $scope.loading = false;
        });
    } else {
      userService
        .search(searchParams)
        .then(function(userSearchResponse) {
          $scope.loading = !!userSearchResponse.items.length;
          _.forEach(userSearchResponse.items, function(user) {
            influencerService.getByUserId(user.id).then(function(influencer) {
              reelService.query({
                status: ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status) ?
                  $stateParams.status : 'scheduled,drafted,posted,live',
                'influencers-ids': influencer.id,
                limit: 300,
                sort: 'createdAt(d)',
                'hide-test-shows': false
              }).then(function(reels) {
                var filteredReels = reels.items;
                if (['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
                  filteredReels = reels.items.filter(function(reel) {
                    return reel.status === $scope.parameters.status;
                  });
                }
                _.forEach(filteredReels, function(reel) {
                  reel.influencerName = influencer.preferredName;
                  reel.userId = influencer.userId;
                });
                $scope.reels = _.concat($scope.reels, filteredReels);
              });
            });
          });
        })
        .catch(function() {
          $scope.loading = false;
        })
        .finally(function() {
          $scope.loading = false;
        });
    }
  };

  if ($stateParams.term && $stateParams.term !== '') {
    search();
  } else {
    getLastReels();
  }

  function getLastReels() {
    var parameters = $scope.parameters;
    $scope.loading = true;
    usedParameters = _.cloneDeep(parameters);
    $state.go($state.current.name, parameters, { notify: false });

    if (!$scope.parameters.term || $scope.parameters.term === '') {
      reelService
        .query({
          status: ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status) ?
            $stateParams.status : 'scheduled,drafted,posted,live',
          limit: 300,
          sort: 'createdAt(d)',
          'hide-test-shows': false
        })
        .then(function(reels) {
          $scope.reels = reels.items.filter(function(reel) {
            if (reel.showProducts && reel.showProducts.length) {
              influencerService.getById(reel.influencerId).then(function(influencer) {
                reel.influencerName = influencer.preferredName;
                reel.userId = influencer.userId;
              });
              $scope.loading = false;
              return true;
            }
          });
        })
        .finally(function() {
          $scope.loading = false;
        });
    }
  }

  $scope.statusChanged = function() {
    $scope.loading = true;
    if ($scope.parameters.term && ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
      search();
    } else {
      getLastReels();
    }
  };

  $scope.keypress = function($event) {
    if ($event.which === 13) {
      if (!_.isEqual($scope.parameters, usedParameters)) {
        $scope.loading = true;
      }
      if ($scope.parameters.term && ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
        search();
      } else {
        getLastReels();
      }
    }
  };
}

module.exports = AdminReelsController;
