/* globals $ */

// @ngInject
function ImageZoomDirective($rootScope, platformService) {
  var linker = function($scope, element, attrs) {

    //Will watch for changes on the attribute
    attrs.$observe('zoomImage', function() {
      $rootScope.removeZoom();
    });
    $scope.$watch(function() {
      return element.parent().attr('class');
    }, unlinkZoom);

    function linkElevateZoom() {
      //Check if its not empty
      if (!attrs.zoomImage) {
        return;
      }
      $rootScope.fullscreenZoom.show({
        image: {
          src: attrs.zoomImage,
          preloadSrc: attrs.lazySrc
        }
      });
      return;
    }

    var touchStartPosition;

    function touchStart(e) {
      if (!attrs.zoomImage) {
        return;
      }
      touchStartPosition = {
        x: e.originalEvent.touches[0].clientX,
        y: e.originalEvent.touches[0].clientY
      };
    }

    function touchEnd(e) {
      if (!attrs.zoomImage) {
        return;
      }
      var touchEndPosition = {
        x: e.originalEvent.changedTouches[0].clientX,
        y: e.originalEvent.changedTouches[0].clientY
      };
      if (
        touchStartPosition &&
        touchEndPosition &&
        (
          Math.abs(touchEndPosition.x - touchStartPosition.x) > 20 ||
          Math.abs(touchEndPosition.y - touchStartPosition.y) > 20
        )
      ) {
        // finger is sliding, not a tap
        return;
      }
      $rootScope.fullscreenZoom.show({
        image: {
          src: attrs.zoomImage,
          preloadSrc: attrs.lazySrc
        }
      });
    }

    function unlinkZoom() {
      $rootScope.removeZoom();
      $(element).parent().css({
        cursor: ''
      });
    }

    if (platformService.isMobile()) {
      element.bind('touchstart', touchStart, {passive: true});
      element.bind('touchend', touchEnd);
    } else {
      element.bind('click', linkElevateZoom);
    }
  };
  return {
    link: linker,
    restrict: 'EA'
  };
}

module.exports = ImageZoomDirective;
