var sharedBusinessRules = require('shared-business-rules');
var _ = require('lodash-core');

// @ngInject
function AdminPartnerNexusController($scope, $state, $stateParams, fulfillerService, formsService, userService) {
  $scope.form = {};

  var formPromise = ($scope.formPromise = formsService.promiseHandler());

  function loadPartner() {
    var partnerPromise = fulfillerService.getByIdWithoutMemoize(
      $stateParams.id
    );

    partnerPromise.then(function(data) {
      $scope.form.nexus = data ? data : [];
      $scope.form.nexus.taxNexus.push({
        country: 'US',
        state: '',
        city: '',
        zip: '',
        street: '',
        notSaved: true
      });
      $scope.savingData = false;
    });
  }
  loadPartner();

  $scope.states = (function() {
    var states = sharedBusinessRules.states;
    var list = [];
    for (var code in states) {
      list.push({
        code: code,
        name: states[code].name
      });
    }
    return list;
  })();

  $scope.addNexus = function() {
    $scope.form.nexus.taxNexus.push({
      country: 'US',
      state: '',
      city: '',
      zip: '',
      street: '',
      notSaved: true
    });
  };

  $scope.removeState = function(id) {
    $scope.form.nexus.taxNexus.splice(id, 1);
  };

  // watch if taxNexus has or not brand nexus to update
  $scope.$watchCollection('form.nexus.taxNexus', function(newValue, oldValue) {
    if (newValue !== undefined && newValue !== oldValue) {
      // set available to validate form if taxNexus empty
      $scope.form.$error = {};
      $scope.form.$valid = true;
    }
  });

  $scope.isStateAvailable = function(state, nexus) {
    return $scope.form.nexus && !!_.find($scope.form.nexus.taxNexus, function(taxNexus) {
      return taxNexus !== nexus && taxNexus.state === state;
    });
  };

  $scope.save = function() {
    $scope.savingData = true;
    $scope.form.nexus.id = $stateParams.id;
    var dataToUpdate = {};
    var user = userService.getUser();

    dataToUpdate.id = $scope.form.nexus.id;
    dataToUpdate.lastModifiedBy = user && user.email;
    dataToUpdate.modifiedAt = new Date().getTime();
    dataToUpdate.orchardMileReporting = $scope.form.nexus.orchardMileReporting;
    dataToUpdate.taxNexus = _.filter($scope.form.nexus.taxNexus, function(taxNexus) {
      return !!taxNexus.country && !!taxNexus.state;
    });
    dataToUpdate.taxNexus.forEach(function(taxNexus) {
      delete taxNexus.notSaved;
    });

    formPromise.handle(
      fulfillerService.update(dataToUpdate).then(function() {
        $scope.savingData = false;
        $state.reload();
      })
    );
  };
}

module.exports = AdminPartnerNexusController;
