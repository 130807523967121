var _s = require('underscore.string');
var categoryHelper = require('shared-business-rules').categories;

// @ngInject
function ProductImageDirective(config, productService, $parse, $window,
  viewportService) {
  var linker = function($scope, element, attrs) {

    var getProduct = function() {
      return attrs.currentProduct ? $parse(attrs.currentProduct)($scope) : $scope.product;
    };

    var getStyleId = function() {
      var product = attrs.currentProduct ? $parse(attrs.currentProduct)($scope) : $scope.product;
      if (product && product.styleId) {
        return product.styleId;
      }
      if (attrs.currentProduct) {
        return;
      }
      var style = $scope.style;
      if (style && style.id) {
        return style.id;
      }
      return $scope.styleId;
    };

    var pad = attrs.pad ? $parse(attrs.pad)($scope) : true;
    var quality = attrs.quality ? $parse(attrs.quality)($scope) : config.images.defaults.quality;

    $scope.zoomeable = attrs.zoomeable ? $parse(attrs.zoomeable)($scope) : false;
    $scope.quickView = attrs.quickView ? $parse(attrs.quickView)($scope) : false;
    $scope.lowResolutionPreview = attrs.lowResolutionPreview ? $parse(attrs.lowResolutionPreview)($scope) : false;
    $scope.onlyPreview = attrs.onlyPreview ? $parse(attrs.onlyPreview)($scope) : false;

    $scope.blankImage = attrs.blankImage ? $parse(attrs.blankImage)($scope) : true;

    // $scope.imageMicrodata = attrs.microdata ? $parse(attrs.microdata)($scope) : false;

    $scope.getAltText = function() {
      var altText = 'Image of ';
      if ($scope.product && $scope.product.brand) {
        altText += ($scope.product.brand.name || '');
        if ($scope.product.tags) {
          var categoryNames = categoryHelper.getCategoriesFromTags($scope.product.tags);

          var category = categoryNames[categoryNames.length - 1];
          if (category) {
            try {
              altText += ' ' + category.charAt(0).toUpperCase() +
                category.slice(1);
            } catch (e) {

            }
          }
        }
      } else {
        altText += 'a product';
      }
      return altText;
    };

    $scope.getAriaLabelText = function() {
      var altText = 'Image of ';
      if ($scope.product && $scope.product.brand) {
        if ($scope.product.name) {
          altText += $scope.product.name;
        }
        altText += (' ' + $scope.product.brand.name || '');
        if ($scope.product.tags) {
          var categoryNames = categoryHelper.getCategoriesFromTags($scope.product.tags);
          altText += ' ' + categoryNames.join(', ');
        }
        if ($scope.product.minPrice && $scope.product.maxPrice) {
          if ($scope.product.minPrice !== $scope.product.maxPrice) {
            altText += '. From $' + ($scope.product.minPrice / 100) + ' to $' + ($scope.product.maxPrice / 100);
          } else {
            altText += '. $' + ($scope.product.minPrice / 100);

          }
        }
        if ($scope.product.sizes) {
          altText += ' - Available sizes: ' + $scope.product.sizes.join(', ');
        }
      } else {
        altText += 'a product';
      }

      return altText;
    };

    function setSizeForCurrentViewport() {
      var breakpoint = _s.capitalize(viewportService.current());
      var width = attrs['width' + breakpoint] || attrs.width;
      $scope.imageWidth = width ? $parse(width)($scope) : config.images.defaults.width;
      $scope.imageHeight = $scope.imageWidth * 1.5;
    }

    $scope.$on('viewportBreakpointChange', setSizeForCurrentViewport);
    setSizeForCurrentViewport();

    var zoomedImageEnabled = false;

    $scope.enableZoomedImage = function() {
      zoomedImageEnabled = true;
    };

    $scope.getZoomedImage = function() {
      if (!zoomedImageEnabled) {
        return;
      }
      if ($scope.quickView) {
        return;
      }
      var image;
      if (attrs.zoomedImage) {
        image = $parse(attrs.zoomedImage)($scope);
      } else if (attrs.image) {
        image = $parse(attrs.image)($scope);
      } else {
        var product = getProduct();
        if (product) {
          if (product.image) {
            image = product.image;
          } else {
            var images = productService.getImages(product);
            image = images[0];
          }
        }
      }
      if (!image) {
        return undefined;
      }
      if (typeof image === 'string' && image.slice(0, 4) === 'http') {
        // image URL is absolute
        return image;
      } else {
        var zoomedSize = {
          width: $window.innerWidth * viewportService.chooseForCurrent({
            xs: 3,
            sm: 2,
            else: 1
          })
        };
        return productService.getImageUrl(image.path, {
          width: zoomedSize.width,
          availableSizes: image.availableSizes,
          pad: false
        });
      }
    };

    $scope.getImageUrl = function(type) {
      type = type || 'image';
      var image;
      var product;
      if (attrs[type]) {
        image = $parse(attrs[type])($scope);
      } else {
        product = getProduct();
        if (product) {
          if (product[type]) {
            image = product[type];
          } else {
            var images = productService.getImages(product);
            image = images[0];
          }
        }
      }
      if (!image) {
        if (type !== 'image') {
          return;
        }
        return productService.getBlankImageUrl();
      }
      if (typeof image === 'string' && image.slice(0, 4) === 'http') {
        // image URL is absolute
        return image;
      } else {
        var imageVersion = {
          url: image.path || image, // fallback for product list url
          availableSizes: image.availableSizes,
          width: $scope.imageWidth,
          pad: pad,
          quality: quality
        };
        if (product && product.styleId && type === 'image') {
          imageVersion.styleId = product.styleId;
          setTimeout(function() {
            productService.styleImageShown(imageVersion);
          }, 200);
        }
        var fullUrl = productService.getImageUrl(imageVersion.url, {
          width: imageVersion.width,
          availableSizes: imageVersion.availableSizes,
          pad: imageVersion.pad,
          quality: imageVersion.quality,
          devicePixelRatio: 1
        });
        imageVersion.fullUrl = fullUrl;
        return fullUrl;
      }
    };

    $scope.getInitialImageUrl = function() {
      if (!$scope.blankImage) {
        return $scope.getImageUrl();
      }
      return productService.getBlankImageUrl();
    };

    $scope.getLowResolutionPreviewImageUrl = function() {
      var styleId = getStyleId();
      if (!styleId) {
        return;
      }
      var cachedImage = productService.getCachedStyleImage(styleId);
      if (!cachedImage || cachedImage.width >= $scope.imageWidth) {
        return;
      }
      return cachedImage.fullUrl;
    };
  };

  return {
    link: linker,
    templateUrl: '/views/partials/product-image.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ProductImageDirective;
