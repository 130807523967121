var _ = require('lodash-core');
var sharedBusinessRules = require('shared-business-rules');

/* globals $, angular */
/* jshint maxparams: 35 */

// @ngInject
function RootController($rootScope, $state, $stateParams, $uibModal, $transitions, config, globalKeysService,
                        userService, orderService, platformService, initializerService, canonicalizerService,
                        cartService, authenticationModalService, $timeout, $location, headerLinksService,
                        shopModalService, viewportService, metadataService, globalizationService, campaignService) {

  $rootScope.environmentAlternativeName = config.alternativeEnvironmentName ? config.environment : '';
  $rootScope.bodyClassesProviders = $rootScope.bodyClassesProviders || {};
  $rootScope.iframeUrl = '';
  $rootScope.widgetConfig = {};
  $rootScope.isWEBPCapable = false;
  $rootScope.loadHamburger = function() {
    if (!$rootScope.hamburgerLoaded) {
      $rootScope.hamburgerLoaded = true;
      setTimeout(function() {
        $rootScope.hamburger.toggle();
      }, 200);
    }
  };
  $rootScope.isDesktopView = function() {
    return !viewportService.is('<md');
  };
  $rootScope.isMobileView = function() {
    return viewportService.is('<sm');
  };
  $rootScope.isTabletView = function() {
    return !$rootScope.isDesktopView() && !$rootScope.isMobileView();
  };

  globalizationService.initialize();
  window.isAutomatedBrowser = platformService.isAutomatedBrowser();
  window.isBot = platformService.isBot();
  if (!$rootScope.appIsReady) {
    $rootScope.appIsReady = true;
  }
  platformService.testWebP(function(support) {
    $rootScope.isWEBPCapable = support;
  });
  $rootScope.initIframe = function() {
    if (userService.isLoggedIn() && !$location.search().hasOwnProperty('srtoken')) {
      userService.logout();
    }
    $rootScope.bodyClassesProviders.loadIframe = function() {
      return 'iframe-mode';
    };

    var receiveMessage = function(event) {
      var widgetData = {};
      try {
        widgetData = JSON.parse(event.data);
      } catch (e) {
      }
      if (widgetData.action === 'config') {
        $rootScope.widgetConfig = widgetData.content;
      }
    };
    window.addEventListener('message', receiveMessage, false);

    $rootScope.noPageHeader = true;

    $rootScope.noPageFooter = true;
    $rootScope.iframeUrl = document.referrer ? document.referrer : window.parent.location;
    shopModalService.loaded();
  };
  $rootScope.shouldCleanCart = function() {
    return !shopModalService.cartFeature();
  };
  $rootScope.isOnWidget = function() {
    return $rootScope.iframeUrl !== '';
  };

  initializerService.initializeAll();
  if (window.location !== window.parent.location && !window.parent.location.search.includes('abet=')) {
    $rootScope.initIframe();
  }

  // make feature flags available from templates
  $rootScope.featureFlags = config.featureFlags || {};

  // Flag for modal instances
  $rootScope.modalStack = 0;
  $rootScope.addModalToStack = function() {
    $rootScope.modalStack++;
  };

  $rootScope.removeModalFromStack = function() {
    $rootScope.modalStack--;
  };

  $rootScope.keypressHandler = function(event, selector) {
    if (event.keyCode === 13) {
      $timeout(function() {
        angular.element(selector).triggerHandler('click');
      });
    }
  };

  $rootScope.$on('$locationChangeStart', function() {
    canonicalizerService.normalizeCurrentUrl();
  });

  globalKeysService.on('keydown', function(e) {
    setTimeout(function() {
      $rootScope.$evalAsync(function() {
        $rootScope.$broadcast('globalKeydown', e);
      });
    }, 0);
  });

  // subscribe to an event emitter restricted to an angular $scope lifetime
  $rootScope.$onEmitter = function(emitter, name, handler) {
    var $currentScope = this;
    emitter.on(name, handler);
    $currentScope.$on('$destroy', function() {
      emitter.removeListener(name, handler);
    });
  };

  $rootScope.metadata = {
    breadcrumbs: {path: []}
  };
  orderService.cleanPlacedOrder();

  window.goToProductDetailsPage = function(styleId) {
    $state.go('root.product-permalink', {
      id: styleId
    });
  };

  window.internalLink = function(path) {
    window.placeExpander();
    $location.path(path);
  };

  window.emptyInternalLink = function(path) {
    window.placeExpander();
    $location.path(path).search({});
  };

  // show cart if on querysting
  if (/^[^?]*\?([^&]+&)*cart=true(&|$)/.test(window.location.toString())) {
    if (!cartService.isEmpty()) {
      setTimeout(function() {
        $rootScope.modal.show('cart', {
          trigger: 'cart-in-url',
          bagActive: true
        });
      }, 1);
    }
  }

  // show login modal if on querysting
  if (/^[^?]*\?([^&]+&)*login=true(&|$)/.test(window.location.toString())) {
    setTimeout(function() {
      $rootScope.modal.show('login', {
        trigger: 'login-in-url'
      });
    }, 1);
  }

  // ---- SCOPE FUNCTIONS ----

  $rootScope.showHomeText = function() {
    return !($rootScope.menu && $rootScope.menu.visible);
  };

  $(window).scroll(_.debounce(function() {
    if ($rootScope.menu) {
      $rootScope.menu.hide();
      headerLinksService.closeOnScroll();
      $rootScope.$evalAsync();
    }
  }, 250, {leading: true, trailing: false}));

  $transitions.onStart({}, function(trans) {
    if (platformService.isMobile()) {
      $rootScope.showLoadingOverlay = true;
    }
    trans.promise.finally(function() {
      if ($state && $state.current.redirectTo) {
        $state.go($state.current.redirectTo, $state.params);
      }

      metadataService.clear();

      $rootScope.pagination = {};

      if ($rootScope.menu) {
        $rootScope.menu.hide();
      }
      $rootScope.hideSearchPreview();
      $rootScope.hideSearchPreviewMobile();
      $rootScope.removeZoom();

      orderService.cleanPlacedOrder();

      platformService.isIOS();

      setTimeout(function() {
        document.title = metadataService.getTitle();
      }, 1);
      $rootScope.showLoadingOverlay = false;

      campaignService.loadCampaignOnRoot();
      campaignService.saveUTMParameters();

      authenticationModalService.initialize();

      // Fix sticky header
      setTimeout(function(){
        if (platformService.isPhone()) {
          $('.sticky-header-replacement')
            .css('height', config.featureFlags.saleBanner.enabled ? '147px' : '100px')
            .css('width', '100%');
          $('#ssh1')
            .css('height', config.featureFlags.saleBanner.enabled ? '147px' : '100px')
            .css('width', '100%');
          $('.sticky-header-shadow')
            .css('height', config.featureFlags.saleBanner.enabled ? '147px' : '100px')
            .css('width', '100%');
        } else {
          $('.sticky-header-replacement')
            .css('height', config.featureFlags.saleBanner.enabled ? '224px' : '110px')
            .css('width', '100%');
          $('#ssh1')
            .css('height', config.featureFlags.saleBanner.enabled ? '224px' : '110px')
            .css('width', '100%');
          $('.sticky-header-shadow')
            .css('height', config.featureFlags.saleBanner.enabled ? '224px' : '110px')
            .css('width', '100%');
        }
      }, 50);
    });
  });
  $rootScope.isHomePage = function() {
    return $state.current.name === 'root.home';
  };

  $rootScope.isCheckout = function() {
    // if it's a checkout route but not the confirmation step
    return $state.current.name.indexOf('root.checkout') === 0 && $state.current.name !== 'root.checkout.confirmation';
  };

  $rootScope.isTheMile = function() {
    return $state.current.name.indexOf('root.the-mile') === 0 || $state.current.name === 'root.influencer' ||
      $state.current.name === 'root.giveaway-rules' || $state.current.name === 'root.christie-giveaway-rules';
  };

  $rootScope.isShow = function() {
    return ($state.params && $state.params.show) || ($rootScope.modalShow && $rootScope.modalShow.status);
  };

  $rootScope.isMyAccount = function() {
    return $state.current.name.indexOf('root.account') === 0;
  };
  $rootScope.isOccasion = function() {
    return !!($state.params && $state.params.name && $state.params.name.indexOf('occasions/') !== -1);
  };

  $rootScope.isStory = function() {
    return $state.current.name.indexOf('root.stories') === 0;
  };
  $rootScope.isHoliday = function() {
    return $location.path().indexOf('holiday') > -1;
  };
  $rootScope.isDesigner = function() {
    return $state.current.name.indexOf('root.brands') === 0;
  };
  $rootScope.isShopby = function() {
    return $state.current.name.indexOf('root.shopby') === 0;
  };

  $rootScope.isShop = function(state) {
    var stateToCheck = state || $state.current.name;
    return stateToCheck.indexOf('root.shop') >= 0;
  };
  $rootScope.isPages = function(state) {
    var stateToCheck = state || $state.current.name;
    return stateToCheck.indexOf('root.pages') >= 0;
  };
  $rootScope.isSale = function(state) {
    var stateToCheck = state || $state.current.name;
    return stateToCheck.indexOf('root.sale') >= 0;
  };
  $rootScope.isBrandProducts = function(state) {
    var stateToCheck = state || $state.current.name;
    return stateToCheck.indexOf('root.brand-products') >= 0;
  };
  $rootScope.isSearch = function(state) {
    var stateToCheck = state || $state.current.name;
    return stateToCheck.indexOf('root.search') >= 0;
  };
  $rootScope.isPDP = function(state) {
    var stateToCheck = state || $state.current.name;
    return stateToCheck.indexOf('root.product') >= 0;
  };
  $rootScope.isCategory = function(cat) {
    return (($state.current.name.indexOf('root.shop') === 0) && $stateParams.category1 === cat);
  };

  $rootScope.hasPLP = function() {
    return $rootScope.isShop() ||
      $rootScope.isSale() ||
      $rootScope.isBrandProducts() ||
      $rootScope.isShopby() ||
      $rootScope.isOccasion() ||
      $rootScope.isSearch();
  };

  $rootScope.getRobots = function() {
    return metadataService.getRobots();
  };


  $rootScope.isCheckoutConfirmation = function() {
    // if it's a checkout  confirmation step
    return $state.current.name === 'root.checkout.confirmation';
  };

  var dropdownTimer;
  $rootScope.cancelDropdowns = function() {
    $timeout.cancel(dropdownTimer);
  };
  $rootScope.hideDropdowns = function() {
    $timeout.cancel(dropdownTimer);
    $rootScope.menu.hide();
  };
  $rootScope.hideSearchPreview = function() {
    $rootScope.searchPreview = false;
  };
  $rootScope.showSearchPreviewMobile = function() {
    $rootScope.isOpenSearchMobile = true;
    if (window.scrollY === 0) {
      $('.search-preview-mobile .drop-down-content')
        .css('top', config.featureFlags.saleBanner.enabled ? '140px' : '80px');
      document.body.style.overflow = 'hidden';
    } else {
      $('.search-preview-mobile .drop-down-content')
        .css('top', config.featureFlags.saleBanner.enabled ? '110px' : '50px');
    }
    var searchPreviewMobileElement = $('.search-preview-mobile .search-term');
    searchPreviewMobileElement.on('focus');
    searchPreviewMobileElement.focus();
  };
  $rootScope.hideSearchPreviewMobile = function() {
    $rootScope.isOpenSearchMobile = false;
  };
  $rootScope.showSearchPreview = function() {
    $rootScope.searchPreview = true;
    var deltaTop = 50;
    if (window.scrollY === 0) {
      deltaTop = 100;
    }
    $('.search-preview').css('top', (window.scrollY + deltaTop) + 'px');
  };
  $rootScope.showDropdown = function(name, checkOpacity) {
  $rootScope.headerCategoryMenuLoaded = true;

    $timeout.cancel(dropdownTimer);
    dropdownTimer = $timeout(function() {
      if (checkOpacity) {
        var visibleDropdowns = $('.dropdown-nav').filter(function() {
          return $(this).css('opacity') > 0.5;
        }).length;
        if (visibleDropdowns === 0) {
          $rootScope.menu[name].hide();
          return -1;
        }
      }

      var headerHeight = angular.element('.site-header')[0].offsetHeight;
      var diff = angular.element($('.site-header')).offset().top - window.pageYOffset;
      if (diff < headerHeight) {
        $rootScope.menu[name].show();
      }
    }, 500);
  };

  $rootScope.closeModals = function() {
    if ($rootScope.modal) {
      $rootScope.modal.hide();
    }
    setTimeout(function() {
      $('.modal').trigger('click');
    }, 1);
  };

  $rootScope.removeZoom = function() {
    //First I will fix a elevatezoom problem when the image change.
    var zoomContainer = angular.element('.zoomContainer');
    if (zoomContainer.length) {
      zoomContainer.remove();
    }

    var fullscreenZoom = $rootScope.fullscreenZoom;
    if (fullscreenZoom && fullscreenZoom.isVisible) {
      fullscreenZoom.hide();
    }
  };

  $rootScope.getUser = function() {
    var user = userService.getUser();
    if (user) {
      user.isAdmin = !user.anonymous && user.roles && user.roles.indexOf('admin') >= 0;
    }
    return user;
  };

  $rootScope.adminHasPermission = function (role) {
    var user = userService.getUser();
    return user.isAdmin && user.roles.indexOf(role) >= 0;
  };

  $rootScope.getCountryName = function(code) {
    if (!code) {
      code = 'us';
    }
    return _.find(sharedBusinessRules.countries.getArray(), {code: code.toLowerCase()}).name;
  };

  $rootScope.getCountryCode = function() {
    return 'US';
  };

  $rootScope.inView = function($inview, $inviewpart, $event, className) {
    if ($inview && $inviewpart === 'top') {
      $($event.inViewTarget).addClass(className);
    }
  };

  $rootScope.setBreadcrumbPath = function(path) {
    $rootScope.metadata.breadcrumbs.path = path;

    if (!$rootScope.metadata.breadcrumbs.taken) {
      $rootScope.metadata.breadcrumbs.taken = true;
      // this = caller $scope, so the breadcrumb is reset when caller is destroyed
      this.$on('$destroy', function() {
        $rootScope.metadata.breadcrumbs.path = [];
        $rootScope.metadata.breadcrumbs.taken = false;
      });
    }
  };

  $rootScope.setNoRobots = function() {
    metadataService.setRobots('noindex,nofollow');
  };

  $rootScope.setPageMetadata = function(metadata) {
    metadataService.setPage(metadata);
  };

  $rootScope.setCanonicalUrl = function(options) {
    canonicalizerService.setCanonicalUrl(options);
  };

  $rootScope.getBodyClasses = function() {
    return _(_.keys($rootScope.bodyClassesProviders))
      .map(function(key) {
        return $rootScope.bodyClassesProviders[key]();
      })
      .flatten()
      .compact()
      .value();
  };

  $rootScope.showRegisterStandaloneModal = function(type, options) {
    var $modalScope = $rootScope.$new(false);
    $modalScope.type = type || 'register';
    $modalScope.email = options && options.email;
    $modalScope.trigger = options && options.trigger || 'header';
    $modalScope.itemForLater = options && options.itemForLater || {};

    $modalScope.modal.registerStandaloneInstance = $uibModal.open({
      templateUrl: '/views/partials/modals/register-standalone.html',
      controller: 'RegisterStandaloneController',
      windowClass: 'register-standalone',
      scope: $modalScope,
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    });
    $rootScope.openSearch = function(){
      $rootScope.search = !$rootScope.search;
    };

    $rootScope.addModalToStack();
    $modalScope.modal.registerStandaloneInstance.result.finally($rootScope.removeModalFromStack);
  };
}

module.exports = RootController;
