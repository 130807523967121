// @ngInject
function CartEmptyController($scope, cartService, shopModalService) {
  $scope.cartData = cartService.getData();
  function getForLater() {
    return $scope.cartData.items.filter(cartService.isForLater);
  }
  function getItems() {
    return $scope.cartData.items;
  }
  $scope.status = '';
  if (getItems().length === 0) {
    $scope.status = 'empty';
  } else if (getForLater().length === getItems().length) {
    $scope.status = 'empty';
  } else {
    $scope.status = 'soldout';
  }
  if ($scope.isOnWidget()) {
    $scope.closeShopModal = function() {
      shopModalService.closeShopModal();
    };
  }
}

module.exports = CartEmptyController;
