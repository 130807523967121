var format = require('libphonenumber-js').format;

// @ngInject
function PhoneNumberFilter() {
  return function(input) {
    if (input) {
      return format(input.replace(/(\+1|[^\d])/g, ''), 'US', 'International');
    }
    return input;
  };
}

module.exports = PhoneNumberFilter;
