var _s = require('underscore.string');

/* globals $, angular */
/* jshint maxparams: 10 */

// @ngInject
function ModalController($scope, $rootScope, $timeout, $transitions, analyticsService, bodyScrollService,
                         cartService, platformService, formsService, $uibModal) {


  var modal = $rootScope.modal = {
    isVisible: false,
    isFullyClosed: true,
    defaultAutoHide: 2000,

    show: function(name, options) {
      var self = this;

      if ($scope.hamburger) {
        $scope.hamburger.close();
      }

      if (name === 'register' || name === 'login' || name === 'forgot-password') {
        $rootScope.showRegisterStandaloneModal(name, options);
        return;
      }

      this.instanceData = this.instanceData || {};
      modal.name = name;
      this.instanceData.options = options || {};
      this.cancelAutoHide();
      modal.isFullyClosed = false;
      if (this.fullyClosedTimeout) {
        clearTimeout(this.fullyClosedTimeout);
        delete this.fullyClosedTimeout;
      }

      $timeout(function() {
        modal.isVisible = true;
        $scope.modalVisible = true;
        platformService.fixSafariScroll('ol, ul');
        if (options && options.autoHide) {
          var delay = typeof options.autoHide === 'number' ?
            options.autoHide : self.defaultAutoHide;
          self.hidePromise = $timeout(function() {
            self.hide();
          }, delay);
        }
      }, 50);

      var checkCookie = setInterval(function() {
        var cookiesElement = $('#onetrust-pc-sdk');
        if (cookiesElement.length > 0 && cookiesElement[0].style) {
          var value = cookiesElement[0].style.display;
          if (value === '') {
            $('.modal-footer').addClass('active');
          } else {
            $('.modal-footer').removeClass('active');
            clearInterval(checkCookie);
          }
        } else {
          $('.modal-footer').removeClass('active');
          clearInterval(checkCookie);
        }
      }, 1000);

      //clear inputs
      $('.modal-panel input').val('');
      $('.modal-panel [track-focus]').removeClass('visited'); //triggering unvisit event doesn't work
      var formPromise = $scope.formPromise = formsService.promiseHandler();
      formPromise.reset();

      bodyScrollService.lock();

      if (name === 'cart') {
        // trigger a cart reload when opening the cart modal
        cartService.reload(true);
        cartService.setBagActive(options.bagActive);
        if ($rootScope.featureFlags.shopRunner.enabled) {
          var checkShopRunner = setInterval(function() {
            /*jshint camelcase: false */
            if (window.sr_updateMessages) {
              window.sr_updateMessages();
              clearInterval(checkShopRunner);
            }
          }, 100);
        }
      }

      analyticsService.track(_s.titleize(name) + ' Modal Shown');
    },
    hide: function() {
      if (!this.isVisible && !$scope.modalVisible) {
        return;
      }
      var self = this;

      angular.element(':focus').blur();
      bodyScrollService.unlock(true);
      this.cancelAutoHide();
      delete this.instanceData;
      this.isVisible = false;
      $scope.modalVisible = false;
      this.fullyClosedTimeout = setTimeout(function() {
        if (!self.isVisible) {
          $scope.$apply(function() {
            // the modal should be fully closed now
            self.isFullyClosed = true;
            self.name = null;
          });
        }
        // 250ms is a safe timeout that ensures hide animation is complete
      }, 250);

      analyticsService.track(_s.titleize(modal.name) + ' Modal Hidden');
    },
    cancelAutoHide: function() {
      if (this.hidePromise) {
        $timeout.cancel(this.hidePromise);
        delete this.hidePromise;
      }
    }
  };

  $scope.show = function(name, options) {
    modal.show(name, options);
  };

  $scope.hide = function() {
    modal.hide();
  };

  $scope.cancelAutoHide = function() {
    modal.cancelAutoHide();
  };

  $scope.$on('globalKeydown', function(e, keyboardEvent) {
    if (keyboardEvent.keyCode === 27) {
      modal.hide();
    }
  });

  $('.modal-panel').on('mousedown mousemove touchstart touchmove', '*', function() {
    modal.cancelAutoHide();
  });

  $transitions.onStart({}, function(trans) {
    trans.promise.finally(function() {
      if (!platformService.isMobile()) {
        return;
      }
      // on mobile modal covers most of the screen,
      // close it to show the new page behind it
      modal.hide();
    });
  });

  $scope.$onEmitter(cartService.events, 'add_to_cart', function(params) {
    if (
      ['shop-now', 'cart', 'product-list-page-save-for-later', 'quick-view-save-for-later', 'quick-view-checkout']
        .indexOf(params.source) !== -1
    ) {
      return;
    }
    if (!$rootScope.isShow()) {
      $scope.modal.show('cart', {
        autoHide: true,
        trigger: 'product-added',
        bagActive: true
      });
    } else {
      cartService.setStep('cart');
      $rootScope.cartStep = cartService.getStep;
      $scope.instance = $uibModal.open({
        templateUrl: '/views/partials/modals/the-mile-checkout.html',
        windowClass: 'show-quick-view-modal',
        //!Here I need to add a class if its mobile -> call mobile!
        scope: $scope,
      });
      $scope.closeButton = function () {
        if (!$rootScope.placingOrder) {
          $rootScope.confirmation = false;
          $rootScope.preConfirmation = false;
          $scope.instance.dismiss();
        }
      };
    }
    $scope.justAdded = params.item;
    ensureAddedItemIsVisible(params.index);
    // just added for just a few
    $timeout(function() {
      $scope.justAdded = null;
    }, 300);
  });

  $scope.isJustAdded = function(item) {
    return $scope.justAdded === item;
  };

  function ensureAddedItemIsVisible(index) {
    setTimeout(function() {
      if (index === 0) {
        var listElement = document.querySelector('.cart .item-list');
        if (listElement) {
          listElement.scrollTop = 0;
        }
      } else {
        var li = document.querySelectorAll('.cart .just-added')[0];
        if (li) {
          li.scrollIntoView();
        }
      }
    }, 20);
  }


}

module.exports = ModalController;
