var _ = require('lodash-core');
var promises = require('../../async/promises');

/* globals Promise */
// @ngInject
function TranslationService($translate, apiService) {
  this.translate = function(param) {
    if (this.changingLanguage) {
      return this.changingLanguage.then(function() {
        return $translate(param);
      });
    } else {
      return $translate(param);
    }
  };

  this.translatePartiallyObjectMessage = function(obj, field, replacement) {
    if (this.changingLanguage) {
      return this.changingLanguage.then(function() {
        $translate(replacement).then(function(text) {
          obj[field] = obj[field].replace(replacement, text);
        });
      });
    } else {
      $translate(replacement).then(function(text) {
        obj[field] = obj[field].replace(replacement, text);
      });
    }
  };

  this.translateObject = function(obj) {
    var self = this;
    _.forEach(Object.entries(obj), function(entry) {
      self.translate(entry[1]).then(function(text) {
        obj[entry[0]] = text;
      });
    });
  };

  this.changeLanguage = function(lang) {
    var self = this;
    var resolver;
    this.changingLanguage = new Promise(function(resolve) {
      resolver = resolve;
    });
    $translate.use(lang).then(function() {
      resolver();
      delete self.changingLanguage;
    });
  };

  this.translateName = function(firstName, lastName, sourceLanguage) {
    return promises.extend(
      apiService.post('/users/translate-name', {
        body: {
          firstName: firstName,
          lastName: lastName,
          sourceLanguage: sourceLanguage
        }
      })
    );
  };
}

module.exports = TranslationService;
