// @ngInject
function ValuePropositionController($scope, $rootScope, $cookies, userService, viewportService) {
  $scope.hide = function() {
    /*jshint sub:true*/
    $cookies['_vp'] = 1;
    $rootScope.closeModals();
    userService.dismissValueProposition();
  };

  $scope.$on('viewportBreakpointChange', function() {
    if (viewportService.is('<md')) {
      $scope.hide();
    }
  });
}

module.exports = ValuePropositionController;