var _ = require('lodash-core');
var EventEmitter = require('events').EventEmitter;
var promises = require('../../async/promises');
var cartManipulation = require('./cart/data-manipulation');

/* jshint maxparams: 13 */

// @ngInject
function OrderService(apiService, userService, cartService, $state, $rootScope, paymentService, $timeout, $cookies,
                      sessionStorageService, platformService, $stateParams, shopModalService) {

  this.events = new EventEmitter();

  this.validate = function(order) {
    var orderToValidate = _.cloneDeep(order);
    var user = userService.getUser();
    orderToValidate.userId = orderToValidate.userId || (!user.guest && user.id) || 'guest';
    var usingCart = !orderToValidate.items;
    if (usingCart) {
      orderToValidate.items = cartService.getData().items.filter(cartManipulation.isPurchasable);
      orderToValidate.fulfillers = cartService.getFulfillers();
    }
    if (!orderToValidate.shipping.contact.email) {
      orderToValidate.shipping.contact.email = user.email;
    }

    if ($rootScope.featureFlags.internationalShipping.enabled) {
      _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
        if (order.shipping.address.country.toLowerCase() === country.code) {
          if (country.state === 'not-required') {
            orderToValidate.shipping.address.state = order.shipping.address.city;
          }
        }
      });
    }

    _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
      if (order.billing.address.country.toLowerCase() === country.code) {
        if (country.state === 'not-required') {
          orderToValidate.billing.address.state = order.billing.address.city;
        }
      }
    });

    orderToValidate.shipping.address.state = orderToValidate.shipping.address
      .state.replace(new RegExp(orderToValidate.shipping.address.country + '-', 'i'), '');
    orderToValidate.billing.address.state = orderToValidate.billing.address
      .state.replace(new RegExp(orderToValidate.billing.address.country + '-', 'i'), '');

    return paymentService.prepareOrder(orderToValidate, true).then(function(preparedOrder) {
      return apiService.post('/orders/validate', {
        body: JSON.stringify(preparedOrder, function(key, value) {
          if (key === '$$hashKey') {
            return undefined;
          }
          return value;
        })
      });
    });
  };

  this.place = function(order) {
    var self = this;
    var user = userService.getUser();
    order.userId = order.userId || (!user.guest && user.id) || 'guest';
    if (order.shipping.address.country && order.shipping.address.country.toLowerCase() !== 'us') {
      order.isInternationalOrder = true;
      if ($rootScope.featureFlags.internationalShipping.enabled) {
        _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
          if (order.shipping.address.country.toLowerCase() === country.code) {
            if (country.state === 'not-required') {
              order.shipping.address.state = order.shipping.address.city;
            }
          }
        });
      }
    }

    _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
      if (order.billing.address.country.toLowerCase() === country.code) {
        if (country.state === 'not-required') {
          order.billing.address.state = order.billing.address.city;
        }
      }
    });

    order.shipping.address.state = order.shipping.address
      .state.replace(new RegExp(order.shipping.address.country + '-', 'i'), '');
    order.billing.address.state = order.billing.address
      .state.replace(new RegExp(order.billing.address.country + '-', 'i'), '');

    if (order.userId === 'guest' && $rootScope.subscribeNewsletter) {
      userService.subscribeTo('newsletter', 'checkout');
    }
    var usingCart = !order.items;
    if (usingCart) {
      order.items = cartService.getData().items.filter(cartManipulation.isPurchasable);
      order.fulfillers = cartService.getFulfillers();
    }
    if (!order.shipping.contact.email) {
      order.shipping.contact.email = user.email;
    }

    this._addOrderMetadata(order);

    if (order.userId === 'guest') {
      $rootScope.preparedOrderPromiseToSave = paymentService.prepareOrder(order);
    }

    if ($cookies.get('sr_token')) {
      order.srToken = $cookies.get('sr_token');
    }

    var placeOrderPromise = paymentService.prepareOrder(order)
      .then(function(preparedOrder) {
        return apiService.post('/orders', {
          body: JSON.stringify(preparedOrder, function(key, value) {
            if (key === '$$hashKey') {
              return undefined;
            }
            return value;
          })
        });
      });

    placeOrderPromise
      .then(function(placedOrder) {
        self.events.emit('orderPlaced', placedOrder, order);
        self.events.emit('purchase', placedOrder);
        if (usingCart) {
          cartService.empty();
        }
        shopModalService.updateCart([]);
        paymentService.afterOrderPlaced(order);
        if (user.anonymous) {
          // this is to remove the checkout option from the potential user
          // checkout option is used to say that the potential user was attempting to checkout
          userService.savePotentialUser(order.shipping.contact.email, {checkout: false});
        }
      })
      .catch(function(err) {
        // ensure a $digest
        $timeout(function() {
        });
        throw err;
      });

    return placeOrderPromise;
  };

  this.get = function(id, token) {
    return promises.extend(apiService.get('/orders/{id}', {
      pathParams: {
        id: id
      },
      urlParams: {
        token: token
      }
    }));
  };

  this.getByUser = function(id) {
    return promises.extend(apiService.get('/users/{id}/orders', {
      pathParams: {
        id: id
      }
    }));
  };

  this.cleanPlacedOrder = function() {
    if ($state.current.name !== 'root.checkout.confirmation') {
      delete $rootScope.placedOrderNumber;
      delete $rootScope.placedOrderToken;
    }
  };

  this.returnItems = function(order, items, returnReason, onCancellation, onReturnRequested) {
    var item = Array.isArray(items) ? items[0] : items;
    //Get the updated order to avoid errors caused by staying on the page
    //and making a return request after the order was processed
    var orderPromise = this.get(order.number, $stateParams.token);
    var self = this;
    return orderPromise.then(function(updatedOrder) {
      var isFulfilled = updatedOrder.events.find(function(updatedEvent) {
        return updatedEvent.type === 'fulfiller-order' && updatedEvent.provider === item.partner.id;
      });
      if (!isFulfilled) {
        var event = {
          type: 'item-cancelled',
          details: {
            item: {
              brand: item.brand,
              partner: item.partner,
              productId: item.productId,
              quantity: item.quantity,
              sku: item.sku,
              style: item.style,
              hash: item.hash
            },
            quantity: item.quantity - (item.undeliverableQuantity || 0),
            reason: 'Customer Request'
          }
        };
        return self.addEvent(updatedOrder, event).then(onCancellation);
      }
      return self.sendReturnRequest(updatedOrder, items, returnReason).then(onReturnRequested);
    });
  };

  this.sendReturnRequest = function(order, items, returnReason) {
    if (!(items instanceof Array)) {
      items = [items];
    }
    return apiService.post('/orders/{number}/returns', {
      pathParams: {
        number: order.number
      },
      body: {
        items: items,
        reason: returnReason
      }
    });
  };

  this.process = function(orderNumber) {
    return promises.extend(apiService.post('/orders/{number}/process', {
      pathParams: {
        number: orderNumber
      }
    }));
  };

  this.forceAnalysis = function(orderNumber, provider) {
    return promises.extend(apiService.post('/orders/{number}/analysis', {
      pathParams: {
        number: orderNumber
      },
      body: {
        provider: provider
      }
    }));
  };

  this.generateStripeCreditCard = function(orderNumber) {
    return promises.extend(apiService.post('/orders/{number}/generate-credit-card', {
      pathParams: {
        number: orderNumber
      }
    }));
  };

  this.processRefund = function(refund) {
    var order = _.cloneDeep(refund.order);
    var event = _.cloneDeep(refund);
    delete event.order;
    delete event.selected;
    delete refund.selected;
    refund.details.processed = true;
    event.details.processed = true;
    var eventEmitter = this.events;
    return this.updateEvent(order, event).then(function() {
      eventEmitter.emit('refund', { order: order, event: event });
    });
  };

  this.addEvent = function(order, event) {
    return apiService.post('/orders/{number}/events', {
      pathParams: {
        number: order.number
      },
      body: event
    });
  };

  this.updateEvent = function(order, event) {
    return apiService.put('/orders/{number}/events/{eventId}', {
      pathParams: {
        number: order.number,
        eventId: event._id
      },
      body: event
    });
  };

  this.deleteRefund = function(orderNumber, event) {
    return apiService.delete('/orders/{number}/events/{eventId}', {
      pathParams: {
        number: orderNumber,
        eventId: event._id
      }
    });
  };

  this.deleteEvent = function(order, event) {
    return apiService.delete('/orders/{number}/events/{eventId}', {
      pathParams: {
        number: order.number,
        eventId: event._id
      }
    });
  };

  this.searchOrders = function(params) {
    var from;
    if (!params.term) {
      if (params.from) {
        from = params.from;
      } else {
        from = new Date();
        from.setMonth(from.getMonth() - 4);
      }
    }
    return promises.extend(apiService.get('/orders/search', {
      urlParams: {
        onlyCompletedOrders: params.onlyCompletedOrders,
        onlyUncompletedOrders: params.onlyUncompletedOrders,
        hideTestOrders: params.hideTestOrders,
        term: params.term,
        from: from,
        captured: params.onlyCaptured,
        refunded: params.onlyRefunded,
        refundedOnCard: params.onlyRefundedOnCard
      }
    }));
  };

  this.getOrdersWithoutShipping = function(date, notification) {
    return promises.extend(apiService.get('/orders/orders-without-shipping', {
      urlParams: {
        dueDate: date,
        notification: notification
      }
    }));
  };

  this.claimOrderForCurrentUser = function(orderNumber, token) {
    var user = userService.getUser();
    if (user.anonymous) {
      return promises.immediate({success: false});
    }
    return promises.extend(apiService.put('/orders/{number}/user', {
      pathParams: {
        number: orderNumber
      },
      body: {
        id: user.id,
        token: token
      }
    }));
  };

  this._addOrderMetadata = function(order) {
    order.metadata = order.metadata || {};
    if (platformService.isPhone()) {
      order.metadata.device = 'phone';
    } else if (platformService.isMobile()) {
      order.metadata.device = 'mobile';
    } else {
      order.metadata.device = 'desktop';
    }
    order.metadata.automated = platformService.isAutomatedBrowser();
    order.metadata.userAgent = platformService.getUserAgent();
    order.metadata.fullStory = platformService.getFulstorySessionId();
    order.metadata.sessionId = sessionStorageService.getSessionId();
    order.metadata.language = platformService.getLanguage();
    if ($rootScope.utmCampaign ||
      $rootScope.utmSource ||
      $rootScope.utmMedium) {
      order.metadata.utm = {
        'campaign': $rootScope.utmCampaign || '',
        'source': $rootScope.utmSource || '',
        'medium': $rootScope.utmMedium || '',
        'content': $rootScope.utmContent || ''
      };
    }
    if ($rootScope.iframeUrl !== '') {
      order.metadata.iframeUrl = $rootScope.iframeUrl;
    }
  };

  this.postPlacedOrderOpt = function (order) {
    this.events.emit('orderPlaced', order);
    this.events.emit('purchase', order);
    cartService.empty();
    shopModalService.updateCart([]);
    paymentService.afterOrderPlaced(order);
  };

  this.getItemPackages = function(order) {

    var packagesInfo = _.cloneDeep(order.packagesInfo);

    // has the packages on the order the specific list of items
    var hasSplittedItems = packagesInfo && packagesInfo.some(function(packageInfo) {
      return packageInfo.items !== undefined && packageInfo.items.length > 0;
    });

    if (hasSplittedItems) {
      // add only items data specified on the package from the order to the packageInfo
      packagesInfo = _.map(packagesInfo, function(packageInfo) {
        packageInfo.items = _.map(packageInfo.items, function(packedItem) {
          var item = _.find(order.items, function(orderItem) {
            return packedItem.productId === orderItem.productId &&
              packedItem.hash === orderItem.hash &&
              (packedItem.styleId || packedItem.style.id) === orderItem.style.id;
          });
          item = _.cloneDeep(item);
          item.quantity = packedItem.quantity;
          return item;
        });
        return packageInfo;
      });
    } else {
      // add all the items data of the fulfiller specified on the package from the order to the packageInfo
      packagesInfo = _.map(packagesInfo, function(packageInfo) {
        packageInfo.items = _.filter(order.items, function(orderItem) {
          return orderItem.partner.id === packageInfo.partnerId;
        });
        return packageInfo;
      });
    }

    // create packages with the unprocessed items
    var unprocessedItems = _.cloneDeep(order.items);
    _.forEach(packagesInfo, function(packageInfo) {
      _.forEach(packageInfo.items, function(packedItem) {
        var unprocessedItem = _.find(unprocessedItems, function(item) {
          return packedItem.productId === item.productId &&
            packedItem.hash === item.hash &&
            packedItem.sku === item.sku;
        });
        unprocessedItem.quantity -= packedItem.quantity;
      });
    });

    var unprocessedItemsByFulfiller = _(unprocessedItems)
      .filter(function(item) {
        return item.quantity > 0;
      })
      .groupBy(function(item) {
        return item.partner.id;
      })
      .value();

    for (var fulfiller in unprocessedItemsByFulfiller) {
      var processed = !!_.find(order.events, {type: 'fulfiller-order', provider: fulfiller});
      packagesInfo.push({
        partnerId: fulfiller,
        items: unprocessedItemsByFulfiller[fulfiller],
        status: processed ? 'processed' : 'processing'
      });
    }

    return packagesInfo;
  };

  this.getPaymentMethod = function(userId, paymentMethodId) {
    return promises.extend(apiService.get('/orders/payment-method',{
      urlParams: {
        'user-id': userId,
        'payment-method-id': paymentMethodId
      }
    }));
  };
}

module.exports = OrderService;
