// @ngInject
function AdminController($scope, brandService) {

  $scope.setNoRobots();

  brandService.getAll('name').then(function(brands) {
    $scope.brands = brands;
  });
}

module.exports = AdminController;
