/* jshint maxparams: 12 */

// @ngInject
function BannerBrandsDirective(config, $state, staticAssetService) {

  var linker = function ($scope) {
    var configBanner = config.featureFlags.theMileInfo;
    if (configBanner.enabled) {
      var brandAssets = staticAssetService.brand(configBanner.homePage.brandId);

      $scope.heroImageUrl = brandAssets.url('heroImage');

      $scope.logoUrl = brandAssets.url('logo');

      $scope.brandId = configBanner.homePage.brandId;

      $scope.goToBrandPage = function() {
        $state.go('root.influencer', { influencer: $scope.influencerBanner.pageName });
      };
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/banner-brands.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = BannerBrandsDirective;
