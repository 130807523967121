var format = require('shared-business-rules').format;

// @ngInject
function ValidateAddressPoboxDirective() {

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {
      function validate(viewValue) {
        var err = format.isPoBox(viewValue);
        ctrl.$setValidity('pobox', !err);
        return viewValue;
      }
      ctrl.$parsers.unshift(validate);
    }
  };
}

module.exports = ValidateAddressPoboxDirective;
