var _ = require('lodash-core');

// @ngInject
function AdminExperienceController($scope, $state, $stateParams, loyaltyService, loyaltyExperienceService,
  formsService) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.reload = function() {
    if ($stateParams.id === 'create') {
      $scope.experienceData = {};
      $scope.partnersData = loyaltyService.getLoyaltyPartners().lazyObject();
    } else {
      var promise = loyaltyExperienceService.getById($stateParams.id);
      $scope.experienceData = promise.lazyObject();
      promise.then(function() {
        var experienceData = $scope.experienceData;
        if (typeof experienceData.beginsAt === 'string') {
          experienceData.beginsAt = new Date(experienceData.beginsAt);
        }
        if (typeof experienceData.expiresAt === 'string') {
          experienceData.expiresAt = new Date(experienceData.expiresAt);
        }
        experienceData._id = experienceData.id;
      });
    }
  };

  $scope.reload();

  $scope.isExpired = function() {
    return $scope.experienceData.expiresAt && $scope.experienceData.expiresAt < new Date();
  };

  $scope.hasntStarted = function() {
    return $scope.experienceData.beginsAt && $scope.experienceData.beginsAt > new Date();
  };

  $scope.isActive = function() {
    return !($scope.isExpired() || $scope.hasntStarted());
  };

  $scope.save = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }

    var experienceData = _.cloneDeep($scope.experienceData);

    delete experienceData.createdAt;
    delete experienceData.modifiedAt;
    delete experienceData.codes;

    if ($scope.isCreationMode()) {
      formPromise.handle(loyaltyExperienceService.create(experienceData).then(function() {
        $state.go('admin.experience', { id: experienceData._id });
      }));
    } else {
      formPromise.handle(loyaltyExperienceService.update(experienceData).then(function() {
        $state.go('admin.experiences');
      }));
    }
  };

  $scope.cancel = function() {
    $state.go('admin.experiences');
  };

  $scope.isCreationMode = function() {
    return $stateParams.id === 'create';
  };

  function load() {
    $scope.reload();
  }

  $scope.$on('codesAdded', load);
  $scope.$on('codeRemoved', load);


}

module.exports = AdminExperienceController;
