
// @ngInject
function CheckGiftCardController($scope, formsService, giftCardService, userService) {
  var formPromise = $scope.formPromise = formsService.promiseHandler();
  $scope.userMail = '';
  $scope.giftCard = '';

  $scope.checkGiftCard = function() {
    if (!$scope.$root.getUser().email) {
      if (!$scope.userMail) {
        $scope.form.email.$setValidity('required', false);
        return;
      }
      $scope.form.email.$setValidity('required', true);
      if ($scope.form.email.$valid) {
        userService.subscribeEmailTo($scope.userMail, 'newsletter'); // capture the email even the GC is invalid
      }
    }
    if (!$scope.giftCard) {
      $scope.form.giftCard.$setValidity('required', false);
      return;
    }
    $scope.form.giftCard.$setValidity('required', true);
    $scope.form.giftCard.$setValidity('notFound', giftCardService.codeRegex.test($scope.giftCard));
    if (!formPromise.validate($scope.form)) {
      return;
    }
    formPromise.handle(giftCardService.get($scope.giftCard)
      .then(
        function(result) {
          if (!result.balance) {
            $scope.form.giftCard.$setValidity('notFound', false);
          } else {
            userService.setDefaultEmail($scope.userMail);
            userService.subscribeEmailTo($scope.userMail, 'robinhood', { skipDiscount: true });
          }
          return result;
        },
        function(err) {
          throw err;
        }
      )
    );
  };
}

module.exports = CheckGiftCardController;
