var _ = require('lodash-core');

var ABOVE_CLASS = 'above-top';
var BELOW_CLASS = 'below-top';

// @ngInject
function TrackPositionInViewportDirective($window, $document) {
  var trackingList = [];
  var compactList = [];

  var $body = angular.element($document[0].body);

  var tracker = _.throttle(function() {
    _.forEach(compactList, function(track) {
      track($document.scrollTop());
    });
  }, 50);

  angular.element($window).scroll(tracker);

  return {
    restrict: 'A',
    link: function($scope, element, attrs) {
      addElementToList($scope, element, attrs);
    }
  };

  ///// private methods

  function addElementToList($scope, element, attrs) {
    var marginTop = parseInt(attrs.viewportMargin || '0');
    var bodyClassPrefix = attrs.id;
    var limitTop = element.offset().top - marginTop;
    var above, entirelyAbove;

    checkPosition($document.scrollTop());

    var index = trackingList.length;
    trackingList.push(checkPosition);
    compactList.push(checkPosition);

    $scope.$on('$destroy', function() {
      trackingList[index] = null;
      compactList = _.compact(trackingList);
      if (!compactList.length) {
        //if trackingList contains only null values, reset it
        trackingList = [];
      }
    });

    function checkPosition(scrollTop) {
      var newAbove = scrollTop > limitTop;
      var newEntirelyAbove = scrollTop > (limitTop + element.height());

      if (above === newAbove && entirelyAbove === newEntirelyAbove) {
        return;
      }

      above = newAbove;
      entirelyAbove = newEntirelyAbove;
      setClasses(element, above, entirelyAbove, bodyClassPrefix);
    }
  }

  function setClasses(element, above, entirelyAbove, bodyClassPrefix) {

    // set element classes
    element.toggleClass(ABOVE_CLASS, above);
    element.toggleClass(BELOW_CLASS, !above);
    element.addClass('has-been-' + (above ? ABOVE_CLASS : BELOW_CLASS));
    element.toggleClass('entirely-' + ABOVE_CLASS, entirelyAbove);
    if (entirelyAbove) {
      element.addClass('has-been-entirely-' + ABOVE_CLASS);
    }

    // set body classes
    if (!bodyClassPrefix) {
      return;
    }
    $body.toggleClass(bodyClassPrefix + '-' + ABOVE_CLASS, above);
    $body.toggleClass(bodyClassPrefix + '-' + BELOW_CLASS, !above);
  }

}

module.exports = TrackPositionInViewportDirective;
