// @ngInject
function AnonymousSubscriptionController($scope, $rootScope, formsService, userService) {
  var formPromise = $scope.formPromise = formsService.promiseHandler();

  var user = userService.getUser();
  $scope.userMail = user && user.email || '';

  // Reset form promise status on log out
  userService.events.on('loggedOut', function() {
    formPromise.success = null;
  });

  $scope.subscribe = function() {
    var email = $scope.userMail;
    if (email === '') {
      $scope.form.email.$setValidity('required', false);
      return false;
    }

    if (!formPromise.validate($scope.form)) {
      return;
    }

    delete $rootScope.anonymousSubscriptionResult;
    formPromise.handle(userService.subscribeEmailTo(email, 'newsletter', {source: 'footer'})
      .then(function(result) {
        $rootScope.anonymousSubscriptionResult = result;
        return result;
      }, function(err) {
        throw err;
      })
    );
  };
}

module.exports = AnonymousSubscriptionController;
