
// @ngInject
function NumberOfProductsDirective($state) {
  var linker = function($scope) {
    $scope.sale = false;
    $scope.showCategoryName = true;
    if (/root\.sale/.test($state.current.name)) {
      $scope.sale = true;
    }
    if (/root\.pages/.test($state.current.name)) {
      // Hiding h1 on static pages with product lists
      if ($scope.page && $scope.page.title && $state.current.templateUrl !== '/views/static-page.html') {
        $scope.currentCategory = {};
        $scope.currentCategory.name = $scope.page.title;
      } else {
        $scope.showCategoryName = false;
      }
    }
  };
  return {
    link: linker,
    templateUrl: '/views/partials/number-of-products.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = NumberOfProductsDirective;
