var util = require('util');
var events = require('events');

var analyticsReady = false;
var marketingReady = false;

var adapters = {
  console: require('./adapters/console'),
  ga: require('./adapters/ga'),
  trackJs: require('./adapters/trackjs'),
  segmentIo: require('./adapters/segment-io'),
  milePopulation: require('./adapters/mile-population'),
  signifyd: require('./adapters/signifyd'),
  shareASale: require('./adapters/share-a-sale')
};

function AnalyticsEmitter() {
  events.EventEmitter.call(this);
  this.adapters = adapters;
}
util.inherits(AnalyticsEmitter, events.EventEmitter);

function addEmitterMethod(obj, name) {
  obj[name] = function() {
    if (this.pageLazyArguments) {
      this.pageLazyArguments = null;
      this.page.apply(this, this.pageLazyArguments);
    }
    var args = Array.prototype.slice.apply(arguments);
    if (name === 'page' || name === 'track') {
      args[1] = this.injectParametersDefaultValues.apply(this, args);
    }
    args.unshift(name);
    return this.emit.apply(this, args);
  };
}

addEmitterMethod(AnalyticsEmitter.prototype, 'page');
addEmitterMethod(AnalyticsEmitter.prototype, 'track');
addEmitterMethod(AnalyticsEmitter.prototype, 'identify');
addEmitterMethod(AnalyticsEmitter.prototype, 'alias');

AnalyticsEmitter.prototype.pageLazy = function() {
  // page event delayed until another event occurs
  this.pageLazyArguments = Array.prototype.slice.apply(arguments);
};

AnalyticsEmitter.prototype.ready = function(callback) {
  // if this object is called, we are ready
  callback();
};

AnalyticsEmitter.prototype.makeBrowserGlobal = function() {
  if (window.analyticsHub && window.analyticsHub._dequeue) {
    // dequeue the queue facade
    window.analyticsHub._dequeue(this);
  }
  window.analyticsHub = this;
};

AnalyticsEmitter.prototype.setParameterDefaultValue = function(name, value) {
  if (!this.parametersDefaultValues) {
    this.parametersDefaultValues = {};
  }
  this.parametersDefaultValues[name] = value;
};

AnalyticsEmitter.prototype.injectParametersDefaultValues = function(eventName, parameters) {
  if (!this.parametersDefaultValues) {
    return parameters;
  }
  parameters = parameters || {};
  for (var name in this.parametersDefaultValues) {
    if (this.parametersDefaultValues.hasOwnProperty(name)) {
      if (typeof parameters[name] === 'undefined' && this.parametersDefaultValues[name] !== null) {
        parameters[name] = this.parametersDefaultValues[name];
      }
    }
  }
  return parameters;
};

//Its a speed test ? ok lets avoid loading stuff
AnalyticsEmitter.prototype.speedTest = function() {
  /* jshint ignore:start */
  if (
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.match(
      /nux.*oto\sG|x11.*fox\/54|x11.*ome\/39|x11.*ome\/62|oid\s6.*1.*xus\s5.*MRA58N.*ome|JWR66Y.*ome\/62|woobot|speed|ighth|tmetr|eadle/i
    )
  ) {
    return true;
  }
  /* jshint ignore:end */
  return false;
};

AnalyticsEmitter.prototype.setupAnalytics = function(config, userService, errorExplodeService, $window) {
  if (!analyticsReady) {
    $window.cookiePolicy = {};
    if (this.speedTest()) {
      $window.cookiePolicy.runAnalytics = true;
      return;
    }
    if (!$window.cookiePolicy.runAnalytics) {
      $window.cookiePolicy.runAnalytics = true;
      if (!config.analytics) {
        return;
      }
      if (config.analytics.ga) {
        this.adapters.ga.attach(this, config.analytics.ga);
      }
      if (config.analytics.trackJs) {
        this.adapters.trackJs.attach(this, config.analytics.trackJs, userService, errorExplodeService);
      }
      analyticsReady = true;
    }
  }
};

AnalyticsEmitter.prototype.setupMarketing = function(config, $window) {
  if (!marketingReady) {
    if (this.speedTest()) {
      $window.cookiePolicy.runMarketing = true;
      return;
    }
    if (!$window.cookiePolicy.runMarketing) {
      $window.cookiePolicy.runMarketing = true;
      if (!config.analytics) {
        return;
      }
      if (config.analytics.shareASale && config.analytics.shareASale.enabled) {
        this.adapters.shareASale.attach(this);
      }
      marketingReady = true;
    }
  }
};

/* jshint maxparams: 20 */
AnalyticsEmitter.prototype.setup = function(
  config,
  userService,
  errorExplodeService,
  viewportService,
  platformService,
  cartService,
  sessionStorageService,
  userPageViewService,
  $filter,
  $timeout,
  $window
) {
  if (!config.analytics) {
    return this;
  }
  if (this.speedTest()) {
    $window.cookiePolicy.runRequired = true;
  }
  if (window.isOnWidget()) {
    $window.cookiePolicy.runMarketing = true;
  }
  if (!$window.cookiePolicy.runRequired) {
    $window.cookiePolicy.runRequired = true;
    if (config.analytics.console && $window.cookiePolicy.analytics) {
      this.adapters.console.attach(this, config.analytics.console, userService);
    }
    if (config.analytics.segmentIo) {
      this.adapters.segmentIo.attach(this, config.analytics.segmentIo, userService);
    }
    if (config.analytics.signifyd && config.analytics.signifyd.enabled) {
      this.adapters.signifyd.attach(this, sessionStorageService);
    }
    if (config.analytics.milePopulation) {
      this.adapters.milePopulation.attach(this, config.analytics.milePopulation, userService, userPageViewService);
    }
  }

  this.setupAnalytics(
    config,
    userService,
    errorExplodeService,
    viewportService,
    platformService,
    cartService,
    sessionStorageService,
    userPageViewService,
    $filter,
    $timeout,
    $window
  );

  this.setupMarketing(config, $window);

  return this;
};

module.exports = new AnalyticsEmitter();
