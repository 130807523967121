var _ = require('lodash-core');
// @ngInject
function ProductPermalinkController($state, $stateParams, productService, errorService) {
  productService.getByStyleId($stateParams.id)
    .then(function(product) {
      if (!product || !product.visible) {
        errorService.notFound();
        return;
      }
      $state.go('root.product.variant', {
        brand: product.brand.id,
        productName: (_.find(product.styles, { id: $stateParams.id }) || {}).slug || product.slug,
        id: $stateParams.id
      });
    })
    .catch(errorService.pageError);
}

module.exports = ProductPermalinkController;
