// @ngInject
function BackgroundSrcDirective(staticAssetService) {
  var linker = function($scope, element, attr) {
    var value = staticAssetService.url(attr.backgroundSrc);
    element.css({
      'background-image': 'url(' + value + ')',
      'background-size': 'cover'
    });
  };
  return {
    priority: 99,
    link: linker,
    restrict: 'A'
  };
}

module.exports = BackgroundSrcDirective;
