var _ = require('lodash-core');
var categories = require('shared-business-rules').categories.getArray();

// @ngInject
function CategoryDropdownDirective($rootScope, brandService, config, viewportService) {

  var linker = function($scope) {

    var category = _.find(categories, { key: $scope.key });
    $scope.category = category;
    $scope.subcategories = [];
    $scope.designers = [];
    $scope.featured = [];
    $scope.asset = $rootScope.asset;
    $scope.featureFlags = config.featureFlags;
    $scope.isLowRes = viewportService.width() < 991;
    $scope.show = function() {
      var visibleDropdowns = $('.dropdown-nav').filter(function() {
        return $(this).css('opacity') > 0.5;
      }).length;
      if (visibleDropdowns === 0) {
        $rootScope.menu[$scope.key].hide();
        return;
      }
      $rootScope.menu[$scope.key].show();
    };

    $scope.hide = function() {
      $rootScope.menu[$scope.key].hide();
    };

    $scope.isVisible = function() {
      return $rootScope.menu && $rootScope.menu[$scope.key] && $rootScope.menu[$scope.key].visible;
    };

    $scope.isHeaderCategoryMenuLoaded = function() {
      return $rootScope.headerCategoryMenuLoaded;
    };

    $scope.countrySelected = $rootScope.countrySelected;

    if (!category) {
      return;
    }
    if ($scope.key === 'gifts') {
      category.children.push({ 'name': 'Gift Cards', 'link': '/gift-card' });
    }
    $scope.subcategories = category.children;

    var dropdown = window.orchardMileContent.dropdowns[category.key];

    if (!dropdown) {
      return;
    }

    brandService.getAll().then(function(brands) {
      var maxDesignerCount = 10;
      var designerCount = 0;
      _.forEach(dropdown.designers, function(brandId) {
        var brand = _.find(brands, { id: brandId });
        if (brand && designerCount < maxDesignerCount) {
          $scope.designers.push(brand);
          designerCount++;
        }
      });
      var maxFeaturedCount = 3;
      var featuredCount = 0;
      _.forEach(dropdown.featured, function(brandId) {
        var brand = _.find(brands, { id: brandId });
        if (brand && featuredCount < maxFeaturedCount) {
          $scope.featured.push(brand);
          featuredCount++;
        }
      });
    });

  };

  return {
    restrict: 'E',
    templateUrl: '/views/partials/category-dropdown.html',
    scope: {
      key: '@'
    },
    link: linker
  };
}

module.exports = CategoryDropdownDirective;
