var _ = require('lodash-core');

// @ngInject
function DeletableBillingOptionsDirective(userService, $uibModal, $timeout, $rootScope) {
  var linker = function($scope) {

    $scope.confirmDeleteBillingOption = function(optionToDelete) {
      $scope.billingOptionToDelete = optionToDelete;

      var instance = $uibModal.open({
        templateUrl: '/views/partials/modals/delete-billing-option.html',
        windowClass: 'confirmation-modal',
        size: 'md',
        scope: $scope
      });

      $rootScope.addModalToStack();
      instance.result.finally($rootScope.removeModalFromStack);

      $scope.closeConfirmationModal = function() {
        instance.dismiss();
      };
      $scope.deleteBillingOption = function() {
        userService.deleteBillingOption(optionToDelete).then(function() {
          var index = _.findIndex($scope.billingOptions.options, function(option) {
            return option === optionToDelete;
          });
          $scope.billingOptions.options.splice(index, 1);
          if ($scope.billingOptions.selected === index) {
            $scope.billingOptions.selected = $scope.billingOptions.options.length ? 0 : -1;
          }
          instance.dismiss();
        }, function(err) {
          optionToDelete.error = err;
          $timeout(function() {
            instance.dismiss();
            delete optionToDelete.deleting;
            delete optionToDelete.error;
          }, 5000);
        });
        optionToDelete.deleting = true;
      };
    };
  };

  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = DeletableBillingOptionsDirective;
