/* jshint maxparams: 12 */

// @ngInject
function SingleShowsCarouselDirective(showService, influencerService, config, modalShowService) {

  var modalsToShow = 1;

  var linker = function ($scope, element, attrs) {
    $scope.title = attrs.title;
    $scope.name = attrs.name;
    $scope.refreshing = false;
    var hideTestItems = config.environment === 'production';

    var sectionName = element[0].outerHTML.match(/name="(.*?)"/);
    if (sectionName && sectionName.length) {
      sectionName = sectionName[1];
    }

    element.on('click', function(e){
      if (e.target.id && /[1234567890abcdef]{24}/.test(e.target.id)) {
        e.preventDefault();
        modalShowService.openShow(e.target.id, $scope, sectionName);
        showService.incrementAmountOfViews($scope.items, e.target.id);
        safeUpdateView();
      }
    });

    if (attrs.refreshAfter) {
      setInterval(function () {
        loadShows();
      }, Number(attrs.refreshAfter) * 1000);

      $scope.$watch('items', function () {
        $scope.refreshing = true;
        setTimeout(function () {
          $scope.refreshing = false;
          $scope.$digest();
        }, 250);
      });
    }

    loadShows();

    function loadShows() {
      if (attrs.items) {
        $scope.items = JSON.parse(attrs.items);
      } else if (attrs.ids) {
        var ids = JSON.parse(attrs.ids);
        showService.getNormalizedAndOrderedByIds(ids, false).then(function(shows) {
          influencerService.addInfluencerToShows(shows).then(function() {
            $scope.items = shows;
            safeUpdateView();
          });
        });
      } else if (attrs.term) {
        var term = JSON.parse(attrs.term);
        showService.getNormalizedShowsByTerm(term, false, hideTestItems).then(function (shows) {
          $scope.items = shows;
          safeUpdateView();
        });
        var popupMatch = /[\?&#]show=([^\?&#]+)/i.exec(document.location.search);
        if (popupMatch && popupMatch[1] && !$scope.instance && modalsToShow === 1) {
          modalShowService.openShow(popupMatch[1], $scope, sectionName);
        }
      }
    }

    function safeUpdateView() {
      if ($scope.$root) {
        if ($scope.$root.$$phase && ['$digest', '$apply'].includes($scope.$root.$$phase)) {
          setTimeout(function() {
            safeUpdateView();
          }, 500);
        } else {
          $scope.$digest();
        }
      }
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/single-shows-carousel.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = SingleShowsCarouselDirective;
