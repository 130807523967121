// @ngInject
function AnimatedLabelDirective($timeout) {
  var linker = function($scope, element) {

    var hasValueClass = 'has-value';
    var showClass = 'show';

    $(element).bind('checkval', function() {
      var label = $(this).prev('label');
      if (this.value !== '') {
        label.addClass(showClass);
        $(element).addClass(hasValueClass);
      } else {
        label.removeClass(showClass);
        $(element).removeClass(hasValueClass);
      }
    }).on('keyup', function() {
      $(this).trigger('checkval');
    }).on('change', function() {
      $(this).trigger('checkval');
    }).on('focus', function() {
      $(this).prev('label').addClass(showClass);
    }).on('blur', function() {
      if (!$(this).hasClass(hasValueClass)) {
        $(this).prev('label').removeClass(showClass);
      }
    }).trigger('checkval');

    // the previous checkval trigger may happen before the binding
    $timeout(function() {
      $(element).trigger('checkval');
    }, 1000);
  };
  return {
    link: linker,
    restrict: 'C'
  };
}

module.exports = AnimatedLabelDirective;