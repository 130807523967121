// @ngInject
function HoverSrcDirective($compile, platformService) {
  var linker = function($scope, element) {
    if (!$scope.hoverSrc || platformService.isMobile()) {
      return;
    }
    var hoverImage = $compile($('<img lazy-src="' + $scope.hoverSrc + '" lazy-src-visible="true"/>').css({
      position: 'absolute',
      opacity: 0
    }))($scope);
    element.css({
      position: 'absolute',
      '-webkit-transition': 'opacity .5s ease-in-out',
      '-moz-transition': 'opacity .5s ease-in-out',
      '-o-transition': 'opacity .5s ease-in-out',
      'transition': 'opacity .5s ease-in-out'
    }).before(hoverImage);
    element.hover(function() {
      element.css({ opacity: 0 });
      hoverImage.css({ opacity: 1 });
    }, function() {
      element.css({ opacity: 1 });
    });
  };
  return {
    link: linker,
    restrict: 'A',
    scope: {
      hoverSrc: '@'
    }
  };
}

module.exports = HoverSrcDirective;
