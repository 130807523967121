var _ = require('lodash-core');

// @ngInject
function InfluencerController($scope, $rootScope, $state, influencerService, platformService, localStorageService) {

  loadInfluencer();

  function loadInformation(influencer) {
    $scope.giveawayInfluencer = influencer.giveaway;
    delete influencer.giveaway;
    $scope.influencer = influencer;
  }

  function loadImages(influencer) {
    var isMobile = platformService.isMobile;
    if (isMobile()) {
      influencer.heroImgUrl = influencer.page.heroImageMobile;
    } else {
      influencer.heroImgUrl = influencer.page.heroImage;
    }
  }

  function loadInterview(influencer) {
    $scope.interview = influencer.page.interview;
    delete influencer.page.interview;
  }

  function loadCarousels(influencer) {
    $scope.carousels = influencer.page.carouselProducts;
    delete influencer.page.carouselProducts;
    _.forEach($scope.carousels, function (carousel) {
      carousel.opened = false;
    });
    if ($scope.carousels && $scope.carousels.length === 1) {
      $scope.carousels[0].opened = true;
    }
  }

  function loadSocialMedia(influencer) {
    if (influencer.socialNetworks.instagram) {
      influencer.socialNetworks.instagram = 'https://www.instagram.com/' + influencer.socialNetworks.instagram;
    }

    if (influencer.socialNetworks.tiktok) {
      influencer.socialNetworks.tiktok =  'https://www.tiktok.com/@' + influencer.socialNetworks.tiktok;
    }

    if (influencer.socialNetworks.youtube) {
      influencer.socialNetworks.youtube =  'https://www.youtube.com/@' + influencer.socialNetworks.youtube;
    }

    if (influencer.socialNetworks.facebook) {
      influencer.socialNetworks.facebook = 'https://www.facebook.com/' + influencer.socialNetworks.facebook;
    }
  }

  function loadDiamondBrands(influencer) {
    var diamondBrands = influencer.page.diamondBrands;
    delete influencer.page.diamondBrands;
    if ($rootScope.featureFlags.influencerBrands && diamondBrands && diamondBrands.length) {
      $scope.diamondBrands = diamondBrands[0];
    }
  }

  function loadInfluencer() {

    if ($state.params.influencer) {
      // provisory redirection
      if ($state.params.influencer === 'jackie-miranne') {
        $state.params.influencer = 'jackiemiranne';
      }
      influencerService.getByUsername($state.params.influencer).then(function(influencer) {
        if (influencer && influencer.page) {
          loadImages(influencer);
          loadSocialMedia(influencer);
          loadInterview(influencer);
          loadCarousels(influencer);
          loadDiamondBrands(influencer);
          loadInformation(influencer);
          saveWatchedStoreOnSession(influencer);
        } else {
          $state.go('root.the-mile');
        }
      }).catch(function() {
        $state.go('root.the-mile');
      });
    }
  }

  function saveWatchedStoreOnSession(influencer) {
    var watchedStores = localStorageService.getItem('watched_stores');
    if (watchedStores) {
      watchedStores = JSON.parse(watchedStores);
    } else {
      watchedStores = [];
    }
    addWatchedStore(watchedStores, influencer);
    localStorageService.setItem('watched_stores', JSON.stringify(watchedStores));
  }

  function addWatchedStore(watchedStores, inf) {
    var watchedStore = _.find(watchedStores, function (wStoreSaved) {
      return wStoreSaved.id === inf.id;
    });

    if (!watchedStore) {
      watchedStore = {};
      watchedStore.id = inf.id;
      watchedStores.push(watchedStore);
    }

    watchedStore.watchedAt = new Date();
  }

  $scope.isMobile = platformService.isMobile;

  $scope.toggleCarousel = function(number) {
    $scope.carousels[number].opened = !$scope.carousels[number].opened;
  };

  $scope.goToGiveAwayRules = function() {
    $('#giveaway-modal').modal('hide');
    if ($state.params.influencer === 'jackie-miranne') {
      setTimeout(function() {
        $state.go('root.giveaway-rules');
      }, 400);
    } else {
      setTimeout(function() {
        $state.go('root.christie-giveaway-rules');
      }, 400);
    }
  };

  //VIDEO INTERVIEW
  // Posted Show
  $scope.isPlayingPosted = function() {
    return $scope.myVideo && !$scope.myVideo.paused;
  };

  $scope.playPosted = function() {
    if (!$scope.myVideo) {
      $scope.myVideo = window.document.getElementById('show-interview-video');
    }
    $scope.myVideo.play();
    $scope.myVideo.muted = false;
    $scope.postedVideoStarted = true;
  };

  $scope.pausePosted = function() {
    $scope.postedVideoStarted = false;
    $scope.myVideo.pause();
  };

}

module.exports = InfluencerController;
