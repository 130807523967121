var shared = require('shared-business-rules');
var _ = require('lodash-core');

/* globals $ */
/* jshint maxparams: 15 */

// @ngInject
function MenuController($rootScope, $scope, analyticsService, $state, brandService, userService, viewportService,
                        config, localStorageService, globalizationService) {

  $scope.categories = shared.categories.getArray();

  $scope.designers = {
    all: [],
    contemporary: [],
    designer: [],
    featured: []
  };

  brandService.getAll().then(function(brands) {
    _.forEach(_.keys($scope.designers), function(group) {
      var maxItems = group === 'featured' ? 3 : 10;
      $scope.designers[group] = _(brandService.getBrandGroup(group))
        .map(function(brandId) {
          return _.find(brands, {id: brandId});
        })
        .compact()
        .value()
        .slice(0, maxItems);
    });
  });

  var dropdowns = ['designers', 'stories', 'shopby', 'holiday'];
  shared.categories.getArray().forEach(function(category) {
    dropdowns.push(category.key);
  });

  var menu = $rootScope.menu = new SubMenu(dropdowns, analyticsService);

  $('body').click(function(e) {
    if (!menu.isVisible()) {
      return;
    }
    if (!clickedOutsideMenu(e)) {
      return;
    }
    $scope.$apply(function() {
      menu.hide();
    });
  });

  function clickedOutsideMenu(e) {
    var target = e.target ? $(e.target) : $(e.toElement);
    return !target.closest('.dropdown-nav-container,.nav-container,.user-container,.header-subnav').length;
  }

  $scope.$on('globalKeydown', function(e, keyboardEvent) {
    if (keyboardEvent.keyCode === 27) {
      menu.hide();
    }
  });

  $scope.isCurrentView = function(name) {
    return $state.$current.name === name;
  };

  $scope.searchClick = function($event) {
    if (viewportService.is('<md') && config.featureFlags.searchPreview.enabled) {
      $event.preventDefault();
      $rootScope.showSearchPreviewMobile();
      var searchInput = $('.search-preview-mobile .search-term');
      if (searchInput) {
        searchInput.focus();
      }
      return;
    }
    if ($scope.isCurrentView('root.search')) {
      $rootScope.$broadcast('searchToTop');
    }
  };

  $scope.selectCountry = function(countrySelected) {
    globalizationService.selectCountry(countrySelected);
    if (userService.isLoggedIn() && $rootScope.country && userService.getUser().lang !== $rootScope.country.language) {
      userService.setLang($rootScope.country.language);
    }
  };

  $rootScope.$watch('country', function(newValue, oldValue) {
    if ($rootScope.country && newValue.code && (!oldValue || oldValue.code !== newValue.code)) {
      window.location.reload();
    }
  });

  if (localStorageService.getItem('country')) {
    $scope.selectCountry(localStorageService.getItem('country'));
  } else {
    $scope.selectCountry('us');
  }
}

function SubMenu(list, analyticsService) {
  var self = this;
  this.visible = false;
  this.isVisible = function() {
    return _.some(list, function(name) {
      return self[name].visible;
    });
  };
  this.hide = function() {
    _.forEach(list, function(name) {
      self[name].hide();
    });
  };
  _.forEach(list, function(name) {
    var title = name.charAt(0).toUpperCase() + name.slice(1);
    self[name] = {
      visible: false,
      show: function() {
        if (this.visible) {
          return;
        }
        self.hide();
        self.visible = this.visible = true;
        analyticsService.track(title + ' Menu Shown');
      },
      hide: function() {
        if (!this.visible) {
          return;
        }
        self.visible = this.visible = false;
        analyticsService.track(title + ' Menu Hidden');
      },
      toggle: function() {
        if (this.visible) {
          this.hide();
        } else {
          this.show();
        }
      }
    };
  });

  window.onscroll = function() {
    var scroll = document.documentElement.scrollTop || document.body.scrollTop;
    var dropdownNavElement = $('.dropdown-nav');
    if (dropdownNavElement && scroll > -1 && scroll < 300) {
      dropdownNavElement.addClass('nav-active');
      dropdownNavElement.removeClass('no-active');
    } else {
      dropdownNavElement.addClass('no-active');
      dropdownNavElement.removeClass('nav-active');
    }
  };
}

module.exports = MenuController;
