var promises = require('../../async/promises');
var states = require('shared-business-rules').states;
var _ = require('lodash-core');

/* jshint maxparams: 9 */
/* globals $ */

// @ngInject
function PaymentRequestButtonDirective($rootScope, paymentService, analyticsService, shoppingContextService,
                                       userService, cartService, config, pendingOrderService, $state) {
  var linker = function($scope) {
    var fulfillers;
    var paymentRequest;

    setTimeout(function() {
      fulfillers = shoppingContextService.getFulfillers();
      var paymentRequestButtonElement = $('#payment-request-button');
      if (paymentRequestButtonElement.length) {
        loadPaymentRequestButton();
      } else {
        setTimeout(function() {
          paymentRequestButtonElement = $('#payment-request-button');
          if (paymentRequestButtonElement.length) {
            loadPaymentRequestButton();
          }
        }, 500);
      }
    }, 250);

    function loadPaymentRequestButton() {
      if (fulfillers && fulfillers.length) {
        paymentService.loadProvider().then(function() {
          var totals = shoppingContextService.getTotals();
          if (config.featureFlags.stripeQuickPaymentOnCart.enabled && !$rootScope.isCheckout()) {
            paymentRequest = window.stripeElements.paymentRequest({
              country: 'US',
              currency: 'usd',
              requestPayerName: true,
              requestPayerEmail: true,
              requestPayerPhone: true,
              requestShipping: true,
              displayItems: getLineItems(totals, cartService.getData()),
              total: getTotal(totals)
            });
            getShippingMethods(fulfillers)[0]
              .id.split('/')
              .forEach(function(shippingOptionSelected) {
                var fulfillerId = shippingOptionSelected.split(',')[0];
                var shippingOption = shippingOptionSelected.split(',')[1];
                shoppingContextService.updateFulfillerShippingMethod(
                  fulfillerId,
                  shippingOption
                );
              });
          } else {
            paymentRequest = window.stripeElements.paymentRequest({
              country: 'US',
              currency: 'usd',
              requestPayerName: false,
              requestPayerEmail: false,
              requestPayerPhone: false,
              requestShipping: false,
              displayItems: getLineItems(totals, cartService.getData()),
              total: getTotal(totals)
            });
          }
          var elements = window.stripeElements.elements();
          var prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                theme: 'dark'
              }
            }
          });

          // Check the availability of the Payment Request API first.
          paymentRequest.canMakePayment().then(function(result) {
            if ($('#payment-request-button').length) {
              if (result) {
                prButton.mount('#payment-request-button');
                prButton.addEventListener('click', function() {
                  startQuickPayment();
                });
              } else {
                var paymentRequestButtonElement = document.getElementById('payment-request-button');
                if (paymentRequestButtonElement) {
                  paymentRequestButtonElement.style.display = 'none';
                }
              }
            }
          });
        });
      }
    }

    $scope.$on('cartItemsChanged', function() {
      var totals = shoppingContextService.getTotals();
      paymentRequest.update({
        displayItems: getLineItems(totals, cartService.getData()),
        total: getTotal(totals)
      });
    });

    function startQuickPayment() {
      if (!paymentRequest) {
        return;
      }
      analyticsService.track('Stripe Quick Payment Button Clicked');
      $rootScope.modal.hide();
      if (!$rootScope.isCheckout()) {
        getShippingMethods(fulfillers)[0]
          .id.split('/')
          .forEach(function(shippingOptionSelected) {
            var fulfillerId = shippingOptionSelected.split(',')[0];
            var shippingOption = shippingOptionSelected.split(',')[1];
            shoppingContextService.updateFulfillerShippingMethod(fulfillerId, shippingOption);
          });
      }
      var totals = shoppingContextService.getTotals();
      paymentRequest.update({
        displayItems: getLineItems(totals, cartService.getData()),
        total: getTotal(totals)
      });
      paymentRequest.on('shippingaddresschange', function(ev) {
        function updateTotals(tryCount) {
          if (!$rootScope.calculatingTotals) {
            var totals = shoppingContextService.getTotals();
            var shippingOptions = getShippingMethods(fulfillers, shoppingContextService.getShipping());
            if (shippingOptions && shippingOptions.length) {
              ev.updateWith({
                status: 'success',
                shippingOptions: shippingOptions,
                displayItems: getLineItems(totals, cartService.getData()),
                total: getTotal(totals)
              });
            } else {
              ev.updateWith({ status: 'invalid_shipping_address' });
            }
            return;
          }
          if (tryCount++ < 20) {
            setTimeout(function() {
              updateTotals(tryCount);
            }, 500);
          }
        }

        if (!['US'].includes(ev.shippingAddress.country)) {
          ev.updateWith({ status: 'invalid_shipping_address' });
        } else {
          var shipping = shoppingContextService.getShipping() || { address: {}, contact: {} };
          shipping.address.state = ev.shippingAddress.region;
          shipping.address.city = ev.shippingAddress.city;
          shipping.address.zip = ev.shippingAddress.postalCode;
          shoppingContextService.updateShipping({
            address: shipping.address,
            contact: shipping.contact
          });
          setTimeout(function() {
            updateTotals(0);
          }, 1000);
        }
      });
      paymentRequest.on('cancel', function () {
        $rootScope.quickPaymentStarted = false;
      });
      paymentRequest.on('shippingoptionchange', function(ev) {
        function updateTotals(tryCount) {
          if (!$rootScope.calculatingTotals) {
            var totals = shoppingContextService.getTotals();
            ev.updateWith({
              status: 'success',
              displayItems: getLineItems(totals, cartService.getData()),
              total: getTotal(totals)
            });
            return;
          }
          if (tryCount++ < 20) {
            setTimeout(function() {
              updateTotals(tryCount);
            }, 500);
          }
        }

        var shippingOptionsSelected = ev.shippingOption.id.split('/');
        if (!$rootScope.isCheckout()) {
          shippingOptionsSelected.forEach(function(shippingOptionSelected) {
            var fulfillerId = shippingOptionSelected.split(',')[0];
            var shippingOption = shippingOptionSelected.split(',')[1];
            shoppingContextService.updateFulfillerShippingMethod(fulfillerId, shippingOption);
          });
        }
        setTimeout(function() {
          updateTotals(0);
        }, 1000);
      });
      paymentRequest.on('paymentmethod', function(ev) {
        window.onbeforeunload = function () {
          return 'We\'re processing your payment, please do not close your tab yet';
        };
        $rootScope.quickPaymentStarted = true;
        var shipping = shoppingContextService.getShipping() || { address: {}, contact: {} };
        if (ev.shippingAddress) {
          // shipping address information
          shipping.address.country = ev.shippingAddress.country.toLowerCase();
          shipping.address.state = ev.shippingAddress.region;
          shipping.address.city = ev.shippingAddress.city;
          shipping.address.zip = ev.shippingAddress.postalCode;
          shipping.address.address1 = ev.shippingAddress.addressLine[0];
          shipping.address.address2 = ev.shippingAddress.addressLine[1];

          // shipping contact information
          var names;
          try {
            /*jshint camelcase: false */
            names = parseShippingName(ev.shippingAddress, ev.payerName);
          } catch (e) {
            $rootScope.quickPaymentStarted = false;
            window.onbeforeunload = undefined;
            ev.complete('invalid_shipping_address');
            console.log(e);
          }

          shipping.contact.firstName = names.firstName;
          shipping.contact.lastName = names.lastName;
          shipping.contact.email = ev.payerEmail;
          shipping.contact.phoneNumber = ev.shippingAddress.phone;
          shoppingContextService.updateShipping({
            address: shipping.address,
            contact: shipping.contact
          });
        }

        var email = shipping.contact.email || ev.payerEmail || ev.paymentMethod.billing_details.email;

        function createOrder(tryCount) {
          if (!$rootScope.calculatingTotals) {
            //generate the payment intent
            var totals = shoppingContextService.getTotals();
            var paymentIntentRequest;
            paymentIntentRequest = {
              currency: 'usd',
              amount: totals.purchasable.totalPrice,
              /*jshint camelcase: false */
              payment_method: ev.paymentMethod.id,
              // receipt_email: ev.payerEmail || shipping.contact.email,
              shipping: {
                address: {
                  line1: shipping.address.address1,
                  line2: shipping.address.address2,
                  city: shipping.address.city,
                  country: shipping.address.country || 'us',
                  postal_code: shipping.address.zip,
                  state: shipping.address.state
                },
                name: shipping.contact.firstName + ' ' + shipping.contact.lastName,
                phone: shipping.contact.phoneNumber
              },
              capture_method: 'manual',
              metadata: {
                testing: userService.userIsTester().toString(),
                user_email: ev.payerEmail || shipping.contact.email
              }
            };
            paymentService
              .getProviderByName('stripe')
              .generateStripePaymentIntent(paymentIntentRequest)
              .then(function(paymentIntent) {
                window.stripeElements
                  .confirmCardPayment(
                    /*jshint camelcase: false */
                    paymentIntent.client_secret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: false }
                  )
                  .then(function(confirmResult) {
                    if (confirmResult.error) {
                      // Report to the browser that the payment failed, prompting it to
                      // re-show the payment interface, or show an error message and close
                      // the payment interface.
                      ev.complete('fail');
                      window.onbeforeunload = undefined;
                    } else {
                      // Report to the browser that the confirmation was successful, prompting
                      // it to close the browser payment method collection interface.
                      ev.complete('success');
                      placeOrder(
                        confirmResult.paymentIntent,
                        ev.paymentMethod,
                        shipping,
                        email
                      );
                    }
                  })
                  .finally(function () {
                    $rootScope.quickPaymentStarted = false;
                    window.onbeforeunload = undefined;
                  });
            });
            return;
          }
          if (tryCount++ < 20) {
            setTimeout(function() {
              createOrder(tryCount);
            }, 500);
          }
        }
        isValidOrder(ev, shipping, email).then(function () {
          createOrder(0);
        }).catch(function (e) {
          $rootScope.quickPaymentStarted = false;
          window.onbeforeunload = undefined;
          ev.complete('fail');
          console.log(e);
        });
      });
    }

    function parseShippingName(shipping, payerName) {
      var names = {
        firstName: '',
        lastName: ''
      };
      var fullNameWords;
      var nameWords = shipping.recipient.replace(/[^A-Za-zÀ-ÿ\s]/g, '').split(' ');
      if (!nameWords && !nameWords.length) {
        throw new Error('full name is require for shipping');
      } else if (nameWords.length === 1) {
        if (payerName.includes(nameWords[0])) {
          var pyNameWords = payerName.replace(/[^A-Za-zÀ-ÿ\s]/g, '').split(' ');
          if (pyNameWords && pyNameWords.length > 1) {
            fullNameWords = pyNameWords;
          }
        }
        if (!fullNameWords) {
          fullNameWords = nameWords;
          fullNameWords.push('.');
        }
      } else {
        fullNameWords = nameWords;
      }

      if (!fullNameWords) {
        throw new Error('invalid full name');
      }

      fullNameWords = _.compact(fullNameWords);

      names.firstName = fullNameWords[0];
      for (var i = 1; i < fullNameWords.length - 1; i++) {
        names.firstName += ' ' + fullNameWords[i];
      }
      names.lastName = fullNameWords.length === 1 ? '.' : fullNameWords[fullNameWords.length - 1];

      return names;
    }

    function validateShipping(shippingInfo) {
      if (!shippingInfo) {
        return {
          error: new Error('No shipping information'),
        };
      }
      if (!shippingInfo.contact.email) {
        return {
          error: new Error('No email'),
        };
      }
      // state is a free text input, so do our best to find it
      if (!states[shippingInfo.address.state]) {
        // state is not in AA format
        var stateName = shippingInfo.address.state.toUpperCase();
        _.keys(states).some(function(stateKey) {
          if (states[stateKey].name.toUpperCase() === stateName) {
            shippingInfo.address.state = stateKey;
            return true;
          }
        });
      }
      var shippingAddressToValidate = shippingInfo.address;
      if (!shippingInfo.address.address1) {
        shippingAddressToValidate = _.merge(
          { address1: '555 Dummy St' },
          shippingInfo.address
        );
      }
      var validationError = shoppingContextService.validateShippingAddress(
        shippingAddressToValidate
      );
      if (validationError) {
        return {
          error: validationError,
        };
      }
      shoppingContextService.updateShipping({
        address: shippingInfo.address,
        contact: shippingInfo.contact,
      });
      if (!userService.isLoggedIn() && userService.getUser().email !== shippingInfo.contact.email) {
        userService.guestCheckout(shippingInfo.contact.email);
      }
      return shippingInfo;
    }

    function getLineItems(totals, cartData) {
      var lineItems = [
        {
          label: 'Subtotal',
          amount: totals.cost,
        },
        {
          label: 'Shipping',
          amount: totals.shipping,
        },
        {
          label: 'Taxes',
          amount: totals.purchasable.taxes,
        },
      ];
      // only show discount if there is one
      if (cartData.rewards && cartData.rewards.items && cartData.rewards.items.length) {
        cartData.rewards.items.forEach(function(reward) {
          lineItems.splice(1, 0, {
            label: reward.description,
            amount: -reward.credit, // minus here to show it's a discount
          });
        });
      }
      if (totals.giftCardDebit && totals.giftCardDebit > 0) {
        lineItems.splice(1, 0, {
          label: 'Gift Card',
          amount: -totals.giftCardDebit, // minus here to show it as a discount
        });
      }
      return lineItems;
    }

    function getTotal(totals) {
      return {
        label: 'Total',
        amount: totals.purchasable.totalPrice,
      };
    }

    function isValidOrder(ev, shipping, email) {
      /*jshint camelcase: false */
      shipping.contact.email = userService.isLoggedIn() ? userService.getUser().email : email;
      if (ev.paymentMethod.billing_details && ev.paymentMethod.billing_details.phone) {
        shipping.contact.phoneNumber = ev.paymentMethod.billing_details.phone;
      }
      if (ev.paymentResult && ev.paymentResult.shipping && ev.paymentResult.shipping.phone) {
        shipping.contact.phoneNumber = ev.paymentResult.shipping.phone;
      }
      var validationResult = validateShipping(shipping);
      if (validationResult.error) {
        return promises.immediate(validationResult.error);
      }
      var totals = shoppingContextService.getTotals().purchasable;
      var billingAddress = ev.paymentMethod.billing_details.address;
      var order = {
        shipping: validationResult,
        fulfillers: $scope.fulfillers,
        /*jshint camelcase: false */
        billing: {
          paymentMethod: {
            paymentProvider: 'stripe',
            paymentProviderData: ev,
            nameOnCard: ev.paymentMethod.billing_details.name,
            cardNumber: 'xxxxxxxxxxxx' + ev.paymentMethod.card.last4,
            /*jshint camelcase: false */
            expirationMonth: ev.paymentMethod.card.exp_month,
            expirationYear: ev.paymentMethod.card.exp_year,
            cvv: 'xxx',
          },
          contact: {
            firstName: ev.paymentMethod.billing_details.name
          },
          address: {
            address1:
              billingAddress && billingAddress.line1 ? billingAddress.line1 : validationResult.address.address1,
            address2:
              billingAddress && billingAddress.line2 ? billingAddress.line2 : validationResult.address.address2,
            city:
              billingAddress && billingAddress.city ? billingAddress.city : validationResult.address.city,
            state:
              billingAddress && billingAddress.state ? billingAddress.state : validationResult.address.state,
            zip:
              billingAddress && billingAddress.postal_code ? billingAddress.postal_code : validationResult.address.zip,
            country:
              billingAddress && billingAddress.country ? billingAddress.country.toLowerCase() : 'us',
          },
        },
        promoCode: shoppingContextService.getCart().promoCode,
        totals: totals,
      };
      if ($scope.$root.giftCard && totals.giftCardDebit) {
        order.giftCard = $scope.$root.giftCard.code;
        order.giftCardBalance = $scope.$root.giftCard.balance;
      }
      var validationError = shoppingContextService.validateShipping(
        order.shipping
      );
      if (validationError) {
        return promises.immediate(validationError);
      }

      userService.getUser().guest = !userService.isLoggedIn();
      return pendingOrderService.create(order);
    }

    function getShippingMethods(fulfillers, shipping) {
      var shippingMethods = [];
      function getGroundAndSpecialShippingMethods() {
        var totalAmount = 0;
        var fulfillersNames = fulfillers.map(function(fulfiller) {
          var shippingMethodCandidate;
          if (shipping && shipping.address && ['AK', 'HI'].includes(shipping.address.state)) {
            shippingMethodCandidate = fulfiller.shippingMethods.find(function (shippingMethod) {
              return shippingMethod.id.includes('special' + shipping.address.state);
            });
          } else {
            shippingMethodCandidate = fulfiller.shippingMethods.find(function (shippingMethod) {
              return shippingMethod.id === 'ground';
            });
          }
          if (shippingMethodCandidate) {
            totalAmount += shippingMethodCandidate.cost;
            return fulfiller.id + ',' + shippingMethodCandidate.id;
          }
        }).filter(function(ff) {return ff;});
        if (fulfillersNames.length === fulfillers.length) {
          return {
            id: fulfillersNames.join('/'),
            label: 'Ground',
            details: '',
            amount: totalAmount
          };
        }
      }

      var shippingMethodsSelected = getGroundAndSpecialShippingMethods();

      if (shippingMethodsSelected) {
        shippingMethods.push(shippingMethodsSelected);
      }
      return shippingMethods;
    }

    function placeOrder(paymentResult, paymentMethod, shipping, email) {
      /*jshint camelcase: false */
      shipping.contact.email = userService.isLoggedIn() ? userService.getUser().email : email;
      if (paymentMethod.billing_details && paymentMethod.billing_details.phone) {
        shipping.contact.phoneNumber = paymentMethod.billing_details.phone;
      }
      if (paymentResult && paymentResult.shipping && paymentResult.shipping.phone) {
        shipping.contact.phoneNumber = paymentResult.shipping.phone;
      }
      var validationResult = validateShipping(shipping);
      if (validationResult.error) {
        return promises.immediate(validationResult.error);
      }
      var totals = shoppingContextService.getTotals().purchasable;
      var billingAddress = paymentMethod.billing_details.address;
      var order = {
        shipping: validationResult,
        fulfillers: $scope.fulfillers,
        /*jshint camelcase: false */
        billing: {
          paymentMethod: {
            paymentProvider: 'stripe',
            paymentProviderData: paymentResult,
            nameOnCard: paymentMethod.billing_details.name,
            cardNumber: 'xxxxxxxxxxxx' + paymentMethod.card.last4,
            /*jshint camelcase: false */
            expirationMonth: paymentMethod.card.exp_month,
            expirationYear: paymentMethod.card.exp_year,
            cvv: 'xxx',
          },
          address: {
            address1:
              billingAddress && billingAddress.line1 ? billingAddress.line1 : validationResult.address.address1,
            address2:
              billingAddress && billingAddress.line2 ? billingAddress.line2 : validationResult.address.address2,
            city:
              billingAddress && billingAddress.city ? billingAddress.city : validationResult.address.city,
            state:
              billingAddress && billingAddress.state ? billingAddress.state : validationResult.address.state,
            zip:
              billingAddress && billingAddress.postal_code ? billingAddress.postal_code : validationResult.address.zip,
            country:
              billingAddress && billingAddress.country ? billingAddress.country.toLowerCase() : 'us',
          },
        },
        promoCode: shoppingContextService.getCart().promoCode,
        totals: totals,
      };
      if ($scope.$root.giftCard && totals.giftCardDebit) {
        order.giftCard = $scope.$root.giftCard.code;
        order.giftCardBalance = $scope.$root.giftCard.balance;
      }
      order.paymentIntentId = paymentResult.id;
      var validationError = shoppingContextService.validateShipping(
        order.shipping
      );
      if (validationError) {
        return promises.immediate(validationError);
      }

      userService.getUser().guest = !userService.isLoggedIn();
      return pendingOrderService.create(order, true)
        .then(function () {
          $rootScope.quickPaymentStarted = false;
          $state.go('root.checkout.pre-confirmation', {
            'payment_intent': paymentResult.id,
            'payment_intent_client_secret': paymentResult.client_secret
          });
        }).catch(function () {
          $rootScope.quickPaymentStarted = false;
          $state.go('root.checkout.pre-confirmation', {
            'payment_intent': paymentResult.id,
            'payment_intent_client_secret': paymentResult.client_secret
          });
        });
    }
  };
  return {
    link: linker,
    templateUrl: '/views/partials/payment-request-button.html',
    restrict: 'E',
    scope: {
      size: '@',
      color: '@',
      giftCard: '@',
      before: '&',
    },
  };
}

module.exports = PaymentRequestButtonDirective;
