// @ngInject
function DropDownFilterDirective($rootScope, $document, $parse, $state, $stateParams, listContextService) {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/modals/drop-down-filter.html',
    scope: {},
    link: function($scope, element, attrs) {

      $scope.productsCount = function() {
        return $rootScope.productsCount;
      };

      $scope.getCountryCode = function() {
        return $rootScope.getCountryCode();
      };

      // create nested context to avoid change the main context until user press GO button
      listContextService.setScopeContext($scope, { urlMode: false });

      // we have to get the search term for parent scope because of we need to react to the search term change
      $scope.$watch('$parent.search.term', function(val) {
        $scope.search.term = val;
      });

      $scope.sortDefaultText = attrs.sortDefaultText;

      //$scope.scrollTo = $scope.$parent.scrollTo;
      $scope.featureFlags = $scope.$parent.featureFlags;

      var filters = $scope.filters;
      var sort = $scope.sort;

      var original;
      //var scroll=0;


      $scope.whatsNew = $parse(attrs.whatsNew)($scope.$parent) !== false;
      $scope.defaultCategoryText = attrs.defaultCategoryText;
      $scope.hideSizes = $parse(attrs.hideSizes)($scope.$parent);
      $scope.hideColors = $parse(attrs.hideColors)($scope.$parent);
      $scope.showBrandFilter = $parse(attrs.showBrandFilter)($scope.$parent);
      $scope.showSaleFilter = $parse(attrs.showSaleFilter)($scope.$parent);
      $scope.showDiscountFilter = $parse(attrs.showDiscountFilter)($scope.$parent);
      $scope.showOccasionFilter = $parse(attrs.showOccasionFilter)($scope.$parent);
      $scope.showPriceFilter = $parse(attrs.showPriceFilter)($scope.$parent);
      $scope.brandId = $parse(attrs.brandId)($scope.$parent);

      $scope.isOpen = false;

      $scope.toggle = function(isChanged) {
        $scope.isOpen = !$scope.isOpen;
        if ($scope.isOpen) {
          filters.category = $scope.$parent.filters.category.slice(0);
          filters.sizes = $scope.$parent.filters.sizes.slice(0);
          filters.colors = $scope.$parent.filters.colors.slice(0);
          filters.brands = $scope.$parent.filters.brands.slice(0);
          filters.occasion = $scope.$parent.filters.occasion;
          filters.price = { min: $scope.$parent.filters.price.min, max: $scope.$parent.filters.price.max };
          filters.sale = $scope.$parent.filters.sale;
          filters.discount = $scope.$parent.filters.discount;
          filters.userTags = $scope.$parent.filters.userTags;
          sort.criteria = $scope.$parent.sort.criteria;

          original = JSON.stringify([filters, sort]);
          //scroll = $document.scrollTop();
        } else {
          if (!isChanged) {
            //  $scope.scrollTo(0, scroll);
          }
        }
      };

      $scope.isDirty = function() {
        return JSON.stringify([filters, sort]) !== original;
      };

      $scope.apply = function() {
        $scope.$parent.filters.sizes = filters.sizes.slice(0);
        $scope.$parent.filters.colors = filters.colors.slice(0);
        $scope.$parent.filters.brands = filters.brands.slice(0);
        $scope.$parent.filters.occasion = filters.occasion;
        $scope.$parent.filters.price.min = filters.price.min;
        $scope.$parent.filters.price.max = filters.price.max;
        $scope.$parent.filters.sale = filters.sale;
        $scope.$parent.filters.category = filters.category.slice(0);
        $scope.$parent.filters.discount = filters.discount;
        $scope.$parent.sort.criteria = sort.criteria;
        $scope.toggle(true);
      };
      $scope.clear = function() {
        filters.category = [];
        filters.countCat = 0;
        filters.sizes = [];
        filters.colors = [];
        filters.brands = [];
        if ($stateParams.brand) {
          filters.brands.push($stateParams.brand);
        } else {
          if ($stateParams.category1) {
            filters.category.push($stateParams.category1);
          }
        }
        filters.occasion = undefined;
        filters.price = { min: undefined, max: undefined };
        filters.discount = 0;
        filters.sale = false;
        sort.criteria = '';
      };

      $scope.getFilterHeader = function() {
        if ($state.current.name.indexOf('search') >= 0) {
          return 'search';
        }
        if (filters.category && filters.category[0]) {
          return filters.category[0];
        }
        return 'what\'s new';
      };
    }
  };
}

module.exports = DropDownFilterDirective;
