
// @ngInject
function TheMileController($scope, showService, influencerService, userService,
                           formatService, config, platformService) {

  $scope.isMobile = platformService.isMobile;

  if (config.featureFlags.theMileInfo.enabled) {
    var hideTestItems = config.environment === 'production';
    $scope.showsDailyLooks = {
      status: 'live,posted,scheduled',
      tags: 'u.daily-looks',
      sort: 'schedule(d)'
    };
    $scope.showsDateNightVibes = {
      status: 'live,posted,scheduled',
      tags: 'u.date-night-vibes',
      sort: 'schedule(d)'
    };
    $scope.showsGrwm = {
      status: 'live,posted,scheduled',
      tags: 'u.grwm',
      sort: 'schedule(d)'
    };
    $scope.showsManeStyles = {
      status: 'live,posted,scheduled',
      tags: 'u.mane-styles',
      sort: 'schedule(d)'
    };
    $scope.showsVacationChic = {
      status: 'live,posted,scheduled',
      tags: 'u.vacation-chic',
      sort: 'schedule(d)'
    };
    $scope.showsPartyTime = {
      status: 'live,posted,scheduled',
      tags: 'u.party-time',
      sort: 'schedule(d)'
    };

    $scope.showsWhatsNewTerm = {
      status: 'posted',
      limit: 30,
      sort: 'schedule(d)'
    };

    $scope.showsLiveNowTerm = {
      status: 'live',
      sort: 'createdAt',
      refreshAfter: 5
    };

    $scope.showsOrchardMileTerm = {
      isBrandContent: true,
      isReel: true,
      sort: 'createdAt(d)',
      status: 'posted'
    };

    $scope.showsFutureTerm = {
      status: 'scheduled',
      sort: 'schedule'
    };

    $scope.occasions = [
      {
        tag: 'u-daily-looks',
        title: 'Daily Looks',
        description: 'Relaxed and effortless meets stylish and put-together.',
        image: '../../../img/the-mile/tiles/u-daily-looks.jpg',
        alt: 'The Mile: Daily Looks'
      },
      {
        tag: 'u-date-night-vibes',
        title: 'Night Out',
        description: 'Ready, set, go: Girls Night or Date Night!',
        position: 'right',
        color: 'white',
        image: '../../../img/the-mile/tiles/u-date-night-vibes.jpg',
        alt: 'The Mile: Date Night'
      },
      {
        tag: 'u-grwm',
        title: 'GRWM',
        description: 'View the latest beauty routines from trusted influencers.',
        image: '../../../img/the-mile/tiles/u-grwm.jpg',
        alt: 'The Mile: GRWM'
      },
      {
        tag: 'u-vacation-chic',
        title: 'Vacation Chic',
        description: 'Comfortable and breezy meets stylish and cool for your next holiday.',
        color: 'white',
        image: '../../../img/the-mile/tiles/u-vacation-chic.jpg',
        alt: 'The Mile: Vacation Chic'
      },
      {
        tag: 'u-mane-styles',
        title: 'Mane Styles',
        description: 'Discover new trends, techniques and the latest products for you‘do.',
        position: 'right',
        color: 'white',
        image: '../../../img/the-mile/tiles/u-mane-styles.jpg',
        alt: 'The Mile: Mane Styles'
      },
      {
        tag: 'u-party-time',
        title: 'Party Time',
        description:
        'Event looks are all about dressing to impress and making a statement, check out these shows for inspiration',
        position: 'right',
        image: '../../../img/the-mile/tiles/u-party-time.jpg',
        alt: 'The Mile: Party Time'
      }
    ];

    $scope.showsTrendingIds= config.featureFlags.theMileInfo.trendingShows;

    $scope.influencersIds = config.featureFlags.theMileInfo.influencers;

    $scope.featuredBrands = config.featureFlags.theMileInfo.featuredBrands;
  }

  $scope.search = function() {
    if ($scope.searchTermShow && $scope.searchTermShow.length > 2) {
      $scope.results = undefined;
      $scope.selectedInf = undefined;
      $scope.influencerShows = undefined;
      showService.getNormalizedShowsByTerm({
        term: $scope.searchTermShow,
        status: 'live,scheduled,posted'
      }, false, hideTestItems).then(function(shows) {
        influencerService.addInfluencerToShows(shows).then(function() {
          $scope.results = shows;
        });
      });
    } else {
      $scope.results = undefined;
    }
  };

  var showOnPx = 200;
  var backToTopButton = document.querySelector('.back-to-top');
  var scrollContainer = function() {
    return document.documentElement || document.body;
  };
  document.addEventListener('scroll', function() {
    if (scrollContainer().scrollTop > showOnPx) {
      backToTopButton.classList.remove('hidden');
    } else {
      backToTopButton.classList.add('hidden');
    }
  });
}

module.exports = TheMileController;
