// @ngInject
function BrandSubstoresDirective(fulfillerService, brandService, platformService, $stateParams, $state) {
  var linker = function($scope, $element, $attrs) {

    $scope.substores = [];

    var brandId = $stateParams.brand;

    $scope.setSubstoresData = function(partnerId) {
      fulfillerService.getById(partnerId).then(function(partner) {
        $scope.partner = partner;
        var partnerId = $scope.partner.id;
        var partnerName = $scope.partner.name;
        var onlyKidsFlag = $scope.featureFlags.onlyKids && $scope.featureFlags.onlyKids.enabled;
        var kidBrandID = $scope.relatedKidsBrand && $scope.relatedKidsBrand.id;
        var womenBrandID = $scope.relatedWomanBrand && $scope.relatedWomanBrand.id;
        brandService.getAll().then(function(brands) {
          $scope.substores = brands.filter(function(brand) {
            return brand.id.indexOf(partnerId) !== -1 &&
              brand.id !== brandId &&
              (!onlyKidsFlag || [kidBrandID, womenBrandID].indexOf(brand.id) === -1);
          }).map(function(brand) {
            var matches = brand.name.match(new RegExp('(.*)(' + partnerName + ')(.*)'));
            var division = matches[1] + matches[3];
            brand.division = division.trim();
            return brand;
          }).sort(function(a, b) {
            return a.division > b.division ? 1 : -1;
          });
          if (onlyKidsFlag && kidBrandID && kidBrandID !== brandId) {
            $scope.substores.push({
              id: kidBrandID,
              division: 'Kids'
            });
          }
          if (onlyKidsFlag && womenBrandID && womenBrandID !== brandId) {
            $scope.substores.push({
              id: womenBrandID,
              division: 'Women'
            });
          }
        });
      });
    };

    $scope.setSpecificStores = function(specificStores) {
      for (var i in specificStores) {
        $scope.substores.push({
          id: specificStores[i].id,
          division: specificStores[i].division
        });
      }
    };

    window.goToSubstore = function(id) {
      $state.go('root.brand-products', {
        brand: id,
        page: 1
      });
    };

    if ($attrs.partner) {
      $scope.setSubstoresData($attrs.partner);
    }

    if ($attrs.stores) {
      $scope.setSpecificStores(JSON.parse($attrs.stores));
    }

    $scope.isMobile = platformService.isMobile();

  };

  return {
    link: linker,
    templateUrl: '/views/partials/brand-substores.html',
    restrict: 'EA',
    scope: true
  };
}

module.exports = BrandSubstoresDirective;
