var EventEmitter = require('events').EventEmitter;
var promises = require('../../async/promises');

// @ngInject
function LoyaltyService(apiService) {
  this.events = new EventEmitter();

  this.getLoyaltyPartners = function() {
    return promises.extend(apiService.get('/loyalty/partners'));
  };

  // For admins to get loyalty info of different users
  this.getLoyaltyByTypeAndStatus = function(type, status) {
    return promises.extend(
      apiService.get('loyalty/activities', {
        urlParams: {
          type: type,
          status: status
        }
      })
    );
  };

  // For admins to work with loyaltyStatus
  this.patchWithdrawStatus = function(userId, withdrawId, newStatus) {
    return promises.extend(
      apiService.put('users/{userId}/withdraw/{activityId}', {
        pathParams: {
          userId: userId,
          activityId: withdrawId
        },
        urlParams: {
          status: newStatus
        }
      })
    );
  };
}

module.exports = LoyaltyService;
