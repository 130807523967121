var _ = require('lodash-core');

// @ngInject
function AdminReturnsController($scope, returnService, $state, $stateParams) {
  $scope.searchTerm = '';
  $scope.hideTestOrders = true;
  $scope.loading = false;

  $scope.statuses = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'pending-processing',
      name: 'Pending Processing'
    },
    {
      id: 'processed',
      name: 'Processed'
    },
    {
      id: 'refunded',
      name: 'Refunded'
    },
    {
      id: 'cancelled',
      name: 'Cancelled'
    },
    {
      id: 'rejected',
      name: 'Rejected'
    }
  ];

  var search = _.debounce(function(term, prev, force) {
    if (!term) {
      term = '';
    }
    if (!force && term === prev) {
      return;
    }
    $state.go($state.current.name, { search: term }, { notify: false });
    $scope.loading = true;
    var params = {
      term: term,
      status: status,
      hideTestOrders: $scope.hideTestOrders
    };
    var promise = returnService.searchReturns(params);
    $scope.returns = promise.lazyObject();
    promise.then(function() {
      $scope.loading = false;
    });
  }, 1000);

  $scope.$watch('hideTestOrders', function() {
    search($scope.searchTerm, '', true);
  });

  $scope.$watch('status', function() {
    search($scope.searchTerm, '', true);
  });

  $scope.$watch(function() { return JSON.stringify($stateParams); }, function() {
    $scope.searchTerm = $stateParams.search;
  });

  $scope.keypress = function($event) {
    if ($event.which === 13) {
      search($scope.searchTerm, '', false);
    }
  };
}

module.exports = AdminReturnsController;
