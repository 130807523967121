// @ngInject
function OccasionsCarouselDirective() {
  var linker = function ($scope, element) {
    element.on('click', function(e){
      e.preventDefault();
      if(e.target.outerHTML) {
        var match = e.target.outerHTML.match(/name="(.*?)"/);
        if (match) {
          $scope.goToSection(match[1]);
        }
      }
    });
    $scope.goToSection = function(section) {
      var anchor = document.getElementById(section);
      if (anchor) {
        anchor.scrollIntoView();
      }
    };
  };


  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/occasions-carousel.html',
    restrict: 'E',
    scope: false,
  };
}

module.exports = OccasionsCarouselDirective;
