var _ = require('lodash-core');

// @ngInject
function CsvFileImporterDirective(fileReaderService) {
  var linker = function($scope, element) {

    var csv = $scope.csvFileImporter = {
      ready: false,
      reading: false,
      progress: 0,
      data: [],
      error: ''
    };

    element.find('#csv-file').on('change', function() {
      csv.ready = false;
      csv.reading = !!this.files.length;
      csv.progress = 0;
      csv.error = '';
      csv.data = [];
      $scope.$broadcast('csvFileChange');
      $scope.$digest();
      if (!this.files.length) {
        return;
      }
      fileReaderService.readAsText(this.files[0], $scope).then(function(result) {
        var rows = _.compact(result.split(/$/m));
        csv.data = _(rows)
          .filter(function(row) {
            return /[^, \n\r\t]/.test(row);
          })
          .map(function(row) {
            return row.replace(/,\s+/g, ',').replace(/,+$/, '').split(',');
          }).value();
        csv.ready = true;
        csv.reading = false;
        csv.error = '';
      }, function() {
        csv.reading = false;
        csv.error = 'Cannot read the csv file.';
        csv.data = [];
      });
      $scope.$on('fileProgress', function(progress) {
        csv.progress = Math.round((progress.loaded * 100) / progress.total);
      });
    });

  };

  return {
    link: linker,
    templateUrl: '/views/partials/csv-file-importer.html',
    restrict: 'E'
  };
}

module.exports = CsvFileImporterDirective;
