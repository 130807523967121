// @ngInject
function CheckoutV2Controller($scope) {

  $scope.getStepClasses = function(step) {
    var index = $scope.steps.indexOf(step);
    var currentIndex = $scope.steps.indexOf($scope.step);
    var classes = {
      visited: index < currentIndex || ($scope.validSteps && $scope.validSteps[step]),
      active: index === currentIndex
    };
    classes['step-' + step] = true;
    return classes;
  };

  $scope.goNextStep = function() {
    var nextStep = $scope.steps[$scope.steps.indexOf($scope.step) + 1];
    if (!nextStep) {
      return;
    }
    $scope.goToStep(nextStep);
  };
}

module.exports = CheckoutV2Controller;
