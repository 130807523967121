// @ngInject
function SidebarDirective() {
  var linker = function($scope, element, attrs) {
    attrs.saveBottomOffset = attrs.saveBottomOffset === 'true';
    angular.element(element).smartSidebar(attrs);
  };
  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = SidebarDirective;
