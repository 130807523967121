var promises = require('../../async/promises');

// @ngInject
function UserPageViewService(apiService) {

  this.getViews = function(userId) {
    return promises.extend(apiService.get('/user-page-views/{userId}', {
      pathParams: {
        userId: userId
      }
    }));
  };

  this.trackView = function(pageview) {
    return promises.extend(apiService.post('/user-page-views', {
      body: pageview
    }));
  };
}

module.exports = UserPageViewService;
