var _ = require('lodash-core');
// @ngInject
function ProductSourceListItemsDirective($parse) {
  function linker($scope, element, attrs) {

    $scope.getItems = function() {
      return attrs.items ? $parse(attrs.items)($scope) : $scope.products;
    };

    $scope.getTargetItems = function() {
      return $parse(attrs.targetItems)($scope);
    };

    $scope.addProduct = function(product) {
      var targetItems = $scope.getTargetItems();
      _.remove(targetItems, { id: product.id });
      targetItems.push(product);
    };
  }
  return {
    link: linker,
    templateUrl: '/views/partials/product-source-list-items.html',
    scope: true,
    restrict: 'E'
  };
}

module.exports = ProductSourceListItemsDirective;

