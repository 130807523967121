// @ngInject
function ReviewStarsDirective() {
  var linker = function(scope) {
    scope.stars = [];

    var updateStars = function(rating) {
      scope.stars = [];

      for (var i = 0; i < 5; i++) {
        if (rating > i) {
          if (rating > i && rating < i + 1) {
            scope.stars.push({ class: 'star-half' });
          } else {
            scope.stars.push({ class: 'star-full' });
          }
        } else {
          scope.stars.push({ class: 'star-empty' });
        }
      }
    };

    scope.setRating = function(rating, event) {
      var rect = event.target.getBoundingClientRect();
      var hoverX = event.clientX - rect.left;
      var starWidth = rect.width;
      if (hoverX > starWidth / 2) {
        scope.review.rating = rating + 1;
        updateStars(rating + 1);
      } else {
        scope.review.rating = rating + 0.5;
        updateStars(rating + 0.5);
      }
    };

    scope.hoverRating = function(rating, event) {
      var rect = event.target.getBoundingClientRect();
      var hoverX = event.clientX - rect.left;
      var starWidth = rect.width;
      if (hoverX > starWidth / 2) {
        updateStars(rating + 1);
      } else {
        updateStars(rating + 0.5);
      }
    };

    scope.resetHover = function() {
      updateStars(scope.review.rating);
    };

    updateStars();
  };

  return {
    link: linker,
    restrict: 'E',
    templateUrl: '/views/partials/review-stars.html',
    scope: true
  };
}

module.exports = ReviewStarsDirective;
