var promises = require('../../async/promises');

// @ngInject
function ColorService(apiService) {

  this.get = function(params) {
    return promises.extend(apiService.get('/colors', {
      urlParams: params
    }));
  };

  this.getByOriginalColor = function(originalColor) {
    return promises.extend(apiService.get('/colors/{originalColor}', {
      pathParams: {
        originalColor: originalColor
      }
    }));
  };

  this.save = function(color) {
    if (color.id) {
      return promises.extend(apiService.put('/colors', {
        body: color
      }));
    } else {
      return promises.extend(apiService.post('/colors', {
        body: color
      }));
    }
  };
}

module.exports = ColorService;
