var _ = require('lodash-core');
var categoryHelper = require('shared-business-rules').categories;

/* jshint maxparams: 15 */
// @ngInject
function BrandProductsController($scope, $rootScope, $state, $stateParams, brandService, errorService, config,
  analyticsService, listContextService, platformService, staticAssetService) {

  $scope.isMobile = platformService.isMobile();
  $scope.isSale = $stateParams.sale;
  $scope.featureFlags = config.featureFlags;
  document.body.style.overflow = 'auto';
  delete $rootScope.influencerId;

  var brand = window.orchardMileContent.brands[$stateParams.brand];

  var influencerBrand = _.find(config.featureFlags.influencerBrands.brands, function(influencerBrand) {
    return influencerBrand.brandId === brand.id;
  });

  if (influencerBrand) {
    $rootScope.influencerId = influencerBrand.influencerId;
  }

  $scope.showsInfluencerTerm = {
    'influencers-ids': $rootScope.influencerId,
    sort: 'createdAt',
    status: 'live,scheduled,posted'
  };

  if ($state.current.name === 'root.brand-products') { // support brands without its own store
    if (brand.store && brand.store !== $stateParams.brand) {
      var requestedBrand = $stateParams.brand;
      $stateParams.brand = brand.store;
      $stateParams.designers = requestedBrand;
      return $state.go($state.current.name, $stateParams);
    } else if (!brand.store && $stateParams.designers &&
      window.orchardMileContent.brands[$stateParams.designers] &&
      window.orchardMileContent.brands[$stateParams.designers].store !== $stateParams.brand) {
      delete $stateParams.designers;
      return $state.go($state.current.name, $stateParams);
    }
  }

  $scope.getDefaultIndex = function() {
    if (!$scope.featureFlags.sortByMultiBrandScoreOnRelevant.enabled) {
      return 'scores-whatsnew';
    }

    return (brand.store) ? 'scores-multibrand' : 'scores-whatsnew';
  };

  $scope.brandId = $stateParams.brand;
  brandService.hasKidsBrandAssociated($scope.brandId).then(function(relatedKidsBrand) {
    $scope.relatedKidsBrand = relatedKidsBrand;
    var relatedKidsBrandId = relatedKidsBrand ? relatedKidsBrand.id : $scope.brandId;
    var relatedWomanBrandId = relatedKidsBrandId.replace('-kids', '');
    brandService.getBrand(relatedWomanBrandId).then(function(brand) {
      if (brand && brand.id) {
        $scope.relatedWomanBrand = {
          id: brand.id
        };
      }
    });
  });

  $scope.brand = { id: $scope.brandId, name: $scope.brandId };
  $scope.brandMessage = undefined;

  listContextService.setScopeContext($scope);
  var filters = $scope.filters;

  brandService.getBrand($scope.brandId).then(function(brand) {
    if (!brand) {
      errorService.notFound();
      return;
    }
    $scope.brand = brand;
    updateMetadata();
  });

  $scope.lastTrackedCategory = null;

  $scope.promotionBannerEnabled = function() {
    if (config.featureFlags.theMilePromotionBanner.enabled) {
      return !config.featureFlags.theMilePromotionBanner.blockedBrands.includes($scope.brandId);
    } else {
      return false;
    }
  };

  // breadcrumb
  $scope.$watch('filters.category', updateMetadata);
  $scope.$watch('brandId', updateMetadata);

  function updateMetadata() {
    var breadcrumbs = [{
      name: $scope.brand.name || $scope.brandId,
      link: $scope.brand.visible ? $state.href('root.brand-products', { brand: $scope.brandId }) : undefined
    }];
    var categoryName = categoryHelper.getCategoriesFromTags($scope.filters.category);
    _.forEach($scope.filters.category, function(node, index) {
      breadcrumbs.push({
        name: categoryName[index],
        link: $state.href('root.brand-products.category' + (index + 1), {
          brand: $scope.brandId,
          category1: filters.category[0],
          category2: filters.category[1],
          category3: filters.category[2]
        })
      });
    });
    $scope.setBreadcrumbPath(breadcrumbs);

    if ($scope.filters.category && $scope.filters.category.length) {
      var categoryTitle = categoryName.join(' / ').replace(/(^|[^\w])(\w)/g, function(l) { return l.toUpperCase(); });
      categoryTitle = 'Shop ' + categoryTitle + ' from ' + $scope.brand.name + ' at THE MILE with free shipping';
      categoryTitle += ' and returns';
      $scope.setPageMetadata({
        title: categoryTitle,
        description: $scope.brand.seoDescription || $scope.brand.description,
        image: $scope.brand.hero === false ? undefined : $scope.heroImageUrl
      });
    } else {
      var brandTitle = 'Shop ' + $scope.brand.name + ' at THE MILE with free shipping';
      brandTitle += ' and returns';
      $scope.setPageMetadata({
        title: brandTitle,
        description: $scope.brand.seoDescription || $scope.brand.description,
        image: $scope.brand.hero === false ? undefined : staticAssetService.brand($scope.brandId).url('heroImage'),
        shareImage: $scope.brand.hero === false ? undefined : staticAssetService.brand($scope.brandId).url('heroImage')
      });
    }
    $scope.setCanonicalUrl({ whitelist: [] });
  }
}

module.exports = BrandProductsController;
