var sharedBusinessRules = require('shared-business-rules');

// @ngInject
function AdminFulfillerOrdersController($scope, $stateParams, $timeout, fulfillerService, formsService) {
  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.from = new Date();
  $scope.from.setMonth($scope.from.getMonth() - 3);
  $scope.from.setHours(0, 0, 0, 0);
  $scope.to = new Date();
  $scope.to.setHours(23, 59, 59, 999);
  $scope.selectedFulfiller = {};
  $scope.newFormat = true;
  $scope.newFormatInternal = false;

  $scope.getFulfillers = function() {
    return fulfillerService.getAll().lazyArray();
  };

  $scope.selectFormat = function(format) {
    $scope.newFormat = format === 'newFormat';
    $scope.newFormatInternal = format === 'newFormatInternal';
  };

  $scope.loadScripts = function() {
    $('#xls1').remove();
    $('#xls2').remove();
    // Create an async script.
    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.id = 'xls1';

    script.src = ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'unpkg.com/xlsx/dist/shim.min.js';
    // Insert our script next to the first script element.
    var scripts = document.getElementsByTagName('script');
    var last = scripts[scripts.length - 1];
    last.parentNode.insertBefore(script, last.nextSibling);
    // Create an async script.
    var script2 = document.createElement('script');

    script2.type = 'text/javascript';
    script2.async = true;
    script2.id = 'xls2';

    script2.src = ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'unpkg.com/xlsx/dist/xlsx.full.min.js';
    scripts = document.getElementsByTagName('script');
    last = scripts[scripts.length - 1];
    last.parentNode.insertBefore(script2, last.nextSibling);
  };

  $scope.get = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }

    fulfillerService.getById($scope.fulfiller.id).then(function(info) {
      $scope.selectedFulfiller = info;
      var from = new Date($scope.from);
      from.setMonth($scope.from.getMonth() - 5);
      from.setHours(0, 0, 0, 0);
      formPromise.handle(
        fulfillerService.getOrders($scope.fulfiller.id, from, $scope.to).then(dataToXLS).then(downloadXLS)
      );
    });
  };

  function dataToXLS(data) {
    var invoiceData = sharedBusinessRules.invoice.getInvoiceData(
      data.items,
      data.returns,
      $scope.selectedFulfiller,
      $scope.from,
      $scope.to,
      $scope.newFormatInternal
    );
    return invoiceData.rows.general;
  }

  function downloadXLS(data) {
    /* jshint camelcase: false */
    var filename = 'fulfiller-' + $scope.fulfiller.id + '-orders.xlsx';
    var wsName = 'Orchardmile';

    var wb = window.XLSX.utils.book_new(), ws = window.XLSX.utils.aoa_to_sheet(data);

    /* add worksheet to workbook */
    window.XLSX.utils.book_append_sheet(wb, ws, wsName);

    /* write workbook */
    window.XLSX.writeFile(wb, filename);
  }
}

module.exports = AdminFulfillerOrdersController;
