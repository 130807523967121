var _ = require('lodash-core');

/* globals $ */
/*jshint maxparams: 10 */

// @ngInject
function BrandsController($scope, $state, $rootScope,
                          brandService, productService, $stateParams, viewportService, searchService) {

  var allBrands = [];
  $scope.filteredBrands = [];
  $scope.alphabeticalList = [];
  $scope.athleisureList = [];
  $scope.categoryList = [];
  $scope.boutiquesList = [];

  $scope.productListName = 'designers-list';
  $scope.group = $stateParams.group;
  $scope.sale = $stateParams.sale;
  $scope.listMode = getListMode();
  $scope.filterCategories = ['boutiques', 'athleisure', 'clothing',
    'shoes', 'bags', 'accessories', 'wellness', 'beauty', 'home', 'kids', 'men'];
  $scope.minSizeForAbc = 5;

  $scope.setPageMetadata({
    title: 'Shop Designers at THE MILE'
  });

  $scope.$on('viewportBreakpointChange', loadBrands);

  $scope.isLowRes = viewportService.width() < 1200;

  var capitalize = function(string) {
    if (!string) {
      return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  $scope.$watch(function() {
    return $stateParams.group;
  }, function() {
    if ($stateParams.group) {
      $scope.setBreadcrumbPath([{name: 'designers', link: '/designers'},
        {name: $stateParams.group || ''}]);
    } else {
      $scope.setBreadcrumbPath([{
        name: 'designers'
      }]);
    }

    $scope.group = $stateParams.group;

    $scope.tt = _.filter($scope.options, {label: capitalize($scope.group) || 'All'})[0].value;
    $scope.listMode = getListMode();
    loadBrands();
    if (['boutiques', 'athleisure'].indexOf($stateParams.group) === -1) {
      $scope.categoryName = $scope.group;
    } else {
      $scope.categoryName = undefined;
    }
  });

  setupKeyboardBehavior();

  // -- end of initializer

  $scope.scrollToBrand = function(brandId) {
    $scope.lastJumpBrand = brandId;
    var elem = document.querySelector('.brands .brand-' + brandId);
    if (elem) {
      $scope.scrollTo(0, $(elem).offset().top - 80);
    }
  };

  $scope.getListName = function() {
    return 'designers';
  };

  $scope.goToHash = function(event, hash) {
    if (window.location.hash === hash) {
      event.preventDefault();
      window.location.hash = '#_';
      setTimeout(function() {
        window.location.hash = hash;
      }, 100, hash);
    }
  };

  function getListMode() {
    $scope.categoryMode = false;
    $scope.group = $stateParams.group;

    if (['designer', 'contemporary'].indexOf($stateParams.group) >= 0) {
      return 'showcase';
    } else if ($stateParams.group === 'athleisure') {
      return 'athleisure';
    } else if ($stateParams.group === 'alphabetical') {
      return 'alphabetical';
    } else if ($stateParams.group === 'boutiques') {
      return 'boutiques';
    } else {
      if (!window.orchardMileContent.dropdowns[$stateParams.group]) {
        return 'alphabetical';
      }

      $scope.categoryName = $stateParams.group;
      $scope.categoryMode = true;
      return 'category';
    }
  }

  function getBrandsToFilter() {
    var rule = _.find($rootScope.featureFlags.internationalBlockedBrands.rules, function (rule) {
      return rule.countries.includes($rootScope.countrySelected());
    });
    if (rule && $rootScope.featureFlags.internationalBlockedBrands.enabled) {
      return rule.brands;
    } else {
      return [];
    }
  }

  function filterBrandsByCountry(brands) {
    if ($rootScope.featureFlags.internationalBlockedBrands.enabled && $rootScope.countrySelected() !== 'us') {
      if (brands && brands.length > 0) {
        var blockedBrands = getBrandsToFilter();
        var filteredBrands = _.filter(brands, function (brand) {
          return !blockedBrands.includes(brand.id);
        });
        if (filteredBrands && filteredBrands.length) {
          return filteredBrands;
        } else {
          return ['non-exist-brand'];
        }
      }
    }
    return brands;
  }

  function loadBrands() {
    if (allBrands.length) {
      populateList();
    } else {
      brandService.getAll('name').then(function(brands) {
        brands = _.filter(brands, function(brand) {
          return brand.visible === true && brand.id !== 'orchard-mile';
        });
        brands = filterBrandsByCountry(brands);
        allBrands = _.sortBy(brands, function(each) {
          each.sanitizedName = each.name.replace(/[éè¨ê]/gi, 'e');
          return /^[^a-z]+/i.test(each.name) ? 'zzz' + each.name.toLowerCase() : each.name.toLowerCase();
        });
        populateList();
      });
    }
  }

  function populateList() {
    var listMode = getListMode();
    if (listMode === 'alphabetical') {
      populateAlphabeticalList();
    } else if (listMode === 'athleisure') {
      populateAthleisureList();
    } else if (listMode === 'boutiques') {
      populateMultibrandList();
    } else if (listMode === 'showcase') {
      populateFilteredList();
    } else if (listMode === 'category') {
      populateCategoryList();
    }
  }

  function chunk(arr, size) {
    var newArr = [];
    for (var i = 0; i < arr.length; i += size) {
      newArr.push(arr.slice(i, i + size));
    }
    return newArr;
  }

  function prepareBrandList(brands) {
    var groupedBrands = _.groupBy(brands, function(brand) {
      var firstChar = brand.name.substring(0, 1);
      if (firstChar.match(/^[a-z]/i)) { //Group no-letter initials brands as #
        return firstChar.toUpperCase();
      } else {
        return '#';
      }
    });

    var letters = [];
    Object.keys(groupedBrands).forEach(function(letter) {
      if ($scope.isLowRes) {
        letters.push({
          letter: letter,
          brands: _.sortBy(groupedBrands[letter], function(each) {
            return each.sanitizedName.toLowerCase();
          })
        });
      } else {
        letters.push({
          letter: letter,
          brands: chunk(_.sortBy(groupedBrands[letter], function(each) {
            return each.sanitizedName.toLowerCase();
          }), Math.ceil(groupedBrands[letter].length / 3))
        });
      }
    });
    // move # to the bottom
    if (letters[0] && letters[0].letter === '#') {
      var numeral = letters.splice(0, 1)[0];
      letters.push(numeral);
    }

    $scope.alphabet = _.map(letters, 'letter');
    $scope.alphabeticalList = letters;
  }

  function populateAlphabeticalList() {
    prepareBrandList(allBrands);
  }

  function populateMultibrandList() {
    if ($scope.boutiquesList && $scope.boutiquesList.length) {
      return;
    }
    var stores = _.map(allBrands, _.property('store'));
    var multiBrandStores = _.filter(allBrands, function(brand) {
      return stores.indexOf(brand.id) >= 0;
    });
    var together = _.sortBy(multiBrandStores, function(each) {
      return /^[^a-z]+/i.test(each.name) ? 'zzz' + each.name.toLowerCase() : each.name.toLowerCase();
    });

    prepareBrandList(together);
  }

  function populateAthleisureList() {
    if ($scope.athleisureList && $scope.athleisureList.length) {
      prepareBrandList($scope.athleisureList);
      return;
    }

    var athleisureBrands = _.filter(allBrands, function(brand) {
      return brandService.isFromGroup(brand, 'athleisure');
    });

    var together = _.sortBy(athleisureBrands, function(each) {
      return /^[^a-z]+/i.test(each.name) ? 'zzz' + each.name.toLowerCase() : each.name.toLowerCase();
    });

    $scope.athleisureList = together;
    prepareBrandList(together);
  }

  function populateCategoryList() {

    searchService.getFacets({tags: [$stateParams.group]}).then(function(responses) {
      var brandFacets = responses && responses.brandId;
      var brands = _.filter(allBrands, function(each) {
        return Object.keys(brandFacets).indexOf(each.id) >= 0;
      });

      prepareBrandList(brands);
    });
  }

  function populateFilteredList() {
    $scope.filteredBrands = _
      .chain(allBrands)
      .filter(function(brand) {
        return brandService.isFromGroup(brand, $stateParams.group) && brand.hero;
      })
      .sortBy(function(brand) {
        return /^[^a-z]+/i.test(brand.name) ? 'zzz' + brand.name.toLowerCase() : brand.name.toLowerCase();
      })
      .value();
    var chunk = [];
    var timeout = 0;
    _.forEach($scope.filteredBrands, function(brand) {
      if (!brand.products) {
        chunk.push(brand);
      }
      if (chunk.length === 5) {
        loadShowcases(chunk, timeout);
        chunk = [];
        timeout += 500;
      }
    });
    if (chunk.length) {
      loadShowcases(chunk, timeout);
    }
  }

  function loadShowcases(brands, timeout) {
    if (!brands.length) {
      return;
    }
    var brandIds = _.map(brands, 'id').join(',');
    setTimeout(function() {
      productService.getShowcaseProductsByBrand(brandIds).then(function(data) {
        _.forEach(data.brands, function(brand) {
          var targetBrand = _.find(brands, {id: brand.id});
          targetBrand.products = brand.products;
        });
      });
    }, timeout);
  }

  function setupKeyboardBehavior() {
    $scope.lastJumpBrand = null;
    $scope.$on('globalKeydown', function(e, keyboardEvent) {
      var char = String.fromCharCode(keyboardEvent.keyCode);
      if (!/^[a-z0-9]$/i.test(char)) {
        return;
      }
      char = char.toLowerCase();

      var selectedBrands = $scope.filteredBrands.filter(function(brand) {
        return brand.id.substr(0, 1).toLowerCase() === char;
      });
      if (!selectedBrands.length) {
        return;
      }
      var nextBrand;
      if ($scope.lastJumpBrand && char === $scope.lastJumpBrand.substr(0, 1).toLowerCase()) {
        var index = _.findIndex(selectedBrands, function(brand) {
          return brand.id === $scope.lastJumpBrand;
        });
        index++;
        nextBrand = selectedBrands[index >= selectedBrands.length ? 0 : index];
      } else {
        nextBrand = selectedBrands[0];
      }
      $scope.scrollToBrand(nextBrand.id);
    });
  }

  $scope.options = [
    {label: 'All', value: {state: 'root.brands'}}
  ];
  _.forEach($scope.filterCategories, function(each) {
    $scope.options.push({label: capitalize(each), value: {state: 'root.brands.group', params: {group: each}}});
  });

  $scope.tt = _.filter($scope.options, {label: capitalize($scope.group) || 'All'})[0].value;

  $scope.goNewState = function(tt) {
    if (tt) {
      $state.go(tt.state, tt.params);
    }
  };
}

module.exports = BrandsController;
