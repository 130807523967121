// @ngInject
function BrandHeroDirective($rootScope, staticAssetService, brandService, promoService, viewportService) {

  var linker = function($scope, _elem, attrs) {
    if (attrs.brandId) {
      $scope.brandId = attrs.brandId.replace(/["']/g, '');
      $scope.brand = { id: $scope.brandId, name: $scope.brandId, hero: false, visible: false };
      $scope.brandMessage = undefined;
      brandService.getBrand($scope.brandId).then(function(brand) {
        if (brand) {
          $scope.brand = brand;
        }
      });
    }

    var brandAssets = staticAssetService.brand($scope.brandId);

    if ($rootScope.featureFlags.influencerBrands.enabled && $rootScope.influencerId) {
      if (viewportService.is('<sm')) {
        $scope.heroImageUrl = brandAssets.url('heroMobileImage');
      } else if (viewportService.is('>md')) {
        $scope.heroImageUrl = brandAssets.url('heroImage');
      } else {
        $scope.heroImageUrl = brandAssets.url('heroTabletImage');
      }
    } else {
      if (viewportService.is('<md')) {
        $scope.heroImageUrl = brandAssets.url('heroMobileImage');
      } else if (viewportService.is('>md')) {
        $scope.heroImageUrl = brandAssets.url('heroImage');
      }  
    }

    promoService.getPublicForBrand($scope.brandId).then(function(promos) {
      $scope.promos = promos;
    });

    $scope.getBrandHtmlMessage = function() {
      var bannerPromo = $scope.promos && $scope.promos.filter(function(promo) {
        return !!promo.htmlMessage;
      })[0];
      if (bannerPromo) {
        return (bannerPromo && bannerPromo.htmlMessage) || '';
      }
      var message = (!$scope.brand.plpMessage || !$scope.brand.overrideFulfillerPlpMessage) ?
        $scope.brand.fulfillerPlpMessage : $scope.brand.plpMessage;
      if (message) {
        return message;
      }
      return false;
    };
  };

  return {
    link: linker,
    templateUrl: '/views/partials/brand-hero.html',
    restrict: 'E',
    scope: true
  };

}

module.exports = BrandHeroDirective;
