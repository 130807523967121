var _ = require('lodash-core');

/* jshint maxparams: 14 */

// @ngInject
function ProductListItemDirective($state, $parse, $timeout, $uibModal, config, curationService, clipboardService,
                                  cartService, productService, viewportService, userService, $rootScope,
                                  platformService, algoliaInsightsService) {

  var linker = function($scope, element, attrs) {
    $scope.product = attrs.current ? $parse(attrs.current)($scope) : $scope.product;
    $scope.link = attrs.link || $scope.link;
    $scope.hasDiscount = $scope.product.listPrice && $scope.product.listPrice !== $scope.product.price;
    $scope.updating = false;
    $scope.stylesExpanded = $scope.product.stylesExpanded = false;
    $scope.product.otherStyles = $scope.product.otherStyles || [];
    $scope.hasOtherStyles = !!$scope.product.otherStyles.length;
    $scope.styleList = $scope.product.otherStyles.concat($scope.product);
    $scope.styleIndex = $scope.styleList.length - 1;
    $scope.algolia = algoliaInsightsService.getPLPProductData($scope.product.id, $scope.$index + 1);

    $scope.maxPrice = $scope.product.maxPrice;
    $scope.minPrice = $scope.product.minPrice;
    $scope.showPriceRange = ($scope.product.showPriceRange && config.featureFlags.priceVariationMessagePLP.enabled);

    $scope.product = $scope.styleList[$scope.styleIndex];
    $scope.isLoggedIn = userService.isLoggedIn;
    $scope.toggleExpandStyles = function($event) {
      $event.preventDefault();
      $scope.stylesExpanded = $scope.product.stylesExpanded = !$scope.product.stylesExpanded;
      $scope.$emit($scope.product.stylesExpanded ? 'productListStylesExpanded' : 'productListStylesCollapsed');
      return false;
    };

    $scope.isLowInStock = function() {
      if (!$scope.product) {
        return false;
      }
      if ($scope.product && $scope.product.brand && $scope.product.brand.id === 'm-gemi') {
        $scope.product.isLowInStock = false;
      }
      return $scope.product.isLowInStock;
    };

    $scope.nextStyle = function($event) {
      $event.preventDefault();
      $scope.styleIndex -= 1;
      if ($scope.styleIndex < 0) {
        $scope.styleIndex = $scope.styleList.length - 1;
      }
      $scope.product = $scope.styleList[$scope.styleIndex];
      return false;
    };

    $scope.productClick = function(product) {
      algoliaInsightsService.sendEvent('product-click', product);
      $rootScope.lastClickedProductIndex = $scope.$index;
      productService.trackProductListItemClick({
        listName: $scope.getListName(),
        item: product,
        index: $scope.$index
      });
      return true;
    };

    $scope.getHref = function() {
      if ($scope.link === 'false' || !$scope.product.brand) {
        return '';
      }
      if ($scope.product.brand.id === 'orchard-mile') {
        return $state.href('root.gift-card');
      }
      return $state.href($scope.link || 'root.product.variant', {
        brand: $scope.product.brand.id,
        productName: $scope.product.slug,
        id: $scope.product.styleId,
        comingfrom: getComingFrom(),
        color: undefined,
        size: undefined
      });
    };

    $scope.hideItem = function(product) {
      product.hidden = true;
    };

    $scope.getProductName = function() {
      var productName = $scope.product.name;
      if ($scope.product.brand.id === 'emporio-armani') {
        productName = productName.replace('Myea', 'MyEA');
      }
      if ($scope.product.brand.id === 'jane-win') {
        productName = productName.replace('Jw', 'JW');
      }
      if (['dolce-and-gabbana', 'dolce-and-gabbana-kids'].includes($scope.product.brand.id)) {
        productName = productName.replace('Dg', 'DG');
      }
      return productName;
    };

    function getComingFrom() {
      if (platformService.isBot()) {
        return;
      }
      if ($state.current.name === 'root.influencer') {
        return 'influencer-' + $scope.influencer.id;
      }
      var comingFrom = $scope.getListName && $scope.getListName() || $state.current.name.replace('root.', '');
      if (comingFrom) {
        if ($state.params.category1) {
          comingFrom = comingFrom.replace('categories', $state.params.category1);
        }
        if ($state.params.category2) {
          comingFrom += '/' + $state.params.category2;
        }
        if ($state.params.category3) {
          comingFrom += '/' + $state.params.category3;
        }
      } else {
        comingFrom = $state.current.name;
      }
      return comingFrom;
    }

    function getRankingId() {
      return $scope.getRankingId && $scope.getRankingId();
    }

    $scope.getPrivateData = function() {
      var rankingId = getRankingId();
      if (!$scope.itemsPrivateData || !$scope.itemsPrivateData.length) {
        return;
      }
      var styles = _.filter($scope.itemsPrivateData, {id: $scope.product.id.split('-')[0]});
      if (styles.length < 1) {
        return;
      }
      var listStyle = _.find(styles, {styleId: $scope.product.styleId});
      if (!listStyle) {
        return;
      }

      var styleBestScore;
      var bestScore;
      styles.forEach(function(style) {
        var styleScore = style.scores && style.scores[rankingId] && style.scores[rankingId].value || 0;
        style.currentScore = styleScore;
        style.currentCurations = style.curations && style.curations.rankings && style.curations.rankings[rankingId];
        if (!styleBestScore || styleScore > bestScore ||
          (styleScore === bestScore && style.styleId === listStyle.styleId)) {
          styleBestScore = style;
          bestScore = styleScore;
        }
      });

      return {
        id: listStyle.id,
        style: listStyle,
        styleBestScore: styleBestScore,
        styles: styles
      };
    };

    $scope.getScoreByAttributes = function() {
      var rankingId = getRankingId();
      var privateData = $scope.getPrivateData();
      if (!privateData) {
        return;
      }
      var style = privateData.styleBestScore;
      if (!style || !style.scoresAttrs) {
        return;
      }
      var offset = (((style.curations || {}).rankings || {})[rankingId] || {}).offset || 0;
      return _.filter(
        [{id: 'offset', score: Math.abs(offset), negative: offset < 0}].concat(style.scoresAttrs[rankingId] || []),
        function(e) {
          return e.score;
        }
      );
    };

    $scope.canToggleHot = function() {
      return $scope.result;
    };

    $scope.getHotClass = function() {
      if ($scope.updating || !$scope.result || !$scope.result.curations || $scope.result.curations.length === 0) {
        return 'curations-loading';
      }
      var styleCurations = _.find($scope.result.curations, {styleId: $scope.product.styleId});
      if (!styleCurations || !styleCurations.curations || !styleCurations.curations.score ||
        styleCurations.curations.score === 0) {
        return 'curations-nothot';
      }
      return 'curations-hot';
    };

    $scope.toggleHot = function() {
      if ($scope.updating) {
        return;
      }
      $scope.updating = true;
      var styleCurations = _.find($scope.result.curations, {styleId: $scope.product.styleId});
      var newScore = !styleCurations || !styleCurations.curations || !styleCurations.curations.score ?
        Number.MAX_VALUE : 0;
      curationService.setStyleCurations($scope.product.styleId, {score: newScore}).then(function(response) {
        if (!styleCurations) {
          $scope.result.curations.push({
            id: $scope.product.id.split('-')[0],
            styleId: $scope.product.styleId,
            curations: {
              score: response.score
            }
          });
        } else {
          styleCurations.curations = styleCurations.curations || {};
          styleCurations.curations.score = response.score;
        }
        $scope.updating = false;
      });
    };

    $scope.modifyClipboard = function() {
      $scope.productChecked = $scope.product.productChecked = !$scope.product.productChecked;
      if ($scope.productChecked) {
        clipboardService.addItem($scope.product, _.filter(
          $scope.itemsPrivateData, {id: $scope.product.id.split('-')[0]}));
      } else {
        clipboardService.removeItem($scope.product);
      }
    };

    $scope.isSavedForLater = function(styleId) {
      return cartService.getVariantsSavedForLaterByStyle(styleId).length > 0;
    };

    $scope.saveForLater = function(styleId) {
      var variantsSavedForLater = cartService.getVariantsSavedForLaterByStyle(styleId);
      var qty = variantsSavedForLater.length > 0 ? -1 : 1;
      if (qty === -1) {
        variantsSavedForLater.forEach(function(variantSavedForLater) {
          addToCart(styleId, variantSavedForLater, qty, true);
        });
      } else {
        productService.getByStyleId(styleId).then(function(product) {
          var style = _.find(product.styles, {id: styleId});
          // Looking for a variant with the customer's size
          var variantToSaveForLater = _.find(style.variants, function(variant) {
            return variant.key &&
              variant.key.size &&
              variant.key.size.length &&
              variant.stock !== 0;
          });
          if (!variantToSaveForLater) {
            variantToSaveForLater = style.variants[0];
          }
          addToCart(styleId, variantToSaveForLater, qty, true);
        });
      }
    };

    $scope.quickView = function(styleId) {
      $scope.quickViewStyleId = styleId;
      $scope.quickViewTrigger = 'product-list';
      $scope.instance = $uibModal.open({
        templateUrl: '/views/partials/modals/product-quick-view.html',
        windowClass: 'product-quick-view-modal',
        size: 'lg',
        scope: $scope
      });
    };

    function addToCart(styleId, variant, qty, forLater) {
      var item = {
        styleId: styleId,
        variant: variant,
        quantity: qty,
        source: 'product-list-page-save-for-later',
        sku: variant.sku,
        comingFrom: 'product-list-page',
        forLater: forLater
      };

      cartService.add(item);
      return true;
    }

    $scope.$watch('clipboard.items.length', function() {
      $scope.productChecked = $scope.product.productChecked = clipboardService.itemIsInClipboard($scope.product);
    });
  };

  return {
    link: linker,
    templateUrl: '/views/partials/product-list-item.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ProductListItemDirective;
