// @ngInject
function CarouselCenterDirective() {

  var linker = function() {
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/carousel-center.html',
    restrict: 'E'
  };
}

module.exports = CarouselCenterDirective;
