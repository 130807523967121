// @ngInject
function AdminInviteController($scope, $stateParams, $timeout, userService, formsService) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.sendInviteEmail = false;

  if ($stateParams.email) {
    $timeout(function() {
      $scope.form.email.$setViewValue($stateParams.email);
      $scope.form.email.$render();
    }, 100);
  }

  function touchEmailField() {
    // change email field value to redo validation
    var email = $scope.email;
    $scope.form.email.$setViewValue('');
    $scope.form.email.$render();
    $timeout(function() {
      $scope.form.email.$setViewValue(email);
      $scope.form.email.$render();
    });
  }

  $scope.$watch('ifEmailFoundSendAnyway', touchEmailField);
  $scope.$watch('sendInviteEmail', touchEmailField);

  $scope.invite = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var email = $scope.email;
    var options = {
      skipEmail: !$scope.sendInviteEmail
    };
    $scope.skippedEmail = !$scope.sendInviteEmail;

    formPromise.handle(
      userService.invite(email, options)
    );

  };
}

module.exports = AdminInviteController;
