var _ = require('lodash-core');

// @ngInject
function GiftsSectionsDirective() {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/gifts-sections.html',
    scope: true,
    link: function($scope) {

      var giftsConfig = window.orchardMileContent && window.orchardMileContent.gifts;
      var groups = $scope.groups = giftsConfig && giftsConfig.groups || [
        { key: '', name: 'All gifts' },
        { key: 'under-100', name: 'Under $100', type: 'price' },
        { key: 'under-300', name: 'Under $300', type: 'price' }
      ];

      if ($scope.filters && $scope.filters.gifts) {
        $scope.$watchCollection('filters.gifts', function() {
          _.forEach(groups, function(g) { g.active = false; });
          var group = _.find(groups, { key: $scope.filters.gifts[$scope.filters.gifts.length - 1] || '' }) || groups[0];
          group.active = true;
        });
      }
    }
  };
}

module.exports = GiftsSectionsDirective;
