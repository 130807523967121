// @ngInject
function AdminPartnerPageController($scope, $state, $stateParams, fulfillerService, brandService, formsService) {

  $scope.form = {};

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  function loadPartner() {
    var partnerPromise = fulfillerService.getByIdWithoutMemoize($stateParams.id);
    partnerPromise.then(function(partner) {
      $scope.form.partner = partner;
      $scope.form.brandsToUpdate = partner.brands;
      $scope.brands = partner.brands;
      var mainBrandPromise = brandService.getByIdWithoutMemoize(partner.id);
      mainBrandPromise.then(function(brand) {
        $scope.isBoutique = brand.store;
      });
      if (partner.brands) {
        var brandPromise = brandService.getByIdWithoutMemoize(partner.brands[0]);
        brandPromise.then(function(brand) {
          if (brand) {
            $scope.form.plpMessage = brand.fulfillerPlpMessage ? brand.fulfillerPlpMessage.replace(/<br>/g, '\n') : '';
            $scope.form.additionalNotes = brand.additionalNotes ? brand.additionalNotes.replace(/<br>/g, '\n') : '';
            $scope.form.brandAvailable = !brand.unavailable;
          }
        });
      }
    });
  }

  loadPartner();

  $scope.save = function() {
    $scope.form.partner.id = $stateParams.id;
    if (!formPromise.validate($scope.form)) {
      return;
    }
    var dataToUpdate = {};
    var brandsToUpdate;
    if ($scope.isBoutique) {
      brandsToUpdate = $scope.brands;
    } else {
      brandsToUpdate = $scope.form.brandsToUpdate;
    }
    dataToUpdate.id = $scope.form.partner.id;
    dataToUpdate.unavailable = !$scope.form.brandAvailable;
    dataToUpdate.fulfillerPlpMessage = $scope.form.plpMessage ?
      $scope.form.plpMessage.replace(/\n/g, '<br>') : null;
    dataToUpdate.additionalNotes = $scope.form.additionalNotes ?
      $scope.form.additionalNotes.replace(/\n/g, '<br>') : null;
    brandsToUpdate.forEach(function(brandId) {
      dataToUpdate.id = brandId;
      formPromise.handle(brandService.update(dataToUpdate).then(
        function() {
          $state.go('admin.partner-pages');
        })
      );
    });
  };

}

module.exports = AdminPartnerPageController;
