var _ = require('lodash-core');
var dialogs = require('dialogs');

/*jshint maxparams: 10 */

// @ngInject
function AdminUserController(
  $scope,
  config,
  $state,
  $stateParams,
  userService,
  influencerService,
  cartService,
  orderService,
  productService,
  returnService
) {
  function loadUser() {
    $scope.loading = {
      user: true,
      orders: true,
      returns: true,
      mile: true,
    };
    // load user
    var userPromise = userService.getById($stateParams.id);
    $scope.user = userPromise.lazyObject();
    userPromise.then(function (user) {
      $scope.loading.user = false;
      $scope.notes = user.notes; // copy notes to compare later
      user.cart = cartService.get(user.id).lazyObject();
      user.rewards = _.sortBy(user.rewards, 'createdAt').reverse();

      // load orders
      var ordersPromise = orderService.getByUser(user.id);
      user.orders = ordersPromise.lazyArray();
      ordersPromise.then(function (orders) {
        $scope.loading.orders = false;
        if (orders && orders.length) {
          var billing = orders[0].billing;
          var shipping = orders[0].shipping;
          user.lastBilling = {
            payment:
              (billing.paymentMethod.cardIssuer || '').toUpperCase() +
              ' ends with ' +
              (billing.paymentMethod.cardNumber || '').replace(/[x ]+/gi, '') +
              ', expiration: ' +
              billing.paymentMethod.expirationMonth +
              '/' +
              billing.paymentMethod.expirationYear,
            address: formatAddress(billing.address),
          };
          user.lastShipping = {
            contact: shipping.contact.firstName + ' ' + shipping.contact.lastName + ' (' + shipping.contact.email + ')',
            address: formatAddress(shipping.address),
          };
          user.orderTotal = 0;
          user.orderTotalNoTaxes = 0;
          user.orderTotalNoTaxesCurrentYear = 0;
          _.forEach(orders, function (order) {
            user.orderTotal += order.totals.totalPrice;
            user.orderTotalNoTaxes += order.totals.totalPrice - (order.totals.taxes || 0);
            if (isFromCurrentYear(order)) {
              user.orderTotalNoTaxesCurrentYear += order.totals.totalPrice - (order.totals.taxes || 0);
            }
          });
          user.orderAov = user.orderTotalNoTaxes / orders.length;
        }
      });

      // load returns
      returnService.getAllByUser(user.id).then(function (response) {
        $scope.loading.returns = false;
        user.returnTotal = 0;
        user.returnTotalCurrentYear = 0;
        _.forEach(response.items, function (orderReturn) {
          _.forEach(orderReturn.items, function (item) {
            user.returnTotal += item.price || 0;
            if (isFromCurrentYear(orderReturn)) {
              user.returnTotalCurrentYear += item.price || 0;
            }
          });
        });
      });
    });

    $scope.updateInfluencerExtraData = function () {
      influencerService.updateInfluencer($scope.influencer.id, $scope.influencer);
    };

    var influencerPromise = influencerService.getByUserId($stateParams.id);
    influencerPromise.then(function (influencer) {
      if (influencer) {
        $scope.influencer = influencer;
        influencerService.getShowsById(influencer.id).then(function (shows) {
          $scope.shows = shows;
          shows.forEach(function (show) {
            productService.getByStyleIds(
              show.showProducts.map(
                function (showProduct) {
                  return showProduct.styleId;
                }
              )
            ).then(function (curatedProducts) {
              show.curatedProducts = curatedProducts.items;
            });
          });
          $scope.shows = $scope.shows.sort(function(a, b) {
            var dateA = a.schedule ? a.schedule : a.createdAt;
            var dateB = b.schedule ? b.schedule : b.createdAt;
            return new Date(dateB) - new Date(dateA);
          });
        });
        userService.getCloset($stateParams.id).then(function (closetProducts) {
          $scope.closetProducts = closetProducts;
        });
      }
    });
  }

  loadUser();

  $scope.currentOrdersPage = 1;
  $scope.ordersShown = '10';

  $scope.totalOrders = function() {
    if ($scope.user && $scope.user.orders) {
      return Math.ceil($scope.user.orders.length / parseInt(document.getElementById('order-size').value));
    }
    return 0;
  };

  $scope.getCurrentOrdersPage = function () {
    return $scope.currentOrdersPage;
  };

  $scope.updatePaginatedOrders = function() {
    if ($scope.user && $scope.user.orders) {
      var start = ($scope.currentOrdersPage - 1) * parseInt(document.getElementById('order-size').value);
      var end = start + parseInt(document.getElementById('order-size').value);
      $scope.paginatedOrders = $scope.user.orders.slice(start, end);
    }
  };

  $scope.previousOrdersPage = function() {
    if ($scope.currentOrdersPage > 1) {
      $scope.currentOrdersPage--;
      $scope.updatePaginatedOrders();
    }
  };

  $scope.nextOrdersPage = function() {
    if ($scope.currentOrdersPage < $scope.totalOrders()) {
      $scope.currentOrdersPage++;
      $scope.updatePaginatedOrders();
    }
  };

  $scope.$watch(function () {
    if (document.getElementById('order-size')) {
      return parseInt(document.getElementById('order-size').value);
    }
  }, function () {
    $scope.currentOrdersPage = 1;
  });

  $scope.$watch('user.orders.loading', function (obtaining, startedToObtain) {
    if (startedToObtain && !obtaining) {
      $scope.updatePaginatedOrders();
    }
  });

  $scope.currentShowsPage = 1;
  $scope.showsShown = '10';
  $scope.showTypes = 'all';

  $scope.totalShows = function() {
    if ($scope.shows) {
      return Math.ceil($scope.shows.length / parseInt(document.getElementById('shows-size').value));
    }
    return 0;
  };

  $scope.getCurrentShowsPage = function () {
    return $scope.currentShowsPage;
  };

  $scope.updatePaginatedShows = function() {
    if ($scope.shows && $scope.shows.length) {
      var start = ($scope.currentShowsPage - 1) * parseInt(document.getElementById('shows-size').value);
      var end = start + parseInt(document.getElementById('shows-size').value);
      var filter = document.getElementById('show-types').value;
      if (filter === 'all') {
        $scope.paginatedShows = $scope.shows.slice(start, end);
      } else {
        $scope.paginatedShows = $scope.shows.filter(function (show) {
          if (filter === 'shows') {
            return !show.isReel;
          } else {
            return show.isReel;
          }
        }).slice(start, end);
      }
    }
  };

  $scope.previousShowsPage = function() {
    if ($scope.currentShowsPage > 1) {
      $scope.currentShowsPage--;
      $scope.updatePaginatedShows();
    }
  };

  $scope.nextShowsPage = function() {
    if ($scope.currentShowsPage < $scope.totalShows()) {
      $scope.currentShowsPage++;
      $scope.updatePaginatedShows();
    }
  };

  $scope.$watch(function () {
    if (document.getElementById('shows-size')) {
      return parseInt(document.getElementById('shows-size').value);
    }
  }, function () {
    $scope.currentShowsPage = 1;
  });

  $scope.$watch('shows', function () {
    if ($scope.shows && $scope.shows.length) {
      $scope.updatePaginatedShows();
    }
  });

  $scope.$on('rewardAdded', loadUser);

  $scope.$on('rewardRemoved', loadUser);

  $scope.$on('awardAdded', loadUser);

  $scope.$on('closetUpdated', loadUser);

  $scope.copyUserCartAndWishlist = function () {
    dialogs({ok: 'YES', cancel: 'NO', hostname: 'Please, confirm'})
      .confirm('Are you sure you want to copy this cart? Your current cart will be deleted.', function (ok) {
        if (ok) {
          var cartItemsToCopy = _.cloneDeep($scope.user.cart.items);
          cartService.get(userService.getUser().id).then(function () {
            cartService.copyCartItems(cartItemsToCopy);
            $state.go('root.home');
          });
        }
      });
  };

  $scope.getUserClasses = function (user) {
    var classes = [];
    if (user.disabled) {
      classes.push('user-disabled');
    }
    if (user.invite) {
      classes.push('user-invite');
      if (!user.inviteRedeemedAt) {
        classes.push('user-invite-waiting');
      }
    }
    if (user.inviteRedeemedAt) {
      classes.push('user-invite-redeemed');
    }
    return classes.join(' ');
  };

  $scope.userExternalLink = function (site, user) {
    var mixPanelId = config.debug ? '587845' : '587847';

    var mixPanelSegmentation =
      'https://mixpanel.com/report/' +
      mixPanelId +
      '/segmentation/#action:segment,arb_event:\'Loaded%20a%20Page\',' +
      'bool_op:and,chart_analysis_type:linear,chart_type:pie,from_date:-29,' +
      'segfilter:!((dropdown_tab_index:1,filter:(operand:!(\'' +
      encodeURIComponent(user.email) +
      '),operator:%3D%3D),property:(name:\'$email\',no_second_icon:!t,source:user,type:string),' +
      'selected_property_type:string,type:string),';

    switch (site) {
      case 'mixpanel':
        return 'https://mixpanel.com/report/' + mixPanelId + '/explore/#user?distinct_id=' + user.id;

      case 'mixpanel:pages':
        return (
          mixPanelSegmentation +
          '(dropdown_tab_index:1,filter:(operand:admin.,operator:\'not%20in\'),property:(name:name,no_second_icon:!t,' +
          'source:properties,type:string),selected_property_type:string,type:string),(dropdown_tab_index:1,' +
          'filter:(operand:product.,operator:\'not%20in\'),property:(name:name,no_second_icon:!t,' +
          'source:properties,type:string),selected_property_type:string,type:string),(dropdown_tab_index:0,' +
          'property:(name:path,source:properties,type:string),selected_property_type:string,type:string)),' +
          'segment_type:string,to_date:0,type:general,unit:day'
        );

      case 'mixpanel:brands':
        return (
          mixPanelSegmentation +
          '(dropdown_tab_index:1,filter:(operand:\'\',operator:set),' +
          'property:(name:brand,no_second_icon:!t,source:properties,type:string),selected_property_type:string,' +
          'type:string),(dropdown_tab_index:0,property:(name:brand,source:properties,type:string),' +
          'selected_property_type:string,type:string)),segment_type:string,to_date:0,type:general,unit:day'
        );

      case 'mixpanel:categories':
        return (
          mixPanelSegmentation +
          '(dropdown_tab_index:1,filter:(operand:\'\',operator:set),' +
          'property:(name:category2,no_second_icon:!t,source:properties,type:string),selected_property_type:string,' +
          'type:string),(dropdown_tab_index:0,property:(name:category2,source:properties,type:string),' +
          'selected_property_type:string,type:string)),segment_type:string,to_date:0,type:general,unit:day'
        );

      case 'hotjar':
        if (!user.email) {
          return;
        }
        return (
          'https://insights.hotjar.com/sites/392335/playbacks?sort_by=-created&filters=%7B%22AND%22:%5B%7B%22' +
          'DAYS_AGO%22:%7B%22created%22:180%7D%7D%2C%7B%22EQUAL%22:%7B%22user_attributes.email.email%22:%22' +
          user.email.replace('@', '%40') +
          '%22%7D%7D%5D%7D'
        );
      case 'stripe':
        return 'https://dashboard.stripe.com/' + (config.debug ? 'test/' : '') + 'customers/' + user.stripeId;
    }
  };

  $scope.toggleProducts = function (order) {
    order.showProducts = !order.showProducts;
  };

  $scope.toggleShowProducts = function (order) {
    order.showProductsShown = !order.showProductsShown;
  };

  $scope.orderPackagesInfo = function (order) {
    if (order.packagesInfo && order.packagesInfo.length > 0) {
      return _.uniq(
        order.packagesInfo.map(function (info) {
          return info.status;
        })
      );
    }
    return 'not shipped yet';
  };

  $scope.cartTotalCount = function (cart) {
    if (!cart || !cart.items || !cart.items.length) {
      return 0;
    }
    var total = 0;
    cart.items.forEach(function (item) {
      total += item.quantity || 1;
    });
    return total;
  };

  $scope.openCart = function (cart) {
    cart.items.forEach(function (item) {
      productService.getByStyleId(item.styleId).then(function (product) {
        var params = {
          brand: product.brandId || product.brand.id,
          productName: (_.find(product.styles, {id: item.styleId}) || {}).slug,
          id: item.styleId,
        };
        if (item.variantKey) {
          for (var key in item.variantKey) {
            params[key] = item.variantKey[key];
          }
        }
        var url = $state.href('root.product.variant', params);
        window.open(url, '_blank');
      });
    });
  };

  $scope.notesBlur = function () {
    var oldNotes = $scope.user.notes || '';
    var newNotes = $scope.notes || '';
    if ($scope.user && oldNotes !== newNotes) {
      $scope.user.notes = $scope.notes;
      $scope.savingNotes = true;
      userService.admin.setNotes($scope.user.id, newNotes).then(function () {
        $scope.savingNotes = false;
      });
    }
  };

  $scope.notesChange = _.debounce(function () {
    $scope.notesBlur();
  }, 2000);
}

module.exports = AdminUserController;

//private functions

function formatAddress(address) {
  return _.compact([address.address1, address.address2, ',', address.city, address.state, address.zip])
    .join(' ')
    .replace(' ,', ',');
}

var currentYear = new Date().getFullYear() + '';

function isFromCurrentYear(obj) {
  return (obj.createdAt + '').split('-')[0] === currentYear;
}
