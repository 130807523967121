var httpStatus = require('http-status');

// @ngInject
function ErrorPageController($scope, $state, $stateParams, config, metadataService, userService) {

  $scope.setNoRobots();
  var status = $scope.status = parseInt($stateParams.status, 10) || 500;
  var categories = $stateParams.categories || [];
  var oosStyleId = $stateParams['oos-style-id'];
  var comingFrom = $stateParams['coming-from'];
  var statusText = httpStatus[status];
  $scope.title = status + ': ' + statusText;
  $scope.pageName = (document.location && document.location.pathname || '/new-page').slice(1);

  $scope.getCssClasses = function() {
    var classes = ['error-' + status];
    classes.push(Math.floor(status / 100) + 'xx');
    return classes.join(' ');
  };

  $scope.is403 = function() {
    return parseInt($stateParams.status, 10) === 403;
  };
  if (!Array.isArray(categories)) {
    if (categories) {
      categories = [categories];
    } else {
      categories = [];
    }
  }
  var route = categories.length ? ('root.shop.category' + categories.length) : 'root.shop.just-in';
  var details = '';
  switch (status) {
    case 403:
      if ($stateParams['forbidden-country']) {
        $state.go(route, {
          category1: categories[0] || 'whats-new',
          category2: categories[1] || undefined,
          category3: categories[2] || null,
          page: null,
          sale: null,
          sort: null,
          sizes: null,
          occasion: null,
          'forbidden-country': true
        });
        break;
      }
      details = '';
      metadataService.setTitle('Unauthorized');
      metadataService.setStatus(403);
      $scope.title = '';
      $scope.showSignInLink = true;
      $scope.showSplashPageLink = config.privateAccess;
      break;
    case 404:
      $state.go(route, {
        category1: categories[0] || 'whats-new',
        category2: categories[1] || undefined,
        category3: categories[2] || null,
        page: null,
        sale: null,
        sort: null,
        sizes: null,
        occasion: null,
        error404: true,
        'coming-from': comingFrom,
        'oos-style-id': oosStyleId
      });
      break;
    default:
      metadataService.setTitle($scope.title);
      metadataService.setStatus($scope.status);
  }
  $scope.details = details;

  if (status === 404) {
    var user = userService.getUser();
    if (user && user.roles && user.roles.indexOf('admin') >= 0) {
      $scope.canCreateThisPage = true;
    }
  }

  var loginModalShown = false;
  if (status === 403 && !$stateParams['forbidden-country']) {
    $scope.$watch('modal', function() {
      setTimeout(function() {
        if (!loginModalShown) {
          $scope.modal.show('login', { trigger: 'error-page' });
          loginModalShown = true;
        }
      }, 50);
    });
  }
}

module.exports = ErrorPageController;
