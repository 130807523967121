var _ = require('lodash-core');

// @ngInject
function MetadataService($location, config, productService, staticAssetService, $rootScope) {

  this.defaults = {
    title: 'The Mile - Scroll down fashion\'s most luxurious mile',
    description: 'The Mile is the first fashion destination that allows you to shop your favorite designers\' ' +
      'full collections, updated daily, in one place.',
    image: staticAssetService.url('/img/logo.png'),
    descriptionCTA: 'Scroll down your favorite shopping street.'
  };
  this.headTag = document.getElementsByTagName('head')[0];
  this.trackedTags = {};
  this.staticMetadata = window.orchardMileContent.metadata;

  $rootScope.$on('$locationChangeSuccess', function() {
    setTimeout(function() {
      this.titleBlocked = false;
      this.descriptionBlocked = false;
      if (this.staticMetadata && this.staticMetadata[window.location.pathname]) {
        this.currentMetadata = this.staticMetadata[window.location.pathname];
        if (this.currentMetadata.title) {
          this.setTitle(this.currentMetadata.title);
          this.titleBlocked = true;
        }
        if (this.currentMetadata.description) {
          this.setDescription(this.currentMetadata.description);
          this.descriptionBlocked = true;
        }
      }
    }.bind(this), 0);
  }.bind(this));


  this.prepareTitle = function(title) {
    if (title.length > 70) {
      title = title.substr(0, 67);
      title = title.substr(0, title.lastIndexOf(' ')).concat('...');
    }
    return title;
  };

  this.setTitle = function(title) {
    if (this.titleBlocked) {
      return;
    }
    this.title = this.prepareTitle(title);
    document.title = this.title;
    return this.title;
  };

  this.getTitle = function() {
    return this.title || this.defaults.title;
  };

  this.setRobots = function(content) {
    $('meta[name=robots]').remove();
    $('head').append('<meta name="robots" content="' + content + '">');
  };

  this.getRobots = function() {
    return $('meta[name=robots]').attr('content');
  };

  this.setStatus = function(status) {
    this.setMeta('prerender-status-code', status);
  };

  this.setRedirect = function(url, status) {
    this.setMeta('prerender-status-code', status);
    this.setMeta('prerender-header', url ? 'Location: ' + url : undefined);
  };

  this.prepareDescription = function(description) {
    if (description.length > 160) {
      description = description.substr(0, 100);
      return description.substr(0, description.lastIndexOf(' ')).concat('... - ', this.defaults.descriptionCTA);
    } else if ((description + ' - ' + this.defaults.descriptionCTA).length <= 160) {
      return description.concat(' - ', this.defaults.descriptionCTA);
    } else {
      return description;
    }
  };

  this.setDescription = function(description) {
    if (this.descriptionBlocked) {
      return;
    }
    description = this.prepareDescription(this.cleanHtml(description));
    this.setMeta('description', description);
    this.setMetaProperty('og:description', description);
    this.setMeta('twitter:description', description);
  };

  this.setPage = function(metadata) {

    this.setBasicMetadata();
    var title = this.cleanHtml(metadata.title);
    this.setTitle(title);
    this.setMeta('twitter:title', this.getTitle());
    this.setMeta('og:title', this.getTitle());

    this.setDescription(metadata.description || this.defaults.description);

    var keywords = metadata.keywords || this.defaults.keywords;
    if (keywords) {
      this.setMeta('keywords', typeof keywords === 'string' ? keywords : keywords.join(', '));
    }

    if (metadata.shareImage) {
      var imageUrl = staticAssetService.url(metadata.shareImage);

      this.setMetaProperty('og:image', imageUrl);
      this.setMeta('twitter:image', imageUrl);
      this.setMeta('twitter:card', 'summary_large_image');
    } else {
      this.setMeta('twitter:card', 'summary');
      this.setMeta('twitter:image', this.defaults.image);
    }
  };

  this.setProduct = function(product, style, variant) {

    this.setBasicMetadata();
    this.setMeta('twitter:card', 'product');
    this.setMetaProperty('og:type', 'product');

    var productName = this.cleanHtml(style.name || product.name);
    var brandName = product.brand.name;
    this.setTitle(productName + ' by ' + brandName + ' at THE MILE');

    this.setMeta('title', this.prepareTitle(productName + ' by ' + brandName));
    this.setMetaProperty('og:title', this.prepareTitle(productName + ' by ' + brandName));
    this.setMeta('twitter:title', this.prepareTitle(productName + ' by ' + brandName));

    var productVariants = _.flatten(_.map(product.styles, 'variants'));
    var productColors = _.uniq(_.compact(_.map(productVariants, function(each) {
      return each && each.sanitizedKey && each.sanitizedKey.color;
    })));
    var productDescription = 'Purchase ' + productName + ' featured by ' + brandName;
    var productDescriptionSuffix = '. Buy now, enjoy now, pay later, with our partners, Affirm, Afterpay, Klarna, ' +
      'including free shipping on all products.';

    if (!productColors || !productColors.length) {
      productDescription += productDescriptionSuffix;
    } else {
      while ((productDescription + ' in ' + productColors.join(', ')).length > 160 && productColors.length) {
        productColors.pop();
      }

      if (productColors && productColors.length) {
        var lastColor = null;

        if (productColors.length > 1) {
          lastColor = productColors.pop();
        }
        productDescription += ' in ' + productColors.join(', ');

        if (lastColor) {
          productDescription += ' or ' + lastColor;
        }
      }

      if ((productDescription + productDescriptionSuffix).length <= 160) {
        productDescription += productDescriptionSuffix;
      } else {
        productDescription += '.';
      }
    }

    this.setDescription(productDescription);

    if (style.images && style.images[0] && style.images[0].path) {
      var imageUrl = productService.getImageUrl(style.images[0].path, {width: 600});
      this.setMetaProperty('og:image', imageUrl);
      this.setMeta('og:vertical_image', imageUrl);
      this.setMeta('twitter:image', imageUrl);
    } else {
      this.setMetaProperty('og:image', this.defaults.image);
      this.setMeta('twitter:image', this.defaults.image);
    }

    this.setMeta('og:brand', brandName);
    this.setMeta('twitter:creator', brandName);

    this.setMeta('product:gender', 'female');
    this.setMeta('product:geographic_availability', 'all');

    if (style.keys && style.keys.color) {
      this.setMeta('product:color', style.keys.color);
      this.setMeta('twitter:label1', 'Color');
      this.setMeta('twitter:data1', style.keys.color);
    }

    if (product.brand && product.brand.visible && product.visible) {
      if (variant && variant.stock !== 0) {
        this.setMeta('og:availability', 'instock');
      } else if (!variant && _.find(style.variants, function(v) {
        return v && v.stock !== 0;
      })) {
        this.setMeta('og:availability', 'instock');
      } else {
        this.setMeta('og:availability', 'out of stock');
      }
    } else {
      this.setMeta('og:availability', 'discontinued');
    }

    if (!variant) {
      variant = style.variants && style.variants[0];
    }

    if (variant && variant.price) {
      var price = variant.price / 100;
      this.setMeta('product:price:currency', 'USD');
      this.setMeta('product:price:amount', price);
      this.setMeta('twitter:label2', 'Price');
      this.setMeta('twitter:data2', '$' + price);

      if (variant.listPrice !== variant.price) {
        this.setMeta('og:price:standard_amount', variant.listPrice / 100);
      }
    }
  };

  this.setArticle = function(metadata) {

    this.setBasicMetadata();
    this.setMetaProperty('og:type', 'article');
    this.setMeta('twitter:card', 'summary_large_image');

    var title = this.cleanHtml(metadata.title);
    this.setTitle(title);
    this.setMeta('twitter:title', this.getTitle());
    this.setMetaProperty('og:title', this.getTitle());

    this.setDescription(metadata.description || this.defaults.description);
    var imageUrl = this.defaults.image;
    if (metadata.shareImage) {
      imageUrl = staticAssetService.url(metadata.shareImage);
    }

    this.setMetaProperty('og:image', imageUrl);
    this.setMeta('twitter:image', imageUrl);
  };

  this.clear = function() {
    var self = this;
    this.title = undefined;
    this.robots = undefined;
    Object.keys(self.trackedTags).forEach(function(trackedName) {
      var tag = self.trackedTags[trackedName];
      if (tag && tag.parentNode) {
        tag.parentNode.removeChild(tag);
      }
    });
    self.trackedTags = {};
  };

  this.setBasicMetadata = function() {
    this.setMetaProperty('og:site_name', 'The Mile');
    this.setMetaProperty('og:url', $location.absUrl());
  };

  this.setMetaProperty = function(name, content) {
    if (this.trackedTags[name]) {
      this.trackedTags[name].content = content;
      return;
    }
    var metaTag = $('<meta>', {
      'property': name,
      'content': content
    })[0];
    this.headTag.appendChild(metaTag);
    this.trackedTags[name] = metaTag;
  };

  this.setMeta = function(name, content) {
    if (this.trackedTags[name]) {
      this.trackedTags[name].content = content;
      return;
    }
    var metaTag = $('<meta>', {
      'name': name,
      'content': content
    })[0];
    this.headTag.appendChild(metaTag);
    this.trackedTags[name] = metaTag;
  };

  this.setLink = function(rel, href) {
    if (this.trackedTags[rel]) {
      this.trackedTags[rel].href = href;
      return;
    }

    var linkTag = $('<link>', {
      'rel': rel,
      'href': href
    })[0];
    this.headTag.appendChild(linkTag);
    this.trackedTags[rel] = linkTag;
  };

  this.cleanHtml = function(content) {
    if (!content || content === '') {
      return '';
    }
    var text = content.replace(/<[^>]*>/ig, '');
    var dummyDiv = document.createElement('div');
    dummyDiv.innerHTML = text;
    return dummyDiv.textContent || dummyDiv.innerText;
  };

}

module.exports = MetadataService;
