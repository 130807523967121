var _ = require('lodash-core');

/*jshint camelcase: false */
/* jshint maxparams: 10 */
/* globals $ */

// @ngInject
function CheckoutBaseReviewController($rootScope, $scope, userService, pendingOrderService, shoppingContextService,
                                      config, paymentService, globalizationService, algoliaInsightsService, $http) {
  if (!$rootScope.currency) {
    globalizationService.setCurrency();
  }

  $scope.placeOrder = function () {
    $rootScope.placingOrder = true;
    // track event on hotjar
    if (window.hj) {
      window.hj('event', 'place-order-click');
    }
    algoliaInsightsService.sendEvent('click-purchase');
    if ([-1, -2, -3, -4, -5, -6].indexOf($scope.billingOptions.selected) === -1) {
      window.onbeforeunload = function () {
        return 'We\' processing your payment, please do not close your tab yet';
      };
    }
    setTimeout(function () {
      if ($rootScope.calculatingTotals) {
        handleErrorPosPlaceOrder(new Error('Please wait, we\'re calculating the taxes'));
        return;
      }

      ensureBilling();
      var orderData = {
        shipping: $scope.shipping,
        billing: $scope.billing,
        promoCode: $scope.cartData.promoCode,
        fulfillers: $scope.fulfillers,
        totals: $scope.cartTotals,
        items: shoppingContextService.getCartPurchasableItems()
      };

      userService.identifyOnHotjar(
        orderData.shipping.contact.email,
        {'total_spend': $scope.cartTotals.totalPrice / 100}
      );
      var counterTryToGetCartProducts = 0;
      var findInvalidItem = function () {
        return _.find(orderData.items, function (item) {
          return !item.styleId;
        });
      };
      while (counterTryToGetCartProducts++ < 6 && findInvalidItem()) {
        orderData.items = shoppingContextService.getCartPurchasableItems();
      }
      if ($scope.giftCard && $scope.cartTotals.giftCardDebit) {
        orderData.giftCard = $scope.giftCard.code;
        orderData.giftCardBalance = $scope.giftCard.balance;
        if ($scope.cartTotals.totalPrice === 0) {
          orderData.billing.paymentMethod.paymentCode = $scope.giftCard.code;
          orderData.billing.paymentMethod.paymentProvider = 'giftCard';
        }
      }

      if ($rootScope.country && $rootScope.country.currency !== 'USD') {
        orderData.totals.international = {
          totalPrice: convertPrice(orderData.totals.totalPrice, $rootScope.currency),
          shipping: convertPrice(orderData.totals.shipping, $rootScope.currency),
          internationalShipping: convertPrice(orderData.totals.internationalShipping,
            $rootScope.currency),
          taxes: convertPrice(orderData.totals.taxes, $rootScope.currency),
          cost: convertPrice(orderData.totals.cost, $rootScope.currency),
          giftCardDebit: convertPrice(orderData.totals.giftCardDebit, $rootScope.currency),
          quantity: orderData.totals.quantity,
          discount: convertPrice($scope.cartTotals.discount, $rootScope.currency),
          currency: $rootScope.country.currency
        };
      }

      if (orderData.totals.totalPrice === 0) {
        orderData.billing.paymentMethod = {
          paymentProvider: 'giftCard',
          paymentProviderData: 'giftCard',
          nameOnCard: orderData.shipping.contact.firstName + orderData.shipping.contact.lastName,
          cardNumber: '4111111111111111',
          cvv: '000',
          expirationMonth: '12',
          expirationYear: (new Date()).getFullYear() + 1
        };
        shoppingContextService.validateOrder(orderData).then(function (valid) {
          if (valid) {
            shoppingContextService.placeOrder(orderData);
          }
        });
      } else if (orderData.billing.paymentMethod && orderData.billing.paymentMethod.paymentProviderId &&
        $scope.payWithStripe) {
        shoppingContextService.validateOrder(orderData).then(function (valid) {
          if (valid) {
            window.stripeElements.confirmCardPayment($rootScope.paymentIntent.client_secret, {
              payment_method: orderData.billing.paymentMethod.paymentProviderId
            }).then(function (result) {
              if (result.error) {
                handleErrorPosPlaceOrder(result.error);
                throw new Error(result.error);
              }
              shoppingContextService.getPaymentMethod(result.paymentIntent.payment_method)
                .then(function (paymentMethod) {
                  orderData.billing.paymentMethod = {
                    paymentProvider: 'stripe',
                    paymentProviderData: result.paymentIntent,
                    nameOnCard: paymentMethod.nameOnCard,
                    cardNumber: 'xxxxxxxxxxxx' + paymentMethod.card.last4,
                    /*jshint camelcase: false */
                    expirationMonth: paymentMethod.card.exp_month,
                    expirationYear: paymentMethod.card.exp_year,
                    cvv: 'xxx'
                  };
                  shoppingContextService.placeOrder(orderData);
                });
            });
          }
        });
      } else {
        var redirectUrl = '';
        switch (config.environment) {
          case 'local':
            redirectUrl = 'https://localhost:3000/checkout/pre-confirmation';
            break;
          case 'staging':
            redirectUrl = 'https://staging.orchardmile.com/checkout/pre-confirmation';
            break;
          default:
            redirectUrl = 'https://orchardmile.com/checkout/pre-confirmation';
        }
        if ($scope.payWithPayPal) {
          shoppingContextService.validateOrder(orderData).then(function (valid) {
            if (valid) {
              var paymentReady = false;
              try {
                initializePaymentPaypal(redirectUrl, orderData);
                paymentReady = true;
              } catch (e) {
                handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
              }
              if (paymentReady) {
                var paypal = paymentService.getProviderByName('paypal');
                paypal.createPayment($rootScope.paymentPaypal).then(function (response) {
                  $rootScope.paypalOrder = response.result;
                  var url = new URL(response.result.links[1].href);
                  var id = url.searchParams.get('token');
                  orderData.paymentIntentId = response.result.id;
                  orderData.billing.paymentMethod = {
                    paymentProvider: 'paypal',
                    paymentProviderId: id,
                    paymentProviderData: response.result
                  };
                  if (id && id === orderData.paymentIntentId) {
                    pendingOrderService.create(orderData, true).then(function () {
                      window.location.href = response.result.links[1].href;
                    }).catch(function () {
                      handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
                    });
                  } else {
                    handleErrorPosPlaceOrder(new Error('We had an issue processing your payment'));
                  }
                }).catch(function () {
                  handleErrorPosPlaceOrder(new Error('We had an issue processing your payment'));
                });
              }
            }
          });
        } else if ($scope.payWithCitcon) {
          shoppingContextService.validateOrder(orderData).then(function (valid) {
            if (valid) {
              var paymentReady = false;
              pendingOrderService.create(orderData, true).then(function (pendingOrder) {
                var referenceId = pendingOrder.id;
                try {
                  redirectUrl = redirectUrl + '?token=' + pendingOrder.id;
                  initializeCitconPayment(pendingOrder, redirectUrl);
                  paymentReady = true;
                } catch (e) {
                  handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
                }
                if (paymentReady) {
                  var citcon = paymentService.getProviderByName('citcon');
                  citcon.createPayment($rootScope.citconPayment).then(function (response) {
                    $rootScope.citconOrder = response.data;
                    var uri = _.find(response.data.payment.client, function (url) {
                      return url.format === 'redirect';
                    });
                    if (!uri) {
                      handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
                    }
                    var id = uri.content.split('/').pop();
                    pendingOrder.paymentIntentId = pendingOrder.id;
                    pendingOrder.billing.paymentMethod = {
                      paymentProvider: 'citcon',
                      paymentProviderData: {
                        authKey: $rootScope.citconPayment.authKey,
                        autoCapture: $rootScope.citconPayment.transaction.auto_capture,
                        referenceId: referenceId,
                        paymentTokenId: id,
                        paymentId: response.data.id
                      }
                    };
                    if (id) {
                      pendingOrderService.create(pendingOrder, true).then(function () {
                        window.onbeforeunload = undefined;
                        window.location.href = uri.content;
                      }).catch(function () {
                        handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
                      });
                    } else {
                      handleErrorPosPlaceOrder(new Error('We had an issue processing your payment'));
                    }
                  }).catch(function () {
                    handleErrorPosPlaceOrder(new Error('We had an issue processing your payment'));
                  });
                }
              });
            }
          });
        } else {
          orderData.paymentIntentId = $rootScope.paymentIntent.id;
          shoppingContextService.validateOrder(orderData).then(function (valid) {
            if (valid) {
              pendingOrderService.create(orderData, true).then(function (pendingOrder) {
                if (pendingOrder && pendingOrder.paymentIntentId === orderData.paymentIntentId) {
                  window.stripeElements.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    elements: window.stripePaymentElement,
                    redirect: 'always',
                    confirmParams: {
                      payment_method_data: {
                        /*jshint camelcase: false */
                        billing_details: {
                          name: $scope.billing.paymentMethod.nameOnCard,
                          email: orderData.shipping.contact.email ?
                            orderData.shipping.contact.email :
                            userService.getUser().email,
                          phone: orderData.shipping.contact.phoneNumber,
                          address: {
                            country: orderData.billing.address.country ?
                              orderData.billing.address.country.toUpperCase() : 'US',
                            state: orderData.billing.address.state,
                            city: orderData.billing.address.city,
                            line1: orderData.billing.address.address1,
                            line2: orderData.billing.address.address2 || 'undefined',
                            postal_code: orderData.billing.address.zip
                          }
                        }
                      },
                      return_url: redirectUrl
                    }
                  }).then(function (result) {
                    if (result.error) {
                      $scope.initializePaymentIntents().then(function () {
                        if ($scope.billingOptions.selected === -1) {
                          $scope.mountPaymentElement($rootScope.paymentIntent);
                        }
                      });
                      handleErrorPosPlaceOrder(result.error);
                    }
                  });
                }
              }).catch(function () {
                handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
              });
            } else {
              handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
            }
          }).catch(function() {
            handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
          });
        }
      }
    }, 1500);
  };

  $scope.getPlacingOrderErrorMessage = function () {
    window.onbeforeunload = null;
    var err = $rootScope.placingOrderError;
    if (!err) {
      return;
    }
    if (['amount_too_large', 'amount_too_small'].indexOf(err.code) !== -1) {
      return 'You cannot pay this amount by using this payment method, please try another';
    }
    return err.message;
  };

  $scope.getPlacingOrderErrorIncident = function () {
    var err = $rootScope.placingOrderError;
    if (!err) {
      return;
    }
    if (err.data && err.data.error && err.data.error.incident) {
      return err.data.error.incident;
    }
  };

  function handleErrorPosPlaceOrder(err) {
    $rootScope.placingOrder = false;
    $rootScope.placingOrderError = err;
    window.onbeforeunload = null;
    // scroll to #place-order-error
    setTimeout(function () {
      var errorMessageOffset = $('#place-order-error').offset();
      if (errorMessageOffset) {
        $('body').animate({scrollTop: errorMessageOffset.top - 200}, 'slow');
      }
    }, 100);
  }

  function ensureBilling() {
    $scope.billing.paymentMethod.nameOnCard = $scope.billing.paymentMethod.nameOnCard ||
      ($scope.shipping.contact.firstName + ' ' + $scope.shipping.contact.lastName);
    if (!$scope.billing.address) {
      $scope.billing.address = _.clone($scope.shipping.address);
    }
  }

  function buildProductDetails(orderData, totalPrice, taxesTotal) {
    var taxesByUnitPercent = 0;
    if (taxesTotal && taxesTotal > 0) {
      taxesByUnitPercent = ((taxesTotal * 100) / totalPrice) / 100;
    }

    var items = [];

    orderData.items.forEach(function (item) {
      var itemPrice = (item.price / 100).toFixed(2);
      var variant = '';
      if (item.variantKey) {
        if (item.variantKey.color) {
          variant = variant.concat(item.variantKey.color);
        }
        if (item.variantKey.size) {
          if (variant !== '') {
            variant = variant.concat(' ');
          }
          variant = variant.concat(item.variantKey.size);
        }
      }
      items.push({
        'name': item.variant().name || item.style().name || item.product().name,
        'description': variant,
        'sku': item.variant().sku || item.style().sku,
        'unit_amount': {
          'currency_code': 'USD',
          'value': itemPrice.toString()
        },
        'tax': {
          'currency_code': 'USD',
          'value': (itemPrice * taxesByUnitPercent).toFixed(2).toString()
        },
        'quantity': item.quantity.toString(),
        'category': 'PHYSICAL_GOODS'
      });
    });

    return items;
  }

  function initializePaymentPaypal(redirectUrl, orderData) {
    var totalCost = ($scope.cartTotals.cost / 100).toFixed(2);
    var taxesTotal = 0;
    if ($scope.cartTotals && $scope.cartTotals.taxes) {
      taxesTotal = ($scope.cartTotals.taxes / 100).toFixed(2);
    }
    $rootScope.paymentPaypal = {
      'intent': 'AUTHORIZE',
      'application_context': {
        'return_url': redirectUrl,
        'cancel_url': redirectUrl,
        'shipping_preference': 'SET_PROVIDED_ADDRESS'
      },
      payment_source: {
        paypal: {
          'name': {
            'given_name': $scope.billing.paymentMethod.nameOnCard
          },
          address: {
            'address_line_1': orderData.billing.address.address1,
            'address_line_2': orderData.billing.address.address2,
            'admin_area_2': orderData.billing.address.city,
            'admin_area_1': orderData.billing.address.state,
            'postal_code': orderData.billing.address.zip,
            'country_code': orderData.billing.address.country ? orderData.billing.address.country.toUpperCase() : 'US'
          }
        }
      },
      'purchase_units': [
        {
          amount: {
            'currency_code': 'USD',
            'value': ($scope.cartTotals.totalPrice / 100).toFixed(2).toString(),
            'breakdown': {
              'item_total': {
                'currency_code': 'USD',
                'value': totalCost.toString()
              },
              'shipping': {
                'currency_code': 'USD',
                'value': (($scope.cartTotals.shipping + $scope.cartTotals.internationalShipping) / 100)
                  .toFixed(2).toString()
              },
              'tax_total': {
                'currency_code': 'USD',
                'value': taxesTotal.toString()
              },
              'discount': {
                'currency_code': 'USD',
                'value': ($scope.cartTotals.discount / 100).toFixed(2).toString()
              }
            }
          },
          'items': buildProductDetails(orderData, totalCost, taxesTotal),
          'shipping': {
            'name': {
              'full_name': $scope.shipping.contact.firstName + $scope.shipping.contact.lastName
            },
            'address': {
              'address_line_1': orderData.shipping.address.address1,
              'address_line_2': orderData.shipping.address.address2,
              'admin_area_2': orderData.shipping.address.city,
              'admin_area_1': orderData.shipping.address.state,
              'postal_code': orderData.shipping.address.zip,
              'country_code': orderData.shipping.address.country ?
                orderData.shipping.address.country.toUpperCase() : 'US'
            }
          }
        }
      ]
    };
  }

  function initializeCitconPayment(order, redirectUrl) {
    $rootScope.citconPayment = {};

    var paymentMethod = _.find($scope.internationalPaymentOptions, function (pm) {
      return pm.id === $scope.billingOptions.selected;
    });

    if (!paymentMethod || !$rootScope.country) {
      handleErrorPosPlaceOrder(new Error('We had a problem placing your order'));
    }

    var totalPrice = order.totals.totalPrice;
    var taxesTotal = order.totals.taxes;

    if (order.totals.international) {
      totalPrice = order.totals.international.totalPrice;
      taxesTotal = order.totals.international.taxes;
    }

    if ($rootScope.currency && !order.totals.international) {
      totalPrice = Math.round(totalPrice * $rootScope.currency.conversionRate);
    }

    $rootScope.citconPayment.authKey = paymentMethod.authKey || 1;

    $rootScope.citconPayment.transaction = {
      'reference': order.id,
      'amount': totalPrice,
      'currency': $rootScope.country.currency.toUpperCase(),
      'country': $rootScope.country.code.toUpperCase()
    };

    if (paymentMethod.data.includes('auto_capture')) {
      $rootScope.citconPayment.transaction.auto_capture = true;
    }

    if (paymentMethod.data.includes('auto_capture_false')) {
      $rootScope.citconPayment.transaction.auto_capture = false;
    }

    if (paymentMethod.data.includes('goods')) {
      $rootScope.citconPayment.goods = {
        data: [
          {
            'total_tax_amount': taxesTotal,
            'total_discount_code': ''
          }
        ]
      };
    }

    if (paymentMethod.data.includes('consumer')) {
      $rootScope.citconPayment.consumer = {
        'reference': userService.getUser() ? userService.getUser().id : 'guest',
        'first_name': $scope.shipping.contact.firstName,
        'last_name': $scope.shipping.contact.lastName,
        'phone': $scope.shipping.contact.phone,
        'email': $scope.shipping.contact.email,
        'ext_data': {
          'document_id': ''
        }
      };
    }

    if (paymentMethod.data.includes('payment_extra')) {
      $rootScope.citconPayment.payment = {
        'method': $scope.billingOptions.selected,
        'indicator': 'non-authenticated',
        'request_token': false,
        'client': [
          'desktop'
        ]
      };
    } else {
      $rootScope.citconPayment.payment = {
        'method': $scope.billingOptions.selected
      };
    }

    var baseUrl;

    switch (config.environment) {
      case 'production':
        baseUrl = 'https://api.orchardmile.com';
        break;
      default:
        baseUrl = 'https://api-staging.orchardmile.com';
    }

    $rootScope.citconPayment.urls = {
      'ipn': baseUrl + '/orders/citcon-notify',
      'mobile': redirectUrl + '&provider=citcon',
      'success': redirectUrl + '&provider=citcon',
      'cancel': redirectUrl + '&provider=citcon',
      'fail': redirectUrl + '&provider=citcon'
    };
  }

  function convertPrice(price, currency) {
    var priceConverted = price * currency.conversionRate;
    if (currency.decimals === 2) {
      priceConverted = Math.round(priceConverted);
    } else if (currency.decimals < 2) {
      priceConverted = Math.round(priceConverted / Math.pow(10, (2 - currency.decimals)));
    } else if (currency.decimals > 2) {
      priceConverted = Math.round(priceConverted * Math.pow(10, (currency.decimals - 2)));
    }

    return priceConverted;
  }


  function constructor() {
    if ($scope.isPhone) {
      return;
    }

    if ($scope.promo.code !== $scope.cartData.promoCode && !$scope.getPromoReward()) {
      // user forgot to click Apply on billing step, let's do it now
      $scope.applyPromoCode();
    }
  }

  setTimeout(function () {
    // trying a fake request to consult ad
    $http({
      url: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
      method: 'HEAD',
      mode: 'no-cors'
    }).catch(function () {
      $scope.adBlockDetected = true;
    });
  }, 1000);

  constructor();
}

module.exports = CheckoutBaseReviewController;
