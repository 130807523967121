var _ = require('lodash-core');

// @ngInject
function SizeFilterDirective() {
  var linker = function($scope, attrs) {

    $scope.where = attrs.where;
    $scope.status = {};
    $scope.status.openSize = false;

    var filters = $scope.filters;
    var sources = $scope.sources;

    var clothingSources = [
      { name: 'XXS', selected: false, disabled: false },
      { name: 'XS', selected: false, disabled: false },
      { name: 'S', selected: false, disabled: false },
      { name: 'M', selected: false, disabled: false },
      { name: 'L', selected: false, disabled: false },
      { name: 'XL', selected: false, disabled: false },
      { name: 'XXL', selected: false, disabled: false },
      { name: '1X', selected: false, disabled: false },
      { name: '2X', selected: false, disabled: false },
      { name: '3X', selected: false, disabled: false }
    ];

    var shoesSources = _.flatten(_.map([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], function(val) {
      return [
        { name: val + ' US', selected: false, disabled: false, val: val },
        { name: val + '.5 US', selected: false, disabled: false, val: val }
      ];
    }));

    var normalizedSizes = _.flatten(_.map(shoesSources, function(source) {
      var val = source.val;
      var result = {};
      result[source.name] = [
        // US
        (val + 32) + '',
        (val + 32) + '.5',
        (val + 32) + ',5',
        (val + 32) + 'h',
        val + '.5',
        val + 'us',
        val + '.5us',
        val + '.0 US',
        val + 'h',
        val + '',

        // US Special
        val + '.0 - aa',
        val + '.5 - aa',
        val + '.0 - eee',
        val + '.5 - eee',
        val + '.0 - ee',
        val + '.5 - ee',
        val + '.0 - e',
        val + '.5 - e',
        val + '.0 - b',
        val + '.5 - b',
        val + '.0 - c',
        val + '.5 - c',
        val + '.0 - aa (standard)',
        val + '.5 - aa (standard)',
        val + '.0 - eee (standard)',
        val + '.5 - eee (standard)',
        val + '.0 - ee (standard)',
        val + '.5 - ee (standard)',
        val + '.0 - e (standard)',
        val + '.5 - e (standard)',
        val + '.0 - b (standard)',
        val + '.5 - b (standard)',
        val + '.0 - c (standard)',
        val + '.5 - c (standard)',

        val + '.0 - aa (narrow)',
        val + '.5 - aa (narrow)',
        val + '.0 - eee (narrow)',
        val + '.5 - eee (narrow)',
        val + '.0 - ee (narrow)',
        val + '.5 - ee (narrow)',
        val + '.0 - e (narrow)',
        val + '.5 - e (narrow)',
        val + '.0 - b (narrow)',
        val + '.5 - b (narrow)',
        val + '.0 - c (narrow)',
        val + '.5 - c (narrow)',

        val + '.0 - aa (wide)',
        val + '.5 - aa (wide)',
        val + '.0 - eee (wide)',
        val + '.5 - eee (wide)',
        val + '.0 - ee (wide)',
        val + '.5 - ee (wide)',
        val + '.0 - e (wide)',
        val + '.5 - e (wide)',
        val + '.0 - b (wide)',
        val + '.5 - b (wide)',
        val + '.0 - c (wide)',
        val + '.5 - c (wide)',

        val + ' us - wide',
        val + '.5 us - wide',
        val + ' us - narrow',
        val + '.5 us - narrow',
        val + ' us - standard',
        val + '.5 us - standard',
        val + ' us - none',
        val + '.5 us - none',

        val + ' - wide',
        val + '.5 - wide',
        val + ' - narrow',
        val + '.5 - narrow',
        val + ' - standard',
        val + '.5 - standard',
        val + ' - medium',
        val + '.5 - medium',
        val + ' - none',
        val + '.5 - none',

        // IT
        (val + 32) + ' IT',
        (val + 32) + '.5 IT',
        (val + 32) + ' it',
        (val + 32) + '.5 it',
        'it ' + (val + 32),
        'it ' + (val + 32) + '.5',
        'IT ' + (val + 32),
        'IT ' + (val + 32) + '.5',

        // IT With Conversion
        (val + 32) + ' it (' + val + ' us)',
        (val + 32) + '.5 it (' + val + '.5 us)',
        'it ' + (val + 32) + ' (' + val + ' us)',
        'it ' + (val + 32) + '.5 (' + val + '.5 us)',
        (val + 32) + '.5 it (' + val + ' us / ' + val + '.5 us)',

        // FR With Conversion
        'fr ' + (val + 30) + ' / us ' + val,
        'fr ' + (val + 29) + ' / us ' + val,

        // UK
        (val + 32) + ' (uk)',
        (val + 32) + 'h (uk)',
        (val + 32) + 'h (uk)',
        val + 'h (uk)',
        val + ' (uk)',
        val + ' UK',
        val + '.5 UK',

        // EU
        val + ' EU',
        val + '.5 EU'
      ];
      return result;
    }));

    //TODO
    var kidsSources = [
      { name: '0-3 MONTHS', selected: false, disabled: false },
      { name: '3-6 MONTHS', selected: false, disabled: false },
      { name: '6-12 MONTHS', selected: false, disabled: false },
      { name: '12-18 MONTHS', selected: false, disabled: false },
      { name: '18-24 MONTHS', selected: false, disabled: false },
      { name: '2 YEARS', selected: false, disabled: false },
      { name: '3 YEARS', selected: false, disabled: false },
      { name: '4 YEARS', selected: false, disabled: false },
      { name: '5 YEARS', selected: false, disabled: false },
      { name: '6 YEARS', selected: false, disabled: false },
      { name: '7 YEARS', selected: false, disabled: false },
      { name: '8 YEARS', selected: false, disabled: false },
      { name: '9 YEARS', selected: false, disabled: false },
      { name: '10 YEARS', selected: false, disabled: false },
      { name: '11 YEARS', selected: false, disabled: false },
      { name: '12 YEARS', selected: false, disabled: false },
      { name: '13 YEARS', selected: false, disabled: false },
      { name: '14 YEARS', selected: false, disabled: false },
      { name: '15 YEARS', selected: false, disabled: false },
      { name: '16 YEARS', selected: false, disabled: false }
    ];

    $scope.isVisible = isVisible;

    $scope.onContextReady(function() {

      loadSources();

      $scope.$watch('facets.sizes', function() {
        var facets = $scope.facets.sizes;
        _.forEach(sources.sizes, function(opt) {
          opt.disabled = !(matchSomeFaceSize(opt.name, facets.sizes));
          opt.selected = opt.disabled ? false : opt.selected;
        });
      }, true);

      // watch first category level change
      $scope.$watch('filters.category[0]', loadSources);

      // watch selection for changes
      $scope.$watch('sources.sizes', updateSizesFilter, true);
      $scope.$watch('filters.sizes', updateSizesSource, true);
    });

    // private

    function matchSomeFaceSize(opt, facets) {
      return facets && (!!facets[opt] || _.some(normalizedSizes, function (normalizedSize) {
        if (normalizedSize[opt]) {
          return _.some(Object.keys(facets), function (facet) {
            return normalizedSize[opt].includes(facet);
          });
        }
      }));
    }

    function getNormalizedMatches(opt) {
      var normalizedSizesSelected = [];
      _.forEach(normalizedSizes, function (normalizedSize) {
        if (normalizedSize[opt]) {
          _.forEach(Object.keys($scope.facets.sizes.sizes), function (facet) {
            if (normalizedSize[opt].includes(facet)) {
              normalizedSizesSelected.push(facet);
            }
          });
        }
      });
      return normalizedSizesSelected;
    }

    function isVisible() {
      if (filters.category[0] === 'clothing') {
        return true;
      }
      if (filters.category[0] === 'shoes') {
        return true;
      }
      if (filters.category[0] === 'kids') {
        return true;
      }

      return false;
    }

    function loadSources() {
      switch (filters.category[0]) {
        case 'shoes':
          sources.sizes = shoesSources;
          break;
        case 'kids':
          sources.sizes = kidsSources;
          break;
        default:
          sources.sizes = clothingSources;
      }
      updateSizesSource();
    }

    function updateSizesSource() {
      _.forEach(sources.sizes, function(opt) {
        opt.selected = filters.sizes.indexOf(opt.name) > -1;
      });

      if (_.filter(sources.sizes, { selected: true }).length) {
        $scope.status.openSize = true;
      }
    }

    function updateSizesFilter() {
      if (sources.sizes.length) {
        var selectedSizes = _.map(_.filter(sources.sizes, { selected: true }), 'name');
        _.forEach(selectedSizes, function (selectedSize) {
          selectedSizes = _.concat(selectedSizes, getNormalizedMatches(selectedSize));
        });
        selectedSizes = selectedSizes.join(',');
        if (filters.sizes.join(',') !== selectedSizes) {
          filters.sizes = _.compact(selectedSizes.split(','));
        }
      }
    }

  };
  return {
    link: linker,
    templateUrl: '/views/partials/size-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = SizeFilterDirective;
