/* jshint maxparams: 12 */
// @ngInject
function AuthenticationModalService($rootScope, $uibModal, $state, $stateParams, userService, platformService,
  experimentsService, sessionStorageService, analyticsService,
  localStorageService) {


  this.initialize = function() {
    setTimeout(function() {
      var experimentVariation = experimentsService.getVariation('authentication-modal');
      if (mustShowAuthenticationModal(experimentVariation)) {
        if (experimentVariation) {
          showModal(experimentVariation);
        } else {
          showModal(fromUrl());
        }
      }
    }, 10000);
  };
  function getSubModal() {
    var popupMatch = /[\?&#]v=([^\?&#]+)/i.exec(document.location.search);
    if (!popupMatch) {
      return;
    }

    return decodeURIComponent(popupMatch[1]);
  }
  function showModal(experimentVariation) {

    // track in analytics and parameter for segmentation
    analyticsService.track('Authentication Modal shown', {
      authenticationModal: experimentVariation
    });

    var $modalScope = $rootScope.$new(false);
    var version = 'login';
    if (experimentVariation === 'register') {
      version = 'register';
    }
    if (getSubModal()) {
      version = getSubModal();
    }

    $modalScope.modal = {
      name: version,
      instanceData: {
        options: {
          trigger: experimentVariation === 'with-no-close-button' ?
            'authentication-modal-no-close' : 'authentication-modal'
        }
      },
      show: function(name) {
        $modalScope.modal.name = name;
      },
      hide: function(closeButton) {
        if (closeButton) {
          analyticsService.track('Authentication Modal dismissed');
        }

        sessionStorageService.setItem('authentication-modal', 'dismissed');
        var dismissTimes = localStorageService.getItem('authentication-modal-dismiss') || 0;
        dismissTimes++;
        localStorageService.setItem('authentication-modal-dismiss', dismissTimes);
        analyticsService.track('Authentication Modal dismissed: ' + dismissTimes);
        $modalScope.modal.instance.dismiss();
      }
    };

    var additionalWindowClass = '';
    if (experimentVariation === 'with-no-close-button') {
      additionalWindowClass += ' no-close';

      // track in analytics and parameter for segmentation
      analyticsService.track('Authentication Modal shown without X', {
        authenticationModal: experimentVariation
      });
    } else {
      // track in analytics and parameter for segmentation
      analyticsService.track('Authentication Modal shown with X', {
        authenticationModal: experimentVariation
      });
    }

    $modalScope.modal.instance = $uibModal.open({
      templateUrl: '/views/partials/modals/authentication-modal.html',
      windowClass: 'authentication-modal' + additionalWindowClass,
      scope: $modalScope,
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    });

    $rootScope.addModalToStack();
    $modalScope.modal.instance.result.finally($rootScope.removeModalFromStack);
  }

  function fromUrl() {
    var popupMatch = /[\?&#]signup=([^\?&#]+)/i.exec(document.location.search);
    if (!popupMatch) {
      return;
    }

    return decodeURIComponent(popupMatch[1]);
  }

  function mustShowAuthenticationModal(experimentVariation) {
    if (platformService.isPrerender() || platformService.isBot() ||
      platformService.isAutomatedBrowser()) {
      return false;
    }
    if (!userService.getUser().anonymous) {
      return false;
    }
    if (['root.invite', 'root.inviteRedeem', 'root.passwordReset', 'root.error'].indexOf($state.current.name) > -1) {
      return false;
    }
    if ($state.current.name === 'root.pages' &&
      ['browser-not-supported', 'mobile-not-supported'].indexOf($stateParams.name) > -1) {
      return false;
    }
    if (sessionStorageService.getItem('authentication-modal') === 'dismissed') {
      return false;
    }
    if ($rootScope.modalStack >= 1) {
      return;
    }
    if (fromUrl()) {
      return true;
    }
    return experimentVariation;
  }
}

module.exports = AuthenticationModalService;
