var _ = require('lodash-core');

// @ngInject
function AdminHotnessDirective(curationService) {

  var linker = function($scope) {

    $scope.$watch('product', function() {
      if (!$scope.result && $scope.product && $scope.product.styles) {
        var styleIds = _.map($scope.product.styles, 'id');
        curationService.getStylesCurations(styleIds).then(function(result) {
          $scope.curations = result.items;
        });
      }
    });

    $scope.canToggleHot = function() {
      return $scope.curations;
    };

    $scope.getStyleCurations = function() {
      var styleId = $scope.style ? $scope.style.id : $scope.product.styleId;
      return _.find($scope.curations, { styleId: styleId });
    };

    $scope.getHotClass = function() {
      if ($scope.updating || !$scope.curations || $scope.curations.length === 0) {
        return 'curations-loading';
      }
      var styleCurations = $scope.getStyleCurations();
      if (!styleCurations || !styleCurations.curations || !styleCurations.curations.score ||
        styleCurations.curations.score === 0) {
        return 'curations-nothot';
      }
      return 'curations-hot';
    };

    $scope.toggleHot = function() {
      if ($scope.updating) {
        return;
      }
      $scope.updating = true;
      var styleCurations = $scope.getStyleCurations();

      // Higher score as possible make the api assign the maximum between current scores plus a certain amount
      var newScore = !styleCurations || !styleCurations.curations || styleCurations.curations.score === 0 ?
        Number.MAX_VALUE : 0;

      curationService.setStyleCurations($scope.style ? $scope.style.id : $scope.product.styleId, { score: newScore })
        .then(function() {
          if (!styleCurations) {
            $scope.curations.push({
              id: $scope.product.id,
              styleId: $scope.style ? $scope.style.id : $scope.product.styleId,
              curations: {
                score: newScore
              }
            });
          } else {
            styleCurations.curations = styleCurations.curations || {};
            styleCurations.curations.score = newScore;
          }
          $scope.updating = false;
        });
    };

  };

  return {
    link: linker,
    templateUrl: '/views/partials/admin-hotness.html',
    restrict: 'E',
    scope: {
      product: '=',
      style: '=',
      result: '='
    }
  };

}

module.exports = AdminHotnessDirective;
