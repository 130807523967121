// @ngInject
function CopyToClipboardDirective($timeout) {
  return {
    restrict: 'A',
    link: function(scope, elem, attrs) {
      elem.click(function() {
        if (attrs.copyToClipboard) {
          var $tempInput = $('<input>');
          $('body').append($tempInput);
          $('#' + attrs.data).fadeIn('slow');
          $tempInput.val(attrs.copyToClipboard).select();
          document.execCommand('copy');
          $tempInput.remove();
          $timeout(function() {
            $('#' + attrs.data).fadeOut('slow');
          }, 2000);
        }
      });
    }
  };
}

module.exports = CopyToClipboardDirective;
