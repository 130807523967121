
// @ngInject
function PageRedirectDirective($location, $window, platformService, metadataService) {

  var linker = function($scope, element, attrs) {
    var url = attrs && attrs.url;
    if (url) {
      if (platformService.isPrerender()) {
        if (url.slice(0, 1) === '/') {
          url = $window.location.origin + url;
        }
        metadataService.setRedirect(url, 301);
        return;
      }
      $location.url(url);
    }
  };

  return {
    link: linker,
    restrict: 'E'
  };

}

module.exports = PageRedirectDirective;
