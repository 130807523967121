// @ngInject
function BreadcrumbsDirective(shopModalService) {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/breadcrumbs.html',
    scope: {
      path: '&'
    },
    link: function($scope) {
      $scope.isOnWidget = function() {
        return shopModalService.isOnWidget();
      };
      if ($scope.isOnWidget) {
        $scope.closeShopModal = function() {
          shopModalService.closeShopModal();
        };
      }
      $scope.homeLink = window.location.protocol + '//' + window.location.host;
    }
  };
}

module.exports = BreadcrumbsDirective;
