// @ngInject
function ScrollableContentDirective(platformService, $window, $rootScope, scrollService) {

  var linker = function($scope, element) {
    var intervalRemove = null;
    var times = 700;
    var scrollTrackingStorage = {};
    try {
      scrollTrackingStorage = platformService.isSessionStorageAvailable() && window.sessionStorage ||
        window.localStorage;
    } catch (e) {
      scrollTrackingStorage = window.iframeStorage;
    }
    var debug = scrollTrackingStorage.getItem('OM_DEBUG') || false;
    $scope.scrollToTop = function(animate, ml) {
      return this.scrollTo(0, 0, animate, ml);
    };

    $scope.scrollTo = function(x, y, animate, ml) {
      ml = (ml) ? ml : 200;
      if (animate === false) {
        ml = 0;
      }
      var scrollable = element.is('body') ? angular.element('body,html') : element; // "body,html" for firefox
      if (!scrollService.isInfiniteScroll()) {
        scrollable.animate({ scrollLeft: x, scrollTop: y }, ml);
      } else {
        try {
          $([document.documentElement, document.body]).animate({
            scrollTop: $($('.product-list-item')[($rootScope.lastClickedProductIndex % 60) * 60]).offset().top
          }, 2000);
        } catch (e) { }

      }
      $('.scroll-expander-body').remove();
    };

    $scope.scrollIntoView = function(selector, offset) {
      var selectedOffset = element.find(selector).offset();
      if (selectedOffset && selectedOffset.top) {
        var elementOffset = element.offset();
        if (debug) {
          console.log('scroll into view');
        }
        $scope.scrollTo(0, selectedOffset.top - elementOffset.top + (offset || 0));
        $('.scroll-expander-body').remove();
      }
    };

    if (element.is('body')) {
      window.canTrackScroll = window.canTrackScroll || false;

      var setScroll = function(event) {
        event.stopPropagation();
        if (window.canTrackScroll) {
          if ($rootScope.hasPLP()) {
            scrollTrackingStorage.setItem(window.location.toString(), $window.pageYOffset);
            if (debug) {
              console.log('seteo height a: ' + window.location.toString() + ' ' + $window.pageYOffset);
            }
          }
        }
      };

      if (scrollTrackingStorage) {
        $(window).on('scroll resize', function(event) {
          setScroll(event);
        });
      }
      $rootScope.$on('readyToScroll', function() {
        if ($rootScope.isBackOrForward) {
          setTimeout(function() {
            window.canTrackScroll = true;
          }, 1000);
          removeExpander();
        }
      });

      $rootScope.$on('$locationChangeStart', function() {
        $rootScope.isBackOrForward = false;
        window.canTrackScroll = false;
        window.placeExpander();
        times = 700;
      });

      $(window).on('popstate', function() {
        $rootScope.isBackOrForward = true;
        if (scrollTrackingStorage) {
          if (window.location.toString().indexOf('#') !== -1) {
            $('.scroll-expander-body').remove();
          }
        }
      });

      $rootScope.$on('$locationChangeSuccess', function() {
        if (!$rootScope.isBackOrForward) {
          if (!scrollService.isInfiniteScroll()) {
            window.scrollTo(0, 0);
          } else {
            $('.scroll-expander-body').remove();
          }
        }
        intervalRemove = setInterval(function() {
          removeExpander();
        }, 100);
      });
    }

    window.placeExpander = function() {
      var placeholder = element.find('#scroll-expander-placeholder');
      placeholder = placeholder.length ? placeholder : element;
      if (!platformService.isAutomatedBrowser()) {
        var height = 100;
        if (platformService.isBot()) {
          height = 1;
        }
        placeholder.append('<div class="scroll-expander-body" style="position: fixed;top: 0; left: 0;' +
          'width: 100%; height: ' + height + '%; z-index: -9999;"></div>');
      }
    };

    var getParent = function(url) {
      var urlArray = url.split('/');
      urlArray.pop();
      return urlArray.join('/');
    };

    var getScrollValue = function() {
      var storageValue = scrollTrackingStorage.getItem(window.location.toString());
      if (!storageValue && $rootScope.hasPLP()) {
        storageValue = scrollTrackingStorage.getItem(getParent(window.location.toString()));
      }
      return storageValue || 0;
    };

    var scrollAndRemove = function(force) {
      if (!force) {
        var scrollY = getScrollValue();
        if (debug && scrollY !== 0) {
          console.log('scroll to: ' + scrollY + '  body: ' + $('body').height());
        }
        $scope.scrollTo(0, scrollY);
        setTimeout(
          function() {
            if ($window.pageYOffset !== scrollY) {
              if (debug) {
                console.log('double check of scroll I am now on ' + $window.pageYOffset +
                  ' I should be on ' + scrollY);
              }
              window.canTrackScroll = true;
              $scope.scrollTo(0, scrollY);
            }
          }, 200
        );
      }
      scrollTrackingStorage.removeItem(window.location.toString());
      $('.scroll-expander-body').remove();
      window.canTrackScroll = true;
      clearInterval(intervalRemove);
    };

    function removeExpander() {
      var expander = element.find('.scroll-expander-body');
      if (expander.length) {
        if ($('body').height() > getScrollValue()) {
          //there is content enough
          scrollAndRemove(false);
        } else {
          if (times > 0) {
            times--;
          } else {
            if (window.trackJs) {
              var error = {
                message: 'Scroll Problem',
                scrollHeight: getScrollValue()
              };
              window.trackJs.track(error);
            }
            scrollAndRemove(true);
          }
        }
      }
    }
  };

  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = ScrollableContentDirective;
