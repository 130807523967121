// @ngInject
function ContentPageListItemsDirective() {
  var linker = function() { };

  return {
    link: linker,
    templateUrl: '/views/partials/content-page-list-items.html',
    restrict: 'E',
    transclude: true
  };
}

module.exports = ContentPageListItemsDirective;
