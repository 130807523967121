// @ngInject
function CompiledHtmlDirective($compile) {
  return {
    template: '<div></div>',
    scope: {
      rawHtml: '=compiledHtml'
    },
    link: function(scope, elem) {
      scope.$watch('rawHtml', function(value) {
        if (!value) {
          return;
        }
        // we want to use the scope OUTSIDE of this directive
        // (which itself is an isolate scope).
        var newElem = $compile(value)(scope.$parent);
        elem.contents().remove();
        elem.append(newElem);
      });
    }
  };
}

module.exports = CompiledHtmlDirective;
