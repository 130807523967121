var _ = require('lodash-core');

// @ngInject
function AdminInfluencersController($scope, $state, $stateParams, userService,
  influencerService) {

  var usedParameters = {};

  $scope.parameters = {};

  $scope.$watch(function() {
    return $stateParams;
  }, function() {
    _.merge($scope.parameters, _.pick($stateParams, [
      'term'
    ]));
  });

  $scope.$watch('showInfluencerApproval', function () {
    if ($scope.showInfluencerApproval === true && !$stateParams.term) {
      loadUnapprovedInfluencers();
    }
  });

  function loadSocialMedia(influencer) {
    if (influencer.socialNetworks) {
      if (influencer.socialNetworks.instagram) {
        influencer.socialNetworks.instagram = 'https://www.instagram.com/' + influencer.socialNetworks.instagram;
      }

      if (influencer.socialNetworks.tiktok) {
        influencer.socialNetworks.tiktok =  'https://www.tiktok.com/@' + influencer.socialNetworks.tiktok;
      }

      if (influencer.socialNetworks.youtube) {
        influencer.socialNetworks.youtube =  'https://www.youtube.com/@' + influencer.socialNetworks.youtube;
      }

      if (influencer.socialNetworks.facebook) {
        influencer.socialNetworks.facebook = 'https://www.facebook.com/' + influencer.socialNetworks.facebook;
      }
    }
  }

  function loadUnapprovedInfluencers () {
    $scope.users = [];
    influencerService.getUnapprovedInfluencers().then(function(influencers) {
      $scope.loading = influencers.length;
      _.forEach(influencers, function (influencer) {
        loadSocialMedia(influencer);
        userService.getById(influencer.userId).then(function(user) {
          user.influencer = influencer;
          influencerService.getShowsById(influencer.id).then(function(shows){
            user.influencer.shows = shows;
          });
          $scope.users.push(user);
        }).finally(function () {
          $scope.loading--;
        });
      });
    });
  }

  $scope.updateInfluencerApproval = function (influencer) {
    influencerService.updateInfluencerApproval(influencer.id, influencer).then(function () {
      loadUnapprovedInfluencers();
    });
  };


  var search = _.debounce(function() {
    var parameters = $scope.parameters;
    if (_.isEqual(parameters, usedParameters)) {
      return;
    }
    usedParameters = _.cloneDeep(parameters);

    $state.go($state.current.name, parameters, { notify: false });

    if (!parameters.term) {
      delete $scope.users;
      return;
    }

    var searchParams = {};

    searchParams.term = parameters.term;
    $scope.users = [];

    if (parameters.term && /[1234567890abcdf]{24}/.test(parameters.term)) {
      $scope.loading = 2;
      userService.getById(parameters.term).then(function(user) {
        influencerService.getByUserId(user.id).then(function(influencer) {
          loadSocialMedia(influencer);
          user.influencer = influencer;
          influencerService.getShowsById(influencer.id).then(function(shows){
            user.influencer.shows = shows;
          });

          $scope.users.push(user);
          $scope.loading--;
        });
      });
      influencerService.getById(parameters.term).then(function(influencer) {
        loadSocialMedia(influencer);
        userService.getById(influencer.userId).then(function(user) {
          user.influencer = influencer;
          influencerService.getShowsById(influencer.id).then(function(shows){
            user.influencer.shows = shows;
          });

          $scope.users.push(user);
          $scope.loading--;
        });
      });
    } else {
      var userSearchPromise = userService.search(searchParams);
      userSearchPromise.then(function(response) {
        $scope.loading = response.items.length;
        _.forEach(response.items, function(user) {
          influencerService.getByUserId(user.id).then(function(influencer) {
            loadSocialMedia(influencer);
            user.influencer = influencer;
            influencerService.getShowsById(influencer.id).then(function(shows){
              user.influencer.shows = shows;
            });
            $scope.users.push(user);
          }).finally(function() {
            $scope.loading--;
          });
        });
      }).catch(function() {
        $scope.loading = undefined;
      });
    }

  }, 1000);

  $scope.getUserClasses = function(user) {
    var classes = [];
    if (user.disabled) {
      classes.push('user-disabled');
    }
    if (user.invite) {
      classes.push('user-invite');
      if (!user.inviteRedeemedAt) {
        classes.push('user-invite-waiting');
      }
    }
    if (user.inviteRedeemedAt) {
      classes.push('user-invite-redeemed');
    }
    return classes.join(' ');
  };

  search($scope.searchTerm, '');

  $scope.keypress = function($event) {
    if ($event.which === 13) {
      $scope.loading = true;
      search($scope.searchTerm, '');
    }
  };

}

module.exports = AdminInfluencersController;
