// @ngInject
function HomepageTilesDirective(viewportService, staticAssetService, $rootScope) {
  var config = window.orchardMileContent.home.treeTiles;
  var template = function() {
    return '/views/partials/homepage-tiles.html';
  };
  var linker = function($scope) {
    switch (config.version) {
      case 'v1.1':
        $scope.tilesV = 'v1';
        break;
      case 'v1.2':
        $scope.tilesV = 'v2';
        break;
      default:
      case 'v1.3':
        $scope.tilesV = 'v3';
        break;
    }
    if (config.tiles) {
      $scope.tiles = config.tiles;
    } else {
      $scope.tiles = config;
    }

    $scope.getTileImageUrl = function(tile) {
      if (!tile.image) {
        return false;
      }
      var baseUrl = '/content/images/tiles/';
      if (viewportService.is('<md')) {
        return staticAssetService.url(baseUrl + tile.image + '-mobile' +
          ($rootScope.isWEBPCapable ? '.webp' : '.jpg'));
      } else {
        return staticAssetService.url(baseUrl + tile.image +
          ($rootScope.isWEBPCapable ? '.webp' : '.jpg'));
      }
    };

    $scope.getTileTitleImageUrl = function(tile) {
      var baseUrl = '/content/images/tiles/';
      return staticAssetService.url(baseUrl + tile.titleImage + '.png');
    };
  };

  return {
    link: linker,
    templateUrl: template,
    restrict: 'E',
    scope: true
  };
}

module.exports = HomepageTilesDirective;
