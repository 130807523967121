var _ = require('lodash-core');

// @ngInject
function AdminOnboardingsController($scope, $state, $stateParams, fulfillerService, brandService, apiService,
                                    searchService) {

  var items = [];
  var disabledBrands = [];
  $scope.shopifyPending = false;
  $scope.loading = true;

  $scope.filterItems = function () {
    if ($scope.searchTerm) {
      $scope.filteredItems = _.filter(items, function(item) {
        return (item.partner && item.partner.id
          .trim()
          .toLowerCase()
          .indexOf(
            $scope.searchTerm
              .trim()
              .toLowerCase()
          ) >= 0) || (item.partner && item.partner.provider && item.partner.provider
          .trim()
          .toLowerCase()
          .indexOf(
            $scope.searchTerm
              .trim()
              .toLowerCase()
          ) >= 0) || (item.brand && item.brand.id
          .trim()
          .toLowerCase()
          .indexOf(
            $scope.searchTerm
              .trim()
              .toLowerCase()
          ) >= 0);
      });
    } else {
      $scope.filteredItems = items;
    }
  };

  $scope.reloadBrands = function () {
    items = [];
    loadBrands();
  };

  $scope.scrape = function (replaceImages) {
    var params = {
      brand: $scope.brand.id
    };
    if (replaceImages) {
      params.replaceImages = true;
    }
    apiService.post('/partner-providers/' + $scope.partner.provider + '/scraping', {
      urlParams: params
    }).then(function (response) {
      console.log(response);
      window.alert('Scraping process is executing please wait one minute and refresh the page cleaning cache');
    });
  };

  function loadBrands() {
    brandService.getAll('id', $scope.shopifyPending).then(function (brands) {
      if (window.orchardMileContent) {
        disabledBrands = _.difference(Object.keys(window.orchardMileContent.brands), _.map(brands, 'id'));
      }
      fulfillerService.getAll().then(function (partners) {
        var facets;
        searchService.getFacets({}).then(function (f) {
          facets = f;
        }).finally(function () {
          _.forEach(brands, function (brand) {
            var partner = _.find(partners, function (partner) {
              return partner.brands && partner.brands.includes(brand.id);
            });
            if (partner) {
              items.push({
                partner: partner,
                brand: brand,
                products: facets.brandId[brand.id]
              });
            }
          });
          if ($scope.shopifyPending) {
            items = items.filter(function (item) {
              return !item.brand.visible && item.partner.provider === 'shopify-app';
            });
          }
          $scope.filterItems();
          $scope.loading = false;
        });
      });
    });
  }

  function loadBrand(brandId) {
    brandService.getByIdWithoutMemoize(brandId).then(function (brand) {
      $scope.brand = brand;
      fulfillerService.getAll().then(function (partners) {
        $scope.partner = _.find(partners, function (partner) {
          return partner && partner.brands && partner.brands.includes(brand.id);
        });
        $scope.partner.partnerBrands = $scope.partner.brands.join(',');
        if ($scope.partner.external && $scope.partner.external.size) {
          $scope.partner.external.propSizes = $scope.partner.external.size.join(',');
        }
        if ($scope.partner.external && $scope.partner.external.color) {
          $scope.partner.external.propColors = $scope.partner.external.color.join(',');
        }
      });
    });
  }

  $scope.update = function () {
    brandService.update($scope.brand.id, $scope.brand).then(function () {
      $scope.partner.brands = $scope.partner.partnerBrands.split(',');
      fulfillerService.update(_.omit($scope.partner, ['partnerBrands'])).then(function () {
        $state.go('admin.earnings');
      });
    });
  };

  if (!$stateParams.id) {
    loadBrands();
  } else {
    loadBrand($stateParams.id);
    $scope.brandCheckboxProps = [
      { key: 'onlyMen', value: 'Only Men'},
      { key: 'onlyKids', value: 'Only Kids'},
      { key: 'overrideFulfillerPlpMessage', value: 'overrideFulfillerPlpMessage'},
      { key: 'visible', value: 'Visible'},
      { key: 'visibleInSale', value: 'Visible In Sale'},
      { key: 'visibleInWhatsNew', value: 'Visible In Sale'},
      { key: 'unavailable', value: 'Make Unavailable'},
    ];

    $scope.normalizationProps = [
      { key: 'propSizes', node: 'normalizedKeys', value: 'Normalized Sizes (comma separated)'},
      { key: 'propColors', node: 'normalizedKeys', value: 'Normalized Colors (comma separated)'}
    ];

    $scope.brandInputProps = [
      { key: 'name', id: 'brandName', value: 'Brand Name'},
      { key: 'id', id: 'brandId', value: 'Brand Id'},
      { key: 'providerId', value: 'Provider Id'},
    ];

    $scope.billingInputProps = [
      { key: 'accountingContactName', value: 'Accounting Contact Name' },
      { key: 'phoneNumber', value: 'Phone Number' },
      { key: 'emailAddress', value: 'Email Address' }
    ];

    $scope.shippingInputProps = [
      { key: 'shippingContactName', value: 'Shipping Contact Name' },
      { key: 'emailAddress', value: 'Email Address' },
      { key: 'shippingWarehouseAddress', value: 'Shipping Warehouse Address' },
      { key: 'returnWarehouseAddress', value: 'Return Warehouse Address' }
    ];

    $scope.generalInputProps = [
      { key: 'legalAddress', value: 'Legal Address' },
      { key: 'phoneNumber', value: 'Phone Number' },
      { key: 'primaryContactName', value: 'Primary Contact Name' },
      { key: 'customerServiceContactName', value: 'Customer Service Contact Name' },
      { key: 'techContactName', value: 'Tech Contact Name' },
      { key: 'privacyPoliciesLink', value: 'Privacy Policies Link' },
      { key: 'termsAndConditionsLink', value: 'Terms And Conditions Link' }
    ];

    $scope.socialMediaInputProps = [
      { key: 'instagram', node: 'socialMedia', value: 'Instagram' },
      { key: 'tiktok', node: 'socialMedia', value: 'TikTok' },
      { key: 'facebook', node: 'socialMedia', value: 'Facebook' },
      { key: 'youtube', node: 'socialMedia', value: 'YouTube' }
    ];

    $scope.partnerInputProps = [
      { key: 'name', id: 'partnerName', value: 'Partner Name'},
      { key: 'id', id: 'partnerId', value: 'Partner Id'},
      { key: 'provider', value: 'Provider Name'},
      { key: 'partnerBrands', value: 'Brands (comma separated)'},
      { key: 'url', value: 'Url'},
      { key: 'commissionRate', value: 'Commission', type: 'number'},
      { key: 'negociatedFee', value: 'Negociated Fee', type: 'number'},
      { key: 'additionalFee', value: 'Additional Fee', type: 'number'},
      { key: 'commissionSale', value: 'Commission Sale', type: 'number'},
      { key: 'accountsCreated', value: 'Accounts Created', type: 'number'},
      { key: 'accumulatedCommissionTowardsOnboardingFee', value: 'Accumulated Commission', type: 'number'}
    ];

    $scope.partnerCheckboxProps = [
      { key: 'hasBot', id: 'hasBot', value: 'has Bot'},
      { key: 'disabled', id: 'disabledPartnerId', value: 'Disabled'},
      { key: 'internationalShipping', value: 'International Shipping'},
      { key: 'orchardMileReporting', value: 'Orchard Mile Reporting'},
      { key: 'subscribeNewsletter', value: 'Subscribe Newsletter'}
    ];

    $scope.partnerArrayForm = [
      { node: 'shippingMethods', title: 'Shipping Methods', subTitle: 'Shipping', inputProps: [
          { key: 'id', id: 'shippingId', value: 'Id' },
          { key: 'description', id: 'shippingDescription', value: 'Description' },
          { key: 'code', value: 'Code' },
          { key: 'cost', value: 'Cost', type: 'number' },
          { key: 'daysToShip', value: 'DaysToShip', type: 'number' },
          { key: 'daysToDeliver', value: 'DaysToDeliver', type: 'number' },
          { key: 'daysToDeliverMax', value: 'DaysToDeliverMax', type: 'number' },
          { key: 'timeOfDayThreshold', value: 'TimeOfDayThreshold', type: 'number' }
        ] }
    ];

    $scope.partnerObjectForm = [
      { node: 'shippingAndReturns', title: 'Shipping and returns', inputProps: [
          { key: 'orderProcessing', value: 'Order Processing' },
          { key: 'returnPeriod', value: 'Return Period' },
          { key: 'brandSpecificReturnPolicy', value: 'Brand Specific Return Policy' },
          { key: 'returnFee', value: 'Return Fee' },
          { key: 'returnFeePercentage', value: 'Return Fee Percentage', type: 'number' },
          { key: 'giftOption', value: 'Gift Option' },
          { key: 'returnProcedure', value: 'Return Procedure' },
          { key: 'customerReturnProcedure', value: 'Customer Return Procedure' },
          { key: 'returnProcedureEmail', value: 'Return Procedure Email' },
          { key: 'finalSalePolicy', value: 'Final Sale Policy' },
          { key: 'exchangePolicy', value: 'Exchange Policy' },
          { key: 'returnPortalLink', value: 'Return Portal Link' },
          { key: 'returnEmailAddress', value: 'Return Email Address' },
          { key: 'orderProcessingTime', value: 'Order Processing Time' },
          { key: 'shippingFee', value: 'Shipping Fee' },
          { key: 'freeShippingThreshold', value: 'Free Shipping Threshold' },
          { key: 'shippingFeeToHLAndHIAndPR', value: 'Shipping Fee To HL/HI/PR' },
          { key: 'shippingFeeGlobally', value: 'Shipping Fee Globally' }
        ],
        checkboxProps: [
          { key: 'returnFeeVariable', value: 'Return Fee Variable' },
          { key: 'returnLabelSupplied', value: 'Return Label Supplied' },
          { key: 'shippingToHLAndHIAndPR', value: 'Shipping To HL/HI/PR' },
          { key: 'shippingGlobally', value: 'Shipping Globally' }
        ]
      },
      { node: 'external', title: 'External Configuration', inputProps: [
          { key: 'id', value: 'External ID' },
          { key: 'sellerId', value: 'Seller ID' }
        ],
        checkboxProps: [
          { key: 'hasTags', value: 'Has Tags' }
        ]
      }
    ];

    $scope.partnerArrayForm.push(
      { node: 'taxNexus', title: 'Tax Nexus', subTitle: 'Tax Information', inputProps: [
          { key: 'country', value: 'Country' },
          { key: 'state', value: 'State' },
          { key: 'city', value: 'City' },
          { key: 'zip', value: 'ZIP' },
          { key: 'street', value: 'Street' }
        ]
      }
    );
  }
}

module.exports = AdminOnboardingsController;
