var format = require('shared-business-rules').format;

// @ngInject
function CurrencyCentsFilter($rootScope) {

  var filter = function(input) {
    return format.formatCurrencyCents(input, {
      country: $rootScope.country,
      currency: $rootScope.currency
    });
  };

  filter.$stateful = true;

  return filter;
}

module.exports = CurrencyCentsFilter;
