var _ = require('lodash-core');
var getCountryByTimezone = require('../helpers/location').getCountryByTimezone;

// @ngInject
function GlobalizationService($rootScope, apiService, localStorageService, config, $location, cartService,
                              translationService) {

  $rootScope.countrySelected = function() {
    return ($rootScope.country && $rootScope.country.code) || 'us';
  };

  $rootScope.currencySelected = function() {
    return ($rootScope.country && $rootScope.country.currency) || 'USD';
  };

  this.getCurrencyData = function(currencyCode) {
    return apiService.get('/globalizations/currencies', {
      urlParams: {
        code: currencyCode
      }
    });
  };

  this.selectCountry = function(countrySelected) {
    if (config.featureFlags.translations.enabled) {
      var country = this.getCountry(countrySelected);
      apiService.addCache('country', country);
      translationService.changeLanguage(country.language);
      $rootScope.isInternationalFlag = country.code !== 'us';
      $rootScope.country = country;
      this.getCurrencyData(country.currency).then(function(currency) {
        cartService.reCalculateTotals();
        $rootScope.currency = currency;
        $rootScope.isInternationalFlag = country.code !== 'us';
      });
    }
  };

  this.initialize = function() {
    if (config.featureFlags.translations.enabled) {
      $rootScope.country = this.getCountry();
      apiService.addCache('country', $rootScope.country);
      translationService.changeLanguage($rootScope.country.language);
      $rootScope.isInternationalFlag = $rootScope.country.code !== 'us';

      if ($location.$$path.indexOf('/admin/') === -1) {
        this.setCurrency();
        setInterval(this.setCurrency, 30000);
      }
    }
  };

  this.getCountry = function(country) {
    var countrySelected;
    var self = this;
    try {
      countrySelected = country || localStorageService.getItem('country');
    } catch (e) {
      console.error(e);
    }

    if (countrySelected) {
      countrySelected = _.find(config.featureFlags.translations.countries, function(country) {
        //TODO: split language and countries to support countries with different language
        return countrySelected === country.code || countrySelected === country.language;
      });
    }

    if (!countrySelected) {
      countrySelected = _.find(config.featureFlags.translations.countries, function(country) {
        var zoneCode = self.getCountryCodeUsingTimeZone();
        if (zoneCode && zoneCode.toLowerCase() === country.code.toLowerCase()) {
          return true;
        }

        var navigatorCode = self.getCountryCodeUsingNavigator();
        if (navigatorCode && navigatorCode.toLowerCase() === country.code.toLowerCase()) {
          return true;
        }
      });

      if (!countrySelected) {
        return _.find(config.featureFlags.translations.countries, function(country) {
          return country.code === 'us';
        });
      }
    }

    localStorageService.setItem('country', countrySelected.code);
    return countrySelected;
  };

  this.getCountryCodeUsingNavigator = function() {
    var language = _.find(navigator.languages, function(lang) {
      if (lang && lang.length === 5 && lang.includes('-')) {
        return lang;
      }
    });
    if (language) {
      return language.split('-')[1];
    }
  };

  this.getCountryCodeUsingTimeZone = function() {
    try {
      return getCountryByTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    } catch (e) {
    }
  };

  this.setCurrency = function(reCalculateTotals) {
    //TODO: split country and currency
    if ($rootScope.country && $rootScope.country.code !== 'us') {
      $rootScope.loadingCurrency = true;
      return apiService.get('/globalizations/currencies', {
        urlParams: {
          code: $rootScope.country.currency
        }
      }).then(function(currency) {
        if (!$rootScope.currency ||
          ($rootScope.currency && $rootScope.currency.conversionRate !== currency.conversionRate)) {
          $rootScope.currency = currency;
          if (reCalculateTotals) {
            cartService.reCalculateTotals();
          }
        }
      }).catch(function(e) {
        console.log(e);
      }).finally(function() {
        $rootScope.loadingCurrency = false;
      });
    } else {
      delete $rootScope.currency;
    }
  };

  this.setExtraOrderCurrency = function(order) {
    if (order && order.totals && order.totals.international && !$rootScope.extraCurrency) {
      $rootScope.loadingCurrency = true;
      this.getCurrencyData(order.totals.international.currency).then(function(currency) {
        delete currency.conversionRate;
        $rootScope.extraCurrency = currency;
        $rootScope.loadingCurrency = false;
      });
    }
  };

}

module.exports = GlobalizationService;
