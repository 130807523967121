// @ngInject
function AssetSrcDirective(staticAssetService) {
  var linker = function($scope, element, attr) {
    attr.$observe('assetSrc', function(value) {
      if (!value) {
        return;
      }
      value = staticAssetService.url(value);
      var previousValue = element.attr('src');
      if (value === previousValue) {
        return;
      }
      attr.$set('src', value);
      if (element.prop) {
        element.prop('src', attr.src);
      }
      if (element.is('script')) {
        // script tags need to be reinserted in the DOM for new src to be loaded
        var placeholder = angular.element('<span class="script-placeholder"></span>');
        element.replaceWith(placeholder);
        placeholder.replaceWith(element);
      }

    });
  };
  return {
    priority: 99,
    link: linker,
    restrict: 'A'
  };
}

module.exports = AssetSrcDirective;
