// @ngInject
function GiftCardCodeDirective(giftCardService) {

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {
      var lastValue;
      function validate(viewValue) {
        var value = viewValue;
        lastValue = value;
        if (value === '' || value === null || value === undefined) {
          ctrl.$setValidity('notFound', true);
          ctrl.$setValidity('expiration', true);
          return viewValue;
        }
        if (giftCardService.codeRegex.test(value)) {
          ctrl.$setValidity('notFound', true);
          giftCardService.get(value).then(function(result) {
            if (lastValue !== value) {
              // value has already changed, ignore this result
              return;
            }
            ctrl.$setValidity('notFound', result.balance > 0);
            ctrl.$setValidity('expiration', result.daysToExpiration > 0 || result.balance <= 0);
          });
        } else {
          ctrl.$setValidity('notFound', false);
          ctrl.$setValidity('expiration', true);
        }
        return viewValue;
      }
      ctrl.$parsers.unshift(validate);
    }
  };
}

module.exports = GiftCardCodeDirective;
