var _ = require('lodash-core');
// @ngInject
function StyleGuideController($scope, $stateParams, productService, listContextService) {

  listContextService.setScopeContext($scope);

  $scope.setNoRobots();
  $scope.setPageMetadata({ title: 'Style guide for THE MILE' });

  $scope.scrollTo = function(section) {
    window.document.getElementById(section).scrollIntoView();
  };

  var section = $scope.section = ($stateParams.section || '').toLowerCase();
  if (section) {
    $scope.scrollTo(section);
  }

  /*
   * search an element with less-info and then try to find for each row an font-detail and a selector.
   * with the selector gets the CSS and fill into font-detail
   */
  $scope.getVariablesCSS = function() {
    var containers = $('.less-info .row');
    containers.each(function(k, v) {
      var fontDetail = $(v).find('.font-detail');
      var fontVariable = $(v).find('.selector');
      var colorDetail = $(v).find('.color-detail');
      colorDetail.html(colorDetail.html() +
        '<div class="color-square" style="background-color: ' +
        colorDetail.html() + '"></div>');
      if (fontVariable.length > 0 && fontDetail.length > 0) {
        fontDetail.html(
          '<span>' + fontVariable.css('font-family') + ', ' +
          fontVariable.css('font-size') + ', ' +
          fontVariable.css('font-weight') +
          '</span>');
      }
    });
  };

  $scope.getVariablesCSS();

  $scope.brands = [];
  productService.getShowcaseProductsByBrand().then(function(data) {
    // use _.map to generate brand object for each product, to allow product directive work properly
    $scope.brands = _.map(data.brands, function(brand) {
      brand.products = _.map(brand.products, function(product) {
        if (!product) {
          throw new Error('product cannot be undefined');
        }
        product.brand = { id: brand.id };
        return product;
      });
      return brand;
    });
  });

  $scope.breadcrumbSample = [
    { name: 'parent with link', link: '/style-guide' },
    { name: 'current without link' }];


  //breadcrumb
  $scope.setBreadcrumbPath([{ name: 'style guide' }]);
}

module.exports = StyleGuideController;
