var _ = require('lodash-core');
var localStoragePolyfill = require('./local-storage-polyfill');
localStoragePolyfill.initialize();

var envSubdomains = [
  'staging',
  'maxcdn',
  'dev',
  'local'
];

function Configurator() {
}

Configurator.prototype.detectSourceHost = function() {
  return document.location.host.toLowerCase();
};

Configurator.prototype.detectEnvironment = function() {
  var host = this.detectSourceHost();
  var hostParts = host.split('.').reverse();
  if (hostParts[0] === 'com' && hostParts[1] === 'orchardmile') {
    // this is an orchardmile page, use page main subdomain
    if (envSubdomains.indexOf(hostParts[2]) >= 0) {
      return hostParts[2];
    }
    return 'production';
  }

  // use build env name (generated at index.html build time)
  if (window.buildEnvironmentName && window.buildEnvironmentName !== 'local') {
    return window.buildEnvironmentName;
  }

  if (/^localhost\:/i.test(host) ||
      /^127\.0\.0\.1\:/i.test(host) ||
      /^0\.0\.0\.0\:/i.test(host) ||
      /^192\.168\.[0-9]{1,3}\.[0-9]{1,3}:/.test(host) ||
      hostParts[1] === 'localtunnel') {
    return 'local';
  }
  return 'production';
};

Configurator.prototype.getConfiguration = function(appOptions) {
  if (!this.configuration) {
    var all = require('./all');
    var config = all.base ? all.base(appOptions || {}) : {};
    config.environment = this.detectEnvironment();
    var envConfig = all[config.environment];
    if (envConfig) {
      envConfig(config);
    }
    this.postProcessConfig(config);
    this.configuration = config;

    // minification flag one-time initialization
    // to override this flag use the browser's console:
    //    localConfigOverride.minification(true|false);
    if (typeof config.minification !== 'undefined') {
      if ((typeof window !== 'undefined') && window.localStorage) {
        var minification = window.localStorage.getItem('minification');
        if (typeof minification === 'undefined' || minification === null) {
          window.localStorage.setItem('minification', config.minification);
        }
      }
    }
  }
  return this.configuration;
};

Configurator.prototype.postProcessConfig = function(config) {
  // apply feature flag toggles
  if (!config.featureFlags || !config.featureFlags.toggles) {
    return;
  }

  function getFeatureFlag(name) {
    var flag = config.featureFlags[name];
    if (flag) {
      return flag;
    }
    var key = _.find(Object.keys(config.featureFlags), function(featureName) {
      var initials = (featureName.slice(0, 1) +
        featureName.slice(1).replace(/[^A-Z]/g, '')).toLowerCase();
      return initials === name;
    });
    if (key) {
      return config.featureFlags[key];
    }
  }

  var toggles = config.featureFlags.toggles;
  if (!Array.isArray(toggles)) {
    toggles = toggles.split(/,/g);
  }
  toggles.forEach(function(key) {
    var on = true;
    if (key.slice(0, 1) === '!') {
      on = false;
      key = key.slice(1);
    }
    var flag = getFeatureFlag(key);
    if (flag) {
      flag.enabled = on;
    }
  });
};

Configurator.prototype.configureApp = function(app) {
  if (!app) {
    return;
  }
  var self = this;
  app.service('config', function(){
    var appOptions = app.defaultOptions || {};
    return self.getConfiguration(appOptions);
  });
};

module.exports = new Configurator();
