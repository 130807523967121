var EventEmitter = require('events').EventEmitter;
var promises = require('../../async/promises');

// @ngInject
function LoyaltyExperienceService(apiService) {
  this.events = new EventEmitter();

  this.getTierName = function(tier) {
    switch (tier) {
      case 2:
        return 'Gold';
      case 3:
        return 'Platinum';
      default:
        return 'Silver';
    }
  };

  this.getActiveLoyaltyExperiences = function() {
    return promises.extend(
      apiService.get('/loyalty/experiences', {
        urlParams: {
          'include-deleted': false,
          'include-inactive': false
        }
      })
    );
  };


  this.get = function(options) {
    return promises.extend(
      apiService.get('/loyalty/experiences', {
        urlParams: {
          'include-inactive': options.includeInactive,
          'include-secret': options.includeSecret,
          'include-exhausted': options.includeExhausted
        }
      })
    );
  };

  this.getById = function(id) {
    return promises.extend(
      apiService.get('/loyalty/experiences/{id}', {
        pathParams: {
          id: id
        }
      })
    );
  };

  this.deleteById = function(id) {
    return promises.extend(
      apiService.delete('/loyalty/experiences/{id}/remove', {
        pathParams: {
          id: id
        }
      })
    );
  };

  this.create = function(experience) {
    return promises.extend(
      apiService.post('/loyalty/experiences', {
        body: experience
      })
    );
  };

  this.update = function(experience) {
    return promises.extend(
      apiService.put('/loyalty/experiences/{id}', {
        pathParams: {
          id: experience.id
        },
        body: experience
      })
    );
  };

  this.addExperienceCodes = function(experienceId, params) {
    return promises.extend(
      apiService.post('/loyalty/experiences/{id}/codes', {
        pathParams: {
          id: experienceId
        },
        body: params
      })
    );
  };

  this.removeExperienceCode = function(experienceId, code) {
    return promises.extend(
      apiService.delete('/loyalty/experiences/{id}/code/{code}', {
        pathParams: {
          id: experienceId,
          code: code._id
        }
      })
    );
  };

  this.redeem = function(id) {
    return promises.extend(
      apiService.put('/loyalty/experiences/{id}/redeem', {
        pathParams: {
          id: id
        },
        body: {}
      })
    );
  };

  this.redeemReward = function(id) {
    return promises.extend(
      apiService.put('/loyalty/rewards/{id}/redeem', {
        pathParams: {
          id: id
        },
        body: {}
      })
    );
  };

}

module.exports = LoyaltyExperienceService;
