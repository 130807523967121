var _ = require('lodash-core');

/* jshint maxparams: 20 */

// @ngInject
function StaticPageController($scope, $rootScope, $state, $stateParams, $timeout,
                              contentPageService, platformService, errorService, listContextService, preloadService,
                              staticAssetService, metadataService, userService, fileDownloaderService,
                              contentPopupService) {

  listContextService.setScopeContext($scope, {categoriesOnQueryString: true});

  var name = $scope.name = $stateParams.name.toLowerCase();
  //TODO: Have this as a configuration
  if (name.indexOf('shop-on-modal-plps') > -1) {
    /*jshint camelcase: false */
    if (!$stateParams.plp_id) {
      $stateParams.plp_id = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img.exec($rootScope.iframeUrl)[1];
    }
    $scope.plp = _.find(window.orchardMileContent.shopOnModalPlps.plps, {id: $stateParams.plp_id});
    name = $scope.name = $stateParams.name.toLowerCase() + '/' + $scope.plp.template;
  } else if (name.indexOf('memorial-day-sales-2022') > -1) {
    $scope.tiles = window.orchardMileContent.sales.memorialDaySales2022.tiles;
  } else if (name.indexOf('black-friday-sales-2022') > -1) {
    $scope.tiles = window.orchardMileContent.sales.blackFridaySales2022.tiles;
  } else if (name.indexOf('cyber-week') > -1) {
    $scope.tiles = window.orchardMileContent.sales.cyberWeek.tiles;
  }
  $scope.productListName = name;

  $scope.isMobile = platformService.isMobile();
  $scope.isPopup = $stateParams.popup;

  $scope.$on('$destroy', function() {
    $rootScope.noPageHeader = false;
    $rootScope.noPageFooter = false;
  });

  $scope.$watch('page', function() {
    $rootScope.noPageHeader = $scope.page.noHeader || $scope.isPopup;
    $rootScope.noPageFooter = $scope.page.noFooter || $scope.isPopup;
  });

  var promise = contentPageService.getPage(name);
  $scope.page = promise.lazyObject();
  promise.then(function() {
    metadataService.setArticle({
      title: $scope.page.title,
      description: $scope.page.dek || $scope.page.subtitle || '',
      image: staticAssetService.url($scope.page.metaImage || $scope.page.image || $scope.page.heroImageUrl),
      shareImage: ($scope.page.shareImage || $scope.page.image),
    });
    $rootScope.noPageHeader = $scope.page.noHeader || $scope.isPopup;
    $rootScope.noPageFooter = $scope.page.noFooter || $scope.isPopup;

    if ($stateParams.edit) {
      var user = userService.getUser();
      if (user && user.roles && user.roles.indexOf('admin') >= 0) {
        $scope.editLive();
      }
    }
  }, function(err) {
    if ([403, 404].indexOf(err.status) < 0) {
      errorService.pageError(err);
    }
    if ($stateParams.create) {
      var user = userService.getUser();
      if (user && user.roles && user.roles.indexOf('admin') >= 0) {
        $scope.page = {
          html: '',
          title: 'New Page'
        };
        $scope.create = true;
        $scope.editLive();
        return;
      }
    }
    // not found
    errorService.notFound();
  });

  // breadcrumb
  var names = name.split('/');
  names.pop();
  $scope.pages = [{page: $scope.page}];
  while (names.length) {
    var current = names.join('/');
    names.pop();
    $scope.pages.unshift({
      page: contentPageService.getPage(current).lazyObject(),
      link: $state.href('root.pages', {name: current})
    });
  }

  var editor;

  function startLiveEdit(source) {
    $scope.liveEditing = true;
    $scope.source = {
      original: source.toString(),
      current: source.toString()
    };
    $scope.sourcePath = '/public/pages/' + $scope.name + '.md';

    function createEditor() {
      var editPanel = document.getElementById('live-edit-panel');
      if (editPanel) {
        editPanel.scrollIntoView();
      } else {
        $timeout(createEditor, 500);
        return;
      }
      /* global CodeMirror */
      var textarea = document.querySelector('.live-edit-panel textarea');
      if (!textarea) {
        setTimeout(createEditor, 500);
        return;
      }
      editor = CodeMirror.fromTextArea(textarea, {
        mode: 'gfm',
        lineNumbers: true,
        viewportMargin: Infinity
      });
      editor.on('change', function() {
        $timeout(function() {
          $scope.source.current = editor.getValue();
        });
      });
      $scope.loadingEditor = false;
    }

    createEditor();
  }

  $scope.fileUploaderVisible = false;

  function getStaticAssetList() {
    $scope.staticAssetList = contentPageService.getContentPageStaticAssets().map(function(url) {
      return {
        isImage: /\.(png|jpg|jpeg|gif)$/i.test(url),
        url: getAssetUrl(url)
      };
    });
    $scope.staticAssetList.reverse();
  }

  function getAssetUrl(url) {
    if (/orchardmile\.com$/.test(window.location.host)) {
      var prefix = '//static' + (/^orchardmile/.test(window.location.host) ? '.' : '-');
      return url.replace(
        /^.*?\/content-files\//,
        window.location.protocol + prefix + window.location.host + '/content-files/');
    }
    return url;
  }

  $scope.fileUploaderToggle = function() {
    $scope.fileUploaderVisible = !$scope.fileUploaderVisible;
    if ($scope.fileUploaderVisible) {
      getStaticAssetList();
    }
  };

  $scope.$on('fileUploaded', function(e, url) {
    contentPageService.fileUploaded(url);
    getStaticAssetList();
  });

  $scope.setFrontMatterAttribute = function(key, value) {
    var source = $scope.source.current;
    var frontMatter = /^---\n([\s\S]+)---\n/m.exec(source);
    if (!frontMatter) {
      editor.setSelection({line: 0, ch: 0}, {line: 0, ch: 0});
      editor.replaceSelection('---\n' + key + ': ' + value +
        '\n---\n', 'around');
      return;
    }

    var frontMatterLines = frontMatter[1].split(/\n/g);
    var currentLine = frontMatterLines.map(function(line) {
      var keyMatch = /\s*([^\:]+)\:/.exec(line);
      if (!keyMatch) {
        return;
      }
      return keyMatch[1];
    }).indexOf(key);

    if (currentLine < 0) {
      editor.setSelection({line: 1, ch: 0}, {line: 1, ch: 0});
      editor.replaceSelection(key + ': ' + value + '\n', 'around');
      return;
    }
    editor.setSelection({line: currentLine + 1, ch: 0},
      {line: currentLine + 1, ch: frontMatterLines[currentLine].length});
    editor.replaceSelection(key + ': ' + value, 'around');
  };

  $scope.setPublished = function() {
    $scope.setFrontMatterAttribute('published', new Date().toISOString());
  };

  $scope.insertUrl = function(url) {
    var cursor = editor.getCursor();
    var line = editor.getLine(cursor.line);
    var before = line.slice(0, cursor.ch);
    var after = line.slice(cursor.ch);
    var beforeQuoteIndex = Math.max(before.lastIndexOf('"'), before.lastIndexOf('\''));
    if (beforeQuoteIndex >= 0) {
      var afterQuoteIndex = after.indexOf(before[beforeQuoteIndex]);
      if (afterQuoteIndex >= 0) {
        // select the full url and replace it
        editor.setSelection({
          line: cursor.line,
          ch: beforeQuoteIndex + 1
        }, {
          line: cursor.line,
          ch: cursor.ch + afterQuoteIndex
        });
        editor.replaceSelection(url, 'around');
        return;
      }
    }

    var urlMatch = /[^\w]((?:https?:\/)?\/[\w\d\-\_\/\.\:\?]+\.[\w\d]{2,4})[\"\']?\s*$/i.exec(line);
    if (urlMatch) {
      // cursor is in a url
      editor.setSelection({
        line: cursor.line,
        ch: urlMatch.index + 1
      }, {
        line: cursor.line,
        ch: urlMatch.index + 1 + urlMatch[1].length
      });
      editor.replaceSelection(url, 'around');
      return;
    }

    var metadataMatch = /:([ \w\d\.\/\-\_\:\?]*)$/i.exec(line);
    if (metadataMatch) {
      // cursor is in a url
      editor.setSelection({
        line: cursor.line,
        ch: metadataMatch.index + 1
      }, {
        line: cursor.line,
        ch: metadataMatch.index + 1 + metadataMatch[1].length
      });
      editor.replaceSelection(' ' + url, 'around');
      return;
    }

    editor.replaceSelection(/\.(jpg|jpeg|png|gif|svg)$/i.test(url) ?
      '\n<img class="" asset-src=\"' + url + '\" />\n' :
      '\n<a href=\"' + url + '\" />download</a>\n');
  };

  $scope.showPages = function() {
    $scope.loadingPageList = true;
    contentPageService.listAll().then(function(list) {
      $scope.pageList = list.map(function(pageName) {
        var path = pageName.split(/\//g);
        return {
          name: pageName,
          path: path,
          basename: path[path.length - 1]
        };
      });
      delete $scope.loadingPageList;
    }, function(err) {
      delete $scope.loadingPageList;
      /* global alert */
      console.error(err);
      alert('Oops! An error ocurred while loading pages list: ' + err.message);
    });
  };

  $scope.hidePages = function() {
    delete $scope.pageList;
  };

  $scope.editLive = function() {
    if ($scope.liveEditing) {
      var editPanel = document.getElementById('live-edit-panel');
      if (editPanel) {
        editPanel.scrollIntoView();
      }
      return;
    }

    $scope.loadingEditor = true;

    var editorReady = false;
    var pageSource;

    function checkIfReady() {
      if (editorReady && pageSource !== undefined) {
        startLiveEdit(pageSource);
      }
    }

    if ($scope.create) {
      $timeout(function() {
        pageSource = '';
        checkIfReady();
      });
    } else {
      contentPageService.getSource($scope.name).then(function(src) {
        pageSource = src;
        checkIfReady();
      }, function(err) {
        $scope.loadingEditor = false;
        /* global alert */
        console.error(err);
        alert('Oops! An error ocurred while loading page source: ' + err.message);
      });
    }

    preloadService.style('/components/codemirror/lib/codemirror.css');
    preloadService.style('/components/codemirror/lib/codemirror.css');
    preloadService.scripts([
      '/components/codemirror/lib/codemirror.js',
      '/components/codemirror/addon/mode/overlay.js',
      '/components/codemirror/mode/markdown/markdown.js',
      '/components/codemirror/mode/xml/xml.js',
      '/components/codemirror/mode/css/css.js',
      '/components/codemirror/mode/gfm/gfm.js',
      '/components/codemirror/mode/htmlmixed/htmlmixed.js',
      '/components/plupload/js/plupload.full.min.js',
    ]).then(function() {
      editorReady = true;
      checkIfReady();
    }, function(err) {
      $scope.loadingEditor = false;
      /* global alert */
      console.error(err);
      alert('Oops! An error ocurred while loading page editor: ' + err.message);
    });
  };

  $scope.editPopup = function() {
    var url = document.location.href +
    document.location.href.indexOf('?') >= 0 ? '&' : '?' +
      'edit=true&popup=true';
    var popup = window.open(url, 'edit-popup', 'height=600,width=800');
    if (popup.focus) {
      popup.focus();
    }
    return false;
  };

  $scope.previewWindow = function() {
    var url = document.location.href.replace('edit=true', '');
    var popup = window.open(url, 'edit-preview', 'height=400,width=600');
    if (popup.focus) {
      popup.focus();
    }
    return false;
  };

  $scope.finishEdit = function() {
    $scope.liveEditing = false;
    delete $scope.source;
    $scope.scrollToTop();
    if ($stateParams.popup) {
      window.close();
    }
  };

  $scope.saveChanges = function() {
    if ($scope.source.original === $scope.source.current) {
      $scope.finishEdit();
      return;
    }
    $scope.saving = true;
    contentPageService.saveSource($scope.name, $scope.source.current).then(function() {
      delete $scope.fileList;
      $scope.saving = false;
      $scope.create = false;
      $scope.finishEdit();
    }, function(err) {
      $scope.saving = false;
      /* global alert */
      console.error(err);
      alert('Oops! An error ocurred while saving changes: ' + err.message);
    });
  };

  $scope.$watch('source.current', function() {
    if ($scope.liveEditing) {
      if (!($scope.source && $scope.source.current)) {
        return;
      }
      contentPageService.compile($scope.source.current).then(function(compiledPage) {
        if (!$scope.source) {
          return;
        }
        $scope.page = compiledPage;
        contentPageService.liveEditChange($scope.name, compiledPage);
      });
    }
  });

  contentPageService.listenForLiveEditChanges();

  $scope.$onEmitter(contentPageService.events, 'change', function(e) {
    if ($scope.liveEditing || e.name !== $scope.name) {
      return;
    }
    $scope.page = e.value;
    $scope.page.name = e.name;
  });

  $scope.isEmailable = function() {
    // to identify as emailable look for html comment:
    //      <!-- emailable: true -->
    return $scope.page.emailable ||
      /emailable:\s*true/.test($scope.page.html);
  };

  $scope.downloadEmail = function() {
    $scope.downloadingEmail = true;
    setTimeout(function() {
      $scope.page.contentNode = $('.page-content');
      $scope.page.name = name;
      contentPageService.getEmailFromPage($scope.page).then(function(response) {
        try {
          var filename = name.replace(/\//g, '-') + '.html';
          fileDownloaderService.download(filename, response.content);
        } finally {
          delete $scope.downloadingEmail;
        }
      });
    }, 1000);
  };

  $scope.currentPageIsAPopup = function() {
    return /^popups\//.test($scope.name);
  };

  $scope.showAsPopup = function() {
    var popup = _.cloneDeep($scope.page);
    delete popup.showOnlyOnce;
    var name = $scope.name.replace(/^popups\//, '');
    contentPopupService.show(name, popup);
  };

  $scope.$watch(function() {
    return JSON.stringify($scope.pages);
  }, function() {
    $scope.setBreadcrumbPath(
      _.map($scope.pages, function(parent) {
        if (parent.link === '/influencers') {
          return {name: 'Tastemakers', link: '/influencers'};
        // } else if (parent.link === '/occasions') {
        //   return {name: 'Event', link: '/occasions'};
        // } else if (parent.link === '/people') {
        //   return {name: 'Best Dressed', link: '/people'};
        // } else if (parent.link === '/inspiration') {
        //   return {name: 'Inspiration', link: '/stories'};
        // } else if (parent.link === '/inspiration') {
        //   return {name: 'Inspiration', link: '/inspiration'};
        } else if (parent.link === '/holiday') {
          return {name: 'Holiday', link: '/holiday'};
        } else if (parent.link && parent.link.indexOf('/shop-on-modal-plps') > -1) {
          return;
        } else if (parent.page.name && parent.page.name.indexOf('shop-on-modal-plps') > -1) {
          return {name: $scope.plp.name, link: undefined};
        }
        var contentWithoutMainScript = (parent.html || '').replace(/<script(\s+|>).+?<\/script>/, '');
        var shouldHasLink = /\w/.test(contentWithoutMainScript); // check content not blank
        var pageNiceName = parent.page.name ?
          parent.page.name.replace(/-/g, ' ') : '';
        return {name: pageNiceName, link: shouldHasLink ? parent.link : undefined};
      })
    );
    // deleted 'occasions', 'edits', 'inspiration',
    if (['holiday'].indexOf($scope.name.split('/')[0]) > -1) {  
      var anchors = document.querySelectorAll('div[class="page-content"] a');
      for (var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        if (
          !platformService.isBot() &&
          anchor.href.indexOf('comingfrom') === -1 &&
          (anchor.href.indexOf('localhost:3000') > -1 || anchor.href.indexOf('orchardmile.com') > -1)
        ) {
          if (anchor.href.indexOf('?') > -1) {
            anchor.href += '&comingfrom=' + $scope.name;
          } else {
            anchor.href += '?comingfrom=' + $scope.name;
          }
        }
      }
    }
  });


  // Injecting url parameter for items comingFrom and deleted 'occasions', 'edits', 'inspiration'
  if (
    !platformService.isBot() && ['holiday'].indexOf($scope.name.split('/')[0]) > -1
  ) {
    document.addEventListener('click', function(e) {
      if (
        (
          e.target.getAttribute('href') &&
          e.target.getAttribute('href').indexOf('comingfrom') === -1 &&
          (
            e.target.getAttribute('href').indexOf('localhost:3000') > -1 ||
            e.target.getAttribute('href').indexOf('orchardmile.com') > -1
          )
        )
      ) {
        if (e.target.getAttribute('href').indexOf('?') > -1) {
          e.target.setAttribute('href', e.target.getAttribute('href') + '&comingfrom=' + $scope.name);
        } else {
          e.target.setAttribute('href', e.target.getAttribute('href') + '?comingfrom=' + $scope.name);
        }
      } else if (
        (
          e.target.parentNode.getAttribute('href') &&
          e.target.parentNode.getAttribute('href').indexOf('comingfrom') === -1 &&
          (
            e.target.parentNode.getAttribute('href').indexOf('localhost:3000') > -1 ||
            e.target.parentNode.getAttribute('href').indexOf('orchardmile.com') > -1
          )
        )
      ) {
        if (e.target.parentNode.getAttribute('href').indexOf('?') > -1) {
          e.target.parentNode.setAttribute(
            'href', e.target.parentNode.getAttribute('href') + '&comingfrom=' + $scope.name
          );
        } else {
          e.target.parentNode.setAttribute(
            'href', e.target.parentNode.getAttribute('href') + '?comingfrom=' + $scope.name
          );
        }
      } else if (
        (
          e.target.parentNode.parentNode.getAttribute('href') &&
          e.target.parentNode.parentNode.getAttribute('href').indexOf('comingfrom') === -1 &&
          (
            e.target.parentNode.parentNode.getAttribute('href').indexOf('localhost:3000') > -1 ||
            e.target.parentNode.parentNode.getAttribute('href').indexOf('orchardmile.com') > -1
          )
        )
      ) {
        if (e.target.parentNode.parentNode.getAttribute('href').indexOf('?')) {
          e.target.parentNode.parentNode.setAttribute(
            'href', e.target.parentNode.parentNode.getAttribute('href') + '&comingfrom=' + $scope.name
          );
        } else {
          e.target.parentNode.parentNode.setAttribute(
            'href', e.target.parentNode.parentNode.getAttribute('href') + '?comingfrom=' + $scope.name
          );
        }
      }
    }, false);
  }
}

module.exports = StaticPageController;
