var promises = require('../../../async/promises');
var _ = require('lodash-core');

function PaypalPaymentProvider(config, userService, $rootScope, $timeout, apiService) {
  this.config = config;
  this.userService = userService;
  this.$rootScope = $rootScope;
  this.$timeout = $timeout;
  this.apiService = apiService;
}

PaypalPaymentProvider.prototype.createPayment = function(paymentRequest) {
  return promises.extend(
    this.apiService.post('/orders/paypal-payment', {
      body: JSON.stringify(paymentRequest)
    })
  );
};

PaypalPaymentProvider.prototype.authorizePayment = function(paymentToAuthorize) {
  return promises.extend(
    this.apiService.post('/orders/paypal-payment-authorization', {
      body: JSON.stringify(paymentToAuthorize)
    })
  );
};

PaypalPaymentProvider.prototype.prepareOrder = function(order) {
  if (order.shipping.contact.email) {
    order.shipping.contact.email = order.shipping.contact.email.toLowerCase();
  }
  if (order && order.billing && order.billing.paymentMethod) {
    /*jshint camelcase: false */
    var fullName = order.billing.paymentMethod.paymentProviderData.payer.name.given_name;
    _.merge(order.billing.paymentMethod, {
      cardNumber: '4242424242424242',
      expirationMonth: 1,
      expirationYear: 2100,
      nameOnCard: fullName,
      cvv: '111'
    });
  }
  return promises.immediate(order);
};

module.exports = PaypalPaymentProvider;
