var promises = require('../../async/promises');
var _ = require('lodash-core');

/* globals Promise */
// @ngInject
function InfluencerService(apiService) {

  this.getById = function(influencerId) {
    return promises.extend(apiService.get('/influencers/{id}', {
      pathParams: {
        id: influencerId
      }
    }));
  };

  this.getByUserId = function(userId) {
    return promises.extend(apiService.get('/influencers/by-user-id/{id}', {
      pathParams: {
        id: userId
      }
    }));
  };

  this.getShowsById = function(influencerId, terms) {
    return promises.extend(apiService.get('/influencers/{id}/shows', {
      pathParams: {
        id: influencerId
      },
      urlParams: terms
    }));
  };

  this.getUnapprovedInfluencers = function () {
    return promises.extend(apiService.get('/influencers/unapproved/all'));
  };

  this.getByUsername = function(influencerPageName) {
    return promises.extend(apiService.get('/influencers/by-username/{username}', {
      pathParams: {
        username: influencerPageName
      }
    }));
  };

  this.addInfluencerToShows = function(shows) {
    var self = this;
    var obtained = 0;
    return new Promise(function(resolve) {
      _.forEach(shows, function(show) {
        self.getById(show.influencerId).then(function(influencer) {
          show.influencerName = influencer.username || influencer.preferredName;
          show.photoUrl = influencer.profilePicture;
        }).finally(function() {
          obtained++;
          if (obtained === shows.length) {
            resolve();
          }
        });
      });
    });
  };

  this.updateInfluencerApproval = function (influencerId, influencer) {
    return promises.extend(apiService.patch('/influencers/{id}/approve', {
      pathParams: {
        id: influencerId
      },
      body: influencer
    }));
  };

  this.updateInfluencer = function (influencerId, influencer) {
    return promises.extend(apiService.patch('/influencers/{id}', {
      pathParams: {
        id: influencerId
      },
      body: {
        specialDaysHours: influencer.specialDaysHours,
        isBrand: influencer.isBrand
      }
    }));
  };

  this.getAttributions = function(params) {
    return promises.extend(apiService.get('/influencers-attributions', {
      urlParams: {
        influencerId: params.influencerId,
        from: params.from,
        to: params.to
      }
    }));
  };

  this.getOrderTotals = function(params) {
    return promises.extend(apiService.get('/order-totals', {
      urlParams: {
        from: params.from,
        to: params.to
      }
    }));
  };

  this.updateInfluencerCloset = function(influencerId, preOwnedProducts, brandId) {
    return promises.extend(apiService.patch('/influencers/{id}', {
      pathParams: {
        id: influencerId
      },
      body: {
        preOwnedProducts: preOwnedProducts,
        brandId: brandId
      }
    }));
  };
}

module.exports = InfluencerService;
