
// @ngInject
function InviteController($rootScope, $scope, $state, formsService, userService,
  metadataService, config) {

  $rootScope.noPageHeader = true;
  $rootScope.noPageFooter = true;

  metadataService.setPage({
    title: 'The Mile - Scroll down fashion\'s most luxurious mile',
  });
  metadataService.setRobots('index,nofollow');

  $scope.$on('$destroy', function() {
    $rootScope.noPageHeader = false;
    $rootScope.noPageFooter = false;
  });

  $scope.showForm = function() {
    $scope.formIsVisible = true;
    formsService.focusFirstField();
  };

  if (userService.isLoggedIn() || !config.privateAccess) {
    $state.go('root.home');
    return;
  }

  $scope.$onEmitter(userService.events, 'loggedIn', function() {
    $state.go('root.home');
  });

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.requestInvite = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }
    var email = $scope.email;
    formPromise.handle(
      userService.invite(email)
    );
  };

}

module.exports = InviteController;
