// @ngInject
function InviteRedeemController($scope, $state, $stateParams, formsService, userService) {

  if (userService.isLoggedIn()) {
    $state.go('root.home');
    return;
  }
  $scope.newUser = {};
  $scope.sharedLink = true;
  if (typeof $stateParams.email === 'undefined') {
    $scope.newUser.email = '';
  } else {
    $scope.newUser.email = $stateParams.email;
    $scope.sharedLink = false;
  }
  if (typeof $stateParams.code === 'undefined') {
    $scope.newUser.code = '';
  } else {
    $scope.newUser.code = $stateParams.code;
  }
  if (typeof $stateParams.referrer === 'undefined') {
    $scope.newUser.referer = '';
  } else {
    $scope.newUser.referer = $stateParams.referrer;
    $scope.setPageMetadata({
      title: 'I Want To Share The Mile With You',
      description: 'I just shopped at The Mile, the only fashion destination that ' +
        'offers full collections from all of our favorite brands.'
    });
  }


  $scope.setNoRobots();
  $scope.setPageMetadata({ title: 'Redeem your invite to access THE MILE' });

  if ($scope.newUser.email !== '') {
    userService.checkInvite($scope.newUser.email, $scope.newUser.code).then(function(inviteData) {
      if (inviteData.valid) {
        $scope.firstName = inviteData.firstName || '';
        $scope.lastName = inviteData.lastName || '';
        return;
      }
      $scope.inviteIsInvalid = inviteData;
      var reason = 'This invite code is not valid.';
      if (inviteData.notValid) {
        $state.go('root.home');
        return;
      } else if (inviteData.notFound) {
        reason = 'No invite for ' + $scope.newUser.email + ' has been found';
      } else if (inviteData.disabled) {
        reason = 'The account for ' + $scope.newUser.email + ' is not active';
      } else if (inviteData.inviteAlreadyRedeemed) {
        reason = 'It seems this invite for ' + $scope.newUser.email + ' has already been redeemed.';
      } else if (inviteData.userHasNoInviteCode) {
        reason = $scope.newUser.email + ' is already registered';
      }
      $scope.inviteIsInvalidReason = reason;
    });
  } else if (typeof $scope.newUser.referer === 'undefined') {
    $state.go('root.home');
  } else {
    //Validate referer
  }

  $scope.$onEmitter(userService.events, 'loggedIn', function() {
    $state.go('root.home');
  });

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.redeemInvite = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var params = {
      email: $scope.newUser.email,
      password: $scope.newUser.password,
      code: $scope.newUser.code,
      firstName: $scope.newUser.firstName,
      lastName: $scope.newUser.lastName,
      referer: $scope.newUser.referer,
      transactional: $scope.newUser.transactional,
      trigger: 'invite-redeem'
    };

    formPromise.handle(userService.redeemInvite(params.email, params.code, params.password,
      params.firstName, params.lastName, params.referer, params.transactional))
      .then(function() {
        // login using new credentials
        userService.login(params)
          .then(function() {
            $state.go('root.home');
          }, function() {
            $scope.loggedInFailed = true;
            $state.go('root.home');
          });
      });
  };

  $scope.login = function() {
    $scope.modal.show('login', {
      email: $stateParams.email,
      trigger: 'invite-redeem'
    });
  };

  $scope.forgotPassword = function() {
    $scope.modal.show('forgot-password', {
      email: $stateParams.email,
      trigger: 'invite-redeem'
    });
  };

}

module.exports = InviteRedeemController;
