var resourceShadow = require('resource-shadow');

// @ngInject
function ResourceShadowService(apiService) {

  this.create = function(key, options, transform) {
    if (!key) {
      throw new Error('a key for storing at localStorage is required');
    }

    transform = transform || {};

    options.localKey = 'orchard-mile-' + key;
    options.httpHandler = {
      get: function(url, headers, callback) {
        if (transform && transform.url) {
          url = transform.url(url, 'GET');
        }
        apiService.get(url).then(function(data) {
          if (transform.fromRemote) {
            data = transform.fromRemote(data);
          }
          callback(null, JSON.stringify(data));
        }, callback);
      },
      put: function(url, headers, body, callback) {
        if (transform && transform.url) {
          url = transform.url(url, 'PUT');
        }
        body = JSON.parse(body);
        if (transform.toRemote) {
          body = transform.toRemote(body);
        }
        apiService.put(url, { body: body }).then(function(data) {
          if (transform.fromRemote) {
            data = transform.fromRemote(data);
          }
          callback(null, JSON.stringify(data));
        }, callback);
      },
      post: function(url, headers, body, callback) {
        if (transform && transform.url) {
          url = transform.url(url, 'POST');
        }
        body = JSON.parse(body);
        if (transform.toRemote) {
          body = transform.toRemote(body);
        }
        apiService.post(url, {body: body}).then(function(data) {
          if (transform.fromRemote) {
            data = transform.fromRemote(data);
          }
          callback(null, JSON.stringify(data));
        }, callback);
      }
    };

    return resourceShadow.create(options);
  };
}

module.exports = ResourceShadowService;
