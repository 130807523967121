
// @ngInject
function ReferFriendsDetailsController($scope, userService) {
  $scope.userRewards = {};
  $scope.referreds = {};
  var userRewardsPromise = userService.getRewards();
  var userReferredsPromise = userService.getReferred();
  userReferredsPromise.then(function(data) {
    $scope.referreds = data.referred;
  });
  userRewardsPromise.then(function(rewards) {
    $scope.userRewards = rewards.items;
  });
  $scope.friendsPurchased = function() {
    var purchased = 0;
    if ($scope.userRewards.length > 0) {
      $scope.userRewards.forEach(function(reward) {
        if (reward.type === 'referral-first-order') {
          purchased++;
        }
      });
    }
    return purchased;
  };

  $scope.inStoreTotalCredit = function() {
    var credit = 0;
    if ($scope.userRewards.length > 0) {
      $scope.userRewards.forEach(function(reward) {
        if (reward.type === 'referral-first-order') {
          credit += reward.credit;
        }
      });
    }
    return credit;
  };

  $scope.inStoreCredit = function() {
    var credit = 0;
    if ($scope.userRewards.length > 0) {
      $scope.userRewards.forEach(function(reward) {
        if (reward.type === 'referral-first-order' && reward.used !== true) {
          credit += reward.credit;
        }
      });
    }
    return credit;
  };
}

module.exports = ReferFriendsDetailsController;

