
// @ngInject
function CartItemController($scope, cartService, algoliaInsightsService) {

  $scope.$watch('item.quantity', function() {
    $scope.shadowQuantity = $scope.item.quantity;
    refreshQuantityOptions();
  });

  $scope.$watch('shadowQuantity', function() {
    if ($scope.shadowQuantity !== $scope.item.quantity) {
      cartService.add({
        styleId: $scope.item.styleId,
        variant: $scope.item,
        quantity: $scope.shadowQuantity - $scope.item.quantity,
        source: 'cart'
      });
      refreshQuantityOptions();
    }
  });
  $scope.setForLater = function(item, forLater) {
    setTimeout(function() {
      cartService.add({
        styleId: item.styleId,
        variant: item,
        metadata: item.metadata,
        forLater: forLater,
        moving: true,
        source: 'cart'
      });
      refreshQuantityOptions();
    }, 10);
  };

  $scope.queryId = algoliaInsightsService.generateUUID();
  $scope.getObjectId = algoliaInsightsService.getObjectId;
  $scope.isSoldOut = cartService.isSoldOut;
  $scope.isForLater = cartService.isForLater;
  $scope.isPurchasable = cartService.isPurchasable;
  $scope.isOverMaxQty = cartService.isOverMaxQty;
  $scope.isBrandAvailable = cartService.isBrandAvailable;

  function refreshQuantityOptions() {
    $scope.quantityOptions = [];
    if (!$scope.item.getMaxQty) {
      cartService.setUsingGiftCard();
    }
    $scope.maxQty = $scope.item.getMaxQty();
    $scope.brandItemMaxQty = ($scope.item.getBrandItemMaxQty() <= 0) ? 0 : $scope.item.getBrandItemMaxQty();
    $scope.brandMaxQty = $scope.item.brandMaxQty;
    var maxQty = 10;
    if ($scope.maxQty) {
      maxQty = $scope.maxQty;
    }
    if ($scope.brandMaxQty && $scope.brandMaxQty < maxQty) {
      maxQty = $scope.brandMaxQty;
    }
    if ($scope.item.variant() && $scope.item.variant().stock > 0 && $scope.item.variant().stock < maxQty) {
      maxQty = $scope.item.variant().stock;
    }
    for (var i = 1; i <= Math.max(maxQty, $scope.item.quantity); i++) {
      $scope.quantityOptions.push(i);
    }
  }
}

module.exports = CartItemController;
