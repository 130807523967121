var promises = require('../../async/promises');

// @ngInject
function NormalizedColorService(apiService) {

  this.get = promises.memoize(function(params) {
    return promises.extend(apiService.get('normalized-colors', {
      urlParams: params
    }));
  });

  this.save = function(color) {
    if (color.id) {
      return promises.extend(apiService.put('normalized-colors', {
        body: color
      }));
    } else {
      return promises.extend(apiService.post('normalized-colors', {
        body: color
      }));
    }
  };
}

module.exports = NormalizedColorService;
