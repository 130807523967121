
// @ngInject
function ContactUsController($scope, formsService, userService, $timeout) {
  var formPromise = $scope.formPromise = formsService.promiseHandler();
  $scope.contactData = {};

  var user = userService.getUser();
  if (user && user.email) {
    $timeout(function() {
      $scope.form.email.$setViewValue(user.email);
      $scope.form.email.$render();
    }, 100);
  }
  if (user && user.firstName && user.lastName) {
    $timeout(function() {
      $scope.form.name.$setViewValue(user.firstName + ' ' + user.lastName);
      $scope.form.name.$render();
    }, 100);
  }

  $scope.isLoggedIn = userService.isLoggedIn;

  $scope.sendContactUs = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    formPromise.handle(
      userService.contactUs($scope.contactData)
    );
  };

  $scope.retry = function() {
    formPromise.clear();
    formsService.focusFirstField();
  };

  $scope.setBreadcrumbPath([{ name: 'contact us' }]);
  $scope.setPageMetadata({ title: 'Contact Us | THE MILE' });
}

module.exports = ContactUsController;
