
// @ngInject
function IncludeReplaceDirective() {
  return {
    require: 'ngInclude',
    restrict: 'A', /* optional */
    link: function(scope, el) {
      el.replaceWith(el.children());
    }
  };
}

module.exports = IncludeReplaceDirective;