var _ = require('lodash-core');

// @ngInject
function AdminRelatedBrandsController($scope, $state, relatedBrandService) {

  $scope.fetchRelatedBrands = function () {
    $scope.brandList = [];

    var prom = relatedBrandService.get({
      limit: 1000,
      sort: 'id'
    });

    prom.then(function (data) {
      $scope.relatedBrandList = _.sortBy(data.brands, 'name');

      $scope.$watch('searchTerm', function() {
        if(!$scope.searchTerm) {
          $scope.brandList = $scope.relatedBrandList;
          return;
        }
        $scope.brandList = _.filter($scope.relatedBrandList, function (each) {
          return each.id.trim().toLowerCase().indexOf($scope.searchTerm.trim().toLowerCase()) >= 0;
        });
      });
    });
  };

  $scope.fetchRelatedBrands();
}

module.exports = AdminRelatedBrandsController;
