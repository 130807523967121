var _ = require('lodash-core');

// @ngInject
function ShippingFilterDirective(fulfillerService, brandService, $state, $rootScope, $transitions, viewportService) {
  var linker = function($scope) {
    $scope.status = {};
    $scope.status.openShipping = true;
    var basicShipping = [
      {
        id: 'express-shipping',
        name: 'Express Shipping',
        description: '2 Business Days',
        selected: false
      },
      {
        id: 'priority-shipping',
        name: '3 Day Shipping',
        description: '3 Business Days',
        selected: false
      },
      {
        id: 'free-standard-shipping',
        name: 'Free Standard Shipping',
        description: '',
        selected: true
      }
    ];
    if ($rootScope.featureFlags.inStore.enabled) {
      basicShipping = [
        {
          id: 'express-shipping',
          name: 'Express Shipping',
          description: '2 Business Days',
          selected: false
        },
        {
          id: 'free-standard-shipping',
          name: 'Free Standard Shipping',
          description: 'Up to 5 business days',
          selected: false
        }
      ];
    }
    var filters = $scope.filters;
    var brandsPromise = brandService.getAll();
    var fulfillersPromise = fulfillerService.getAll();
    var fulfillersFreeShipping = [];
    var fulfillersExpressShipping = [];
    var fulfillersPriorityShipping = [];

    if ($rootScope.featureFlags.inStore.enabled) {
      $scope.onContextReady(function() {
        $scope.$watch('facets.inStore', function() {
          if ($scope.facets || $scope.facets.inStore && $scope.facets.inStore['styles.inStore'] &&
            $scope.facets.inStore['styles.inStore']['true']) {
            $scope.shippings = _.clone(basicShipping);
            $scope.shippings.push({
              id: 'in-store-shipping',
              name: 'Available In Store',
              description: '',
              selected: $scope.filters.inStore ? $scope.filters.inStore : false
            });
          }
        });
      });
    }

    fulfillersPromise.then(function(fulfillers) {
      fulfillers.forEach(function(fulfiller) {
        if (!fulfiller.disabled) {
          if (fulfiller.shippingMethods && _.find(fulfiller.shippingMethods, function(shippingMethod) {
            return shippingMethod.daysToDeliverMax <= 2;
          })) {
            fulfillersExpressShipping.push(fulfiller);
          }
          if (fulfiller.shippingMethods && _.find(fulfiller.shippingMethods, function(shippingMethod) {
            return shippingMethod.daysToDeliverMax === 3;
          })) {
            fulfillersPriorityShipping.push(fulfiller);
          }
          fulfillersFreeShipping.push(fulfiller);
        }
      });
    });

    if ($rootScope.shippings && viewportService.is('<md')) {
      $scope.shippings = $rootScope.shippings;
    } else {
      $scope.shippings = $rootScope.shippings = basicShipping;
    }

    $scope.selectShipping = function(shippingOptionId) {
      $scope.shippings.forEach(function(shipping) {
        shipping.selected = shippingOptionId === shipping.id;
      });
      var fulfillers;
      filters.inStore = false;
      switch (shippingOptionId) {
        case 'in-store-shipping':
          filters.inStore = true;
          fulfillers = [];
          break;
        case 'express-shipping':
          fulfillers = fulfillersExpressShipping;
          break;
        case 'priority-shipping':
          fulfillers = fulfillersPriorityShipping;
          break;
        default:
          fulfillers = fulfillersFreeShipping;
          break;
      }
      brandsPromise.then(function(brands) {
        var partners = _.map(fulfillers, 'id');
        var brandsSources = _.map(
          _.filter($scope.sources.brands,
            function(brand) {
              if ($state.current.name.indexOf('root.shop.just-in') >= 0) {
                return brand.visibleInWhatsNew;
              } else if ($state.current.name.indexOf('root.sale')) {
                return brand.visibleInSale;
              } else {
                return brand.visibleInSale;
              }
            }), 'id');
        var brandsToFilter = [];
        brands.forEach(function(brand) {
          if (
            brandsSources.indexOf(brand.id) > -1 &&
            (
              partners.indexOf(brand.id) > -1 || partners.indexOf(brand.store) > -1
            )
          ) {
            brandsToFilter.push(brand.id);
          }
        });
        if (!/^root\.brand-products/.test($state.$current.name)) {
          filters.brands = brandsToFilter;
        }
      });
      $rootScope.shippings = $scope.shippings;
    };
    $transitions.onStart({}, function(trans) {
      trans.promise.finally(function() {
        if ($rootScope.featureFlags.inStore.enabled) {
          $scope.selectShipping('in-store-shipping');
        } else {
          $scope.selectShipping('free-standard-shipping');
        }
      });
    });
  };
  return {
    link: linker,
    templateUrl: '/views/partials/shipping-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ShippingFilterDirective;
