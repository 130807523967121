/* global gtag */
// var gaCustom = require('./ga-custom');
var gaECommerce = require('./ga-e-commerce');

// TODO: add custom dimensions and ga-ecommerce
function GoogleAnalyticsAdapter() {}

GoogleAnalyticsAdapter.prototype.loadTracker = function(trackingId) {
  var sNew = document.createElement('script');
  sNew.preload = true;
  sNew.async = true;
  sNew.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;
  var s0 = document.head.getElementsByTagName('script')[0];
  s0.parentNode.insertBefore(sNew, s0);
  gtag('js', new Date());
  gtag('config', trackingId);
};

GoogleAnalyticsAdapter.prototype.attach = function(analyticsEmitter, config) {
  this.loadTracker(config.trackingId);
  analyticsEmitter.on('track', function(name, parameters) {
    parameters = parameters || {};

    if (gaECommerce[name]) {
      gaECommerce[name](parameters);
    } else {
      var fields = {};
      if (eventIsNonInteraction(name, parameters)) {
        fields.nonInteraction = true;
      }
      // gtag(
      //   'event',
      //   'send',
      //   parameters.category || 'All',
      //   name,
      //   parameters.label,
      //   parameters.value || parameters.revenue || 0,
      //   fields,
      // );
    }
  });

  function eventIsNonInteraction(name, parameters) {
    if (parameters.nonInteraction !== undefined) {
      return parameters.nonInteraction;
    }
    // try to guess if the event is nonInteraction
    return /Viewed|Experiment/i.test(name);
  }

  analyticsEmitter.on('identify', function(id, parameters) {
    if (typeof id === 'object') {
      parameters = id;
    } else {
      gtag('set', 'user_properties', {
        id: id,
        isTester: parameters.isTester,
        isAdmin: parameters.isAdmin,
      });
    }
  });

  analyticsEmitter.on('alias', function() {});
};

module.exports = new GoogleAnalyticsAdapter();
