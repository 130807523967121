/* jshint maxparams: 10 */
// @ngInject
function RegisterStandaloneController($scope, userService, config, formsService, analyticsService, $rootScope,
                                      platformService, cartService, translationService, globalizationService) {

  $scope.translations = {
    forgotPasswordTitle: 'Forgot your password?',
    signInTitle: 'Sign in',
    registerTitle: 'Create an account',
    registerAdditional: 'Please sign in to use our wishlist',
    registerError1: 'Error: \"',
    registerError2: '\", please retry or',
    registerError3: 'contact us',
    registerTYC1: 'Sign up for early Sale access plus tailored new arrivals, trends and promotions.',
    registerTYC2: 'Find out more.',
    registerTYC3: 'To opt out, click unsubscribe in our emails.',
    registerTYC4: 'We\'ll only use your personal information (email, phone number and shopping preferences) to keep ' +
    'you updated about our most exciting products and promotions. For more information, please check our Privacy ' +
    'Policy.',
    registerTYC5: 'Show less',
    registerTYC6: 'By registering you are accepting our',
    registerTYC7: 'Privacy Polic',
    registerTYC8: '_and',
    registerTYC9: 'Terms of Use',
    registerNewsletter: 'Enter your number to receive real-time updates on your favorite products and designers.',
    registerExistsAccount: 'already have an account?',
    registerSending: 'Please wait...',
    registerNotSending: 'Sign up',
    registerSignIn: 'Sign in',
    registerForgot: 'Forgot password?',
    registerRecaptcha: 'reCAPTCHA is required.',
    registerFailure1: 'Sorry, incorrect email or password',
    registerFailure2: 'An error occurred requesting this password reset. Please try again.',
    registerFailure3: 'Unfortunately no account exists for',
    registerFailure4: 'Would you like to',
    registerFailure5: 'try a different e-mail address?',
    registerSuccess1: 'A link to reset your password has',
    registerSuccess2: 'been sent to',
    registerUnregistered1: 'if you\'re not registered, register',
    registerUnregistered2: 'right here',
    registerAssociated: 'Please enter the email address associated with your account, and we will email\n you a link ' +
    'to reset your password.',
    placeHolderEmail: 'Email',
    placeHolderPassword: 'Password',
    placeHolderRePassword: 'Confirm Password',
    placeHolderFirstName: 'First Name',
    placeHolderLastName: 'Last Name',
    placeHolderPhoneNumber: 'Phone Number'
  };

  $scope.inputPasswordType = 'password';

  $scope.showHidePassword = function () {
    $scope.inputPasswordType = $scope.inputPasswordType === 'password' ? 'text' : 'password';
  };

  translationService.translateObject($scope.translations);

  $scope.model = {
    key: config.recaptcha.siteKey
  };

  $scope.type = $scope.type || 'register';
  $scope.featureFlags = $rootScope.featureFlags;
  var formPromise;
  $scope.isAutomatedBrowser = platformService.isAutomatedBrowser();
  $scope.initializeFields = function() {
    $scope.forms = {};
    $scope.inputs = {};
    $scope.inputs.email = $scope.email || '';
    $scope.inputs.phoneNumber = '';
    $scope.inputs.firstName = '';
    $scope.inputs.lastName = '';
    $scope.inputs.password = '';
    $scope.inputs.repassword = '';
    $scope.inputs.recaptchaResponse = '';
    $scope.inputs.transactional = true;
    formPromise = $scope.formPromise = formsService.promiseHandler();
  };
  $scope.initializeFields();

  $scope.doSignUp = function() {

    if (!formPromise.validate($scope.forms.register)) {
      return;
    }

    formPromise.handle(userService.signup({
      email: $scope.inputs.email,
      password: $scope.inputs.password,
      phoneNumber: $scope.inputs.phoneNumber,
      firstName: $scope.inputs.firstName,
      lastName: $scope.inputs.lastName,
      transactional: $scope.inputs.transactional,
      trigger: $scope.trigger
    })).then(function() {
      if ($scope.trigger) {
        if ($scope.trigger.indexOf('authentication-modal') >= 0) {
          analyticsService.track('Signed in through Authentication Modal');
        } else if ($scope.trigger.indexOf('header') >= 0) {
          analyticsService.track('Signed in through Side Bar');
        } else if ($scope.trigger.indexOf('cart-sfl') >= 0) {
          analyticsService.track('Sign up from SFL guest');
          cartService.add({
            styleId: $scope.itemForLater.styleId,
            variant: $scope.itemForLater,
            metadata: $scope.itemForLater.metadata,
            forLater: true,
            source: 'cart'
          });
        }
      }

      $scope.modal.hide();
    });
  };

  $scope.doLogin = function() {
    if (!formPromise.validate($scope.forms.login)) {
      return;
    }
    var params = {
      email: $scope.inputs.email,
      password: $scope.inputs.password,
      recaptchaResponse: $scope.inputs.recaptchaResponse,
      trigger: $scope.trigger
    };
    formPromise.handle(userService.login(params))
      .then(function() {
        if (userService.isLoggedIn()) {
          if (userService.getUser().lang) {
            globalizationService.selectCountry(userService.getUser().lang);
          } else {
            if ($rootScope.country && $rootScope.country.language) {
              userService.setLang($rootScope.country.language);
            }
          }
        }
        if ($scope.trigger) {
          if ($scope.trigger.indexOf('authentication-modal') >= 0) {
            analyticsService.track('Logged in from Authentication Modal');
          } else if ($scope.trigger.indexOf('header') >= 0) {
            analyticsService.track('Logged in from Side Bar');
          }
        }

        if ($scope.modal) {
          $scope.modal.hide();
        }
      }).catch(function() {
        window.grecaptcha.reset();
      });
  };

  $scope.doReset = function() {
    if (!formPromise.validate($scope.forms.forgot)) {
      return;
    }

    var email = $scope.inputs.email;
    $scope.emailSent = email;

    formPromise.handle(
      userService.sendPasswordResetEmail(email)
    );
  };

  $scope.getAdditionalText = function() {
    if ($scope.trigger.includes('wishlist')) {
      return $scope.translations.registerAdditional;
    }
  };

  $scope.getTitle = function() {
    if ($scope.type === 'forgot-password') {
      return $scope.translations.forgotPasswordTitle;
    } else if ($scope.type === 'login') {
      return $scope.translations.signInTitle;
    } else {
      return $scope.translations.registerTitle;
    }
  };

  $scope.sendInvite = function() {
    $scope.sendingInvite = true;
    userService.invite($scope.inputs.email).then(function() {
      $scope.sendingInvite = false;
      $scope.inviteSent = true;
    }, function() {
      $scope.sendingInvite = false;
    });
  };

  $scope.setType = function(type) {
    $scope.type = type;
    $scope.initializeFields();
  };

  userService.events.on('loggedIn', function() {
    if ($scope.modal && $scope.modal.registerStandaloneInstance) {
      $scope.modal.registerStandaloneInstance.close();
    }
  });

  $scope.closeButton = function() {
    $scope.modal.registerStandaloneInstance.dismiss();
  };
}

module.exports = RegisterStandaloneController;
