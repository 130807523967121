
// @ngInject
function ShopInstagramTileDirective() {
  function linker() {
  }
  return {
    link: linker,
    templateUrl: '/views/partials/shop-instagram-tile.html',
    restrict: 'E'
  };
}

module.exports = ShopInstagramTileDirective;
