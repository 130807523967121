var _ = require('lodash-core');

/*jshint maxparams: 10 */
// @ngInject
function AdminShowController(
  $scope,
  $state,
  $stateParams,
  userService,
  productService,
  showService,
  influencerService,
  modalShowService,
  searchService,
  orderService
) {
  $scope.showEdit = {};
  $scope.formPromise = {};
  $scope.availableTags = ['daily-looks', 'date-night-vibes', 'grwm', 'mane-styles', 'party-time', 'vacation-chic'];

  function loadShow() {
    // load show
    var showSalesPromise = showService.getSales($stateParams.id);
    showService.getById($stateParams.id).then(function(show) {
      if (!$scope.show || !_.isEqual($scope.show, show)) {
        $scope.show = show;
        influencerService.getById(show.influencerId).then(function(influencer) {
          userService.getById(influencer.userId).then(function(user) {
            $scope.user = user;
          });
        });
        $scope.products = [];
        showSalesPromise.then(function(showSales) {
          _.forEach(show.showProducts, function(product) {
            productService.getByStyleId(product.styleId).then(function(productDetailsOrigin) {
              var productDetails = _.cloneDeep(productDetailsOrigin);
              productDetails.style = _.find(productDetails.styles, function(style) {
                return style.id === product.styleId;
              });
              productDetails.isSimilar = product.isSimilar;
              productDetails.variant = _.find(productDetails.style.variants, function(variant) {
                return variant.hash === product.hash;
              });

              productDetails.stock = 0;
              _.forEach(productDetails.style.variants, function (variant) {
                if (variant && !variant.outOfStockAt && variant.stock !== 0) {
                  var stock = variant.stock > 0 ? variant.stock: 1;
                  productDetails.stock += stock;
                }
              });

              productDetails.link = '/' + productDetails.brand.id;
              productDetails.link += '/' + productDetails.style.slug + '-' + productDetails.style.id;
              var productSales = showSales.products.find(function(productSales) {
                return productSales.styleId === productDetails.style.id;
              });
              productDetails.unconfirmedSales = productSales.amountOfUnconfirmedSales;
              productDetails.confirmedSales = productSales.amountOfConfirmedSales;
              productDetails.timeToShow = product.timeToShow;
              $scope.products.push(productDetails);
            });
          });
          $scope.orders = [];
          showSales.orders.forEach(function(orderNumber) {
            var itemsCount = 0;
            orderService.get(orderNumber, $stateParams.token).then(function(order) {
              order.items.forEach(function(item) {
                itemsCount += item.quantity;
              });
              $scope.orders.push({
                number: order.number,
                totals: order.totals,
                items: itemsCount,
                completed: order.completed,
                cancelled: order.orderCancelled
              });
            });
          });
          orderService.get($stateParams.orderNumber, $stateParams.token);
        });
      }
    });
  }

  function loadFields() {
    $scope.showEdit.status = $scope.show.status;
    if ($scope.show.schedule) {
      $scope.showEdit.schedule = new Date($scope.show.schedule);
    }
    if ($scope.show.s3VideoThumbnails && $scope.show.s3VideoThumbnails.length) {
      $scope.showEdit.thumbnail = $scope.show.s3VideoThumbnails[0];
    }
    if ($scope.show.tags && $scope.show.tags.length) {
      $scope.showEdit.editTags = $scope.show.tags
        .map(function(tag) {
          return tag.replace('u.', '');
        })
        .filter(function(tag) {
          return $scope.availableTags.includes(tag);
        });
    }
  }

  $scope.searchSimilarProducts = function () {
    productService.getSimilarProducts({id: $scope.productSimilarId }, 30).then(function (results) {
      $scope.similarProducts = _.chunk(results.items, 6);
      _.forEach(results.items, function (item) {
        item.imageUrl = productService.getImageUrl(item.image, {});
        item.link = '/' + item.brand.id;
        item.link += '/' + item.slug + '-' + item.styleId;
      });
    });

    $scope.searchSimilarByTags($scope.productSimilarId, 1);
  };

  $scope.searchSimilarByTags = function (productId, page) {
    var productDetails = _.find($scope.products, function (product) {
      return product.id === productId && product.brandId !== 'chanel-beauty';
    });
    if (productDetails) {
      var searchParams = {
        tags: productDetails.tags,
        sort: 'scores-popular',
        limit: 30,
        page: page || 1
      };

      if (productDetails.variant && productDetails.variant.key) {
        searchParams.color = productDetails.variant.key.color;
      }

      if (productDetails.variant && productDetails.variant.price) {
        searchParams.price = {};
        searchParams.price.max = (productDetails.variant.price / 100) * 1.50;
        searchParams.price.min = (productDetails.variant.price / 100) * 0.9;
      }
      searchService.search(searchParams).then(function (results) {
        $scope.similarProductsPerTags = _.chunk(results.items, 6);
        _.forEach(results.items, function (item) {
          item.imageUrl = productService.getImageUrl(item.image.path, {});
          item.link = '/' + item.brand.id;
          item.link += '/' + item.slug + '-' + item.styleId;
        });
      });
    } else {
      delete $scope.similarProductsPerTags;
    }
  };

  $scope.$watch('show', function() {
    if ($scope.show) {
      loadFields();
    }
  });

  $scope.save = function() {
    if ($scope.showEdit.hidden) {
      var reSendModal = window.confirm(
        'If you hide this show, the show will also disappear from the administrator page. Do you want to proceed?'
      );
      if (!reSendModal) {
        return;
      }
    }
    $scope.formPromise.sending = true;
    if ($scope.showEdit.thumbnail) {
      if ($scope.show.s3VideoThumbnails && $scope.show.s3VideoThumbnails.length) {
        if ($scope.showEdit.thumbnail !== $scope.show.s3VideoThumbnails[0]) {
          $scope.showEdit.s3VideoThumbnails = _.concat([$scope.showEdit.thumbnail], $scope.show.s3VideoThumbnails);
        }
      } else {
        $scope.showEdit.s3VideoThumbnails = [$scope.showEdit.thumbnail];
      }
    }
    delete $scope.showEdit.thumbnail;

    if ($scope.showEdit.editTags && $scope.showEdit.editTags.length) {
      $scope.showEdit.tags = $scope.showEdit.editTags.map(function(tag) {
        return 'u.' + tag.text;
      });
      delete $scope.showEdit.editTags;
    }

    showService
      .update($scope.show.id, $scope.showEdit)
      .then(function() {
        window.location.reload();
      })
      .finally(function() {
        $scope.formPromise.sending = false;
      });
  };

  $scope.remove = function() {
    var reSendModal = window.confirm('Are you sure you want to remove this show?');
    $scope.formPromise.deleting = true;
    if (reSendModal) {
      showService
        .delete($scope.show.id)
        .then(function() {
          $state.go('admin.shows');
        })
        .finally(function() {
          $scope.formPromise.deleting = false;
        });
    }
  };

  $scope.removeProduct = function(styleId) {
    if ($scope.show.showProducts.length === 1) {
      window.alert(
        'You cannot delete this product because it is the only product in the show, DELETE the entire show instead'
      );
      return;
    }
    var reSendModal = window.confirm('Are you sure you want to remove this product?');
    $scope.formPromise.sending = true;
    var newProducts = $scope.show.showProducts.filter(function(showProduct) {
      return showProduct.styleId !== styleId;
    });
    if (reSendModal) {
      showService
        .update($scope.show.id, {
          showProducts: newProducts,
        })
        .then(function() {
          $state.reload();
        })
        .finally(function() {
          $scope.formPromise.sending = false;
        });
    }
  };

  $scope.curateProducts = function () {
    $scope.curating = true;
    showService.curate($stateParams.id).finally(function () {
      $scope.curating = false;
      window.location.reload();
    });
  };

  $scope.saveTimeToShow = function (product) {
    $scope.show.showProducts.forEach(function(showProduct) {
      if (showProduct.styleId === product.style.id && showProduct.timeToShow !== product.timeToShow) {
        showProduct.timeToShow = product.timeToShow;
        showService.update($scope.show.id, {showProducts: $scope.show.showProducts});
      }
    });
  };

  $scope.addProduct = function() {
    if (
      $scope.show.showProducts.find(function(showProduct) {
        return showProduct.styleId === $scope.productToAdd;
      })
    ) {
      window.alert('This product is on the show already');
      return;
    }
    productService
      .getByStyleId($scope.productToAdd)
      .then(function(productToAdd) {
        if (!productToAdd) {
          window.alert('This styleId does not exist, try another one');
          return;
        }
        $scope.formPromise.sending = true;
        var newProduct = {
          productId: productToAdd.id,
          styleId: $scope.productToAdd,
          isSimilar: $scope.isSimilar,
          isSimilarTo: $scope.isSimilarTo,
          hash: productToAdd.styles
            .find(function(style) {
              return style.id === $scope.productToAdd;
            })
            .variants.find(function(variant) {
              return variant.stock !== 0;
            }).hash,
        };
        $scope.show.showProducts.push(newProduct);
        showService
          .update($scope.show.id, {
            showProducts: $scope.show.showProducts,
          })
          .finally(function() {
            $scope.formPromise.sending = false;
            $state.reload();
          });
      })
      .catch(function() {
        window.alert('This styleId does not exist, try another one');
        return;
      });
  };

  $scope.addSimilar = function (styleId) {
    $scope.productToAdd = styleId;
    $scope.isSimilar = true;
    $scope.isSimilarTo = styleId;
    $scope.addProduct();
  };

  $scope.openAttributions = function () {
    var fromDate = new Date(2023, 1, 1);
    var href = $state.href('admin.earning', {
      id: $scope.show.influencerId,
      show: $scope.show.id,
      from: fromDate.toISOString().substr(0,10),
      to: new Date().toISOString().substr(0,10)
    });
    window.open(href, '_blank');
  };

  $scope.getUserClasses = function(user) {
    var classes = [];
    if (user.disabled) {
      classes.push('user-disabled');
    }
    if (user.invite) {
      classes.push('user-invite');
      if (!user.inviteRedeemedAt) {
        classes.push('user-invite-waiting');
      }
    }
    if (user.inviteRedeemedAt) {
      classes.push('user-invite-redeemed');
    }
    return classes.join(' ');
  };

  $scope.openShow = function() {
    modalShowService.openShow($scope.show.id, $scope, 'admin');
  };

  loadShow();
}

module.exports = AdminShowController;
