var _ = require('lodash-core');

// @ngInject
function AdminUsersController($scope, $state, $stateParams, userService,
  cartService, productService) {

  var usedParameters = {};
  var today = new Date().getTime();
  var day = 24 * 3600000;
  var week = day * 7;
  var month = day * 30;

  $scope.parameters = {};

  $scope.recencies = [
    {
      date: today - 1 * week,
      name: 'within last week'
    },
    {
      date: today - 2 * week,
      name: 'within last 2 weeks'
    },
    {
      date: today - 1 * month,
      name: 'within last month'
    },
    {
      date: today - 3 * month,
      name: 'within last 3 months'
    },
    {
      date: today - 6 * month,
      name: 'within last 6 months'
    },
    {
      date: today - 12 * month,
      name: 'within last 12 months'
    }
  ];

  for (var i = 0; i < $scope.recencies.length; i++) {
    var periodStart = $scope.recencies[i].date;
    var periodEnd = i > 0 ?
      $scope.recencies[i - 1].date : today + 1 * day;
    $scope.recencies[i].value = formatDate(periodStart) + '-' + formatDate(periodEnd);
  }

  $scope.frequencies = [
    {
      value: '1-1'
    },
    {
      value: '2-5'
    },
    {
      value: '5-15'
    },
    {
      value: '15-25'
    },
    {
      value: '25-'
    }
  ];

  $scope.monetaryAmounts = [
    {
      value: '100-25000',
      text: '1-250'
    },
    {
      value: '25100-100000',
      text: '251-1000'
    },
    {
      value: '100100-500000',
      text: '1001-5000'
    },
    {
      value: '500100-1500000',
      text: '5001-15000'
    },
    {
      value: '1500000-',
      text: '15000-'
    }
  ];

  $scope.$watch(function() {
    return $stateParams;
  }, function() {
    _.merge($scope.parameters, _.pick($stateParams, [
      'term',
      'recency',
      'frequency',
      'monetary'
    ]));
  });

  var search = _.debounce(function() {

    var parameters = $scope.parameters;
    if (_.isEqual(parameters, usedParameters)) {
      return;
    }
    usedParameters = _.cloneDeep(parameters);

    $state.go($state.current.name, parameters, { notify: false });

    if (!(parameters.term || parameters.frequency ||
      parameters.recency || parameters.monetary)) {
      delete $scope.users;
      return;
    }

    var searchParams = {};
    if (parameters.term) {
      searchParams.term = parameters.term;
    } else {
      if (parameters.recency) {
        searchParams.recency = parameters.recency;
      }
      if (parameters.frequency) {
        searchParams.frequency = parameters.frequency;
      }
      if (parameters.monetary) {
        searchParams.monetary = parameters.monetary;
      }
    }

    var userSearchPromise = userService.search(searchParams);

    userSearchPromise.then(function(response) {
      _.forEach(response.items, function(user) {
        user.cart = cartService.get(user.id).lazyObject();
      });
    });

    $scope.users = userSearchPromise.lazyObject();

  }, 1000);

  $scope.$watch('parameters.recency', search, true);
  $scope.$watch('parameters.frequency', search, true);
  $scope.$watch('parameters.monetary', search, true);

  $scope.getUserClasses = function(user) {
    var classes = [];
    if (user.disabled) {
      classes.push('user-disabled');
    }
    if (user.invite) {
      classes.push('user-invite');
      if (!user.inviteRedeemedAt) {
        classes.push('user-invite-waiting');
      }
    }
    if (user.inviteRedeemedAt) {
      classes.push('user-invite-redeemed');
    }
    return classes.join(' ');
  };

  $scope.cartTotalCount = function(cart) {
    if (!cart || !cart.items || !cart.items.length) {
      return 0;
    }
    var total = 0;
    cart.items.forEach(function(item) {
      total += (item.quantity || 1);
    });
    return total;
  };

  $scope.openCart = function(cart) {
    cart.items.forEach(function(item) {
      productService.getByStyleId(item.styleId).then(function(product) {
        var params = {
          brand: product.brandId || product.brand.id,
          productName: (_.find(product.styles, { id: item.styleId }) || {}).slug,
          id: item.styleId
        };
        if (item.variantKey) {
          for (var key in item.variantKey) {
            params[key] = item.variantKey[key];
          }
        }
        var url = $state.href('root.product.variant', params);
        window.open(url, '_blank');
      });
    });
  };

  $scope.keypress = function($event) {
    if ($event.which === 13) {
      search($scope.searchTerm, '');
    }
  };

  function formatDate(timestamp) {
    if (!timestamp) {
      return;
    }
    var date = new Date(+timestamp);
    return date.toISOString().slice(0, 10).replace(/-/g, '');
  }
}

module.exports = AdminUsersController;
