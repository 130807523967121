var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function CitiesService(apiService) {

  this.get = _.memoize(function(state) {
    if (!state) {
      return promises.immediate({});
    }
    return promises.extend(apiService.get('/cities/{state}', {
      pathParams: {
        state: state
      }
    }));
  });
}

module.exports = CitiesService;
