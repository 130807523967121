var promises = require('../../async/promises');

// @ngInject
function StatusService(apiService) {
  this.getApiInfo = function() {
    return promises.extend(apiService.get('/info'));
  };

  this.getError = function(status) {
    return promises.extend(apiService.get('/error/{status}', {
      pathParams: {
        status: status
      }
    }));
  };
}

module.exports = StatusService;
