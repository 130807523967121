/* jshint maxparams: 13 */
// @ngInject
function ShopbyDropdownController($scope, $rootScope, $state, $stateParams, $timeout,
  staticAssetService, viewportService, contentPageService, listContextService) {

  listContextService.setScopeContext($scope);

  // $scope.occasionsPages = contentPageService.search({ filters: ['occasions/*'], limit: 2 });
  // $scope.inspirationPages = contentPageService.search({ filters: ['inspiration/*'], limit: 2 });
  // $scope.peoplePages = contentPageService.search({ filters: ['people/*'], limit: 2 });

  // $scope.featuredInspiration = $scope.inspirationPages[0];
  // $scope.featuredPeople = $scope.peoplePages[0];
  // $scope.featuredOccasion = $scope.occasionsPages[0];
  $scope.featuredInfluencers = $scope.influencersPages[0];

  $scope.getStoryUrl = function(story) {
    if (typeof story === 'undefined') {
      return '';
    }
    return '/' + story.name;
  };

  $scope.getStoryLinkText = function(story) {
    if (typeof story === 'undefined') {
      return '';
    }
    return story.linkText || 'Read More';
  };

  $scope.getStoryImageUrlDropdown = function(story) {
    if (typeof story === 'undefined') {
      return '';
    }
    return staticAssetService.url('/content/images/' + story.image + '-mobile.jpg');
  };

}

module.exports = ShopbyDropdownController;
