// @ngInject
function ForgotPasswordDirective(formsService, userService, $parse) {

  var linker = function($scope, element, attrs) {

    $scope.inputs = {};

    formsService.focusFirstField();

    var formPromise = $scope.formPromise = formsService.promiseHandler();

    $scope.success = function() {
      if (attrs.success) {
        return $parse(attrs.success)($scope);
      }
    };

    $scope.sendPasswordResetEmail = function() {

      if (!formPromise.validate($scope.form)) {
        return;
      }

      var email = $scope.inputs.email;
      $scope.emailSent = email;

      formPromise.handle(
        userService.sendPasswordResetEmail(email)
      );
    };

    $scope.reset = function() {
      $scope.inputs.email = '';
      formPromise.clear();
      $scope.$broadcast('unvisit');
      formsService.focusFirstField();
    };

  };

  return {
    link: linker,
    templateUrl: '/views/partials/forgot-password.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ForgotPasswordDirective;