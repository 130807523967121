var _ = require('lodash-core');

// @ngInject
function ProductSortableListItemsDirective($parse) {
  function linker($scope, element, attrs) {

    $scope.getItems = function() {
      return attrs.items ? $parse(attrs.items)($scope) : $scope.products;
    };

    $scope.removeProduct = function(product) {
      var items = $scope.getItems();
      _.remove(items, { id: product.id });
    };

    $scope.moveProductNext = function(product) {
      var items = $scope.getItems();
      var pos = _.findIndex(items, { id: product.id });
      if (pos < items.length) {
        items.splice(pos, 1);
        items.splice(pos + 1, 0, product);
      }
    };

    $scope.moveProductPrev = function(product) {
      var items = $scope.getItems();
      var pos = _.findIndex(items, { id: product.id });
      if (pos > -1) {
        items.splice(pos, 1);
        items.splice(pos - 1, 0, product);
      }
    };
  }
  return {
    link: linker,
    templateUrl: '/views/partials/product-sortable-list-items.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ProductSortableListItemsDirective;

