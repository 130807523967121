var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function PreloadService(staticAssetService, $timeout) {

  this.loadTimeout = 60000;

  this.image = _.memoize(function(url) {
    var self = this;
    var src = staticAssetService.url(url);
    return promises.create(function(resolve, reject) {
      var done = false;
      function loaded() {
        if (done) {
          return;
        }
        done = true;
        $timeout(function() {
          resolve();
        }, 100);
      }
      function failed() {
        if (done) {
          return;
        }
        done = true;
        reject();
      }
      try {
        var img = new Image();
        img.onload = loaded;
        img.onerror = failed;
        img.src = src;
        setTimeout(function() {
          failed('timeout');
        }, self.loadTimeout);
      } catch (err) {
        reject(err);
      }
    });
  });

  this.images = function(urls) {
    if (urls.length < 1) {
      return promises.immediate();
    }
    if (urls.length === 1) {
      return this.image(urls[0]);
    }
    var self = this;
    var queue = urls.slice();
    var next = queue.shift();
    return this.image(next).then(function() {
      return self.images(queue);
    });
  };

  this.bundle = function(name) {
    return this.script('/build/orchard-mile-web-client-' + name +
      (window.localConfigOverride.minification() ? '.min' : '') +
      '.js');
  };

  this.scripts = function(urls) {
    if (urls.length < 1) {
      return promises.immediate();
    }
    if (urls.length === 1) {
      return this.script(urls[0]);
    }
    var self = this;
    var queue = urls.slice();
    var nextScript = queue.shift();
    return this.script(nextScript).then(function() {
      return self.scripts(queue);
    });
  };

  this.script = _.memoize(function(url) {
    var self = this;
    var src = staticAssetService.url(url);
    return promises.create(function(resolve, reject) {
      var done = false;
      function loaded() {
        if (done) {
          return;
        }
        done = true;
        $timeout(function() {
          resolve();
        }, 100);
      }
      function failed() {
        if (done) {
          return;
        }
        done = true;
        reject();
      }
      try {
        var scriptTag = document.createElement('script');
        scriptTag.onload = loaded;
        scriptTag.onerror = failed;
        scriptTag.src = src;
        document.body.appendChild(scriptTag);
        setTimeout(function() {
          failed('timeout');
        }, self.loadTimeout);
      } catch (err) {
        reject(err);
      }
    });
  });

  this.style = _.memoize(function(url) {
    var self = this;
    var src = staticAssetService.url(url);
    return promises.create(function(resolve, reject) {
      var done = false;
      function loaded() {
        if (done) {
          return;
        }
        done = true;
        $timeout(function() {
          resolve();
        }, 100);
      }
      function failed() {
        if (done) {
          return;
        }
        done = true;
        reject();
      }
      try {
        var styleTag = document.createElement('link');
        styleTag.onload = loaded;
        styleTag.onerror = failed;
        styleTag.rel = 'stylesheet';
        styleTag.href = src;
        document.head.appendChild(styleTag);
        setTimeout(function() {
          failed('timeout');
        }, self.loadTimeout);
      } catch (err) {
        reject(err);
      }
    });
  });

}

module.exports = PreloadService;
