// @ngInject
function CheckoutConfirmationController($scope, $rootScope, $timeout, userService, shopModalService) {
  delete $rootScope.paymentIntent;
  window.onbeforeunload = null;

  $timeout(function() {
    $scope.disappearReferFriends = false;
  }, 2000);
  $timeout(function() {
    $scope.disappearSignUp = false;
  }, 3000);

  $scope.createAccount = false;

  var user = userService.getUser();
  if (user.email) {
    userService.isEmailAvailable(user.email).then(function(result) {
      if (result.availability) {
        $scope.createAccount = true;
      }
    });
  }

  if ($scope.isOnWidget()) {
    $scope.closeShopModal = function() {
      shopModalService.updateCart([]);
      shopModalService.closeShopModal();
    };
  }
}

module.exports = CheckoutConfirmationController;
