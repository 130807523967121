var _ = require('lodash-core');

// @ngInject
function ViewportService($window, $rootScope) {

  // get fast and cross-browser viewport width
  var getWidth = (function() {
    var w = $window;
    if (w && w.innerWidth) {
      return function() {
        return w.innerWidth;
      };
    }
    var d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0];
    return function() {
      return w.innerWidth || e.clientWidth || g.clientWidth;
    };
  })();

  this.width = getWidth;

  // cache values when width doesn't change
  var cache = {
    values: {}
  };

  function getCurrent() {
    var currentWidth = getWidth();
    if (currentWidth === cache.width) {
      return cache.breakpoint;
    }
    var breakpoint = window.ResponsiveBootstrapToolkit.current();
    var cachedBreakpoint = cache.breakpoint;
    cache = {
      width: currentWidth,
      breakpoint: breakpoint,
      values: {}
    };
    if (breakpoint && breakpoint !== cachedBreakpoint) {
      $rootScope.$broadcast('viewportBreakpointChange');
    }
    return breakpoint;
  }

  this.is = function(breakpoint) {
    if (getCurrent() === breakpoint) {
      return true;
    }
    if (typeof cache.values[breakpoint] === 'undefined') {
      cache.values[breakpoint] = window.ResponsiveBootstrapToolkit.is(breakpoint);
    }
    return cache.values[breakpoint];
  };

  this.current = function() {
    return getCurrent();
  };

  this.chooseForCurrent = function(values) {
    var current = getCurrent();
    for (var breakpoint in values) {
      if (breakpoint === 'else') {
        continue;
      }
      if (typeof values[breakpoint] === 'undefined') {
        continue;
      }
      if (current === breakpoint) {
        return values[breakpoint];
      }
      if (typeof cache.values[breakpoint] === 'undefined') {
        cache.values[breakpoint] = window.ResponsiveBootstrapToolkit.is(breakpoint);
      }
      if (cache.values[breakpoint]) {
        return values[breakpoint];
      }
    }
    return values.else || null;
  };

  this.isInViewPort = function(element) {
    var elementTop = element.offset().top;
    var elementBottom = elementTop + element.outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return element.is(':visible') && elementBottom > viewportTop && elementTop < viewportBottom;
  };

  angular.element($window).resize(_.debounce(function() {
    getCurrent();
  }, 500));
}

module.exports = ViewportService;
