var _ = require('lodash-core');
var validations = require('shared-business-rules').validations;
var format = require('shared-business-rules').format;
var EventEmitter = require('events').EventEmitter;
var cartManipulation = require('./cart/data-manipulation');
var crypto = require('crypto');

/* globals $ */
/*jshint maxparams: 11 */
/* jshint camelcase: false */

// @ngInject
function ShoppingContextService($rootScope, $timeout, $state, pendingOrderService, fulfillerService, orderService,
                                contentPageService, userService, paymentService, cartService, translationService) {

  var events = this.events = new EventEmitter();
  this.events.setMaxListeners(100);
  $rootScope.$watch(cartWatcher, updateFulfillers);
  var fulfillersWithItems;

  this.getCart = cartService.getData;
  this.getTotals = cartService.getTotals;
  this.getFulfillers = cartService.getFulfillers;

  this.getFulfillers = function() {
    if (!fulfillersWithItems) {
      fulfillersWithItems = getFulfillersWithItems();
    }
    return fulfillersWithItems;
  };

  this.updateShipping = function(shipping) {
    // merge not to break watchers
    if ($rootScope.shipping === undefined) {
      $rootScope.shipping = {};
    }
    _.merge($rootScope.shipping, shipping);

    // force a digest
    $timeout(function() {
    }, 0);
  };

  this.getShipping = function() {
    return $rootScope.shipping;
  };

  this.updateFulfillerShippingMethod = function(fulfillerId, shippingMethodId, inStore) {
    var fulfiller = _.find(fulfillersWithItems, {id: fulfillerId});
    if (!fulfiller) {
      return;
    }
    if (inStore) {
      fulfiller.selectedShippingMethodInStore = shippingMethodId;
    } else {
      fulfiller.selectedShippingMethod = shippingMethodId;
    }

    // fulfillerForOrder collects all updated data of the fulfiller
    var fulfillerForOrder = {
      id: fulfiller.id,
      name: fulfiller.name
    };
    var method;
    if (fulfiller.selectedShippingMethod) {
      method = _.find(fulfiller.shippingMethods, {id: fulfiller.selectedShippingMethod});
      fulfillerForOrder.shippingMethod = {
        id: method.id,
        description: method.description,
        cost: method.cost,
        estimatedDeliveryFrom: method.dateFrom,
        estimatedDeliveryTo: method.dateTo
      };
    }

    if (fulfiller.selectedShippingMethodInStore) {
      method = _.find(fulfiller.shippingMethods, {id: fulfiller.selectedShippingMethodInStore});
      fulfillerForOrder.shippingMethodInStore = {
        id: method.id,
        description: method.description,
        cost: method.cost,
        estimatedDeliveryFrom: method.dateFrom,
        estimatedDeliveryTo: method.dateTo
      };
    }

    if (!$rootScope.fulfillers) {
      $rootScope.fulfillers = _.cloneDeep(_.map(fulfillersWithItems, function(fulfillerWithItem) {
        return {
          id: fulfillerWithItem.id,
          name: fulfillerWithItem.name,
          shippingMethod: _.find(fulfillerWithItem.shippingMethods, {id: fulfillerWithItem.selectedShippingMethod}),
          shippingMethodInStore: _.find(
            fulfillerWithItem.shippingMethods,
            {id: fulfillerWithItem.selectedShippingMethodInStore}
          )
        };
      }));
    }
    // Update fulfiller data on $rootScope.fulfillers
    $rootScope.fulfillers = $rootScope.fulfillers.filter(function(fulfiller) {
      return fulfiller.id !== fulfillerForOrder.id;
    });
    $rootScope.fulfillers.push(fulfillerForOrder);
    // force a digest
    $timeout(function() {
    }, 0);
  };

  this.validateShipping = function(shipping) {
    var validationResult = this.validateShippingContact(shipping.contact);
    if (validationResult) {
      return validationResult;
    }
    validationResult = this.validateShippingAddress(shipping.address);
    if (validationResult) {
      return validationResult;
    }
  };

  this.validateShippingContact = function(shippingContact) {
    var shippingContactError = validate(shippingContact, validations.contact, 'shipping contact');
    if (shippingContactError) {
      return shippingContactError;
    }
  };

  this.validateShippingAddress = function(shippingAddress) {
    var shippingAddressError = validate(shippingAddress, validations.address, 'shipping address');
    if (shippingAddressError) {
      return shippingAddressError;
    }
  };

  this.validateOrder = function(order) {
    delete $rootScope.placingOrderError;
    return orderService.validate(order).catch(function(e) {
      $rootScope.placingOrder = false;
      $rootScope.placingOrderError = (e && e.data) ? e.data.error : e;
      translationService.translatePartiallyObjectMessage($rootScope.placingOrderError, 'message',
        'Sorry, some partners doesn\'t allow us to ship to your country.' +
        ' Please try again by removing the following items');
      // scroll to #place-order-error (will only work on the checkout)
      setTimeout(function() {
        var errorMessageOffset = $('#place-order-error').offset();
        if (errorMessageOffset) {
          $('body').animate({scrollTop: errorMessageOffset.top - 200}, 'slow');
        }
      }, 100);
      return false;
    });
  };

  this.placeOrder = function(order, paymentIntentIdToDeletePendingOrder) {
    $rootScope.placingOrder = true;
    return orderService.place(order).then(function(placedOrder) {
      if ($rootScope.shippingOptions) {
        delete $rootScope.shippingOptions.loaded;
      }
      if ($rootScope.billingOptions) {
        delete $rootScope.billingOptions.loaded;
      }
      $rootScope.placedOrderNumber = placedOrder.number;
      $rootScope.placedOrderToken = placedOrder.token;
      $rootScope.placingOrder = false;
      // new options might be just added, reload them on next checkout
      if ($rootScope.billingOptions) {
        delete $rootScope.billingOptions.loaded;
      }
      if ($rootScope.shippingOptions) {
        delete $rootScope.shippingOptions.loaded;
      }

      if (paymentIntentIdToDeletePendingOrder) {
        pendingOrderService.deleteByPaymentIntentId(paymentIntentIdToDeletePendingOrder);
      }
      placePixelOrderShopRunner(order, placedOrder);
      cartService.cleanCart(true, false);
      if (!$rootScope.isShow()) {
        $state.go('root.checkout.confirmation');
      } else {
        $rootScope.cartStep = function () {
          return 'checkout';
        };
        $rootScope.confirmation = true;
        cartService.cleanCart(true, false);
      }
      cartService.setPromoCode('');
    }).catch(function(err) {
      if (order.billing.paymentMethod.paymentProvider === 'stripe') {
        var stripeProvider = paymentService.getProviderByName('stripe');
        stripeProvider.cancelStripePaymentIntent(order.billing.paymentMethod.paymentProviderData);
      }
      $rootScope.placingOrder = false;
      $rootScope.placingOrderError = (err && err.data) ? err.data.error : err;
      // scroll to #place-order-error (will only work on the checkout)
      setTimeout(function() {
        var errorMessageOffset = $('#place-order-error').offset();
        if (errorMessageOffset) {
          $('body').animate({scrollTop: errorMessageOffset.top - 200}, 'slow');
        }
      }, 100);
    });
  };

  this.displayPrePlacedOrder = function(placedOrder) {
    if ($rootScope.shippingOptions) {
      delete $rootScope.shippingOptions.loaded;
    }
    if ($rootScope.billingOptions) {
      delete $rootScope.billingOptions.loaded;
    }
    $rootScope.placedOrderNumber = placedOrder.number;
    $rootScope.placedOrderToken = placedOrder.token;
    $rootScope.placingOrder = false;
    // new options might be just added, reload them on next checkout
    if ($rootScope.billingOptions) {
      delete $rootScope.billingOptions.loaded;
    }
    if ($rootScope.shippingOptions) {
      delete $rootScope.shippingOptions.loaded;
    }
    placePixelOrderShopRunner(placedOrder);
    cartService.cleanCart(true, false);
    if (!$rootScope.isShow()) {
      $state.go('root.checkout.confirmation');
    } else {
      $rootScope.cartStep = function () {
        return 'checkout';
      };
      $rootScope.confirmation = true;
      cartService.cleanCart(true, false);
    }
    cartService.setPromoCode('');
  };

  this.getPaymentMethod = function(paymentMethodId) {
    return orderService.getPaymentMethod(userService.getUser().id, paymentMethodId);
  };

  this.getCartPurchasableItems = function() {
    return cartService.getData().items.filter(cartManipulation.isPurchasable);
  };

  function cartWatcher() {
    if (!$rootScope.cartData || !$rootScope.cartData.items) {
      return '';
    }
    return $rootScope.cartData.items.map(function(item) {
      var product = typeof item.product === 'function' && item.product();
      var forLater = item.forLater || '';
      return product && product.brand && product.brand.id + '-' + forLater;
    }).join(',');
  }

  function updateFulfillers() {
    fulfillersWithItems = getFulfillersWithItems();
    updateFulfillersShippingMethods(fulfillersWithItems);
    events.emit('cartUpdated');
  }

  function updateFulfillersShippingMethods(fulfillers) {
    fulfillers.forEach(function(fulfiller) {
      fulfillerService.getById(fulfiller.id).then(function(info) {
        if (info.subscribeNewsletter) {
          fulfiller.subscribeNewsletter = info.subscribeNewsletter;
        }
        fulfiller.shippingMethods = info.shippingMethods;

        fulfiller.selectedShippingMethod = fulfiller.shippingMethods[0].id;

        if (info.privacyPoliciesLink) {
          fulfiller.privacyPoliciesLink = info.privacyPoliciesLink;
        }
        if (info.termsAndConditionsLink) {
          fulfiller.termsAndConditionsLink = info.termsAndConditionsLink;
        }

        _.forEach(fulfiller.shippingMethods, function(method) {
          setShippingMethodMessage(method);
        });

        if (info.shippingAndReturns) {
          fulfiller.shippingAndReturns = info.shippingAndReturns;
        }
      });
    });
  }

  function getPurchasableItems() {
    if (!$rootScope.cartData || !$rootScope.cartData.items) {
      $rootScope.cartData = cartService.getData();
    }
    return $rootScope.cartData.items.filter(function(item) {
      if (cartService.isPurchasable(item)) {
        var product = typeof item.product === 'function' && item.product();
        return product && product.partner;
      }
    });
  }

  function getFulfillersWithItems() {
    var purchasableItems = getPurchasableItems();

    var fulfillers = [];
    purchasableItems.forEach(function(item) {
      var product = typeof item.product === 'function' && item.product();
      var fulfillerId = product.partner.id;
      var fulfiller = _.find(fulfillers, {id: fulfillerId});
      if (!fulfiller) {
        fulfiller = {
          id: fulfillerId,
          name: product.partner.name,
          internationalShipping: product.partner.internationalShipping,
          items: []
        };

        fulfillers.push(fulfiller);
      }
      fulfiller.items.push(item);
    });

    return fulfillers;
  }

  function setShippingMethodMessage(method) {
    method.message = '';
    var formattedCost = method.cost === 0 ? 'Free' : '$' + format.formatCurrencyCents(method.cost, {
      valueOnly: true,
      decimals: 2,
      country: $rootScope.country
    });
    method.fullDescription = method.description + ' (' + formattedCost + ')';
    switch (method.id) {
      case 'pick-up-in-store':
        method.message = 'Items will be packed separately and picked up at each store.';
        return;
      case 'pick-up-at-concierge':
        method.message = 'Packages will be consolidated and made available at the concierge service desk;' +
          'contactless pick-up available.';
        return;
      case 'same-day-delivery':
        method.message = 'Available for Manhattan and Brooklyn only.';
        return;
      case '2day':
        contentPageService.getTemplate('checkout').then(function(data) {
          method.message = data['2day'];
        });
        return;
      case 'nextday':
        contentPageService.getTemplate('checkout').then(function(data) {
          method.message = data.nextday;
        });
        return;
      default:
        method.message = '';
        var fromTo = '';
        if (method.businessDaysFrom && method.businessDaysTo) {
          if (method.businessDaysFrom === method.businessDaysTo) {
            fromTo = method.businessDaysFrom + ' business ' + (method.businessDaysFrom > 1 ? 'days' : 'day');
          } else {
            fromTo = method.businessDaysFrom + '-' + method.businessDaysTo + ' business days';
          }
        }
        method.fullDescription = method.fullDescription + ' ' + fromTo;
        if (!method.estimatesAvailable) {
          return;
        }
        if (method.hoursToTimeThreshold > 0 && method.hoursToTimeThreshold < 6) {
          method.message = 'Place the order in the next';
          if (method.hoursToTimeThreshold === 1) {
            method.message += ' hour';
          } else {
            method.message += ' ' + method.hoursToTimeThreshold + ' hours';
          }
          method.message += ' to receive your package';
        } else {
          method.message = 'You will receive your package';
        }
        method.message += ' in ' + fromTo;
        translationService.translatePartiallyObjectMessage(method, 'message', 'Place the order in the next');
        translationService.translatePartiallyObjectMessage(method, 'message', 'hours');
        translationService.translatePartiallyObjectMessage(method, 'message', 'hour');
        translationService.translatePartiallyObjectMessage(method, 'message', 'to receive your package');
        translationService.translatePartiallyObjectMessage(method, 'message', 'You will receive your package');
        translationService.translatePartiallyObjectMessage(method, 'message', 'in');
        translationService.translatePartiallyObjectMessage(method, 'message', 'business days');
        translationService.translatePartiallyObjectMessage(method, 'message', 'business day');
    }
  }

  function validate(data, validator, name, fields) {
    var result = resolveValidator(validator)(data, fields);

    if (!result.valid) {
      var message = [];
      if (name) {
        message.push('invalid ' + name + ',');
      }
      message.push('\'' + result.error + '\' error');
      if (result.field) {
        message.push('on field ' + result.field);
      }
      var error = new Error(message.join(' '));
      error.code = 'invalid-' + name.toLowerCase().replace(/\s+/g, '-');
      return error;
    }
  }

  function placePixelOrderShopRunner(order, placedOrder) {
    function srOrderSubmit() {
      window._shoprunner_com.submitConfirmationData();
    }

    if (order.srToken) {
      var items = '';
      _.forEach(order.items, function(itemFromOrder) {
        var itemFromCart = _.find(cartService.getData().items, function(item) {
          return itemFromOrder.styleId === item.styleId && itemFromOrder.hash === item.hash;
        });
        var sku = itemFromCart.style().sku || itemFromCart.variant().sku;
        var quantity = itemFromCart.quantity;
        var price = itemFromCart.price / 100;
        var shipMethod = _.find(order.fulfillers, function(ff) {
          return ff.id === itemFromCart.getBrand().id || ff.id === itemFromCart.product().partner.id;
        });
        if (shipMethod) {
          shipMethod = shipMethod.shippingMethod.description;
        }
        var elegibility = !$rootScope.featureFlags.shopRunner.excludedBrands.includes(itemFromCart.getBrand().id);
        var stock = itemFromCart.stock !== 0 ? 'IS' : 'UNK';

        items += '|' +
          sku + '~' +
          quantity + '~' +
          price + '~' +
          shipMethod + '~' +
          elegibility + '~' +
          stock;
      });
      window._shoprunner_com.orderID = order.number || placedOrder.number;
      window._shoprunner_com.tokenID = order.srToken;
      window._shoprunner_com.confirmedProducts = items;
      window._shoprunner_com.totalOrderAmount = order.totals.totalPrice / 100;
      window._shoprunner_com.billingSubTotal = order.totals.cost / 100;
      window._shoprunner_com.tenderType = 'OT';
      window._shoprunner_com.emailHash = crypto.createHash('sha256')
        .update(order.shipping.contact.email, 'utf8')
        .digest('base64');

      (function() {
        var interval, count = 0;

        function srTryToSubmit() {
          if (typeof (window._shoprunner_com) !== 'undefined' &&
            typeof (window._shoprunner_com.submitConfirmationData) === 'function') {
            clearInterval(interval);
            srOrderSubmit();
          }
          count += 1;
          if (count > 10) {
            clearInterval(interval);
          }
        }
        interval = setInterval(srTryToSubmit, 500);
      })();
    }
  }

  function resolveValidator(validator) {
    return typeof validator === 'function' ? validator : validator.isValid;
  }
}

module.exports = ShoppingContextService;
