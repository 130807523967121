
// @ngInject
function PriceFilterDirective() {
  var linker = function($scope, element, attrs) {
    $scope.userInput = {};
    $scope.$watch('filters.price', function() {
      $scope.userInput.minPrice = $scope.filters.price.min;
      $scope.userInput.maxPrice = $scope.filters.price.max;
    });

    var wrongMaxPrice;

    $scope.$watch('userInput.maxPrice', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        checkWrongValues();
      }
    });

    $scope.$watch('userInput.minPrice', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        checkWrongValues();
      }
    });

    function checkWrongValues() {
      var maxPrice = parseInt(($scope.userInput.maxPrice || '').replace(/[^0-9]g/, ''));
      var minPrice = parseInt(($scope.userInput.minPrice || '').replace(/[^0-9]g/, ''));
      if (!attrs.hasOwnProperty('applyButton')) {
        $scope.wrongMaxPrice = !!(minPrice && maxPrice && minPrice > maxPrice && minPrice > 0);
      } else {
        wrongMaxPrice = !!(minPrice && maxPrice && minPrice > maxPrice && minPrice > 0);
        if ($scope.wrongMaxPrice && !wrongMaxPrice) {
          $scope.wrongMaxPrice = false;
        }
      }
    }

    $scope.isResetVisible = function() {
      return $scope.filters.price.min || $scope.filters.price.max;
    };

    $scope.reset = function() {
      $scope.filters.price.max = '';
      $scope.filters.price.min = '';
      $scope.userInput.minPrice = $scope.filters.price.min;
      $scope.userInput.maxPrice = $scope.filters.price.max;
    };

    $scope.validateKey = function($event) {
      if ([188, 190].indexOf($event.which) > -1 || ($event.which >= 65 && $event.which <= 90)) {
        return $event.preventDefault();
      }
    };

    if (attrs.hasOwnProperty('applyButton')) {
      $scope.applyButton = true;
    } else {
      $scope.updateFilters = updateFilter;
    }

    $scope.updateFilter = updateFilter;

    function updateFilter() {
      if (wrongMaxPrice) {
        $scope.wrongMaxPrice = wrongMaxPrice;
      } else {
        $scope.filters.price.min = ($scope.userInput.minPrice || '').replace(/[^0-9]g/, '');
        $scope.filters.price.max = ($scope.userInput.maxPrice || '').replace(/[^0-9]g/, '');
      }
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/price-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = PriceFilterDirective;
