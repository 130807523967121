// @ngInject
function FieldErrorDirective() {

  return {
    restrict: 'A',
    scope: {
      field: '=fieldError'
    },
    templateUrl: '/views/partials/field-error.html',
    link: function(scope, element, attrs) {
      element.hide();

      function refresh() {
        var field = scope.field || { $label: 'no-field', $invalid: false };

        if (!field.$label) {
          field.$label = attrs.label || field.$name;
        }

        if (field.$invalid) {
          element.show();
        } else {
          element.hide();
        }
      }

      scope.$watchCollection(function() {
        return [scope.field, scope.field && scope.field.$invalid || false];
      }, refresh);
    }
  };
}

module.exports = FieldErrorDirective;
