var _ = require('lodash-core');

/* jshint maxparams: 8 */
/* jshint camelcase: false */
/* global Promise */
// @ngInject
function AnalyticsECommerceService(
  $transitions,
  analyticsService,
  cartService,
  productService,
  orderService,
  returnService
) {
  this.initialize = function() {
    var lastStyleId;

    productService.events.on('view_item_list', function(params) {
      analyticsService.track('view_item_list', params);
    });

    productService.events.on('select_item', function(params) {
      analyticsService.track('select_item', params);
    });

    productService.events.on('view_item', function(params) {
      if (!params.variant || lastStyleId === params.style.id) {
        return;
      }
      lastStyleId = params.style.id;
      analyticsService.track('view_item', params);
    });

    cartService.events.on('add_to_cart', function(params) {
      params.style.categories = productService.getProductCategories(params.style);
      analyticsService.track('add_to_cart', params);
    });

    cartService.events.on('remove_from_cart', function(params) {
      params.style.categories = productService.getProductCategories(params.style);
      analyticsService.track('remove_from_cart', params);
    });

    cartService.events.on('add_to_wishlist', function(params) {
      params.style.categories = productService.getProductCategories(params.style);
      analyticsService.track('add_to_wishlist', params);
    });

    cartService.events.on('remove_from_wishlist', function(params) {
      params.style.categories = productService.getProductCategories(params.style);
      analyticsService.track('remove_from_wishlist', params);
    });

    cartService.events.on('begin_checkout', function(params) {
      params.items = fillItemsCategories(params.items);
      analyticsService.track('begin_checkout', params);
    });

    cartService.events.on('add_shipping_info', function(params) {
      params.items = fillItemsCategories(params.items);
      analyticsService.track('add_shipping_info', params);
    });

    cartService.events.on('add_payment_info', function(params) {
      params.items = fillItemsCategories(params.items);
      analyticsService.track('add_payment_info', params);
    });

    orderService.events.on('purchase', function(params) {
      getOrderItemsDetails(params.items).then(function(items) {
        params.items = items;
        analyticsService.track('purchase', params);
      });
    });

    // TODO: improve this logic to detect shipping price per fulfiller
    orderService.events.on('refund', function(params) {
      var taxRate = params.order.totals.taxes / (params.order.totals.totalPrice - params.order.totals.taxes);
      var totalShipping = params.order.totals.shipping + params.order.totals.internationalShipping;
      var shippingRate = totalShipping / (params.order.totals.totalPrice - totalShipping);
      params.tax = parseInt(params.event.details.amount * taxRate);
      params.shipping = parseInt(params.event.details.amount * shippingRate);
      getOrderItemsDetails(
        params.event.details.refundReasons.map(function(refundReason) {
          return refundReason.product;
        })
      ).then(function(items) {
        params.items = items;
        analyticsService.track('refund', params);
      });
    });

    $transitions.onStart({}, function(trans) {
      trans.promise.finally(function() {
        if (!/^root\.product/.test(trans.to().name)) {
          lastStyleId = null;
        }
      });
    });

    orderService.events.on('orderPlaced', function(placedOrder) {
      var totals = placedOrder.totals;
      var discount = placedOrder.discount || 0;
      placedOrder.userOrderNumber = placedOrder.userOrderNumber || 1;
      placedOrder.userTotal = placedOrder.userTotal || 0;
      var productsData = getCartProductsData();
      analyticsService.track('Completed Order', {
        products: productsData,
        orderId: placedOrder.number,
        coupon: placedOrder.promoCode,
        userOrderNumber: placedOrder.userOrderNumber || 1,
        userTotal: placedOrder.userTotal / 100.0,
        userAOV: placedOrder.userTotal / placedOrder.userOrderNumber / 100.0,
        revenue: (totals.cost - discount) / 100.0,
        tax: totals.taxes / 100.0,
        shipping: totals.shipping / 100.0,
        discount: discount / 100.0,
        total: totals.totalPrice / 100.0
      });

      // user's cart is now empty
      var userData = {
        cartQuantity: 0,
        cartTotalPrice: 0,
        cartLastChange: new Date().getTime()
      };

      // try to extract some user data from the order
      // see: https://segment.com/docs/spec/identify/#traits
      userData.phone =
        placedOrder.shipping && placedOrder.shipping.contact && placedOrder.shipping.contact.phoneNumber;
      if (placedOrder && placedOrder.billing) {
        var address = placedOrder.billing.address;
        if (!address && placedOrder.billing.paymentMethod && placedOrder.shipping) {
          address = placedOrder.shipping.address;
        }
        if (address) {
          if (address.address1) {
            userData.address = {
              street: address.address1 + (address.address2 ? ', ' + address.address2 : ''),
              city: address.city,
              state: address.state,
              postalCode: address.zip
            };
            userData.city = address.city;
            userData.state = address.state;
          }
        }
      }

      userData.lastOrderUrl = document.location.origin + '/admin/orders/' + placedOrder.number;
      if (placedOrder.userOrderNumber) {
        userData.numberOfOrders = placedOrder.userOrderNumber;
        userData.orderTotalPrice = placedOrder.userTotal / 100.0;
        userData.orderAOV = placedOrder.userTotal / placedOrder.userOrderNumber / 100.0;
      }
      userData.lastCategory = '';
      userData.lastBrands = '';
      productsData.forEach(function(item) {
        userData['purchased-' + item.brand] = true;
        userData.lastBrands += ' ' + item.brand;
        userData.lastCategory += ' ' + item.category;
      });

      analyticsService.identify(userData);

      if (placedOrder.rewardsUsed) {
        placedOrder.rewardsUsed.forEach(function(reward) {
          var rewardAgeHours;
          if (reward.createdAt) {
            rewardAgeHours = Math.round((new Date().getTime() - new Date(reward.createdAt).getTime()) / (1000 * 3600));
          }
          analyticsService.track('Reward Used', {
            rewardType: reward.type,
            credit: reward.credit,
            orderTotal: totals.totalPrice / 100.0,
            ageHours: rewardAgeHours
          });
        });
      }
    });

    returnService.events.on('cost', function(orderReturn, returnEvent) {
      analyticsService.track('Order Return Cost', {
        orderId: orderReturn.orderNumber,
        cost: returnEvent.cost / 100.0
      });
    });
  };

  function fillItemsCategories(items) {
    return items.map(function(item) {
      item.style.categories = productService.getProductCategories(item.style);
      return item;
    });
  }

  function getOrderItemsDetails(items) {
    items = items.filter(function(item) {
      return !!item;
    });
    if (!items || items.length === 0) {
      return Promise.resolve([]);
    }
    var productPromises = items.map(function(item) {
      return productService.getByStyleId(item.styleId);
    });
    return Promise.all(productPromises).then(function(products) {
      return items.map(function(item) {
        var product = products.find(function(p) {
          return p.styles.some(function(s) {
            return s.id === item.styleId;
          });
        });
        var style = product.styles.find(function(s) {
          return s.id === item.styleId;
        });
        style.categories = productService.getProductCategories(style);
        var variant = style.variants.find(function(v) {
          return v.hash === item.hash;
        });
        return {
          product: product,
          style: style,
          variant: variant,
          quantity: item.quantity
        };
      });
    });
  }

  function getCartProductsData() {
    var items = cartService.getData().items.filter(function(item) {
      return !cartService.isForLater(item);
    });
    return items.map(function(item, index) {
      var itemData = getProductData(item);
      itemData.quantity = item.quantity;
      itemData.position = index + 1;
      return itemData;
    });
  }

  function getProductData(item, product, variant) {
    product = product || (item && item.product && item.product());
    variant = variant || (item && item.variant && item.variant());
    if (!(product && product.id) || !variant) {
      // product is not loaded yet, we only have cart data
      return {
        id: item && item.styleId
      };
    }
    var style;
    if (variant && variant.getParentStyle) {
      style = variant.getParentStyle();
    } else {
      style = _.filter(product.styles, { id: item.styleId })[0];
    }
    var productListPrice = (variant.listPrice || style.listPrice || product.listPrice) / 100.0;
    var productPrice = (variant.price || style.price || product.price) / 100.0;
    var saleItemPurchased = false;
    if (productListPrice && productListPrice !== productPrice) {
      saleItemPurchased = true;
    }
    var options = {};
    options.availableSizes = style.images[0].availableSizes;
    var fullId = style.id || product.id;
    if (variant.hash) {
      fullId += '-' + variant.hash;
    }
    return {
      id: style.id || product.id,
      fullId: fullId, //The id that match the google product feed
      sku: variant.sku || style.sku || product.sku,
      name: variant.name || style.name || product.name,
      price: productPrice,
      img: productService.getImageUrl(style.images[0].path, options),
      sale_item_purchased: saleItemPurchased,
      brand: product.brandId || product.brand.id,
      category: productService.getProductCategories(product).join('/'),
      variant: concatPropertyValues(style.key)
    };
  }

  function concatPropertyValues(obj) {
    if (!obj) {
      return;
    }
    return Object.keys(obj)
      .map(function(name) {
        return obj[name];
      })
      .join(',');
  }
}

module.exports = AnalyticsECommerceService;
