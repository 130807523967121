// @ngInject
function HomeV2Controller() {
  var videoElement = document.getElementById('background-video-test');

  if (videoElement) {
    videoElement.addEventListener('dblclick', function(event) {
      event.preventDefault();
    });

    videoElement.addEventListener('fullscreenchange', function() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    });
  }

  document.addEventListener('keydown', function(e) {
    if (e.key === 'F11') {
      e.preventDefault();
    }
  });
}

module.exports = HomeV2Controller;
