var _ = require('lodash-core');
var promises = require('../../async/promises');

/* jshint maxparams: 15 */

// @ngInject
function SearchDirective($state, $stateParams, $rootScope, $transitions, config, searchService, brandService,
                         viewportService, analyticsService, translationService) {
  /* jshint unused:false */
  var linker = function($scope, element) {
    var input = element.find('input[type=text]');
    var placeholder;
    translationService.translate('Search items, brands or designers').then(function(text) {
      placeholder = text;
      input.attr('placeholder', placeholder);
    });
    var isSearchSection = $state.current.name === 'root.search';

    function getMatchScore(name, term) {
      var score = -1;
      var cleanName = getCleanWord(name);
      var cleanTerm = getCleanWord(term);
      if (cleanName.indexOf(cleanTerm) > -1) {
        score++;
      }

      var nameArr = getWordArray(name);
      var termArr = getWordArray(term);
      for (var i = 0; i < nameArr.length; ++i) {
        for (var j = 0; j < termArr.length; ++j) {
          if (nameArr[i].indexOf(termArr[j]) === 0) {
            score = score + (termArr[j].length * (nameArr.length - i + 1));
          }
        }
      }
      return score;
    }

    function getWordArray(str) {
      return _.compact((str || '').toLowerCase()
        .replace('&', ' and ')
        .replace(/[^a-zA-Z0-9]/g, ' ')
        .replace(/  /g, ' ')
        .split(' '));
    }

    function getCleanWord(str) {
      return (str || '').toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    }

    function getBrandsToFilter() {
      var rule = _.find($rootScope.featureFlags.internationalBlockedBrands.rules, function (rule) {
        return rule.countries.includes($rootScope.countrySelected());
      });
      if (rule && $rootScope.featureFlags.internationalBlockedBrands.enabled) {
        return rule.brands;
      } else {
        return [];
      }
    }

    function filterBrandsByCountry(brands) {
      if ($rootScope.featureFlags.internationalBlockedBrands.enabled && $rootScope.countrySelected() !== 'us') {
        if (brands && brands.length > 0) {
          var blockedBrands = getBrandsToFilter();
          var filteredBrands = _.filter(brands, function (brand) {
            return !blockedBrands.includes(brand.id);
          });
          if (filteredBrands && filteredBrands.length) {
            return filteredBrands;
          } else {
            return ['non-exist-brand'];
          }
        }
      }
      return brands;
    }

    var doSearchPreview = function(search) {
      if ($scope.current.length >= 3 && input.val().length >= 3 && config.featureFlags.searchPreview.enabled) {
        if (search) {
          $rootScope.searchTerm = $scope.current;
          $rootScope.brandsFound = _($scope.brands())
            .map(function(brand) {
              if (brand.visible) {
                brand = {
                  id: brand.id,
                  name: brand.name,
                  found: getMatchScore(brand.name, $scope.current)
                };
                if (brand.found > -1) {
                  return brand;
                }
              }
              return null;
            })
            .compact()
            .orderBy('found', 'desc')
            .value();

          if ($rootScope.brandsFound.length > 5) {
            $rootScope.brandsFound = $rootScope.brandsFound.slice(0, 5);
          }

          var sampleSize = viewportService.is('<md') ? 20 : 6;
          promises.extend(
            searchService.search({
              searchTerm: $rootScope.searchTerm,
              sort: 'scores-popular',
              limit: sampleSize,
              page: 1
            }).then(function(result) {
              $rootScope.searchPreviewItems = _.uniqBy(result.items, 'id');
              $rootScope.showSearchPreview();
            }));
          if (!$rootScope.newArrivalsProducts) {
            promises.extend(
              searchService.search({
                searchTerm: '', limit: sampleSize, sort: 'scores-popular',
                page: 1
              }).then(function(result) {
                $rootScope.newArrivalsProducts = _.uniqBy(result.items, 'id');
              }));
          }
        } else {
          $rootScope.showSearchPreview();
        }
      } else if ($scope.current.length < 4) {
        $rootScope.hideSearchPreview();
      }
    };
    var getBrandsPromise = brandService.getAll;
    $scope.brands = _.memoize(function() {
      return filterBrandsByCountry(getBrandsPromise().lazyArray());
    });

    $transitions.onStart({}, function(trans) {
      if (trans.to().name !== 'root.search') {
        input.val('');
        $rootScope.hideSearchPreview();
        $rootScope.searchInput = false;
      }
    });

    var doSearch = function() {
      if ($scope.current) {
        $scope.doAlgoliaIndexSearch();
      } else {
        $scope.doCustomSearch();
      }
    };

    $scope.doAlgoliaIndexSearch = function() {
      var processResult = function(content, err) {
        if (!err && content && content.hits && content.hits.length > 0 && content.hits[0].url) {
          //Analytics for Custom Search
          analyticsService.track($scope.current.trim(), {category: 'Search Custom Redirect', nonInteraction: 1});
          window.emptyInternalLink(content.hits[0].url);
        } else {
          $scope.doCustomSearch();
        }
      };
      searchService.searchAlgoliaRedirect($scope.current.trim(), processResult);
    };

    $scope.doCustomSearch = function() {
      if (
        $scope.current &&
        (config.featureFlags.searchByCategories.enabled || config.featureFlags.searchByBrand.enabled)
      ) {
        var category = false;
        var brand = false;
        if (config.featureFlags.searchByCategories.enabled) {
          category = searchCategoryByTerm($scope.current);
        }
        if (config.featureFlags.searchByBrand.enabled) {
          brand = searchBrandByTerm($scope.current);
        }

        var params = {};
        var terms = $scope.current.toLowerCase().split(' ');
        if (terms.indexOf('sale') >= 0) {
          params.sale = true;
        }
        var stateName = 'root.search';
        if (brand) {
          stateName = 'root.brand-products';
          params.brand = brand.id;

          //Analytics for Brand Redirect
          analyticsService.track($scope.current.trim(), {category: 'Search Brand Redirect', nonInteraction: 1});
        } else if (category) {
          stateName = 'root.shop.category' + category.level;
          params.category1 = category.key1 || category.key;
          params.category2 = category.level >= 2 ? category.key2 || category.key : null;
          params.category3 = category.level >= 3 ? category.key : null;
          //Analytics for category redirect
          analyticsService.track($scope.current.trim(), {category: 'Search Category Redirect', nonInteraction: 1});
        } else {
          params.term = $scope.current;
        }
        // Cleaning $stateParams
        Object.keys($stateParams).forEach(function(e) {
          $stateParams[e] = undefined;
        });
        return $state.go(stateName, params);
      }
      if (!isSearchSection) {
        return $state.go('root.search', {term: $scope.current});
      }
      $stateParams.term = $scope.search.term = $scope.current.trim();
      delete $stateParams.page;
      $scope.search.ready = !!$scope.current.length;
      $state.go($state.$current.name, $stateParams, {location: 'replace'});
    };

    $scope.keypress = function($event) {
      /*jshint sub:true*/
      if ($event.which === 13) {
        doSearch();
      }
    };

    $scope.current = $stateParams.term || '';
    $scope.search = {
      term: $scope.current.trim(),
      ready: !!$scope.current.length
    };

    $scope.showPreviewOnClick = function() {
      doSearchPreview(false);
    };


    $scope.change = _.debounce(function() {
      /*jshint sub:true*/
      doSearchPreview(true);
      //doSearch();
    }, 500, {leading: false, trailing: true, maxWait: 5000});

    $scope.$on('searchToTop', function() {
      $scope.scrollTo(0, 0);
    });

    // refreshPlaceholder();

    // private functions

    function searchBrandByTerm(term) {
      term = term.trim().toLowerCase();
      var terms = [term, term.replace('sale', '').trim()];
      return _.find($scope.brands(), function(brand) {
        return brand.visible && brand.name && terms.indexOf(brand.name.toLowerCase()) >= 0;
      });
    }

    function searchCategoryByTerm(term) {
      term = term.trim();
      var terms = term.split(' ');
      var categories = require('shared-business-rules').categories.getArray();
      for (var i = 0; i < categories.length; i++) {
        var cat1 = categories[i];
        if (terms.indexOf(cat1.name) >= 0) {
          cat1.level = 1;
          return cat1;
        } else {
          if (cat1.children) {
            for (var j = 0; j < cat1.children.length; j++) {
              var cat2 = cat1.children[j];
              cat2.level = 2;
              cat2.key1 = cat1.key;
              if (terms.indexOf(cat2.name) >= 0) {
                return cat2;
              } else {
                /* jshint ignore:start */
                for (var k = 0; k < cat2.children.length; k++) {
                  var cat3 = cat2.children[k];
                  cat3.key2 = cat2.key;
                  cat3.key1 = cat1.key;
                  if (terms.indexOf(cat3.name) >= 0) {
                    cat3.level = 3;
                    return cat3;
                  }
                }
                /* jshint ignore:end */
              }
            }
          }
        }
      }
    }

    // function refreshPlaceholder() {
    //   // TODO this should be moved to a viewport service or helper
    //   var window = angular.element($window);
    //   var winWidth = window.innerWidth() || window.outerWidth();
    //   if (winWidth <= 767) {
    //     placeholder = input.attr('placeholder-xs');
    //     input.attr('placeholder', placeholder);
    //   }
    // }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/search.html',
    restrict: 'E'
  };

}

module.exports = SearchDirective;
