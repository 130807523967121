// @ngInject
function BrandsCarouselDirective() {
  var linker = function () {
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/brands-carousel.html',
    restrict: 'E',
    scope: false,
  };
}

module.exports = BrandsCarouselDirective;
