var _ = require('lodash-core');

// @ngInject
function AdminBulkTagProductsController($scope, productService, fulfillerService) {

  $scope.fileIsUploading = false;
  $scope.byUrl = true;
  $scope.by = 'sourceUrl';
  $scope.action = 'addTags';
  $scope.fulfillerIds = [];
  var userTagsPromise = productService.getUserTags();
  var fulfillerPromise = fulfillerService.getAll();

  $scope.loadTags = function(query) {
    query = query || '';
    return userTagsPromise.then(function(data) {
      var tags = _.map(data.tags, function(tag) {
        return { text: tag.replace(/^u\./, '') };
      });
      return _.filter(tags, function(tag) {
        return tag.text.substring(0, query.length).toLowerCase() === query.toLowerCase();
      });
    });
  };

  $scope.loadFulfillers = function(query) {
    query = query || '';
    return fulfillerPromise.then(function(data) {
      var fulfillerIds = _.map(data, 'id');
      return _.filter(fulfillerIds, function(fulfillerId) {
        return fulfillerId.substring(0, query.length).toLowerCase() === query.toLowerCase();
      });
    });
  };

  $scope.selectByUrl = function() {
    $scope.by = 'sourceUrl';
    $scope.byUrl = true;
    $scope.byStyleId = false;
    $scope.bySku = false;
  };

  $scope.selectByStyleId = function() {
    $scope.by = 'styleId';
    $scope.byUrl = false;
    $scope.byStyleId = true;
    $scope.bySku = false;
  };

  $scope.selectBySku = function() {
    $scope.by = 'sku';
    $scope.byUrl = false;
    $scope.byStyleId = false;
    $scope.bySku = true;
  };

  $scope.uploadFile = function() {
    delete $scope.error;
    delete $scope.result;
    if (!$scope.file) {
      $scope.error = 'Error! missing file';
      return;
    }
    if (['addTags', 'removeTags'].indexOf($scope.action) > -1 && !$scope.userTags) {
      $scope.error = 'Error! missing tags';
      return;
    }
    $scope.fileIsUploading = true;

    var tags = _.map($scope.userTags, function(tag) {
      return 'u.' + tag.text.toLowerCase();
    });

    var fulfillerIds = _.map($scope.fulfillerIds, function(fulfillerId) {
      return fulfillerId.text.toLowerCase();
    });

    productService.uploadBulkTagProductsCsvFile($scope.file, tags, $scope.action,
      $scope.by, fulfillerIds).then(function(res) {
        $scope.fileIsUploading = false;
        $scope.result = res;
      });
  };

}

module.exports = AdminBulkTagProductsController;
