var promises = require('../../async/promises');

// @ngInject
function CurationService(apiService) {
  this.getStylesCurations = function(styleIds) {
    return promises.extend(apiService.get('/products/curations', {
      urlParams: {
        'style-ids': styleIds.join(',')
      }
    }));
  };

  this.setStyleCurations = function(styleId, curations) {
    return promises.extend(apiService.post('/products/by-style/{styleId}/curations', {
      pathParams: {
        styleId: styleId
      },
      body: curations
    }));
  };
}

module.exports = CurationService;
