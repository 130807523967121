var promises = require('../../async/promises');

// @ngInject
function StaticAssetService($window, $rootScope, apiService) {

  var staticAssetsBaseUrl = $window.staticAssetsBaseUrl;
  var contentBaseUrl = $window.contentBaseUrl;

  var self = $rootScope.asset = this;
  var urlGetterCache = {};

  this.url = function(url) {
    if (!url) {
      return url;
    }
    if (/^\/[^\/].*\.[\w\d-]+/i.test(url)) {
      if (/^\/content\/[^\/].*\.[\w\d-]+/i.test(url)) {
        if (contentBaseUrl) {
          url = contentBaseUrl + url;
        }
      } else if (staticAssetsBaseUrl) {
        url = staticAssetsBaseUrl + url;
      }
    }
    return url;
  };

  function PrefixedUrlGetter(prefix, aliases) {
    if (prefix.substr(-1) !== '/') {
      prefix += '/';
    }
    this.prefix = prefix;
    this.aliases = aliases;
  }

  PrefixedUrlGetter.prototype.url = function(url) {
    if (!url) {
      return url;
    }
    if (this.aliases && this.aliases[url]) {
      url = this.aliases[url];
    }
    if (/^\/[^\/]/.test(url)) {
      url = url.substr(1);
    }
    return self.url(url ? (this.prefix + url) : url);
  };

  function getPrefixUrlGetter(prefix, aliases) {
    var getter = urlGetterCache[prefix];
    if (!getter) {
      urlGetterCache[prefix] = getter = new PrefixedUrlGetter(prefix, aliases);
    }
    return getter;
  }

  var brandAssetAliases = {
    logo: 'logo.svg',
    heroImage: 'hero.jpg',
    heroTabletImage: 'hero-tablet.jpg',
    heroMobileImage: 'hero-mobile.jpg',
    browseBrandsImage: 'browse-brands.jpg',
    assetsPartial: 'assets.html',
    squareBrand: 'square-brand.png',
    'tile-two-across': 'tile-two-across.jpg',
    'tile-two-across-left-offset': 'tile-two-across-left-offset.jpg',
    'tile-two-across-right-offset': 'tile-two-across-right-offset.jpg',
    'tile-four-across': 'tile-four-across.jpg',
    'tile-mobile-one-across-tall': 'tile-one-across-tall.jpg',
    'tile-mobile-one-across-short': 'tile-one-across-short.jpg',
    'featured': 'featured.jpg',
    leftPanel: 'left-panel.html'
  };

  this.brand = function(brand) {
    var prefix = '/content/brands';
    if (brand) {
      var brandId = typeof brand === 'object' ? brand.id : brand;
      prefix += '/' + brandId;
    }
    return getPrefixUrlGetter(prefix, brandAssetAliases);
  };

  this.gift = function(section) {
    var url = '/content/images/gifts/';
    url += section ? section + '.jpg' : 'hero.jpg';
    return this.url(url);
  };

  this.getImgUploaderCredentials = function() {
    return promises.extend(apiService.get('/secrets/asset-upload/credentials'));
  };

}

module.exports = StaticAssetService;
