var _ = require('lodash-core');
var isBot = require('isbot');

/* globals $ */

// @ngInject
function PlatformService(config, $cookies) {

  var platformIsSupported;
  var mobileAgentRegex;
  var userAgents = config.platformSupport && config.platformSupport.userAgents;

  /* jshint ignore: start */
  // jscs:disable
  mobileAgentRegex = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/;
  // jscs:enable
  /* jshint ignore: end */

  this.isBot = function() {
    var userAgent = navigator.userAgent || navigator.vendor;
    return userAgent && isBot(userAgent);
  };

  this.isIOS = function() {
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.getElementsByTagName('html')[0].setAttribute('class', 'iOS-device');
    }
    return iOS;
  };

  this.isPrerender = function() {
    var userAgent = navigator.userAgent || navigator.vendor;
    return userAgent && userAgent.toLowerCase().indexOf('prerender') >= 0;
  };

  this.isAutomatedBrowser = function() {
    var cook = $cookies.get('automatedbrowser');
    var flag = /local-config-override=automatedbrowser/i.test(window.location.href);

    if (flag || cook) {
      if (!cook) {
        $cookies.put('automatedbrowser', true);
      }
      return true;
    }

    $cookies.remove('automatedbrowser');
    return false;
  };

  this.isPhantomJS = function() {
    var userAgent = navigator.userAgent || navigator.vendor;
    return userAgent && navigator.userAgent.toLowerCase().indexOf('phantomjs') >= 0;
  };

  this.isMobile = function() {
    var userAgent = navigator.userAgent || navigator.vendor;
    return mobileAgentRegex.test(userAgent);
  };

  this.isPhone = function() {
    return this.isMobile() && window.innerWidth < 768;
  };

  this.getUserAgent = function() {
    return navigator.userAgent || navigator.vendor;
  };

  this.getLanguage = function() {
    return navigator.languages && navigator.languages[0] || // Chrome / Firefox
      navigator.language ||   // All browsers
      navigator.userLanguage;
  };

  this.isAllowedBrowser = function() {
    if (!userAgents) {
      return true;
    }
    var userAgent = navigator.userAgent || navigator.vendor;
    if (userAgents.allow && !userAgents.allow.test(userAgent)) {
      return false;
    }
    if (userAgents.disallow && userAgents.disallow.test(userAgent)) {
      return false;
    }
    return this.hasRequiredFeatures();
  };

  this.hasRequiredFeatures = _.memoize(function() {
    return (function svgFiltersSupport() {
      // see http://caniuse.com/#feat=svg-filters
      var result = false;
      try {
        result = 'SVGFEColorMatrixElement' in window &&
          SVGFEColorMatrixElement.SVG_FECOLORMATRIX_TYPE_SATURATE === 2;
      } catch (e) {
      }
      return result;
    })();
  });

  this.isSupported = function() {
    if (typeof platformIsSupported !== 'undefined') {
      return platformIsSupported;
    }
    if (config.platformSupport.bots && this.isBot()) {
      return (platformIsSupported = true);
    }
    if (config.platformSupport.mobile === false && this.isMobile()) {
      return (platformIsSupported = false);
    }
    if (!this.isAllowedBrowser()) {
      return (platformIsSupported = false);
    }
    return true;
  };

  this.isSafari = function() {
    if (!process.browser) {
      return false;
    }
    return navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
  };

  this.isFirefox = function() {
    if (!process.browser) {
      return false;
    }
    return navigator.userAgent.indexOf('Firefox') !== -1 &&
      navigator.userAgent.indexOf('Seamonkey') === -1;
  };

  this.isChromeIos = function() {
    if (!process.browser) {
      return false;
    }
    return navigator.userAgent.indexOf('CriOS') !== -1;
  };

  this.fixSafariScroll = function(selector) {
    if (!process.browser || !this.isSafari()) {
      return;
    }
    // hack: stupid hack to fix safari scroll freezing
    setTimeout(function() {
      var elements = document.querySelectorAll(selector);
      _(elements).each(function(element) {
        element.style.height = '100%';
      });
      setTimeout(function() {
        _(elements).each(function(element) {
          element.style.height = '';
        });
      }, 200);
    }, 200);
  };

  this.fixiOSModalJumps = function(modalInstance) {
    // this happens when the modal has inputs, so the modal fixed position fights the input position
    // when the mobile keyboard is displayed
    // fix derived from https://github.com/twbs/bootstrap/issues/17324#issuecomment-135050152
    if (!modalInstance || !modalInstance.opened ||
      !navigator || !navigator.userAgent || !navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return;
    }
    modalInstance.opened.then(function() {
      setTimeout(function() {
        var $this = $('.modal'); // find the modal
        // Position modal absolute and bump it down to the scrollPosition
        var heightModal = Math.max($('body').height(), $(window).height(), $(document).height()) + 1;
        $this.css({
          position: 'absolute',
          paddingTop: $(window).scrollTop() + 'px',
          height: heightModal + 'px'
        });
        // Position backdrop absolute and make it span the entire page
        // Also dirty, but we need to tap into the backdrop after Boostrap
        // positions it but before transitions finish.
        setTimeout(function() {
          $('.modal-backdrop').css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: heightModal + 'px'
          });
        }, 350);
      }, 1);
    });
  };

  this.isSessionStorageAvailable = _.memoize(function() {
    try {
      window.sessionStorage.setItem('_____test', 'data');
      return true;
    } catch (e) {
      return false;
    }
  });

  this.getFulstorySessionId = function() {
    if (window.FS !== undefined && typeof window.FS.getCurrentSession === 'function') {
      try {
        return window.FS.getCurrentSession();
      } catch (err) {
      }
    }
  };
  this.isWebPCapable = undefined;
  this.testWebP = function(callback) {
    if (typeof this.isWEBPCapable !== 'undefined') {
      return callback(this.isWEBPCapable);
    }
    var elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      var isWebPFast =
        elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      if (isWebPFast) {
        this.isWEBPCapable = true;
        return callback(this.isWEBPCapable);
      }
    }
    if (typeof this.isWebPCapable === 'undefined') {
      // very old browser like IE 8, canvas not supported
      var webP = new Image();
      webP.onload = webP.onerror = function() {
        this.isWEBPCapable = (webP.height === 2);
        callback(this.isWEBPCapable);
      };
      webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAAC' +
        'yAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    }
  };
}

module.exports = PlatformService;
