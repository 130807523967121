var _ = require('lodash-core');

// @ngInject
function CheckoutBaseGiftCardPromoCodeController(
  $scope,
  $state,
  cartService,
  giftCardService,
  promoService,
  userService,
  translationService
) {

  var signInIfNeededToCheck;

  $scope.translations = {
    promoNotFound: 'promo code not found',
    promoNotApplicable: 'This promo code does not apply to your order.',
    promoOnlyOnApp: 'Promo code valid on app purchases only.' +
      ' <a href="https://apps.apple.com/us/app/the-mile/id1628821302" target="_blanks">Download now</a>',
    promoRequires: 'this promo requires',
    promoTooMany: 'too many requests, try again in a few seconds'
  };

  $scope.$watch('calculatingTotals', function (val) {
    if (!$scope.promo.error || $scope.promo.error.status === 400) {
      if (val === false && $scope.promo.code && !$scope.promo.data && $scope.cartData.promoCode === $scope.promo.code) {
        $scope.checkPromoCode();
      }
      if (val === true && $scope.promo.code && !$scope.promo.data) {
        $scope.promo.loading = true;
        delete $scope.promo.error;
      }
    }
  });

  $scope.checkPromoCode = function () {
    delete $scope.promo.error;
    $scope.promo.loading = true;
    promoService
      .getByCode($scope.promo.code)
      .then(function (promo) {
        if ($scope.cartData.rewards && $scope.cartData.rewards.items) {
          $scope.cartData.rewards.items.forEach(function (reward) {
            if (promo.id === reward.promoId) {
              delete $scope.promo.error;
              $scope.promo.data = promo;
            }
          });
        }
        delete $scope.promo.loading;
        if (!$scope.promo.data) {
          if (promo && promo.onOrders && promo.onOrders.fromApp) {
            $scope.promo.error = {
              status: 405
            };
          } else {
            $scope.promo.error = {
              status: 400
            };
          }
        }
      })
      .catch(function (err) {
        // promo cannot be applied
        delete $scope.promo.loading;
        $scope.promo.error = err;
        if (err.status === 403 && signInIfNeededToCheck) {
          signInIfNeededToCheck = false;
          $scope.signInForPromo();
        }
      });
  };

  translationService.translateObject($scope.translations);

  $scope.applyPromoCode = function (signInIfNeeded) {
    delete $scope.promo.data;
    delete $scope.promo.error;
    signInIfNeededToCheck = signInIfNeeded;
    cartService.setPromoCode($scope.promo.code);
  };

  $scope.welcomeRewardRemoved = function () {
    return $scope.cartTotals.giftCardDebit && $scope.cartData.rewards && $scope.cartData.rewards.totalCredit > 0;
  };

  $scope.toggleGiftCardInput = function () {
    $scope.giftCardInputVisible = !$scope.giftCardInputVisible;
    if (!$scope.giftCardInputVisible) {
      return;
    }
    setTimeout(function () {
      document.querySelector('.gift-card-code').focus();
    }, 20);
  };

  $scope.removeGiftCard = function () {
    $scope.giftCardInputVisible = false;
    $scope.giftCard.code = '';
    if (!$scope.isShow() && $scope.giftCard.appliedCode) {
      $state.go('root.checkout.shipping');
    }
  };

  $scope.signInForPromo = function() {
    var user = userService.getUser();
    var action = user.id ? 'login' : 'register';
    $scope.modal.show(action, {
      email: $scope.shipping.contact.email,
      reason: 'Please ' + action + ' below to use this Promo code.',
      trigger: 'checkout-sign-in-for-promo'
    });
  };

  $scope.getPromoErrorMessage = function() {
    if (!$scope.promo.error) {
      return;
    }
    switch ($scope.promo.error.status) {
      case 400:
        return $scope.translations.promoNotApplicable;
      case 404:
        return $scope.translations.promoNotFound;
      case 403:
        return $scope.translations.promoRequires;
      case 405:
        return $scope.translations.promoOnlyOnApp;
      case 429:
        return $scope.translations.promoTooMany;
    }
    if ($scope.promo.error.data && $scope.promo.error.data.error) {
      return $scope.promo.error.data.error.message ||
        $scope.promo.error.data.text ||
        $scope.promo.error.data.error.text;
    }
    return $scope.promo.error.message || $scope.promo.error.statusText;
  };

  // $scope.togglePromoCodeInput = function() {
  //   $scope.promoCodeInputVisible = !$scope.promoCodeInputVisible;
  //   if (!$scope.promoCodeInputVisible) {
  //     return;
  //   }
  //   setTimeout(function() {
  //     document.querySelector('.promo-code').focus();
  //   }, 20);
  // };

  $scope.removePromoCode = function () {
    // $scope.promoCodeInputVisible = false;
    if ($scope.promo.data) {
      delete $scope.promo.code;
      delete $scope.promo.data;
      cartService.setPromoCode('');
    } else {
      delete $scope.promo.code;
    }
  };

  function constructor() {
    if (!$scope.promo) {
      $scope.promo = {};
    }
    if (!$scope.giftCard) {
      $scope.giftCard = {};
    }

    $scope.promo.code = $scope.cartData.promoCode;

    $scope.$watch('promo.code', function () {
      if ($scope.promo.code) {
        $scope.promoCodeInputVisible = true;
      }
      if (!$scope.promo.code && $scope.cartData.promoCode) {
        $scope.applyPromoCode();
      }
    });

    $scope.$watch('giftCard.code', function () {
      if ($scope.giftCard.code) {
        $scope.giftCardInputVisible = true;
      }
      if ($scope.giftCard.code === $scope.giftCard.appliedCode) {
        return;
      }
      delete $scope.giftCard.balance;
      var code = $scope.giftCard.code;
      giftCardService
        .get(code)
        .then(function (result) {
          if (code !== $scope.giftCard.code) {
            // code already changed, ignore this response
            return;
          }
          var giftCard = $scope.giftCard;
          _.merge(giftCard, result);
          giftCard.appliedCode = code;
          delete giftCard.error;
          if (giftCard.balance <= 0 || giftCard.daysToExpiration <= 0) {
            giftCard.error = true;
          }
        })
        .catch(function () {
          $scope.giftCard.error = true;
        });
    });
  }

  constructor();
}

module.exports = CheckoutBaseGiftCardPromoCodeController;
