/* jshint maxparams: 8 */
// @ngInject
function InitializerService($rootScope, config, cartService, stateSecurityService,
  analyticsECommerceService, errorExplodeService, experimentsService, contentPopupService) {

  this.initializeAll = function() {

    $rootScope.bodyClassesProviders = $rootScope.bodyClassesProviders || {};
    $rootScope.lastUrlInitiated = '';


    $rootScope.$on('$locationChangeSuccess', function() {
      setTimeout(function() {
        var newUrl = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
        if ($rootScope.lastUrlInitiated !== newUrl) {
          $rootScope.lastUrlInitiated = newUrl;
          contentPopupService.initialize();
        }
      }, 70);
    });
    analyticsECommerceService.initialize();

    $rootScope.bodyClassesProviders.debugMode = function() {
      return config.debug ? 'debug-mode' : 'live-mode';
    };

    if (config.debug) {
      errorExplodeService.enable();
    }
    experimentsService.initialize();

    cartService.load();

    // ensure anonymous user dont't see private stuff
    stateSecurityService.securePrivateStates();

    // Load the images of the dropdown in background after 4 seconds
    if (!window.isBot) {
      setTimeout(
        function() {
          $('.dropdown-nav img').show();
        }, 4000
      );
    }
  };
}

module.exports = InitializerService;
