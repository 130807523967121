
// @ngInject
function CamelToNameFilter() {
  return function(input) {
    if (input && typeof input.replace === 'function') {
      input = input.replace(/[A-Z]/g, function(c) { return ' ' + c; })
        .replace(/^\s+/, '').replace(/^[a-z]/, function(c) { return c.toUpperCase(); });
    }
    return input;
  };
}

module.exports = CamelToNameFilter;
