var _ = require('lodash-core');

/* globals $, Promise */
// jshint maxparams:10
// @ngInject
function ProductListDirective(productService, $parse, $rootScope, $state, brandService, scrollService) {
    var linker = function($scope, element, attrs) {

    $scope.sortable = attrs.sortable ? $parse(attrs.sortable)($scope) : false;

    var autoScrollTop = attrs.autoScrollTop ? $parse(attrs.autoScrollTop)($scope) : true;
    var styleIds = attrs.styleIds ? parseStringArray($parse(attrs.styleIds)($scope)) : null;
    //var scrollOffsetTop = parseInt(attrs.scrollOffsetTop || '0');
    var productListNameAttrValue = attrs.listName ? $parse(attrs.listName)($scope) : null;
    var defaultSort = attrs.defaultSort || '';
    if (productListNameAttrValue) {
      $scope.productListName = productListNameAttrValue;
    }
    var firstTime = true;
    var loading = true;

    var load = _.debounce(loadProducts, 100, {leading: false, trailing: true, maxWait: 2000});

    $scope.ready = function() {
      $rootScope.showLoadingOverlay = !loading;
      return !loading;
    };

    if (attrs.pageSize) {
      $scope.pager.size = $parse(attrs.pageSize)($scope);
    }

    // search params change
    $scope.$watch(function() {
      return $scope.getSearchParams();
    }, load, true);

    $scope.getParamsDescription = function() {
      var params = getParams();
      params.styleIds = styleIds;

      function wrapValue(value) {
        return '<span class="product-list-param-value">' + value + '</span>';
      }

      return _.compact(Object.keys(params).map(function(key) {
        if (['searchReady', 'limit'].indexOf(key) >= 0) {
          return;
        }
        if (!params[key] || (Array.isArray(params[key]) && params[key].length < 1)) {
          return;
        }
        var value = params[key];
        if (key === 'tags') {
          var userTags = value.filter(function(tag) {
            return /^u\./.test(tag);
          }).map(function(tag) {
            return wrapValue(tag.replace(/^u\./, ''));
          });
          var tags = value.filter(function(tag) {
            return !/^u\./.test(tag);
          }).map(function(tag) {
            return wrapValue(tag);
          });
          return _.compact([
            tags.length > 0 ? 'tags: ' + tags.join(', ') : undefined,
            userTags.length > 0 ? 'user tags: ' + userTags.join(', ') : undefined
          ]).join(', ');
        }
        return key + ':' + wrapValue(value);
      })).join(', ');
    };

    /*******************/
    /* private methods */

    var lastParams;

    function loadProducts() {

      var params = getParams();
      params.sort = params.sort || 'newest';

      var readyPromise = new Promise(function(resolve) {
        function hideCategory(category) {
          if (!params.searchTerm && params.tags.indexOf(category) === -1) {
            params.tags.push('-' + category);
          }
        }

        if ($scope.filters && $scope.filters.brands) {
          if ($scope.filters.brands.length !== 1) {
            if ($scope.filters.userTags.length === 0) {
              hideCategory('men');
              hideCategory('kids');
            }
            resolve();
          } else {
            brandService.getBrand($scope.filters.brands[0]).then(function(brand) {
              if (!brand.onlyKids) {
                // Hiding kids products by default in all the PLPs except kids categories
                hideCategory('kids');
              }
              if (!brand.onlyMen) {
                // Hiding men's products by default in all the PLPs except men's categories
                hideCategory('men');
              }
              resolve();
            });
          }
        }
      });

      readyPromise.then(function() {
        if (_.isEqual(params, lastParams)) {
          return; // avoid duplicated queries
        }

        // decide if we need to scroll to top
        var skipScrollToTopParams = {
          brand: null,
          price: null,
          tags: null
        };

        var lastParamsPart = _.merge({}, lastParams, skipScrollToTopParams);
        var paramsPart = _.merge({}, params, skipScrollToTopParams);
        var scrollToTop = !_.isEqual(lastParamsPart, paramsPart);

        if (!scrollToTop) {
          // check if there is a category change that require scrolling to top
          var lastTags = lastParams.tags.join(',').split(','); // join->split is for get the multiple category
          var currentTags = params.tags.join(',').split(',');
          var minLength = Math.min(lastTags.length, currentTags.length);
          scrollToTop = _.intersection(currentTags, lastTags).length < minLength;
        }

        if (lastParams && params.page === lastParams.page) {
          //so something different from page has changed, so we need to reset page
          $scope.pager.page = params.page = 1;
          $scope.result.items = [];
        }

        lastParams = params;
        loading = true;

        if (!params.searchReady) {
          return setData({items: [], count: 0, facets: {}, ready: false});
        }

        if ($state.current.name.indexOf('just-in') >= 0 && params.brand && params.brand.length === 0) {
          loadWhatsNewProducts(params, scrollToTop);
        } else if ($state.current.name.indexOf('sale') >= 0 && params.brand.length === 0) {
          loadSaleProducts(params, scrollToTop);
        } else {
          // load products
          makeSearch(params, scrollToTop);
        }
      });
    }

    function loadWhatsNewProducts(params, scrollToTop) {
      params.tags.push('-beauty');
      params.tags.push('-intimates');
      params.tags.push('-home');
      params.tags.push('-wellness');
      params.tags.push('-belts');
      params.tags.push('-gloves');
      params.tags.push('-hair-accessories');
      params.tags.push('-keychains');
      params.tags.push('-scarves');
      params.tags.push('-jewelry');
      params.tags.push('-sunglasses');
      params.tags.push('-optical-frames');
      params.tags.push('-sports accessories');
      params.tags.push('-watches');
      params.tags.push('-small-accessories');
      params.tags.push('-small-leather-goods');
      params.tags.push('-tech-accessories');
      params.tags.push('-travel');
      params.price.min = params.price.min || 50;
      brandService.getBrandsForWhatsNew().then(
        function(whatsNewBrands) {
          params.brand = _.map(whatsNewBrands, 'id');
          makeSearch(params, scrollToTop);
        }
      );
    }

    function loadSaleProducts(params, scrollToTop) {
      brandService.getBrandsForSale().then(
        function(whatsNewBrands) {
          params.brand = _.map(whatsNewBrands, 'id');
          makeSearch(params, scrollToTop);
        }
      );
    }

    function makeSearch(params, scrollToTop) {
      var brandsAllowed = filterBrandsByCountry(params.brand);
      var excludedBrands = _.merge([], params.excludeBrand, getBrandsToFilter());
      productService.search({
        searchTerm: params.searchTerm,
        styleIds: styleIds,
        limit: params.limit,
        page: params.page,
        sizes: params.sizes,
        colors: params.colors,
        tags: params.tags,
        brand: brandsAllowed,
        excludeBrands: excludedBrands,
        sort: params.sort,
        sale: params.sale,
        inStore: params.inStore,
        discount: params.discount,
        gifts: params.gifts,
        price: params.price,
        noDesigners: true
      }).then(function(data) {
        if (!_.isEqual(params, lastParams)) {
          return; // avoid fast first query override second slow one
        }
        if (data) {
          data.ready = true;
          setData(data, scrollToTop);
        }
        $rootScope.$emit('readyToScroll');
      });
    }

    $scope.pastData = -1;

    function getBrandsToFilter() {
      var rule = _.find($rootScope.featureFlags.internationalBlockedBrands.rules, function (rule) {
        return rule.countries.includes($rootScope.countrySelected());
      });
      if (rule && $rootScope.featureFlags.internationalBlockedBrands.enabled) {
        return rule.brands;
      } else {
        return [];
      }
    }

    function filterBrandsByCountry(brands) {
      if ($rootScope.featureFlags.internationalBlockedBrands.enabled && $rootScope.countrySelected() !== 'us') {
        if (brands && brands.length > 0) {
          var blockedBrands = getBrandsToFilter();
          var filteredBrands = _.filter(brands, function (brand) {
            return !blockedBrands.includes(brand);
          });
          if (filteredBrands && filteredBrands.length) {
            return filteredBrands;
          } else {
            return ['non-exist-brand'];
          }
        }
      }
      return brands;
    }

    function setData(data, scrollToTop) {
      $scope.result.count = $rootScope.productsCount = data.count;
      if (!scrollService.isInfiniteScroll()) {
        $scope.result.items = data.items;
      } else {
        $scope.result.items = $scope.result.items ? _.union($scope.result.items, data.items) : $scope.result.items;
        if ($scope.pastData !== $scope.result.count) {
          $scope.result.items = data.items;
          $('html, body').animate({scrollTop: 0}, 'fast');
        }
        $scope.pastData = $scope.result.count;
      }

      $scope.result.facets = data.facets;
      $scope.result.ready = data.ready;
      $scope.result.index = data.index;
      $scope.result.pages = data.pages;
      $scope.products = _.compact($scope.result.items);

      if (!firstTime) {
        if (autoScrollTop && scrollToTop) {
          //$scope.scrollTo(0, element.offset().top-scrollOffsetTop);
        }
      } else {
        firstTime = false;
      }
      loading = false;
    }

    function parseStringArray(value) {
      if (!value) {
        return null;
      }
      if (typeof value === 'string') {
        value = value.split(/ *, */g);
      }
      if (Array.isArray(value) && value.length > 0) {
        return value;
      }
      return null;
    }

    function getParams() {
      $scope.filters.userTags = !attrs.userTags ? [] : _.map(attrs.userTags.split(','), function(tag) {
        return 'u.' + tag.replace(/^\s+/, '').replace(/\s+$/, '');
      });
      var params = $scope.getSearchParams ? $scope.getSearchParams() : {};
      return {
        tags: params.tags,
        brand: params.brand,
        sizes: params.sizes,
        colors: params.colors,
        searchTerm: params.searchTerm,
        searchReady: $scope.search ? $scope.search.ready : true,
        sort: params.sort || defaultSort,
        sale: params.sale,
        discount: params.discount,
        inStore: params.inStore,
        gifts: params.gifts,
        page: params.page,
        limit: $scope.pager.size,
        price: getPrice(params.price)
      };
    }

    function getPrice(price) {
      if (attrs.minPrice) {
        price.min = $parse(attrs.minPrice)($scope);
      }
      if (attrs.maxPrice) {
        price.max = $parse(attrs.maxPrice)($scope);
      }
      return price;
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/product-list.html',
    restrict: 'E',
    scope: true,
    transclude: true
  };
}

module.exports = ProductListDirective;
