var sharedBusinessRules = require('shared-business-rules');

// @ngInject
function UniqueEmailDirective(userService) {

  // simple test just to prevent unnecessary roundtrips
  // (email address validity is done by type=email)
  var EMAIL_REGEX = /^[^@]+\@[^@]+$/;
  var disposableEmails = [];

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {
      var lastEmail;
      function validate(viewValue) {
        var email = viewValue;
        lastEmail = email;
        if (email && EMAIL_REGEX.test(email) && attrs.skipUniqueEmailCheck !== 'true') {
          userService.isEmailAvailable(email).then(function(result) {
            if (lastEmail !== email) {
              // email has already changed, ignore this result
              return;
            }
            if (attrs.skipUniqueEmailCheckError) {
              scope.uniqueEmailNoInvite = result.availability;
            } else {
              disposableEmails = sharedBusinessRules.disposableEmails.getArray().emails;
              /* jshint ignore:start */
              var regex = new RegExp(disposableEmails.map(function(s) {
                return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
              }).join('|'));
              ctrl.$setValidity('uniqueEmail', result.availability || result.invitePending);
              ctrl.$setValidity('nonDisposableEmail', !regex.test(email));
              /* jshint ignore:end */
            }
          });
        } else {
          ctrl.$setValidity('uniqueEmail', true);
          ctrl.$setValidity('uniqueEmailNoInvite', true);
          scope.uniqueEmailNoInvite = true;
          ctrl.$setValidity('hasInvitePending', true);
          ctrl.$setValidity('nonDisposableEmail', true);
        }
        return viewValue;
      }
      ctrl.$parsers.unshift(validate);
    }
  };
}

module.exports = UniqueEmailDirective;
