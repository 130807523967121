/* globals $ */

// @ngInject
function AccountController($scope, $state, $location) {

  $scope.setNoRobots();

  $scope.orderDetail = {
    visible: false
  };

  $scope.setBreadcrumbPath([{name: 'account'}]);
  $scope.setPageMetadata({
    title: 'Account | THE MILE'
  });

  updateCurrentAccountView();

  $scope.goToSection = function(section) {
    if ($location.hash() === section) {
      $location.hash('section');
      setTimeout(function() {
        $location.hash(section);
      }, 10);
    } else {
      $location.hash(section);
    }
  };

  $scope.isSection = function(section) {
    return $location.hash() === section;
  };

  function updateCurrentAccountView() {
    var match = /account\.([\w]+)/.exec($state.current.name);
    if (!match) {
      $state.go('root.account.summary');
    }
  }

  $(window).on('scroll', function() {
    var accountView = $('.account-view');
    if (accountView && accountView.offset() && $(window).scrollTop() > accountView.offset().top - 20) {
      $('.menu-panel').addClass('active');
    } else {
      $('.menu-panel').removeClass('active');
    }
  });
}

module.exports = AccountController;
