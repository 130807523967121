// @ngInject
function LoyaltyExperienceController($scope, $uibModal, $rootScope, staticAssetService, userService,
                                     loyaltyExperienceService) {
  $scope.instance = {};

  $scope.hideModal = function() {
    $rootScope.closeModals();
  };

  $scope.openModal = function(modal, experience) {
    $rootScope.closeModals();
    setTimeout(function() {
      $rootScope.experience = experience;
      $scope.instance = $uibModal.open({
        templateUrl: '/views/partials/modals/loyalty-' + modal + '.html',
        windowClass: 'loyalty-modal',
        size: 'lg',
        scope: $rootScope
      });
    }, 300);
  };

  $scope.canRedeemReward = function(experience) {
    experience = experience || $rootScope.experience;
    return $rootScope.loyalty.remainingPoints >= experience.points;
  };
  $scope.errorMessage = false;
  $scope.loadingRedeem = false;
  $scope.redeemReward = function() {
    $scope.loadingRedeem = true;
    var redeemPromise = {};

    if ($rootScope.experience.partner.id === 'orchardmile') {
      redeemPromise = loyaltyExperienceService.redeemReward($rootScope.experience.id);
    } else {
      redeemPromise = loyaltyExperienceService.redeem($rootScope.experience.id);
    }

    redeemPromise.then(function(result) {
      if (result.ok) {
        $rootScope.closeModals();
        $scope.loadingRedeem = false;
        $rootScope.loyalty = {loading: true};
        userService.redeemPoints();
        var loyaltyPromise = userService.getLoyalty();
        loyaltyPromise.then(function(loyalty) {
          $rootScope.loyalty = loyalty;
        });
      } else {
        $scope.errorMessage = true;
        $scope.loadingRedeem = false;
        $scope.error = result.message ? result.message : 'Sorry, an error ocurred';
      }
    });
  };
  $scope.showConfirmation = function(experience) {
    $rootScope.closeModals();
    setTimeout(function() {
      $scope.openModal('confirmation', experience || $scope.experience);
    }, 300);
  };

  $scope.descriptionStyled = function(description) {
    if (description) {
      return description.replace(/\n/g, '<br/>');
    }
    return '';
  };

  $scope.getExperienceImage = function(experience, image) {
    var partnerId = experience.partner.id ? experience.partner.id : experience.partner;
    if (image === 'logo.png') {
      return staticAssetService.url('/content/images/experiences/' + partnerId + '/' + image);
    } else {
      return staticAssetService.url('/content/images/experiences/' + partnerId + '/' + experience.id + '/' + image);
    }
  };
}

module.exports = LoyaltyExperienceController;
