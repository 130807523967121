var _ = require('lodash-core');

/* jshint camelcase: false */
// @ngInject
function AdminEarningsController($scope, $state, $stateParams, userService, showService, influencerService) {

  var usedParameters = {};
  var influencers = {};
  var influencerId;
  var shows = [];

  $scope.pageSize = 10;

  $scope.rankings = {
    influencer: [],
    category: [],
    brand: [],
    content: [],
    item: []
  };

  $scope.rankingOptions = [
    { id: 'brand', name: 'PER BRAND' },
    { id: 'category', name: 'PER CATEGORY' },
    { id: 'content', name: 'PER CONTENT' }
  ];

  $scope.selectedDate = {
    from: null,
    to: null
  };

  $scope.pages = {
    brand: 0,
    influencer: 0,
    category: 0,
    content: 0,
    item: 0
  };

  $scope.cardSections = {
    earnings: undefined,
    comingFromList: undefined,
    origin: undefined
  };

  $scope.getPaginatedRanking = function (pageSize, type) {
    var filteredRanking = _.filter($scope.rankings[type], function (item) {
      if (!$scope.parameters.term) {
        return true;
      } else {
        var value = false;
        _.forEach(Object.values(item), function (val) {
          if (JSON.stringify(val).toLowerCase().includes($scope.parameters.term.toLowerCase())) {
            value = true;
          }
          if (!value && (typeof val === 'string' || val instanceof String) &&
            matchByScoring($scope.parameters.term.toLowerCase(), val.replace(/ /g, '').toLowerCase()) <= 5) {
            value = true;
          }
        });
        return value;
      }
    });
    return _.take(
      _.slice(
        filteredRanking, $scope.pages[type]
      ), pageSize || $scope.pageSize
    );
  };

  function matchByScoring(a, b) {
    var matrix = Array.from(Array(a.length + 1), function () {
      return Array(b.length + 1);
    });
    var i,j;

    for (i = 0; i <= a.length; i++) {
      matrix[i][0] = i;
    }
    for (j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }

    for (i = 1; i <= a.length; i++) {
      for (j = 1; j <= b.length; j++) {
        var cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }

    return matrix[a.length][b.length];
  }

  $scope.parameters = {
    term: $stateParams.term
  };

  $scope.getMonthlySummary = function () {
    if ($scope.monthly) {
      return _.orderBy(Object.values($scope.monthly), ['dateName']);
    }
  };

  if ($stateParams.type) {
    $scope.attributionsType = $stateParams.type;
  } else {
    $scope.attributionsType = 'influencers';
  }

  if ($stateParams.show) {
    $scope.parameters.term = $stateParams.show;
  }

  function loadInfluencerInfo() {
    influencerId = $stateParams.id;
    influencerService.getById(influencerId).then(function (inf) {
      $scope.influencer = inf;
      $scope.influencer.name = inf.preferredName || inf.username;
    });
  }

  if ($stateParams.id) {
    loadInfluencerInfo();
    $scope.rankingOptions.unshift({ id: 'item', name: 'PER ITEM' });
  } else {
    $scope.rankingOptions.unshift({ id: 'influencer', name: 'PER INFLUENCER' });
  }

  var loadShowsInfo = function (showsIds) {
    var showsIdsToLoad = _.filter(showsIds, function (showId) {
      return _.some(shows, function (loadedShow) {
        return loadedShow.id === showId;
      });
    });
    return showService.getAttributionExtra(showsIdsToLoad).then(function (loadedShows) {
      _.merge(shows, loadedShows);
    });
  };

  var calculateItemAttributionTotal = function (item) {
    if (!item.confirmed) {
      item.pendingAttributedAmount = item.attributedAmount;
      item.attributedAmount = 0;
    }
    if (item.cancelled || item.returned) {
      item.cancelledAmount = item.attributedAmount;
      item.attributedAmount = 0;
    }
    return item;
  };

  $scope.changeType = function () {
    window.location.href = $state.href($state.current.name, { type: $scope.attributionsType }, { absolute: true });
  };

  var parseAttributions = function (attributions, name) {
    var attributionsResult = {
      name: name,
      influencers: [],
      general: {
        total: { amount: 0, count: 0, pendingAmount: 0 },
        netTotal: { amount: 0, count: 0, pendingAmount: 0 },
        attributions: { amount: 0, count: 0, pendingAmount: 0 },
        discounts: { amount: 0, count: 0, pendingAmount: 0 },
        returns: { amount: 0, count: 0 },
        cancellations: { amount: 0, count: 0 }
      },
      brands: [],
      comingFrom: {
        referralShow: { total: 0, attributions: 0, count: 0 },
        referralProduct: { total: 0, attributions: 0, count: 0 },
        referralStore: { total: 0, attributions: 0, count: 0 },
        referralReel: { total: 0, attributions: 0, count: 0 },
        show: { total: 0, attributions: 0, count: 0 },
        liveShow: { total: 0, attributions: 0, count: 0 },
        reel: { total: 0, attributions: 0, count: 0 },
        store: { total: 0, attributions: 0, count: 0 },
        brand: { total: 0, attributions: 0, count: 0 },
        app:  { total: 0, attributions: 0, count: 0, cancelled: 0 },
        web:  { total: 0, attributions: 0, count: 0, cancelled: 0 }
      },
      tags: [],
      contentIds: [],
      content: []
    };
    _.forEach(Object.entries(attributions), function (value) {
      var data = value[1];
      if ((data.isBrand && ['all', 'brands'].includes($scope.attributionsType)) ||
        (!data.isBrand && ['all', 'influencers'].includes($scope.attributionsType))) {
        // influencer attributions
        var influencer = _.find(attributionsResult.influencers, function (inf) {
          return inf.id === value[0];
        });

        if (!influencer) {
          influencer = {
            id: value[0],
            total: 0,
            attributions: 0,
            discounts: 0,
            returnedAttributions: 0,
            returnedTotal: 0,
            cancelledAttributions: 0,
            cancelledTotal: 0,
            attributedAmount: 0,
            pendingAttributedAmount: 0,
          };
          attributionsResult.influencers.push(influencer);
        }

        influencer.total += data.total;
        influencer.attributions += data.attributions;
        influencer.discounts += data.discounts;
        influencer.returnedAttributions += data.returnedAttributions;
        influencer.returnedTotal += data.returnedTotal;
        influencer.cancelledAttributions += data.cancelledAttributions;
        influencer.cancelledTotal += data.cancelledTotal;
        influencer.attributedAmount += data.attributedAmount;
        influencer.pendingAttributedAmount += data.pendingAttributedAmount;

        _.forEach(data.items, function (item) {
          // content ids
          var comingFromId = getComingFromId(item.comingFrom);
          if (comingFromId) {
            attributionsResult.contentIds.push(comingFromId);
          }

          // campaigns
          var campaignName = getCampaignName(item.attributionReason, item.comingFrom);

          if (campaignName && attributionsResult.comingFrom[campaignName]) {
            var campaign = attributionsResult.comingFrom[campaignName];
            if (item.cancelled || item.returned) {
              campaign.cancelled += item.quantity;
            } else if (!item.confirmed) {
              campaign.unconfirmed += item.quantity;
            } else {
              campaign.total += item.price * item.quantity;
              campaign.attributions += item.attributedAmount;
              campaign.count += item.quantity;
            }
          }

          if (data.isBrand) {
            var brandCampaign = attributionsResult.comingFrom.brand;

            brandCampaign.total += item.price * item.quantity;
            brandCampaign.attributions += item.attributedAmount;
            brandCampaign.count += item.quantity;
          }

          if (item.app) {
            var appCampaign = attributionsResult.comingFrom.app;
            if (item.cancelled || item.returned) {
              appCampaign.cancelled += item.quantity;
            } else if (!item.confirmed) {
              appCampaign.unconfirmed += item.quantity;
            } else {
              appCampaign.total += item.price * item.quantity;
              appCampaign.attributions += item.attributedAmount;
              appCampaign.count += item.quantity;
            }
          } else {
            var webCampaign = attributionsResult.comingFrom.web;

            if (item.cancelled || item.returned) {
              webCampaign.cancelled += item.quantity;
            } else if (!item.confirmed) {
              webCampaign.unconfirmed += item.quantity;
            } else {
              webCampaign.total += item.price * item.quantity;
              webCampaign.attributions += item.attributedAmount;
              webCampaign.count += item.quantity;
            }
          }

          // brands
          var brand = _.find(attributionsResult.brands, function (brand) {
            return brand.id === item.brandId;
          });

          if (!brand) {
            brand = {
              id: item.brandId,
              total: 0,
              contents: [],
              countByBrand: 0,
              count: 0
            };
            attributionsResult.brands.push(brand);
          }

          if (comingFromId) {
            brand.contents.push({id: comingFromId, quantity: item.quantity});
          }
          brand.count += item.quantity;
          brand.total += item.price;

          // tags
          _.forEach(item.tags, function (itemTag) {
            var tag = _.find(attributionsResult.tags, function (tag) {
              return tag.name === itemTag;
            });

            if (!tag) {
              tag = {
                name: itemTag,
                total: 0,
                count: 0
              };
              attributionsResult.tags.push(tag);
            }

            tag.count += item.quantity;
            tag.total += item.price;
          });

          var contentId = getCampaignId(item.attributionReason, item.comingFrom);

          if (contentId) {
            // content
            var content = _.find(attributionsResult.content, function (content) {
              return content.id === contentId;
            });

            if (!content) {
              content = {
                id: contentId,
                type: campaignName,
                total: 0,
                returned: 0,
                cancelled: 0,
                count: 0
              };
              attributionsResult.content.push(content);
            }
            if (item.cancelled) {
              content.cancelled++;
            }
            if (item.returned) {
              content.returned++;
            }
            content.count += item.quantity;
            content.total += item.price;
          }

          // general count
          if (item.discount) {
            attributionsResult.general.discounts.count += item.quantity;
          }
        });

        // General Attributions
        attributionsResult.general.total.amount += data.total;
        attributionsResult.general.total.count += data.attributions;
        attributionsResult.general.total.pendingAmount += data.pendingAmount;

        attributionsResult.general.attributions.amount += data.attributedAmount;
        attributionsResult.general.attributions.pendingAmount += data.pendingAttributedAmount;
        attributionsResult.general.attributions.count +=
          data.attributions - data.returnedAttributions - data.cancelledAttributions;

        attributionsResult.general.returns.amount += data.returnedTotal;
        attributionsResult.general.returns.count += data.returnedAttributions;

        attributionsResult.general.cancellations.amount += data.cancelledTotal;
        attributionsResult.general.cancellations.count += data.cancelledAttributions;

        attributionsResult.general.discounts.amount += data.discounts;
        attributionsResult.general.netTotal.amount += data.total - data.returnedTotal - data.cancelledTotal -
          data.discounts;
        attributionsResult.general.netTotal.pendingAmount += data.pendingAmount - data.pendingDiscounts;
        attributionsResult.general.discounts.pendingAmount += data.pendingDiscounts;
        attributionsResult.general.netTotal.count += data.attributions - data.cancelledAttributions -
          data.returnedAttributions;

        attributionsResult.items = _.map(data.items, function (item) {
          return calculateItemAttributionTotal(item);
        });
        if (name === 'all' && !$scope.monthly && $stateParams.id) {
          $scope.monthly = {};
          _.forEach(data.items, function(item) {
            var date = item.createdAt.substr(0, 7);
            if (!$scope.monthly[date]) {
              $scope.monthly[date] = {
                dateName: date,
                confirmedAmount: 0,
                unconfirmedAmount: 0,
                cancelledAmount: 0
              };
            }
            $scope.monthly[date].confirmedAmount += item.attributedAmount;
            $scope.monthly[date].unconfirmedAmount += item.pendingAttributedAmount || 0;
            $scope.monthly[date].cancelledAmount += item.cancelledAmount || 0;
          });
        }
      }
    });
    return attributionsResult;
  };

  var today = new Date();
  var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  var firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  var firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
  var theMileStartedDate = new Date(2023, 1, 1);

  var getPeriodData = function () {
    var data = {
      id: ''
    };
    if ($scope.from) {
      data.id += 'f' + $scope.from.toISOString().substr(0,10);
    } else {
      data.id += 'f' + theMileStartedDate.toISOString().substr(0,10);
    }

    if ($scope.to) {
      data.id += 't' + $scope.to.toISOString().substr(0,10);
    } else {
      data.id += 't' + today.toISOString().substr(0,10);
    }
    return data;
  };


  function getFirstDayOfPreviousMonth() {
    var year = today.getFullYear();
    var month = today.getMonth();

    if (month === 0) {
      year--;
      month = 11;
    } else {
      month--;
    }

    return new Date(year, month, 1);
  }

  function getCampaignName(reason, comingFrom) {
    if (reason) {
      return reason.type.replace('-s', 'S').replace('-r', 'R').replace('-p', 'P');
    }
    if (comingFrom) {
      if (comingFrom.includes('show')) {
        return 'show';
      }
      if (comingFrom.includes('influencer')) {
        return 'store';
      }
    }
  }

  function getCampaignId(reason, comingFrom) {
    if (reason && reason.id) {
      return reason.id;
    }
    if (comingFrom) {
      if (comingFrom.includes('show')) {
        return comingFrom.replace('the-mile.', '').replace('show-', '');
      }
      if (comingFrom.includes('influencer')) {
        return comingFrom.replace('the-mile.', '').replace('influencer-', '');
      }
    }
  }

  function getComingFromId(comingFrom) {
    if (comingFrom && comingFrom.includes('the-mile')) {
      return comingFrom.replace('the-mile.', '').replace('show-', '').replace('influencer-', '');
    }
  }

  $scope.sortRankingBy = function (type, field, isAsc) {
    var order = [];
    if (isAsc) {
      order.push('asc');
    } else {
      order.push('desc');
    }
    $scope.rankings[type] = _.orderBy($scope.rankings[type], [field], order);
  };

  var periods = {
    all: { name: 'all', params: { from: theMileStartedDate } },
    thisMonth: { name: 'this month', params: { from: firstDayOfMonth } },
    lastMonth: { name: 'last month', params: { from: getFirstDayOfPreviousMonth(), to: firstDayOfMonth } },
    thisYear: { name: 'this year', params: { from: firstDayOfYear } },
    lastYear: { name: 'last year', params: { from: firstDayOfLastYear, to: firstDayOfYear } }
  };

  $scope.isPeriodLoading = function (periodId) {
    if (periodId && periods[periodId]) {
      return !periods[periodId].attributions;
    } else if ($scope.periodSelected) {
      var customPeriod = getPeriodData();
      return periods[customPeriod.id] && !periods[customPeriod.id].attributions;
    } else {
      return true;
    }
  };

  var loadPeriod = function (periodId, isDefaultPeriod) {
    var period = periods[periodId];

    if (influencerId) {
      period.params.influencerId = influencerId;
    }
    $scope.loading = true;
    influencerService.getAttributions(period.params).then(function (attributions) {
      period.attributions = parseAttributions(attributions, period.name);

      period.attributions.tags = _.orderBy(period.attributions.tags, ['total'], 'desc');
      _.forEach(period.attributions.tags, function (tag, pos) {
        tag.number = pos + 1;
      });

      loadShowsInfo(period.attributions.contentIds).then(function () {
        _.forEach(period.attributions.brands, function(brand) {
          _.forEach(brand.contents, function (content) {
            var show = _.find($scope.shows, function (show) {
              return show.id === content.id;
            });
            if (show && show.isBrandContent) {
              brand.countByBrand += content.quantity;
            }
          });
        });
      });

      period.attributions.content = _.orderBy(period.attributions.content, ['total'], 'desc');
      _.forEach(period.attributions.content, function (content, pos) {
        content.number = pos + 1;
      });

      period.attributions.brands = _.orderBy(period.attributions.brands, ['total'], 'desc');
      _.forEach(period.attributions.brands, function (brand, pos) {
        brand.number = pos + 1;
      });


      if (isDefaultPeriod) {
        $scope.selectPeriod(periodId);
      }
    }).catch(function (e) {
      console.error(e);
    }).finally(function () {
      $scope.loading = false;
    });
  };

  var loadAttributions = function () {
    if ($stateParams.from || $stateParams.to) {
      $scope.loadPeriodFromUrl();
      loadPeriod('thisMonth');
    } else {
      loadPeriod('thisMonth', true);
    }
    loadPeriod('lastMonth');
    loadPeriod('thisYear');
    loadPeriod('lastYear');
    loadPeriod('all');
  };

  $scope.data = {};

  var loadEarningCards = function (cards, attributions) {
    var general = attributions.general;
    var totalTip = 'The gross total, before returns and cancellations';
    cards.push({
      title: 'Gross total',
      type: 'primary',
      amount: general.total.amount,
      tip: totalTip,
      icon: 'icon-money',
      props: [{
        key: 'Items:',
        value: general.total.count
      },
      {
        key: 'Unconfirmed amount:',
        value: '$' + general.total.pendingAmount.toFixed(2)
      }]
    });
    var netTotalTip = 'Net total, returns and cancellations are deducted.';
    cards.push({
      title: 'Net Total',
      type: 'primary',
      amount: general.netTotal.amount,
      tip: netTotalTip,
      icon: 'icon-net',
      props: [{
        key: 'Items:',
        value: general.netTotal.count
      },{
        key: 'Unconfirmed amount:',
        value: '$' + general.netTotal.pendingAmount.toFixed(2)
      }]
    });
    var discountTips = 'Sum of discounts';
    cards.push({
      title: 'Discounts',
      type: 'primary',
      amount: general.discounts.amount,
      tip: discountTips,
      iconChar: '%',
      props: [{
        key: 'Items with a discounted price:',
        value: general.discounts.count
      }, {
        key: 'Unconfirmed amount:',
        value: '$' + general.discounts.pendingAmount.toFixed(2)
      }]
    });
    var attributionsTip = 'Total attributed to the influencers.';
    cards.push({
      title: 'Attributions',
      type: 'secondary',
      amount: general.attributions.amount,
      tip: attributionsTip,
      icon: 'icon-wallet',
      props: [{
        key: 'Items:',
        value: general.attributions.count
      },{
        key: 'Unconfirmed amount:',
        value: '$' + general.attributions.pendingAmount.toFixed(2)
      }]
    });
    var returnsTip = 'Sum of returns.';
    cards.push({
      title: 'Returns',
      type: 'warn',
      amount: general.returns.amount,
      tip: returnsTip,
      icon: 'icon-refresh',
      props: [{
        key: 'Items:',
        value: general.returns.count
      }]
    });
    var cancellationsTip = 'Sum of cancellations.';
    cards.push({
      title: 'Cancellations',
      type: 'warn',
      amount: general.cancellations.amount,
      tip: cancellationsTip,
      icon: 'icon-minus',
      props: [{
        key: 'Items:',
        value: general.cancellations.count
      }]
    });
  };

  var loadCampaignsCards = function (cards, attributions) {
    var comingFromList = attributions.comingFrom;
    var total = comingFromList.referralShow.attributions + comingFromList.referralProduct.attributions +
      comingFromList.referralStore.attributions + comingFromList.referralReel.attributions;
    var tip1 = 'Total attributed due UTM campaigns';
    cards.push({
      title: 'UTM Links',
      type: 'data',
      amount: total,
      tip: tip1,
      icon: 'icon-profile',
      props: [{
        key: 'Items attributed to show links: ',
        value: comingFromList.referralShow.count + comingFromList.referralReel.count
      },
      {
        key: 'Items attributed to product links: ',
        value: comingFromList.referralProduct.count
      }]
    });

    var totalPerWatching = comingFromList.show.attributions + comingFromList.reel.attributions +
      comingFromList.store.attributions + comingFromList.liveShow.attributions;
    cards.push({
      title: 'Watched Content',
      type: 'data',
      amount: totalPerWatching,
      tip: 'Total attributed due watched content',
      icon: 'icon-profile',
      props: [{
          key: 'Items attributed to posted shows: ',
          value: comingFromList.show.count + comingFromList.reel.count
        },
        {
          key: 'Items attributed to live shows: ',
          value: comingFromList.liveShow.count
        },
        {
          key: 'Items attributed to visited stores: ',
          value: comingFromList.store.count
        }]
    });

    cards.push({
      title: 'Brand Content',
      type: 'data',
      amount: comingFromList.brand.attributions,
      tip: 'Total attributed to our own content (i.e: brand reels)',
      icon: 'icon-money',
      props: [{
        key: 'Items: ',
        value: comingFromList.brand.count
      }]
    });
  };

  var loadOriginCards = function (cards, attributions) {
    var comingFromList = attributions.comingFrom;
    cards.push({
      title: 'App',
      type: 'data',
      amount: comingFromList.app.attributions,
      tip: 'Total attributed from users using app',
      icon: 'icon-money',
      props: [{
        key: 'Items Attributed: ',
        value: comingFromList.app.count
      },
        {
          key: 'Items Returned or cancelled: ',
          value: comingFromList.app.cancelled
        }]
    });
    cards.push({
      title: 'Web Client',
      type: 'data',
      amount: comingFromList.web.attributions,
      tip: 'Total attributed from users using our website',
      icon: 'icon-money',
      props: [{
        key: 'Items Attributed: ',
        value: comingFromList.web.count
      },
        {
          key: 'Items Returned or cancelled: ',
          value: comingFromList.web.cancelled
        }]
    });
  };

  $scope.getTitle = function (type) {
    if (influencerId) {
      if ($scope.influencer) {
        return $scope.influencer.name + ' ' + type.toUpperCase() + ' for ' + $scope.periodSelected.name;
      }
    } else {
      if ($scope.periodSelected) {
        return 'GENERAL ' + type.toUpperCase() + ' for ' + $scope.periodSelected.name;
      }
    }
    return 'Loading...';
  };

  var selectCustomDates = function (period) {
    if (period.params.from) {
      $scope.from = period.params.from;
    } else {
      $scope.from = today;
    }
    if (period.params.to) {
      $scope.to = period.params.to;
    } else {
      $scope.to = today;
    }
  };

  $scope.loadPeriodFromUrl = function () {
    var from = $stateParams.from.replace(/-/g, '/');
    var to = $stateParams.to.replace(/-/g, '/');
    if (from || to) {
      if (from) {
        $scope.from = new Date(from);
      } else {
        $scope.from = theMileStartedDate;
      }
      if (to) {
        $scope.to = new Date(to);
      } else {
        $scope.to = today;
      }
      $scope.searchCustomPeriod();
    }
  };

  $scope.searchCustomPeriod = function () {
    var periodData = getPeriodData();

    if (periods[periodData.id]) {
      $scope.selectPeriod(periodData.id);
    } else {
      var period = {
        name: periodData.name,
        params: {}
      };
      if ($scope.from) {
        var dateFrom = $scope.from.toISOString().substr(0,10).split('-');
        period.params.from = new Date(Number(dateFrom[0]), Number(dateFrom[1]) - 1, Number(dateFrom[2]),0,0,0);
      } else {
        period.params.from = theMileStartedDate;
      }

      if ($scope.to) {
        period.params.to = new Date($scope.to.toISOString().substr(0,10) + 'T23:59:59.999Z');
      } else {
        period.params.to = today;
      }
      periods[periodData.id] = period;
      loadPeriod(periodData.id, true);
    }
  };

  $scope.selectPeriod = function(periodId) {
    var periodSelected = periods[periodId] || {};
    periodSelected.id = periodId;
    selectCustomDates(periodSelected);

    $scope.periodSelected = periodSelected;
    var attribution = periodSelected.attributions;

    if (influencerId) {
      $scope.items = periodSelected.attributions.items;
    }
    // Load Influencer Info
    _.forEach(attribution.influencers, function (influencerAttribution) {
      var influencerData = influencers[influencerAttribution.id];
      if (!influencerData) {
        influencerService.getById(influencerAttribution.id).then(function (inf) {
          influencers[influencerAttribution.id] = inf;
          influencerAttribution.name = inf.preferredName || inf.username;
          influencerAttribution.isBrand = inf.isBrand;
          influencerAttribution.userId = inf.userId;
        });
      } else {
        influencerAttribution.name = influencerData.preferredName || influencerData.username;
        influencerAttribution.isBrand = influencerData.isBrand;
      }
    });

    var earnings = { cards: [] };
    loadEarningCards(earnings.cards, periodSelected.attributions);
    $scope.cardSections.earnings = earnings;

    var comingFromList = { cards: [] };
    loadCampaignsCards(comingFromList.cards, periodSelected.attributions);
    $scope.cardSections.comingFromList = comingFromList;

    var origin = { cards: [] };
    loadOriginCards(origin.cards, periodSelected.attributions);
    $scope.cardSections.origin = origin;

    // Prepare ranking data
    if (influencerId) {
      $scope.rankings.item = attribution.items;
    } else {
      $scope.rankings.influencer = attribution.influencers;
    }
    $scope.rankings.category = attribution.tags;
    $scope.rankings.content = attribution.content;
    $scope.rankings.brand = attribution.brands;
  };

  $scope.goNext = function (type) {
    if ($scope.pages[type] + $scope.pageSize < $scope.rankings[type].length) {
      $scope.pages[type] += $scope.pageSize;
    }
  };

  $scope.goPrevious = function (type) {
    if ($scope.pageSize < $scope.pages[type]) {
      $scope.pages[type] -= $scope.pageSize;
    } else {
      $scope.pages[type] = 0;
    }
  };

  $scope.getPagesCount = function (type) {
    if ($scope.rankings[type]) {
      if (($scope.pages[type] + $scope.pageSize) > $scope.rankings[type].length) {
        return $scope.rankings[type].length + '/' + $scope.rankings[type].length;
      } else {
        return ($scope.pages[type] + $scope.pageSize) + '/' + $scope.rankings[type].length;
      }
    } else {
      return 0;
    }

  };

  $scope.keypress = function($event) {
    if ($event.which === 13) {
      if (!_.isEqual($scope.parameters, usedParameters)) {
        $scope.loading = true;
      }
    }
  };

  $scope.loadScripts = function() {
    $('#xls1').remove();
    $('#xls2').remove();
    // Create an async script.
    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.id = 'xls1';

    script.src = ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'unpkg.com/xlsx/dist/shim.min.js';
    // Insert our script next to the first script element.
    var scripts = document.getElementsByTagName('script');
    var last = scripts[scripts.length - 1];
    last.parentNode.insertBefore(script, last.nextSibling);
    // Create an async script.
    var script2 = document.createElement('script');

    script2.type = 'text/javascript';
    script2.async = true;
    script2.id = 'xls2';

    script2.src = ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'unpkg.com/xlsx/dist/xlsx.full.min.js';
    scripts = document.getElementsByTagName('script');
    last = scripts[scripts.length - 1];
    last.parentNode.insertBefore(script2, last.nextSibling);
  };


  $scope.downloadReport = function () {
    var earningCards = $scope.cardSections.earnings.cards;
    var data = [['Title', 'Amount', 'Count']];

    _.forEach(earningCards, function (card) {
      data.push([
        card.title, card.amount, card.props[0].value
      ]);
    });
    downloadXLS(data);
    if ($scope.influencer) {
      var extraDataInf = [[
        'Title',
        'Style Id',
        'Brand Id',
        'Order Number',
        'Price',
        'Quantity',
        'Attributed Amount',
        'Discount',
        'Cancelled',
        'Returned'
      ]];
      _.forEach(this.getPaginatedRanking(300), function (item) {
        extraDataInf.push([
            item.title,
            item.styleId,
            item.brandId,
            item.orderNumber,
            '$' + item.price.toFixed(2),
            item.quantity,
            '$' + item.attributedAmount.toFixed(2),
            '$' + item.discount.toFixed(2),
            item.cancelled ? 'x': '',
            item.returned  ? 'x': ''
        ]);
      });
      downloadXLS(extraDataInf);
    } else {
      var extraData = [['Influencer Name',
        'Influencer Id',
        'Total Attributed',
        'Points Earned',
        'Number of attributions',
        'Selling Amount',
        'Discounts',
        'Total Returned',
        'Number of items returned',
        'Total Cancelled',
        'Number of items Cancelled']];

      _.forEach(this.getPaginatedRanking(300), function (inf) {
        extraData.push([
          inf.name,
          inf.id,
          '$' + inf.attributedAmount.toFixed(2),
          inf.attributedAmount.toFixed(0) * 20,
          inf.attributions,
          '$' + inf.total.toFixed(2),
          '$' + inf.discounts.toFixed(2),
          '$' + inf.returnedTotal.toFixed(2),
          inf.returnedAttributions,
          '$' + inf.cancelledTotal.toFixed(2),
          inf.cancelledAttributions
        ]);
      });
      downloadXLS(extraData);
    }
  };

  $scope.generateItemReport = function () {
    fillReport($scope.rankings.item);
    saveReport('test', 'testSlap');
  };

  function downloadXLS(data) {
    /* jshint camelcase: false */
    var filename = 'attributions-' + getPeriodData().id + '.xlsx';
    var wsName = 'Orchardmile Attributions';

    var wb = window.XLSX.utils.book_new(), ws = window.XLSX.utils.aoa_to_sheet(data);

    /* add worksheet to workbook */
    window.XLSX.utils.book_append_sheet(wb, ws, wsName);

    /* write workbook */
    window.XLSX.writeFile(wb, filename);
  }

  var wb;
  var ws;

  function saveReport(sheetName, slapName) {
    window.XLSX.utils.book_append_sheet(wb, ws, slapName);
    window.XLSX.writeFile(wb, sheetName + '.xlsx');
  }

  function fillReport(reportData) {
    wb = window.XLSX.utils.book_new();
    ws = window.XLSX.utils.aoa_to_sheet([]);
    var titles = Object.keys(reportData[0]);

    window.XLSX.utils.sheet_add_aoa(ws, [titles], { origin: 'A1' });

    titles.forEach(function(title, index) {
      var cellRef = window.XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellRef]) {
        ws[cellRef] = {t: 's', v: title};
      }
      ws[cellRef].s = {
        fill: {
          fgColor: { rgb: '333333' }
        },
        font: {
          color: { rgb: 'FFFFFF' },
          bold: true
        }
      };
    });

    window.XLSX.utils.sheet_add_json(ws, reportData, { origin: 'A2', skipHeader: true });
  }

  loadAttributions();
}

module.exports = AdminEarningsController;
