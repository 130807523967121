var agoraRTC = require('agora-rtc-sdk-ng');
agoraRTC.setLogLevel(4);
var StreamChat = require('stream-chat').StreamChat;
var _ = require('lodash-core');

/* globals Promise */
/* jshint maxparams: 18 */

// @ngInject
function ShowController(
  $scope,
  $rootScope,
  $state,
  $uibModal,
  $location,
  sessionStorageService,
  localStorageService,
  config,
  $stateParams,
  showService,
  platformService,
  modalShowService,
  influencerService,
  promoService,
  productService,
  cartService,
  userService,
  translationService
) {
  var showId = $state.params.show || $scope.showId;

  var show;
  var streamer1;
  var chatChannel;
  var intervalScheduledTime;
  var intervalPostedVideoTime;
  var intervalShowStatusChecker;
  var intervalStickyProducts;
  var intervalOpacityControls;
  var intervalZendeskChat;
  var zendeskChatTry = 0;
  var intervalShopRunner;
  var chatClient;
  var productsAddedToCartToDisplay = [];
  var mousedown = false;
  var mouseTarget = document.getElementById('mouseTarget');
  var progressContainer = document.querySelector('.progress-container');
  var opacityControlsTime = 2;

  $scope.isLoggedIn = userService.isLoggedIn;

  // load show at open it
  if (($rootScope.modalShow && $rootScope.modalShow.status === 'opened') || $scope.draggable) {
    if (!$scope.draggable && $rootScope.floatingShowOpened) {
      $rootScope.floatingShowOpened = false;
    }

    $rootScope.videoMuted = $rootScope.videoMuted === undefined ? true : $rootScope.videoMuted;
    $scope.timing = '00:00';
    loadShow();
    loadShopRunnerBanners();
  }

  $rootScope.$watch('floatingShowOpened', function() {
    if ($scope.draggable && !$rootScope.floatingShowOpened) {
      $scope.closeFloatingShow();
    }
  });

  function removeVideoControls() {
    setTimeout(function() {
      var video = document.getElementById('pre-recorded');
      if (video) {
        video.removeAttribute('controls');
      }
    }, 1000);
  }

  $scope.opacityControlsResetTime = function() {
    if (opacityControlsTime !== 2) {
      opacityControlsTime = 2;
      mouseTarget.classList.remove('opacity-module');
      progressContainer.classList.remove('opacity-module');
    }
  };

  function opacityControlsStart() {
    if (!intervalOpacityControls && !$scope.draggable) {
      intervalOpacityControls = setInterval(function() {
        if (opacityControlsTime > 0) {
          opacityControlsTime--;
        }
        if (opacityControlsTime === 0) {
          mouseTarget.classList.add('opacity-module');
          progressContainer.classList.add('opacity-module');
        } else if (opacityControlsTime > 0) {
          mouseTarget.classList.remove('opacity-module');
          progressContainer.classList.remove('opacity-module');
        }
      }, 1000);
    }
  }

  function loadShopRunnerBanners() {
    if ($rootScope.featureFlags.shopRunner.enabled) {
      var tries = 100;
      intervalShopRunner = setInterval(function() {
        /*jshint camelcase: false */
        if (window.sr_updateMessages) {
          window.sr_updateMessages();
        }
        if (tries === 0 || window.sr_updateMessages) {
          clearInterval(intervalShopRunner);
        }
        tries--;
      }, 100);
    }
  }

  $scope.$watch(
    function() {
      return $scope.isLoggedIn();
    },
    function() {
      if ($scope.isShowLoaded()) {
        if (chatChannel) {
          chatClient.disconnectUser().then(function() {
            initializeChat();
          });
        }
      }
      loadLike();
    }
  );

  function loadLike() {
    if ($scope.isLoggedIn()) {
      if (userService.getUser().likedShows && _.map(userService.getUser().likedShows, 'showId').includes(showId)) {
        $scope.userLike = true;
      }
    }
  }

  if ($rootScope.modalShow) {
    $rootScope.modalShow.close = function() {
      unloadShow();
    };
  }

  $scope.closeFloatingShow = function() {
    unloadShow();
    $scope.closeShow();
  };

  $scope.closeModalShow = function() {
    unloadShow();
    modalShowService.closeShow();
  };

  function unloadShow() {
    if ($scope.leaveChannel) {
      $scope.leaveChannel();
    }
    if (show && (show.status === 'live' || show.status === 'posted')) {
      $rootScope.floatingShowOpened = true;
      $rootScope.floatingSectionName = $rootScope.modalShow ? $rootScope.modalShow.sectionName : null;
    }
    $rootScope.showId = showId;
    chatClient = StreamChat.getInstance(config.streamChat.apiKey);
    chatClient.disconnectUser();
    clearInterval(intervalScheduledTime);
    clearInterval(intervalPostedVideoTime);
    clearInterval(intervalShowStatusChecker);
    clearInterval(intervalStickyProducts);
    clearInterval(intervalOpacityControls);
    clearInterval(intervalShopRunner);
  }

  $scope.getStickyProductBackground = function() {
    if ($scope.productToShow) {
      return {
        background: 'url(' + $scope.getProductImage($scope.productToShow.image, { width: 150 }) + ')',
        'background-size': 'cover'
      };
    }
  };

  $scope.quickView = function(product) {
    $scope.quickViewStyleId = product.styleId;
    $scope.quickViewTrigger = 'show';
    if (window.hj) {
      window.hj('event', 'the-mile-open-product-quick-view-click');
    }
    if (!$state.params.productName) {
      $scope.instance = $uibModal.open({
        templateUrl: '/views/partials/modals/product-quick-view.html',
        windowClass: 'product-quick-view-modal',
        size: 'lg',
        scope: $scope
      });
    } else {
      setTimeout(function () {
        $state.go('root.product.variant', {
          brand: product.brand.id,
          productName: product.slug,
          id: product.styleId,
          comingfrom: 'show-' + showId,
          color: undefined,
          size: undefined
        }, { location: 'replace' });
      }, 100);
      $scope.closeModalShow();
    }
  };

  $scope.getProductImage = function(imageUrl, options) {
    return productService.getImageUrl(imageUrl, options);
  };

  $scope.toggleInfluencerCollection = function() {
    $scope.showInfluencerCollection = !$scope.showInfluencerCollection;
  };

  function loadShow() {
    loadLike();
    Promise.all([showService.getById(showId), showService.getAudienceToken(showId)]).then(function(responses) {
      show = responses[0];
      promoService.getByShowId(showId).then(function(promo) {
        if (promo && promo.code) {
          $scope.promo = promo;
          $scope.copyPromo = function() {
            navigator.clipboard
              .writeText($scope.promo.code)
              .then(function() {
                window.alert('Promo code copied');
              })
              .catch(function(error) {
                console.error('Unable to copy the promo code: ', error);
              });
          };
        }
      });
      promoService.getByInfluencerId(show.influencerId).then(function(promo) {
        if (promo && promo.code) {
          $scope.promo = promo;
          $scope.copyPromo = function() {
            navigator.clipboard
              .writeText($scope.promo.code)
              .then(function() {
                window.alert('Promo code copied');
              })
              .catch(function(error) {
                console.error('Unable to copy the promo code: ', error);
              });
          };
        }
      });
      $scope.showTitle = show.title;
      $scope.showStatus = show.status;
      $scope.showLikes = show.likes;
      $scope.showEvents = show.events;
      $scope.showViews = show.views;
      $scope.thumbnail =
        (show.s3VideoThumbnails && show.s3VideoThumbnails.length && show.s3VideoThumbnails[0]) ||
        (show.s3RecordedShowThumbnails && show.s3RecordedShowThumbnails.length && show.s3RecordedShowThumbnails[0]) ||
        '/img/bg-video-profile.gif';

      influencerService.getById(show.influencerId).then(function(influencer) {
        $scope.influencer = influencer;
        $scope.influencer.name = influencer.username || influencer.preferredName;
      });

      if (show.schedule && show.status === 'scheduled') {
        $scope.showDate = new Date(show.schedule);
        $scope.calculateShowDate();
        intervalScheduledTime = setInterval(function() {
          $scope.calculateShowDate();
          updateView();
        }, 1000);
        loadAgoraVideo();
      } else {
        loadProducts(show.showProducts);
        if ((show.s3Video || show.s3RecordedShow) && show.status === 'posted') {
          $scope.postedVideoUrl = show.s3RecordedShow || show.s3Video;
          $scope.hasCC = false;
          $scope.postedShowLoaded = true;
          $scope.showViews += 3;
          showService.update(showId, { $inc: { views: 3 } });
          initializeChat();
          loadPostedVideo();
          removeVideoControls();
        }

        if (show.status === 'live' || isTestingShow()) {
          var audienceToken = responses[1].token;
          var agoraUid = responses[1].uid;
          var videoOptions = {
            appId: config.agora.appId,
            channel: showId,
            uid: agoraUid,
            role: 'audience',
            token: audienceToken
          };
          if (!$scope.isShowLoaded()) {
            $scope.liveShowLoaded = true;
            if (show.status === 'live') {
              $scope.showViews += 1;
              showService.update(showId, { $inc: { views: 1 } });
            }
            loadAgoraVideo(videoOptions, show.showProducts);
            initializeChat();
          }
        }
      }
    });
  }

  function isTestingShow() {
    return show.status === 'drafted' && show.test === true;
  }

  function loadAgoraVideo(videoOptions, showProducts) {
    var rtc = {
      localAudioTrack: null,
      localVideoTrack: null,
      client: null
    };
    var clientRoleOptions = {
      level: 1
    };

    rtc.client = agoraRTC.createClient({
      mode: 'live',
      codec: 'vp8'
    });

    $scope.$watch(
      function() {
        return streamer1 && streamer1.videoTrack && streamer1.videoTrack.isPlaying;
      },
      function() {
        if (!intervalShowStatusChecker) {
          intervalShowStatusChecker = setInterval(function() {
            updateShowData();
          }, 1000);
        }
        if (streamer1 && streamer1.videoTrack && !streamer1.videoTrack.isPlaying) {
          if (!intervalShowStatusChecker) {
            intervalShowStatusChecker = setInterval(function() {
              updateShowData();
            }, 1000);
          }
        }
      }
    );

    // Join Show
    rtc.client.setClientRole(videoOptions.role, clientRoleOptions);
    rtc.client.join(videoOptions.appId, videoOptions.channel, videoOptions.token, videoOptions.uid);
    $scope.leaveChannel = function() {
      rtc.client.leave();
    };
    rtc.client.on('user-published', function(user, mediaType) {
      rtc.client.subscribe(user, mediaType).then(function() {
        // If the subscribed track is video.
        if (mediaType === 'video') {
          // Initialize show elements
          if (!$scope.showStarted) {
            $scope.showStarted = true;
            // initializeCounter();
            presentProducts(showProducts);
          }
          setTimeout(function() {
            playTrack(user, mediaType);
          }, 500);
        }
      });
    });

    function playTrack(streamer, mediaType) {
      // If the subscribed track is audio.
      // Play the audio track. No need to pass any DOM element.

      if (mediaType === 'video') {
        var primaryVideoContainer = document.getElementById('primary-video-container');

        streamer1 = streamer;
        streamer1.videoTrack.play(primaryVideoContainer);

        updateView();
      }

      if (streamer.audioTrack) {
        if ($rootScope.videoMuted) {
          streamer.audioTrack.stop();
        } else {
          streamer.audioTrack.play();
        }
      }
    }
  }

  function loadProducts(productsToLoad) {
    var styleIds = _.map(productsToLoad, 'styleId');
    productService.getByStyleIds(styleIds).then(function(loadedProducts) {
      $scope.influencerCollection = loadedProducts.items;
      if (!$scope.quickViewTrigger) {
        saveWatchedShowOnSession();
      }
    });
  }

  function presentProducts(productsToPresent) {
    var duration = 10000;
    var productToShow = 0;
    presentProduct(productsToPresent[0]);
    intervalStickyProducts = setInterval(function() {
      presentProduct(productsToPresent[productToShow]);
      if (productToShow === productsToPresent.length - 1) {
        productToShow = 0;
      } else {
        productToShow++;
      }
    }, duration);
  }

  function presentProduct(product) {
    $scope.productToShow = _.find($scope.influencerCollection, {
      id: product.productId,
      styleId: product.styleId
    });
    updateView();
  }

  function translateMessagesHistory() {
    $scope.chatMessages.forEach(function(message) {
      translationService.translate(message.text).then(function(text) {
        message.text = text;
      });
    });
  }

  function refreshChatMessages() {
    chatChannel.query({}).then(function(searchResponse) {
      var previousMessages = searchResponse.messages.map(function(message) {
        return {
          username: message.user.name,
          text: message.message || message.text,
          /*jshint camelcase: false */
          timestamp: message.created_at
        };
      });
      if (previousMessages && previousMessages.length) {
        $scope.chatMessages = previousMessages;
        translateMessagesHistory();
        updateView();
        scrollToLastMessage();
      }
    });
  }

  function scrollToLastMessage() {
    if ($rootScope.modalShow) {
      setTimeout(
        function() {
          var scroller = document.getElementById('message-container');
          if (scroller) {
            scroller.scrollTop = scroller.scrollHeight;
          }
        },
        0,
        false
      );
    }
  }

  function initializeChat() {
    if ($rootScope.modalShow) {
      $scope.chatMessages = [];
      var user = userService.getUser();

      showService.getChatToken(showId).then(function(response) {
        chatClient = StreamChat.getInstance(config.streamChat.apiKey);
        $scope.uid = response.uid;
        var userSaved = sessionStorageService.getItem('userChatName');
        if (user && !user.anonymous) {
          userSaved = user.firstName;
        } else if (!userSaved) {
          userSaved =  'Guest ' + Math.floor(Math.random() * 50) + 1;
        }
        chatClient
          .connectUser(
            {
              id: response.uid,
              name: userSaved
            },
            response.token
          )
          .then(function() {
            chatChannel = chatClient.channel('livestream', showId);
            chatChannel.query({}).then(function(searchResponse) {
              var previousMessages = searchResponse.messages.map(function(message) {
                return {
                  username: message.user.name,
                  text: message.message || message.text,
                  /*jshint camelcase: false */
                  timestamp: message.created_at
                };
              });
              $scope.chatMessages = $scope.chatMessages.concat(previousMessages);

              $scope.changeChatName = function(evt) {
                if (evt && evt.which !== 13) {
                  return;
                }
                $scope.chatNameSaved = true;
                if ($scope.userChatName && $scope.userChatName !== userSaved) {
                  sessionStorageService.setItem('userChatName', $scope.userChatName);
                  chatClient
                    .partialUpdateUser({ id: $scope.uid, set: { name: $scope.userChatName } })
                    .then(function() {});
                }
              };

              chatChannel.watch();
              updateView();
              scrollToLastMessage();

              chatChannel.on('message.new', function(event) {
                $scope.chatMessages.push({
                  username: event.user.name,
                  text: event.message.message || event.message.text,
                  /*jshint camelcase: false */
                  timestamp: event.received_at
                });
                refreshChatMessages();
              });

              $scope.sendMessage = function(chatMessage) {
                if (chatMessage && chatMessage !== '') {
                  chatChannel.sendMessage({ message: chatMessage });
                  $scope.chatMessage = '';
                }
              };

              $scope.isChatAlive = true;

              setTimeout(function() {
                if ($scope.showStatus === 'live') {
                  $scope.sendMessage('just joined');
                }
              }, 0);
            });
          })
          .finally(function() {
            if ($rootScope.modalShow && $rootScope.modalShow.status === 'closed') {
              chatClient.disconnectUser();
            }
          });
      });
    }
  }

  // scheduled shows
  $scope.isScheduledShow = function() {
    return show && show.status === 'scheduled';
  };

  $scope.calculateShowDate = function() {
    var todayDate = new Date();
    if ($scope.showDate.getTime() > todayDate.getTime()) {
      todayDate.setDate(todayDate.getDate());
      var time = $scope.showDate.getTime() - todayDate.getTime();
      var dayTime = Math.trunc(time % 86400000);
      var seconds = String(Math.trunc((dayTime / 1000) % 60)).padStart(2, '0');
      var minutes = String(Math.trunc((dayTime / 60000) % 60)).padStart(2, '0');
      var hours = String(Math.trunc((dayTime / 3600000) % 24)).padStart(2, '0');
      $scope.dd1 = Math.trunc(time / 86400000);
      $scope.hh1 = hours[0];
      $scope.hh2 = hours[1];
      $scope.mm1 = minutes[0];
      $scope.mm2 = minutes[1];
      $scope.ss1 = seconds[0];
      $scope.ss2 = seconds[1];
    } else {
      $scope.dd1 = 0;
      $scope.hh1 = 0;
      $scope.hh2 = 0;
      $scope.mm1 = 0;
      $scope.mm2 = 0;
      $scope.ss1 = 0;
      $scope.ss2 = 0;
      if (intervalScheduledTime) {
        intervalScheduledTime = clearInterval(intervalScheduledTime);
      }
    }
  };

  // Live Show
  $scope.isLiveVideo = function() {
    if ($scope.showStatus) {
      return $scope.showStatus === 'live';
    }
  };

  $scope.sendMessageWithKey = function($event) {
    if ($event.which === 13 && $scope.sendMessage) {
      $scope.sendMessage($scope.chatMessage);
    }
  };

  // Posted Show
  $scope.$watch('myVideo.paused', function() {
    if ($scope.isPlayingPosted()) {
      trackTime();
      if (!$scope.postedVideoStarted) {
        $scope.postedVideoStarted = true;
      }
    } else {
      intervalPostedVideoTime = clearInterval(intervalPostedVideoTime);
    }
  });

  $scope.timePosted = function() {
    if ($scope.myVideo) {
      return $scope.myVideo.currentTime;
    }
  };

  $scope.isPlayingPosted = function() {
    return $scope.myVideo && !$scope.myVideo.paused;
  };

  $scope.playPosted = function() {
    $scope.myVideo.play();
    if ($rootScope.videoMuted) {
      $scope.myVideo.muted = $rootScope.videoMuted;
    }
    opacityControlsStart();
  };

  $scope.pausePosted = function() {
    $scope.myVideo.pause();
    $scope.opacityControlsResetTime();
    intervalOpacityControls = clearInterval(intervalOpacityControls);
  };

  $scope.showHideCC = function () {
    $scope.cc = !$scope.cc;
  };

  $scope.muteOrUnmute = function() {
    $rootScope.videoMuted = !$rootScope.videoMuted;
    if ($scope.myVideo && $scope.postedVideoStarted) {
      $scope.myVideo.muted = $rootScope.videoMuted;
    } else {
      if (streamer1 && streamer1.audioTrack) {
        if ($rootScope.videoMuted) {
          streamer1.audioTrack.stop();
        } else {
          streamer1.audioTrack.play();
        }
      }
    }
  };

  $scope.isPostedVideo = function() {
    return $scope.showStatus && $scope.showStatus === 'posted';
  };

  function trackProgressBar() {
    var progress = document.querySelector('.progress');
    if (progress) {
      progress.addEventListener('click', scrub);
      progress.addEventListener('mousedown', function() {
        mousedown = true;
      });
      progress.addEventListener('mousemove', function(e) {
        return mousedown && scrub(e);
      });
      progress.addEventListener('mouseup', function() {
        mousedown = false;
      });
    }
  }

  function scrub(e) {
    var progress = document.querySelector('.progress');
    $scope.myVideo.currentTime = (e.offsetX / progress.offsetWidth) * $scope.myVideo.duration;
    $scope.flexBasis = ($scope.myVideo.currentTime / $scope.myVideo.duration) * 100 + '%';
  }

  function trackTime() {
    if (!intervalPostedVideoTime) {
      intervalPostedVideoTime = setInterval(function() {
        if ($scope.myVideo) {
          $rootScope.currentPostedVideo = {
            showId: showId,
            time: $scope.myVideo.currentTime
          };
          $scope.flexBasis = ($scope.myVideo.currentTime / $scope.myVideo.duration) * 100 + '%';
          $scope.timing =
            String(Math.trunc($scope.myVideo.currentTime / 60)).padStart(2, '0') +
            ':' +
            String(Math.trunc($scope.myVideo.currentTime % 60)).padStart(2, '0');
          updateView();
        }
      }, 100);
    }
  }

  function loadPostedVideo() {
    if ($scope.isPostedVideo() && !$scope.myVideo) {
      opacityControlsStart();
      setTimeout(function() {
        $scope.myVideo = window.document.getElementById('pre-recorded');
        if ($scope.myVideo) {
          if ($rootScope.currentPostedVideo && $rootScope.currentPostedVideo.showId === showId) {
            $scope.myVideo.currentTime = $rootScope.currentPostedVideo.time;
          }
          $scope.myVideo.play();
          $scope.myVideo.muted = $rootScope.videoMuted;
          $scope.duration =
            String(Math.trunc($scope.myVideo.duration / 60)).padStart(2, '0') +
            ':' +
            String(Math.trunc($scope.myVideo.duration % 60)).padStart(2, '0');
          trackTime();
          presentProducts(show.showProducts);
          if ($rootScope.modalShow) {
            trackProgressBar();
          }
        }
      }, 500);
    }
  }

  // Common Actions
  $scope.$watch('showStatus', function(newStatus, oldStatus) {
    if (oldStatus === 'scheduled' && newStatus === 'live') {
      loadShow();
      intervalShowStatusChecker = clearInterval(intervalShowStatusChecker);
    }
    if (oldStatus === 'scheduled' && newStatus === 'posted') {
      $scope.isFinalState = true;
      $scope.productToShow = undefined;
      intervalShowStatusChecker = clearInterval(intervalShowStatusChecker);
      intervalStickyProducts = clearInterval(intervalStickyProducts);
    }
    if (oldStatus === 'live' && newStatus === 'posted') {
      $scope.isFinalState = true;
      $scope.isChatAlive = true;
      setTimeout(function() {
        $scope.isChatAlive = false;
      }, 900000);
      $scope.productToShow = undefined;
      intervalShowStatusChecker = clearInterval(intervalShowStatusChecker);
      intervalStickyProducts = clearInterval(intervalStickyProducts);
    }
  });

  $scope.openCartModal = function() {
    cartService.setStep('cart');
    $rootScope.cartStep = cartService.getStep;
    $scope.instanceCart = $uibModal.open({
      templateUrl: '/views/partials/modals/the-mile-checkout.html',
      windowClass: 'show-quick-view-modal',
      scope: $scope
    });
    $scope.closeButton = function() {
      if (!$rootScope.placingOrder) {
        $rootScope.confirmation = false;
        $rootScope.preConfirmation = false;
        $scope.instanceCart.dismiss();
        delete $scope.instanceCart;
      }
    };
  };

  $scope.$watch('timing', function() {
    if ($scope.myVideo && Math.trunc($scope.myVideo.currentTime) === Math.trunc($scope.myVideo.duration)) {
      setTimeout(function() {
        $scope.isFinalState = true;
      }, 500);
    }
  });

  $scope.like = function() {
    if (!$scope.userLike) {
      updateShowData().then(function() {
        showService.update(showId, { $inc: { likes: 1 } });
        userService.saveShowLike(showId);
        $scope.showLikes += 1;
      });
      $scope.userLike = true;
    }
  };

  $scope.playAgain = function() {
    $scope.isFinalState = false;
    if ($scope.isLiveVideo()) {
      updateShowData().then(function() {
        loadShow();
      });
    } else if ($scope.isPostedVideo()) {
      if ($scope.isChatAlive) {
        chatClient.disconnect();
      }
      if (!$scope.postedShowLoaded) {
        loadShow();
      } else {
        $scope.myVideo.currentTime = 0;
        $scope.myVideo.play();
        presentProducts(show.showProducts);
      }
    }
  };

  $scope.isShowLoaded = function() {
    return (
      $scope.liveShowLoaded || $scope.postedShowLoaded || (show && show.status === 'scheduled' && $scope.isChatAlive)
    );
  };

  // Common Functions
  function updateShowData() {
    return showService.getById(showId).then(function(show) {
      $scope.showStatus = show.status;
      $scope.showLikes = show.likes;
      $scope.showViews = show.views;
      checkNewEvents(show.events);
    });
  }

  function checkNewEvents(newEvents) {
    var events = $scope.showEvents;
    if (newEvents && (!events || events.length !== newEvents.length)) {
      _.forEach(newEvents, function(newEvent) {
        if (newEvent.type === 'addedToCart' && _.every(events, function(event) {
          return event.user.name !== newEvent.user.name || event.details.styleId !== newEvent.details.styleId ||
            event.details.hash !== newEvent.details.hash;
        })) {
          productsAddedToCartToDisplay.push(newEvent);
          $scope.showEvents.push(newEvent);
          showProductAddedToCart();
        }
      });
      return true;
    }
  }
  function showProductAddedToCart() {
    var displayProduct = productsAddedToCartToDisplay.shift();
    if (displayProduct) {
      setTimeout(function() {
        $scope.displayProductAddedToBag = displayProduct;

        $scope.userWhoAddedToCart = $scope.displayProductAddedToBag.user.name;
        if ($scope.displayProductAddedToBag.user.city) {
          $scope.userWhoAddedToCart += ' from ' + $scope.displayProductAddedToBag.user.city;
        }
        $scope.userWhoAddedToCart += ' added ';
        var product = displayProduct.details;
        $scope.productAddedToCart = product.title;
        setTimeout(function() {
          $scope.displayProductAddedToBag = undefined;
          if (productsAddedToCartToDisplay.length !== 0) {
            showProductAddedToCart();
          }
        }, 4000);
      }, 2000);
    }
  }

  $scope.copyShareLink = function() {
    $scope.linkOnClipboard = true;
    setTimeout(function() {
      $scope.linkOnClipboard = false;
    }, 2000);
    navigator.clipboard.writeText($location.absUrl());
  };

  function updateView() {
    if ($scope.$root) {
      if ($scope.$root.$$phase && ['$digest', '$apply'].includes($scope.$root.$$phase)) {
        setTimeout(function() {
          updateView();
        }, 500);
      } else {
        $scope.$digest();
      }
    }
  }

  // $rootScope.noPageFooter = platformService.isMobile();

  function saveWatchedShowOnSession() {
    var watchedShows = parseShowsWatched(localStorageService.getItem('watched_shows'));
    addWatchedShow(watchedShows);
    localStorageService.setItem('watched_shows', JSON.stringify(watchedShows));
    showService.syncInteractionsContent();
  }

  function addWatchedShow(watchedShows) {
    var watchedShow = _.find(watchedShows, function (wShowSaved) {
      return wShowSaved.showId === showId;
    });

    if (!watchedShow) {
      watchedShow = {};
      watchedShow.showId = showId;
      watchedShow.isReel = show.isReel;
      watchedShows.push(watchedShow);
    }

    watchedShow.watchedAt = new Date();
    watchedShow.status = show.status;
    watchedShow.influencerId = $scope.influencer.id;
  }

  function parseShowsWatched(watchedShows) {
    if (watchedShows) {
      watchedShows = JSON.parse(watchedShows);
    }
    if (watchedShows && Array.isArray(watchedShows) && watchedShows.length) {
      return _.uniqBy(_.filter(watchedShows, function (wShow) {
        return wShow.influencerId;
      }), 'showId');
    } else {
      return [];
    }
  }

  $scope.goToInfluencerPage = function() {
    var influencerPage = _.find(config.featureFlags.theMileInfo.influencersPages, function(page) {
      return page.influencerId === $scope.influencer.id;
    });
    if (influencerPage) {
      $scope.closeModalShow();
      $state.go('root.influencer', { influencer: influencerPage.name });
    }
  };

  function hideZendeskChat() {
    if (zendeskChatTry++ >= 20) {
      clearInterval(intervalZendeskChat);
      return;
    }
    var element = document.getElementById('launcher');
    if (element && element.style.display !== 'none') {
      element.style.display = 'none';
      clearInterval(intervalZendeskChat);
    }
  }

  if (!$rootScope.isDesktopView()) {
    setInterval(function() {
      hideZendeskChat();
    }, 500);
  }
}

module.exports = ShowController;
