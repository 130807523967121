var _ = require('lodash-core');

// @ngInject
function AccountSummaryController($scope, userService, $rootScope, productService, formsService) {
  $scope.user = $rootScope.getUser();
  $scope.phone = { hasPhone: !!$scope.user.phoneNumber, editing: false, deleting: false };
  $scope.birthday = { hasBirthday: !!$scope.user.birthday, editing: false, deleting: false };
  $scope.name = { hasName: !!($scope.user.firstName && $scope.user.lastName) };
  $scope.orders = { loading: true };
  $scope.billingOptions = { loading: true };
  var phoneFormPromise = $scope.phoneFormPromise = formsService.promiseHandler();
  var birthdayFormPromise = $scope.birthdayFormPromise = formsService.promiseHandler();
  var nameFormPromise = $scope.nameFormPromise = formsService.promiseHandler();

  var ordersPromise = userService.getOrders();
  ordersPromise.then(function(orders) {
    $scope.orders = _.sortBy(orders, function(order) { return -new Date(order.createdAt); });
  });

  userService.getBillingOptions().then(function(options) {
    $scope.billingOptions = { options: options || [] };
  });

  userService.getPhoneNumber().then(function() {
    $scope.user = userService.getUser();
    $scope.phone.hasPhone = !!$scope.user.phoneNumber;
    $scope.phone.editing = !$scope.user.phoneNumber;
  });

  $scope.requestUserEmailValidation = function() {
    $scope.user.emailValidationRequested = true;
    userService.requestEmailValidation();
  };

  $scope.popularProducts = productService.getPopularProducts(4).lazyArray();

  $scope.phoneEditMode = function() {
    return $scope.phone.editing || !$scope.phone.hasPhone;
  };

  $scope.editPhoneNumber = function() {
    $scope.phone.editing = true;
  };

  $scope.setPhoneNumber = function() {
    // Hide the invalid message until we know better
    $scope.phone.invalid = false;

    if (!phoneFormPromise.validate($scope.phone.form)) {
      $scope.phone.invalid = true;
      return;
    }

    if (!$scope.user.newPhoneNumber) {
      $scope.deletePhoneNumber();
      return;
    }

    var usCode = '+1';
    var phoneNumber = usCode + $scope.user.newPhoneNumber.replace(/^\+1/, '');
    phoneFormPromise.handle(userService.setPhoneNumber(phoneNumber)
      .then(function() {
        $scope.user.phoneNumber = phoneNumber;
        $scope.phone.hasPhone = true;
        $scope.phone.editing = false;
      }, function(err) {
        $scope.phone.editing = true;
        $scope.phone.invalid = true;
        throw err;
      })
    );
  };

  userService.getBirthday().then(function() {
    $scope.user = userService.getUser();
    $scope.birthday.hasBirthday = !!$scope.user.birthday;
    $scope.birthday.editing = !$scope.user.birthday;
  });

  $scope.editBirthday = function() {
    $scope.birthday.editing = true;
    if ($scope.user.birthday) {
      $scope.user.newBirthday = _ISOStringToDate($scope.user.birthday);
    }
  };

  $scope.birthdayEditMode = function() {
    return $scope.birthday.editing || !$scope.birthday.hasBirthday;
  };


  $scope.setBirthday = function() {
    $scope.birthday.invalid = false;

    if (!birthdayFormPromise.validate($scope.birthday.form)) {
      $scope.birthday.invalid = true;
      return;
    }

    if ($scope.user.newBirthday) {
      if (!$scope.user.newBirthday.day && !$scope.user.newBirthday.month && !$scope.user.newBirthday.year) {
        $scope.deleteBirthday();
        return;
      } else if (!$scope.user.newBirthday.day || !$scope.user.newBirthday.month || !$scope.user.newBirthday.year) {
        $scope.birthday.invalid = true;
        return;
      }
    } else {
      $scope.deleteBirthday();
      return;
    }

    var birthday = _dateToISOString($scope.user.newBirthday);

    birthdayFormPromise.handle(userService.setBirthday(birthday)
      .then(function() {
        delete $scope.user.newBirthday;
        $scope.user.birthday = birthday;
        $scope.birthday.hasBirthday = true;
        $scope.birthday.editing = false;
      }, function(err) {
        $scope.birthday.editing = true;
        $scope.birthday.invalid = true;
        throw err;
      })
    );
  };

  userService.getName().then(function() {
    $scope.user = userService.getUser();
    $scope.name.hasName = !!($scope.user.firstName && $scope.user.lastName);
  });

  $scope.setName = function() {
    $scope.name.invalid = false;

    if (!nameFormPromise.validate($scope.name.form) ||
      !$scope.user.newName ||
      !$scope.user.newName.firstName ||
      !$scope.user.newName.lastName) {
      $scope.name.invalid = true;
      return;
    }

    nameFormPromise.handle(userService.setName($scope.user.newName)
      .then(function() {
        $scope.user.firstName = $scope.user.newName.firstName;
        $scope.user.lastName = $scope.user.newName.lastName;
        $scope.name.hasName = true;
      }, function(err) {
        $scope.name.invalid = true;
        throw err;
      })
    );
  };

  $scope.deletePhoneNumber = function() {
    $scope.phone.deleting = true;
    phoneFormPromise.handle(userService.deletePhoneNumber()
      .then(function() {
        delete $scope.user.phoneNumber;
        $scope.phone.hasPhone = false;
        $scope.phone.editing = false;
        $scope.phone.deleting = false;
      })
      .catch(function(err) {
        console.error(err);
      })
    );
  };

  $scope.deleteBirthday = function() {
    $scope.birthday.deleting = true;
    birthdayFormPromise.handle(userService.deleteBirthday()
      .then(function() {
        delete $scope.user.birthday;
        delete $scope.user.newBirthday;
        $scope.birthday.hasBirthday = false;
        $scope.birthday.editing = false;
        $scope.birthday.deleting = false;
      })
      .catch(function(err) {
        console.error(err);
      })
    );
  };

  $scope.getFormattedBirthday = function() {
    var birthday = $scope.user.birthday;
    return new Date(birthday).toUTCString().substring(4, 16);
  };

  $scope.getBirthdayButtonText = function() {
    if (birthdayFormPromise.sending) {
      if ($scope.birthday.deleting) {
        return 'Removing...';
      } else {
        return 'Saving...';
      }
    } else {
      if (!$scope.birthday.hasBirthday) {
        return 'Add';
      } else {
        return 'Edit';
      }
    }
  };

  $scope.getPhoneButtonText = function() {
    if (phoneFormPromise.sending) {
      if ($scope.phone.deleting) {
        return 'Removing...';
      } else {
        return 'Saving...';
      }
    } else {
      if (!$scope.phone.hasPhone) {
        return 'Add';
      } else {
        return 'Edit';
      }
    }
  };

  $scope.checkBDayYear = function() {
    if ($scope.birthday.form.birthdayYear.$viewValue) {
      if ($scope.birthday.form.birthdayYear.$viewValue < 0) {
        $scope.user.newBirthday.year = 1900;
        $scope.birthday.invalid = false;
      } else if ($scope.birthday.form.birthdayYear.$viewValue > 9999) {
        $scope.user.newBirthday.year = parseInt($scope.birthday.form.birthdayYear.$viewValue.substring(0, 4));
        if ($scope.birthday.form.birthdayYear.$viewValue > $scope.getMaxLegalYear()) {
          $scope.birthday.invalid = true;
        }
      } else if ($scope.birthday.form.birthdayYear.$viewValue > $scope.getMaxLegalYear() ||
        $scope.birthday.form.birthdayYear.$viewValue < 1900) {
        $scope.birthday.invalid = true;
      } else {
        $scope.birthday.invalid = false;
      }
    }
  };

  $scope.checkBDayMonth = function() {
    if ($scope.birthday.form.birthdayMonth.$viewValue) {
      if ($scope.birthday.form.birthdayMonth.$viewValue < 0) {
        $scope.user.newBirthday.month = 0;
        $scope.birthday.invalid = false;
      } else if ($scope.birthday.form.birthdayMonth.$viewValue > 99) {
        $scope.user.newBirthday.month = parseInt($scope.birthday.form.birthdayMonth.$viewValue.substring(0, 2));
        if ($scope.birthday.form.birthdayMonth.$viewValue > 12) {
          $scope.birthday.invalid = true;
        }
      } else if ($scope.birthday.form.birthdayMonth.$viewValue > 12) {
        $scope.birthday.invalid = true;
      } else {
        $scope.birthday.invalid = false;
      }
    }
  };

  $scope.checkBDayDay = function() {
    if ($scope.birthday.form.birthdayDay.$viewValue) {
      if ($scope.birthday.form.birthdayDay.$viewValue < 0) {
        $scope.user.newBirthday.day = 0;
        $scope.birthday.invalid = false;
      } else if ($scope.birthday.form.birthdayDay.$viewValue > 99) {
        $scope.user.newBirthday.day = parseInt($scope.birthday.form.birthdayDay.$viewValue.substring(0, 2));
        if ($scope.birthday.form.birthdayDay.$viewValue > 31) {
          $scope.birthday.invalid = true;
        }
      } else if ($scope.birthday.form.birthdayDay.$viewValue > 31) {
        $scope.birthday.invalid = true;
      } else {
        $scope.birthday.invalid = false;
      }
    }
  };

  $scope.getMaxLegalYear = function() {
    var legalAge = 18;
    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - legalAge);
    return maxDate.getFullYear();
  };

  function _dateToISOString(date) {
    var dd = ('0' + date.day).slice(-2);
    var mm = ('0' + date.month).slice(-2);
    var yyyy = date.year;
    return yyyy + '-' + mm + '-' + dd;
  }

  function _ISOStringToDate(string) {
    var array = string.split('-');
    return {
      day: parseInt(array[2]),
      month: parseInt(array[1]),
      year: parseInt(array[0])
    };
  }

}

module.exports = AccountSummaryController;

