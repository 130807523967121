// @ngInject
function BreadcrumbDirective() {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/breadcrumb.html',
    scope: {
      name: '&',
      link: '&',
      position: '&',
      separator: '@'
    }
  };
}

module.exports = BreadcrumbDirective;
