var _ = require('lodash-core');

// @ngInject
function AdminExperienceCodesController($scope, config, $state, $stateParams, formsService,
  loyaltyExperienceService) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  function clear() {
    $scope.data = {
    };

    formPromise.clear();
    formsService.focusFirstField();
  }

  clear();

  $scope.add = function() {
    var data = _.cloneDeep($scope.data);
    var params = {};
    if (data.newCodes) {
      params = {
        codes: data.newCodes.split(',')
      };
    }
    $scope.adding = false;
    formPromise.handle(loyaltyExperienceService.addExperienceCodes($stateParams.id, params).then(function() {
      delete $scope.adding;
      $scope.$emit('codesAdded');
    }));
  };

  $scope.beginAdd = function() {
    $scope.adding = true;
  };

  $scope.cancelAdd = function() {
    $scope.adding = false;
  };

  $scope.remove = function(code) {
    loyaltyExperienceService.removeExperienceCode($stateParams.id, code).then(function() {
      $scope.$emit('codeRemoved');
    });
  };

  $scope.getActiveCodes = function() {
    if (typeof $scope.experienceData.codes === 'undefined') {
      return [];
    }
    return $scope.experienceData.codes.filter(function(code) {
      return code.deleted !== true && code.redeemed !== true;
    });
  };

  $scope.getDeletedCodes = function() {
    if (typeof $scope.experienceData.codes === 'undefined') {
      return [];
    }
    return $scope.experienceData.codes.filter(function(code) {
      return code.deleted === true;
    });
  };

  $scope.getRedeemedCodes = function() {
    if (typeof $scope.experienceData.codes === 'undefined') {
      return [];
    }
    return $scope.experienceData.codes.filter(function(code) {
      return code.redeemed === true;
    });
  };

}

module.exports = AdminExperienceCodesController;
