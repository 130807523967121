// @ngInject
function ShopModalService($rootScope) {
  this.sendMessage = function(message) {
    if (this.isOnWidget()) {
      window.parent.postMessage(message, $rootScope.iframeUrl);
    }
  };
  this.closeShopModal = function() {
    this.sendMessage('closeOmModal');
  };
  this.goToCheckout = function() {
    this.sendMessage('checkout');
  };
  this.loaded = function() {
    this.sendMessage('loaded');
  };
  this.cartFeature = function() {
    return !!($rootScope.widgetConfig && $rootScope.widgetConfig.cart);
  };
  this.bagFlowFeature = function() {
    return !!($rootScope.widgetConfig && $rootScope.widgetConfig.bagFlow);
  };
  this.productAddedPDP = function() {
    this.sendMessage('productAdded:');
  };
  this.updateCart = function(items) {
    var qty = 0;
    items.forEach(function(item) {
      qty += item.quantity;
    });
    this.sendMessage('cartChange:' + qty);
  };
  this.isOnWidget = function() {
    return $rootScope.iframeUrl !== '';
  };
}

module.exports = ShopModalService;
