var _ = require('lodash-core');

// @ngInject
function AdminPartnersNexusController($scope, fulfillerService) {
  $scope.fetchPartners = function() {
    $scope.partners = [];

    var prom = fulfillerService.getAll();

    prom.then(function(data) {
      $scope.partnersList = _.sortBy(data, 'name');
      $scope.partners = $scope.partnersList;
    });
  };

  $scope.search = function() {
    if (!$scope.searchTerm) {
      $scope.partners = $scope.partnersList;
      return;
    }
    $scope.partners = _.filter($scope.partnersList, function(each) {
      return (
        each.id
          .trim()
          .toLowerCase()
          .indexOf($scope.searchTerm.trim().toLowerCase()) >= 0 ||
        _.find(each.brands, function(brand) {
          return (
            brand
              .replace('-', ' ')
              .trim()
              .toLowerCase()
              .indexOf($scope.searchTerm.trim().toLowerCase()) >= 0
          );
        })
      );
    });
  };

  $scope.fetchPartners();
}

module.exports = AdminPartnersNexusController;