
// @ngInject
function BrandLogoDirective($timeout, $parse, viewportService, brandService) {

  var linker = function($scope, element, attrs) {

    $scope.brandId = attrs.brandId ? $parse(attrs.brandId)($scope) : false;

    brandService.getBrand($scope.brandId).then(function(brand) {
      if (brand !== undefined && brand.store && brand.store !== true) {
        $scope.brandId = brand.store;
      }
    });

    $scope.fitToContent = attrs.fitToContent ? $parse(attrs.fitToContent)($scope) : false;
    $scope.logoColor = attrs.logoColor;
    $scope.classes = attrs.class;
    $scope.asset = $scope.$root.asset;
    $scope.ready = false;

    function getPositionForCurrentViewport() {
      return viewportService.chooseForCurrent({
        xs: attrs.positionXs,
        sm: attrs.positionSm,
        md: attrs.positionMg,
        else: attrs.positionLg || attrs.position
      });
    }

    function getPreserveAspectRatio(position) {
      if (!position) {
        return false;
      }

      var horizontalAlignment = 'xMid';
      if (position.indexOf('left') >= 0) {
        horizontalAlignment = 'xMin';
      } else if (position.indexOf('right') >= 0) {
        horizontalAlignment = 'xMax';
      }
      var verticalAlignment = 'YMid';
      if (position.indexOf('top') >= 0) {
        verticalAlignment = 'YMin';
      } else if (position.indexOf('bottom') >= 0) {
        verticalAlignment = 'YMax';
      }
      return horizontalAlignment + verticalAlignment;
    }

    function setPreserveAspectRatio(value) {
      if (!value) {
        return true;
      }
      var svg = $(element).find('svg')[0];
      if (svg) {
        svg.setAttribute('preserveAspectRatio', value);
        return true;
      }
      return false;
    }

    function fitToContent() {
      var svg = $(element).find('svg')[0];
      var totalWidth = 0;
      var totalHeight = 0;
      if (svg) {
        var children = svg.children;

        for (var i = 0; i < children.length; i++) {
          var child = children[i];

          if (child instanceof SVGPathElement) {
            var w = child.getBoundingClientRect().width;
            var h = child.getBoundingClientRect().height;
            if (w > totalWidth) { totalWidth = w; }
            if (h > totalHeight) { totalHeight = h; }
          } else if (child instanceof SVGGElement) {
            totalWidth += child.getBBox().width;
            totalHeight += child.getBBox().height;
          } else if (child instanceof HTMLElement) {
            totalWidth += child.offsetWidth;
            totalHeight += child.offsetHeight;
          }
        }

        // svg.setAttribute('viewBox', '0 0 ' + totalWidth + ' ' + totalHeight);
        svg.setAttribute('width', String(totalWidth * 1.2) || '0');
        svg.setAttribute('height', String(totalHeight * 1.5) || '0');
      }
    }

    function refresh() {
      var position = getPositionForCurrentViewport();
      var preserveAspectRatio = getPreserveAspectRatio(position);
      setPreserveAspectRatio(preserveAspectRatio);

      if ($scope.fitToContent) {
        fitToContent();
      }
    }

    // this method will be called once the content is loaded
    $scope.refresh = function() {
      refresh();
    };

    $scope.$on('viewportBreakpointChange', refresh);

    // init
    $timeout(function() {
      $scope.ready = true;
      $timeout(refresh, 1);
    }, 1);
  };

  return {
    link: linker,
    templateUrl: '/views/partials/brand-logo.html',
    restrict: 'E',
    scope: true
  };

}

module.exports = BrandLogoDirective;
