var _ = require('lodash-core');


// @ngInject
function PaymentService(config, userService, $rootScope, $timeout, apiService, preloadService, platformService) {

  var providers = ({"payment-providers":({"citcon":require("./payment-providers/citcon.js"),"giftCard":require("./payment-providers/giftCard.js"),"none":require("./payment-providers/none.js"),"paypal":require("./payment-providers/paypal.js"),"stripe":require("./payment-providers/stripe.js")})})['payment-providers'];

  var provider;

  this.loadProvider = function(withoutCache) {
    if (withoutCache) {
      return provider.loadWithoutCache();
    } else {
      return provider.load();
    }
  };

  this.providerForOrder = function(order) {
    var orderProvider = provider;
    if (order && order.billing && order.billing.paymentMethod &&
      order.billing.paymentMethod.paymentProvider) {
      orderProvider = this.getProviderByName(order.billing.paymentMethod.paymentProvider);
      if (!orderProvider) {
        throw new Error('payment provider not found: ' + order.billing.paymentMethod.paymentProvider);
      }
    }
    return orderProvider;
  };

  this.prepareOrder = function(order, validation) {
    return this.providerForOrder(order).prepareOrder(_.cloneDeep(order), validation);
  };

  this.prepareOrderToSaveOptions = function(order) {
    return this.providerForOrder(order).prepareOrderToSaveOptions(_.cloneDeep(order));
  };

  this.afterOrderPlaced = function(order) {
    var orderProvider = this.providerForOrder(order);
    if (orderProvider.afterOrderPlaced) {
      orderProvider.afterOrderPlaced(order);
    }
  };

  this.getProviderByName = _.memoize(function(name) {
    var cfg = _.cloneDeep(config.payment[name]);
    _.merge(cfg, config.payment.allProviders);
    return new providers[name](cfg, userService, $rootScope, $timeout, apiService, preloadService, platformService);
  });

  this.clearUserCheckoutData = function() {
    for (var name in providers) {
      var provider = this.getProviderByName(name);
      if (provider.clearUserCheckoutData) {
        provider.clearUserCheckoutData();
      }
    }
  };

  provider = this.getProviderByName(config.payment.providerName || 'none');
}

module.exports = PaymentService;
