// @ngInject
function TrackEmptyDirective() {

  return {
    restrict: 'A',
    link: function(scope, element) {
      var $elem = $(element[0]);
      var isEmpty;
      var isInitialized = false;

      function refresh() {
        var value = $elem.find('input,select,textarea').val();
        if (value === '' || value === null || value === undefined) {
          if (isEmpty === true) {
            return;
          }
          isEmpty = true;
          $elem.addClass('is-empty').removeClass('is-not-empty');
        } else {
          if (isEmpty === false) {
            return;
          }
          isEmpty = false;
          $elem.addClass('is-not-empty').removeClass('is-empty');
        }
        if (!isInitialized) {
          isInitialized = true;
          setTimeout(function() {
            $elem.addClass('tracking-is-empty');
          }, 20);
        }
      }

      $elem.on('change focus blur keyup input paste valuesync',
        'input,select,textarea', refresh);

      refresh();
      // the initial value might change right after binding
      setTimeout(refresh, 300);
    }
  };
}

module.exports = TrackEmptyDirective;
