var _ = require('lodash-core');
/* jshint maxparams: 8 */

// @ngInject
function ClipboardService($rootScope, localStorageService, productService) {
  load();
  localStorageService.onKeyChange('clipboard', load);

  this.getItems = function() {
    return $rootScope.clipboard.items;
  };
  this.cantItems = function() {
    return $rootScope.clipboard.items.length;
  };
  this.addItem = function(product, privateData) {
    if (!this.itemIsInClipboard(product)) {
      productService.getByStyleId(product.styleId).then(function(dbProduct) {
        var dbStyle = _.find(dbProduct.styles, {id: product.styleId});
        var dbStyleUserTags = _.filter(dbStyle.tags, function(tag) {
          return /^u\./.test(tag);
        });
        product.tags = _.concat(dbProduct.tags, dbStyleUserTags);
        $rootScope.clipboard.items.push({item: product, privateData: privateData});
        productService.getByStyleId.cache.clear();
        computeCommonUserTags();
      });
    }
    save();
  };
  this.removeItem = function(product) {
    var existingItem = _.findLast($rootScope.clipboard.items, function(dataItem) {
      return (dataItem.item.id === product.id);
    });
    var itemIndex = $rootScope.clipboard.items.indexOf(existingItem);
    $rootScope.clipboard.items.splice(itemIndex, 1);
    save();
  };
  this.shift = function() {
    var elements = _.remove(
      $rootScope.clipboard.items, function(elem) {
        return elem.item.id.split('-')[0] ===
          $rootScope.clipboard.items[0].item.id.split('-')[0];
      }
    );
    save();
    return elements;
  };
  this.itemIsInClipboard = function(product) {
    return _.some(_.map($rootScope.clipboard.items, 'item'), {id: product.id});
  };
  this.cleanClipboard = function() {
    $rootScope.clipboard.items = [];
    save();
  };

  this.computeCommonUserTags = computeCommonUserTags;
  this.save = save;

  // private
  function save() {
    localStorageService.setItem('clipboard', JSON.stringify({items: ($rootScope.clipboard || {}).items || []}));
    computeCommonUserTags();
  }

  function load() {
    $rootScope.clipboard = JSON.parse(localStorageService.getItem('clipboard') || '{ "items": [] }');
    computeCommonUserTags();
  }

  function computeCommonUserTags() {
    $rootScope.clipboard = $rootScope.clipboard || {};

    var allTags = _.filter(_.map(_.map($rootScope.clipboard.items, 'item'), 'tags'), function(tag) {
      return /^u\./.test(tag);
    });

    $rootScope.clipboard.userTags = _.map(_.intersection.apply(this, allTags), function(tag) {
      return tag.replace(/^u\./, '');
    });
  }
}

module.exports = ClipboardService;
