var _ = require('lodash-core');
var promises = require('../../../async/promises');

/* globals $ */

function StripePaymentProvider(config, userService, $rootScope, $timeout, apiService) {
  this.config = config;
  this.userService = userService;
  this.$rootScope = $rootScope;
  this.$timeout = $timeout;
  this.apiService = apiService;
}

StripePaymentProvider.prototype.load = _.memoize(function() {
    return this.loadWithoutCache();
  }
);

StripePaymentProvider.prototype.loadWithoutCache = function() {
  if (window.Stripe) {
    return promises.immediate();
  }
  this.testMode = this.config.testMode || this.userService.userIsTester();
  var self = this;
  return promises.create(function(resolve, reject) {
    // jQuery promises are incompatible with other Promises
    var stripeCDN = 'https://js.stripe.com/v3/';
    $.getScript(stripeCDN).then(function() {
      if (!window.Stripe) {
        throw new Error('error loading stripe payment provider');
      }
      self.Stripe = window.stripeElements =
        window.Stripe(self.testMode ? self.config.testPublicKey : self.config.publicKey);
    }).then(resolve, reject);
  });
};

StripePaymentProvider.prototype.setTestMode = function(testMode) {
  if (testMode === this.testMode) {
    return;
  }
  this.testMode = !!testMode;
};

StripePaymentProvider.prototype.prepareOrderToSaveOptions = function() {
  var preparedOrderPromiseToSave = this.$rootScope.preparedOrderPromiseToSave;
  delete this.$rootScope.preparedOrderPromiseToSave;
  return preparedOrderPromiseToSave;
};

StripePaymentProvider.prototype.prepareOrder = function(order, validation) {
  var self = this;
  var paymentMethod = order.billing && order.billing.paymentMethod;

  if (!paymentMethod) {
    // nothing to process
    return promises.immediate(order);
  }

  if (paymentMethod.paymentProvider || validation) {
    // for saved stripe cards, cvv is not required, server requires it
    paymentMethod.cvv = 'xxx';
    if (validation) {
      paymentMethod.paymentProvider = 'stripe';
      paymentMethod.paymentProviderData = {
        id : 'xxxx'
      };
      paymentMethod.nameOnCard = 'xxx xxx';
      paymentMethod.cardNumber = 'xxxxxxxxxxxxxxxx';
      /*jshint camelcase: false */
      paymentMethod.expirationMonth = '12';
      paymentMethod.expirationYear = (new Date()).getFullYear() + 1;
    }
    // this payment method is already processed by a payment provider (this or any other)
    return promises.immediate(order);
  }

  this.setTestMode(self.config.testMode || this.userService.userIsTester());

  return this.load().then(function() {
    return self._createToken(order);
  });
};

StripePaymentProvider.prototype._createToken = promises.tryAtMost(function(order) {
  var self = this;
  return promises.create(function(resolve, reject) {
    var paymentMethod = order.billing && order.billing.paymentMethod;
    var address = order.billing.address || order.shipping.address;

    var stripeCallback = function(status, response) {
      response = status && status.token;
      self.$rootScope.billing.paymentMethod.cardIssuer = status.token.card.brand;
      self.$rootScope.billing.paymentMethod.hiddenCardNumber = 'XXXXXXXXXXXX' + status.token.card.last4;
      var err = !response ? {type: 'noresponse', code: 'noresponse', message: 'No response'} : response.error;
      if (err) {
        reject({
          data: {
            error: err
          }
        });
      } else {
        // don't send sensitive data to the server
        paymentMethod.cardNumber = 'xxxxxxxxxxxx' + response.card.last4;
        paymentMethod.cvv = 'xxx';
        // send stripe token instead
        paymentMethod.paymentProvider = 'stripe';
        paymentMethod.paymentProviderData = response;
        resolve(order);
      }
    };

    var card = {
      name: paymentMethod.nameOnCard,
      number: paymentMethod.cardNumber,
      cvc: paymentMethod.cvv,
      'exp_month': paymentMethod.expirationMonth,
      'exp_year': paymentMethod.expirationYear,
      'address_line1': address.address1,
      'address_line2': address.address2,
      'address_city': address.city,
      'address_state': address.state,
      'address_zip': address.zip,
      'address_country': (address.country || 'us').toUpperCase()
    };
    self.Stripe.createToken(window.cardElement, card).then(stripeCallback);
  });
}, {
  retries: 2,
  ifError: function(err) {
    return ['api_connection_error', 'api_error'].indexOf(err.type) >= 0;
  }
});

StripePaymentProvider.prototype.generateStripePaymentIntent = function(paymentRequest) {
  return promises.extend(
    this.apiService.post('/orders/stripe-payment-intent', { body: JSON.stringify(paymentRequest) })
  );
};

StripePaymentProvider.prototype.cancelStripePaymentIntent = function(paymentIntent, force) {
  return promises.extend(
    this.apiService.post('/orders/stripe-payment-intent/{paymentIntentId}/remove', {
      pathParams: {
        paymentIntentId: paymentIntent.id
      },
      body: JSON.stringify({
        testing: paymentIntent.metadata && paymentIntent.metadata.testing === 'true',
        force: force
      })
    })
  );
};

StripePaymentProvider.prototype.updateStripePaymentIntent = function(paymentIntentId, paymentRequest) {
  return promises.extend(
    this.apiService.put('/orders/stripe-payment-intent/{paymentIntentId}', {
      pathParams: {
        paymentIntentId: paymentIntentId
      },
      body: JSON.stringify(paymentRequest)
    })
  );
};

module.exports = StripePaymentProvider;
