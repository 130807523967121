/* jshint maxparams: 10 */
// @ngInject
function ProductReviewController($scope, $location, productReviewService, formsService) {

  $scope.review = {
    rating: 0
  };

  $scope.loading = true;

  productReviewService.getById($scope.reviewId).then(function (review) {
    if (review.rating) {
      setStatus('Your review has already been sent. Thank you!');
    } else {
      $scope.review = review;
    }
  }).catch(function () {
    setStatus('Your review has already been sent. Thank you!');
  }).finally(function () {
    $scope.loading = false;
  });

  $scope.closeButton = function () {
    if ($scope.productReviewModal) {
      $scope.productReviewModal.dismiss();
    }
  };

  $scope.saveReview = function () {
    if (!$scope.review.rating || $scope.review.rating === 0) {
      $scope.noSelectedStars = true;
    }

    if (!formsService.promiseHandler().validate($scope.forms.review)) {
      return;
    }

    productReviewService.update($scope.review.id, $scope.review).then(function () {
      setStatus('Your review has been sent. Thank you!');
      setTimeout(function () {
        $location.url($location.path());
      }, 2000);
    });
  };

  function setStatus(message) {
    $scope.message = message;
  }

  $scope.$watch('review.rating', function (newRating) {
    if (newRating && $scope.noSelectedStars) {
      $scope.noSelectedStars = false;
    }
  });
}

module.exports = ProductReviewController;
