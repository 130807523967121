var _ = require('lodash-core');

// @ngInject
function ProductImageSliderDirective($parse, $timeout, $rootScope, viewportService) {
  var linker = function($scope, element, attrs) {

    $scope.quickView = attrs.quickView;
    $scope.slides = [];
    var lastIndex = 0;
    var lastProductId = -1;

    if (attrs.images) {
      $scope.images = $parse(attrs.images)($scope);
    }

    $scope.$watch(
      function() {
        return JSON.stringify($scope.images);
      },
      function() {
        if (!$scope.images || !$scope.images.length) {
          return;
        }
        var newProductId = attrs.productId;
        if (lastProductId !== newProductId) {
          lastProductId = newProductId;
          lastIndex = 0;
        }
        $scope.ready = false;
        var slides = _.map($scope.images, function(image, index) {
          return {
            image: image,
            active: false,
            index: index
          };
        });
        $scope.swiperName = '/views/partials/swiper-blank.html';
        $scope.slides = slides;
        setTimeout(function() {
          $scope.swiperName = '/views/partials/swiper.html';
          $scope.$digest();
        }, 0);
        if (slides.length) {
          $timeout(function() {
            // Showing the first image of the style when you change the color
            $scope.setActiveSlide(slides[0]);
            $scope.ready = true;
          }, 50);
        }
      }
    );


    $scope.setActiveSlide = function(slide) {
      if (typeof slide !== 'object') {
        slide = $scope.slides[slide];
      }
      if (!viewportService.is('<md')) {
        $timeout(function() {
          element.find('.carousel-indicators').children().get(slide.index).click();
          lastIndex = slide.index;
        });
      }
    };

    $scope.moveNext = function() {
      if (!viewportService.is('<md')) {
        $timeout(function() {
          element.find('.right.carousel-control').click();
          lastIndex = lastIndex < $scope.slides.length - 1 ? lastIndex + 1 : 0;
        });
      }
    };

    $scope.movePrevious = function() {
      if (!viewportService.is('<md')) {
        $timeout(function() {
          element.find('.left.carousel-control').click();
          lastIndex = lastIndex > 0 ? lastIndex - 1 : $scope.slides.length - 1;
        });
      }
    };

    $scope.$on('globalKeydown', function(e, keyboardEvent) {
      switch (keyboardEvent.keyCode) {
        case 37:
          // left arrow
          $scope.movePrevious();
          break;
        case 39:
          // right arrow
          $scope.moveNext();
          break;
      }
    });
    $scope.swiperName = '/views/partials/swiper-blank.html';
    $scope.$on('ngRepeatFinished', function() {
      if ($scope.slides.length > 1) {
        setTimeout(function() {
          var container = $('.swiper-container');
          if (container.length) {
            var swiper = new window.Swiper('.swiper-container', {
              pagination: '.swiper-pagination',
              paginationClickable: false,
              loop: true,
              slidesPerView: 1,
              calculateHeight: true
            });
            container.data('swiper', swiper);
          }
        }, 100);
      }
      //Desktop
      $('.product-image-slider').on('click', '.slider-lens', function() {
        $rootScope.fullscreenZoom.show({
          image: {
            src: $('.carousel-inner .product-image img.ready').attr('data-zoom-image'),
            preloadSrc: $('.carousel-inner .product-image img.ready').src
          }
        });
      });

      //Mobile & Tablet
      $('.swiper-container').on('click touch', '.slider-lens', function() {
        var element = $('.swiper-slide-visible img.ng-scope:visible');
        if (element.length === 0) {
          element = $('.swiper-slide img.ng-scope:visible');
        }
        $rootScope.fullscreenZoom.show({
          image: {
            src: element.attr('data-zoom-image'),
            preloadSrc: element.attr('src')
          }
        });
      });

      $('.swiper-container').on('click', 'img', function() {
        $rootScope.fullscreenZoom.show({
          image: {
            src: $(this).attr('data-zoom-image'),
            preloadSrc: this.src
          }
        });
      });

    });

  };

  return {
    link: linker,
    templateUrl: '/views/partials/product-image-slider.html',
    restrict: 'E'
  };
}

module.exports = ProductImageSliderDirective;
