// @ngInject
function BrandAssetsDirective(staticAssetService, analyticsService) {
  var linker = function($scope, attrs) {
    $scope.defaultInterval = attrs.interval || 7000;
    $scope.prevInterval = 100000;
    $scope.brandId = attrs.brandId || $scope.brandId;

    $scope.openImages = function(prefix, number, ext) {
      analyticsService.track('Brand Assets Gallery Open', {
        brand: $scope.brandId
      });
      var brandAssets = staticAssetService.brand($scope.brandId);
      var images = [];
      for (var i = 1; i <= parseInt(number); i++) {
        images.push({
          href: brandAssets.url('assets/' + prefix + '/' + i + '.' + ext)
        });
      }
      $.fancybox.open(images, {
        padding: 0,
        helpers: {
          title: { type: 'over' }
        },
        wrapCSS: 'brand-assets',
        beforeShow: function() {
          this.title = (this.title ? '' + this.title + '' : '') + ' ' + (this.index + 1) + ' of ' + this.group.length;
          var lastInterval = $scope.defaultInterval;
          $scope.defaultInterval = $scope.prevInterval;
          $scope.prevInterval = lastInterval;
        }, // beforeShow
        afterClose: function() {
          var lastInterval = $scope.defaultInterval;
          $scope.defaultInterval = $scope.prevInterval;
          $scope.prevInterval = lastInterval;
          analyticsService.track('Brand Assets Gallery Close', {
            brand: $scope.brandId
          });
        }
      });
      return true;
    };

    function slideClick(e) {
      var target = $(e.target).parent();
      var isArrow = target.is('.carousel-control');
      var isNext = isArrow && !$(e.target).parent().is('.left');
      setTimeout(function() {
        var index = $('.carousel-inner .active').index();
        analyticsService.track('Brand Assets Slide Click', {
          slideIndex: index,
          clickedOn: isArrow ? (isNext ? 'next' : 'previous') : 'dot'
        });
      }, 1000);
    }

    $('body').on('click', '.carousel-control, .carousel-indicators li', slideClick);
    $scope.$on('$destroy', function() {
      $('body').off('click', '.carousel-control, .carousel-indicators li', slideClick);
    });

  };

  return {
    link: linker,
    templateUrl: '/views/partials/brand-assets.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = BrandAssetsDirective;
