// @ngInject
function HttpDecorator($delegate, $injector) {
  var $http = $delegate;
  var staticAssetsBaseUrl = window.staticAssetsBaseUrl;
  var contentBaseUrl = window.contentBaseUrl;
  var userService;

  function transformRequestArguments(method, methodArguments) {
    var options = methodArguments[0];
    var url;
    if (typeof options === 'string') {
      url = options;
      options = methodArguments[1];
    } else {
      url = options.url;
    }

    if (/^\/[^\/].*\.[\w\d-]+/i.test(url)) {
      if (!options) {
        options = {};
      }
      if (/^\/content\//.test(url)) {
        // content with relative url, add base url
        if (contentBaseUrl) {
          url = contentBaseUrl + url;
        }
      } else if (staticAssetsBaseUrl) {
        // static asset with relative url, add base url
        url = staticAssetsBaseUrl + url;
      }
      if (options && options.url) {
        options.url = url;
      }
      return [url, options];
    }

    if (!/api.*\.orchardmile\.com(\:\d+)?\/(?:products|brands)/i.test(url)) {
      return;
    }

    var args = methodArguments;

    if (method === '' || method === 'get' || options.method === 'GET') {
      args = Array.prototype.slice.apply(methodArguments);
      if (!options) {
        options = args[1] = {};
      }

      if (!userService) {
        userService = $injector.get('userService');
      }

      var user = userService.getUser();
      if (user && !user.anonymous && user.roles && user.roles.indexOf('admin') >= 0) {
        if (!options) {
          options = {};
        }
        options.params = options.params || {};
        options.params._t = new Date().getTime();
      }
    }
    return args;
  }

  function transformResponsePromise(method, promise) {
    promise.catch(function(err) {
      deleteSensitiveData(err);

      var isError = true;
      if (err.status && [0, 400, 401, 402, 403, 404, 429].indexOf(err.status) >= 0) {
        isError = false;
      }

      var message = getHttpError(err);
      console[isError ? 'error' : 'log'](message);
      throw err;
    });
  }

  function deleteSensitiveData(err) {
    if (err.config && err.config.data) {
      if (err.config.data.password) {
        err.config.data.password = '<sensitive>';
      }
      if (err.config.data.billing) {
        err.config.data.billing = '<sensitive>';
      }
    }
  }

  function getHttpError(exception) {
    var messages = [];
    if (exception.config) {
      messages.push('[HTTP] ' + exception.config.method + ' ' + exception.config.url +
        '-> [' + exception.status + '] ' + exception.statusText);
      if (exception.data && exception.data.error) {
        messages.push(exception.data.error.message || exception.data.error.toString());
        if (exception.data.error.incident) {
          messages.push('INCIDENT: ' + exception.data.error.incident);
        }
        if (exception.data.error.detail) {
          messages.push(exception.data.error.detail);
        }
      } else {
        messages.push(serialize(exception.data));
      }
    } else {
      messages.push(serialize(exception));
    }
    return messages.join('\n');
  }

  function serialize(data) {
    try {
      return JSON.stringify(data, null, 2);
    } catch (err) {
      return data.toString();
    }
  }

  var wrapper = function() {
    // Apply global changes to arguments, or perform other
    // nefarious acts.
    var args = transformRequestArguments('', arguments);
    var promise = $http.apply($http, args || arguments);
    return transformResponsePromise('', promise) || promise;
  };

  // $http has convenience methods such as $http.get() that we have
  // to pass through as well.
  Object.keys($http).filter(function(key) {
    return (typeof $http[key] === 'function');
  }).forEach(function(key) {
    wrapper[key] = function() {

      // Apply global changes to arguments, or perform other
      // nefarious acts.
      var args = transformRequestArguments(key, arguments);
      var promise = $http[key].apply($http, args || arguments);
      return transformResponsePromise(key, promise) || promise;
    };
  });

  return wrapper;
}

module.exports = HttpDecorator;
