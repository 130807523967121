var promises = require('../../async/promises');
var _ = require('lodash-core');

/* globals Promise */
// @ngInject
function ShowService(apiService, formatService, productService, translationService,
                     userService, sessionStorageService, localStorageService) {

  this.getById = function(showId) {
    return promises.extend(apiService.get('/shows/{id}', {
      pathParams: {
        id: showId
      }
    }));
  };

  this.query = function(terms) {
    return promises.extend(apiService.get('/shows', {
      urlParams: terms
    }));
  };

  this.getByProductId = function(productId, terms) {
    return promises.extend(apiService.get('/shows/by-product/{productId}', {
      pathParams: {
        productId: productId
      },
      urlParams: terms
    }));
  };

  this.getReelsByShowId = function(showId) {
    return promises.extend(apiService.get('/shows/{showId}/reels', {
      pathParams: {
        showId: showId
      }
    }));
  };

  this.getAudienceToken = function(showId) {
    return promises.extend(apiService.get('/shows/{id}/audience-token', {
      pathParams: {
        id: showId
      }
    }));
  };

  this.update = function(showId, show) {
    return promises.extend(apiService.patch('/shows/{id}', {
      pathParams: {
        id: showId
      },
      body: show
    }));
  };

  this.addEvent = function(showId, type, data) {
    var user = userService.getUser();
    var userSaved = sessionStorageService.getItem('userChatName');
    if (user && !user.anonymous) {
      userSaved = user.firstName;
    } else if (!userSaved) {
      userSaved =  'Guest ' + Math.floor(Math.random() * 50) + 1;
    }

    var city;

    userService.getShippingOptions().then(function(shippingOptions) {
      if (shippingOptions && shippingOptions.length) {
        city = shippingOptions[0].address.city;
      }
    }).finally(function() {
      var newEvent = {
        type: type,
        user: {
          id: user.id,
          name: userSaved,
          city: city
        },
        createdAt: new Date(),
        details: data
      };

      return promises.extend(apiService.patch('/shows/{id}/events', {
        pathParams: {
          id: showId
        },
        body: newEvent
      }));
    });
  };

  this.delete = function(showId) {
    return promises.extend(apiService.delete('/shows/{id}', {
      pathParams: {
        id: showId
      }
    }));
  };

  this.curate = function(showId) {
    return promises.extend(apiService.post('/curations/shows/{id}', {
      pathParams: {
        id: showId
      }
    }));
  };

  this.getChatToken = function(showId) {
    return promises.extend(apiService.get('/shows/{id}/chat-token', {
      pathParams: {
        id: showId
      }
    }));
  };

  this.getNormalizedAndOrderedByIds = function(ids, expandProducts) {
    var self = this;
    var items = _.clone(ids);
    var obtained = 0;
    return new Promise(function(resolve) {
      _.forEach(ids, function(id) {
        self.getById(id).then(function(show) {
          self.normalizeShowData(show, expandProducts);
          items[items.indexOf(id)] = show;
        }).finally(function() {
          obtained++;
          if (obtained === items.length) {
            resolve(_.filter(items, function (item) {
              return ['posted', 'live', 'scheduled'].includes(item.status);
            }));
          }
        });
      });
    });
  };

  this.getNormalizedShowsByInfluencerId = function(influencerId, expandProducts, hideTestShows) {
    var self = this;
    return this.query({
      'influencers-ids': influencerId,
      'status': 'live,scheduled,posted',
      'hide-test-shows': hideTestShows
    })
      .then(function (shows) {
        return _.map(shows.items, function (show) {
          self.normalizeShowData(show, expandProducts);
          return show;
        });
      });
  };

  this.getNormalizedShowsByTerm = function(term, expandProducts, hideTestShows) {
    var self = this;
    term['hide-test-shows'] = hideTestShows;
    return this.query(term).then(function (data) {
      return _.map(data.items, function(show) {
        self.normalizeShowData(show, expandProducts);
        return show;
      });
    });
  };

  this.getNormalizedShowsByProductId = function(productId, expandProducts, hideTestShows) {
    var self = this;
    var term = {};
    term['hide-test-shows'] = hideTestShows;
    return this.getByProductId(productId, term).then(function (data) {
      return _.map(data.items, function(show) {
        self.normalizeShowData(show, expandProducts);
        return show;
      });
    });
  };

  this.incrementAmountOfViews = function(items, showId) {
    var show = _.find(items, function (item) { return item.id === showId; });
    if (show && ['posted', 'live'].includes(show.showStatus)) {
      if (show.showStatus === 'posted') {
        show.views += 3;
      }
      if (show.showStatus === 'live') {
        show.views += 1;
      }
      this.setShowStatus(show);
    }
  };

  this.normalizeShowData = function(show, expandProducts) {
    var self = this;
    show.showStatus = show.status;
    this.setShowStatus(show);
    show.showTitle = show.title;
    show.thumbnailUrl =
      (show.s3VideoThumbnails &&
        show.s3VideoThumbnails.length &&
        show.s3VideoThumbnails[0]) ||
      (show.s3RecordedShowThumbnails &&
        show.s3RecordedShowThumbnails.length &&
        show.s3RecordedShowThumbnails[0]) ||
      '/img/bg-video-profile.gif';
    if (expandProducts) {
      _.forEach(show.showProducts, function (showProduct) {
        self.normalizeShowProductData(showProduct);
      });
    }
  };

  this.normalizeShowProductData = function(showProduct) {
    productService
      .getById(showProduct.productId)
      .then(function (productDetails) {
        showProduct.style = _.find(
          productDetails.styles,
          function (style) {
            return style.id === showProduct.styleId;
          }
        );
        showProduct.variant = _.find(
          showProduct.style.variants,
          function (variant) {
            return variant.hash === showProduct.hash;
          }
        );
        showProduct.productUrl =
          '/' +
          productDetails.brand.id +
          '/' +
          showProduct.style.slug +
          '-' +
          showProduct.style.id;
        showProduct.brandName = productDetails.brand.name;
      });
  };

  this.orderShowsByDate = function(shows) {
    shows.sort(function(showA, showB){
      var dateA = showA.schedule;
      var dateB = showB.schedule;
      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
  };

  this.syncInteractionsContent = function () {
    var self = this;
    if (userService.isLoggedIn()) {
      return userService.getById(userService.getUser().id).then(function (user) {
        var mergedWatchedShows = [];
        var watchedShowsOnSession = localStorageService.getItem('watched_shows');

        if (watchedShowsOnSession) {
          watchedShowsOnSession = JSON.parse(watchedShowsOnSession);
        }

        if (user.watchedShows && (!watchedShowsOnSession || !watchedShowsOnSession.length)) {
          mergedWatchedShows = user.watchedShows;
        } else if (!user.watchedShows && (watchedShowsOnSession && watchedShowsOnSession.length)) {
          mergedWatchedShows = watchedShowsOnSession;
        } else if (user.watchedShows && watchedShowsOnSession) {
          _.merge(mergedWatchedShows, user.watchedShows, watchedShowsOnSession);
          mergedWatchedShows = _.orderBy(mergedWatchedShows, ['watchedAt'], ['desc']);
          mergedWatchedShows = _.uniqBy(mergedWatchedShows, 'showId');
        }
        mergedWatchedShows = _.filter(mergedWatchedShows, function (show) {
          return show.watchedAt && show.showId;
        });

        var loadingShowInfo = mergedWatchedShows.length;

        _.forEach(mergedWatchedShows, function (watchedShow) {
          if (watchedShow && !watchedShow.influencerId) {
            self.getById(watchedShow.showId).then(function (show) {
              watchedShow.influencerId = show.influencerId;
              watchedShow.schedule = show.schedule;
              watchedShow.isReel = show.isReel;
            }).finally(function () {
              loadingShowInfo--;
              if (loadingShowInfo < 1) {
                localStorageService.setItem('watched_shows', JSON.stringify(mergedWatchedShows));
              }
            });
          } else {
            loadingShowInfo--;
          }
        });

        if (mergedWatchedShows && mergedWatchedShows.length) {
          userService.patchUser(user.id, { watchedShows: mergedWatchedShows });
        }

        localStorageService.setItem('watched_shows', JSON.stringify(mergedWatchedShows));
      });
    }
  };

  this.setShowStatus = function(show) {
    var self = this;
    try {
      if (show.status === 'scheduled' && show.schedule) {
        var date = new Date(show.schedule);
        if (self.checkDiffOfDays(show.schedule, 0)) {
          translationService.translate('Today').then(function(text) {
            show.showStatusTitle = text + formatService.timeAmPm(date.getHours(), date.getMinutes());
          });
        } else if (self.checkDiffOfDays(show.schedule, 1)) {
          translationService.translate('Tomorrow').then(function(text) {
            show.showStatusTitle = text + ', ' + formatService.timeAmPm(date.getHours(), date.getMinutes());
          });
        } else {
          show.showStatusTitle = formatService.formatDate(date);
        }
      }
      if (show.status === 'posted' && show.views !== undefined) {
        translationService.translate('views').then(function(text) {
          show.showStatusTitle = (Number(show.views) > 1000 ? (Number(show.views) / 1000).toFixed(1) + 'k'
            : show.views) + ' ' + text;
        });
      }
      if (show.status === 'live') {
        translationService.translate('Live').then(function(text) {
          show.showStatusTitle = text.toUpperCase();
        });
      }
    } catch (e) {
      console.log(e);
      show.showStatusTitle = show.status;
    }
  };

  this.checkDiffOfDays = function(date, days) {
    var showDate = new Date(date);
    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + days);
    if (
      showDate.getFullYear() === todayDate.getFullYear() &&
      showDate.getMonth() === todayDate.getMonth() &&
      showDate.getDate() === todayDate.getDate()
    ) {
      return true;
    }
  };

  this.getSales = function(showId) {
    return promises.extend(apiService.get('/shows/{id}/sales', {
      pathParams: {
        id: showId
      }
    }));
  };

  this.getAttributionExtra = function(showsIds) {
    return promises.extend(apiService.post('/shows/extra-attributions', {
      body: {
        ids: showsIds
      }
    }));
  };
}


module.exports = ShowService;
