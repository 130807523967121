/* global gtag */
/* jshint camelcase: false */
// TODO: Adjust currency for international users
function castListItemToGaItem(params) {
  return {
    item_id: params.item.id,
    item_name: params.item.name,
    discount: 0,
    index: params.index,
    item_list_id: params.listName,
    item_list_name: params.listName,
    item_brand: params.item.brand.id,
    item_category: params.item.categories[0],
    item_category2: params.item.categories[1],
    item_category3: params.item.categories[2],
    price: params.item.price / 100,
    currency: 'USD',
    quantity: 1
  };
}

function castBagItemToGaItem(params) {
  return {
    item_id: params.product.id + '-' + params.style.id,
    item_name: params.style.name,
    discount: params.totalDiscount ? ((params.totalDiscount / params.totalCost) * params.variant.price) / 100 : 0,
    item_brand: params.product.brand.id,
    item_category: params.style.categories[0],
    item_category2: params.style.categories[1],
    item_category3: params.style.categories[2],
    item_variant: (params.variant.key && params.variant.key.color) || undefined,
    price: params.variant.price / 100,
    currency: 'USD',
    quantity: params.quantity || 1,
    coupon: params.promoCode
  };
}

var eCommerceEvents = {
  view_item_list: function(params) {
    gtag('event', 'view_item_list', {
      item_list_id: params.listName,
      item_list_name: params.listName,
      items: params.items.map(function(item, index) {
        return castListItemToGaItem({ item: item, index: index, listName: params.listName });
      })
    });
  },

  select_item: function(params) {
    gtag('event', 'select_item', {
      item_list_id: params.listName,
      item_list_name: params.listName,
      items: [castListItemToGaItem(params)]
    });
  },

  view_item: function(params) {
    gtag('event', 'view_item', {
      currency: 'USD',
      value: params.variant.price,
      items: [
        {
          item_id: params.product.id + '-' + params.style.id,
          item_name: params.style.name,
          discount: (params.variant.listPrice - params.variant.price) / 100,
          item_brand: params.product.brand.id,
          item_category: params.style.categories.join('/'),
          item_variant: (params.variant.key && params.variant.key.color) || undefined,
          price: params.variant.price / 100,
          currency: 'USD',
          quantity: 1
        }
      ]
    });
  },

  add_to_cart: function(params) {
    gtag('event', 'add_to_cart', {
      currency: 'USD',
      value: params.variant.price / 100,
      items: [castBagItemToGaItem(params)]
    });
  },

  remove_from_cart: function(params) {
    gtag('event', 'remove_from_cart', {
      currency: 'USD',
      value: params.variant.price / 100,
      items: [castBagItemToGaItem(params)]
    });
  },

  add_to_wishlist: function(params) {
    gtag('event', 'add_to_wishlist', {
      currency: 'USD',
      value: params.variant.price / 100,
      items: [castBagItemToGaItem(params)]
    });
  },

  remove_from_wishlist: function(params) {
    gtag('event', 'remove_from_wishlist', {
      currency: 'USD',
      value: params.variant.price / 100,
      items: [castBagItemToGaItem(params)]
    });
  },

  begin_checkout: function(params) {
    gtag('event', 'begin_checkout', {
      currency: 'USD',
      value: params.value / 100,
      items: params.items.map(function(item) {
        return castBagItemToGaItem(item);
      })
    });
  },

  add_shipping_info: function(params) {
    gtag('event', 'add_shipping_info', {
      currency: 'USD',
      value: params.value / 100,
      shipping_tier: params.shippingMethod,
      items: params.items.map(function(item) {
        return castBagItemToGaItem(item);
      })
    });
  },

  add_payment_info: function(params) {
    gtag('event', 'add_payment_info', {
      currency: 'USD',
      value: params.value / 100,
      coupon: params.promoCode,
      payment_type: params.paymentType,
      items: params.items.map(function(item) {
        return castBagItemToGaItem({
          product: item.product,
          style: item.style,
          variant: item.variant,
          quantity: item.quantity,
          promoCode: params.promoCode,
          totalDiscount: params.discount / 100,
          totalCost: params.cost / 100
        });
      })
    });
  },

  purchase: function(params) {
    gtag('event', 'purchase', {
      currency: 'USD',
      transaction_id: params.number,
      value: params.totals.totalPrice / 100,
      tax: params.totals.taxes / 100,
      shipping: (params.totals.shipping + params.totals.internationalShipping) / 100,
      coupon: params.promoCode,
      items: params.items.map(function(item) {
        return castBagItemToGaItem({
          product: item.product,
          style: item.style,
          variant: item.variant,
          quantity: item.quantity,
          promoCode: params.promoCode,
          totalDiscount: params.discount / 100,
          totalCost: params.totals.cost / 100
        });
      })
    });
  },

  refund: function(params) {
    gtag('event', 'refund', {
      currency: 'USD',
      transaction_id: params.order.number + '-' + params.event._id,
      value: params.event.details.amount / 100,
      tax: params.tax / 100,
      shipping: params.shipping / 100,
      coupon: params.order.promoCode,
      items: params.items.map(function(item) {
        return castBagItemToGaItem({
          product: item.product,
          style: item.style,
          variant: item.variant,
          quantity: item.quantity,
          promoCode: params.order.promoCode,
          totalDiscount:
            (params.order.totals.cost +
              params.order.totals.taxes +
              params.order.totals.internationalShipping +
              params.order.totals.shipping -
              params.order.totals.giftCardDebit -
              params.order.totals.totalPrice) /
            100,
          totalCost: params.order.totals.cost / 100
        });
      })
    });
  }
};

module.exports = eCommerceEvents;
