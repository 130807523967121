var _ = require('lodash-core');

// @ngInject
function AlgoliaInsightsService(userService, sessionStorageService, localStorageService, $rootScope) {

  this.getIndexName = function (filter) {
    switch (filter) {
      // plp filters
      case 'newest': return 'products-by-newest';
      case 'price-asc': return 'products-by-price-asc';
      case 'price-desc': return 'products-by-price-desc';
      case 'scores-bestselling': return 'products-by-scores-bestselling';
      case 'scores-popular': return 'products-by-scores-popular';

      // by brand
      case 'scores-brands': return 'products-by-scores-brands';

      // by categories
      case 'scores-categories': return 'products-by-scores-categories';

      // by newest
      case 'scores-whatsnew': return 'products-by-scores-whatsnew';

      // by search
      case 'search': return 'products-for-search';

      // occasions
      case 'scores-occasions': return 'products-by-scores-occasions';

      case 'scores-multibrand': return 'products-by-scores-multibrand';
      default: return 'products';
    }
  };

  this.selectPLPIndex = function (indexName) {
    $rootScope.algoliaProductListIndex = this.getIndexName(indexName);
  };

  this.sendEvent = function (eventName, data) {
    if (window.dataLayer) {
      window.dataLayer.push(_.defaults({
        event: eventName
      }, data));
    }
  };

  this.sendAlgoliaUserToken = function () {
    if (window.dataLayer) {
      var userToken = this.getAlgoliaUserToken();
      if (this.userToken !== userToken) {
        this.userToken = userToken;
        window.dataLayer.push({
          algoliaUserToken: userToken
        });
      }
    }
  };

  this.getAlgoliaUserToken = function () {
    var userToken = 'guest';
    var user = userService.getUser();
    if (userService.isLoggedIn()) {
      userToken = user.id;
    } else if (sessionStorageService.getSessionId()) {
      userToken = sessionStorageService.getSessionId();
    }
    return userToken;
  };

  this.sendFilters = function (queries) {
    var self = this;
    _.forEach(queries, function (query) {
      var filters = _.filter(_.clone(query.params.facetFilters), function (filter) {
        return !['brandEnabled:true'].includes(filter);
      });
      if (filters && filters.length) {
        self.sendEvent('click-filter', {
          eventType: 'click-filter',
          eventName: 'click filter', // Required
          index: query.indexName, // Required
          filters: filters, // Required
          userToken: self.getAlgoliaUserToken()
        });
      }
    });
  };

  this.generateUUID = function () {
    if (localStorageService.getItem('queryId')) {
      return localStorageService.getItem('queryId');
    }
    return 'al' + '-' + Math.random().toString(36).substr(2, 9) + '-' +
      Math.random().toString(36).substr(2, 9);
  };

  this.getObjectId = function (item) {
    return item.product().id + '-' + item.styleId;
  };

  this.getProductData = function (productId, style) {
    if (productId && style) {
      return {
        objectId: productId + '-' + style,
        queryId: this.generateUUID()
      };
    }
  };

  this.getPLPProductData = function (productId, pos) {
    if (productId) {
      return {
        objectId: productId,
        position: pos,
        queryId: this.generateUUID()
      };
    }
  };
}

module.exports = AlgoliaInsightsService;
