var _ = require('lodash-core');

// @ngInject
function TranscludeDirective() {
  var linker = function($scope, element, attrs, ctrl, transclude) {
    transclude($scope, function(clone) {
      var hasContent = _.findIndex(clone, function(e) { return !!e.outerHTML; }) > -1;
      if (hasContent) {
        element.empty();
        element.append(clone);
      }
    });
  };
  return {
    link: linker,
    restrict: 'E',
    scope: true,
    replace: true
  };
}

module.exports = TranscludeDirective;
