// @ngInject
function BackInStockModalController($scope, userService, formsService) {
  $scope.inputs = {
    email: $scope.modal.instanceData.options.email || userService.getUser().email
  };
  $scope.styleId = $scope.modal.instanceData.options.styleId || '';
  $scope.variantKey = $scope.modal.instanceData.options.variantKey || {};
  $scope.brandId = $scope.modal.instanceData.options.brandId || {};

  formsService.focusFirstField();

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.sendBackInStock = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var email = $scope.inputs.email;
    userService.setDefaultEmail(email);

    if ($scope.alert === 'brand-is-back') {
      formPromise.handle(
        userService.notifyMeAvailable($scope.brandId, $scope.styleId, $scope.variantKey, $scope.inputs.transactional)
      );
    } else {
      formPromise.handle(
        userService.notifyMeStock($scope.styleId, $scope.variantKey, $scope.inputs.transactional)
      );
    }
  };

  $scope.reset = function() {
    $scope.inputs.email = '';
    formPromise.clear();
    $scope.$broadcast('unvisit');
    formsService.focusFirstField();
  };

}

module.exports = BackInStockModalController;
