// @ngInject
function BrandLeftPanelDirective() {
  var linker = function() {
  };

  return {
    link: linker,
    templateUrl: '/views/partials/brand-left-panel.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = BrandLeftPanelDirective;
