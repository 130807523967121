var _ = require('lodash-core');
/* globals angular, $ */

// @ngInject
function HamburgerDirective($timeout, $rootScope, $transitions, bodyScrollService) {
  return {
    restrict: 'E',
    scope: true,
    transclude: true,
    templateUrl: '/views/partials/hamburger.html',

    link: function($scope) {
      var body = angular.element('body');
      var container = angular.element('.hamburger-content');

      $scope.isOpen = false;
      $scope.levels = [];
      $scope.classes = [];
      $scope.levelIndex = 0;

      var fullyClosedTimeout;

      container.click(function(e) {
        if (e.target.tagName.toLowerCase() !== 'a') {
          return;
        }
        if (e.target.getAttribute('href')) {
          // always close the menu if a link is clicked
          $scope.close();
        }
      });

      $('.logo-container').click('a[href]', function() {
        // close the menu if header logo is clicked
        $scope.close();
      });

      $rootScope.hamburger = $rootScope.hamburger || {};

      $rootScope.hamburger.open = $scope.open = function() {
        body.addClass('hamburger-menu-display');
        if (fullyClosedTimeout) {
          clearTimeout(fullyClosedTimeout);
        }
        $scope.resetLevels();
        $scope.isOpen = true;
        setTimeout(function() {
          bodyScrollService.lock();
          body.addClass('hamburger-menu-visible');
        }, 20);
        setupMenus();
      };

      $rootScope.hamburger.close = $scope.close = function() {
        if (!$scope.isOpen) {
          return;
        }
        bodyScrollService.unlock();
        if (fullyClosedTimeout) {
          clearTimeout(fullyClosedTimeout);
        }
        $scope.isOpen = false;
        body.removeClass('hamburger-menu-visible');
        $timeout($scope.resetLevels, 500);
        fullyClosedTimeout = setTimeout(function() {
          body.removeClass('hamburger-menu-display');
        }, 500);
      };

      $rootScope.hamburger.toggle = $scope.toggle = function() {
        if ($scope.isOpen) {
          $scope.close();
        } else {
          $scope.open();
        }
      };

      $scope.push = function(name, data) {
        $scope.levels[$scope.levelIndex] = data;
        $scope.classes[$scope.levelIndex] = name;
        $scope.classes.splice($scope.levelIndex + 1);
        $scope.levelIndex++;
        angular.element('.levels').addClass('level-' + $scope.levelIndex);
        setupMenus();
      };

      $scope.pop = function() {
        angular.element('.levels').removeClass('level-' + $scope.levelIndex);
        $scope.levelIndex--;
      };

      $scope.resetLevels = function() {
        var levelsElement = angular.element('.levels');
        while ($scope.levelIndex > 0) {
          levelsElement.removeClass('level-' + $scope.levelIndex);
          $scope.levelIndex--;
        }
        $scope.levels = [];
        $scope.classes = [];
      };

      $transitions.onStart({}, function(trans) {
        trans.promise.finally(function() {
          $scope.close();
        });
      });

      $scope.$on('globalKeydown', function(e, keyboardEvent) {
        if (keyboardEvent.keyCode === 27) {
          $scope.close();
        }
      });

      function setupMenus() {
        container.find('.hamburger-menu').hide();
        var show = _.map(
          ['root'].concat(_.uniq($scope.classes)),
          function(name) {
            return '.hamburger-menu.' + name;
          }
        ).join(',');
        container.find(show).css({display: 'inline-block'});
        setTimeout(function() {
          if (container[0].scrollTop !== 0) {
            container[0].scrollTop = 0;
          }
        }, 10);
      }
    }
  };

}

module.exports = HamburgerDirective;
