var _s = require('underscore.string');
var _ = require('lodash-core');

// jshint maxparams:15
// @ngInject
function AdminProductController($scope, $state, $stateParams, $location,
                                productService, errorService, listContextService,
                                colorService, normalizedColorService, formsService, userService) {

  listContextService.setScopeContext($scope, {urlMode: false});

  $scope.sameBrandProducts = [];
  $scope.wearItWithProducts = [];

  $scope.toggleOverridden = {tags: false};
  $scope.userTags = '';
  $scope.saving = false;
  $scope.color = $stateParams.color ? $stateParams.color : false;
  var previous = {};
  var userTagsPromise = productService.getUserTags();

  var loadProduct = function() {
    $scope.product = {
      id: null,
      name: '',
      description: '',
      brandId: null,
      partnerId: null,
      dummy: true,
      visible: true,
      images: [],
      tags: [],
      variants: []
    };

    if ($stateParams.id) {
      productService.getByStyleId($stateParams.id)
        .then(function(p) {
          if (!p) {
            errorService.notFound();
            return;
          }
          var product = _.cloneDeep(p);
          $scope.product = product;
          $scope.filters.brands[0] = product.brand.id;

          var style = _.find(product.styles, {id: $stateParams.id});

          // store current tags
          previous.tags = product.tags;

          // split between user tags and the others
          product.tags = _.filter(previous.tags, function(tag) {
            return !/^u\./.test(tag);
          });
          $scope.userTags = _.map(_.filter(style.tags, function(tag) {
            return /^u\./.test(tag);
          }), function(tag) {
            return {text: tag.replace(/^u\./, '')};
          });

          $scope.sameBrandProducts = productService.getProductsFromSameBrand(product).lazyArray();
          //Disable the call we are not doing it right now
          //$scope.wearItWithProducts = productService.getProductsToWearWith(product).lazyArray();
        }).catch(errorService.pageError);
    }
  };

  loadProduct();

  $scope.$watch('product.name', function(newValue) {
    $scope.product.slug = _s.slugify(newValue);
  });


  $scope.loadTags = function(query) {
    query = query || '';
    return userTagsPromise.then(function(data) {
      var tags = _.map(data.tags, function(tag) {
        return {text: tag.replace(/^u\./, '')};
      });
      return _.filter(tags, function(tag) {
        return tag.text.substring(0, query.length).toLowerCase() === query.toLowerCase();
      });
    });
  };

  $scope.hasCurationError = function() {
    return $scope.product && $scope.product.curated && (!$scope.product.tags || !$scope.product.tags.length) ?
      'You must select a category to mark product as curated' : '';
  };

  $scope.save = function() {
    if ($scope.hasCurationError()) {
      return;
    }
    if (!$scope.formgeneral.$valid) {
      return;
    }
    $scope.saving = true;

    var product = _.cloneDeep($scope.product);
    var styleId = $stateParams.id;
    var style = _.find(product.styles, {id: styleId});

    if (product.brand && product.brand.id) {
      product.brandId = product.brand.id;
    }
    product.partnerId = product.partner.id;

    delete product.brand;
    delete product.partner;

    // prepare tags
    var userTags = _.map($scope.userTags, function(tag) {
      return 'u.' + tag.text.toLowerCase();
    });
    var userTagsToAdd = _.filter(userTags, function(userTag) {
      return !style.tags.includes(userTag);
    });
    var userTagsToRemove = _.filter(style.tags, function(userTag) {
      return /^u\./.test(userTag) && !userTags.includes(userTag);
    });

    var user = userService.getUser();
    product.lastModifiedBy = user && user.email;

    // overridden tags
    if ($scope.toggleOverridden.tags) {
      if (product.overridden) {
        product.overridden.tags = !product.overridden.tags;
      } else {
        product.overridden = {tags: true};
      }
    } else if (product.id && !product.dummy) {
      var prevTags = previous.tags || [];
      var currTags = product.tags || [];
      var dirty = prevTags.length !== currTags.length || _.intersection(prevTags, currTags).length !== prevTags.length;
      if (dirty) {
        if (!product.overridden) {
          product.overridden = {};
        }
        product.overridden.tags = true;
      }
    }

    delete product.variants;
    delete product.styles;

    productService.save(product, $scope.wearItWithProducts, function() {
      productService.saveUserTagsByStyle([styleId], userTagsToAdd, null).then(function() {
        productService.saveUserTagsByStyle([styleId], null, userTagsToRemove).then(function() {
          $state.go('root.product.variant', {
            brand: product.brandId,
            productName: style ? style.slug : product.slug,
            id: $stateParams.id,
            color: undefined,
            size: undefined
          });
        });
      });
    });
  };

  var formPromise = $scope.formPromise = formsService.promiseHandler();
  $scope.getExampleItems = function() {
    productService.getByOriginalColor($scope.colorData.originalColor).then(function(results) {
      $scope.exampleItems = _.map(results.items, function(each) {
        return {name: each.name, image: productService.getImageUrl(each.image)};
      });
    });
  };
  $scope.reload = function() {

    $scope.saved = false;
    var normalizedColorPromise = normalizedColorService.get({limit: 999});

    if ($stateParams.color === 'create') {
      $scope.colorData = {
        originalColor: '',
        normalizedColor: []
      };
    } else {
      var colorServicePromise = colorService.getByOriginalColor($stateParams.color);

      colorServicePromise.then(function(colorData) {
        $scope.colorData = colorData;
        colorData.originalColor = colorData.originalColor || {};

        if (colorData.normalizedColor) {
          colorData.normalizedColor = colorData.normalizedColor.join(',');
        } else {
          colorData.normalizedColor = '';
        }

        normalizedColorPromise.then(function(normalizedColors) {
          $scope.normalizedColors = normalizedColors.items;
          $scope.normalizedColors.forEach(function(normalizedColor) {
            normalizedColor.selected = colorData.normalizedColor.indexOf(normalizedColor.normalizedColor) >= 0;
          });
          $scope.getExampleItems();
        });
      });
    }
  };

  $scope.$watch('[colorData,normalizedColors]', function() {
    $scope.saved = false;
  }, true);

  $scope.reload();

  $scope.saveColor = function() {
    var colorData = _.cloneDeep($scope.colorData);

    colorData.normalizedColor = _.map(_.filter($scope.normalizedColors, {selected: true}), 'normalizedColor');

    $scope.saving = true;

    formPromise.handle(colorService.save(colorData).then(function() {
      $scope.saving = false;
      $scope.saved = true;
    }));
  };

  $scope.isOptionsRequired = function() {
    return !($scope.normalizedColors && _.find($scope.normalizedColors, function(options) {
      return options.selected;
    }));
  };

  $scope.showSave = function() {
    return typeof $location.hash() !== 'undefined' && $location.hash() !== 'color';
  };
}

module.exports = AdminProductController;
