var _ = require('lodash-core');
var dialogs = require('dialogs');

/* jshint maxparams: 11 */
// @ngInject
function ProductListItemsDirective(
  $parse,
  $rootScope,
  $timeout,
  $state,
  curationService,
  productService,
  clipboardService,
  brandService,
  algoliaInsightsService
) {
  var isDragging = false;
  algoliaInsightsService.sendAlgoliaUserToken();

  var linker = function($scope, _element, attrs) {
    $scope.link = attrs.link;

    var productListNameAttrValue = attrs.listName ? $parse(attrs.listName)($scope) : null;
    if (productListNameAttrValue) {
      $scope.productListName = productListNameAttrValue;
    }

    function selectAlgoliaIndex() {
      if ($state.params.category1 === 'whats-new') {
        algoliaInsightsService.selectPLPIndex('scores-whatsnew');
      } else if ($state.params.sort) {
        algoliaInsightsService.selectPLPIndex($state.params.sort.toLowerCase());
      } else {
        algoliaInsightsService.selectPLPIndex('newest');
      }
    }

    selectAlgoliaIndex();

    $scope.$watch(getItemsHash, function(curr, prev) {
      var items = getItems() || [];
      if (items.length) {
        productService.productListViewed({
          listName: $scope.getListName(),
          items: items
        });
      }
      flattenExpandedStyles(items);
      $scope.items = items;

      // private data
      if (isUserAdmin()) {
        $scope.itemsPrivateData = $scope.itemsPrivateData || [];
        var newOnes = _.compact(_.difference(curr.split(','), prev.split(',')));
        if (!newOnes.length) {
          return;
        }
        productService.getPrivateData(newOnes).then(function(privateData) {
          if ($scope.itemsPrivateData.length) {
            _.forEach(privateData.items, function(itemPrivateData) {
              var current = _.find($scope.itemsPrivateData, { styleId: itemPrivateData.styleId });
              if (current) {
                _.merge(current, itemPrivateData);
              } else {
                $scope.itemsPrivateData.push(itemPrivateData);
              }
            });
          } else {
            $scope.itemsPrivateData = privateData.items;
          }
        });
      } else {
        delete $scope.itemsPrivateData;
      }
    });

    $scope.$on('productListStylesExpanded', function() {
      flattenExpandedStyles($scope.items);
    });

    $scope.$on('productListStylesCollapsed', function() {
      flattenExpandedStyles($scope.items);
    });

    $rootScope.bodyClassesProviders.draggingModeClassesProvider = function() {
      return isDragging ? 'dragging-mode' : null;
    };

    $scope.$on('$destroy', function() {
      isDragging = false;
    });

    $scope.sortableOptions = {
      disabled: !isSortableList(),
      cancel: '.unsortable',
      start: function() {
        // dragging
        isDragging = true;
        // fire digest
        $timeout(function() {});
      },
      stop: function(e, ui) {
        // after drop
        isDragging = false;
        $scope.saveItemPosition(ui.item.index(), 1);
      }
    };

    $scope.$watch(isSortableList, function(value) {
      $scope.sortableOptions.disabled = !value;
    });

    $scope.areItemsSelectable = areItemsSelectable;

    // it is useful to save in the database the position of an item added in the list
    $scope.saveItemPosition = function(index, stylesCount) {
      return setScore($scope.items[index], getSideScores(index, stylesCount));
    };

    $scope.cleanClipboard = function() {
      clipboardService.cleanClipboard();
      $scope.clipboard.tags = [];
    };

    $scope.minimizeClipboard = function() {
      $scope.clipboard.minimized = true;
    };

    $scope.maximizeClipboard = function() {
      $scope.clipboard.minimized = false;
    };

    $scope.isClipboardMinimized = function() {
      return $scope.clipboard.minimized === true;
    };

    $scope.pasteClipboard = function(index) {
      if (!clipboardService.getItems().length) {
        _.forEach($scope.items, function(item) {
          item.pasting = false;
        });
        return;
      }
      var elements = clipboardService.shift();
      if (!canItemBeAddedOnThisList(elements[0].item)) {
        return $scope.pasteClipboard(index);
      }
      elements.forEach(function(elem) {
        elem.item.productChecked = false;
        elem.item.pasting = true;
        var removeAtIndex = _.findIndex($scope.items, { id: elem.item.id });
        if (removeAtIndex > -1) {
          $scope.items.splice(removeAtIndex, 1);
          if (removeAtIndex < index) {
            index--;
          }
        }
        $scope.items.splice(index, 0, elem.item);
      });
      _.remove($scope.itemsPrivateData, { id: elements[0].item.id.split('-')[0] });
      $scope.itemsPrivateData = $scope.itemsPrivateData.concat(elements[0].privateData);
      $scope.saveItemPosition(index, elements.length).then(function() {
        return $scope.pasteClipboard(index + elements.length);
      });
    };

    var userTagsPromise = null;
    $scope.loadTags = function(query) {
      if (!userTagsPromise) {
        userTagsPromise = productService.getUserTags();
      }
      query = query || '';
      return userTagsPromise.then(function(data) {
        var tags = _.map(convertFromUserTags(data.tags), function(tag) {
          return { text: tag };
        });
        return _.filter(tags, function(tag) {
          return tag.text.substring(0, query.length).toLowerCase() === query.toLowerCase();
        });
      });
    };

    $scope.$watchGroup(
      ['clipboard.userTags', 'clipboard.items.length'],
      function() {
        $scope.editingClipboardTags = $scope.clipboard.userTags.slice(0);
      },
      true
    );

    $scope.isClipboardTagsDirty = function() {
      return JSON.stringify(_.map($scope.editingClipboardTags, 'text')) !== JSON.stringify($scope.clipboard.userTags);
    };

    $scope.saveUserTags = function(styleIds, tagsToAdd, tagsToRemove) {
      tagsToAdd = tagsToAdd || [];
      tagsToRemove = tagsToRemove || [];
      productService.saveUserTagsByStyle(styleIds, tagsToAdd, tagsToRemove).then(function() {
        _($scope.clipboard.items)
          .map('item')
          .forEach(function(product) {
            product.tags = _.uniq((product.tags || []).concat(tagsToAdd));
            product.tags = _.difference(product.tags, tagsToRemove);
          });
        clipboardService.computeCommonUserTags();
        clipboardService.save();
        $scope.savingProductTags = false;
      });
    };

    $scope.applyUserTags = function() {
      if (!$scope.isClipboardTagsDirty()) {
        return;
      }
      $scope.savingProductTags = true;
      var editingTags = _.map($scope.editingClipboardTags, 'text');
      var dbTags = $scope.clipboard.userTags;
      var tagsToRemove = _.difference(dbTags, editingTags);
      var tagsToAdd = _.difference(editingTags, dbTags);
      tagsToRemove = convertToUserTags(tagsToRemove);
      tagsToAdd = convertToUserTags(tagsToAdd);
      var styleIds = _.map(_.map($scope.clipboard.items, 'item'), 'styleId');
      $scope.saveUserTags(styleIds, tagsToAdd, tagsToRemove);
    };

    function confirmChange(message, callback) {
      return dialogs({ ok: 'YES', cancel: 'NO', hostname: 'Please, confirm' }).confirm(message, callback);
    }

    $scope.applyChanges = function() {
      $scope.savingChanges = true;
      confirmChange('Are you sure you want to update all the selected items?', function(ok) {
        if (ok) {
          var changes = {
            curated: $scope.clipboard.curatedFlag,
            visible: $scope.clipboard.visibleFlag,
            overridden: {
              tags: $scope.clipboard.overriddenTagsFlag
            },
            tags: $scope.clipboard.tags || []
          };
          var styleIds = _.map(_.map($scope.clipboard.items, 'item'), 'styleId');
          productService.getByStyleIds(styleIds).then(function(products) {
            for (var i = 0; i < products.items.length; i++) {
              _.merge(products.items[i], changes);
              products.items[i].id = products.items[i].id.split('-')[0];
              productService.update(products.items[i]);
            }
          });
          $scope.saveUserTags(styleIds);
        }
        $scope.savingChanges = false;
      });
    };

    function convertToUserTags(tags) {
      return _.map(tags, function(tag) {
        return 'u.' + tag;
      });
    }

    function convertFromUserTags(userTags) {
      return _.map(userTags, function(userTag) {
        return userTag.replace(/^u\./, '');
      });
    }

    function getRankingId() {
      return $scope.getRankingId && $scope.getRankingId();
    }

    $scope.getListName = function() {
      var paramValues = _.merge(
        $state.getCurrentPath()[$state.getCurrentPath().length - 2].paramValues,
        $state.getCurrentPath()[$state.getCurrentPath().length - 1].paramValues
      );
      var listName = '';
      if (paramValues.brand) {
        listName += paramValues.brand + '/';
      }
      if ($state.current.name.split('.').includes('sale') || paramValues.sale) {
        listName += 'sale/';
      }
      if (paramValues.category1) {
        listName += paramValues.category1;
        if (paramValues.category2) {
          listName += '/' + paramValues.category2;
          if (paramValues.category3) {
            listName += '/' + paramValues.category3;
          }
        }
      } else {
        listName += 'whats-new';
      }
      return listName;
    };

    $scope.removeOffset = function(item) {
      var rankingId = getRankingId();
      if (!rankingId) {
        return;
      }
      var itemCurations = getCurations(item);
      var currentOffset = ((itemCurations.rankings || {})[rankingId] || {}).offset || 0;
      if (!currentOffset) {
        return;
      }
      var rankings = {};
      rankings[rankingId] = {
        offset: -currentOffset,
        position: 0
      };
      return curationService.setStyleCurations(item.styleId, { rankings: rankings }).then(function(response) {
        // update the score in client memory
        var position = response.rankings[rankingId].position;
        _.forEach(getProductPrivateData(item), function(stylePrivateData) {
          ((stylePrivateData.scores || {})[rankingId] || {}).value -= currentOffset;
          var rankingCuration = ((stylePrivateData.curations || {}).rankings || {})[rankingId] || {};
          rankingCuration.position = position;
          rankingCuration.offset = 0;
        });
        return response;
      });
    };

    // private methods

    function isSortableList() {
      return (
        (attrs.sortable ? $parse(attrs.sortable)($scope) : $scope.sortable) &&
        $scope.itemsPrivateData &&
        $scope.itemsPrivateData.length &&
        isUserAdmin() &&
        getRankingId()
      );
    }

    function areItemsSelectable() {
      return $scope.itemsPrivateData && $scope.itemsPrivateData.length && isUserAdmin();
    }

    function getItemsHash() {
      var items = getItems();
      if (!items || !items.length) {
        return '';
      }
      return items
        .map(function(item) {
          return item.styleId;
        })
        .join(',');
    }

    function getItems() {
      return attrs.items ? $parse(attrs.items)($scope) : $scope.products;
    }

    function flattenExpandedStyles(items) {
      if (!items) {
        return;
      }
      var appearing = false;
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.stylesExpanded) {
          if (item.otherStyles && items[i + 1] !== item.otherStyles[0]) {
            var spliceArgs = [i + 1, 0];
            spliceArgs.push.apply(spliceArgs, item.otherStyles);
            items.splice.apply(items, spliceArgs);
            i += item.otherStyles.length;
            item.otherStyles.forEach(function(otherStyle) {
              otherStyle.childStyle = true;
              otherStyle.listItemAppearing = true;
            });
            appearing = true;
          }
        } else {
          if (item.otherStyles && items[i + 1] === item.otherStyles[0]) {
            items.splice(i + 1, item.otherStyles.length);
          }
        }
      }
      if (appearing) {
        $timeout(function() {
          items.forEach(function(item) {
            delete item.listItemAppearing;
          });
        }, 50);
      }
    }

    function isUserAdmin() {
      return ($scope.getUser() || {}).isAdmin;
    }

    function getScore(item) {
      var score = _.max(
        _.map(_.compact(_.map(_.compact(_.map(getProductPrivateData(item), 'scores')), getRankingId())), 'value')
      );
      return score < 0 ? 0 : score;
    }

    function setScore(item, sideScores) {
      var rankingId = getRankingId();
      if (!rankingId) {
        return;
      }
      var targetScore =
        (sideScores.prev.score || sideScores.next.score) / 2 + (sideScores.next.score || sideScores.prev.score) / 2;

      var position = sideScores.prev.position / 2 + (sideScores.next.position || sideScores.prev.position) / 2;

      var itemCurations = getCurations(item);

      var rankings = {};
      rankings[rankingId] = {
        offset: targetScore - getScore(item),
        position: position
      };

      return curationService
        .setStyleCurations(item.styleId, {
          rankings: rankings
        })
        .then(function(response) {
          itemCurations.rankings = _.merge(itemCurations.rankings || {}, response.rankings);
          // update the score in client memory
          var offset = response.rankings && response.rankings[rankingId] && response.rankings[rankingId].offset;
          if (offset) {
            _.forEach(getProductPrivateData(item), function(stylePrivateData) {
              ((stylePrivateData.scores && stylePrivateData.scores[rankingId]) || {}).value += offset;
            });
          }
          return response;
        });
    }

    function getPosition(item, score) {
      var rankingId = getRankingId();
      if (!rankingId) {
        return 0;
      }
      var pos = _.min(
        _.compact(
          _.map(
            _.compact(
              _.map(
                _.compact(
                  _.map(
                    _.compact(
                      _.map(
                        _.filter(getProductPrivateData(item), function(style) {
                          return ((style.scores || {})[rankingId] || {}).value === score;
                        }),
                        'curations'
                      )
                    ),
                    'rankings'
                  )
                ),
                rankingId
              )
            ),
            'position'
          )
        )
      );
      return pos === Infinity ? 0 : pos;
    }

    function getCurations(item) {
      var product = getStylePrivateData(item);
      product.curations = product.curations || {};
      return product.curations;
    }

    function getStylePrivateData(item) {
      return _.find($scope.itemsPrivateData, { styleId: item.styleId }) || {};
    }

    function getProductPrivateData(item) {
      return _.filter($scope.itemsPrivateData || $scope.itemsPrivateData, { id: item.id.split('-')[0] });
    }

    function getSideScores(index, stylesCount) {
      var result = { prev: { score: 0, position: 0 }, next: { score: 0, position: 0 } };
      if (index) {
        var left = $scope.items[index - 1];
        result.prev.score = getScore(left);
        result.prev.position = getPosition(left, result.prev.score);
      }
      if (index < $scope.items.length - 2) {
        var right = $scope.items[index + stylesCount];
        result.next.score = getScore(right);
        result.next.position = getPosition(right, result.next.score);
      }
      return result;
    }

    function canItemBeAddedOnThisList(item) {
      if (!isSortableList()) {
        return false;
      }
      var category = _.compact(($scope.filters && $scope.filters.category) || []);
      if (category.length && _.intersection(item.tags, category).length < category.length) {
        return false;
      }
      var brands = ($scope.filters && $scope.filters.brands) || [];
      if ($state.current.name.indexOf('just-in') >= 0 && brands.length === 0) {
        brandService.getBrandsForWhatsNew().then(function(brandss) {
          brands = brandss;
        });
      }
      var brand = window.orchardMileContent.brands[brands && brands.length === 1 && brands[0]];
      var productsBrandData = window.orchardMileContent.brands[(item.brand && item.brand.id) || item.brandId];
      return !(
        brands.length &&
        brand &&
        !brand.store &&
        brands.indexOf((item.brand && item.brand.id) || item.brandId) < 0 &&
        brands.indexOf(productsBrandData && productsBrandData.store) < 0
      );
    }
  };
  return {
    link: linker,
    templateUrl: '/views/partials/product-list-items.html',
    restrict: 'E',
    scope: true,
    transclude: true
  };
}

module.exports = ProductListItemsDirective;
