var promises = require('../../async/promises');

// @ngInject
function FacebookLoginDirective(config, userService) {
  var fbConfigDefaults = {
    xfbml: false,
    version: 'v3.2',
    cookie: true,
    status: true,
    oauth: true
  };

  function isLoaded() {
    return !!document.getElementById('facebook-jssdk');
  }

  function insertSdk() {
    var fbScriptTag = document.createElement('script');
    fbScriptTag.id = 'facebook-jssdk';
    fbScriptTag.src = '//connect.facebook.net/en_US/sdk.js';
    var firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(fbScriptTag, firstScript);
  }

  function load() {
    var fbConfig = Object.assign({}, fbConfigDefaults, config.authentication.facebook);
    return promises.create(function(resolve, reject) {
      try {
        window.fbAsyncInit = function() {
          window.FB.init(fbConfig);
          resolve(window.FB);
        };
        if (isLoaded()) {
          resolve(window.FB);
        } else {
          insertSdk();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  function login() {
    return promises.create(function(resolve, reject) {
      return load().then(function(fb) {
        fb.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            resolve(response);
          } else {
            fb.login(function(loginResponse) {
              if (loginResponse.status === 'connected') {
                resolve(loginResponse);
              } else {
                reject(loginResponse);
              }
            }, { scope: 'email' });
          }
        });
      });
    });
  }

  var linker = function($scope) {
    $scope.login = function() {
      login()
        .then(function(response) {
          $scope.working = true;
          if (!response.authResponse) {
            throw new Error('facebook login didn\'t return an authResponse');
          }
          return userService.authenticate({
            signedRequest: response.authResponse.signedRequest,
            accessToken: response.authResponse.accessToken,
            provider: 'facebook',
            trigger: $scope.modal && $scope.modal.instanceData && $scope.modal.instanceData.options &&
              $scope.modal.instanceData.options.trigger
          });
        })
        .then(function() {
          $scope.working = false;
        })
        .catch(function() {
          $scope.working = false;
        });
    };
  };

  return {
    templateUrl: '/views/partials/facebook-login.html',
    restrict: 'E',
    link: linker
  };
}

module.exports = FacebookLoginDirective;
