var promises = require('../../async/promises');

// @ngInject
function GithubService($timeout, preloadService, userService, secretService) {

  var token;
  var Github;

  function getRepo(repoName) {

    return preloadService.bundle('editor').then(function() {
      if (!Github) {
        Github = window.orchardMileWebClient.editor.Github;
      }
      function makeRepo(githubToken) {
        token = githubToken;
        var github = new Github({
          token: token,
          auth: 'token'
        });
        return github.getRepo('orchardmile', repoName);
      }
      if (token) {
        return makeRepo(token);
      }
      return secretService.get('editor-github-token').then(makeRepo);
    });
  }

  this.read = function(repoName, path) {
    return getRepo(repoName).then(function(repo) {
      return promises.create(function(resolve, reject) {
        repo.read('master', path, function(err, data) {
          $timeout(function() {
            if (err) {
              reject(err);
            }
            resolve(data);
          });
        });
      });
    });
  };

  this.write = function(repoName, path, data) {
    return getRepo(repoName).then(function(repo) {
      return promises.create(function(resolve, reject) {
        var user = userService.getUser();
        repo.write('master', path, data, user.email + ' update to ' + path, function(err) {
          $timeout(function() {
            if (err) {
              return reject(err);
            }
            resolve();
          });
        });
      });
    });
  };

  this.getTree = function(repoName) {
    return getRepo(repoName).then(function(repo) {
      return promises.create(function(resolve, reject) {
        repo.getTree('master?recursive=true', function(err, data) {
          $timeout(function() {
            if (err) {
              reject(err);
            }
            resolve(data);
          });
        });
      });
    });
  };

}

module.exports = GithubService;
