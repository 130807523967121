/*
 * app configuration values
 *
 */
var _ = require('lodash-core');
var localConfigOverride = require('./local-config-override');

var all = {
  // base configuration for all environments
  base: function (appOptions) {
    return {
      siteName: 'orchardmile',
      alternativeEnvironmentName: true,
      testing: appOptions.testing,
      authentication: {
        facebook: {
          enabled: true,
          appId: '1741085872822110'
        }
      },
      recaptcha: {
        siteKey: '6Ldv698UAAAAANsaAh8HsBS3q-32FIvrcXmTcXq8'
      },
      platformSupport: {
        mobile: true,
        bots: true,
        userAgents: {
          allow: /Mozilla|Chrome|Safari|Firefox|FBAV|FBBV|FBAN|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i
          //disallow: /OPR|Edge/ // no Opera or MS Edge
        }
      },
      featureFlags: {
        myMilePromoLanguageRestriction: {
          description: 'Restrict My Mile Creation promotion for browsers with specifics language',
          enabled: false,
          languages: ['ko'],
          regex: /10.*[mile.*creation|first.*order]/i
        },
        headerMessageFreeShippingAndReturns: {
          description: 'if enabled, free shipping and returns is messaged in the header',
          enabled: true
        },
        headerMessageAvailableDiscount: {
          description: 'if enabled, user available discount is messaged in the header',
          enabled: true
        },
        saleSection: {
          description: 'enable sale section in the site',
          enabled: true
        },
        giftsSection: {
          description: 'enable gift section in the site',
          enabled: true
        },
        stories: {
          description: 'stories page (latest editorial pages)',
          enabled: true
        },
        hardcodedGiftsSection: {
          description: 'show hardcoded gift section ie: valentines day',
          enabled: false
        },
        sortByScoreOnRelevant: {
          // sort by whatsnew score on whats-new page
          description: 'select an score to sort when sort criteria is "Relevant" on whats-new page',
          enabled: true
        },
        sortByCategoriesScoreOnRelevant: {
          // sort by categories score on category pages
          description: 'select an score to sort when sort criteria is "Relevant" on category pages',
          enabled: true
        },
        sortByBrandScoreOnRelevant: {
          // sort by brands score on brand store pages
          description: 'select an score to sort when sort criteria is "Relevant" on brand store pages',
          enabled: true
        },
        sortByMultiBrandScoreOnRelevant: {
          // sort by brands score on brand store pages
          description: 'select an score to sort when sort criteria is "Relevant" on multi brand store pages',
          enabled: true
        },
        productListMoreColors: {
          description: 'show if MORE COLORS are available in product list items',
          enabled: true
        },
        productListMoreColorsNextStyle: {
          description: 'MORE COLORS link shows next style available',
          enabled: false
        },
        productListMoreColorsExpand: {
          description: 'MORE COLORS link expands other styles in the list',
          enabled: false
        },
        multipleItemsSort: {
          description: 'enable multiple items sort',
          enabled: true
        },
        giftCards: {
          description: 'using gift cards at checkout',
          enabled: true
        },
        tagging: {
          description: 'tagging system',
          enabled: true
        },
        searchBoxInHeader: {
          description: 'Adding the search box in header section',
          enabled: true
        },
        sloganExampleExperiment: {
          description: 'example experiment that alters the slogan, only for testing',
          enabled: true
        },
        alwaysVisibleTotalsExperiment: {
          description: 'experiment for making totals always visible during checkout',
          enabled: true
        },
        promoCode: {
          description: 'allow entering a promo code at checkout',
          enabled: true
        },
        urlRefererFriends: {
          description: 'Enable the shareable url for referer a friend',
          enabled: false
        },
        trackReferrers: {
          description: 'View of referrer tracking',
          enabled: false
        },
        loyalty: {
          description: 'Loyalty Program',
          enabled: true,
          awardsEnabled: [
            'shopTheMile',
            'createAnAccount',
            'downloadTheMileApp',
            'writeAProductReview',
            'subscribeToEmail',
            // 'followOnInstagram',
            // 'likeOnFacebook',
            //  'followOnTikTok',
            //  'followOnYoutube',
            'birthdayGift',
            //'shareContent',
            'postReel',
            //  'appReview',
            'referAFriend',
            'subscribeToSms'
          ]
        },
        sweepstakesTickets: {
          description: 'Sweepstakes Tickets Program',
          enabled: false
        },
        brandSocialButton: {
          description: 'Shop Instagram button below the brand hero',
          enabled: true
        },
        deprioritizeSales: {
          description: 'avoid show the style in sale on product lists',
          enabled: true
        },
        productReviews: {
          description: 'show/hide product review on pdp',
          enabled: true
        },
        stripeQuickPaymentOnCart: {
          description: 'show/hide quick payment option in CART',
          enabled: true
        },
        holidayDropdown: {
          description: 'Holiday dropdown in the header',
          enabled: false
        },
        shopByDropdown: {
          description: 'Shop By dropdown in the header',
          enabled: false
        },
        editorialDropdown: {
          description: 'Editorial dropdown in the header',
          enabled: false
        },
        homeBanner: {
          description: 'Home banner',
          enabled: true
        },
        saleBanner: {
          description: 'Sale banner',
          enabled: false
        },
        onlyKids: {
          description: 'Brands with kids products',
          enabled: true
        },
        designersLinksInDropdown: {
          description: 'DESIGNERS link and View All in category dropdowns',
          enabled: true
        },
        percentInsteadOfCreditOff: {
          description: 'Changes $ credit off for % off',
          enabled: false
        },
        welcomeReward: {
          description: 'Changes $ credit off for % off',
          enabled: false
        },
        taxJarRefactor: {
          description: 'Correct tax calculation',
          enabled: true
        },
        guestCartPersist: {
          enabled: true
        },
        packedItems: {
          description: 'Allows pack items of same fulfiller on different packages',
          enabled: true
        },
        birthdayField: {
          description: 'Shows birthday field on user\'s account',
          enabled: true
        },
        showPopup: {
          description: 'To show popups in orchard-mile-content',
          enabled: true
        },
        shopRunner: {
          description: 'Shop runner banners',
          excludedBrands: [
            'chanel-beauty',
            'chloe',
            'dermadoctor',
            'dolce-and-gabbana',
            'salvatore',
            'ferragamo',
            'benedicte-de-boysson',
            'lighting-reimagined'
          ],
          enabled: true
        },
        edits: {
          enabled: true
        },
        domesticShipmentSplit: {
          enabled: true,
          description: 'Allow split items of a fulfiller to ship on the different packages and modifies the UI'
        },
        priceVariationMessagePLP: {
          enabled: true,
          description: 'Show if the price between variants are different a message'
        },
        infoPromoPerVariant: {
          enabled: true,
          description: 'Show promo information per style/variant'
        },
        searchByCategories: {
          enabled: false,
          description: 'if the user enter a category its redirected to the category PLP'
        },
        searchByBrand: {
          enabled: true,
          description: 'if the user enter a brand its redirected to the brand PLP'
        },
        infiniteScroll: {
          description: 'infinite scroll feature for this moment only in sale',
          states: ['root.sale'],
          enabled: true
        },
        searchPreview: {
          description: 'show a preview of search like reflektion',
          enabled: true
        },
        mayWeSuggest: {
          enabled: true
        },
        birthdayAward: {
          description: 'show the birthday surprise on omrewards',
          enabled: true
        },
        createStripeCards: {
          description: 'allows an admin user to create a credit card in stripe',
          enabled: true
        },
        removeItemCancelledEvents: {
          description: 'allows an admin user to remove item-cancelled events',
          enabled: true
        },
        translations: {
          description: 'flag to select language for others countries out of EE.UU.',
          countries: [
            {
              code: 'kr',
              language: 'ko',
              currency: 'KRW',
              header: 'ko-KR'
            },
            {
              code: 'us',
              language: 'en',
              currency: 'USD',
              header: 'en-US'
            },
            {
              code: 'au',
              language: 'en',
              currency: 'AUD',
              header: 'en-AU'
            },
            {
              code: 'ca',
              language: 'en',
              currency: 'CAD',
              header: 'en-CA'
            }
          ],
          enabled: true
        },
        citcon: {
          enabled: true,
          urlOnCheckout: true,
          description: 'allow to use international payments with citcon as a provider'
        },
        internationalBlockedBrands: {
          enabled: true,
          rules: [
            {
              countries: [
                'au', 'cn', 'de', 'es', 'fr', 'gb', 'hk', 'id', 'in', 'it', 'jp', 'nz', 'pl', 'pt', 'sg',
                'th', 'mx', 'ca', 'at', 'be', 'ch', 'nl', 'se', 'gr', 'ua', 'fi', 'ie', 'bd', 'ph', 'ae',
                'tr', 'kw', 'sa', 'il', 'tw', 'qa', 'pr', 'pa', 'gd', 'do', 'br', 'co', 've', 'za', 'ng'
              ],
              brands: ['chanel-beauty']
            },
            {
              countries: ['kr'],
              brands: [
                'valentino',
                'chanel-beauty',
                'dolce-and-gabbana',
                'lagos',
                'dolce-and-gabbana-kids',
                'oscar-de-la-renta',
                'valentino-garavani',
                'emporio-armani',
                'emporio-armani-men',
                'emporio-armani-kids',
                'giorgio-armani-sunglasses',
                'armani',
                'armani-men',
                'ea7',
                'ea7-men',
                'ea7-kids'
              ]
            }
          ]
        },
        internationalShipping: {
          description: 'allows shipping on another countries out of EE.UU.',
          countries: [
            { code: 'us', name: 'United States of America', state: 'specific' },
            { code: 'au', name: 'Australia', state: 'specific' },
            { code: 'at', name: 'Austria', state: 'not-required' },
            { code: 'bd', name: 'Bangladesh', state: 'not-required' },
            { code: 'be', name: 'Belgium', state: 'not-required' },
            { code: 'br', name: 'Brazil', state: 'specific' },
            { code: 'ca', name: 'Canada', state: 'specific' },
            { code: 'cn', name: 'China', state: 'required' },
            { code: 'co', name: 'Colombia', state: 'required' },
            { code: 'do', name: 'Dominican Republic', state: 'not-required' },
            { code: 'fi', name: 'Finland', state: 'not-required' },
            { code: 'fr', name: 'France', state: 'not-required' },
            { code: 'de', name: 'Germany', state: 'not-required' },
            { code: 'gr', name: 'Greece', state: 'not-required' },
            { code: 'hk', name: 'Hong Kong', state: 'not-required' },
            { code: 'in', name: 'India', state: 'not-required' },
            { code: 'id', name: 'Indonesia', state: 'not-required' },
            { code: 'ie', name: 'Ireland', state: 'not-required' },
            { code: 'il', name: 'Israel', state: 'not-required' },
            { code: 'it', name: 'Italy', state: 'required' },
            { code: 'jp', name: 'Japan', state: 'required' },
            { code: 'kw', name: 'Kuwait', state: 'not-required' },
            { code: 'mx', name: 'Mexico', state: 'required' },
            { code: 'nl', name: 'Netherlands', state: 'not-required' },
            { code: 'nz', name: 'New Zealand', state: 'not-required' },
            { code: 'ng', name: 'Nigeria', state: 'not-required' },
            { code: 'pa', name: 'Panama', state: 'not-required' },
            { code: 'ph', name: 'Philippines', state: 'not-required' },
            { code: 'pl', name: 'Poland', state: 'not-required' },
            { code: 'pt', name: 'Portugal', state: 'not-required' },
            { code: 'pr', name: 'Puerto Rico', state: 'not-required' },
            { code: 'qa', name: 'Qatar', state: 'not-required' },
            { code: 'sa', name: 'Saudi Arabia', state: 'not-required' },
            { code: 'sg', name: 'Singapore', state: 'not-required' },
            { code: 'kr', name: 'South Korea', state: 'required' },
            { code: 'za', name: 'South Africa', state: 'not-required' },
            { code: 'es', name: 'Spain', state: 'not-required' },
            { code: 'se', name: 'Sweden', state: 'not-required' },
            { code: 'ch', name: 'Switzerland', state: 'not-required' },
            { code: 'tw', name: 'Taiwan', state: 'not-required' },
            { code: 'th', name: 'Thailand', state: 'not-required' },
            { code: 'tr', name: 'Turkey', state: 'not-required' },
            { code: 'ua', name: 'Ukraine', state: 'not-required' },
            { code: 'ae', name: 'United Arab Emirates', state: 'not-required' },
            { code: 'gb', name: 'United Kingdom', state: 'not-required' },
            { code: 've', name: 'Venezuela', state: 'not-required' }
          ],
          address: {
            fullName: 'The Mile',
            email: 'concierge@orchardmile.com',
            address1: '4293 Express Lane',
            address2: 'Suite 9963-854',
            city: 'Sarasota',
            state: 'FL',
            zip: '34249',
            country: 'US',
            phone: '1 941 227 4444'
          },
          enabled: true
        },
        shippingFilter: {
          description: 'allows customers to filter by shipping methods on PLPs (except brands PLPs)',
          enabled: true
        },
        specialShipping: {
          description: 'enable the special shipping options for alaska and hawaii',
          enabled: true
        },
        fulfillerPrivacyPolicy: {
          description: 'new links on shipping options per partner with privacy policy link',
          enabled: true
        },
        loginEnabled: {
          enabled: true,
          copy: 'We’re currently undergoing routine maintenance, you will be able to scroll your mile within 24hs.'
        },
        riskAnalysisToProcess: {
          provider: 'signifyd'
        },
        backToAvailableAlert: {
          description: 'show notify me when available alert button',
          enabled: true
        },
        saveForLater: {
          enabled: true
        },
        attachOrder: {
          description: 'allow admin to attach an extra order to a orchardmile order',
          enabled: true
        },
        unmatchedRefunds: {
          description: 'allow admin to get access to the unmatched refunds view',
          enabled: true
        },
        inteliStyleWidget: {
          description: 'widget coming from instelistyle with products my we suggest',
          enabled: true
        },
        wearItWith: {
          description: 'wear it with',
          enabled: false
        },
        stripeQuickPayment: {
          description: 'stripe quick payments',
          enabled: true
        },
        stripeWidget: {
          description: 'stripe after pay widget on pdp',
          enabled: true
        },
        paypal: {
          description: 'paypal payment method',
          enabled: true
        },
        inStore: {
          enabled: true,
          brandIds: ['oscar-de-la-renta', 'scanlan-theodore']
        },
        menCategory: {
          enabled: true
        },
        multilanguage: {
          description: 'multilanguage support',
          enabled: true
        },
        editorialHomepageTiles: {
          description: 'editorial HP tiles test',
          enabled: true
        },
        popupChanelState: {
          description: 'popup for chanel blocked states',
          enabled: false
        },
        theMileInfo: {
          description: 'the mile static data',
          enabled: true,
          featuredBrands: [
            'alexis',
            'alice-and-olivia',
            'anine-bing',
            'dolce-and-gabbana',
            'armani',
            'ba-and-sh',
            'khaite',
            'paige',
            'rebecca-minkoff',
            'alexandre-birman',
            'solid-and-striped',
            'st-tropez',
            'vince',
            'zadig-et-voltaire',
            'iro',
            'sandro',
            'maje',
            'chanel-beauty'
          ],
          influencers: [],
          homePage: {
            influencerId: '642ac845411db2a342a808d3',
            brandId: 'vitality'
          },
          bannerShowsIds: [
            '64f63aa3a45f282615849e5e',
            '642f374aca27b71c725badfd',
            '63e2bcd3415ead1da511c860',
            '65426a15a1b66b55ddefb0e8'
          ],
          trendingShows: [
            '64f63aa3a45f282615849e5e',
            '642f374aca27b71c725badfd',
            '63e2bcd3415ead1da511c860',
            '65426a15a1b66b55ddefb0e8'
          ]
        },
        theMileLogo: {
          description: 'Show The Mile Logo in the header',
          enabled: true
        },
        theMilePromotionBanner: {
          description: 'Show The Mile Logo in pdp and brand page',
          enabled: true,
          blockedBrands: [
            'valentino-men',
            'valentino',
            'valentino-garavani',
            'dolce-and-gabbana',
            'oscar-de-la-renta',
            'chanel-beauty'
          ]
        },
        floatingVideo: {
          description: 'Show the floating video on the mile banner',
          enabled: true
        },
        influencerBrands: {
          description: 'Show a special PLP page for Diamond Brands',
          brands: [
            {
              brandId: 'sincerely-ria',
              influencerId: '649dc559d2e3e720ac390713'
              // },
              // {
              //   brandId: 'lapo-lounge',
              //   influencerId: '65119fc3309f3c696bcbdfc9'
            }
          ],
          enabled: true
        },
        showBrandHeroImage: {
          description: 'show brand hero images',
          enabled: true,
          exceptions: ['balmain-kids', 'alala']
        },
        promoteLoyaltyProgramPdp: {
          description: 'Loyalty program on PDPs',
          enabled: true
        },
        promoteLoyaltyProgramCart: {
          description: 'Loyalty program on cart',
          enabled: true
        },
        theMileAddedToCartMessage: {
          description: 'John Doe from Texas Added to card kind of messages in the mile live shows',
          enabled: true,
          names: [
            'Mary',
            'Elizabeth',
            'Patricia',
            'Jennifer',
            'Linda',
            'Barbara',
            'Susan',
            'Margaret',
            'Jessica',
            'Sarah',
            'Karen',
            'Nancy',
            'Lisa',
            'Betty',
            'Sandra',
            'Ashley',
            'Dorothy',
            'Kimberly',
            'Emily',
            'Donna',
            'Michelle',
            'Carol',
            'Amanda',
            'Melissa',
            'Deborah',
            'Stephanie',
            'Rebecca',
            'Laura',
            'Sharon',
            'Cynthia',
            'Kathleen',
            'Amy',
            'Shirley',
            'Angela',
            'Helen',
            'Anna',
            'Brenda',
            'Pamela',
            'Nicole',
            'Ruth',
            'Katherine',
            'Samantha',
            'Christine',
            'Emma',
            'Catherine',
            'Debra',
            'Virginia',
            'Rachel',
            'Carolyn',
            'Janet'
          ],
          states: [
            'New York',
            'California',
            'Florida',
            'Texas',
            'Massachusetts',
            'New Jersey',
            'Ohio',
            'Washington',
            'Illinois',
            'Michigan'
          ]
        }
      },
      experiments: {
        'authentication-modal': {
          enabled: false,
          audience: 1, // 20%
          variations: {
            register: {}
          }
        }
      },
      apiBaseUrl: 'https://api-staging.orchardmile.com:443/',
      images: {
        providerName: 'cloudfront',
        defaults: {
          width: 600,
          aspectRatio: 1.5
        },
        cloudfront: {
          baseUrl: 'https://static-staging.orchardmile.us/'
        },
        cloudinary: {
          cloudName: 'orchard-mile',
          folder: 'static-staging'
        }
      },
      payment: {
        providerName: 'stripe',
        allProviders: {
          // config settings shared by all providers
          testMode: true
        },
        stripe: {
          testPublicKey: 'pk_test_xzvp3oj2uJMaFosHCQQ1oGuO00homzBhko',
          publicKey: 'pk_live_0hatfZUTFLfrr9uFo3PWuRrE00R6Ex6S2k'
        },
        giftCard: {
          status: 'test'
        }
      },
      orderProcessing: {
        blockOrderProcessingUntilRiskAnalysisResponse: false
      },
      algolia: {
        applicationId: 'AFSJE2AQWY',
        apiKey: '80634bc6ea170c59c0d2b0dea52dfbb7',
        index: 'products-staging',
        redirectIndex: 'redirects-staging'
      },
      agora: {
        appId: 'fb4f93fb3a2f490292be8c6b003f0398'
      },
      streamChat: {
        apiKey: process.env.STREAM_CHAT_API_KEY || 'awysz22j8qxv'
      },
      robots: 'index, follow',
      facetFiltering: {
        delayDesktop: 500,
        delayMobile: 3000
      }
    };
  },

  // environmental overrides
  local: function (base) {
    return _.merge(
      base,
      {
        debug: true,
        minification: false,
        recaptcha: {
          siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        },
        analytics: {
          ga: {
            trackingId: 'G-51DWB770ED'
          },
          console: true,
          milePopulation: {
            enabled: false
          }
        },
        featureFlags: {
          theMileInfo: {
            influencers: [
              '642ac845411db2a342a808d3',
              '647fa524c8836725e4a23cb6',
              '64d285c9911bcbaeef74b350',
              '65119fc3309f3c696bcbdfc9',
              '647fc968c8836725e4a24399',
              '649dc559d2e3e720ac390713',
              '648125bc8229649ef78ce4f4',
              '647e75a98d90066522f8f08b',
              '632a2738f7d859a789b49b7d',
              '632dbc620a92113a3d3be1c8',
              '632e6c290a92113a3d3cd0cc',
              '632f0a598822b383d23c7c08',
              '632f1b8c8822b383d23c7caa',
              '6330718da596c571bcb747d1',
              '633073e3a596c571bcb74857',
              '63318b30979f5de53cce6efc',
              '6331f9a66d3be99b7c83377a',
              '6332260e6d3be99b7c8370f7',
              '633230266d3be99b7c837589',
              '6334aade1f14dadce06713d0',
              '63446c915c4cf525dfe8832d'
            ],
            trendingShows: []
          },
          internationalPayments: {
            description: 'allows payments on another countries out of EE.UU.',
            paymentMethods: [
              {
                provider: 'citcon',
                currency: 'KRW',
                methods: [
                  { id: 'card', logo: 'icard.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 1 },
                  {
                    id: 'kakaopay',
                    logo: 'kakaopay.png',
                    data: ['auto_capture', 'goods', 'consumer', 'payment_extra'],
                    authKey: 2
                  },
                  { id: 'samsungpay', logo: 'samsungp.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 3 },
                  { id: 'naverpay', logo: 'npay.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 1 },
                  { id: 'payco', logo: 'payco.jpg', data: ['goods', 'consumer', 'payment_extra'], authKey: 3 }
                ]
              },
              {
                provider: 'citcon',
                currency: 'USD',
                methods: [{ id: 'paypal', logo: 'paypal.svg', data: ['auto_capture_false'] }]
              }
            ],
            enabled: true
          },
          influencerBrands: {
            description: 'Show a special PLP page for Diamond Brands',
            brands: [
              {
                brandId: 'sincerely-ria',
                influencerId: '649dc559d2e3e720ac390713'
                // },
                // {
                //   brandId: 'lapo-lounge',
                //   influencerId: '65119fc3309f3c696bcbdfc9'
              }
            ],
            enabled: true
          }
        },
        cartReloadInterval: 5000
      },
      localConfigOverride.get(true) || {}
    );
  },

  ci: function (base) {
    return _.merge(base, {
      debug: true
    });
  },

  staging: function (base) {
    return _.merge(
      base,
      {
        debug: true,
        restrictedAccessPasswords: ['rekcufrehtomgnimmargorp', 'nehctikemil'],
        userDisplayNameSuffix: ' (staging)',
        recaptcha: {
          siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        },
        analytics: {
          console: true,
          ga: {
            trackingId: 'G-51DWB770ED'
          },
          trackJs: {
            application: 'web-client-staging'
          },
          segmentIo: {
            writeKey: 'Uu1GcCPua1VE8Pn8RTAPreYzqEDsEtRQ'
          },
          inside: {
            enabled: false,
            id: '1000636'
          },
          milePopulation: {
            enabled: true
          },
          jewel: {
            enabled: false,
            id: '5d0a89f571a11958047f444b'
          },
          signifyd: {
            enabled: true
          },
          shareASale: {
            enabled: false
          }
        },
        featureFlags: {
          floatingVideo: {
            enabled: true
          },
          theMileInfo: {
            influencers: [
              '642ac845411db2a342a808d3',
              '647fa524c8836725e4a23cb6',
              '647fc968c8836725e4a24399',
              '65119fc3309f3c696bcbdfc9',
              '64d285c9911bcbaeef74b350',
              '64c2d3cb51e3ec3ec52958b6',
              '649dc559d2e3e720ac390713',
              '648125bc8229649ef78ce4f4',
              '647e75a98d90066522f8f08b',
              '632a2738f7d859a789b49b7d',
              '632dbc620a92113a3d3be1c8',
              '632e6c290a92113a3d3cd0cc',
              '632f0a598822b383d23c7c08',
              '632f1b8c8822b383d23c7caa',
              '6330718da596c571bcb747d1',
              '633073e3a596c571bcb74857',
              '63318b30979f5de53cce6efc',
              '6331f9a66d3be99b7c83377a',
              '6332260e6d3be99b7c8370f7',
              '633230266d3be99b7c837589',
              '6334aade1f14dadce06713d0',
              '63446c915c4cf525dfe8832d'
            ],
            trendingShows: [
              '64f63aa3a45f282615849e5e',
              '642f374aca27b71c725badfd',
              '63e2bcd3415ead1da511c860',
              '65426a15a1b66b55ddefb0e8',
              '6528110eb5c96496bc992cc3',
              '6511a5f1309f3c696bcbffdc',
              '6542961a3edee66d00c543a8'
            ]
          },
          internationalPayments: {
            description: 'allows payments on another countries out of EE.UU.',
            paymentMethods: [
              {
                provider: 'citcon',
                currency: 'KRW',
                methods: [
                  { id: 'card', logo: 'icard.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 1 },
                  {
                    id: 'kakaopay',
                    logo: 'kakaopay.png',
                    data: ['auto_capture', 'goods', 'consumer', 'payment_extra'],
                    authKey: 2
                  },
                  { id: 'samsungpay', logo: 'samsungp.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 3 },
                  { id: 'naverpay', logo: 'npay.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 1 },
                  { id: 'payco', logo: 'payco.jpg', data: ['goods', 'consumer', 'payment_extra'], authKey: 3 }
                ]
              },
              {
                provider: 'citcon',
                currency: 'USD',
                methods: [{ id: 'paypal', logo: 'paypal.svg', data: ['auto_capture_false'] }]
              }
            ],
            enabled: true
          },
          influencerBrands: {
            description: 'Show a special PLP page for Diamond Brands',
            brands: [
              {
                brandId: 'sincerely-ria',
                influencerId: '649dc559d2e3e720ac390713'
                // },
                // {
                //   brandId: 'lapo-lounge',
                //   influencerId: '65119fc3309f3c696bcbdfc9'
              }
            ],
            enabled: true
          }
        }
      },
      localConfigOverride.get(true) || {}
    );
  },

  production: function (base) {
    return _.merge(
      base,
      {
        alternativeEnvironmentName: false,
        featureFlags: {
          giftsSection: {
            enabled: true
          },
          productListMoreColors: {
            enabled: true
          },
          productListMoreColorsExpand: {
            enabled: false
          },
          sloganExampleExperiment: {
            // this is a testing experiment, NEVER enable this in production!
            enabled: false
          },
          productHasSeveralColors: {
            enabled: true
          },
          relatedBrands: {
            enabled: false
          },
          taxJarRefactor: {
            enabled: true
          },
          urlRefererFriends: {
            enabled: false
          },
          trackReferrers: {
            enabled: false
          },
          loyalty: {
            enabled: true
          },
          sweepstakesTickets: {
            enabled: false
          },
          myMilePromoLanguageRestriction: {
            enabled: false
          },
          guestCartPersist: {
            enabled: false
          },
          packedItems: {
            enabled: true
          },
          birthdayField: {
            enabled: true
          },
          showPopup: {
            enabled: true
          },
          edits: {
            enabled: true
          },
          holidayDropdown: {
            enabled: false
          },
          priceVariationMessagePLP: {
            enabled: true
          },
          infoPromoPerVariant: {
            enabled: true
          },
          domesticShipmentSplit: {
            enabled: true
          },
          searchByCategories: {
            enabled: false
          },
          searchByBrand: {
            enabled: true
          },
          infiniteScroll: {
            enabled: false
          },
          internationalShipping: {
            enabled: true
          },
          translations: {
            enabled: true
          },
          citcon: {
            enabled: true
          },
          internationalPayments: {
            paymentMethods: [
              {
                provider: 'citcon',
                currency: 'KRW',
                methods: [
                  { id: 'card', logo: 'icard.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 1 },
                  { id: 'samsungpay', logo: 'samsungp.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 1 },
                  {
                    id: 'kakaopay',
                    logo: 'kakaopay.png',
                    data: ['auto_capture', 'goods', 'consumer', 'payment_extra'],
                    authKey: 2
                  },
                  { id: 'naverpay', logo: 'npay.png', data: ['goods', 'consumer', 'payment_extra'], authKey: 3 },
                  { id: 'payco', logo: 'payco.jpg', data: ['goods', 'consumer', 'payment_extra'], authKey: 3 }
                ]
              }
            ],
            enabled: true
          },
          inteliStyleWidget: {
            description: 'widget coming from instelistyle with products my we suggest',
            enabled: false
          },
          createStripeCards: {
            enabled: true
          },
          fulfillerPrivacyPolicy: {
            enabled: true
          },
          riskAnalysisToProcess: {
            provider: 'signifyd'
          },
          inStore: {
            enabled: false
          },
          multilanguage: {
            enabled: false
          },
          editorialHomepageTiles: {
            enabled: true
          },
          popupChanelState: {
            enabled: false
          },
          promoteLoyaltyProgramPdp: {
            enabled: true
          },
          promoteLoyaltyProgramCart: {
            enabled: true
          },
          cashAppPaymentMethod: {
            enabled: true
          },
          shopRunner: {
            enabled: true
          },
          floatingVideo: {
            enabled: true
          },
          theMileInfo: {
            enabled: true,
            influencers: [
              '6411cf07fe1c68cd45f13c3c',
              '649b5f253419707356ac8cd8',
              '646cddde9fe9ad07f73d5a1f',
              '649e1564f7b1c621b215684e',
              '63bd9fe972ba0823d6e0f07e',
              '64a987f7bbb9708445e72e3c',
              '6480a48138075154137a2018',
              '63d2933483f8072d29793f8c',
              '63a4a72ae87f7c4bc3bb6b67',
              '639dd7fd16268a4e3652cd5c',
              '63b2cde0a4f7130b62c48b4d',
              '63d0684b4a27a5e5b200ae63',
              '63d0500ddcda3e2ab9950862',
              '63571847353231b23fd68018',
              '63f659f380fe41d5174f84f4'
            ],
            bannerShowsIds: [
              '66ddf46fa33663db4f920748',
              '66d7c96e1e89d81db739babd',
              '66d8c5b73010cdb7b7161e07',
              '66d88da82acb0d73d61b4d65'
            ],
            trendingShows: [
              '6705cc82df7f997743d9e677',
              '67004d3b3d2991435755323e',
              '66f5a7a0cdf1b676c2e54cd7',
              '66f33aa675425a72370dfb82',
              '66f55060cdf1b676c2d47451',
              '66f4864e522d703600728031',
              '66f49f9f94705ccd0dd97ed3',
              '66f1e1878a811be9645d5e11',
              '66f4372efe9403310095b778',
              '66f314dd75425a72370ba543'
            ],
            homePage: {
              influencerId: '6411cf07fe1c68cd45f13c3c',
              brandId: 'vitality'
            }
          },
          theMileLogo: {
            enabled: true
          },
          influencerBrands: {
            description: 'Show a special PLP page for Diamond Brands',
            brands: [
              {
                brandId: 'sincerely-ria',
                influencerId: '649b5f253419707356ac8cd8'
                // },
                // {
                //   brandId: 'lapo-lounge',
                //   influencerId: '65110ddc64259c0dc88b6027'
              }
            ],
            enabled: true
          }
        },
        experiments: {
          'authentication-modal': {
            enabled: false
          }
        },
        apiBaseUrl: 'https://api.orchardmile.com/',
        images: {
          cloudfront: {
            baseUrl: 'https://static.orchardmile.us/'
          },
          cloudinary: {
            folder: 'static'
          }
        },
        algolia: {
          applicationId: 'RA8BA33XSG',
          apiKey: '36c6fdde1e6f1f987ecafab1374148b8',
          index: 'products',
          redirectIndex: 'redirects'
        },
        payment: {
          allProviders: {
            testMode: false
          }
        },
        recaptcha: {
          siteKey: '6Ld0lNwUAAAAAA6X-pW9xfTn9u3HTlvbfabiFRtT'
        },
        analytics: {
          ga: {
            trackingId: 'G-59NYTB8YHV'
          },
          trackJs: {
            application: 'web-client-production'
          },
          segmentIo: {
            writeKey: 'IDDiieGBnWPbvJKpDG0GzEp6fuETxx2h'
          },
          bam: {
            id: 'orchardmile',
            enabled: false
          },
          fullstory: false,
          milePopulation: {
            enabled: true
          },
          pinterest: {
            enabled: false,
            id: '2617929500854'
          },
          bing: {
            enabled: false,
            id: '26018695'
          },
          jewel: {
            enabled: false,
            id: '5d0a8a0671a11958047f4453'
          },
          signifyd: {
            enabled: true
          },
          shareASale: {
            enabled: true
          }
        }
      },
      localConfigOverride.get(true) || {}
    );
  }
};

module.exports = all;
