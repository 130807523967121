// @ngInject
function SaleBannerDirective() {
  var linker = function($scope) {
    $scope.saleBanner = window.orchardMileContent.home.saleBanner;
  };

  return {
    link: linker,
    templateUrl: '/views/partials/sale-banner.html',
    restrict: 'E'
  };
}

module.exports = SaleBannerDirective;

