var _ = require('lodash-core');

/* jshint maxparams: 7 */
// @ngInject
function CartController($scope, $rootScope, cartService, userService, $state, shopModalService, klaviyoService) {

  /*
  CASE A: what we have now with the copy free shipping and free returns.
  CASE B: with link to Save for Later (Wishlist) items.
  */
  $scope.saveForLaterABTest = 'a';
  $scope.saveForLaterGuestABTest = 'a';

  $scope.cartData = cartService.getData();
  $scope.cartTotals = cartService.getTotals();

  var user = userService.getUser();
  $scope.userIsAnonymous = !user || !user.token;
  var getNotForLaterItems = function() {
    return $scope.cartData.items.filter(function(item) {
      return !cartService.isForLater(item);
    });
  };
  $scope.isEmpty = function() {
    return getNotForLaterItems().length < 1;
  };
  $scope.isLoggedIn = userService.isLoggedIn;
  $scope.isBagActive = function() {
    return cartService.bagActive;
  };
  $scope.setBagActive = function(active) {
    return cartService.setBagActive(active);
  };
  $scope.getCartQty = function() {
    var qty = 0;
    getNotForLaterItems().forEach(function(item) {
      qty += item.quantity;
    });
    return qty;
  };

  $scope.getSavedForLaterItems = function() {
    var forLaterItems = $scope.cartData.items.filter(cartService.isForLater);
    if (forLaterItems.length === 0) {
      cartService.setBagActive(true);
    }
    return forLaterItems;
  };
  $scope.getSavedForLaterItemsQty = function() {
    var qty = 0;
    $scope.getSavedForLaterItems().forEach(function(item) {
      qty += item.quantity;
    });
    return qty;
  };

  $scope.remove = function(item) {
    cartService.remove({
      styleId: item.styleId,
      variant: item,
      quantity: item.quantity,
      metadata: item.metadata,
      forLater: item.forLater,
      source: 'cart'
    });
  };

  $scope.hasToRedeem = function() {
    if ($rootScope.loyalty && $rootScope.loyalty.remainingPoints && $rootScope.loyalty.remainingPoints > 499) {
      var value = Math.floor($rootScope.loyalty.remainingPoints/500)*25;
      if (value > 250) {
        return 250 + $scope.calculateTotalInCurrency(250);
      } else {
        return value + $scope.calculateTotalInCurrency(value);
      }
    } else {
      return 0;
    }
  };

  $scope.calculateTotalInCurrency = function(total) {
    if ($rootScope.currency && $rootScope.country && $rootScope.country.code !== 'us') {
      return ' (' + $rootScope.currency.symbol + Math.round($rootScope.currency.conversionRate*Number(total)) + ')';
    } else {
      return '';
    }
  };

  $scope.getDiscountReason = cartService.getDiscountReason;

  $scope.isAppliedRedeem = function () {
    return $scope.getDiscountReason() && $scope.getDiscountReason().includes('Loyalty Reward Card');
  };

  $scope.checkout = function() {
    shopModalService.goToCheckout();
    klaviyoService.trackStartedCheckout(cartService.getTotals(), cartService.getData());
    if (!$rootScope.isShow()) {
      $scope.modal.hide();
    }
    var current = $state.current;
    if (current && current.name.indexOf('root.checkout') !== -1) {
      return true;
    }
    if (!userService.isLoggedIn()) {
      userService.guestCheckout(user.email || '').then(function() {
        if ($rootScope.isShow()) {
          cartService.setStep('checkout');
        } else {
          $state.go('root.checkout.shipping');
        }
      });
    } else {
      if ($rootScope.isShow()) {
        cartService.setStep('checkout');
      } else {
        $state.go('root.checkout.shipping');
      }
    }
  };
  $scope.isSoldOut = cartService.isSoldOut;
  $scope.isForLater = cartService.isForLater;
  $scope.isBrandVisible = cartService.isBrandVisible;
  $scope.isBrandAvailable = cartService.isBrandAvailable;
  $scope.isPurchasable = cartService.isPurchasable;

  $scope.showSoldOut = function(item) {
    return !$scope.isPurchasable(item);
  };

  function getBrandsToFilter() {
    var rule = _.find($rootScope.featureFlags.internationalBlockedBrands.rules, function (rule) {
      return rule.countries.includes($rootScope.countrySelected());
    });
    if (rule && $rootScope.featureFlags.internationalBlockedBrands.enabled) {
      return rule.brands;
    } else {
      return [];
    }
  }

  $scope.showBlockedByCountry = function(item) {
    var brand = item.brandId || (item.brand && item.brand.id) || item.getBrand && item.getBrand() && item.getBrand().id;
    return getBrandsToFilter().includes(brand);
  };

  $scope.showSoldOutForLater = function(item) {
    return $scope.isSoldOut(item) || !$scope.isBrandVisible(item) || !$scope.isBrandAvailable(item);
  };

  $scope.isCheckoutBlocked = function() {
    return $rootScope.calculatingTotals || !!_.find($scope.cartData.items, function(item) {
      return !item.forLater && item.getBrandItemMaxQty && item.getBrandItemMaxQty() < item.quantity;
    });
  };

  $scope.soldOutMessage = cartService.getSoldOutMessage;
}

module.exports = CartController;
