
// @ngInject
function OrdinalFilter(formatService) {

  return function(input) {
    var number;
    if (typeof input === 'number') {
      number = input;
    } else if (typeof input === 'string' && /\d+/.test(input)) {
      number = +input;
    } else {
      return input;
    }
    return number + formatService.nthSuffix(number);
  };
}

module.exports = OrdinalFilter;
