
var EventEmitter = require('events').EventEmitter;

// @ngInject
function GlobalKeysService($window) {

  var eventEmitter = new EventEmitter();
  var initialized = false;

  function initialize() {
    if (initialized) {
      return;
    }

    $window.addEventListener('keydown', function(e) {
      // ESC key works as global in form inputs too
      if (e.keyCode !== 27 && /input|select|textarea|button/i.test(e.target.tagName)) {
        // this is form input, not global
        return;
      }
      eventEmitter.emit('keydown', e);
    });

    initialized = true;
  }

  this.on = function(eventName, handler) {
    initialize();
    eventEmitter.on(eventName, handler);
  };

  this.off = function(eventName, handler) {
    initialize();
    eventEmitter.off(eventName, handler);
  };

}

module.exports = GlobalKeysService;
