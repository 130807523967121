// @ngInject
function LoyaltyPanelController($scope, $rootScope) {
  if ($rootScope.showMyPointsContent === undefined) {
    $rootScope.showMyPointsContent = true;
    $rootScope.showRedeemContent = false;
  }

  $rootScope.showOrHideExperiences = function() {
    if ($rootScope.showRewards) {
      $rootScope.showExperiences = !$rootScope.showExperiences;
    }
  };
  $rootScope.showOrHideRewards = function() {
    if ($rootScope.showExperiences) {
      $rootScope.showRewards = !$rootScope.showRewards;
    }
  };
}

module.exports = LoyaltyPanelController;
