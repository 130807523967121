var _ = require('lodash-core');

/* jshint maxparams: 10 */
// @ngInject
function ListContextService($stateParams, $state, $rootScope, userService,
  curationService, searchService, platformService, config) {
  this.setScopeContext = function($scope, options) {

    if ($scope.filters) {
      return;
    }
    var storage = {};
    try {
      storage = window.sessionStorage;
    } catch (e) {
      storage = window.iframeStorage;
    }
    options = options || {};
    var urlMode = $scope.urlMode = (options.urlMode !== false);
    var categoriesOnQueryString = $scope.categoriesOnQueryString = !!options.categoriesOnQueryString;

    // context data

    $scope.filters = {
      category: [],
      brands: [],
      sizes: [],
      colors: [],
      sale: false,
      inStore: false,
      gifts: [],
      discount: 0,
      occasion: '',
      price: {},
      userTags: [] // userTags is a fixed param for some product-lists it never goes in the url or within a filter
    };

    $scope.search = {
      term: $stateParams.term || '',
      ready: true
    };

    $scope.sort = {
      criteria: ''
    };

    $scope.result = {
      items: [],
      count: 0,
      facets: {},
      ready: false,
      curations: []
    };

    $scope.sources = {
      categories: [],
      brands: [],
      sizes: [],
      colors: [],
      discounts: [],
      occasions: []
    };

    $scope.pager = {
      page: 1,
      size: 60
    };

    $scope.facets = {
      category: {},
      inStore: {},
      brands: {},
      sizes: {},
      colors: {},
      sale: {},
      gifts: {},
      discount: {},
      occasion: {}
    };

    // methods

    $scope.refreshCurations = function(items) {
      if (items.length === 0) {
        return;
      }
      var user = userService.getUser();
      if (!user || user.anonymous || !user.roles || user.roles.indexOf('admin') < 0) {
        return;
      }
      var styleIds = [];
      _.forEach(items, function(item) {
        if (item && item.styleId) {
          styleIds.push(item.styleId);
        }
      });
      if (styleIds.length === 0) {
        return;
      }
      curationService.getStylesCurations(styleIds).then(function(result) {
        $scope.result.curations = result.items;
      });
    };

    $scope.getSearchParamsExceptFor = $scope.getSearchParams = function(exclude) {
      var params = {
        search: $scope.search.term.trim(),
        brands: $scope.filters.brands,
        sizes: ['clothing', 'shoes', 'kids'].indexOf($scope.filters.category[0]) > -1 ? $scope.filters.sizes : [],
        colors: $scope.filters.colors,
        category: $scope.filters.category,
        userTags: $scope.filters.userTags,
        sort: $scope.sort.criteria,
        sale: $scope.filters.sale,
        inStore: $scope.filters.inStore,
        discount: $scope.filters.discount,
        gifts: $scope.filters.gifts,
        page: $scope.pager.page,
        occasion: $scope.filters.occasion,
        price: { min: $scope.filters.price.min, max: $scope.filters.price.max }
      };
      if (exclude) {
        delete params.sort;
        delete params[exclude];
      }


      // TODO: unify the names category -> tags and search -> searchTerm
      params.tags = _.union(params.category, params.userTags);
      if (params.occasion) {
        params.tags.push('u.' + params.occasion);
      }
      delete params.category;
      delete params.userTags;
      delete params.occasion;
      params.searchTerm = params.search;
      delete params.search;
      params.brand = params.brands;
      delete params.brands;

      return params;
    };

    $scope.contextReady = false;
    $scope.pendingInitializers = [];

    $scope.onContextReady = function(func) {
      if ($scope.contextReady) {
        func();
      } else {
        $scope.pendingInitializers.push(func);
      }
    };

    $scope.getRankingId = function() {
      if ($scope.result && $scope.result.index && /-scores-/.test($scope.result.index)) {
        return $scope.result.index.split('-').pop();
      }
      return null;
    };

    $scope.updateFacets = _.throttle(function() {
      var queryByFacet = {};
      var facetNames = _.keys($scope.facets);
      _.forEach(facetNames, function(name) {
        var filterValue = $scope.filters[name];
        if (!filterValue || !filterValue.length) {
          queryByFacet[name] = $scope.getSearchParams();
        } else {
          queryByFacet[name] = $scope.getSearchParamsExceptFor(name);
        }
      });

      var queries = _.uniqBy(_.values(queryByFacet), JSON.stringify); // remove repeated queries

      if (!queries || !queries.length) {
        return;
      }

      searchService.getFacets(queries).then(function(responses) {
        _.forEach(queries, function(query, index) {
          var response = responses[index];
          _.forEach(facetNames, function(name) {
            if (_.isEqual(query, queryByFacet[name])) {
              $scope.facets[name] = response;
              if ($scope.facets[name] === undefined) {
                $scope.facets[name] = {};
              }
              $scope.facets[name].rnd = new Date().getMilliseconds();
            }
          });
        });
      });
    }, (platformService.isMobile()) ? config.facetFiltering.delayMobile :
      config.facetFiltering.delayDesktop, { leading: false });

    // watchers

    $scope.$watch(function() { return JSON.stringify($scope.result.items); }, function(items) {
      $scope.refreshCurations(JSON.parse(items || '[]'));
    });

    var dirtyState;
    var dirtyCategoryState;
    if (urlMode) {
      $scope.$watch(function() { return $stateParams; }, function() {
        if ($state.is('root.home')) {
          return;
        }

        var prev = JSON.parse(storage.getItem('OMFilters') || null);
        var stateParams = storage.getItem('prev.state.params') || null;
        var checkCurrentState = checkFiltersState();
        var checkPreviousState = (prev) ? checkFiltersState(prev.prevState) : false;

        // Should the filters be deleted when we change the categories
        if (checkCurrentState && checkPreviousState &&
          JSON.parse(prev && prev.prevStateParams) !== null) {
          if (JSON.parse(prev && prev.prevStateParams).category1 !== JSON.parse(stateParams).category1) {
            storage.setItem('OMFilters', null);
          }
        }
        if (checkCurrentState && checkPreviousState &&
          JSON.parse(prev && prev.prevStateParams) !== null) {
          if (JSON.parse(prev && prev.prevStateParams).term !== JSON.parse(stateParams).term) {
            storage.setItem('OMFilters', null);
          }

        }
        updateCategoryFilter();
        updateBrandsFilter();
        updateSizesFilter();
        updateColorsFilter();
        updateSaleFilter();
        updateDiscountFilter();
        updateGiftsFilter();
        updateOccasionFilter();
        updatePriceFilter();
        updateInStore();
        updateSortCriteria();
        updatePagerPage();
        ready();
      }, true);

      $scope.$watch(function() { return [$scope.filters, $scope.sort, $scope.pager]; }, function() {
        if (!$scope.contextReady || $state.is('root.home')) {
          return;
        }
        dirtyState = false;
        dirtyCategoryState = false;
        updateCategoryState();
        updateBrandsState();
        updateSizesState();
        updateColorsState();
        updateSaleState();
        updateDiscountState();
        updateOccasionState();
        updatePriceState();
        updateSortCriteriaState();
        updatePagerPageState();
        if (dirtyState) {
          updateState();
        }
        $scope.updateFacets();
      }, true);
    } else {
      ready();
    }

    // private

    function updateCategoryFilter() {
      var urlState = getCategoryState();
      if ($scope.filters.category.join(';') === urlState) {
        return;
      }
      $scope.filters.category = urlState.split(';');
    }

    // Checks that the param state is one of the brand filtered states
    function checkFiltersState(state) {
      return $rootScope.isShop(state) ||
        $rootScope.isPages(state) ||
        $rootScope.isSale(state) ||
        $rootScope.isBrandProducts(state) ||
        $rootScope.isSearch(state);
    }

    function updateBrandsFilter() {
      var urlState = getBrandsState();
      if ($scope.filters.brands && $scope.filters.brands.join(',') === urlState) {
        return;
      }
      $scope.filters.brands = _.compact(urlState.split(','));
    }
    function updateSizesFilter() {
      var urlState = getSizesState();
      if ($scope.filters.sizes.join(',') === urlState) {
        return;
      }
      $scope.filters.sizes = _.compact(urlState.split(','));
    }
    function updateColorsFilter() {
      var urlState = getColorsState();
      if ($scope.filters.colors.join(',') === urlState) {
        return;
      }
      $scope.filters.colors = _.compact(urlState.split(','));
    }
    function updateSaleFilter() {
      var urlState = getSaleState();
      if ($scope.filters.sale === urlState) {
        return;
      }
      $scope.filters.sale = urlState;
    }
    function updateInStore() {
      var urlState = getinStoreState();
      if ($scope.filters.inStore === urlState) {
        return;
      }
      $scope.filters.inStore = urlState;
    }
    function updateDiscountFilter() {
      var urlState = getDiscountState();
      if ($scope.filters.discount === urlState) {
        return;
      }
      $scope.filters.discount = urlState;
    }
    function updateGiftsFilter() {
      var urlState = getGiftsState();
      if ($scope.filters.gifts.join(',') === urlState) {
        return;
      }
      $scope.filters.gifts = urlState.split(',');
    }
    function updateSortCriteria() {
      var urlState = getSortCriteriaState();
      if ($scope.sort.criteria === urlState) {
        return;
      }
      $scope.sort.criteria = urlState;
    }
    function updatePagerPage() {
      var urlState = getPagerPageState();
      if ($scope.pager.page === urlState) {
        return;
      }
      $scope.pager.page = urlState;
    }
    function updateOccasionFilter() {
      $scope.filters.occasion = getOccasionState();
    }
    function updatePriceFilter() {
      $scope.filters.price = getPriceState();
    }

    function updateCategoryState() {
      var urlState = getCategoryState();
      if ($scope.filters.category.join(';') !== urlState) {
        _.forEach([1, 2, 3], function(n) { $stateParams['category' + n] = $scope.filters.category[n - 1]; });
        dirtyState = true;
        dirtyCategoryState = true;
      }
    }
    function updateBrandsState() {
      var sessionStorageFilter = getBrandsState();
      var filter = $scope.filters.brands ? $scope.filters.brands.join(',') : '';
      if (filter !== sessionStorageFilter) {
        if ($stateParams.brand !== filter) {
          $stateParams.designers = filter;
          var prev = JSON.parse(storage.getItem('OMFilters')) ||
            { filters: {} };
          prev.filters.brandsFilter = filter;
          prev.prevStateParams = storage.getItem('prev.state.params') || null;
          prev.prevState = storage.getItem('prev.state.current') || null;
          storage.setItem('OMFilters', JSON.stringify(prev));
        } else {
          $stateParams.designers = undefined;
          storage.setItem('OMFilters', null);
        }
        dirtyState = true;
      }
    }
    function updateSizesState() {
      var urlState = getSizesState();
      var filter = $scope.filters.sizes.join(',');
      if (filter !== urlState) {
        $stateParams.sizes = filter;
        dirtyState = true;

      }
    }
    function updateColorsState() {
      var sessionStorageFilter = getColorsState();
      var filter = $scope.filters.colors.join(',');

      if (filter !== sessionStorageFilter) {
        if ($stateParams.colors !== filter) {
          $stateParams.colors = filter;
          var prev = JSON.parse(storage.getItem('OMFilters')) ||
            { filters: {} };
          prev.filters.colorsFilter = filter;
          prev.prevStateParams = storage.getItem('prev.state.params') || null;
          prev.prevState = storage.getItem('prev.state.current') || null;
          storage.setItem('OMFilters', JSON.stringify(prev));
        } else {
          $stateParams.colors = undefined;
          storage.setItem('OMFilters', null);
        }
        dirtyState = true;
      }
    }
    function updateSaleState() {
      var urlState = getSaleState();
      var filter = $scope.filters.sale;
      if (filter !== urlState) {
        $stateParams.sale = filter;
        dirtyState = true;
      }
    }
    function updateDiscountState() {
      var urlState = getDiscountState();
      var filter = $scope.filters.discount;
      if (filter !== urlState) {
        $stateParams.discount = filter || '';
        dirtyState = true;
      }
    }
    function updateOccasionState() {
      var urlState = getOccasionState();
      var filter = $scope.filters.occasion;
      if (filter !== urlState) {
        $stateParams.occasion = filter;
        dirtyState = true;
      }
    }
    function updatePriceState() {
      var urlState = getPriceState();
      var filter = $scope.filters.price;
      if (filter.min !== urlState.min || filter.max !== urlState.max) {
        $stateParams['min-price'] = filter.min;
        $stateParams['max-price'] = filter.max;
        dirtyState = true;
      }
    }
    function updateSortCriteriaState() {
      var urlState = getSortCriteriaState();
      var criteria = $scope.sort.criteria;
      if (criteria !== urlState) {
        $stateParams.sort = criteria;
        dirtyState = true;
      }
    }
    function updatePagerPageState() {
      var urlState = getPagerPageState();
      var page = $scope.pager.page;
      if (page !== urlState) {
        $stateParams.page = page;
        dirtyState = true;
      }
    }


    function updateState() {
      if (dirtyCategoryState && !categoriesOnQueryString) {
        var baseName = $state.current.name.replace(/\.category[1-3]$/, '').replace('.just-in', '');
        if ($scope.filters.category.length && $scope.filters.category[0] !== 'whats-new') {
          $state.go(baseName + '.category' + $scope.filters.category.length, $stateParams, { notify: false });
        } else {
          $state.go(baseName, $stateParams, { notify: false });
        }
      } else {
        $state.go($state.current.name, $stateParams, { notify: false });
      }

    }

    function getCategoryState() {
      return _([1, 2, 3])
        .map(function(n) { return $stateParams['category' + n]; })
        .map(function(category) { return ['whats-new', 'all'].indexOf(category) > -1 ? null : category; })
        .compact()
        .value()
        .join(';');
    }
    function getBrandsState() {
      var prev = JSON.parse(storage.getItem('OMFilters') || null);
      return $stateParams.designers || $stateParams.brand || (prev && prev.filters &&
        prev.filters.brandsFilter) || '';
    }
    function getSizesState() {
      return $stateParams.sizes || '';
    }
    function getColorsState() {
      var prev = JSON.parse(storage.getItem('OMFilters') || null);
      return $stateParams.colors || (prev && prev.filters &&
        prev.filters.colorsFilter) || '';
    }
    function getSaleState() {
      return /^root\.sale/.test($state.$current.name) || $stateParams.sale === 'true';
    }
    function getinStoreState() {
      return $stateParams.inStore;
    }

    function getDiscountState() {
      return parseInt($stateParams.discount || '0');
    }
    function getGiftsState() {
      if (!/^root\.gifts/.test($state.$current.name)) {
        // no gifts route so we return the same gifts filter to avoid any update
        return $scope.filters.gifts.join(',');
      }
      return _.compact(['gifts', $stateParams.group]).join(',');
    }
    function getOccasionState() {
      return $stateParams.occasion;
    }
    function getPriceState() {
      return { min: $stateParams['min-price'], max: $stateParams['max-price'] };
    }
    function getSortCriteriaState() {
      return $stateParams.sort;
    }
    function getPagerPageState() {
      return parseInt($stateParams.page || 1);
    }

    function ready() {
      if (!$scope.contextReady) {
        $scope.contextReady = true;
        _.forEach($scope.pendingInitializers, function(func) { func(); });
      }
    }

    return $scope;
  };
}

module.exports = ListContextService;
