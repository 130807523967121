var _ = require('lodash-core');
var _s = require('underscore.string');
var analytics = require('../../analytics');

/* jshint maxparams: 21 */

// @ngInject
function AnalyticsService(
  $rootScope,
  config,
  $state,
  $transitions,
  userService,
  errorExplodeService,
  viewportService,
  platformService,
  cartService,
  sessionStorageService,
  userPageViewService,
  $filter,
  $timeout,
  $window,
  productService
) {
  analytics
    .setup(
      config,
      userService,
      errorExplodeService,
      viewportService,
      platformService,
      cartService,
      sessionStorageService,
      userPageViewService,
      $filter,
      $timeout,
      $window,
      $rootScope,
      productService
    )
    .makeBrowserGlobal();

  var loadedAnalytics = false;
  var loadedMarketing = false;
  var loadAnalytics = function() {
    if (!loadedAnalytics) {
      loadedAnalytics = true;
      analytics.setupAnalytics(
        config,
        userService,
        errorExplodeService,
        viewportService,
        platformService,
        cartService,
        sessionStorageService,
        userPageViewService,
        $filter,
        $timeout,
        $window
      );
    }
    if (!loadedMarketing) {
      loadedMarketing = true;
      analytics.setupMarketing(config, $window);
    }
  };

  setTimeout(function() {
    loadAnalytics();
  }, 2000);

  $transitions.onStart({}, function(trans) {
    trans.promise.finally(function() {
      analytics.trackStateChange();
    });
  });

  function identifyCurrentUser(params) {
    params = params || {};
    var user = userService.getUser();
    if (!(user && user.id && user.email)) {
      return;
    }
    var userInfo = {
      email: user.email
    };
    if (params.created === true || user.displayName || user.name) {
      userInfo.name =
        (user.displayName || user.name || _.compact([user.firstName, user.lastName]).join(' ') || user.email) +
        userService.environmentDisplayNameSuffix();
    }
    if (user.roles) {
      for (var i = 0; i < user.roles.length; i++) {
        userInfo['is' + _s.classify(user.roles[i])] = true;
      }
    }

    userInfo.isTester = userInfo.isTester || userService.userIsTester();

    if (window.FS && typeof window.FS.getCurrentSessionURL === 'function') {
      userInfo.fullStorySessionUrl = window.FS.getCurrentSessionURL();
    }
    if (typeof user.firstName !== 'undefined') {
      userInfo.firstName = user.firstName;
    }
    if (typeof user.lastName !== 'undefined') {
      userInfo.lastName = user.lastName;
    }
    if (params.created === true) {
      userInfo.signUpTrigger = params.trigger;
      userInfo.signUpProvider = params.provider;
    }
    analytics.identify(user.id, userInfo);
  }

  identifyCurrentUser();

  userService.events.on('subscribedToTopic', function(options) {
    analytics.track('Subscribed To Topic', {
      topic: options.topic
    });
  });

  userService.events.on('inviteRequested', function(options) {
    var user = userService.getUser();
    if (user && user.email && user.email !== options.email) {
      // this is a user (logged in) requesting an invite for someone else
      analytics.track('Invite Requested For Someone Else', {
        requestedBy: user.email,
        email: options.email
      });
      return;
    }
    // identify the user requesting the invite,
    // so we get client-side data associated to its analytics profile
    analytics.identify(options.email, {
      inviteRequested: true
    });
  });

  userService.events.on('loggedIn', function(user, eventInfo) {
    if (eventInfo && eventInfo.source === 'localStorage') {
      // user logged in in another tab, let the other tab track that
      return;
    }
    var userInfo = {};
    if (window.FS && typeof window.FS.getCurrentSessionURL === 'function') {
      userInfo.fullStorySessionUrl = window.FS.getCurrentSessionURL();
    }
    analytics.track('Logged In', userInfo);
    identifyCurrentUser();
  });

  userService.events.on('signedUp', function(userInfo) {
    userInfo = userInfo || {};
    if (window.FS && typeof window.FS.getCurrentSessionURL === 'function') {
      userInfo.fullStorySessionUrl = window.FS.getCurrentSessionURL();
    }
    analytics.track('Signed Up', userInfo);
    identifyCurrentUser(userInfo);
  });

  userService.events.on('loginFailed', function(params, err) {
    params = params || {};
    params.error = err instanceof Error ? err.message : err.statusText || err.toString();
    analytics.track('Login Failed', params);
  });

  userService.events.on('guestCheckout', function(user, eventInfo) {
    if (eventInfo && eventInfo.source === 'localStorage') {
      // user logged in as guest in another tab, let the other tab track that
      return;
    }
    analytics.track('Guest Checkout', {
      email: user.email
    });
  });

  userService.events.on('contactRequest', function(contactData) {
    analytics.track('Contact Request', {
      name: contactData.name,
      email: contactData.email,
      subject: contactData.subject
    });
  });

  userService.events.on('loggedOut', function(eventInfo) {
    if (eventInfo && eventInfo.source === 'localStorage') {
      // user logged out in another tab, let the other tab track that
      return;
    }
    analytics.track('Logged Out');
  });

  userService.events.on('viewedValueProposition', function() {
    analytics.track('Value Proposition Viewed', { nonInteraction: 1 });
  });

  userService.events.on('dismissedValueProposition', function() {
    analytics.track('Value Proposition Dismissed', { nonInteraction: 1 });
  });

  var lastStateChange;
  var lastPageUrl;

  function analyticsPageVisit() {
    if (typeof $state.current.trackView === 'undefined' || $state.current.trackView === true) {
      var pageName = $state.current.name.replace(/^root\./, '');

      var pageUrl = document.location.pathname + document.location.search;
      if (pageUrl === lastPageUrl) {
        return;
      }
      lastPageUrl = pageUrl;
      analytics.page(
        pageName,
        _.merge({}, $state.params, {
          pageUrl: pageUrl
        })
      );
    }
  }

  analytics.trackStateChange = function() {
    var automaticRedirect = false;
    if (
      lastStateChange &&
      $state.current.name &&
      $state.current.name.substr(0, lastStateChange.name.length) === lastStateChange.name
    ) {
      var sinceLastState = new Date().getTime() - lastStateChange.date.getTime();
      if (sinceLastState < 800) {
        // this is an automatic redirect, not a real page visit
        automaticRedirect = true;
      }
    }

    lastStateChange = {
      date: new Date(),
      name: $state.current.name
    };

    if (!automaticRedirect) {
      // small delay to let the browser update document.location
      setTimeout(analyticsPageVisit, 30);
    }
  };

  return analytics;
}

module.exports = AnalyticsService;
