var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function GeoCodingService(config, $http) {

  this.searchByZip = promises.memoize(function(zip) {
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + zip + '&language=en';
    return $http({
      method: 'GET',
      url: url
    }).then(function(response) {
      if (response.status !== 200) {
        return [];
      }
      var matches;
      try {
        matches = response.data.results;
        matches = matches.filter(function(match) {
          if (!hasAddressComponents(match, [
            'postal_code', 'locality', 'administrative_area_level_1', 'country'
          ])) {
            return false;
          }
          var postalCode = getAddressComponent(match, 'postal_code');
          /* jshint camelcase: false */
          return postalCode && (postalCode.short_name === zip || postalCode.long_name === zip);
        }).map(function(match) {
          /* jshint camelcase: false */
          return {
            zip: getAddressComponent(match, 'postal_code').short_name,
            city: getAddressComponent(match, 'locality').short_name,
            state: getAddressComponent(match, 'administrative_area_level_1').short_name,
            country: getAddressComponent(match, 'country').short_name.toLowerCase()
          };
        });
        matches.forEach(function(match, index) {
          // give more priority to US matches
          match.priority = (match.country === 'us' ? 1000 : 0) + (1000 - index);
        });
        _.sortBy(matches, 'priority');
        return matches;
      } catch (err) {
        console.error('error parsing geocoding response', response, err);
        return [];
      }
    });
  });

  function hasAddressComponents(result, types) {
    /* jshint camelcase: false */
    return _.all(types, function(type) {
      return !!getAddressComponent(result, type);
    });
  }

  function getAddressComponent(result, type) {
    /* jshint camelcase: false */
    return _.find(result.address_components, function(component) {
      return component.types && component.types.indexOf(type) >= 0;
    });
  }

}

module.exports = GeoCodingService;
