var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function GiftCardService($rootScope, apiService, orderService, cartService) {

  this.codeRegex = /^([0-9]{15}|OM[a-zA-Z0-9]{32})$/i;

  this.get = _.memoize(function(code) {
    if (!this.codeRegex.test(code)) {
      return promises.immediate({
        balance: 0,
        daysToExpiration: 0
      });
    }
    return promises.extend(apiService.get('/gift-cards/{code}', {
       pathParams: {
         code: code
       }
    }));
  });

  var self = this;

  orderService.events.on('orderPlaced', function() {
    self.get.cache.clear();
  });

  cartService.events.on('begin_checkout', function() {
    self.get.cache.clear();
  });

  $rootScope.$watch('giftCard.code', function() {
    cartService.setUsingGiftCard(!!($rootScope.giftCard || {}).code);
  });

}

module.exports = GiftCardService;
