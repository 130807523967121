// @ngInject
function AdminLoyaltyUserController($scope, $stateParams, userService) {
  $scope.loyaltyUser = { loading: true };
  var loyaltyPromise = userService.getLoyaltyByUserId($stateParams.userId);

  loyaltyPromise.then(function(loyalty) {
    $scope.loyaltyUser = loyalty;
    switch (loyalty.tier) {
      case 2:
        $scope.loyaltyUser.tier = 'Gold';
        break;
      case 3:
        $scope.loyaltyUser.tier = 'Platinum';
        break;
      default:
        $scope.loyaltyUser.tier = 'Silver';
        break;
    }
    if ($scope.loyaltyUser.remainingPoints === undefined) {
      $scope.loyaltyUser.remainingPoints = 0;
    }
    if ($scope.loyaltyUser.activities === undefined) {
      $scope.loyaltyUser.activities = [];
    }
    $scope.loyaltyUser.loading = false;
  });

  var actualUserPromise = userService.getById($stateParams.userId);

  actualUserPromise.then(function(actualUser) {
    $scope.actualUser = {
      firstName: actualUser.firstName,
      lastName: actualUser.lastName,
      id: actualUser.id
    };
  });
}

module.exports = AdminLoyaltyUserController;
