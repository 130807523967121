function GiftCardPaymentProvider() {
  return;
}

GiftCardPaymentProvider.prototype.prepareOrder = function(order) {
  order.billing.paymentMethod.cardNumber = '4111111111111111';
  order.billing.paymentMethod.cvv = '000';
  order.billing.paymentMethod.expirationMonth = '12';
  order.billing.paymentMethod.expirationYear = (new Date()).getFullYear() + 1;
  return new Promise(function(resolve, reject) { // jshint ignore:line
    if (order) {
      return resolve(order);
    }
    reject(new Error('No order details.'));
  });
};

module.exports = GiftCardPaymentProvider;
