
/*jshint camelcase:false */
/*global plupload*/

// @ngInject
function S3ImageUploaderDirective(staticAssetService) {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/image-uploader.html',
    link: function(scope, element, attrs) {

      var $element = $(element);

      // Find and cache the DOM elements we'll be using.
      var dom = {
        uploader: $element.find('.uploader'),
        selectFiles: $element.find('.uploader .selectFiles')
      };

      var lastCredentials = {
        data: null,
        refreshTime: new Date()
      };

      scope.isUploading = false;

      scope.removed = [];

      scope.remove = function(img) {
        var pos = scope.output.indexOf(img);
        if (pos > -1) {
          scope.output.splice(pos, 1);
          scope.removed.push(img);
        }
      };

      scope.reAdd = function(img) {
        var pos = scope.removed.indexOf(img);
        if (pos > -1) {
          scope.removed.splice(pos, 1);
          scope.output.push(img);
        }
      };

      // Instantiate the Plupload uploader. When we do this,
      // we have to pass in all of the data that the Amazon
      // S3 policy is going to be expecting. Also, we have
      // to pass in the policy :)
      var uploader = new plupload.Uploader({
        // Try to load the HTML5 engine
        runtimes: 'html5',

        // we set the url later on BeforeUpload event
        url: 'http://unknown.s3.amazonaws.com/',

        // The ID of the drop-zone element.
        drop_element: dom.uploader[0],

        // To enable click-to-select-files, you can provide
        // a browse button. We can use the same one as the
        // drop zone.
        browse_button: dom.selectFiles[0],

        // The name of the form-field that will hold the
        // upload data. Amason S3 will expect this form
        // field to be called, 'file'.
        file_data_name: 'file',

        multipart: true,

        // set multipart_params later on BeforeUpload event
        multipart_params: {},

        max_file_size: attrs.maxFileSize || '1mb' // 10485760 bytes
      });

      // Set up the event handlers for the uploader.
      uploader.bind('Init', handlePluploadInit);
      uploader.bind('Error', handlePluploadError);
      uploader.bind('FilesAdded', handlePluploadFilesAdded);
      uploader.bind('QueueChanged', handlePluploadQueueChanged);
      uploader.bind('BeforeUpload', handlePluploadBeforeUpload);
      uploader.bind('UploadProgress', handlePluploadUploadProgress);
      uploader.bind('FileUploaded', handlePluploadFileUploaded);
      uploader.bind('StateChanged', handlePluploadStateChanged);

      staticAssetService.getImgUploaderCredentials().then(function(credentials) {
        scope.credentials = credentials;

        // Initialize the uploader (it is only after the
        // initialization is complete that we will know which
        // runtime load: html5 vs. Flash).
        uploader.init();
      });

      // ------------------------------------------ //
      // ------------------------------------------ //


      // I return the content type based on the filename.
      function getContentTypeFromFilename(name) {

        if (/\.jpe?g$/i.test(name)) {
          return ('image/jpg');
        } else if (/\.png/i.test(name)) {
          return ('image/png');
        } else if (/\.gif/i.test(name)) {
          return ('image/gif');
        }

        // If none of the known image types match, then
        // just use the default octet-stream.
        return ('application/octet-stream');

      }

      // I handle the before upload event where the meta data
      // can be edited right before the upload of a specific
      // file, allowing for per-file Amazon S3 settings.
      function handlePluploadBeforeUpload(uploader, file) {

        if (typeof scope.credentials !== 'function') {
          lastCredentials.data = scope.credentials;
          lastCredentials.refreshTime = null; // suppose expiration is handle by the controller
        }

        if (lastCredentials.data === null ||
          (lastCredentials.refreshTime && lastCredentials.refreshTime < new Date())) {

          scope.credentials().then(function(credentials) {

            lastCredentials.data = credentials;
            lastCredentials.refreshTime = new Date();
            lastCredentials.refreshTime.setMinutes(
              lastCredentials.refreshTime.getMinutes() +
              parseInt(credentials.expires / 2)
              //conservatively: at 50% of expiration time credentials will be refreshed
            );

            updateSettings(uploader.settings, file, credentials);

            console.log('File upload about to start (new credentials).');

            // Manually change the file status and trigger the upload. At
            // this point, Plupload will post the actual image binary up to
            // Amazon S3.
            file.status = plupload.UPLOADING;
            uploader.trigger('UploadFile', file);

          }, function(err) {
            lastCredentials.data = null;

            console.error('Oops! ', err);
            console.warn('File being removed from queue:', file.name);

            // Something is wrong with this but
            // we don't want to halt the entire process. In order
            // to get back into queue-processing mode we have to stop the
            // current upload.
            uploader.stop();

            // Then, we have to remove the file from the queue (assuming that
            // a subsequent try won't fix the problem). Due to our event
            // bindings in the "QueueChanged" event, this will trigger a
            // restart of the uploading if there are any more files to process.
            uploader.removeFile(file);

          });

          return false; // this pause the plupload pipeline
        }

        console.log('File upload about to start.');
        updateSettings(uploader.settings, file, lastCredentials.data);
      }

      // I handle the init event. At this point, we will know
      // which runtime has loaded, and whether or not drag-
      // drop functionality is supported.
      function handlePluploadInit(uploader) {
        console.log('Initialization complete.');
        console.info('Drag-drop supported:', !!uploader.features.dragdrop);
      }

      // I handle any errors raised during uploads.
      function handlePluploadError() {
        console.warn('Error during upload.');
      }

      // I handle the files-added event. This is different
      // that the queue-changed event. At this point, we
      // have an opportunity to reject files from the queue.
      function handlePluploadFilesAdded(uploader, files) {
        console.log('Files selected.');

        // Make sure we filter OUT any non-image files.
        for (var i = (files.length - 1); i >= 0; i--) {
          if (!isImageFile(files[i])) {
            console.warn('Rejecting non-image file.');
            files.splice(i, 1);
          }
        }
      }

      // I handle the queue changed event.
      function handlePluploadQueueChanged(uploader) {
        console.log('Files added to queue.');
        if (uploader.files.length && isNotUploading()) {
          uploader.start();
        }
      }

      // I handle the upload progress event. This gives us
      // the progress of the given file, NOT of the entire
      // upload queue.
      function handlePluploadUploadProgress(uploader, file) {
        console.info('Upload progress:', file.percent);
        scope.percent = file.percent;
        scope.$apply();
      }

      // I handle the file-uploaded event. At this point,
      // the resource had been uploaded to Amazon S3 and
      // we can tell OUR SERVER about the event.
      function handlePluploadFileUploaded(uploader, file, response) {
        var url = uploader.settings.url + parseAmazonResponse(response.response).key;
        scope.uploadedFiles = scope.uploadedFiles || [];
        scope.uploadedFiles.push(url);
        console.log('uploaded', url);
        scope.$emit('fileUploaded', url);
      }

      // I handle the change in state of the uploader.
      function handlePluploadStateChanged() {
        scope.isUploading = isUploading();
        scope.$apply();
      }

      // I determine if the given file is an image file.
      // --
      // NOTE: Our policy requires us to send image files.
      function isImageFile(file) {
        var contentType = getContentTypeFromFilename(file.name);

        // Make sure the content type starts with the
        // image super-type.
        return (/^image\//i.test(contentType));
      }

      // I determine if the upload is currently inactive.
      function isNotUploading() {
        var currentState = uploader.state;
        return (currentState === plupload.STOPPED);
      }

      // I determine if the uploader is currently uploading a
      // file (or if it is inactive).
      function isUploading() {
        var currentState = uploader.state;
        return (currentState === plupload.STARTED);
      }

      // When Amazon S3 returns a 201 reponse, it will provide
      // an XML document with the values related to the newly
      // uploaded Resource. This function extracts the two
      // values: Bucket and Key.
      function parseAmazonResponse(response) {
        var result = {};
        var pattern = /<(Bucket|Key)>([^<]+)<\/\1>/gi;
        var matches = pattern.exec(response);

        while (matches) {
          var nodeName = matches[1].toLowerCase();
          var nodeValue = matches[2];
          result[nodeName] = nodeValue;
          matches = pattern.exec(response);
        }

        return (result);
      }

      // Pass through all the values needed by the Policy
      // and the authentication of the request.
      function updateSettings(settings, file, credentials) {

        settings.url = 'https://' + credentials.bucket + '.s3.amazonaws.com/';

        settings.multipart_params = {
          'acl': 'public-read',
          'success_action_status': '201',
          'key': credentials.folder + '/${filename}',
          'Filename': credentials.folder + '/${filename}',
          'Content-Type': getContentTypeFromFilename(file.name),
          'AWSAccessKeyId': credentials.accessKeyId,
          'policy': credentials.policy,
          'signature': credentials.signature
        };

        // Generate a 'unique' key based on the file ID
        // that Plupload has assigned, but keep
        // the original file extension from the client.
        var filename = file.id + '.' + file.name.split('.').pop();
        if (attrs.prefix) {
          filename = attrs.prefix + filename;
        }
        var uniqueKey = (credentials.folder + '/' + filename);

        // Update the Key and Filename so that Amazon S3
        // will store the resource with the correct value.
        uploader.settings.multipart_params.key = uniqueKey;
        uploader.settings.multipart_params.Filename = uniqueKey;
      }
    }
  };

}

module.exports = S3ImageUploaderDirective;
