// @ngInject
function SignupCheckoutController($scope, $state, userService, orderService,
  formsService, paymentService, $rootScope) {
  $scope.inputs = {};
  $scope.inputs.password = '';
  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.doSignUp = function() {

    var password = $scope.inputs.password;
    if (password === '') {
      $scope.form.password.$setValidity('required', false);
      return false;
    }
    if (!formPromise.validate($scope.form)) {
      return;
    }

    formPromise.handle(userService.signup({
      email: $rootScope.shipping.contact.email,
      password: $scope.inputs.password,
      firstName: $rootScope.shipping.contact.firstName,
      lastName: $rootScope.shipping.contact.lastName,
      transactional: $scope.inputs.transactional,
      trigger: 'checkout'
    })).then(function() {
      return paymentService.prepareOrderToSaveOptions({
        shipping: $rootScope.shipping,
        billing: $rootScope.billing
      }).then(function(preparedOrderOptions) {
        if (!preparedOrderOptions) {
          // payment provider doesn't want to save shipping and billing options
          $state.go('root.account.summary');
          return;
        }
        return userService.saveOrderOptions(preparedOrderOptions).then(function() {
          return orderService.claimOrderForCurrentUser(
            $rootScope.placedOrderNumber,
            $rootScope.placedOrderToken
          ).then(function() {
            $state.go('root.account.summary');
          });
        });
      });
    });
  };
}

module.exports = SignupCheckoutController;
