/* jshint maxparams: 10 */

// @ngInject
function StateSecurityService($transitions, userService, $state, $stateParams, config, errorService,
                              sessionStorageService, platformService) {

  var safeRoute = config.privateAccess ? 'root.invite' : 'root.home';

  this.getStatePrivacy = function(name, params) {
    var privacy = {};
    if (/^admin/.test(name)) {
      privacy.private = true;
      privacy.roles = ['admin'];
      if (name === 'admin.order') {
        privacy.roles = ['order-processor'];
      }
    } else if (/^root\.checkout/.test(name)) {
      privacy.private = true;
      privacy.allowGuest = true;
    } else if (/^root\.account\.order/.test(name)) {
      var hasToken = /(\?|&)token=/.test(window.location);
      privacy.private = !hasToken;
      privacy.allowGuest = hasToken;
    } else if (/^root\.account/.test(name)) {
      privacy.private = true;
      privacy.allowGuest = false;
    } else if (/^root\.miles/.test(name)) {
      privacy.private = false;
      privacy.allowGuest = true;
    }
    if (config.privateAccess) {
      privacy.allowGuest = false;
      if (!/^root\.invite/.test(name) &&
        !/^root\.passwordReset/.test(name) &&
        !/^root\.error/.test(name) &&
        !(/^root\.pages/.test(name) && /^browser\-not\-supported/.test(params && params.name))) {
        privacy.private = true;
      }
    }
    return privacy;
  };

  this.getCurrentStatePrivacy = function() {
    return this.getStatePrivacy($state.current.name, $stateParams);
  };

  this.securePrivateStates = function() {

    var self = this;

    $transitions.onStart({}, function(trans) {
      function bounceNotAllowed() {
        trans.abort();
        if (trans.to().name === 'root.home') {
          $state.go(safeRoute, {}, {location: false});
          return;
        }
        var err = new Error('Forbidden');
        err.status = 403;
        errorService.pageError(err);
      }

      var user = userService.getUser();
      if (trans.to().name !== 'root.error') {
        if (platformService.isSessionStorageAvailable()) {
          sessionStorageService.setItem('prev.state.current', JSON.stringify(trans.to().name));
          sessionStorageService.setItem('prev.state.params', JSON.stringify(trans.params()));
        }
      }
      var privacy = self.getStatePrivacy(trans.to().name, trans.params());

      if (privacy.private && (!user || user.anonymous) && !privacy.allowGuest) {
        bounceNotAllowed();
      }

      if (privacy.roles && privacy.roles.length > 0) {
        if (user && user.roles) {
          for (var i = 0; i < privacy.roles.length; i++) {
            if (user.roles.indexOf(privacy.roles[i]) >= 0) {
              return;
            }
          }
        }
        bounceNotAllowed();
      }
    });

    userService.events.on('loggedOut', function() {
      if (self.getCurrentStatePrivacy().private) {
        if ($state.current.name === 'root.home') {
          $state.go('root.invite', {}, {location: false});
          return;
        }
        $state.go('root.home');
      }
    });
  };
}

module.exports = StateSecurityService;
