// @ngInject
function ExceptionHandlerDecorator(/* $delegate */) {
  return function(exception, cause) {
    if (cause) {
      exception.message += ' (caused by ' + cause + ')';
    }

    var isError = true;
    if (exception.status && [0, 400, 401, 402, 403, 404, 429].indexOf(exception.status) >= 0) {
      // status 0 happens when DNS fails to resolve
      isError = false;
    }

    // log directly to console for any error tracking tool to catch it
    var exceptionLoggable;
    if (exception instanceof Error) {
      exceptionLoggable = exception.stack ? exception.stack : exception;
    } else if (typeof exception === 'object') {
      exceptionLoggable = getHttpError(exception);
    } else {
      exceptionLoggable = exception.toString();
    }
    window.console[isError ? 'error' : 'log'](exceptionLoggable);

    // uncomment this to pass the error through to the delegate formats it for the console
    // $delegate(exception, cause);

    function getHttpError(exception) {
      var messages = [];
      if (exception.config) {
        messages.push('[HTTP] ' + exception.config.method + ' ' + exception.config.url);
        if (exception.data && exception.data.error) {
          messages.push(exception.data.error.message || exception.data.error.toString());
          if (exception.data.error.incident) {
            messages.push('INCIDENT: ' + exception.data.error.incident);
          }
          if (exception.data.error.detail) {
            messages.push(exception.data.error.detail);
          }
        } else {
          messages.push(serialize(exception.data));
        }
      } else {
        messages.push(serialize(exception));
      }
      return messages.join('\n');
    }

    function serialize(data) {
      try {
        return JSON.stringify(data, null, 2);
      } catch (err) {
        return data.toString();
      }
    }
  };
}

module.exports = ExceptionHandlerDecorator;
