
// @ngInject
function ModalShowService($rootScope, $uibModal, $state) {

  this.openShow = function(showId, scope, sectionName) {
    var self = this;
    if (!$rootScope.modalShow) {
      if (window.hj) {
        window.hj('event', 'the-mile-open-show-click');
      }
      scope.showId = showId;
      $state.go('.', { show: showId }, { notify: false, reload: false });
      $rootScope.modalShow = $uibModal.open({
        templateUrl: '/views/show.html',
        windowClass: 'show-quick-view-modal',
        scope: scope
      });
      window.isShowOpened = true;
      $rootScope.modalShow.status = 'opened';
      $rootScope.modalShow.sectionName = sectionName;
      $rootScope.modalShow.closed.then(function () {
        if (sectionName) {
          var scrollElements = document.getElementsByName(sectionName);
          if (scrollElements && scrollElements.length) {
            scrollElements[0].scrollIntoView({ block: 'center' });
          }
        }
        self.closeShow();
      });
    }
  };

  this.closeShow = function() {
    if ($rootScope.modalShow && $rootScope.modalShow.status !== 'closed') {
      if ($rootScope.modalShow.close) {
        $rootScope.modalShow.close();
      }
      $rootScope.modalShow.status = 'closed';
      $rootScope.modalShow.dismiss();
      window.isShowOpened = false;
      delete $rootScope.modalShow;
      $state.go('.', { show: undefined }, { notify: false, reload: false });
    }
  };

}

module.exports = ModalShowService;
