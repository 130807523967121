var _ = require('lodash-core');

// @ngInject
function ShippingAndReturnsController($scope, fulfillerService) {
  $scope.setBreadcrumbPath([{ name: 'shipping and returns' }]);
  var partnerPromise = fulfillerService.getAll();
  partnerPromise.then(function(res) {
    $scope.partnerList = _.filter(res, function(each) {
      return !each.disabled;
    });
  });
}

module.exports = ShippingAndReturnsController;
