var _ = require('lodash-core');
var categoryHelper = require('shared-business-rules').categories;

/* jshint maxparams: 9 */

// @ngInject
function ShopController($scope, $state, $stateParams, $transitions, listContextService, analyticsService, config,
                        $rootScope, productService) {

  listContextService.setScopeContext($scope);

  var capitalize = function(text) {
    var arr = text.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  };

  $scope.featureFlags = config.featureFlags;
  $scope.error404 = !!$stateParams.error404;
  $scope.comingFrom = $stateParams['coming-from'];
  $scope.forbiddenCountry = !!$stateParams['forbidden-country'];
  $scope.oosProductName = '';
  $scope.getOosProductHref = undefined;

  delete $stateParams.error404;
  if ($scope.error404 && $stateParams['oos-style-id']) {
    productService.getByStyleId($stateParams['oos-style-id']).then(function(product) {
      var style = _.find(product.styles, { id: $stateParams['oos-style-id'] });
      $scope.oosProductName = product.brand.name + ' | ' + capitalize(style.name);
      $scope.oosProductImage = productService.getImageUrl(style.images[0].path, { webp: true, width: 300 });
      $scope.getOosProductHref = function() {
        return $state.href('root.product.variant', {
          brand: product.brand.id,
          productName: style.slug,
          id: style.id,
          forceview: true,
          color: undefined,
          size: undefined
        });
      };
    });
  }

  $scope.$watch(function() {
    return JSON.stringify($scope.filters);
  }, function() {
    // update breadcrumb
    var category = $scope.filters.category;
    var breadcrumbs = category && category.length && category[category.length - 1] !== '' ? [] : [{ name: 'just in' }];
    var categoryName = categoryHelper.getCategoriesFromTags(category);

    var resetPriceFilter = function() {
      $scope.filters.price.max = '';
      $scope.filters.price.min = '';
      $scope.minPrice = $scope.filters.price.min;
      $scope.maxPrice = $scope.filters.price.max;
    };

    // Centralized spot to reset any filter that
    // shouldn't persist through top level categories
    if ($rootScope.lastTopCategory !== category[0]) {
      resetPriceFilter();
    }
    $rootScope.lastTopCategory = category[0];

    _.forEach(category, function(node, index) {
      if (category[0] !== '') {
        breadcrumbs.push({
          name: categoryName[index],
          link: $state.href('root.shop.category' + (index + 1), {
            category1: category[0],
            category2: category[1],
            category3: category[2]
          })
        });
      }
    });
    $scope.setBreadcrumbPath(breadcrumbs);

    var categoryTitle = categoryName.length ?
      categoryName.join(' / ').replace(/(^|[^\w])(\w)/g, function(l) {
        return l.toUpperCase();
      }) :
      'New Arrivals';

    $scope.setPageMetadata({ title: 'Shop ' + categoryTitle + ' at THE MILE' });
    $scope.setCanonicalUrl({ whitelist: [] });
  });

  $scope.getLastCategoryString = function() {
    var category = $scope.filters.category;
    if (!category || !category[category.length - 1]) {
      return '';
    }

    var prevCategory = category[category.length - 1];
    var categoryNames = categoryHelper.getCategoriesFromTags(category);
    // When selecting multiples categories (separated with ',') => superior category should be shown
    if (prevCategory.indexOf(',') > -1 && category.length >= 2) {
      prevCategory = category[category.length - 2];
    }
    return (prevCategory) ? (categoryNames && categoryNames.length && categoryNames[categoryNames.length - 1]) ||
      prevCategory.replace(/aw-/ig, '').replace(/-/g, ' ') || '' : '';
  };

  $scope.isError404 = function() {
    return $scope.error404;
  };

  $transitions.onStart({}, function(trans) {
    $rootScope.comingFromOosPlp = $scope.isError404() && (!$scope.comingFrom || (!$scope.comingFrom.includes('show') &&
      !$scope.comingFrom.includes('influencer')));
    delete $scope.error404;
    delete $scope.comingFrom;

    trans.promise.finally(function() {
      var isOnWhatsNewPage = $scope.isJustInPage = $state.current.name.indexOf('just-in') >= 0;
      $scope.showSaleFilter = !isOnWhatsNewPage; //Hide In Sale filter on What's New page
    });
  });
}

module.exports = ShopController;
