var _ = require('lodash-core');

// @ngInject
function GiftsController($scope, $state, listContextService, analyticsService, config, staticAssetService) {

  listContextService.setScopeContext($scope);
  $scope.productListName = 'gifts';

  var giftSections = window.orchardMileContent && window.orchardMileContent.gifts &&
    window.orchardMileContent.gifts.groups;
  $scope.featureFlags = config.featureFlags;
  $scope.giftSection = _.find(giftSections, { key: '' });
  $scope.heroImageUrl = staticAssetService.gift($scope.giftSection.key);

  $scope.$watch(function() { return JSON.stringify($scope.filters.gifts); }, function() {

    var breadcrumbs = [{ name: 'gifts', link: $state.href('root.gifts') }];

    if ($scope.filters.gifts[1]) {
      $scope.giftSection = _.find(giftSections, { key: $scope.filters.gifts[1] });
    } else {
      $scope.giftSection = _.find(giftSections, { key: '' });
    }
    $scope.heroImageUrl = staticAssetService.gift($scope.giftSection.key);

    if ($scope.giftSection.key !== '') {
      breadcrumbs.push({ name: $scope.giftSection.name });
      $scope.setPageMetadata({
        title: 'Shop ' + $scope.giftSection.name + ' Gifts at THE MILE',
        description: $scope.giftSection.description,
        image: $scope.heroImageUrl
      });
    } else {
      $scope.setPageMetadata({
        title: 'Shop Gifts at THE MILE',
        description: $scope.giftSection.description,
        image: $scope.heroImageUrl
      });
    }
    $scope.setBreadcrumbPath(breadcrumbs);
    $scope.setCanonicalUrl({ whitelist: [] });
  });

}

module.exports = GiftsController;
