var _ = require('lodash-core');
var categoryHelper = require('shared-business-rules').categories;

// @ngInject
function CategoryTreeDirective($parse, $state, platformService, $rootScope, $stateParams) {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/category-tree.html',
    scope: true,
    link: function($scope, element, attrs) {

      $scope.routeBaseName = $state.current.name.replace(/\.category[1-3]$/, '');

      var filters = $scope.filters;
      filters.countCat = 0;
      var sources = $scope.sources;
      $scope.categories = [];

      $scope.whatsNew = $parse(attrs.whatsNew)($scope.$parent) !== false;
      $scope.defaultText = attrs.defaultText || 'All categories';
      $scope.urlMode = $scope.urlMode && !$scope.categoriesOnQueryString;

      $scope.onContextReady(function() {
        $scope.$watch('facets.category', function() {
          sources.categories = getCategoriesByFacets($scope.facets.category);
          //detect leaf levels
          detectLeafLevel(sources.categories);
          openCategory();
        }, true);

        $scope.$watch('filters.category', function() {
          if (!platformService.isMobile() && hasSubcategoryChanged()) {
            _.assign(filters, {colors: [], sizes: [], price: {}}); // reset filters
          }
          openCategory();
        }, true);

        $scope.$watch('facets.occasion', filterCategoriesByOccasion, true);
      });

      $scope.isNothingSelected = function() {
        return !filters.category ||
          !filters.category.length ||
          !filters.category[0] ||
          filters.category[0] === 'whats-new';
      };

      $scope.toggleNode = function(category) {
        var level = category.length - 1;
        if (level) { // just in case (level 0 cannot be toggle)
          if (filters.category[0] === category[0]) { // the same value at level 0
            if (level === 1 || filters.category[1] === category[1]) { // the same value at level 1
              var current = filters.category[level] && filters.category[level].split(',') || [];
              if ($scope.isNodeSelected(category[level], level)) {
                current = _.filter(current, function(c) {
                  return c !== category[level];
                });
              } else {
                current.push(category[level]);
              }
              category[level] = current.join(',');
            }
          }
        }
        $scope.selectNode(category[0], category[1], category[2]);
      };

      $scope.selectNode = function(category0, category1, category2) {
        if (category2 && filters.category.indexOf(category2) > -1) {
          filters.category.pop();
        } else if (!category2 && category1 && filters.category.indexOf(category1) > -1) {
          filters.category.pop();
        } else if (!category2 && !category1 && category0 && filters.category.indexOf(category0) > -1) {
          filters.category.pop();
        } else {
          filters.category = _.compact([category0, category1, category2]);
        }
        if (category2) {
          filters.countCat = category2.split(',').length;
        } else if (filters.category.length > 1) {
          filters.countCat = 1;
        } else {
          filters.countCat = 0;
        }
      };

      $scope.isNodeSelected = function(categoryPart, level) {
        return filters.category[level] && filters.category[level].split(',').indexOf(categoryPart) > -1;
      };

      $scope.isKidsCategory = function() {
        return $state.params.category1 === 'kids' ||
          !!($state.params && $state.params.brand && $state.params.brand.indexOf('kids') > -1);
      };

      $scope.showWhatsNew = function() {
        return !$scope.isKidsCategory() && (!/root\.shop/.test($state.current.name) ||
          $state.params.category1 === 'whats-new');
      };

      // private methods
      function filterCategoriesByOccasion() {
        var isOccasionTag = function(tag) {
          return Object.keys($scope.facets.occasion.tags).indexOf(tag) !== -1;
        };
        var isFilterCategory = function(tag) {
          return $scope.filters.category.join(',').split(',').indexOf(tag) !== -1;
        };
        var getFilteredCategories = function(list) {
          var filteredList;
          var isLeafLevel = list.every(function(e) {
            return e.leafLevel;
          });
          if (isLeafLevel) {
            var isFilterLevel = list.some(function(e) {
              return isFilterCategory(e.key);
            });
            filteredList = isFilterLevel ? list : list.filter(function(e) {
              return isOccasionTag(e.key);
            });
          } else {
            filteredList = list.map(function(e) {
              if (isOccasionTag(e.key)) {
                e.children = getFilteredCategories(e.children);
              }
              return e;
            });
          }
          return filteredList;
        };
        if ($scope.facets.occasion.tags !== undefined) {
          sources.categories = getFilteredCategories(sources.categories);
        }
      }

      function hasSubcategoryChanged() {
        var currentCategories = [$state.params.category1, $state.params.category2];
        var prevSelectedCategory = $rootScope && $rootScope.currentCategory ? $rootScope.currentCategory.key : '';
        return typeof (currentCategories[1]) !== 'undefined' && //check category2 has been selected
          currentCategories.indexOf(prevSelectedCategory) === -1;  //check categories haven't change
      }

      function openCategory() {
        // if (/root\.shop/.test($state.current.name) &&
        //   $state.params.category1 !== 'whats-new' &&
        //   !platformService.isMobile()) {
        //   // $scope.categories = _.filter(sources.categories, {name: $state.params.category1});
        // } else {
        $scope.categories = sources.categories;
        // }

        if (!$state.params.category1 ||
          $stateParams.category1 === 'whats-new') {
          $rootScope.currentCategory = {};
        }

        closeNodes($scope.categories);
        openNodes(filters.category, $scope.categories);
      }

      function getCategoriesByFacets(facets, list) {
        list = list || categoryHelper.getArray();
        var facet = facets.tags;

        if (!facet) {
          return _.remove(list, function(each) {
            return each.key !== 'kids';
          });
        }

        return _.chain(list).filter(function(node) {
          return facet && facet[node.key];
        }).forEach(function(node) {
          if (node.children) {
            node.children = getCategoriesByFacets(facets, node.children);
          }
        }).value();
      }

      function openNodes(keys, list) {
        if (!keys || !keys.length || !list.length) {
          return;
        }
        keys = keys.slice(0);
        var key = keys.shift();
        var node = _.find(list, {key: key});
        if (node) {
          node.isOpen = true;
          $rootScope.currentCategory = node;
          if (node.children) {
            openNodes(keys, node.children);
          }
        }
      }

      function closeNodes(list) {
        _.forEach(list, function(node) {
          node.isOpen = false;
          if (node.children) {
            closeNodes(node.children);
          }
        });
      }

      function detectLeafLevel(list) {
        var leafLevel = _.every(list, function(node) {
          return !node.children || !node.children.length;
        });
        _.forEach(list, function(node) {
          node.leafLevel = leafLevel;
          if (node.children && node.children.length) {
            detectLeafLevel(node.children);
          }
        });
      }
    }
  };
}

module.exports = CategoryTreeDirective;
