// @ngInject
function EmailCaptureDirective(userService, $parse) {
  // simple test just to prevent unnecessary roundtrips
  // (email address validity is done by type=email)
  var EMAIL_REGEX = /^[^@]+\@[^@]+$/;

  var linker = function($scope, element, attrs) {
    element.on('blur', function() {
      var email = element.val() || '';
      if (userService.getUser().anonymous && email && EMAIL_REGEX.test(email)) {
        var fields = attrs.emailCaptureFields ? $parse(attrs.emailCaptureFields)($scope) : null;
        userService.savePotentialUser(email, fields);
      }
    });
  };
  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = EmailCaptureDirective;
