// @ngInject
function LoginModalController($scope, $state, formsService, userService, config, analyticsService, $rootScope) {
  $scope.featureFlags = $rootScope.featureFlags;
  $scope.model = {
    key: config.recaptcha.siteKey
  };

  $scope.inputs = {
    email: $scope.modal.instanceData.options.email || ''
  };
  $scope.inputs.password = '';
  formsService.focusFirstField();
  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.doLogin = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }
    var params = {
      email: $scope.inputs.email,
      password: $scope.inputs.password,
      trigger: $scope.modal.instanceData && $scope.modal.instanceData.trigger
    };
    formPromise.handle(userService.login(params))
      .then(function() {
        if ($scope.modal.instanceData && $scope.modal.instanceData.trigger) {
          if ($scope.modal.instanceData.trigger.indexOf('authentication-modal') >= 0) {
            analyticsService.track('Logged in from Authentication Modal');
          } else if ($scope.modal.instanceData.trigger.indexOf('header') >= 0) {
            analyticsService.track('Logged in from Side Bar');
          }
        }

        if ($scope.modal) {
          $scope.modal.hide();
        }
      });
  };

  //TODO: remove listeners when scope $destroy
  $scope.userLogInHandler = function() {
    if ($scope.modal) {
      $scope.modal.hide();
    }
  };
  userService.events.on('loggedIn', $scope.userLogInHandler);
  $scope.$on('$destroy', function() {
    userService.events.removeListener('loggedIn', $scope.userLogInHandler);
  });
}

module.exports = LoginModalController;
