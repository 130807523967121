var _ = require('lodash-core');

// @ngInject
function ProductScoresIndicatorDirective($rootScope, $uibModal) {
  var linker = function($scope) {

    $scope.openDetail = function() {
      var instance = $uibModal.open({
        templateUrl: '/views/partials/modals/product-scores-detail.html',
        windowClass: 'product-scores-detail-modal',
        scope: $scope
      });

      $rootScope.addModalToStack();
      instance.result.finally($rootScope.removeModalFromStack);

      $scope.closeDetail = function() {
        instance.dismiss();
      };
      $scope.privateData = $scope.getPrivateData();
    };

    $scope.removeProductOffset = function() {
      if ($scope.removingOffset) {
        return;
      }
      $scope.removingOffset = true;
      $scope.removeOffset($scope.product).then(function() {
        $scope.privateData = $scope.getPrivateData();
        $scope.removingOffset = false;
      });
    };

    $scope.formatPerformanceMetricValue = function(name, value) {
      if (/rate$/i.test(name)) {
        return value + '%';
      }
      if (/revenue/i.test(name)) {
        return '$' + value;
      }
      return value;
    };

    $scope.ageInWeeks = function(date) {
      if (!date) {
        return '';
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      var now = new Date();
      var sunday = new Date(now.getYear() + 1900, now.getMonth(), now.getDate());
      sunday.setDate(sunday.getDate() - sunday.getDay());
      if (date > sunday) {
        return 'this week';
      }
      var weeksOld = Math.ceil((sunday.getTime() - date.getTime()) / 3600000 / 24 / 7);
      if (weeksOld === 1) {
        return 'last week';
      }
      return weeksOld + ' weeks old';
    };

    $scope.$watch('getScoreByAttributes()', function(scores) {
      $scope.noScores = true;
      if (!scores) {
        return;
      }
      $scope.scores = scores;
      var total = _.reduce(_.map(scores, 'score'), function(sum, value) {
        return sum + (value || 0);
      });
      if (!total) {
        return;
      }
      $scope.noScores = false;
      $scope.scores.sort(function(a, b) {
        return (b.score || 0) - (a.score || 0);
      });
      $scope.percentage = {};
      $scope.scoreByAttr = {};
      scores.forEach(function(attribute) {
        $scope.scoreByAttr[attribute.id] = attribute;
        if (!attribute.score) {
          return;
        }
        attribute.percentage = getPercentageClass(attribute.score, total);
        $scope.percentage[attribute.id] = attribute.percentage;
      });
    }, true);

    function getPercentageClass(value, total) {
      return Math.round(100 * (value || 0) / total) + '%';
    }

    $scope.getTooltip = function(attributeId) {
      if (!$scope.scoreByAttr) {
        return;
      }
      var attribute = $scope.scoreByAttr[attributeId];
      if (!attribute) {
        return;
      }
      return attribute.id + ':' + attribute.percentage;
    };
  };
  return {
    link: linker,
    templateUrl: '/views/partials/product-scores-indicator.html',
    restrict: 'E'
  };
}

module.exports = ProductScoresIndicatorDirective;
