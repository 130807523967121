var _ = require('lodash-core');
/*global Promise */
// @ngInject
function InfluencerListRoundedDirective(
  influencerService,
  showService,
  config,
  formatService,
  platformService,
  $state
) {
  var linker = function ($scope, element, attrs) {
    element.on('click', function(e){
      e.preventDefault();
      if(e.target.outerHTML) {
        var match = e.target.outerHTML.match(/name="(.*?)"/);
        if (match && e.target.className === 'influencer-on-click') {
          if ($scope.isDiamondCreator(match[1])) {
            $scope.goToInfluencerPage(match[1]);
          } else {
            $scope.findShows(match[1]);
            $scope.goToSection('results-anchor');
          }
        }
      }
    });

    $scope.isMobile = platformService.isMobile;
    var hideTestItems = config.environment === 'production';
    $scope.isNotReloading = true;
    if (attrs.ids) {
      var items = [];

      var ids = JSON.parse(attrs.ids);

      _.forEach(ids, function (id, ix) {
        loadInfluencer(id, items, ix);
      });

      var itemsToLoad = ids.length;
      var checkLoader = setInterval(function () {
        if (itemsToLoad <= 0) {
          $scope.influencers = items;
          clearInterval(checkLoader);
        }
      }, 1000);
    } else if (attrs.items) {
      $scope.influencers = JSON.parse(attrs.items);
    }

    function loadInfluencer(id, scope, index) {
      influencerService
        .getById(id)
        .then(function (influencer) {
          scope[index] = {
            id: influencer.id,
            name: influencer.username || influencer.preferredName,
            photoUrl: influencer.profilePicture || '/img/the-mile/user-profile-black.svg',
            pageEnabled: influencer.pageEnabled,
            username: influencer.username
          };
        })
        .finally(function () {
          itemsToLoad--;
        });
    }

    $scope.isDiamondCreator = function(influencerId) {
      return _.some($scope.influencers, function(inf) {
        return influencerId === inf.id && inf.pageEnabled;
      });
    };

    $scope.goToInfluencerPage = function(influencerId) {
      var influencerPage = _.find($scope.influencers, function(inf) {
        return inf.id === influencerId;
      });
      if(influencerPage) {
        $state.go('root.influencer', { influencer: influencerPage.name });
      }
    };

    $scope.findShows = function(influencerName) {
      if (!$scope.selectedInf || ($scope.selectedInf && $scope.selectedInf !== influencerName)) {
        $scope.isNotReloading = false;
        if ($scope.searchTerm) {
          $scope.searchTerm = '';
          $scope.results = undefined;
        }
        $scope.selectedInf = influencerName;
        showService.query({
          term: influencerName,
          status: 'live,scheduled,posted',
          'hide-test-shows': hideTestItems,
        }).then(function (shows) {
          var results = shows.items.map(function (show) {
            return {
              id: show.id,
              influencerId: show.influencerId,
              showStatus: show.status,
              showStatusTitle: getShowStatus(show),
              schedule: show.schedule,
              showTitle: show.title,
              imgShowUrl:
                show.s3VideoThumbnails &&
                show.s3VideoThumbnails.length &&
                show.s3VideoThumbnails[0] ||
                show.s3RecordedShowThumbnails &&
                show.s3RecordedShowThumbnails.length &&
                show.s3RecordedShowThumbnails[0] ||
                '/img/bg-video-profile.gif'
            };
          });

          Promise.all(results.map(function (item) {
            return influencerService.getById(item.influencerId).then(function (inf) {
              item.influencerName = inf.username || inf.preferredName;
              item.photoUrl = inf.profilePicture;
            });
          })).finally(function () {
            $scope.isNotReloading = true;
            $scope.influencerShows = results;
            $scope.$digest();
          });
        });
      } else {
        $scope.selectedInf = false;
        $scope.influencerShows = undefined;
        $scope.$digest();
      }
    };

    function getShowStatus(show) {
      try {
        if (show.status === 'scheduled' && show.schedule) {
          var date = new Date(show.schedule);
          if (checkDiffOfDays(show.schedule, 0)) {
            return 'Today, ' + formatService.timeAmPm(date.getHours(), date.getMinutes());
          }
          if (checkDiffOfDays(show.schedule, 1)) {
            return 'Tomorrow, ' + formatService.timeAmPm(date.getHours(), date.getMinutes());
          }
          return formatService.formatDate(date);
        }
        if (show.status === 'posted' && show.views !== undefined) {
          return (Number(show.views) > 1000 ? (Number(show.views) / 1000).toFixed(1) + 'k' : show.views) + ' views';
        }
        if (show.status === 'live') {
          return show.status.toUpperCase();
        }
      } catch (e) {
        console.log(e);
      }
      return show.status;
    }

    function checkDiffOfDays(date, days) {
      var showDate = new Date(date);
      var todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + days);
      if (showDate.getFullYear() === todayDate.getFullYear() &&
        showDate.getMonth() === todayDate.getMonth() &&
        showDate.getDate() === todayDate.getDate()) {
        return true;
      }
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/influencer-list-rounded.html',
    restrict: 'E',
    scope: false,
  };
}

module.exports = InfluencerListRoundedDirective;
