var EventEmitter = require('events').EventEmitter;
var promises = require('../../async/promises');
var _ = require('lodash-core');

/* jshint maxparams: 11 */
/* globals Promise */
// @ngInject
function UserService(config, $rootScope, $cookies, localStorageService, apiService, sessionStorageService) {
  var self = this;
  var storage = {};
  if (localStorageService.isSupported()) {
    storage = window.localStorage;
  } else {
    storage = window.iframeStorage;
  }

  var user = {
    anonymous: true
  };
  try {
    user = JSON.parse(storage.getItem('lastUser')) || {
      anonymous: true
    };
  } catch (e) {}

  var token;

  if (user.token) {
    apiService.addCache('user', user);
  }

  this.events = new EventEmitter();
  this.events.setMaxListeners(1000);
  localStorageService.observer.onKeyChange('lastUser', function() {
    // user logged in/out in another tab
    var currentUser = user;
    user = JSON.parse(storage.getItem('lastUser')) || {
      anonymous: true
    };
    if (
      user.id === currentUser.id &&
      user.email === currentUser.email &&
      user.guest === currentUser.guest &&
      user.anonymous === currentUser.anonymous
    ) {
      return;
    }
    if (user.anonymous && !currentUser.anonymous) {
      self.logout({ source: 'localStorage' });
    }
    if (user.guest && !currentUser.guest) {
      self.events.emit('guestCheckout', user, { source: 'localStorage' });
    }
    if (!user.anonymous && currentUser.anonymous) {
      self.events.emit('loggedIn', user, { source: 'localStorage' });
    }
    if ($rootScope.modal && $rootScope.modal.isVisible) {
      $rootScope.modal.hide();
    }
  });

  this.identifyOnHotjar = function(email, data) {
    if (window.hj) {
      try {
        if (this.isLoggedIn()) {
          window.hj(
            'identify',
            this.getUser().id,
            _.merge(
              {
                email: this.getUser().email
              },
              data
            )
          );
        } else {
          window.hj(
            'identify',
            'potential-' + sessionStorageService.getSessionId(),
            _.merge(
              {
                email: email || this.getUser().email
              },
              data
            )
          );
        }
      } catch (e) {}
    }
  };

  this.environmentDisplayNameSuffix = function() {
    return config.userDisplayNameSuffix || '';
  };

  this.getEmailFromUrl = function() {
    var match = /[\?&#]email=([^\?&#]+)/i.exec(document.location.search);
    if (!match) {
      return;
    }
    return decodeURIComponent(match[1]);
  };

  this.getAll = function(params) {
    return promises.extend(
      apiService.get('/users', {
        urlParams: params
      })
    );
  };

  this.getById = function(id) {
    return promises.extend(
      apiService.get('/users/{id}', {
        pathParams: { id: id }
      })
    );
  };

  this.patchUser = function (id, body) {
    return apiService.patch('/users/{id}', {
      pathParams: {
        id: user.id
      },
      body: body
    });
  };

  this.search = function(parameters) {
    return promises.extend(
      apiService.get('/users/search', {
        urlParams: parameters
      })
    );
  };

  this.saveShowLike = function(showId) {
    var user = this.getUser();
    if (!user.likedShows) {
      user.likedShows = [];
    }
    if (user.likedShows && !_.map(user.likedShows, 'showId').includes(showId)) {
      user.likedShows.push({showId: showId, likedAt: new Date()});
    }
    _setCurrentUser(user);
    if (this.isLoggedIn()) {
      return promises.extend(
        apiService.put('/users/{id}/shows/{showId}/likes', {
          pathParams: {
            showId: showId,
            id: user.id
          },
          body: {}
        })
      );
    }
  };

  this.signup = function(params) {
    params.email = _sanitizeEmail(params.email);
    return promises.extend(
      apiService
        .customPost('/users', {
          body: {
            email: params.email,
            phoneNumber: params.phoneNumber,
            firstName: params.firstName,
            lastName: params.lastName,
            password: params.password,
            'g-recaptcha-response': params.recaptchaResponse,
            /* jshint ignore:start */
            utm_campaign: $rootScope.utmCampaign || '',
            utm_source: $rootScope.utmSource || '',
            utm_medium: $rootScope.utmMedium || '',
            utm_content: $rootScope.utmContent || '',
            /* jshint ignore:end */
            country: ''
          }
        })
        .then(function(response) {
          var userInfo = _.merge(params, response.data);
          self.events.emit('signUp', userInfo);
          token = response.headers('Authorization');
          userInfo.token = token;
          apiService.addCache('user', userInfo);
          _authenticate(userInfo);
          if (params.transactional) {
            self.subscribeEmailTo(params.email, 'newsletter', { source: 'register', campaign: 'register' });
          }
        })
    );
  };

  this.authenticate = function(params) {
    var self = this;
    return promises.extend(
      apiService
        .customPost('/users/authenticate', {
          body: {
            signedRequest: params.signedRequest,
            accessToken: params.accessToken
          }
        })
        .then(function(response) {
          var userInfo = response.data;
          if (userInfo.created === true) {
            userInfo.trigger = params.trigger;
            userInfo.provider = params.provider;
            self.events.emit('signedUp', userInfo);
          }
          _authenticate(userInfo);
        })
        .catch(function(err) {
          self.events.emit('loginFailed', { type: 'authenticate', email: null }, err);
          throw err;
        })
    );
  };

  this.login = function(params) {
    params.email = _sanitizeEmail(params.email);
    return promises.extend(
      apiService
        .customPost('/login', {
          body: {
            email: params.email,
            password: params.password,
            'g-recaptcha-response': params.recaptchaResponse
          }
        })
        .then(function(response) {
          var authenticatedUser = response.data;
          authenticatedUser.trigger = params.trigger;
          token = response.headers('Authorization');
          authenticatedUser.token = token;
          apiService.addCache('user', authenticatedUser);
          _authenticate(authenticatedUser);
        })
        .catch(function(err) {
          self.events.emit('loginFailed', { type: 'login', email: params.email }, err);
          throw err;
        })
    );
  };

  this.guestCheckout = function(email) {
    if (!user.anonymous || typeof email === undefined) {
      // already logged in
      return promises.immediate(null);
    }
    email = _sanitizeEmail(email);
    user.email = email;
    user.guest = true;

    if ($rootScope.shipping && $rootScope.shipping.contact) {
      // update shipping data if exists
      $rootScope.shipping.contact.email = user.email;
    }

    _setCurrentUser(user);
    self.events.emit('guestCheckout', user);
    return promises.immediate(user);
  };

  this.saveOrderOptions = function(orderOptions) {
    return promises.extend(
      apiService.post('/users/{userId}/order-options', {
        pathParams: { userId: user.id },
        body: orderOptions
      })
    );
  };

  this.logout = function(eventInfo) {
    apiService.removeDefaultCommonHeader('Authorization');
    apiService.removeCache();

    var currentUser = user;
    user = {
      anonymous: true
    };
    if (currentUser && currentUser.email) {
      // remember some info of last login (useful for analytics)
      user.email = currentUser.email;
      if (currentUser.id) {
        user.id = currentUser.id;
        user.roles = currentUser.roles;
      }
    }
    if (!eventInfo || eventInfo.source !== 'localStorage') {
      delete user.roles;
      _setCurrentUser(user);
    }
    if ($rootScope.clearUserCheckoutData) {
      $rootScope.clearUserCheckoutData();
    }

    self.events.emit('loggedOut', eventInfo);
  };

  this.isEmailAvailable = function(email) {
    email = _sanitizeEmail(email);
    return promises.extend(
      apiService.get('/users/{email}/available', {
        pathParams: {
          email: email
        }
      })
    );
  };

  this.sendPasswordResetEmail = function(email) {
    email = _sanitizeEmail(email);
    return apiService.post('/users/{email}/password-reset', {
      pathParams: {
        email: email
      },
      urlParams: {
        site: config.siteName
      }
    });
  };

  this.contactUs = function(contactData) {
    function getFullStorySessionUrl() {
      if (!window.FS || !window.FS.getCurrentSessionURL) {
        return;
      }
      try {
        return '\n\nFullStory Session: ' + window.FS.getCurrentSessionURL();
      } catch (err) {
        return '';
      }
    }

    contactData.email = _sanitizeEmail(contactData.email);
    var self = this;
    return promises.extend(
      apiService
        .post('/contact-requests', {
          body: {
            name: contactData.name,
            email: contactData.email,
            subject: contactData.subject,
            transactional: contactData.transactional,
            message: contactData.message + '\n\n' + (getFullStorySessionUrl() || '')
          }
        })
        .then(function() {
          self.events.emit('contactRequest', contactData);
          return true;
        })
    );
  };

  this.resetPassword = function(email, code, password) {
    email = _sanitizeEmail(email);
    return promises.extend(
      apiService.put('/users/{email}/password-reset', {
        pathParams: {
          email: email
        },
        body: {
          code: code,
          password: password
        }
      })
    );
  };

  this.isLoggedIn = function() {
    return user && !user.anonymous;
  };

  this.userIsTester = function() {
    if (user && user.roles && user.roles.indexOf('tester') >= 0) {
      return true;
    }
    return this.isTesterEmail(user && user.email);
  };

  this.isTesterEmail = function(email) {
    if (email) {
      return /^orchardmile\+.*@gmail\.com$/i.test(email) || /^[^\@]+@orchardmile\.com$/i.test(email);
    }
    return false;
  };

  this.getUser = function() {
    return user;
  };

  this.getShippingOptions = function() {
    if (user.guest || user.anonymous) {
      return promises.immediate([]);
    }

    return promises.extend(
      apiService.get('/users/{id}/shipping', {
        pathParams: {
          id: user.id
        }
      })
    );
  };

  this.deleteShippingOption = function(option) {
    if (user.guest) {
      return null;
    }
    return apiService.delete('/users/{id}/shipping/{optionId}', {
      pathParams: {
        id: user.id,
        optionId: option.id
      }
    });
  };

  this.getBillingOptions = function() {
    if (user.guest) {
      return promises.immediate([]);
    }
    return promises.extend(
      apiService.get('/users/{id}/billing', {
        pathParams: {
          id: user.id
        }
      })
    );
  };

  this.deleteBillingOption = function(option) {
    if (user.guest) {
      return null;
    }
    return apiService.delete('/users/{id}/billing/{paymentProvider}/{paymentProviderId}', {
      pathParams: {
        id: user.id,
        paymentProvider: option.paymentMethod.paymentProvider,
        paymentProviderId: option.paymentMethod.paymentProviderId
      }
    });
  };

  this.getOrders = function() {
    return promises.extend(
      apiService.get('/users/{id}/orders', {
        pathParams: {
          id: user.id
        }
      })
    );
  };

  this.getRewards = function() {
    return promises.extend(
      apiService.get('/users/{id}/rewards', {
        pathParams: {
          id: user.id
        }
      })
    );
  };

  this.getReferred = function() {
    return promises.extend(
      apiService.get('/users/{id}/referred', {
        pathParams: {
          id: user.id
        }
      })
    );
  };

  this.subscribeEmailTo = function(email, topic, params) {
    var self = this;
    this.identifyOnHotjar(email);
    return promises.extend(
      apiService
        .put('/users/{email}/subscriptions/{topic}', {
          urlParams: params,
          pathParams: {
            email: email,
            topic: topic
          }
        })
        .then(function(result) {
          if (result.subscribed) {
            /*jshint sub:true*/
            $cookies['_vp'] = 1;
            params = params || {};
            if (params.source === 'footer') {
              params.transactional = true;
              /*
          if its the subscription on the footer that is to our newsletter
          so no need to checkbox
          */
            }
            self.events.emit('subscribedToTopic', {
              topic: topic,
              email: email,
              transactional: params.transactional || false,
              source: params.source || ''
            });
          }
          return result;
        })
    );
  };

  this.notifyMeStock = function(styleId, variantKey, transactional) {
    this.subscribeTo('newsletter', 'stock');
    var user = this.getUser();
    if (user && user.id !== 'guest' && !user.anonymous) {
      transactional = true;
    }
    self.events.emit('subscribedToTopic', {
      topic: 'stock',
      email: user.email,
      transactional: transactional,
      source: 'notify-me-in-stock'
    });
    return promises.extend(
      apiService.post('/users/back-in-stock-alert', {
        body: {
          email: user.email,
          styleId: styleId,
          variantKey: variantKey
        }
      })
    );
  };

  this.notifyMeAvailable = function(brandId, styleId, variantKey, transactional) {
    this.subscribeTo('newsletter', 'stock');
    var user = this.getUser();
    if (user && user.id !== 'guest' && !user.anonymous) {
      transactional = true;
    }
    self.events.emit('subscribedToTopic', {
      topic: 'brand',
      email: user.email,
      transactional: transactional,
      source: 'notify-me-available'
    });
    return promises.extend(
      apiService.post('/users/back-to-available-alert', {
        body: {
          email: user.email,
          styleId: styleId,
          variantKey: variantKey,
          brandId: brandId
        }
      })
    );
  };

  this.subscribeTo = function(topic, source) {
    var user = this.getUser();
    if (!user || !user.email) {
      return;
    }
    return this.subscribeEmailTo(user.email, topic, { source: source });
  };

  this.unSubscribeTo = function(topic) {
    var user = this.getUser();
    if (!user || !user.email) {
      return;
    }
    return this.unsubscribe(user.email, topic);
  };

  this.getSubscriptionsByTopic = function() {
    var user = this.getUser();
    if (!user || !user.email) {
      return promises.immediate({});
    }
    return promises.extend(
      apiService.get('/users/{email}/subscriptions', {
        pathParams: {
          email: user.email
        }
      })
    );
  };

  this.isSubscribedTo = function(email, topic) {
    var user = this.getUser();
    if (!user || !user.email) {
      return promises.immediate({});
    }
    return new Promise(function(resolve) {
      apiService
        .get('/users/{email}/subscriptions', {
          pathParams: {
            email: user.email
          }
        })
        .then(function(subscriptions) {
          return resolve(_.find(subscriptions, { topic: topic }));
        });
    });
  };

  this.invite = function(email, options) {
    options = options || {};
    email = _sanitizeEmail(email);
    var params = {
      site: config.siteName
    };
    if (options.firstName) {
      params.firstName = options.firstName;
    }
    if (options.lastName) {
      params.lastName = options.lastName;
    }
    if (options && options.skipEmail) {
      params.skipEmail = true;
    }
    return promises.extend(
      apiService.put('/users/{email}/invite', {
        urlParams: params,
        pathParams: {
          email: email
        }
      })
    );
  };

  this.checkInvite = function(email, code) {
    email = _sanitizeEmail(email);

    if (!email || email === '' || !code || code.trim() === '') {
      return promises.immediate({ invalid: true, notValid: true });
    }
    return promises.extend(
      apiService.get('/users/{email}/invite', {
        pathParams: {
          email: email
        },
        urlParams: {
          code: code
        }
      })
    );
  };

  this.unsubscribe = function(email, topic) {
    var self = this;
    email = _sanitizeEmail(email);

    if (typeof topic === 'undefined' || topic === '') {
      topic = 'newsletter';
    }

    return promises.extend(
      apiService
        .delete('/users/{email}/subscriptions/{topic}', {
          pathParams: {
            email: email,
            topic: topic
          }
        })
        .then(function() {
          self.events.emit('unsubscribe', {
            email: email
          });
        })
    );
  };

  this.redeemInvite = function(email, code, password, firstName, lastName, mileId, transactional) {
    email = _sanitizeEmail(email);
    var self = this;
    return promises.extend(
      apiService
        .put('/users/{email}/invite/redeem', {
          pathParams: {
            email: email
          },
          body: {
            firstName: firstName,
            lastName: lastName,
            code: code,
            password: password,
            mileId: mileId,
            country: ''
          }
        })
        .then(function() {
          self.events.emit('inviteRedeemed', {
            email: email,
            firstName: firstName,
            lastName: lastName,
            transactional: transactional
          });
        })
    );
  };

  this.showValueProposition = function() {
    self.events.emit('viewedValueProposition');
  };

  this.dismissValueProposition = function() {
    self.events.emit('dismissedValueProposition');
  };

  this.redeemPoints = function() {
    self.events.emit('redeemPoints');
  };

  this.requestEmailValidation = function() {
    if (!user || user.anonymous || !user.email) {
      return;
    }
    return promises.extend(
      apiService
        .post('/users/{email}/validation', {
          pathParams: {
            email: user.email
          }
        })
        .then(function(response) {
          // if the user was validated by other session in other browser
          if (response.alreadyValidated) {
            user.validated = true;
            _setCurrentUser(user);
          }
        })
    );
  };

  this.validateEmail = function(email, code) {
    return promises.extend(
      apiService
        .put('/users/{email}/validation', {
          pathParams: {
            email: email
          },
          body: {
            code: code
          }
        })
        .then(function(response) {
          if (response.success) {
            user.validated = true;
            _setCurrentUser(user);
          }
          return response;
        })
    );
  };

  this.giveReward = function(userId, reward) {
    return promises.extend(
      apiService.post('/users/{userId}/rewards', {
        pathParams: {
          userId: userId
        },
        body: reward
      })
    );
  };

  this.removeReward = function(userId, rewardId) {
    return promises.extend(
      apiService.delete('/users/{userId}/rewards/{rewards}', {
        pathParams: {
          userId: userId,
          rewards: rewardId
        }
      })
    );
  };

  this.setDefaultEmail = function(email) {
    if (email && user.anonymous) {
      user.email = email;
      _setCurrentUser(user);
    }
  };

  this.savePotentialUser = function(email, fields) {
    email = _sanitizeEmail(email);
    this.identifyOnHotjar(email);
    return promises.extend(
      apiService.put('/users/{email}/potential', {
        pathParams: {
          email: email
        },
        urlParams: fields
      })
    );
  };

  this.getPhoneNumber = function() {
    return promises.extend(
      apiService
        .get('/users/{userId}/extra-data', {
          pathParams: {
            userId: user.id
          }
        })
        .then(function(result) {
          if (result.hasOwnProperty('phoneNumber')) {
            user.phoneNumber = result.phoneNumber;
            _setCurrentUser(user);
          }
        })
    );
  };

  this.setPhoneNumber = function(phoneNumber, email, isPotentialUser) {
    return promises.extend(
      apiService
        .put('/users/{userId}/phone-number', {
          pathParams: {
            userId: isPotentialUser ? email : user.id
          },
          body: {
            phoneNumber: phoneNumber,
            isPotentialUser: isPotentialUser
          }
        })
        .then(function() {
          if (user.id) {
            self.events.emit('checkAwardPoints');
            user.phoneNumber = phoneNumber;
            self.events.emit('updatePersonalInformation', {
              /* jshint camelcase: false */
              phone_mobile: phoneNumber
            });
            _setCurrentUser(user);
          }
        })
    );
  };

  this.deletePhoneNumber = function() {
    return promises.extend(
      apiService
        .delete('/users/{userId}/phone-number', {
          pathParams: {
            userId: user.id
          }
        })
        .then(function() {
          delete user.phoneNumber;
          self.events.emit('updatePersonalInformation', {
            /* jshint camelcase: false */
            phone_mobile: ''
          });
          _setCurrentUser(user);
        })
    );
  };

  this.getBirthday = function() {
    return promises.extend(
      apiService
        .get('/users/{userId}/extra-data', {
          pathParams: {
            userId: user.id
          }
        })
        .then(function(result) {
          if (result.hasOwnProperty('birthday')) {
            user.birthday = result.birthday;
            _setCurrentUser(user);
          }
        })
    );
  };

  this.setBirthday = function(birthday) {
    return promises.extend(
      apiService
        .put('/users/{userId}/extra-data', {
          pathParams: {
            userId: user.id
          },
          body: {
            birthday: birthday
          }
        })
        .then(function() {
          user.birthday = birthday;
          self.events.emit('checkAwardPoints');
          self.events.emit('updatePersonalInformation', {
            birthday: birthday.substr(5, 2) + '/' + birthday.substr(8, 2) + '/' + birthday.substr(0, 4)
          });
          _setCurrentUser(user);
        })
    );
  };

  this.deleteBirthday = function() {
    return promises.extend(
      apiService
        .delete('/users/{userId}/birthday', {
          pathParams: {
            userId: user.id
          }
        })
        .then(function() {
          delete user.birthday;
          self.events.emit('updatePersonalInformation', {
            birthday: ''
          });
          _setCurrentUser(user);
        })
    );
  };

  this.getName = function() {
    return promises.extend(
      apiService
        .get('/users/{userId}/extra-data', {
          pathParams: {
            userId: user.id
          }
        })
        .then(function(result) {
          if (result.hasOwnProperty('firstName') && result.hasOwnProperty('lastName')) {
            user.firstName = result.firstName;
            user.lastName = result.lastName;
            _setCurrentUser(user);
          }
        })
    );
  };

  this.setName = function(name) {
    return promises.extend(
      apiService
        .put('/users/{userId}/extra-data', {
          pathParams: {
            userId: user.id
          },
          body: {
            firstName: name.firstName,
            lastName: name.lastName
          }
        })
        .then(function() {
          user.firstName = name.firstName;
          user.lastName = name.lastName;
          self.events.emit('updatePersonalInformation', {
            firstname: name.firstName,
            lastname: name.lastName
          });
          _setCurrentUser(user);
        })
    );
  };

  this.setLang = function(lang) {
    return promises.extend(
      apiService
        .put('/users/{userId}/extra-data', {
          pathParams: {
            userId: user.id
          },
          body: {}
        })
        .then(function() {
          user.lang = lang;
          _setCurrentUser(user);
        })
    );
  };

  this.getLoyalty = function() {
    return promises.extend(
      apiService.get('/users/{userId}/loyalty', {
        pathParams: {
          userId: user.id
        }
      })
    );
  };

  this.getCloset = function(userId) {
    return promises.extend(
      apiService.get('/users/{userId}/products', {
        pathParams: {
          userId: userId
        }
      })
    );
  };

  // For admins to get loyalty info of different users
  this.getLoyaltyByUserId = function(userId) {
    return promises.extend(
      apiService.get('/users/{userId}/loyalty', {
        pathParams: {
          userId: userId
        }
      })
    );
  };

  // For users to get loyalty status
  this.getLoyaltyStatusByUserId = function(userId) {
    return promises.extend(
      apiService.get('/users/{userId}/loyalty/status', {
        pathParams: {
          userId: userId
        }
      })
    );
  };

  this.giveLoyaltyAward = function(userId, award) {
    return promises.extend(
      apiService.post('/users/{userId}/loyalty-award', {
        pathParams: {
          userId: userId
        },
        body: {
          award: award
        }
      })
    );
  };

  this.admin = {
    setNotes: function(userId, notes) {
      return promises.extend(
        apiService.put('/users/{userId}/notes', {
          pathParams: {
            userId: userId
          },
          body: {
            notes: notes
          }
        })
      );
    }
  };

  // --- private methods ---
  function _authenticate(userInfo) {
    // override current user
    user = {
      id: userInfo.id,
      email: userInfo.email,
      alias: userInfo.alias,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
      roles: userInfo.roles,
      likedShows: userInfo.likedShows,
      lang: userInfo.lang,
      token: token // override token with local
    };

    // if checking out set contact info
    if ($rootScope.shipping && $rootScope.shipping.contact) {
      // update shipping data if exists
      $rootScope.shipping.contact.email = user.email;
      $rootScope.shipping.contact.firstName = user.firstName;
      $rootScope.shipping.contact.lastName = user.lastName;
    }
    _setCurrentUser(user);
    if (userInfo.created) {
      self.events.emit('signedUp', userInfo);
    }
    self.events.emit('loggedIn', userInfo);

    return user;
  }

  function _setCurrentUser(userInfo) {
    // follow user on hotjar
    if (window.hj && (userInfo.id || userInfo.email)) {
      try {
        window.hj('identify', userInfo.id || 'potential-' + sessionStorageService.getSessionId(), {
          email: userInfo.email
        });
      } catch (e) {}
    }
    localStorageService.setItem('lastUser', JSON.stringify(userInfo));
  }

  function _sanitizeEmail(email) {
    if (!email) {
      return email;
    }
    return email.toLowerCase().trim();
  }
}

module.exports = UserService;
