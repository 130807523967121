var _ = require('lodash-core');
var categoryHelper = require('shared-business-rules').categories;

// @ngInject
function SaleController($scope, $state, listContextService) {


  listContextService.setScopeContext($scope);
  $scope.productListName = 'sale';
  $scope.filters.sale = true;

  $scope.setPageMetadata({
    description: 'Shop the Sale Section at The Mile. Full Collections. One Destination.'
  });

  $scope.$watch(function() { return JSON.stringify($scope.filters); }, function() {
    // update breadcrumb
    var category = $scope.filters.category;
    var breadcrumbs = [{ name: 'sale', link: $state.href('root.sale', { category1: 'whats-new' }) }];
    var categoryName = categoryHelper.getCategoriesFromTags(category);

    _.forEach(category, function(node, index) {
      breadcrumbs.push({
        name: categoryName[index],
        link: $state.href('root.sale.category' + (index + 1), {
          category1: category[0],
          category2: category[1],
          category3: category[2]
        })
      });
    });
    $scope.setBreadcrumbPath(breadcrumbs);

    var categoryTitle = categoryName.length ?
      categoryName.join(' / ').replace(/(^|[^\w])(\w)/g, function(l) { return l.toUpperCase(); }) :
      'New Arrivals';

    $scope.setPageMetadata({ title: 'Sale ' + categoryTitle + ' at THE MILE' });
    $scope.setCanonicalUrl({ whitelist: [] });
  });

  $scope.getLastCategoryString = function() {
    var category = $scope.filters.category;
    if (!category) {
      return '';
    }
    return category[category.length - 1] || '';
  };

}

module.exports = SaleController;
