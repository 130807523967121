var algoliasearch = require('algoliasearch/lite');

var promises = require('../../async/promises');
var _ = require('lodash-core');
var categories = require('shared-business-rules').categories.getArray();
var brandData = window.orchardMileContent.brands;

/* jshint maxparams: 11 */
// @ngInject
function SearchService(config, $rootScope, brandService, $timeout, $state, platformService,
  viewportService, localStorageService, userService, sessionStorageService, algoliaInsightsService) {

  var self = this;
  this.algoliaSearchObj = null;
  var getBrandsPromise = brandService.getAll;
  var getBrands = _.memoize(function() {
    return getBrandsPromise().lazyArray();
  });

  this.search = function(params) {
    return _.memoize(function(params2) {
      var client = self.getAlgoliaClient();
      var responseDeferred = promises.createDeferred();
      getBrandsPromise().then(function() {
        var queries = _.map(params2 instanceof Array ? params2 : [params2], function (p) {
          var brandsAllowed = filterBrandsByCountry(p.brand);
          var excludedBrands = _.merge([], p.excludeBrand, getBrandsToFilter());
          return self.getQueryParameters({
            searchTerm: p.searchTerm,
            sizes: p.sizes,
            colors: p.colors,
            brand: brandsAllowed,
            excludeBrands: excludedBrands,
            excludeBrand: p.excludeBrand || null,
            tags: p.tags,
            page: p.page - 1,
            hitsPerPage: p.limit || 0,
            sort: p.sort,
            sale: p.sale,
            discount: p.discount,
            gifts: p.gifts,
            price: p.price,
            filters: p.filters,
            inStore: p.inStore,
            noDesigners: p.noDesigners,
            indexName: p.indexName
          });
        });
        algoliaInsightsService.sendFilters(queries);
        client
          .search(_.without(queries, undefined))
          .then(self.getResponseProcess(params2))
          .then(digest(responseDeferred.resolve));
      });
      return promises.extend(responseDeferred.promise);
    })(params);
  };

  function getBrandsToFilter() {
    var rule = _.find($rootScope.featureFlags.internationalBlockedBrands.rules, function (rule) {
      return rule.countries.includes($rootScope.countrySelected());
    });
    if (rule && $rootScope.featureFlags.internationalBlockedBrands.enabled) {
      return rule.brands;
    } else {
      return [];
    }
  }

  function filterBrandsByCountry(brands) {
    if ($rootScope.featureFlags.internationalBlockedBrands.enabled && $rootScope.countrySelected() !== 'us') {
      if (brands && brands.length > 0) {
        var blockedBrands = getBrandsToFilter();
        var filteredBrands = _.filter(brands, function (brand) {
          return !blockedBrands.includes(brand);
        });
        if (filteredBrands && filteredBrands.length) {
          return filteredBrands;
        } else {
          return ['non-exist-brand'];
        }
      }
    }
    return brands;
  }

  this.getAlgoliaClient = function() {
    if (this.algoliaSearchObj === null) {
      this.algoliaSearchObj = algoliasearch(config.algolia.applicationId, config.algolia.apiKey);
    }
    return this.algoliaSearchObj;
  };

  this.getRedirectIndex = function() {
    if (!this.redirectIndex) {
      this.redirectIndex = this.getAlgoliaClient().initIndex(config.algolia.redirectIndex);
    }
    return this.redirectIndex;
  };

  this.searchAlgoliaRedirect = function(term, callback) {
    this.getRedirectIndex().search('', {
      hitsPerPage: 1,
      facetFilters: [
        'query_terms:' + term
      ]
    }).then(callback);
  };

  this.getQueryParameters = function(params) {
    var facets = ['*'];
    var facetFilters = [];

    var brandIds = _.compact(params.brand); // clone brands ids
    var oneBrand = brandIds.length === 1;
    if (oneBrand) {
      var brand = _.find(getBrands(), { id: brandIds[0] });
      if (brand === undefined || brand.visible === false) {
        //not send query to algolia
        return;
      }
    }
    var noTags = !_.compact(params.tags).length;

    var isOnWhatsNewPage = $state.current.name.indexOf('root.shop.just-in') >= 0 &&
      $state.params.category1.indexOf('whats-new') >= 0;
    var isOnNewInSale = $state.current.name.indexOf('root.sale') >= 0 &&
      typeof ($state.params.category1) === 'undefined';

    var hasKidsTag = _.filter(params.tags, function(each) {
      return each.indexOf('kids') >= 0 || each.indexOf('beauty') >= 0;
    }).length;
    var isOnDesignerPage = $state.current.name.indexOf('root.brand-products') >= 0;

    if (isOnDesignerPage) {
      delete params.noDesigners;
    }

    if (brandIds && brandIds.length && brandIds[0] !== 'null') {
      var designers = {};
      var stores = {};
      _.forEach(_.keys(brandData), function(id) {
        var brand = brandData[id];
        if (brand.store && brandIds.indexOf(brand.store) > -1) {
          designers[id] = true;
          stores[brand.store] = true;
        }
      });
      brandIds = _.filter(brandIds, function(id) {
        return !stores[id];
      }); // remove stores

      if (!params.noDesigners) {
        brandIds = brandIds.concat(_.keys(designers)); // add designers
      }

      facetFilters.push(_.map(brandIds, function(id) {
        return 'brandId:' + id;
      }));
    }
    facetFilters.push('brandEnabled:true');

    if (!isOnDesignerPage && !hasKidsTag && params.tags && params.tags.length &&
      $state.current.name.indexOf('root.pages') !== 0) {
      if (params.filters && params.filters !== '') {
        params.filters = params.filters + ' AND NOT tags:kids';
      } else {
        params.filters = 'NOT tags:kids';
      }
    }

    var disjunctiveTags = [];
    _.forEach(params.tags, function(tag) {
      var tagOptions = tag.split(',');
      if (tagOptions.length > 1) {// if contains comma then is disjunctive
        _.forEach(tagOptions, function(option) {
          if (/^u\./.test(tag)) {
            disjunctiveTags.push('styles.tags:' + option);
          } else {
            disjunctiveTags.push('tags:' + option);
          }
        });
      } else {
        if (/^u\./.test(tag)) {
          facetFilters.push('styles.tags:' + tag);
        } else {
          facetFilters.push('tags:' + tag);
        }
      }
    });
    if (disjunctiveTags.length) {
      facetFilters.push(disjunctiveTags);
    }

    var disjunctiveSize = [];
    _.forEach(params.sizes, function(t) {
      disjunctiveSize.push('styles.variants.key.size:' + t);
    });
    if (disjunctiveSize.length) {
      facetFilters.push(disjunctiveSize);
    }

    var normalizedColors = [];
    _.forEach(params.colors, function(t) {
      normalizedColors.push('styles.variants.key.color:' + t);
    });
    if (normalizedColors.length) {
      facetFilters.push(normalizedColors);
    }
    var indexName = config.algolia.index;
    if (params.indexName) {
      indexName = params.indexName;
    } else if (params.sort) {
      if (Array.isArray(params.sort) && params.sort.length > 1) {
        params.sort = params.sort[0];
      }
      indexName = [indexName, 'by', params.sort].join('-');
    } else if (config.featureFlags.sortByMultiBrandScoreOnRelevant.enabled &&
      oneBrand && brandData[params.brand] &&
      brandData[params.brand].store && !params.searchTerm) {
      // sort by brands score when there is one brand and no searchTerm filter
      indexName = indexName + '-by-scores-multibrand';
    } else if (config.featureFlags.sortByScoreOnRelevant.enabled &&
      !oneBrand && noTags &&
      !params.searchTerm) {
      // sort by whatsnew score when there is no tags nor brand nor searchTerm filter
      indexName = indexName + '-by-scores-whatsnew';
    } else if (config.featureFlags.sortByCategoriesScoreOnRelevant.enabled &&
      !oneBrand && !noTags &&
      !params.searchTerm) {
      // sort by categories score when there is no brand nor searchTerm filter
      indexName = indexName + '-by-scores-categories';
    } else if (config.featureFlags.sortByBrandScoreOnRelevant.enabled &&
      oneBrand &&
      !params.searchTerm) {
      // sort by brands score when there is one brand and no searchTerm filter
      indexName = indexName + '-by-scores-brands';
    } else if (config.featureFlags.sortByScoreOnRelevant.enabled &&
      config.featureFlags.sortByCategoriesScoreOnRelevant.enabled &&
      config.featureFlags.sortByBrandScoreOnRelevant.enabled) {

      // use popularity by default except in search that we have another index for better analytics

      indexName = ($state.current.name !== 'root.search') ?
        (indexName + '-by-scores-popular') : 'products-for-search';
    }
    var numericFilters = [];
    if (params.sale || params.discount) {
      var discount = 0;
      if (params.discount > 0) {
        discount = params.discount - 1;
      }
      numericFilters.push('styles.variants.discount>' + discount);
    }

    _.forEach(params.gifts, function(gift) {
      facetFilters.push('tags:' + 'u.' + gift);
      if (/^under-\d+$/.test(gift)) {
        numericFilters.push('styles.variants.price<' + gift.split('-')[1] + '00');
      }
    });

    if (params.price) {
      var min = parseInt(params.price.min) * 100;
      if (min) {
        numericFilters.push('styles.variants.price>=' + min);
      }
      var max = parseInt(params.price.max) * 100;
      if (max && max >= (min || 0)) {
        numericFilters.push('styles.variants.price<=' + max);
      }
    }


    if (isOnWhatsNewPage) { //Now its just in
      params.filters = (params.filters && params.filters !== '') ? params.filters + ' AND ' : '';
      params.filters += 'NOT tags:kids AND NOT tags:men'; // Not list kids products on What's New page
      facetFilters.push('styles.isLowInStock:false'); // Not list Low Stock products on What's New page
      numericFilters.push('styles.variants.discount=0'); // Not list In Sale products on What's New page
    }


    if (isOnNewInSale) {
      params.filters = (params.filters && params.filters !== '') ? params.filters + ' AND ' : '';
      // Not list kids products or men on Sale page when NewInSale filter is selected
      params.filters += 'NOT tags:kids AND NOT tags:men';
    }

    if (params.excludeBrand) {
      params.filters = (params.filters && params.filters !== '') ? params.filters + ' AND ' : '';
      params.filters += 'NOT brandId:' + params.excludeBrand;
    }

    if (params.excludeBrands && params.excludeBrands.length) {
      _.forEach(params.excludeBrands, function (excludedBrand) {
        params.filters = (params.filters && params.filters !== '') ? params.filters + ' AND ' : '';
        params.filters += 'NOT brandId:' + excludedBrand;
      });
    }

    if (params.inStore) {
      facetFilters.push('styles.inStore:true');
    }

    var analyticsTags = [];
    //router-state
    analyticsTags.push($state.current.name);

    //device-type
    if (platformService.isMobile()) {
      if (viewportService.is('<sm')) {
        analyticsTags.push('mobile');
      } else {
        analyticsTags.push('tablet');
      }
    } else {
      analyticsTags.push('desktop');
    }

    var user = userService.getUser();
    var userToken;
    if (userService.isLoggedIn()) {
      userToken = user.id;
    } else {
      userToken = sessionStorageService.getSessionId();
    }

    return {
      indexName: indexName,
      query: params.searchTerm,
      clickAnalytics: true,
      enablePersonalization: user && !user.isAdmin && config.environment === 'production',
      userToken: userToken,
      params: {
        page: params.page,
        hitsPerPage: params.hitsPerPage,
        facets: facets,
        analyticsTags: analyticsTags,
        filters: params.filters || '',
        facetFilters: facetFilters,
        numericFilters: numericFilters.join(',')
      }
    };
  };

  this.getFacets = function(params) {
    var queries = _.map(params instanceof Array ? params : [params], function(p) {
      return {
        searchTerm: p.searchTerm,
        sizes: p.sizes,
        colors: p.colors,
        brand: p.brand,
        tags: p.tags,
        sale: p.sale,
        discount: p.discount,
        price: p.price,
        page: 1,
        hitsPerPage: 0
      };
    });

    return _.memoize(function(par) {
      return self.search(par).then(function(responses) {
        responses = _.map(responses, function(response) {
          response.facets = response.facets || {};
          response.facets.sizes = response.facets['styles.variants.key.size'];
          response.facets.colors = response.facets['styles.variants.key.color'];
          response.facetsStats = response.facetsStats || {};
          response.facetsStats.discount = response.facetsStats['styles.variants.discount'] || 0;
          return _.merge(response.facets, response.facetsStats);
        });
        return params instanceof Array ? responses : responses[0];
      });
    })(queries);
  };

  this.getResponseProcess = function(params) {
    /*jshint camelcase: false */
    return function(response) {
      var results = _.map(response.results, function(result) {
        var products = _.filter(result.hits, function(product) {
          return product.styles && product.styles.length;
        });
        var listItems = _.map(products, self.getProductListItemMapping(params));
        if (result.queryID) {
          localStorageService.setItem('queryId', result.queryID);
        }
        return {
          items: listItems,
          count: result.nbHits,
          pages: result.nbPages,
          facets: result.facets,
          facetsStats: result.facets_stats,
          query: result.query,
          index: result.index
        };
      });
      var finalResult = params instanceof Array ? results : results[0];
      finalResult = finalResult || {};
      finalResult.items = finalResult.items || [];
      finalResult.count = finalResult.count || 0;
      return finalResult;
    };
  };

  this.getProductListItemMapping = function(params) {
    //get the user tags from the filters
    var userTags = _.filter(params.tags, function(tag) {
      return /^u\./.test(tag);
    });
    var oneBrand = _.compact(params.brand).length === 1;

    return function(product) {
      var scores = {};

      // set style userTags matching with params.tags filter
      if (userTags.length) {
        _.forEach(product.styles, function(current) {
          scores[current.id] = scores[current.id] || {};
          scores[current.id].userTags = _.intersection(userTags, current.tags).length ? 1 : 0;
        });
      }

      // avoid to show sale style if it possible
      if (config.featureFlags.deprioritizeSales.enabled && !params.sale && oneBrand) {
        _.forEach(product.styles, function(current) {
          scores[current.id] = scores[current.id] || {};
          scores[current.id].notSale = _.some(current.variants, function(variant) {
            return variant.discount;
          }) ? 0 : 1;
        });
      }

      // set the style highLight score using _highlightResult watching matchLevel
      if (params.searchTerm && product._highlightResult && product._highlightResult.styles) {
        _.forEach(product._highlightResult.styles, function(current, index) {
          var str = JSON.stringify(current);
          var matchFull = str.match(/"matchLevel"\s*:\s*"full"/g) || [];
          var matchPartial = str.match(/"matchLevel"\s*:\s*"partial"/g) || [];
          scores[product.styles[index].id] = scores[product.styles[index].id] || {};
          scores[product.styles[index].id].highLight = 2 * matchFull.length + matchPartial.length;
        });
      }

      // set the style sort and filter scores
      if (params.sort || params.sizes && params.sizes.length ||
        params.price && (params.price.min || params.price.max) ||
        params.colors && params.colors.length) {
        _.forEach(product.styles, function(current) {
          scores[current.id] = scores[current.id] || {};
          if (/^price-/.test(params.sort)) {
            scores[current.id].sort = params.sort === 'price-asc' ?
              _.min(_.map(current.variants, 'price')) * (-1) || 0 :
              _.max(_.map(current.variants, 'price')) || 0;
          }
          if (params.sizes && params.sizes.length) {
            scores[current.id].filters = _.some(current.variants, function(variant) {
              return _.intersection(variant.key && variant.key.size, params.sizes).length;
            }) ? 1 : 0;
          }

          if (params.colors && params.colors.length) {
            scores[current.id].filters = _.some(current.variants, function(variant) {
              return _.intersection(variant.key && variant.key.color.map(toUpper), params.colors.map(toUpper)).length;
            }) ? 1 : 0;
          }

          if (params.price && (params.price.min || params.price.max)) {
            var max = parseInt(params.price.max) * 100;
            var min = parseInt(params.price.min) * 100 || 0;
            scores[current.id].filters = _.some(current.variants, function(variant) {
              if (variant.price >= min) {
                if (max < min || variant.price <= max) {
                  return true;
                }
              }
              return false;
            }) ? 1 : 0;
          }
        });
      }

      if ((params.sort && params.sort === 'scores-whatsnew') && oneBrand) {
        _.forEach(product.styles, function(current) {
          if (current && current.newArrivals) {
            scores[current.id] = scores[current.id] || {};
            scores[current.id].newArrivals = 1 - (current && current.newArrivals / 100);
          }
        });
      }

      var styles = product.styles;

      var isSale = (Array.isArray(params)) ? _.compact(_.map(params, 'sale')).length > 0 : params.sale;
      if (isSale) {
        styles = _.filter(styles, function(style) {
          style.variants = _.filter(style.variants, function(variant) {
            return variant.discount > 0;
          });
          return style.variants.length;
        });
      }
      var extraData = {};
      extraData.showPriceRange = false;
      _.forEach(product.styles, function(style) {
        _.forEach(style.variants, function(variant) {
          if (extraData.minPrice > variant.price || !extraData.minPrice) {
            extraData.minPrice = variant.price;
          }
          if (extraData.maxPrice < variant.price || !extraData.maxPrice) {
            extraData.maxPrice = variant.price;
          }
        });
      });
      if (extraData.minPrice !== extraData.maxPrice) {
        extraData.showPriceRange = true;
      }

      var style = _.isEmpty(scores) ? styles[0] : _.sortBy(styles, function(current) {
        var score = scores[current.id];

        var scoreValue =
          (score.userTags || 0) * 1000000000000000 +
          (score.newArrivals || 0) * 10000000000000 +
          (score.highLight || 0) * 100000000000 +
          (score.sort || 0) * 100000000 +
          (score.filters || 0) * 10000 +
          (score.notSale || 0);
        return scoreValue * -1;
      })[0];

      var listItem = self.flattenStyle(product, style, extraData);
      listItem.otherStyles = styles.filter(function(otherStyle) {
        return otherStyle.id !== style.id;
      }).map(function(otherStyle) {
        return self.flattenStyle(product, otherStyle, extraData);
      });
      return listItem;
    };
  };

  this.flattenStyle = function(product, style, extraData) {
    var variant = style.variants && style.variants[0] || {};

    return {
      id: product.objectID,
      styleId: style.id,
      sku: style.sku || product.sku,
      name: style.name || product.name,
      slug: style.slug || product.slug,
      curated: product.curated,
      image: style.images[0],
      hover: style.images[1],
      brand: this.getBrand(product.brandId),
      tags: product.tags,
      price: variant.price,
      listPrice: variant.listPrice,
      inStock: style.inStock !== false,
      sizes: variant && variant.key && variant.key.size,
      isLowInStock: style.isLowInStock || false,
      showPriceRange: extraData.showPriceRange || 0,
      minPrice: extraData.minPrice || 0,
      maxPrice: extraData.maxPrice || 0
    };
  };

  this.getBrand = _.memoize(function(brandId) {
    var brand = _.find(getBrands(), { id: brandId });
    return {
      id: brandId,
      name: brand && brand.name || brandId
    };
  });

  this.getAllBrands = _.memoize(function() {
    return getBrands();
  });

  this.sortByCategory = function(products) {
    return _.sortBy(products, function(product) {
      return getPosition(product.tags);
    });

    function getPosition(tags, level) {
      tags = tags || [];
      level = level || categories;

      var node = _.find(level, function(node) {
        return tags.indexOf(node.key) > -1;
      });
      if (!node) {
        return 9999; // not found category
      }
      if (!node.children || !node.children.length) {
        return node.position;
      }
      var pos = getPosition(tags, node.children || []);
      return pos === 9999 ? node.position : pos;
    }
  };

  // ensure $digest is fired
  function digest(fn) {
    return function() {
      fn.apply(this, arguments);
      $timeout(function() {
      });
    };
  }
}

prepareCategories(categories);

// set position for each category in the tree
function prepareCategories(current, pos) {
  pos = pos || 0;
  _.forEach(current, function(node) {
    pos++;
    node.position = pos;
    if (node.children && node.children.length) {
      pos = prepareCategories(node.children, pos);
    }
  });
  return pos;
}

function toUpper(item) {
  return item.toUpperCase();
}

module.exports = SearchService;
