
var promises = require('../../async/promises');

// @ngInject
function ProductRankingsService(apiService) {

  this.getRankings = function() {
    return apiService.get('/product-rankings');
  };

  this.getAttributes = promises.memoize(function() {
    return apiService.get('/product-rankings/attributes');
  });

  this.updateRanking = function(ranking) {
    return apiService.put('/product-rankings/{id}', {
      pathParams: {
        id: ranking.id
      },
      body: ranking
    });
  };
}

module.exports = ProductRankingsService;
