var _ = require('lodash-core');

// @ngInject
function AdminRelatedBrandController($scope, $state, $stateParams, formsService,
  brandService, relatedBrandService) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.reload = function() {

    $scope.name = $stateParams.name;
    $scope.choices = [{ id: 1 }];
    $scope.selectedBrand = {};

    var brandsServicePromise = brandService.getAll();
    $scope.brands = brandsServicePromise.lazyArray();

    var promise = relatedBrandService.getById($stateParams.id);
    $scope.relatedBrandData = promise.lazyObject();
    promise.then(function() {
      var relatedBrandData = $scope.relatedBrandData;

      var newItemNo = 1;
      $scope.choices = _.map(relatedBrandData.relatedBrands, function(rb) {
        return {
          brandId: rb,
          id: newItemNo++
        };
      });
    });
  };

  $scope.addNewChoice = function() {
    var newItemNo = $scope.choices.length + 1;
    $scope.choices.push({ 'id': newItemNo });
  };

  $scope.removeChoice = function() {
    var lastItem = $scope.choices.length - 1;
    $scope.choices.splice(lastItem);
  };

  $scope.reload();

  $scope.save = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }

    var res = getRelatedBrandsInputs();

    var relatedBrandData = _.cloneDeep($scope.relatedBrandData);
    relatedBrandData.relatedBrands = res;

    formPromise.handle(relatedBrandService.save(relatedBrandData).then(function() {
      $state.go('admin.related-brands');
    }));
  };

  function getRelatedBrandsInputs() {
    var res = [];
    for (var i = 1; i <= $scope.choices.length; i++) {
      res.push(angular.element('#relatedBrand-' + i + '_value').val());
    }

    return res;
  }
}

module.exports = AdminRelatedBrandController;
