var _ = require('lodash-core');

// @ngInject
function AdminPromosController($scope, $state, promoService, fulfillerService) {

  $scope.promos = promoService.get({
    includeInactive: true,
    includeSecret: true
  }).lazyArray();
  $scope.filteredPromos = $scope.promos;

  fulfillerService.getAll()
    .then(function(fulfillers) {
      $scope.fulfillers = fulfillers;
      $scope.fulfillers.splice(0, 0, { id: 'orchard-mile', name: 'Orchard Mile' }); //Add filter by THE MILE's promo
    });

  $scope.applyFilters = function() {
    $scope.filteredPromos = _.filter($scope.promos, function(promo) {
      if ($scope.activeOnly && !$scope.isActive(promo)) {
        return false;
      }
      if ($scope.onlyFromfulfiller === 'orchard-mile') {
        return !promo.fulfillerDiscount;
      }
      if (!$scope.onlyFromfulfiller ||
        promo.brandId === $scope.onlyFromfulfiller ||
        (promo.overItems && (
          (promo.overItems.fromBrands && promo.overItems.fromBrands.indexOf($scope.onlyFromfulfiller) >= 0) ||
          (promo.overItems.fromFulfillers && promo.overItems.fromFulfillers.indexOf($scope.onlyFromfulfiller) >= 0))
        )
      ) {
        return true;
      }
      return false;
    });
  };

  $scope.onFulfillerFilterChange = function() {
    $scope.applyFilters();
  };

  $scope.toggleActiveOnly = function() {
    $scope.applyFilters();
  };

  $scope.create = function() {
    $state.go('admin.promo', { id: 'create' });
  };

  $scope.delete = function(id) {
    _.remove($scope.promos, function(each) {
      return each.id === id;
    });

    $scope.toggleActiveOnly();
    promoService.deleteById(id);
  };

  $scope.isExpired = function(promo) {
    if (!promo.expiresAt) {
      return false;
    }
    return new Date(promo.expiresAt) < new Date();
  };

  $scope.hasntStarted = function(promo) {
    if (!promo.beginsAt) {
      return false;
    }
    return new Date(promo.beginsAt) > new Date();
  };

  $scope.isActive = function(promo) {
    return !($scope.isExpired(promo) || $scope.hasntStarted(promo));
  };

  $scope.parseExpiration = function(promo) {
    return new Date(promo.expiresAt);
  };

}

module.exports = AdminPromosController;
