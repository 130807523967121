var _ = require('lodash-core');

// @ngInject
function ContentPageListDirective($parse, contentPageService, viewportService, staticAssetService, $rootScope) {
  var linker = function($scope) {

    $scope.featureFlags = $scope.$parent.featureFlags;

    function loadItems() {
      $scope.contentPages = contentPageService.search({
        filters: parseFilters($scope.filters),
        limit: $scope.limit,
      });
    }

    function parseFilters(value) {
      if (!value) {
        return null;
      }
      if (typeof value === 'string') {
        value = value.split(/ *, */g);
      }
      if (Array.isArray(value) && value.length > 0) {
        value = value.filter(function(s) {
          return /[\w\d]/i.test(s);
        });
        return value;
      }
      return null;
    }
    $scope.featuredOccasion = [];
    $scope.$watch('contentPages', function(newVal) {
      if (newVal && newVal.length > 1) {
        $scope.featuredOccasion = $scope.contentPages[0];
      }
    });
    $scope.setFeatured = function(occasion) {
      $scope.featuredOccasion = occasion;
    };

    $scope.$watch('filters', loadItems);
    $scope.$watch('limit', loadItems);

    $scope.getPageUrl = function(page) {
      return '/' + (page && page.name);
    };

    $scope.getPageClasses = function(page) {
      if (!page) {
        return '';
      }
      if (page.tile && page.tile.classes) {
        return page.tile.classes;
      }
      return page.classes || '';
    };

    $scope.getPageLinkText = function(page) {
      return (page && page.linkText) || 'Read More';
    };
    $scope.getOccasionLinkText = function(page) {
      return (page && page.linkText) || page.title;
    };

    $scope.getPageImageUrl = function(page) {
      if (!page) {
        return;
      }
      var mobile = viewportService.is('<md');
      return staticAssetService.url('/content/images/tiles/' + page.image + (mobile ? '-mobile' : '') +
        ($rootScope.isWEBPCapable ? '.webp' : '.jpg'));
    };
    $scope.getOccassionImageUrl = function(page) {
      if (!page) {
        return;
      }
      return staticAssetService.url('/content/images/' + page.image + '.jpg');
    };

    $scope.getImageContainerClasses = function(page) {
      if (!page) {
        return '';
      }
      var isMobile = viewportService.is('<md');
      var classes = $scope.getPageClasses(page).split(' ');
      if (isMobile) {
        var mobileClass = _.find(classes, function(className) {
          return className.indexOf('tile-mobile') === 0;
        });
        return mobileClass || 'tile-mobile-one-across-tall';
      } else {
        return classes[0] || 'tile-one-across';
      }
    };

  };

  return {
    link: linker,
    templateUrl: '/views/partials/content-page-list.html',
    restrict: 'E',
    scope: {
      filters: '=',
      limit: '=',
      listcontainerclass: '='
    },
    transclude: true
  };
}

module.exports = ContentPageListDirective;
