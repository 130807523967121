// @ngInject
function LogoDirective(config, $parse) {

  var linker = function($scope, elem, attrs) {
    $scope.logo = config.alternativeEnvironmentName ? '/img/logo-alternative.svg' : '/img/logo.svg';
    $scope.slogan = attrs.slogan ? $parse(attrs.slogan)($scope) : false;
  };

  return {
    link: linker,
    templateUrl: '/views/partials/logo.html',
    restrict: 'E'
  };
}

module.exports = LogoDirective;
