var _ = require('lodash-core');
var restrictionHelper = require('shared-business-rules').internationalShipping;

/*jshint maxparams: 28 */
/*jshint camelcase: false */

// @ngInject
function ProductDetailController($scope, $rootScope, $location, $timeout, $uibModal, $state, $stateParams, config,
                                 apiService, productService, errorService, cartService, metadataService, promoService,
                                 userService, showService, formatService, formsService, paymentService, klaviyoService,
                                 platformService, shopModalService, sessionStorageService, pinterestService,
                                 productReviewService, fulfillerService, algoliaInsightsService, attributionService) {

  var variantSelector;
  var relatedProductsLoaded = false;
  $scope.brandVisible = true;
  $scope.brandAvailable = true;
  $scope.isLowOfStock = false;
  $scope.stockAlert = false;
  $scope.availableAlert = false;
  $scope.forms = {};
  $scope.contactData = {};
  $scope.review = {};
  $scope.isBot = platformService.isBot();
  var messagingElement;
  $scope.isMobile = platformService.isMobile;

  $scope.showsTrendingIds = config.featureFlags.theMileInfo.trendingShows;


  var loadRelatedProducts = function() {
    window.onscroll = function() {
      if (!relatedProductsLoaded) {
        relatedProductsLoaded = true;
        $scope.productsFromSameBrand = productService.getProductsFromSameBrand($scope.product, 4).lazyArray();
        $scope.productsToWearWith = [];
        if ($scope.canShowWearItWith && config.featureFlags.wearItWith.enabled) {
          $scope.canShowWearItWith = productService.getProductsToWearWith($scope.product, 4).lazyArray();
        }
        if (config.featureFlags.mayWeSuggest.enabled) {
          $scope.productsMayWeSuggest = [];
          productService.getProductsMayWeSuggest($scope.product, 4).then(function(r) {
            $scope.productsMayWeSuggest = r;
          });
          setTimeout(function() {
            try {
              if ($scope.productsMayWeSuggest.length === 0) {
                throw new Error('no products for may we suggest for: ' + $scope.product.id);
              }
            } catch (e) {
              console.log(e);
            }
          }, 100);
        }
      }
    };
    if ($rootScope.featureFlags.shopRunner.enabled && window.sr_updateMessages) {
      var checkShopRunner = setInterval(function() {
        /*jshint camelcase: false */
        if (window.sr_updateMessages) {
          window.sr_updateMessages();
          clearInterval(checkShopRunner);
        }
      }, 100);
    }
  };

  var capitalize = function(text) {
    var arr = text.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  };

  if ($state.current.name === 'root.gift-card') {
    $scope.styleId = 'om-gift-card';
  } else {
    $scope.styleId = $scope.quickViewStyleId || $stateParams.id;
  }

  $scope.isLoggedIn = userService.isLoggedIn;

  $scope.setMetadata = function() {
    metadataService.setProduct($scope.product, $scope.style, $scope.variant);
    $rootScope.setCanonicalUrl({whitelist: ['color']});

    var toTitleCase = function(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    var breadcrumbs = [];
    if (shopModalService.isOnWidget()) {
      if ($rootScope.iframeUrl) {
        //getting the publisher base url and the related plp information
        var sopPlpId = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img.exec($rootScope.iframeUrl)[1];
        var sopPlp = _.find(window.orchardMileContent.shopOnModalPlps.plps, {id: sopPlpId});

        if (sopPlp) {
          var sopPlpTemplate = 'shop-on-modal-plps/' + sopPlp.template;
          var sopPlpName = sopPlp.name;
          breadcrumbs.push({
            name: toTitleCase(sopPlpName),
            link: $state.href('root.pages', {name: sopPlpTemplate, 'plp_id': sopPlpId})
          });
        }
      }
    } else {
      if ($scope.brandVisible) {
        if ($scope.product.brand.store) {
          breadcrumbs.push({
            name: toTitleCase($scope.product.brand.store.replace(/\-/g, ' ')),
            link: $state.href('root.brand-products', {brand: $scope.product.brand.store})
          });

          breadcrumbs.push({
            name: $scope.product.brand.name,
            link: $state.href('root.brand-products', {brand: $scope.product.brand.store}) +
              '?designers=' + $scope.product.brand.id
          });

        } else {
          breadcrumbs.push({
            name: $scope.product.brand.name,
            link: $state.href('root.brand-products', {brand: $scope.product.brand.id})
          });
        }
      }
      var categoryNames = productService.getProductCategories($scope.product);
      var categoryKeys = productService.getProductCategoryKeys(categoryNames);
      _.forEach(categoryNames, function(node, index) {
        var link;
        if (!$scope.brandVisible) {
          link = $state.href('root.shop.category' + (index + 1), {
            category1: categoryKeys[0],
            category2: categoryKeys[1],
            category3: categoryKeys[2]
          });
        } else {
          if ($scope.product.brand.store) {
            link = $state.href('root.brand-products.category' + (index + 1), {
                brand: $scope.product.brand.store,
                category1: categoryKeys[0],
                category2: categoryKeys[1],
                category3: categoryKeys[2]
              }) +
              '?designers=' + $scope.product.brand.id;
          } else {
            link = $state.href('root.brand-products.category' + (index + 1), {
              brand: $scope.product.brand.id,
              category1: categoryKeys[0],
              category2: categoryKeys[1],
              category3: categoryKeys[2]
            });
          }
        }
        breadcrumbs.push({
          name: node,
          link: link
        });
      });
    }
    breadcrumbs.push({name: $scope.style.name || $scope.product.name});
    $scope.setBreadcrumbPath(breadcrumbs);
  };

  $scope.inteliStyleRecommendation = function() {
    if (config.featureFlags.inteliStyleWidget.enabled) {
      if ($scope.product && $scope.product.tags && $scope.product.tags.length) {
        return _.some($scope.product.tags, function(tag) {
          return ['clothing', 'bags', 'shoes', 'accessories'].includes(tag);
        });
      }
    } else {
      return false;
    }
  };

  $scope.inteliStyleWidgetLoaded = function() {
    return !!(config.featureFlags.inteliStyleWidget.enabled &&
      (document.getElementsByClassName('garment-grid') &&
        document.getElementsByClassName('garment-grid').length));
  };

  $scope.openNewReview = function() {
    $stateParams.review = 'new';
    $scope.openReviewProduct();
  };

  function getBrandsToFilter() {
    var rule = _.find($rootScope.featureFlags.internationalBlockedBrands.rules, function (rule) {
      return rule.countries.includes($rootScope.countrySelected());
    });
    if (rule && $rootScope.featureFlags.internationalBlockedBrands.enabled) {
      return rule.brands;
    } else {
      return [];
    }
  }

  $scope.loadProduct = function(refreshData) {
    $scope.addToCartJustFailed = false;
    $scope.subscribeJustFailed = false;
    $scope.quantity = 1;
    $scope.loading = true;

    if (refreshData) {
      productService.getByStyleId.cache.clear();
    } else {
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
      scrollTo(0, 0);
    }
    $scope.hasSize = false;
    $scope.showPriceRange = false;
    // load the product using the style id in the url
    productService.getByStyleId($scope.styleId).then(function(product) {
      $scope.openReviewProduct();
      $scope.hasSize = false;
      $scope.showPriceRange = false;

      // hide not visible styles
      product.styles = _.filter(product.styles, function(style) {
        return style.visible !== false;
      });

      // hide not visible variants
      _.forEach(product.styles, function(style) {
        style.variants = _.filter(style.variants, function(variant) {
          return variant.visible !== false;
        });
      });

      var stylesOutOfStock = _.filter(product.styles, function(style) {
        return !style.variants || (_.filter(style.variants, {stock: 0}).length === style.variants.length);
      });
      var productOutOfStock = product.styles.length === stylesOutOfStock.length;

      var categoryNames = productService.getProductCategories(product);
      if ($rootScope.countrySelected() !== 'us' && getBrandsToFilter().includes(product.brand.id)) {
        errorService.notAllowedForYourCountry(productService.getProductCategoryKeys(categoryNames));
        return;
      }
      if (
        (!product || !product.visible || productOutOfStock || product.brand.unavailable) &&
        !$stateParams.forceview &&
        !$rootScope.isShow() &&
        !platformService.isBot() &&
        !$rootScope.comingFromOosPlp
      ) {
        var categoryKeys = productService.getProductCategoryKeys(categoryNames);
        errorService.notFound(undefined, categoryKeys, $scope.styleId, $scope.getComingFrom());
        return;
      }
      if ($rootScope.comingFromOosPlp && !$stateParams.forceview && productOutOfStock) {
        delete $rootScope.comingFromOosPlp;
        window.history.back();
      }
      $scope.product = product;
      $scope.brandVisible = product.brand.visible;
      $scope.brandAvailable = !product.brand.unavailable;
      $scope.loading = false;
      $scope.loadBuyNowPayLaterWidget();
      setTimeout(function() {
        $scope.loadProductReviews();
      }, 50);

      setTimeout(function() {
        $scope.$apply();
      }, 50);

      $rootScope.productImagesSelection = {
        id: product.id,
        index: 0
      };

      $scope.loadBrandAndPartnerInfo();

      variantSelector = new VariantsSelector($scope.product, ['size', 'color'], $scope);

      if (variantSelector.options.size) {
        variantSelector.options.size.items = _.sortBy(variantSelector.options.size.items, function(item) {
          return productService.sizeNumericValue(item.value);
        });
        if (variantSelector.options.size.items.length === 1) {
          variantSelector.options.size.selectedItem = variantSelector.options.size.items[0];
        }
      }
      if (
        !variantSelector.options.has('size') &&
        !categoryNames.includes('necklaces') &&
        !categoryNames.includes('watches')
      ) {
        $scope.sizeGuide = {data: ''};
      } else {
        $scope.hasSize = true;
      }
      $('.thumbnails').on('init reInit', function() {
        //show only for user admin
        if (userService.getUser().isAdmin) {
          $('.slick-list').css('overflow-y', 'auto').css('width', '100px').css('left', '0px');
        }
      });
      refreshThumbnails();

      $scope.options = variantSelector.options;

      if ($stateParams.shopnow) {
        $scope.shopNow();
      } else {
        loadRelatedProducts();
      }
      $scope.refreshVariant();
      $scope.productName = capitalize($scope.variant.name || $scope.style.name || $scope.product.name);
      if (product.brand.id === 'emporio-armani') {
        $scope.productName = $scope.productName.replace('Myea', 'MyEA');
      }
      if (product.brand.id === 'jane-win') {
        $scope.productName = $scope.productName.replace('Jw', 'JW');
      }
      if (['dolce-and-gabbana', 'dolce-and-gabbana-kids'].includes(product.brand.id)) {
        $scope.productName = $scope.productName.replace('Dg', 'DG');
      }
      if (product && product.hasOwnProperty('translated') && product.translated === false && !$scope.translated) {
        productService.translateProduct($scope.product, $scope.styleId).then(function(translatedProduct) {
          $scope.product = translatedProduct;
          product = translatedProduct;
          _.forEach($scope.product.styles, function(translatedStyle) {
            if (translatedStyle.id === $scope.style.id) {
              $scope.style.description = translatedStyle.description;
            }
            _.forEach($scope.product.variants, function(translatedVariant) {
              if (translatedVariant.styleId === translatedStyle.id) {
                translatedVariant.description = translatedStyle.description;
                $scope.variant.description = translatedStyle.description;
              }
            });
          });
          $scope.translated = true;
        });
      }
      pinterestService.setProductTag($scope.product, $scope.style, $scope.variant, $scope.image);
      $scope.algolia = algoliaInsightsService.getProductData($scope.product.id, $scope.style.id);
      setTimeout(function () {
        algoliaInsightsService.sendEvent('viewed-product');
      }, 1000);
    }).catch(errorService.pageError);
  };

  $scope.loadBrandAndPartnerInfo = function() {
    $scope.fulfiller = $scope.product.partner;
    if (!$scope.product.partner) {
      throw new Error('partner is not defined for product ' + $scope.product.id);
    }
    fulfillerService.getById($scope.product.partner.id).then(function(info) {
      $scope.product.partner = info;
      $scope.product.partner.shippingMethods = info.shippingMethods;

      if ($scope.product.partner.shippingAndReturns) {
        $scope.fulfiller = _.assign($scope.product.partner.shippingAndReturns, $scope.fulfiller);
        $scope.shippingAndReturns = productService.getShippingAndReturns(false).lazyObject();
      } else {
        $scope.shippingAndReturns = productService.getShippingAndReturns(true).lazyObject();
      }
    });
    $scope.fulfiller.daysToReturn = $scope.fulfiller.daysToReturn || 14;

    $scope.sizeGuide = productService.getSizeGuide($scope.product).lazyObject();

    delete $scope.promos;
  };

  $scope.$watch('product.partner', updateEta);

  $scope.$watch('options.color.selectedItem', function (newColor, oldColor) {
    if (newColor && oldColor) {
      $scope.relatedShowsRefresh = true;
      setTimeout(function () {
        $scope.relatedShowsRefresh = false;
      }, 200);
    }
  });

  function updateProductRestriction() {
    $scope.isItemRestricted = restrictionHelper.isItemRestricted($scope.product, $scope.style,
      $scope.variant, 'US');
  }

  function formatEtaDate(date) {
    if (!date) {
      return '';
    }
    var str = formatService.formatDateShort(date);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (date.getMonth() === tomorrow.getMonth() &&
      date.getDate() === tomorrow.getDate()) {
      str = 'tomorrow, ' + str;
    }
    return str;
  }

  function refreshThumbnails() {
    setTimeout(function() {
      var thumbSlider = $('.thumbnails');
      if (thumbSlider && thumbSlider[0] && thumbSlider[0].slick) {
        thumbSlider[0].slick.$slides = null;
      }
    }, 200);
  }

  function updateEta() {
    delete $scope.eta;
    if (!$scope.product || !$scope.product.partner || !$scope.product.partner.shippingMethods) {
      return;
    }
    var info = $scope.product.partner;
    var method;
    info.shippingMethods.forEach(function(shippingMethod) {
      if (!shippingMethod.daysToDeliverMax) {
        return;
      }
      if (!method || method.daysToDeliverMax > shippingMethod.daysToDeliverMax) {
        method = shippingMethod;
      }
    });
    if (!method) {
      return;
    }

    $scope.eta = {
      expedited: info.shippingMethods.length > 1,
      method: method,
      from: formatEtaDate(method.dateFrom),
      to: formatEtaDate(method.dateTo)
    };
  }

  $scope.getBannerMessage = function() {
    var isRestrictedLanguage = false;
    var promoRestriction = config.featureFlags.myMilePromoLanguageRestriction;
    if (promoRestriction && promoRestriction.enabled) {
      var language = (navigator.language || navigator.userLanguage).substring(0, 2);
      isRestrictedLanguage = promoRestriction.languages.indexOf(language) >= 0;
    }

    var bannerPromo = $scope.promos && $scope.promos.filter(function(promo) {
      if (isRestrictedLanguage && promo.description.match(promoRestriction.regex)) {
        return false;
      }
      return !!promo.htmlMessage && (!promo.overItems ||
        !(promo.overItems.fromBrands || promo.overItems.fromFulfillers));
    })[0];

    if (bannerPromo && bannerPromo.signedUpUser && !userService.isLoggedIn()) {
      return 'Log Into Your Account for ' + (bannerPromo && bannerPromo.htmlMessage) || '';
    }

    return (bannerPromo && bannerPromo.htmlMessage) || '';
  };

  $scope.getBrandDiscountMessage = function() {
    var bannerPromo = $scope.promos && $scope.promos.filter(function(promo) {
      if (promo && promo.signedUpUser && !userService.isLoggedIn()) {
        return false;
      }

      return !!promo.htmlMessage && promo.overItems &&
        (promo.overItems.fromBrands || promo.overItems.fromFulfillers);
    })[0];

    return (bannerPromo && bannerPromo.htmlMessage) || '';
  };

  $scope.refreshVariant = function() {
    $scope.showPriceRange = false;
    if (!variantSelector.containStyle($scope.styleId)) {
      $scope.loadProduct();
      return;
    }
    variantSelector.setStyleId($scope.styleId);
    $scope.style = variantSelector.getStyle();

    var amountOfVariants = $scope.style.variants.length;
    var outOfStockVariants = $scope.style.variants && $scope.style.variants.filter(function(v) {
      return v.stock === 0;
    }).length;

    $scope.styleOutOfStock = amountOfVariants === outOfStockVariants;

    function isSomeVariantHasLowInStock(variants) {
      return _.some(variants, function (variant) {
        return variant.stock > 0 && variant.stock <= 5;
      }) && $scope.options && $scope.options.getUnselected().length !== 0;
    }

    $scope.isLowOfStock = (amountOfVariants > outOfStockVariants && (
        (amountOfVariants > 2 && outOfStockVariants >= 2) ||
        (amountOfVariants <= 2 && outOfStockVariants > 0)) || isSomeVariantHasLowInStock($scope.style.variants)
    );

    if ($scope.product && $scope.product.brand && $scope.product.brand.id === 'm-gemi') {
      $scope.isLowOfStock = false;
    }
    refreshThumbnails();
    if (amountOfVariants >= 1) {
      if ($scope.hasSize && typeof ($stateParams.size) === 'undefined') {
        var variants = _.filter($scope.style.variants, function(v) {
          return v.stock !== 0;
        });
        if (variants.length >= 1) {
          $scope.minPrice = _.min(variants, _.property('price')).price;
          $scope.maxPrice = _.max(variants, _.property('price')).price;
          if ($scope.minPrice !== $scope.maxPrice) {
            $scope.showPriceRange = true;
          }
        }
      }
    }

    variantSelector.setKey($stateParams);
    $scope.variant = variantSelector.getVariant();

    if ($scope.variant && $scope.variant.stock && $scope.variant.stock > 0 && $scope.variant.stock <= 5 &&
      $scope.options && $scope.options.getUnselected().length === 0) {
      $scope.stockLeftAlert = $scope.variant.stock;
    } else {
      $scope.stockLeftAlert = undefined;
    }

    $scope.stockAlert = false;
    $scope.availableAlert = false;
    if (!$scope.variant) {
      if ($scope.style) {
        $scope.variant = $scope.style.variants[0];
      } else {
        // clean url
        _.forEach(variantSelector.options.names, function(name) {
          $stateParams[name] = undefined;
        });
        $scope.style = variantSelector.getDefaultStyle();
        $stateParams.id = $scope.style.id;
        $scope.styleId = $scope.style.id;
        $stateParams.productName = $scope.style.slug;
        $state.go($state.current.name, $stateParams, {location: 'replace'});
        return;
      }
    }
    $scope.productName = capitalize($scope.variant.name || $scope.style.name || $scope.product.name);

    klaviyoService.trackViewedProduct($scope.product, $scope.variant, $scope.style);
    productService.productViewed({ product: $scope.product, style: $scope.style, variant: $scope.variant });

    $scope.images = $scope.variant.getParentStyle().images;
    $scope.image = $scope.images[0];
    updateProductRestriction();
    $scope.setMetadata();
    promoService.getPublicForProduct($scope).then(function(promos) {
      $scope.promos = promos;
    });
  };

  $scope.getAvailability = function() {
    if (!$scope.style) {
      return true;
    }
    if (!$scope.product || !$scope.brandVisible) {
      return 'http://schema.org/Discontinued';
    }

    var variantInStock;
    var variantSelected = $scope.options && $scope.options.getUnselected().length === 0;
    if (variantSelected) {
      variantInStock = variantSelector && variantSelector.getOptionsOutOfStock().length === 0;
    } else {
      variantInStock = _.some($scope.style.variants, function(variant) {
        return variant.stock !== 0;
      });
    }

    return variantInStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock';
  };

  $scope.onQuantityBlur = function() {
    if (isNaN($scope.quantity) || $scope.quantity < 1) {
      $scope.quantity = 1;
    }
    if ($scope.quantity > 99) {
      $scope.quantity = 99;
    }
  };

  $scope.getImageUrl = function(image, options) {
    options = options || {};
    options.availableSizes = image.availableSizes;
    return productService.getImageUrl(image.path, options);
  };

  $scope.getProductUrl = function() {
    return $location.absUrl().split('?')[0];
  };

  $scope.showImage = function(image) {
    $scope.image = image;
  };

  $scope.toggleDetails = function() {
    $scope.showDetails = !$scope.showDetails;
  };

  $scope.openSizeGuide = function() {
    var instance = $uibModal.open({
      templateUrl: 'size-guide-modal.html',
      windowClass: 'size-guide-modal',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    $scope.closeSizeGuide = function() {
      instance.dismiss();
    };
  };

  $scope.isSavedForLater = function() {
    return cartService.variantIsSavedForLater($scope.variant);
  };

  $scope.promotionBannerEnabled = function() {
    if ($scope.product && config.featureFlags.theMilePromotionBanner.enabled) {
      return !config.featureFlags.theMilePromotionBanner.blockedBrands.includes($scope.product.brand.id);
    } else {
      return false;
    }
  };

  $scope.openReviewProduct = function() {
    if ($stateParams.review) {
      $scope.reviewId = $stateParams.review;
      $scope.productReviewModal = $uibModal.open({
        templateUrl: '/views/partials/modals/product-review.html',
        windowClass: 'product-review-modal',
        controller: 'ProductReviewController',
        scope: $scope
      });
    }
  };

  $scope.cantAddToCartReason = function() {
    if (!variantSelector) {
      return {};
    }

    if (!$scope.brandVisible) {
      return {
        id: 'cant-add-because-brand-not-visible',
        message: userService.getUser().isAdmin ? 'product is unavailable' : 'Selected product is sold out'
      };
    }

    if (!$scope.brandAvailable) {
      return {
        id: 'cant-add-because-brand-not-available',
        message: 'this brand is not available'
      };
    }

    if ($scope.isItemRestricted) {
      if (restrictionHelper.isRestrictedByBrandOrPartner($scope.product)) {
        return {
          id: 'cant-add-because-no-international-shipping',
          message: 'Unfortunately this brand cannot be shipped to your country'
        };
      } else {
        return {
          id: 'cant-add-because-no-international-shipping',
          message: 'Unfortunately this item cannot be shipped to your country'
        };
      }
    }

    var unselected = $scope.options.getUnselected()[0];
    if (unselected) {
      return {
        id: 'cant-add-because-' + unselected + '-is-required',
        message: 'please select a ' + unselected
      };
    }

    var outOfStock = variantSelector.getOptionsOutOfStock();
    if (outOfStock.length > 0) {
      //Don't show LOW IN STOCK if the variant is sold out.
      $scope.isLowOfStock = false;
      return {
        id: 'cant-add-because-variant-out-of-stock',
        message: 'Selected ' + outOfStock.join('/') + ' is sold out'
      };
    }

    if (cartService.isStockAvailable($scope.variant)) {
      return {
        id: 'cant-add-because-variant-is-stock-available',
        message: 'Unfortunately you can\'t add more units of this product on this order'
      };
    }

    if (cartService.isMaxQty($scope.variant)) {
      return {
        id: 'cant-add-because-variant-is-max-qty',
        message: 'Unfortunately you can\'t add more units of this product on this order'
      };
    }

    if (cartService.isMaxBrandQty($scope.variant, $scope.product.brand.id)) {
      return {
        id: 'cant-add-because-variant-is-max-qty',
        message: 'Unfortunately you can\'t add more than ' +
          cartService.getBrandMaxQty($scope.product.brand.id) +
          ' products from ' + $scope.fulfiller.name + ' on this order'
      };
    }
    return {};
  };

  $scope.canShowWearItWith = function() {
    if ($scope.product && $scope.product.tags) {

      if ($scope.product.tags.indexOf('home') !== -1 ||
        $scope.product.tags.indexOf('beauty') !== -1) {
        return false;
      }
      if ($scope.product.tags.indexOf('accessories') !== -1) {
        if ($scope.product.tags.indexOf('watches') !== -1 ||
          $scope.product.tags.indexOf('travel') !== -1 ||
          $scope.product.tags.indexOf('keychains') !== -1 ||
          $scope.product.tags.indexOf('tech-accessories') !== -1 ||
          $scope.product.tags.indexOf('small-leather-goods') !== -1 ||
          $scope.product.tags.indexOf('small-accessories') !== -1 ||
          $scope.product.tags.indexOf('hair-accessories') !== -1
        ) {
          return false;
        }
      }
    }
    return true;
  };

  $scope.subscribeStock = function() {
    $scope.subscribeJustFailed = false;
    if ($scope.options.getUnselected()[0]) {
      $scope.subscribeJustFailed = true;
      $timeout(function() {
        $scope.subscribeJustFailed = false;
      }, 4000);
      return false;
    }
    if (userService.isLoggedIn()) {
      userService.notifyMeStock($scope.styleId, $scope.variant.key);
      $scope.stockAlert = true;
    } else {
      $scope.modal.show('back-in-stock', {
        styleId: $scope.styleId,
        variantKey: $scope.variant.key,
        alert: 'back-in-stock',
        trigger: 'product-detail'
      });
    }
  };

  $scope.subscribeAvailability = function() {
    $scope.subscribeJustFailed = false;
    if ($scope.options.getUnselected()[0]) {
      $scope.subscribeJustFailed = true;
      $timeout(function() {
        $scope.subscribeJustFailed = false;
      }, 2000);
      return false;
    }
    if (userService.isLoggedIn()) {
      userService.notifyMeAvailable($scope.product.brand.id, $scope.styleId, $scope.variant.key);
      $scope.availableAlert = true;
    } else {
      $scope.modal.show('back-in-stock', {
        brandId: $scope.product.brand.id,
        styleId: $scope.styleId,
        variantKey: $scope.variant.key,
        alert: 'brand-is-back',
        trigger: 'product-detail'
      });
    }
  };

  $scope.addToCartGiftCard = function() {
    var formPromise = $scope.formPromise = formsService.promiseHandler();
    if (!formPromise.validate($scope.forms.giftCard)) {
      return;
    }
    if (!$scope.cantAddToCartReason().id &&
      cartService.canAddToCart($scope.variant)) {
      var metadata = angular.copy($scope.contactData);
      delete metadata.recipientReEmail;
      $scope.addToCart('gift-card', metadata);
    }
  };

  $scope.canAddToCart = function() {
    return !$scope.cantAddToCartReason().id &&
      cartService.canAddToCart($scope.variant);
  };

  $scope.openProductSource = function() {
    var redirectWindow = window.open('', '_blank');
    productService.getSourceUrl($scope.product, $scope.variant)
      .then(function(sourceUrl) {
        redirectWindow.location = sourceUrl;
      });
  };

  $scope.isAdminHasRole = function(role) {
    return userService.isLoggedIn() && userService.getUser().isAdmin &&
      userService.getUser().roles.includes(role);
  };

  $scope.runTagging = function () {
    if (!$scope.executingTagging) {
      apiService.post('/taggings/execute', {
        urlParams: {
          styleId: $scope.styleId,
          brand: $scope.product.brand.id
        }
      }).then(function () {
        $scope.executingTagging = true;
        window.alert('Please wait five minutes and refresh the page waiting for tagging execution,' +
          ' note that is not possible to track tagging status, running many times may cause additional cost');
      });
    }
  };

  $scope.scrape = function (replaceImages, entireBrand) {
    var params = {
      replaceImages: replaceImages
    };
    if (entireBrand) {
      params.brand = $scope.product.brand.id;
    } else {
      params.style = $scope.styleId;
    }
    apiService.post('/partner-providers/' + $scope.product.partner.provider + '/scraping', {
      urlParams: params
    }).then(function (response) {
      console.log(response);
      window.alert('Scraping process is executing please wait one minute and refresh the page cleaning cache');
    });
  };

  $scope.loadProductReviews = function() {
    if (config.featureFlags.productReviews.enabled) {
      productReviewService.getByStyleId($scope.styleId, $scope.product.brand.id).then(function(reviews) {
        if (reviews && reviews.length) {
          reviews = _.filter(reviews, function (review) {
            return review.visible !== false;
          });
          $scope.productReviews = {
            list: reviews
          };
          $scope.productReviews.rating = _.reduce(reviews, function(tr, review) {
            return review.rating + tr;
          }, 0);

          $scope.productReviews.rating = $scope.productReviews.rating/reviews.length;
          $scope.productReviews.rating = $scope.productReviews.rating.toFixed(1);
          $scope.productReviews.count = reviews.length;
          // reviews.forEach(function(review) {
          //   $scope.style.variants.forEach(function(variant) {
          //     if(review.hashId === variant.hash) {
          //       review.variant = variant.key;
          //     }
          //   });
          // });
          // console.log(reviews);
        }
      });
    }
  };

  $scope.loadBuyNowPayLaterWidget = function() {
    if (config.featureFlags.stripeWidget.enabled) {
      paymentService.loadProvider(!window.stripeElements).then(function() {
        var elements = window.stripeElements.elements({ appearance: {
            theme: 'flat'
          }
        });
        $rootScope.userIdStripe = userService.getUser().id || sessionStorageService.getSessionId();
        var options = {
          amount: $scope.variant.price,
          currency: 'USD',
          paymentMethods: ['klarna', 'afterpay_clearpay', 'affirm'],
          countryCode: 'US',
          metaData: {
            userSessionId: $rootScope.userIdStripe
          }
        };
        messagingElement =
          elements.create('paymentMethodMessaging', options);
        messagingElement.mount('#stripe-after-pay-widget');
      });
    }
  };

  $scope.$onEmitter(cartService.events, 'add_to_cart', function(params) {
    if (params.source === 'gift-card') {
      $scope.contactData = {};
      $scope.forms.giftCard.$setPristine(true);
      $scope.forms.giftCard.$setUntouched(true);
    }
  });

  $scope.shopNow = function() {
    if ($rootScope.shouldCleanCart()) {
      cartService.cleanCart(true, true);
    }
    var added = false;
    setTimeout(function() {
      added = $scope.addToCart('shop-now');
    }, 0);
    setTimeout(function() {
      if (added) {
        if (!shopModalService.bagFlowFeature()) {
          var current = $state.current;
          if (current && current.name.indexOf('root.checkout') !== -1) {
            return true;
          }
          if (!userService.isLoggedIn()) {
            userService.guestCheckout('').then(function() {
              $state.go('root.checkout.shipping');
            });
          } else {
            $state.go('root.checkout.shipping');
          }
        } else {
          shopModalService.productAddedPDP();
          $state.go('root.cart-page');
        }
      }
    }, 200);
  };

  $scope.addToCart = function(source, metadata, forLater) {
    $scope.addToCartJustFailed = false;
    if (!$scope.canAddToCart()) {
      // temporal flag to show an auto-destroying visual feedback
      $scope.addToCartJustFailed = false;
      if ($scope.addToCartJustFailedOnTimeout) {
        $timeout.cancel($scope.addToCartJustFailedOnTimeout);
      }
      if ($scope.addToCartJustFailedOffTimeout) {
        $timeout.cancel($scope.addToCartJustFailedOffTimeout);
      }
      $scope.addToCartJustFailedOnTimeout = $timeout(function() {
        $scope.addToCartJustFailed = true;
        $scope.addToCartJustFailedOffTimeout = $timeout(function() {
          $scope.addToCartJustFailed = false;
        }, 4000);
      }, 20);
      return false;
    }
    source = source || 'product-detail';
    var item = {
      styleId: $scope.variant.getParentStyle().id,
      variant: $scope.variant,
      quantity: $scope.quantity,
      source: source,
      sku: $scope.variant.sku || $scope.style.sku,
      comingFrom: $scope.getComingFrom(),
      forLater: forLater
    };

    if (!forLater) {
      attributionService.calculateItemAttribution(item);
    }

    if (metadata) {
      item.metadata = metadata;
    }

    // Removing item from wishlist
    var variantsSavedForLater = cartService.getVariantsSavedForLaterByStyle(item.styleId);
    if (variantsSavedForLater.length > 0) {
      variantsSavedForLater.forEach(function(variantSavedForLater) {
        var itemToRemove = _.cloneDeep(item);
        itemToRemove.variant = variantSavedForLater;
        itemToRemove.quantity = -1;
        itemToRemove.sku = $scope.variant.sku || $scope.style.sku;
        itemToRemove.forLater = true;
        cartService.add(itemToRemove);
      });
    }
    var show = $scope.showId || $stateParams.show;
    // Adding item
    if (item.quantity !== -1) {
      cartService.add(item);
      if (show && !forLater) {
        showService.addEvent(show, 'addedToCart', {
          styleId: item.styleId,
          title: $scope.variant.getParentStyle().name,
          hash: item.variant.hash
        });
      }
      algoliaInsightsService.sendEvent('converted-product');
    }
    if (!forLater && $scope.quickViewStyleId) {
      $scope.hideModal();
    }
    if (messagingElement) {
      messagingElement.update();
    }
    // track event on hotjar
    if (window.hj) {
      window.hj('event', 'add-to-bag');
    }
    return true;
  };

  $scope.getComingFrom = function() {
    var influencer = ($scope.influencer && $scope.influencer.id) || $stateParams.influencer;
    var show = $scope.showId || $stateParams.show;
    if (influencer && show && window.isShowOpened) {
      influencer = undefined;
    }
    return $stateParams.comingfrom || (influencer && 'influencer-' + influencer) ||
      (show && 'show-' + show);
  };

  $scope.saveForLater = function(source, metadata) {
    if ($scope.isSavedForLater()) {
      $scope.quantity = -1;
    }
    source = source || 'product-detail-save-for-later';
    var added = $scope.addToCart(source, metadata, true);
    $scope.quantity = 1;
    return added;
  };

  $scope.getDescription = function() {
    if ($scope.variant || $scope.style || $scope.product) {
      var description = ($scope.variant && $scope.variant.deliveryInformation) ?
        ($scope.variant.deliveryInformation + '<br><br>') : '';
      description +=
        ($scope.variant && $scope.variant.description) ||
        ($scope.style && $scope.style.description) ||
        ($scope.product && $scope.product.description) ||
        '';
      var modelFitReg = new RegExp(/((The model is|Model is|Model wearing) .*?)(<br>|<\/li>|\.)/g, 'i')
        .exec(description);
      if (modelFitReg) {
        description = description.replace(modelFitReg[1], '<strong>' + modelFitReg[1] + '</strong>');
      }
      return (
        $scope.product.brand.additionalNotes ?
          ('<p><strong>' + $scope.product.brand.additionalNotes + '</strong></p>') :
          ''
      ) + (description || '');
    }
  };

  $scope.hideModal = function() {
    $scope.instance.close();
  };

  $scope.$watch(
    function() {
      return JSON.stringify($stateParams);
    },
    function(current, previous) {
      if (current === previous) {
        return;
      }
      $scope.styleId = $stateParams.id;
      $scope.refreshVariant();
    }
  );

  $scope.$on('cartItemsStockChanged', function() {
    // the stock of a product on the cart has changed,
    // refresh in case that affects the product being displayed (or a related one)
    $scope.loadProduct();
  });

  $scope.$on('cartItemsPriceChanged', function() {
    // the price of a product on the cart has changed,
    // refresh in case that affects the product being displayed (or a related one)
    $scope.loadProduct();
  });

  $scope.$watch(
    function() {
      if ($scope.options) {
        return $scope.options.getStringKey();
      }
    },
    function(current, previous) {
      if (current === previous) {
        return;
      }
      var style = variantSelector.getStyle();
      $scope.variant = variantSelector.getVariant();
      $state.go(
        $state.current.name,
        _.assign($stateParams, $scope.options.getKey(), {productName: style.slug, id: style.id}),
        {location: 'replace'}
      );
    });

  $scope.loadProduct();

  $scope.getLastModifiedBy = function () {
    if (!$scope.product) {
      return '';
    } else {
      if ($scope.product.lastModifiedBy === 'AI') {
        return 'AI';
      }
      if ($scope.product.lastModifiedBy) {
        return 'Human';
      }
      if ($scope.product.tags && $scope.product.tags.length) {
        return 'Scraper or GPT';
      } else {
        return 'Not Tagged';
      }
    }
  };

  // make breadcrumb visible, so it doesn't jump when all crumbs are ready
  $scope.setBreadcrumbPath([{}]);
}

// Variant Selector Classes

function VariantsSelector(product, supportedKeys, $scope) {
  var self = this;
  var variants = [];
  var options = new Options();

  // get product info
  var styles = _.map(product.styles, function(style) {
    style = _.cloneDeep(style);
    style.key = supportedKeys ? _.pick(style.key, supportedKeys) : style.key || {};
    style.variants = _.map(style.variants, function(variant) {
      variant = _.cloneDeep(variant);
      variant.key = supportedKeys ?
        _.pick(variant.key, supportedKeys) : variant.key || {};
      variant.normalizedKey = {size: (variant.normalizedKey && variant.normalizedKey.size || '') + ''};

      variant.getParentStyle = function() {
        return style;
      };

      variants.push(variant);
      _.forEach(_.keys(variant.key), function(name) {
        options.add(name, variant.key[name], variant.normalizedKey[name]);
      });
      return variant;
    });
    return style;
  });

  self.options = options;

  self.setStyleId = function(id) {
    var style = _.find(styles, {id: id});
    if (style) {
      self.options.setKey(style.key);
      self.updateOptionCaptions();
    }
  };

  self.setKey = function(key) {
    self.options.setKey(key);
    self.updateOptionCaptions();
  };

  self.getStyle = function(key) {
    if (!key) {
      key = self.options.getKey();
    }
    return _.find(styles, function(style) {
      var found = true;
      _.forEach(_.keys(style.key), function(name) {
        if (key[name] !== undefined) {
          found = found && style.key[name] === key[name];
        }
      });
      return found;
    });
  };

  self.containStyle = function(id) {
    return _.some(styles, {id: id});
  };

  self.getDefaultStyle = function() {
    return styles[0];
  };

  self.getVariant = function(key) {
    return self.getVariants(key)[0];
  };

  self.getVariants = function(key) {
    if (!key) {
      key = self.options.getKey();
    }
    var sortedResult = _.filter(variants, function(variant) {
      var found = true;
      _.forEach(_.keys(variant.key), function(name) {
        if (key[name] !== undefined) {
          found = found && variant.key[name] === key[name];
        }
      });
      return found;
    });
    sortedResult = _.sortBy(sortedResult, 'stock');
    return sortedResult;
  };

  self.isOutOfStock = function(name, value) {
    var key = self.options.getKey();
    key[name] = value;

    var variant = _.find(self.getVariants(key), function(variant) {
      return variant.stock !== 0;
    });

    return !variant;
  };

  self.getOptionsOutOfStock = function() {
    if (self.options.names.length < 1) {
      // no options to select
      return self.getVariant().stock === 0 ? ['product'] : [];
    }
    return _.filter(self.options.names, function(name) {
      var value = self.options[name].value();
      return self.isOutOfStock(name, value);
    });
  };

  self.updateOptionCaptions = function() {
    _.forEach(self.options.names, function(name) {
      _.forEach(self.options[name].items, function(item) {

        item.originalCaption = item.originalCaption || item.caption;

        if (self.isOutOfStock(name, item.value)) {
          var others = _.compact(_.map(
            _.difference(self.options.names, [name]),
            function(n) {
              return self.options[n].value();
            }
          ));

          if (others.length) {
            item.caption = item.originalCaption + ' (sold out in ' + others.join(',') + ')';
          } else {
            item.caption = item.originalCaption + ' (sold out)';
          }
        } else {

          item.caption = item.originalCaption;
        }
      });
    });
  };


  // Options Classes

  function Option(name) {
    var self = this;

    self.name = name;
    self.values = [];
    self.items = [];
    self.selectedItem = undefined;

    self.value = function(value) {
      if (arguments.length) {
        self.selectedItem = _.find(self.items, {value: value});
      }
      return self.selectedItem && self.selectedItem.value;
    };

    self.add = function(value, normal) {
      if (self.values.indexOf(value) === -1) {
        self.values.push(value);

        normal = normal ? normal + '' : '';
        if (normal && normal !== value && $scope.styleId !== 'om-gift-card') {
          normal = ' (' + normal + ')';
        } else {
          normal = '';
        }

        self.items.push({value: value, caption: value + normal});
      }
    };
  }

  function Options() {
    var self = this;
    self.names = [];

    self.add = function(name, value, normal) {
      var option = self[name];
      if (!option) {
        self[name] = option = new Option(name);
        self.names.push(name);
      }
      option.add(value, normal);
    };

    self.getKey = function() {
      var result = {};
      _.forEach(self.names, function(name) {
        var value = self[name].value();
        if (value) {
          result[name] = value;
        }
      }, self);
      return result;
    };

    self.getStringKey = function() {
      return JSON.stringify(self.getKey());
    };

    self.setKey = function(key) {
      _.forEach(self.names, function(name) {
        var val = key[name];
        if (val !== undefined && self[name].values.indexOf(val) >= 0) {
          self[name].value(val);
        }
      }, self);
    };

    self.getUnselected = function() {
      return _.filter(
        self.names,
        function(name) {
          return !self[name].value();
        },
        self
      );
    };

    self.has = function(name) {
      return !!self[name];
    };
  }

  $scope.goToSection = function(section) {
    var anchor = document.getElementById(section);
    if (anchor) {
      anchor.scrollIntoView();
    }
  };

}

module.exports = ProductDetailController;
