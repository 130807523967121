var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function PromoService(apiService, brandService, userService, config) {

  var htmlMessageTemplate = _.template('<%= promo.title || "" %>' +
    '<span class="small"><%= promo.subtitle || "" %></span>');

  this.get = function(options) {
    return promises.extend(apiService.get('/promos', {
      urlParams: {
        'include-inactive': options.includeInactive,
        'include-secret': options.includeSecret
      }
    }));
  };

  this.getPublic = promises.memoize(function() {
    return apiService.get('/promos/public').then(function(promos) {
      return brandService.getAll().then(function(brands) {
        promos.items.forEach(function(promo) {
          var brand = _.find(brands, function(brand) {
            return promo.overItems && promo.overItems.fromBrands && !brand.unavailable &&
              promo.overItems.fromBrands.indexOf(brand.brandId) >= 0;
          });
          promo.htmlMessage = promo.title ? htmlMessageTemplate({
            brand: brand,
            promo: promo
          }) : '';
        });
        return promos.items;
      });
    });
  });

  this.countUsagesByUser = function(userId) {
    return promises.extend(apiService.get('/promos/count-usages-batch/{userId}', {
      pathParams: {
        userId: userId
      }
    }));
  };

  this.getById = function(id) {
    return promises.extend(apiService.get('/promos/{id}', {
      pathParams: {
        id: id
      }
    }));
  };

  this.deleteById = function(id) {
    return promises.extend(apiService.delete('/promos/{id}/remove', {
      pathParams: {
        id: id
      }
    }));
  };

  this.getByShowId = function(showId) {
    return promises.extend(apiService.get('/promos/show/{showId}', {
      pathParams: {
        showId: showId
      }
    }));
  };

  this.getByInfluencerId = function(influencerId) {
    return promises.extend(apiService.get('/promos/show/{influencerId}', {
      pathParams: {
        influencerId: influencerId
      }
    }));
  };

  this.getByCode = function(code) {
    return promises.extend(apiService.get('/promos/by-code/{code}', {
      pathParams: {
        code: code
      }
    }));
  };

  this.save = function(promo) {
    if (!promo.id) {
      return apiService.post('/promos', {
        body: promo
      });
    } else {
      return apiService.put('/promos/{id}', {
        pathParams: {
          id: promo.id
        },
        body: promo
      });
    }
  };

  this.getPublicForBrand = function(brandId) {
    var self = this;
    return brandService.getBrand(brandId).then(function(brand) {
      if (brand.unavailable) {
        return false;
      }
      return self.getPublic().then(function(promos) {
        return promos.filter(function(promo) {
          if (!promo.overItems || (!promo.overItems.fromBrands && !promo.overItems.fromFulfillers)) {
            return false;
          }

          if (promo.overItems.fromBrands) {
            return promo.overItems.fromBrands.indexOf(brandId) >= 0;
          } else if (promo.overItems.fromFulfillers) {
            return promo.overItems.fromFulfillers.indexOf(brandId) >= 0;
          }

          return false;
        });
      });
    });
  };

  this.getPublicForProduct = function(scope) {
    var self = this;
    var product = scope.product;
    var style = scope.style;
    var variant = scope.variant;
    var brandId = product.brandId || (product.brand && product.brand.id);
    var fulfillerId = product.partnerId || (product.partner && product.partner.id);
    var user = userService.getUser();
    var userId = user && user.id;

    return brandService.getBrand(brandId).then(function(brand) {
      if (!brand || brand.unavailable) {
        return false;
      }

      var validatePromo = function(promoUsageCount) {
        return self.getPublic().then(function(promos) {
          return promos.filter(function(promo) {

            var beginsAt = new Date(promo.beginsAt);
            var expiresAt = new Date(promo.expiresAt);

            // Free shipping
            if (promo.freeShippingMethods) {
              var shippingMethodsMatch = _.some(scope.product && scope.product.partner &&
                scope.product.partner.shippingMethods, function(method) {
                return promo.freeShippingMethods.indexOf(method && method.id) >= 0;
              });

              if (!shippingMethodsMatch) {
                return false;
              }
            }

            // New users
            if (promo.newUser &&
              (!userService.isLoggedIn() ||
                user && user.createdAt && user.createdAt.getTime() < beginsAt.getTime() ||
                user && user.createdAt && user.createdAt.getTime() > expiresAt.getTime())) {
              return false;
            }

            // Max Usages Per User
            if (promo.maxUsagesPerUser > 0 && userService.isLoggedIn()) {
              var promoCount = _.filter(promoUsageCount.promoUsesCount, {id: promo.id})[0];
              if ((promoCount && promoCount.count) >= promo.maxUsagesPerUser) {
                return false;
              }
            }

            if (promo.overItems) {

              // From Brands
              if (promo.overItems.fromBrands &&
                promo.overItems.fromBrands.indexOf(brandId) < 0) {
                return false;
              }

              // Not From Brands
              if (promo.overItems.notFromBrands &&
                promo.overItems.notFromBrands.indexOf(brandId) >= 0) {
                return false;
              }

              // From Fulfillers
              if (promo.overItems.fromFulfillers &&
                promo.overItems.fromFulfillers.indexOf(fulfillerId) < 0) {
                return false;
              }

              // Not From Fulfillers
              if (promo.overItems.notFromFulfillers &&
                promo.overItems.notFromFulfillers.indexOf(fulfillerId) >= 0) {
                return false;
              }

              // StyleIds
              if (promo.overItems.styleIds) {
                if (config.featureFlags.infoPromoPerVariant.enabled && !_.indexOf(promo.overItems.styleIds, style.id)) {
                  return false;
                } else {
                  var productStyleIds = _.map(product.styles, 'id');
                  if (!_.some(promo.overItems.styleIds, productStyleIds)) {
                    return false;
                  }
                }
              }

              // With tags
              if (promo.overItems.withTags) {
                var equalTags;
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  var prodTags = _.merge(product.tags, style.tags);
                  equalTags = _.intersection(prodTags, promo.overItems.withTags);
                } else {
                  equalTags = _.intersection(product.tags, promo.overItems.withTags);
                }
                if (!equalTags || !equalTags.length) {
                  return false;
                }
              }

              // Without tags
              if (promo.overItems.withoutTags) {
                var tags;
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  var prodTags2 = _.merge(product.tags, style.tags);
                  tags = _.intersection(prodTags2, promo.overItems.withoutTags);
                } else {
                  tags = _.intersection(product.tags, promo.overItems.withoutTags);
                }
                if (tags && tags.length) {
                  return false;
                }
              }

              // With Price Over
              if (promo.overItems.withPriceOver) {
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  return variant.price > promo.overItems.withPriceOver;
                } else {
                  var anyProductWithPriceOver = _.some(product.variants, function(each) {
                    return each.price >= promo.overItems.withPriceOver;
                  });

                  if (!anyProductWithPriceOver) {
                    return false;
                  }
                }
              }

              // Items on sale
              if (promo.overItems.inSale) {
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  return variant.price !== variant.listPrice;
                } else {
                  var anyProductOnSale = _.some(product.variants, function(each) {
                    return each.price !== each.listPrice;
                  });
                  if (!anyProductOnSale) {
                    return false;
                  }
                }
              }

              // Full Priced
              if (promo.overItems.fullPriced) {
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  return variant.price === variant.listPrice;
                } else {
                  var everyProductFullPriced = _.every(product.variants, function(each) {
                    return each.price === each.listPrice;
                  });

                  if (!everyProductFullPriced) {
                    return false;
                  }
                }
              }

              // Final Sale
              if (promo.overItems.inFinalSale) {
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  return variant.finalSale;
                } else {
                  var anyProductOnFinalSale = _.some(product.variants, function(each) {
                    return each.finalSale;
                  });

                  if (!anyProductOnFinalSale) {
                    return false;
                  }
                }
              }

              // Not in Final Sale
              if (promo.overItems.notInFinalSale) {
                if (config.featureFlags.infoPromoPerVariant.enabled) {
                  return !variant.finalSale;
                } else {
                  var everyProductNotInFinalSale = _.every(product.variants, function(each) {
                    return !each.finalSale;
                  });

                  if (!everyProductNotInFinalSale) {
                    return false;
                  }
                }
              }
            }

            return true;
          });
        });
      };
      if (userService.isLoggedIn()) {
        return self.countUsagesByUser(userId).then(function(promoUsageCount) {
          return validatePromo(promoUsageCount);
        });
      } else {
        return validatePromo();
      }
    });
  };
}

module.exports = PromoService;
