var EventEmitter = require('events').EventEmitter;
var _ = require('lodash-core');

// @ngInject
function ApiService($http, config, localStorageService) {

  this.events = new EventEmitter();
  this.events.setMaxListeners(1000);
  this.cache = {};
  this._headers = {};

  this.setDefaultCommonHeader = function(key, value) {
    if (this.isValue(value)) {
      try {
        this._headers[key] = value;
      } catch (e) {
        if (this._headers === undefined) {
          this._headers = {};
          this._headers[key] = value;
        } else {
          throw e;
        }
      }
    } else if (!this.isObject(value)) {
      throw new Error('Set default common header for: "' + key + '" must be an string, number or boolean');
    }
  };

  this.removeDefaultCommonHeader = function(header) {
    try {
      delete this._headers[header];
    } catch (e) {
      throw e;
    }
  };

  this.get = function(url, config) {
    return this.request({
      method: 'GET',
      url: url,
      config: config
    });
  };

  this.post = function(url, config) {
    return this.request({
      method: 'POST',
      url: url,
      config: config
    });
  };

  this.put = function(url, config) {
    return this.request({
      method: 'PUT',
      url: url,
      config: config
    });
  };

  this.patch = function(url, config) {
    return this.request({
      method: 'PATCH',
      url: url,
      config: config
    });
  };

  this.delete = function(url, config) {
    return this.request({
      method: 'DELETE',
      url: url,
      config: config
    });
  };

  this.customRequest = function(requestConfig) {
    return $http(this.prepareRequest(requestConfig));
  };

  this.request = function(requestConfig) {
    var self = this;
    return $http(this.prepareRequest(requestConfig)).then(function(res) {
      // refresh token
      self.authorizeNewRequest(res);
      return res.data;
    }).catch(function(err) {
      self.events.emit('requestFailed', err, requestConfig);
      throw err;
    });
  };

  this.isLoggedInCache = function() {
    return this.cache.user && !this.cache.user.anonymous;
  };

  this.authorizeNewRequest = function(response) {
    if (this.isLoggedInCache()) {
      var authToken = response.headers('Authorization');
      if (authToken && this.cache.user.token !== authToken) {
        this.cache.user.token = authToken;
        this.updateUserToken(authToken);
      }
    }
  };

  this.updateUserToken = function(token) {
    if (this.isLoggedInCache()) {
      this.cache.user.token = token;
      localStorageService.setItem('lastUser', JSON.stringify(this.cache.user));
    }
  };

  this.loadCountryCache = function() {
    if ((!this.cache.country || !this.cache.country.header) && config.featureFlags.translations.enabled) {
      var countrySelected = localStorageService.getItem('country');
      if (countrySelected) {
        countrySelected = _.find(config.featureFlags.translations.countries, function(country) {
          return country.code.includes(countrySelected);
        });
      }
      if (!countrySelected) {
        countrySelected = _.find(config.featureFlags.translations.countries, function(country) {
          return country.code.includes('us');
        });
      }
      localStorageService.setItem('country', countrySelected.code);
      this.addCache('country', countrySelected);
    }
  };

  this.verifyCache = function(requestConfig) {
    if (!this.cache.user || (this.cache.user && this.cache.user.anonymous)) {
      this.cache.user = JSON.parse(localStorageService.getItem('lastUser'));
    }
    if (!this.isLoggedInCache()) {
      return false;
    }
    if (requestConfig.method === 'GET') {
      // whitelist urls that don't need authentication for GET (allow caching)
      if (/\/\/[^\/]+\/(brands|categories|products\/by-style)([\/\?].*)?$/i.test(requestConfig.url)) {
        return false;
      }
      if (/\/\/[^\/]+\/products(\/[^\/]+)?\/?(\?.+)?$/i.test(requestConfig.url) &&
        !/\/(curations|private-data)/.test(requestConfig.url)) {
        return false;
      }
      if (/\/\/[^\/]+\/products\/[^\/]+\/(wearwith|groups\/showcase)/i.test(requestConfig.url)) {
        return false;
      }
    }
    return true;
  };

  this.customPost = function(url, config) {
    return this.customRequest({
      method: 'POST',
      url: url,
      config: config
    });
  };

  this.customGet = function(url, config) {
    return this.customRequest({
      method: 'GET',
      url: url,
      config: config
    });
  };

  this.customPut = function(url, config) {
    return this.customRequest({
      method: 'PUT',
      url: url,
      config: config
    });
  };

  this.customDelete = function(url, config) {
    return this.customRequest({
      method: 'DELETE',
      url: url,
      config: config
    });
  };

  this.prepareRequest = function(requestConfig) {
    this.prepareUrl(requestConfig);
    this.prepareHeaders(requestConfig);
    this.prepareBody(requestConfig);
    return requestConfig;
  };

  this.prepareUrl = function(requestConfig) {
    this.normalizeUrl(requestConfig);
    if (requestConfig.config) {
      if (requestConfig.config.pathParams) {
        this.buildPathParams(requestConfig);
      }
      if (requestConfig.config.urlParams) {
        this.buildParams(requestConfig);
      }
    }
  };

  this.prepareHeaders = function(requestConfig) {
    if (requestConfig.config && this.isObject(requestConfig.config.headers)) {
      requestConfig.headers = requestConfig.config.headers;
    } else {
      requestConfig.headers = {};
    }
    if (this.verifyCache(requestConfig) && this.isLoggedInCache()) {
      requestConfig.headers.Authorization = this.cache.user.token;
    }
    this.loadCountryCache();
    if (this.cache.country) {
      requestConfig.headers['Accept-Language'] = this.cache.country.header;
    }
    if (this._headers && this._headers.length) {
      if (!requestConfig.headers) {
        requestConfig.headers = this._headers;
      } else {
        Object.entries(this._headers).forEach(function(header) {
          requestConfig.headers[header[0]] = header[1];
        });
      }
    }
  };

  this.prepareBody = function(requestConfig) {
    if (requestConfig.config && requestConfig.config.body) {
      requestConfig.data = requestConfig.config.body;
      requestConfig.headers['Content-Type'] = 'application/json';
    }
  };

  this.buildParams = function(request) {
    var self = this;
    if (this.isString(request.url)) {
      var stringParams;
      if (Array.isArray(request.config.urlParams)) {
        stringParams = request.config.urlParams.join('&');
      } else if (typeof request.config.urlParams === 'object') {
        stringParams = Object.entries(request.config.urlParams).map(function(param) {
          if (self.isValue(param[1])) {
            return param[0] + '=' + param[1];
          } else if (param[1] instanceof Date) {
            return param[0] + '=' + param[1].toISOString();
          } else if (param[1]) {
            throw new Error('url param "' + param[0] + '" must be an string or number and should exists');
          }
        }).filter(function(value) {
          return value !== undefined;
        }).join('&');
      } else {
        stringParams = request.config.urlParams;
      }
      if (request.url.indexOf('?') > -1) {
        request.url = request.url + '&' + stringParams;
      } else {
        request.url = request.url + '?' + stringParams;
      }
    } else {
      throw new Error('param "url" must be an string and should exists');
    }
  };

  this.normalizeUrl = function(request) {
    var apiUrl = config.apiBaseUrl;
    if (apiUrl[apiUrl.length - 1] === '/' && request.url[0] === '/') {
      request.url = apiUrl + request.url.substr(1);
    } else if (apiUrl[apiUrl.length - 1] !== '/' && request.url[0] !== '/') {
      request.url = apiUrl + '/' + request.url;
    } else {
      request.url = apiUrl + request.url;
    }
  };

  this.buildPathParams = function(requestConfig) {
    var self = this;
    var urlWithPath = requestConfig.url;
    Object.entries(requestConfig.config.pathParams).forEach(function(path) {
      var pathToReplace = '{' + path[0] + '}';
      if (self.isValue(path[1]) || path[1] instanceof Date) {
        if (urlWithPath.indexOf(pathToReplace) !== -1) {
          if (path[1] instanceof Date) {
            urlWithPath = urlWithPath.replace(pathToReplace, path[1].toISOString());
          } else {
            urlWithPath = urlWithPath.replace(pathToReplace, path[1]);
          }
        } else {
          throw new Error('We cannot find ' + path[0] + ' in the path to replacement');
        }
      } else if (path[1] === undefined) {
        urlWithPath = urlWithPath.replace('/' + pathToReplace, '');
      } else {
        throw new Error('url param "' + pathToReplace + '" must be an string or number');
      }
    });
    if (urlWithPath.match(/{\w*?}/)) {
      throw new Error('There are values in the path url that does not have replacement value');
    }
    requestConfig.url = urlWithPath;
  };

  this.isString = function(element) {
    return element !== undefined && (element instanceof String || typeof element === 'string');
  };

  this.isValue = function(element) {
    return element !== undefined && element !== '' &&
      (
        element instanceof String ||
        element instanceof Number ||
        element instanceof Boolean ||
        ['string', 'number', 'boolean'].indexOf(typeof element) > -1
      );
  };

  this.isObject = function(element) {
    return element !== undefined && element !== '' &&
      (
        ['object'].indexOf(typeof element) > -1 ||
        element instanceof Object
      );
  };

  this.addCache = function(key, value) {
    this.cache[key] = value;
  };

  this.removeCache = function() {
    this.cache = {};
  };

}

module.exports = ApiService;
