// @ngInject
function FormsService() {

  this.focusFirstField = function() {
    setTimeout(function() {
      var parent = '';
      // if modal is open, only look for fields in the modal
      var modal = $('.modal-panel.open');
      if (modal.length) {
        parent = '.modal-panel ';
      }
      var field = $(
        parent + 'input[autofocus][type!=hidden]:visible:not([readonly]):enabled,' +
        parent + 'input[type!=hidden]:visible:not([readonly]):enabled');
      if (field.length > 1) {
        // if there are many, try to find the first empty one
        var emptyField = field.filter(function(index, input) {
          return input.value === '' || input.value === null;
        });
        if (emptyField.length > 0) {
          field = emptyField;
        }
      }
      field.first().focus();
    }, 100);
  };

  function FormPromiseHandler() {
  }

  FormPromiseHandler.prototype.validate = function(form) {
    if (this.sending) {
      return false;
    }
    this.reset();
    this.form = form;
    if (!form.$valid) {
      return false;
    }
    return true;
  };

  FormPromiseHandler.prototype.reset = function() {
    this.failure = false;
    this.success = false;
    this.error = null;
    this.errorMessage = '';
    return this;
  };

  FormPromiseHandler.prototype.clear = function() {
    this.reset();
    if (this.form) {
      this.form.$setPristine();
      this.form.$setUntouched();
    }
    return this;
  };

  FormPromiseHandler.prototype.setError = function(err) {
    if (typeof err === 'string') {
      err = new Error(err);
    }
    this.sending = false;
    this.failure = true;
    this.error = err;
    this.status = err.status || 500;
    if (err.data && err.data.error && err.data.error.message) {
      this.errorMessage = err.data.error.message;
    } else if (err.statusText) {
      this.errorMessage = err.status + ' ' + err.statusText;
    } else {
      this.errorMessage = err.message || 'server error';
    }
    return this;
  };

  FormPromiseHandler.prototype.handle = function(promise) {
    var self = this;
    this.failure = false;
    this.success = false;
    this.error = null;
    this.errorMessage = '';
    this.sending = true;
    return promise.then(function(result) {
      self.sending = false;
      self.success = true;
      self.result = result;
    }, function(err) {
      self.setError(err);
      throw err;
    });
  };

  FormPromiseHandler.prototype.cssClasses = function() {
    var classes = [];
    if (this.sending) {
      classes.push('sending');
    } else if (this.success) {
      classes.push('success');
    } else if (this.failure) {
      classes.push('failure');
    }
    if (this.error && this.error.status) {
      classes.push('error-' + this.error.status);
      classes.push('error-' + Math.floor(this.error.status / 100) + 'xx');
    }
    if (!classes.length) {
      return '';
    }
    return 'form-' + classes.join(' form-');
  };

  this.promiseHandler = function() {
    return new FormPromiseHandler();
  };

}

module.exports = FormsService;
