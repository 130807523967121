var _ = require('lodash-core');

// @ngInject
function InfluencerProductsCarouselDirective($parse, productService) {
  var linker = function ($scope, _element, attrs) {
    $scope.id = attrs.carouselId;
    $scope.carousel = {
      query: {
        limit: 24,
      },
      breakpoints: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    };

    $scope.filters = {};
    $scope.result = {};
    $scope.responsive = $scope.carousel.breakpoints;
    $scope.productListName = 'influencer-products-carousel';

    var styleIds = JSON.parse(attrs.items);
    var defaultSort = $scope.carousel.query.defaultSort || '';

    var loading = true;

    $scope.ready = function () {
      return !loading;
    };

    var loadProducts = _.memoize(function (params) {
      loading = true;

      productService
        .search({
          searchTerm: params.searchTerm,
          styleIds: styleIds,
          limit: params.limit,
          page: params.page,
          sizes: params.sizes,
          colors: params.colors,
          tags: _.union(params.tags, params.userTags),
          brand: params.brand,
          sort: params.sort,
          sale: params.sale,
          discount: params.discount,
          gifts: params.gifts,
          price: params.price,
        })
        .then(function (data) {
          data.ready = true;
          setData(data);
        });
    });

    function getParams() {
      return {
        searchTerm: $scope.carousel.query.searchTerm || '',
        searchReady: $scope.search ? $scope.search.ready : true,
        sort: $scope.carousel.query.sort || defaultSort,
        page: $scope.carousel.query.page || 1,
        limit: $scope.carousel.query.limit || 8,
      };
    }

    loadProducts(getParams());

    function setData(data) {
      $scope.result.items = data.items;
      $scope.result.count = data.count;
      $scope.result.facets = data.facets;
      $scope.result.ready = data.ready;
      $scope.result.index = data.index;

      if (
        $scope.carousel &&
        $scope.carousel.productOrder &&
        $scope.carousel.productOrder.length
      ) {
        var dbList = _.compact(data.items);
        var unorderedItems = _.difference(
          _.map(dbList, 'styleId'),
          $scope.carousel.productOrder
        );
        var orderedList = $scope.carousel.productOrder.concat(unorderedItems);

        dbList.sort(function (a, b) {
          return (
            orderedList.indexOf(a.styleId) - orderedList.indexOf(b.styleId)
          );
        });
        $scope.products = dbList;
      } else {
        $scope.products = _.compact(data.items);
      }
      loading = false;
    }

  };
  return {
    link: linker,
    scope: true,
    templateUrl: '/views/partials/influencer-products-carousel.html',
    restrict: 'E',
  };
}

module.exports = InfluencerProductsCarouselDirective;
