var _ = require('lodash-core');

// @ngInject
function ProductBySourceController($scope, $state, $stateParams, productService,
  errorService) {

  $scope.url = $stateParams.url;

  var productPromise = productService.getBySourceUrl($stateParams.url);
  $scope.product = productPromise.lazyObject();

  productPromise.then(function() {
    var style = $scope.product.styles[0];
    $state.go('root.product.variant', _.assign({
      brand: $scope.product.brand.id,
      productName: style.slug,
      id: style.id
    }, style.key));
  }).catch(errorService.pageError);
}

module.exports = ProductBySourceController;
