// @ngInject
function UnsubscribePageController($scope, $stateParams, $state, userService) {
  $scope.email = $stateParams.email;
  $scope.topic = $stateParams.topic;
  $scope.setNoRobots();

  if (typeof $scope.email === 'undefined' || $scope.email === '') {
    $state.go('root.home');
  }
  if (typeof $scope.topic === 'undefined' || $scope.topic === '') {
    $scope.topic = 'newsletter';
  }
  $scope.setPageMetadata({
    title: 'Unsubscribe | THE MILE'
  });
  $scope.continueShopping = function() {
    $state.go('root.home');
  };
  userService.unsubscribe($scope.email, $scope.topic);
}

module.exports = UnsubscribePageController;
