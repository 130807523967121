var _ = require('lodash-core');

function lazyObjectGetter() {
  if (this.$object) {
    // shortcut for restangular promises
    if (this.$object instanceof Array) {
      throw new Error('promise response is an array');
    }
  } else {
    this.lazyWaitingObject = true;
    this.$object = {
      loading: true
    };
  }
  return this.$object;
}

function lazyArrayGetter() {
  if (this.$object) {
    // shortcut for restangular promises
    if (!(this.$object instanceof Array || this.$object.items instanceof Array)) {
      throw new Error('promise response is not an array');
    }
    return this.$object.items || this.$object;
  } else {
    this.lazyWaitingArray = true;
    this.$object = [];
    this.$object.loading = true;
  }
  return this.$object;
}

function getErrorMessage(err) {
  if (typeof err === 'string') {
    return err;
  }
  if (err.data && err.data.error && err.data.error.message) {
    return err.data.error.message;
  }
  if (err.statusText) {
    return err.statusText;
  }
  if (err.message) {
    return err.message;
  }
  return 'Server Error';
}

function updateLazyObject(lazy, result) {
  delete lazy.loading;
  if (typeof result === 'object' && !(result instanceof Array)) {
    for (var name in lazy) {
      if (typeof result[name] === 'undefined') {
        delete lazy[name];
      }
    }
    for (name in result) {
      lazy[name] = result[name];
    }
  }
  return lazy;
}

function updateLazyArray(lazy, result) {
  delete lazy.loading;
  if (typeof result === 'object') {
    if (result instanceof Array) {
      lazy.length = 0;
      Array.prototype.push.apply(lazy, result);
    } else if (result.items instanceof Array) {
      lazy.length = 0;
      Array.prototype.push.apply(lazy, result.items);
      if (result.count !== undefined) {
        lazy.totalCount = result.count;
      }
    }
  }
  return lazy;
}

exports.extend = function(promise) {

  // make this method idempotent
  if (promise.lazyObject) {
    return promise;
  }

  var extendedPromise = promise.then(function(value) {
    if (value && typeof value.plain === 'function') {
      value = value.plain();
    }
    if (extendedPromise.lazyWaitingObject) {
      value = updateLazyObject(extendedPromise.$object, value);
    } else if (extendedPromise.lazyWaitingArray) {
      value = updateLazyArray(extendedPromise.$object, value);
    }
    extendedPromise.$object = value;
    return value;
  }, function(error) {
    if (extendedPromise.lazyWaitingObject) {
      var lazy = extendedPromise.$object;
      delete lazy.loading;
      lazy.loadingError = error || true;
      lazy.loadingErrorMessage = getErrorMessage(error);
    }
    throw error;
  });

  extendedPromise.lazyObject = lazyObjectGetter;
  extendedPromise.lazyArray = lazyArrayGetter;
  return extendedPromise;
};

exports.immediate = function(result) {
  // returns a promise which resolves immediately
  var deferred;
  angular.injector(['ng']).invoke(['$q', function($q) {
    deferred = $q.defer();
  }]);
  if (result instanceof Error) {
    deferred.reject(result);
  } else {
    deferred.resolve(result);
  }
  var promise = deferred.promise;
  promise.$object = promise.$object || result;
  promise.lazyObject = lazyObjectGetter;
  promise.lazyArray = lazyArrayGetter;
  return promise;
};

exports.createDeferred = function() {
  var deferred;
  angular.injector(['ng']).invoke(['$q', function($q) {
    deferred = $q.defer();
  }]);
  return deferred;
};

exports.create = function(promiseBody) {
  if (process.browser) {
    var promise;
    angular.injector(['ng']).invoke(['$q', function($q) {
      promise = $q(promiseBody);
    }]);
    return promise;
  } else {
    var Promise = require('bluebird');
    return new Promise(promiseBody);
  }
};

exports.memoize = function(func, resolver) {
  var wrapper = _.memoize(
    function() {
      return exports.extend(
        func.apply(this, arguments).catch(function(e) {
          if (e.status !== 404) {
            wrapper.cache.clear();
          }
          throw e;
        })
      );
    },
    resolver
  );

  return wrapper;
};

exports.tryAtMost = function(func, options) {
  if (!_.isObject(options)) {
    options = { retries: parseInt(options) };
  }
  return function() {
    var args = arguments;
    var self = this;
    return exports.create(function(resolve, reject) {

      retry(options.retries);

      function retry(retries) {
        func.apply(self, args).then(resolve).catch(function(err) {
          if (shouldRetry(err, retries)) {
            retry(--retries);
          } else {
            reject(err);
          }
        });
      }
    });
  };

  function shouldRetry(err, retries) {
    if (err && retries) {
      if (!options.ifError) {
        return true;
      }
      if (typeof options.ifError === 'function') {
        return !!options.ifError(err);
      }
      return _.isEqual(_.pick(err, _.keys(options.ifError)), options.ifError);
    }
    return false;
  }
};
