var sharedBusinessRules = require('shared-business-rules');
var _ = require('lodash');

/* globals $ */
/* jshint maxparams: 15 */
// @ngInject
function LoyaltyController($scope, $rootScope, userService, loyaltyExperienceService, staticAssetService,
  $location, viewportService, $state, config, $window, $uibModal) {
  $scope.setBreadcrumbPath([{ name: 'OM Rewards' }]);
  $scope.setPageMetadata({
    title: 'OM Rewards | THE MILE'
  });
  $scope.showHowItWorksContent = true;
  $scope.showMyPointsContent = false;
  $scope.showRedeemContent = false;
  $scope.hash = '';
  $scope.user = $rootScope.getUser();
  $scope.isLoggedIn = userService.isLoggedIn();
  $scope.logInHandler = function() {
    document.location.reload(true);
  };
  $scope.logOutHandler = function() {
    $state.go('root.home');
  };
  if ($location.hash() === '' && $state.current.name !== 'admin.loyalty') {
    $location.hash('how-it-works');
  }
  userService.events.on('loggedOut', $scope.logOutHandler);
  userService.events.on('loggedIn', $scope.logInHandler);

  var converted = false;

  $scope.$watch('currency.conversionRate', function() {
    if ($rootScope.currency && $rootScope.currency.conversionRate &&
      $rootScope.country && $rootScope.country.code !== 'us' && !converted) {
      converted = true;
      var saved = $scope.loyaltyRewards;
      _.forEach($scope.loyaltyRewards, function(reward) {
        var value = reward.name.match(/\$(\d{2,4})/);
        reward.name = reward.name.replace(value[0], value[0] + $scope.calculateTotalInCurrency(value[1]));
      });
      $scope.loyaltyRewards = [];
      setTimeout(function() {
        $scope.loyaltyRewards = saved;
      }, 0);
    }
  });

  $scope.calculateTotalInCurrency = function(total) {
    if ($rootScope.currency && $rootScope.country && $rootScope.country.code !== 'us') {
      return ' (' + $rootScope.currency.symbol + Math.round($rootScope.currency.conversionRate * Number(total)) + ')';
    } else {
      return '';
    }
  };

  $scope.getAwardMessage = function(activity) {
    var awardText = '';
    if (activity.type === 'award') {
      var awardName = activity.details.award.name.toUpperCase();
      if (awardName === 'COMPLETED PERSONAL INFO') {
        awardText += 'THANK YOU FOR COMPLETING ACCOUNT INFORMATION';
      } else if (awardName === 'MY MILE CREATION') {
        awardText += 'THANK YOU FOR CREATING YOUR MILE';
      } else if (awardName === 'BIRTHDAY') {
        awardText += 'BIRTHDAY SURPRISE';
      } else {
        awardText += activity.details.award.name;
      }
      awardText += (activity.status === 'pending' || activity.status === 'cancelled' ?
        ' (' + activity.status + ')' : '');
    }
    return awardText;
  };
  var hideAll = function() {
    $scope.showMyPointsContent = false;
    $scope.showRedeemContent = false;
    $scope.showHowItWorksContent = false;
  };

  $scope.openSection = function(section) {
    $scope.sectionInstance = $uibModal.open({
      templateUrl: '/views/partials/modals/loyalty-' + section + '.html',
      windowClass: 'loyalty-' + section,
      size: 'lg',
      scope: $scope
    });
  };

  $scope.close = function () {
    $scope.sectionInstance.dismiss();
  };

  $scope.goToSection = function(section) {
    if ($scope.hash === section) {
      var anchor = document.getElementById(section);
      if (anchor) {
        anchor.scrollIntoView();
      }
    } else {
      hideAll();
      switch (section) {
        case 'my-points':
        case 'refer-a-friend':
          $scope.showMyPointsContent = true;
          break;
        case 'redeem':
          $scope.showRedeemContent = true;
          $scope.showRewards = true;
          $scope.showExperiences = true;
          break;
        case 'redeem-experiences':
          $scope.showRewards = false;
          $scope.showExperiences = true;
          $scope.showRedeemContent = true;
          break;
        case 'redeem-rewards':
          $scope.showRewards = true;
          $scope.showExperiences = false;
          $scope.showRedeemContent = true;
          break;
        case 'how-it-works':
          $scope.showHowItWorksContent = true;
          break;
      }
      if (!$scope.isLoggedIn && section !== 'how-it-works') {
        var loginModalShown = false;
        $scope.$watch('modal', function() {
          setTimeout(function() {
            if (!loginModalShown) {
              $scope.modal.show('login', { trigger: 'loyalty' });
              loginModalShown = true;
            }
          }, 50);
        });
      }
      // Scrolling 1px down, to activate the element on view changes
      setTimeout(function() {
        $(window).scrollTop($(window).scrollTop() + 2);
        setTimeout(function() {
          $(window).scrollTop($(window).scrollTop() - 2);
        }, 50);
      }, 100);
    }
    $scope.hash = section;
  };

  $scope.selectExperiences = function() {
    $scope.showExperiences = !$scope.showExperiences;
  };

  $scope.selectRewards = function() {
    $scope.showRewards = !$scope.showRewards;
  };

  if ($location.hash() !== '') {
    $scope.goToSection($location.hash());
  }

  if (viewportService.is('<md')) {
    $scope.$on('$locationChangeSuccess', function() {
      $scope.goToSection($location.hash());
    });
  }

  $rootScope.loyalty = { loading: true };
  var loyaltyPromise = userService.getLoyalty();
  loyaltyPromise.then(function(loyalty) {
    $rootScope.loyalty.loading = false;
    var isCompletelyExpired = function(activity) {
      var expirationInfo = activity.details && activity.details.expirationInfo;
      return expirationInfo &&
        (
          expirationInfo.points === activity.points ||
          (expirationInfo.activity && expirationInfo.activity.points === -activity.points)
        );
    };

    if (loyalty.activities && loyalty.activities.length) {
      loyalty.activities = loyalty.activities.filter(function(activity) {
        return activity.status !== 'conditional' && !isCompletelyExpired(activity);
      });
    }

    $rootScope.loyalty = loyalty;
    $scope.tier = loyaltyExperienceService.getTierName(loyalty.tier);

    $scope.nextTier = loyalty.dollarsForNextTier > 0 ?
      loyaltyExperienceService.getTierName(loyalty.tier + 1) : undefined;

    $scope.tierBanner = staticAssetService.url('/content/images/om-rewards-banners/banner-' +
      $scope.tier.toLowerCase() + '.jpeg');

    if ($rootScope.loyalty.remainingPoints === undefined) {
      $rootScope.loyalty.remainingPoints = 0;
    }
    if ($rootScope.loyalty.sweepstakesTickets === undefined) {
      $rootScope.loyalty.sweepstakesTickets = 0;
    }
    if ($rootScope.loyalty.activities === undefined) {
      $rootScope.loyalty.activities = [];
    }
  });

  var awards = [
    {
      type: 'shopTheMile', title: 'Shop The Mile', points: '$1 = 1 point', icon: '../img/list/01.svg',
      button: 'Shop', status: 'purchase'
    },
    {
      type: 'createAnAccount', title: 'Create an Account', points: '50 points', icon: '../img/list/02.svg',
      button: 'Sign up', status: 'Sign up'
    },
    {
      type: 'downloadTheMileApp', title: 'Download The Mile App', points: '500 points', icon: '../img/list/03.svg',
      button: 'Download', status: 'Download App'
    },
    {
      type: 'writeAProductReview', title: 'Write a Product Review', points: '25 points', icon: '../img/list/04.svg',
      button: 'Shop', status: 'Product Review'
    },
    {
      type: 'subscribeToEmail', title: 'Subscribe to Email', points: '50 points', icon: '../img/list/05.svg',
      button: 'Sign up', status: 'Email sign up'
    },
    {
      type: 'birthdayGift', title: 'Birthday Gift', points: '250 points', icon: '../img/list/10.svg',
      button: 'Complete', status: 'Birthday'
    },
    {
      type: 'referAFriend', title: 'Refer A Friend', points: '50 points', icon: '../img/list/14.svg',
      button: 'Share', status: 'Referral program'
    },
    {
      type: 'postReel', title: 'Post Reel', points: '500 points', icon: '../img/list/12.svg',
      button: 'Post', status: 'Post a community reel'
    },
    {
      type: 'subscribeToSms', title: 'Subscribe To SMS', points: '50 points', icon: '../img/list/03.svg',
      button: 'Subscribe', status: 'Text sign up'
    },
    {
      type: 'followOnInstagram', title: 'Follow on Instagram', points: '50 points', icon: '../img/list/06.svg',
      button: 'Follow', status: 'Follow on instagram'
    },
    {
      type: 'likeOnFacebook', title: 'Like on Facebook', points: '50 points', icon: '../img/list/07.svg',
      button: 'Like', status: 'Follow on facebook'
    },
    {
      type: 'followOnTikTok', title: 'Like on TikTok', points: '50 points', icon: '../img/list/08.svg',
      button: 'Follow', status: 'Follow on tiktok'
    },
    {
      type: 'followOnYoutube', title: 'Like on Youtube', points: '50 points', icon: '../img/list/09.svg',
      button: 'Follow', status: 'Follow on youtube'
    },
    {
      type: 'shareContent', title: 'Share Content', points: '50 points', icon: '../img/list/11.svg',
      button: 'Share', status: 'Sharing content'
    },
    {
      type: 'appReview', title: 'App Review', points: '50 points', icon: '../img/list/13.svg',
      button: 'Review', status: 'Review app store'
    }
  ];

  $scope.awards = [];

  function checkAward(award, statuses) {
    if (award.type === 'subscribeToSms' && userService.getUser().phoneNumber) {
      return true;
    } else if (award.type === 'createAnAccount' && userService.isLoggedIn()) {
      return true;
    } else if (award.type === 'subscribeToEmail' && userService.getUser().email) {
      return true;
    } else if (['shopTheMile', 'shareContent', 'referAFriend'].includes(award.type)) {
      return false;
    } else {
      var status = _.find(statuses, function(rew) { return rew.type === award.status; });
      return !!status.available && status.available > 0;
    }
  }

  function loadAwards() {
    userService.getLoyaltyStatusByUserId(userService.getUser().id || 'guest').then(function(loyaltyStatus) {
      _.forEach(awards, function(award) {
        var loyaltyAward = _.clone(award);
        loyaltyAward.soon = !config.featureFlags.loyalty.awardsEnabled.includes(award.type);
        loyaltyAward.obtained = checkAward(loyaltyAward, loyaltyStatus.rewards);
        $scope.awards.push(loyaltyAward);
      });
    });
  }

  loadAwards();

  $scope.upgradeButtonText = function () {
    switch ($rootScope.loyalty.tier) {
      case 1: return 'UPGRADE TO GOLD';
      case 2: return 'UPGRADE TO PLATINUM';
      default: return 'CONTINUE EARNING';
    }
  };

  $scope.openAwardUrl = function(award) {
    if (award.obtained || award.soon) {
      return;
    }
    switch (award.type) {
      case 'shopTheMile':
      case 'writeAProductReview':
        $state.go('root.shop.just-in', {
          category1: 'whats-new', page: null, sale: null, sort: null, sizes: null, occasion: null
        });
        return;
      case 'createAnAccount':
        // go to sign in
        $scope.modal.show('register', { trigger: 'header' });
        return;
      case 'postReel':
      case 'shareContent':
      case 'downloadTheMileApp':
        $window.open('https://apps.apple.com/us/app/the-mile/id1628821302');
        // go to external link
        return;
      case 'subscribeToSms':
      case 'referAFriend':
      case 'subscribeToEmail':
      case 'birthdayGift':
        // go to profile
        $state.go('root.account.summary');
        return;
      case 'followOnInstagram':
        $window.open('https://www.instagram.com/themilelive', '_blank');
        return;
      case 'likeOnFacebook':
        $window.open('https://www.facebook.com/theorchardmile', '_blank');
        return;
      case 'followOnTikTok':
        $window.open('https://www.tiktok.com/@themilelive', '_blank');
        return;
      case 'followOnYoutube':
        $window.open('https://www.youtube.com/@themilelive', '_blank');
        return;
    }
  };

  $scope.fromCentsToDollarsRounded = function(value) {
    return Math.trunc(value / 100);
  };
  $scope.loyaltyRewards = sharedBusinessRules.rewards.getArray();

  $scope.getTierBannerImage = function() {
    return $scope.tierBanner;
  };

  $scope.loyaltyExperiences = { loading: true };
  var loyaltyExperiencesPromise = loyaltyExperienceService.getActiveLoyaltyExperiences();
  loyaltyExperiencesPromise.then(function(loyaltyExperiences) {
    $scope.loyaltyExperiences = loyaltyExperiences;
  });

  $(window).on('scroll', function() {
    var loyaltyView = $('.loyalty-view');
    if (loyaltyView && loyaltyView.offset() && $(window).scrollTop() > loyaltyView.offset().top - 20) {
      $('.menu-panel').addClass('active');
    } else {
      $('.menu-panel').removeClass('active');
    }
  });
  // LOYALTY EXP
  $scope.instance = {};

  $scope.hideModal = function() {
    $rootScope.closeModals();
  };

  $scope.openModal = function(modal, experience) {
    $rootScope.closeModals();
    setTimeout(function() {
      $rootScope.experience = experience;
      $scope.instance = $uibModal.open({
        templateUrl: '/views/partials/modals/loyalty-' + modal + '.html',
        windowClass: 'loyalty-modal',
        size: 'lg',
        scope: $rootScope
      });
    }, 300);
  };

  $scope.canRedeemReward = function(experience) {
    experience = experience || $rootScope.experience;
    return $rootScope.loyalty.remainingPoints >= experience.points;
  };
  $scope.errorMessage = false;
  $scope.loadingRedeem = false;
  $scope.redeemReward = function() {
    $scope.loadingRedeem = true;
    var redeemPromise = {};

    if ($rootScope.experience.partner.id === 'orchardmile') {
      redeemPromise = loyaltyExperienceService.redeemReward($rootScope.experience.id);
    } else {
      redeemPromise = loyaltyExperienceService.redeem($rootScope.experience.id);
    }

    redeemPromise.then(function(result) {
      if (result.ok) {
        $rootScope.closeModals();
        $scope.loadingRedeem = false;
        $rootScope.loyalty = {loading: true};
        userService.redeemPoints();
        var loyaltyPromise = userService.getLoyalty();
        loyaltyPromise.then(function(loyalty) {
          $rootScope.loyalty = loyalty;
        });
      } else {
        $scope.errorMessage = true;
        $scope.loadingRedeem = false;
        $scope.error = result.message ? result.message : 'Sorry, an error ocurred';
      }
    });
  };
  $scope.showConfirmation = function() {
    $rootScope.closeModals();
    setTimeout(function() {
      $scope.openModal('confirmation', $scope.experience);
    }, 300);
  };

  $scope.descriptionStyled = function(description) {
    if (description) {
      return description.replace(/\n/g, '<br/>');
    }
    return '';
  };

  $scope.getExperienceImage = function(experience, image) {
    var partnerId = experience.partner.id ? experience.partner.id : experience.partner;
    if (image === 'logo.png') {
      return staticAssetService.url('/content/images/experiences/' + partnerId + '/' + image);
    } else {
      return staticAssetService.url('/content/images/experiences/' + partnerId + '/' + experience.id + '/' + image);
    }
  };
}

module.exports = LoyaltyController;
