function TrackJsAdapter() {
}

TrackJsAdapter.prototype.attach = function(analyticsEmitter, config, userService,
  errorExplodeService) {

  window._trackJs = {
    application: config.application,
    onError: function (payload) { // there is a second parameter with error

      // List borrowed from the awesome @pamelafox
      // https://gist.github.com/pamelafox/1878283
      var i, commonCrypticExtensionErrors = [
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'Script error.'
      ];
      for (i = 0; i < commonCrypticExtensionErrors.length; i++) {
        if (payload.message.indexOf(commonCrypticExtensionErrors[i]) > -1) {
          // returning any kind of falsy value will reject error
          return false;
        }
      }

      var httpErrorMatch = /^(\d{3}) /.exec(payload.message);
      if (httpErrorMatch && [400, 401, 402, 403, 404, 429].indexOf(parseInt(httpErrorMatch[1])) >= 0) {
        // don't log these http status, they are not application errors
        return false;
      }

      payload.network = payload.network.filter(function(item) {

        // remove analytics traffic
        if (/https:\/\/api\.segment\.io\//.test(item.url)) {
          return false;
        }
        if (/https:\/\/wwww\.fullstory\.com\/rec/.test(item.url)) {
          return false;
        }
        if (/https:\/\/api\.mixpanel\.com\//.test(item.url)) {
          return false;
        }

        if (/\.svg?g$/i.test(item.url)) {
          return false;
        }
        return true;
      });

      // associate error with user
      var user = userService.getUser();
      if (user && !user.anonymous && !user.guest) {
        payload.customer.userId = user.id;
      }

      // associate error with current fullstory session
      if (window.FS !== undefined && typeof window.FS.getCurrentSession === 'function') {
        try {
          var fullStorySessionURL = window.FS.getCurrentSessionURL();
          if (fullStorySessionURL) {
            // add fullstory session to the error context
            payload.console.push({
              message: 'fullstory session: ' + fullStorySessionURL,
              severity: 'info',
              timestamp: new Date().toISOString()
            });
          }
        } catch (err) {
          console.log('error getting fullstory session id');
        }
      }

      try {
        if (window.hj && window.hj.globals.get('userId')) {
          payload.console.push({
            message: 'hotjar session: ' + window.hj.globals.get('userId').split('-').shift(),
            severity: 'info',
            timestamp: new Date().toISOString()
          });
        }
      } catch (e) {
        console.log('error getting hotjar session id');
      }

      // trigger a fake UI event to get mark on fullstory (or any analogous tool) session log
      function logErrorAsUIEvent(message) {
        try {
          var errorInput = document.querySelector('#hidden-error-message');
          if (!errorInput) {
            errorInput = document.createElement('input');
            errorInput.setAttribute('id', 'hidden-error-message');
            errorInput.setAttribute('style', 'display: none; visibility: hidden;');
          }
          errorInput.value = message;
          document.body.appendChild(errorInput);
          var event = document.createEvent('HTMLEvents');
          event.initEvent('change', true, false);
          errorInput.dispatchEvent(event);
        } catch (err) {
          // unable to log the error on the UI session, ignore
          return;
        }
      }
      logErrorAsUIEvent('ERROR: ' + payload.message);

      if (errorExplodeService.isEnabled()) {
        errorExplodeService.log(payload.message);
      }

      return true;
    }
  };
};

module.exports = new TrackJsAdapter();
