// @ngInject
function BodyScrollService(platformService) {

  this.isLocked = function() {
    return !!this.initialState;
  };

  this.lock = function() {
    if (this.initialState) {
      this.initialState.lockCount++;
      return;
    }
    this.initialState = {
      lockCount: 1
    };
    var html = document.getElementsByTagName('html')[0];
    $(html).addClass('lock-position');
    if (platformService.isMobile()) {
      // Catch touchevents on overlays to prevent scrolling
      $('.hamburger-overlay, .modal-overlay').bind('touchmove', {}, function(event) {
        event.preventDefault();
      });
    }
  };

  this.unlock = function(force) {
    if (!this.initialState) {
      return;
    }
    if (this.initialState.lockCount > 1 && !force) {
      this.initialState.lockCount--;
      return;
    }
    var html = document.getElementsByTagName('html')[0];
    $(html).removeClass('lock-position');
    delete this.initialState;
  };

}

module.exports = BodyScrollService;
