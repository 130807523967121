
// @ngInject
function FullscreenZoomController($scope, $rootScope, $timeout, analyticsService,
  bodyScrollService, productService) {

  $scope.blankImageSrc = productService.getBlankImageUrl();

  var zoom = $rootScope.fullscreenZoom = {

    isVisible: false,
    isFullyClosed: true,
    isFullyLoaded: false,

    show: function(options) {

      // prevent css bottom:0 from going to document bottom
      document.body.scrollTop = 0;

      this.isFullyClosed = false;
      this.isFullyLoaded = false;
      this.instanceData = this.instanceData || {};
      this.instanceData.options = options || {};
      $scope.image = this.instanceData.options.image;
      if (this.fullyClosedTimeout) {
        clearTimeout(this.fullyClosedTimeout);
        delete this.fullyClosedTimeout;
      }

      var container = document.querySelector('.fullscreen-zoom');
      $timeout(function() {
        bodyScrollService.lock();
        zoom.hiding = false;
        zoom.isVisible = true;
        container.scrollLeft = 0.5 * (container.scrollWidth - container.clientWidth);
        container.scrollTop = 0.5 * (container.scrollHeight - container.clientHeight);
        setTimeout(function() {
          container.scrollLeft = 0.5 * (container.scrollWidth - container.clientWidth);
          container.scrollTop = 0.5 * (container.scrollHeight - container.clientHeight);
        }, 100);
      }, 150);

      analyticsService.track('Fullscreen Zoom Shown');

      var fullImageSrc = $scope.image && $scope.image.src;
      var imageLoader = new Image();
      imageLoader.onload = function() {
        if (!$scope.image || $scope.image.src !== fullImageSrc) {
          return;
        }
        $scope.$apply(function() {
          var zoomImg = document.querySelector('.zoom-img');
          zoomImg.style.opacity = 0;
          zoom.isFullyLoaded = true;
          setTimeout(function() {
            zoomImg.style.top = ((container.scrollHeight - zoomImg.clientHeight) / 2) + 'px';
            setTimeout(function() {
              zoomImg.style.opacity = '';
            });
          }, 100);
        });
      };
      imageLoader.src = fullImageSrc;

      resetPageZoom();

      var url = new URL(window.location);
      history.pushState({}, '', url + '#zoom');
    },

    hide: function() {
      if (!this.isVisible) {
        return;
      }

      if (document.location.hash === '#zoom') {
        this.hiding = true;
        history.back();
        return;
      }

      var self = this;
      angular.element(':focus').blur();
      bodyScrollService.unlock(true);
      delete this.instanceData;
      this.isVisible = false;
      this.fullyClosedTimeout = setTimeout(function() {
        zoom.isFullyLoaded = false;
        if (!self.isVisible) {
          $scope.$apply(function() {
            // the modal should be fully closed now
            self.hiding = false;
            self.isFullyClosed = true;
            delete $scope.image;
          });
        }
        // 500ms is a safe timeout that ensures hide animation is complete
      }, 500);

      resetPageZoom();

      analyticsService.track('Fullscreen Zoom Hidden');
    }
  };

  $scope.show = function(options) {
    zoom.show(options);
  };

  $scope.hide = function() {
    zoom.hide();
  };

  $scope.$on('globalKeydown', function(e, keyboardEvent) {
    if (keyboardEvent.keyCode === 27) {
      zoom.hide();
    }
  });

  function resetPageZoom() {
    var metaViewport = $('meta[name=viewport]');
    var metaViewportContent = metaViewport.attr('content');
    metaViewport.replaceWith('<meta name="viewport" content="width=device-width' +
      ', initial-scale=1.0, maximum-scale=1.0, user-scalable=no">');
    setTimeout(function() {
      $('meta[name=viewport]')
        .replaceWith($('<meta name="viewport">').attr('content', metaViewportContent));
    }, 5);
  }

  window.addEventListener('popstate', function() {
    zoom.hide();
  });

}

module.exports = FullscreenZoomController;
