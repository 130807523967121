// @ngInject
function ImageMicrodataDirective() {
  var linker = function($scope, element, attrs) {
    attrs = attrs || {};
    if ($scope.$parent.imageMicrodata) {
      element.attr('itemprop', 'image');
    }
  };
  return {
    link: linker,
    restrict: 'EA'
  };
}

module.exports = ImageMicrodataDirective;
