/* jshint maxparams: 13 */
// @ngInject
function StoriesDropdownController($scope, $rootScope, $state, $stateParams, $timeout,
  staticAssetService, viewportService, contentPageService, listContextService) {

  listContextService.setScopeContext($scope);


  $scope.latestStories = $scope.featureFlags.stories.enabled ?
    contentPageService.getLatestStories() : [];

  $scope.storiesTrend = $scope.latestStories.filter(function(story) {
    return story.category === 'trend';
  });

  $scope.walkTheMileTitle = function(title) {
    if (typeof title !== 'undefined') {
      return title.replace('Walk the Mile with ', '').replace('Walk the Mile in ', '');
    }
    return '';
  };

  $scope.featuredStory = $scope.storiesTrend[0];
  $scope.featuredStoryWalk = $scope.storiesMile[0];

  $scope.getStoryUrl = function(story) {
    if (typeof story === 'undefined') {
      return;
    }
    return '/' + story.name;
  };

  $scope.getStoryLinkText = function(story) {
    if (typeof story === 'undefined') {
      return;
    }
    return story.linkText || 'Read More';
  };

  $scope.getStoryImageUrl = function(story) {
    if (typeof story === 'undefined') {
      return;
    }
    var mobile = viewportService.is('<md');
    return staticAssetService.url('/content/images/tiles/' + story.image + (mobile ? '-mobile' : '') +
      ($rootScope.isWEBPCapable ? '.webp' : '.jpg'));
  };
  $scope.getStoryImageUrlDropdown = function(story) {
    if (typeof story === 'undefined') {
      return;
    }
    return staticAssetService.url(story.shareImage);
  };

}

module.exports = StoriesDropdownController;
