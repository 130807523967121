/* jshint maxparams: 15 */

// @ngInject
function SweepstakesController($scope, $rootScope, $location, $state, $stateParams, $uibModal, userService,
                               staticAssetService, formsService) {
  $scope.setBreadcrumbPath([{name: 'Sweepstakes'}]);
  $scope.setPageMetadata({title: 'Sweepstakes | THE MILE'});
  $scope.showSweepstakes = true;
  $scope.showHowItWorksContent = true;
  $scope.showMyPointsContent = false;
  $scope.user = $rootScope.getUser();
  $scope.isLoggedIn = userService.isLoggedIn();

  $scope.userMail = ($scope.user && !$scope.user.anonymous) ? $scope.user.email : '';
  $scope.userPhoneNumber = ($scope.user && !$scope.user.anonymous) ? $scope.user.phoneNumber : '';
  userService.getSubscriptionsByTopic().then(function(subscriptions) {
    $scope.subscribed = subscriptions && subscriptions.items && subscriptions.items.includes('sweepstakes');
  });

  $scope.logInHandler = function() {
    document.location.reload(true);
  };

  $scope.logOutHandler = function() {
    document.location.reload(true);
  };

  $scope.userParticipating = false;

  userService.events.on('loggedOut', $scope.logOutHandler);
  userService.events.on('loggedIn', $scope.logInHandler);

  $(window).on('scroll', function() {
    var loyaltyView = $('.sweepstakes-view');
    if (loyaltyView && loyaltyView.offset() && $(window).scrollTop() > loyaltyView.offset().top - 20) {
      $('.menu-panel').addClass('active');
    } else {
      $('.menu-panel').removeClass('active');
    }
  });

  $scope.showTermsAndConditions = function(modal, experience) {
    $rootScope.experience = experience;
    $scope.instance = $uibModal.open({
      templateUrl: '/views/partials/modals/sweepstakes-terms-and-conditions.html',
      windowClass: 'loyalty-modal',
      size: 'lg',
      scope: $rootScope
    });
  };

  $scope.hideModal = function() {
    $rootScope.closeModals();
  };

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.subscribe = function() {
    $scope.form.phoneNumber.$setValidity('changingAsAGuest', true);
    var email = $scope.userMail;
    if (email === '') {
      $scope.form.email.$setValidity('required', false);
      return false;
    }

    if (!formPromise.validate($scope.form)) {
      $scope.showErrors = true;
      return false;
    }

    $scope.showErrors = false;

    formPromise.handle(formPromiseHandler().then(function(responses) {
        $scope.subscribed = true;
        return responses[0];
      }, function(err) {
        throw err;
      })
    );

    function formPromiseHandler() {
      return userService.subscribeEmailTo(email, 'newsletter', {
        firstName: $scope.user.anonymous ? $scope.userFirstName : undefined,
        lastName: $scope.user.anonymous ? $scope.userLastName : undefined
      }).then(function() {
        return userService.subscribeEmailTo(email, 'sweepstakes', {
          firstName: $scope.user.anonymous ? $scope.userFirstName : undefined,
          lastName: $scope.user.anonymous ? $scope.userLastName : undefined
        }).then(function() {
          if ($scope.user.anonymous && $scope.userPhoneNumber) {
            return userService.setPhoneNumber('+1' + $scope.userPhoneNumber, $scope.userMail, $scope.user.anonymous)
              .then(function(response) {
                return response;
              }, function(err) {
                $scope.form.phoneNumber.$setValidity('changingAsAGuest', false);
                $scope.showErrors = true;
                throw new Error(err.data.error.message);
              });
          }
        }, function(err) {
          throw err;
        });
      }, function(err) {
        throw err;
      });
    }
  };

  if ($stateParams.showterms) {
    $scope.showTermsAndConditions();
  }
}

module.exports = SweepstakesController;
