/* jshint maxparams: 12 */

// @ngInject
function ShowDraggableVideoDirective(
  config,
  showService,
  modalShowService,
  sessionStorageService,
  platformService,
  $location,
  $rootScope
) {
  var linker = function($scope, element, attrs) {

    if (platformService.isBot()) {
      return;
    }

    var selectedElement;
    $rootScope.floatingShowOpened = !$location.search().show || !/[1234567890abcdef]{24}/.test($location.search().show);
    $scope.loading = true;
    $scope.actions = false;
    $scope.draggable = true;
    var fetchInterval;
    var loadingInterval;

    element.on('click', function(e){
      if (e.target.id && /[1234567890abcdef]{24}/.test(e.target.id)) {
        e.preventDefault();
        setTimeout(function() {
          modalShowService.openShow(e.target.id, $scope, $rootScope.floatingSectionName);
          $rootScope.floatingShowOpened = false;
        }, 200);
      }
    });

    $rootScope.$watch('floatingShowOpened', function() {
      if ($rootScope.floatingShowOpened && $rootScope.showId) {
        $scope.loading = false;
        $scope.actions = false;
        $scope.showId = $rootScope.showId;
        loadDraggableFunctions();
      }
    });

    $scope.closeShow = function () {
      $rootScope.floatingShowOpened = false;
    };

    $scope.$watch('loading', function() {
      if ($scope.loading === false) {
        loadDraggableFunctions();
      }
    });

    function loadDraggableFunctions() {
      loadingInterval = setInterval(function() {
        selectedElement = document.getElementById('draggable-show');
        if (selectedElement) {
          loadDragAndDropFunctions(selectedElement);
        }
      }, 100);
    }

    var draggableShowPos = sessionStorageService.getItem('draggableShowPos') ?
      JSON.parse(sessionStorageService.getItem('draggableShowPos')) :
      {
        pos1: document.documentElement.clientWidth - (platformService.isMobile() ? 160 : 225),
        pos2: document.documentElement.clientHeight - (platformService.isMobile() ? 305 : 420),
        pos3: 0,
        pos4: 0
      };

    if (attrs.showId) {
      $scope.showId = attrs.showId;
      $scope.loading = false;
    } else if (attrs.status) {
      loadFloating();
      fetchInterval = setInterval(function () {
        if ($rootScope.floatingShowOpened && !$scope.showId) {
          loadFloating();
        }
      }, 5000);
    }

    function loadFloating() {
      var term = {
        status: attrs.status,
        sort: 'createdAt'
      };
      term['hide-test-shows'] = config.environment === 'production';
      showService.query(term).then(function (data) {
        if (data && data.items && data.items.length) {
          $scope.showId = data.items[0].id;
          $scope.loading = false;
          clearInterval(fetchInterval);
        }
      });
    }

    function elementDrag(e) {
      e.preventDefault();
      draggableShowPos.pos1 = draggableShowPos.pos3 - e.clientX;
      draggableShowPos.pos2 = draggableShowPos.pos4 - e.clientY;
      draggableShowPos.pos3 = e.clientX;
      draggableShowPos.pos4 = e.clientY;
      selectedElement.style.top = (selectedElement.offsetTop - draggableShowPos.pos2) + 'px';
      selectedElement.style.left = (selectedElement.offsetLeft - draggableShowPos.pos1) + 'px';
      draggableShowPos.pos2 = selectedElement.offsetTop - draggableShowPos.pos2;
      draggableShowPos.pos1 = selectedElement.offsetLeft - draggableShowPos.pos1;
      sessionStorageService.setItem('draggableShowPos', JSON.stringify(draggableShowPos));
    }
    function dragMouseDown(e) {
      e.preventDefault();
      draggableShowPos.pos3 = e.clientX;
      draggableShowPos.pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }

    function loadDragAndDropFunctions(draggableElement) {
      if (platformService.isMobile()) {
        draggableElement.style.top = draggableShowPos.pos2 + 'px';
        draggableElement.style.left = draggableShowPos.pos1 + 'px';
        draggableElement.addEventListener('touchstart', function(e) {
          draggableShowPos.pos3 = e.touches[0].clientX;
          draggableShowPos.pos4 = e.touches[0].clientY;
        });
        draggableElement.addEventListener('touchmove', function(e) {
          e.preventDefault();
          draggableShowPos.pos1 = draggableShowPos.pos3 - e.touches[0].clientX;
          draggableShowPos.pos2 = draggableShowPos.pos4 - e.touches[0].clientY;
          draggableShowPos.pos3 = e.touches[0].clientX;
          draggableShowPos.pos4 = e.touches[0].clientY;
          draggableElement.style.top = (draggableElement.offsetTop - draggableShowPos.pos2) + 'px';
          draggableElement.style.left = (draggableElement.offsetLeft - draggableShowPos.pos1) + 'px';
          draggableShowPos.pos2 = draggableElement.offsetTop - draggableShowPos.pos2;
          draggableShowPos.pos1 = draggableElement.offsetLeft - draggableShowPos.pos1;
          sessionStorageService.setItem('draggableShowPos', JSON.stringify(draggableShowPos));
        });
        draggableElement.addEventListener('touchend', function(e) {
          draggableShowPos.pos3 = e.changedTouches[0].clientX;
          draggableShowPos.pos4 = e.changedTouches[0].clientY;
        });
      } else {
        draggableElement.style.top = draggableShowPos.pos2 + 'px';
        draggableElement.style.left = draggableShowPos.pos1 + 'px';
        draggableElement.onmousedown = dragMouseDown;
      }
      $scope.actions = true;
      if (loadingInterval) {
        clearInterval(loadingInterval);
        loadingInterval = undefined;
      }
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/show-draggable-video.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ShowDraggableVideoDirective;
