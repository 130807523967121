// @ngInject
function AdminReturnController($scope, $state, $stateParams, returnService) {

  $scope.loading = true;
  $scope.forms = {};
  loadOrder();
  $scope.loadOrder = loadOrder;
  $scope.orderType = 'return';

  $scope.backToOrder = function() {
    $state.go('admin.order', $stateParams);
  };

  function loadOrder() {
    var orderReturnPromise = $scope.orderReturnPromise = returnService.get($stateParams.returnId);
    $scope.orderReturn = $scope.order = orderReturnPromise.lazyObject();
    orderReturnPromise.then(function() {
      $scope.loading = false;
    });
  }
}

module.exports = AdminReturnController;
