/* globals $ */
// @ngInject
function SearchPreviewMobileDirective($rootScope, $state, $document) {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/search-preview-mobile.html',
    link: function($scope) {
      $scope.apply = function() {
        $rootScope.isOpenSearchMobile = false;
        document.body.style.overflow = 'auto';
      };
      $scope.status = {};
      $scope.status.openProducts = true;
      $scope.status.openDesigners = true;
      $scope.getProductHref = function(p) {
        if (p === 'orchard-mile') {
          return $state.href('root.gift-card');
        }
        if (!p.brand) {
          return false;
        }
        return $state.href('root.product.variant', {
          brand: p.brand.id,
          productName: p.slug,
          id: p.styleId,
          color: undefined,
          size: undefined
        });
      };
      $scope.clearInput = function() {
        $('.search-preview-mobile input').val('');
        $rootScope.searchPreviewItems = null;
        $rootScope.brandsFound = null;
        $rootScope.searchPreview = false;
        $scope.status.openProducts = true;
        $scope.status.openDesigners = true;
        document.body.style.overflow = 'auto';
      };

      $('#search-box').on('click', function(event) {
        event.stopPropagation();
      });
      $('#search-results').on('click', function(event) {
        event.stopPropagation();
      });

      function handleClickOutside(event) {
        if ($rootScope.isOpenSearchMobile && (event.target.alt !== 'Search')) {
          $rootScope.isOpenSearchMobile = false;
          safeUpdateView();
        }
      }

      function safeUpdateView() {
        if ($scope.$root) {
          if ($scope.$root.$$phase && ['$digest', '$apply'].includes($scope.$root.$$phase)) {
            setTimeout(function() {
              safeUpdateView();
            }, 500);
          } else {
            $scope.$digest();
          }
        }
      }

      function handleScroll() {
        $rootScope.isOpenSearchMobile = false;
      }

      $scope.$on('$destroy', function() {
        $document.off('click', handleClickOutside);
        $document.off('scroll', handleScroll);
        $('#search-results').off('click');
        $('#search-box').off('click');
      });

      $document.on('scroll', handleScroll);
      $document.on('click', handleClickOutside);

      $scope.toggle = function() {
        $rootScope.isOpenSearchMobile = !$rootScope.isOpenSearchMobile;
        document.body.style.overflow = 'auto';
        safeUpdateView();
      };
    }
  };
}

module.exports = SearchPreviewMobileDirective;
