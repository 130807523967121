var _ = require('lodash-core');

// @ngInject
function CheckoutBaseShippingOptionsController($scope, shoppingContextService, userService, config) {

  $scope.fulfillersWithItems = shoppingContextService.getFulfillers();
  $scope.orderOnline = [];
  $scope.orderFromStore = [];
  organizeShippingInStoreAndOnline(true);

  shoppingContextService.events.on('cartUpdated', function() {
    $scope.fulfillersWithItems = shoppingContextService.getFulfillers();
    organizeShippingInStoreAndOnline();
    setTimeout(updateShippingMethodSelected, 300);
  });

  $scope.$watch(function() {
      return _.map($scope.fulfillersWithItems, 'selectedShippingMethod').join(',');
    }, function() {
      _.forEach($scope.fulfillersWithItems, function(fulfiller) {
        shoppingContextService.updateFulfillerShippingMethod(fulfiller.id, fulfiller.selectedShippingMethod);
      });
      organizeShippingInStoreAndOnline(true);
    }
  );

  $scope.$watch(function() {
      return _.map($scope.fulfillersWithItems, 'selectedShippingMethodInStore').join(',');
    }, function() {
      _.forEach($scope.fulfillersWithItems, function(fulfiller) {
        shoppingContextService.updateFulfillerShippingMethod(
          fulfiller.id, fulfiller.selectedShippingMethodInStore, true
        );
      });
    }
  );

  $scope.$watch('shipping.address.country', function() {
      if ($scope.address && $scope.address.country.toLowerCase() !== 'us') {
        updateShippingMethodSelected();
      }
    }
  );

  $scope.preOrderItem = function(items) {
    return _.some(items, {preOrder: true});
  };

  $scope.getShippingOptions = function(fulfiller, inStore) {
    var specialShippingMethod = getSpecialShippingMethod(fulfiller, inStore);
    if (specialShippingMethod) {
      return [specialShippingMethod];
    } else {
      return _.filter(fulfiller.shippingMethods, function(shippingMethod) {
        return inStore ? shippingMethod.inStore : !shippingMethod.inStore && !shippingMethod.id.includes('special');
      });
    }
  };

  $scope.$watch('shipping.address.state', function() {
    setTimeout(updateShippingMethodSelected, 300);
  });

  $scope.changeOrderFrom = function(item, fulfiller) {
    if ($scope.orderOnline.indexOf(item) > -1) {
      _.remove($scope.orderOnline, item);
      $scope.orderFromStore.push(item);
      if (!fulfiller.selectedShippingMethodInStore) {
        fulfiller.selectedShippingMethodInStore = 'pick-up-in-store';
      }
      if (
        !_.find(fulfiller.items, function(fulfillerItem) {
          return $scope.orderFromStore.indexOf(fulfillerItem) === -1;
        })
      ) {
        fulfiller.selectedShippingMethod = fulfiller.selectedShippingMethodInStore;
        shoppingContextService.updateFulfillerShippingMethod(fulfiller.id, fulfiller.selectedShippingMethod);
      }
    } else {
      _.remove($scope.orderFromStore, item);
      $scope.orderOnline.push(item);
      fulfiller.selectedShippingMethod = 'ground';
    }
    if (!$scope.orderFromStore.length) {
      fulfiller.selectedShippingMethodInStore = 'pick-up-in-store';
    }
    if (!$scope.orderOnline.length) {
      fulfiller.selectedShippingMethod = 'ground';
    }
  };

  $scope.getFulfillersWithItems = function(inStore) {
    return _.filter($scope.fulfillersWithItems, function(fulfiller) {
      return _.find(fulfiller.shippingMethods, function(shippingMethod) {
        return inStore ? shippingMethod.inStore : !shippingMethod.inStore;
      }) && _.find(fulfiller.items, function(item) {
        return inStore ? $scope.orderFromStore.indexOf(item) > -1 : $scope.orderOnline.indexOf(item) > -1;
      });
    });
  };

  $scope.isAvailable = function(item, inStore) {
    return _.find($scope.fulfillersWithItems, function(fulfiller) {
      return fulfiller.items.indexOf(item) > -1 &&
        _.find(fulfiller.shippingMethods, function(shippingMethod) {
          return inStore ? shippingMethod.inStore : !shippingMethod.inStore;
        });
    });
  };

  $scope.getSelectedShippingMethodDescription = function(fulfiller) {
    if (fulfiller && fulfiller.shippingMethods && fulfiller.selectedShippingMethod) {
      return _.find(fulfiller.shippingMethods, {id: fulfiller.selectedShippingMethod}).fullDescription;
    }
    return '';
  };

  $scope.changeNewsletter = function(fulfillerId, $event) {
    if ($event.target.checked) {
      userService.subscribeTo('fulfiller-' + fulfillerId, 'checkout');
    } else {
      userService.unSubscribeTo('fulfiller-' + fulfillerId);
    }
  };

  function updateShippingMethodSelected() {
    _.forEach($scope.getFulfillersWithItems(), function(fulfiller){
      if (getSpecialShippingMethod(fulfiller)) {
        shoppingContextService.updateFulfillerShippingMethod(fulfiller.id, getSpecialShippingMethod(fulfiller).id);
      } else {
        shoppingContextService.updateFulfillerShippingMethod(fulfiller.id, fulfiller.shippingMethods[0].id);
      }
    });
  }

  function getSpecialShippingMethod(fulfiller, inStore) {
    if ($scope.shipping && ['AK', 'HI'].includes($scope.shipping.address.state) &&
      config.featureFlags.specialShipping.enabled &&
      _.some(fulfiller.shippingMethods, function(shippingMethod) {
        return [shippingMethod.id.includes('special')];
      })) {
      return _.find(fulfiller.shippingMethods, function(shippingMethod) {
        return inStore ? shippingMethod.inStore : !shippingMethod.inStore &&
          shippingMethod.id.includes('special' + $scope.shipping.address.state);
      });
    }
  }

  function organizeShippingInStoreAndOnline(initialize) {
    $scope.fulfillersWithItems.forEach(function(fulfillerWithItems) {
      fulfillerWithItems.items.forEach(function(item) {
        if (initialize || $scope.orderOnline.indexOf(item) > -1) {
          if (config.featureFlags.inStore.enabled && item.inStoreStock && item.onlineStock === 0) {
            _.remove($scope.orderOnline, item);
            _.remove($scope.orderFromStore, item);
            $scope.orderFromStore.push(item);
            if (!fulfillerWithItems.selectedShippingMethodInStore) {
              fulfillerWithItems.selectedShippingMethodInStore = 'pick-up-in-store';
            }
          } else {
            _.remove($scope.orderOnline, item);
            $scope.orderOnline.push(item);
          }
        } else {
          _.remove($scope.orderFromStore, item);
          $scope.orderFromStore.push(item);
        }
      });
    });
  }
}

module.exports = CheckoutBaseShippingOptionsController;
