// var _ = require('lodash-core');
/* jshint maxparams: 12 */

// @ngInject
function BannerInfluencersDirective(influencerService, $state, config, platformService) {

  var linker = function ($scope) {
    var configBanner = config.featureFlags.theMileInfo;
    if (configBanner.enabled) {
      influencerService.getById(configBanner.homePage.influencerId).then(function (inf) {
        var page = inf.page;
        $scope.influencerBanner = {
          name: (inf.preferredName || inf.userName),
          pageName: (inf.username || inf.preferredName),
          profileImg: page ? page.interview.videoPosterUrl : inf.profilePicture,
          heroImg: platformService.isMobile() ? page.heroImageMobile : page.heroImage,
        };
      });

      $scope.showIds = config.featureFlags.theMileInfo.bannerShowsIds;

      $scope.goToInfluencerPage = function() {
        $state.go('root.the-mile');
      };
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/banner-influencers.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = BannerInfluencersDirective;
