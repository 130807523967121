var _ = require('lodash-core');

// @ngInject
function AdminColorController($scope, $state, $stateParams, colorService,
  normalizedColorService, formsService, productService) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();
  $scope.getExampleItems = function() {
    productService.getByOriginalColor($scope.colorData.originalColor).then(function(results) {
      $scope.exampleItems = _.map(results.items, function(each) {
        return { name: each.name, image: productService.getImageUrl(each.image) };
      });
    });
  };
  $scope.reload = function() {

    var normalizedColorPromise = normalizedColorService.get({ limit: 999 });
    $scope.normalizedColors = normalizedColorPromise.lazyArray();

    var decodedId = decodeURIComponent($stateParams.id);

    if (decodedId === 'create') {
      $scope.colorData = {
        originalColor: '',
        normalizedColor: []
      };
    } else {
      var promise = colorService.getByOriginalColor(decodedId);
      $scope.colorData = promise.lazyObject();
      promise.then(function() {
        var colorData = $scope.colorData;

        colorData.originalColor = colorData.originalColor || {};

        if (colorData.normalizedColor) {
          colorData.normalizedColor = colorData.normalizedColor.join(',');
        } else {
          colorData.normalizedColor = '';
        }

        normalizedColorPromise.then(function() {
          var normalizedColors = $scope.normalizedColors;
          normalizedColors.forEach(function(each) {
            each.selected = colorData.normalizedColor.indexOf(each.normalizedColor) >= 0;
          });
          $scope.getExampleItems();
        });
      });
    }
  };

  $scope.reload();

  $scope.save = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }

    var colorData = _.cloneDeep($scope.colorData);

    colorData.normalizedColor = _.map(_.filter($scope.normalizedColors, { selected: true }), 'normalizedColor');

    formPromise.handle(colorService.save(colorData).then(function() {
      $state.go('admin.colors');
    }));
  };

  $scope.isOptionsRequired = function() {
    return !$scope.normalizedColors.some(function(options) {
      return options.selected;
    });
  };



}

module.exports = AdminColorController;
