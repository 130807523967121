/* globals $ */

// @ngInject
function HeaderLinksService(config, viewportService) {

  this.toggleDropdown = function(name) {
    if ($('.dropdown-menu' + (name ? '.' + name : '')).css('display') !== 'none') {
      $('#ssh1').css('height', config.featureFlags.saleBanner.enabled ? '224px' : '164px');
      setTimeout(function() {
        $((name ? '.' + name : '') + '.btn-group.dropdown.open').removeClass('open');
        $('.uib-dropdown-open').removeClass('uib-dropdown-open');
      }, 50);
    } else {
      $('#ssh1').css('height', config.featureFlags.saleBanner.enabled ? '350px' : '350px');
      setTimeout(function() {
        if ($('.dropdown-menu').css('display') !== 'none' || $('.dropdown-menu.open')) {
          $('.btn-group.dropdown.open').removeClass('open');
        }
        $((name ? '.' + name : '') + '.btn-group.dropdown').addClass('open');
      }, 50);
    }
  };
  this.closeOnScroll = function() {
    if (viewportService.is('xs') || viewportService.is('sm')) {
      return;
    }
    //because of consolidating header
    setTimeout(function() {
      if ($('.dropdown-menu').css('display') !== 'none' || $('.dropdown-menu.open')) {
        $('#ssh1').css('height', config.featureFlags.saleBanner.enabled ? '224px' : '111px');
        $('.btn-group.dropdown.open').removeClass('open');
        $('.uib-dropdown-open').removeClass('uib-dropdown-open');
      }
    }, 100);
  };
}

module.exports = HeaderLinksService;
