
// @ngInject
function ContentPageListItemTileDirective() {
  function linker() {
  }
  return {
    link: linker,
    templateUrl: '/views/partials/content-page-list-item-tile.html',
    restrict: 'E'
  };
}

module.exports = ContentPageListItemTileDirective;
