var _ = require('lodash-core');

// @ngInject
function ProductUserTaggedWarningDirective() {
  var linker = function($scope) {
    $scope.$watch('product.tags', function() {
      $scope.userTags = _.filter($scope.product.tags, function(tag) {
        return /^u\./.test(tag);
      });
      $scope.userTagsTooltip = $scope.userTags.join('\n').replace(/^u\./mg, '');
    });
  };
  return {
    link: linker,
    templateUrl: '/views/partials/product-user-tagged-warning.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ProductUserTaggedWarningDirective;
