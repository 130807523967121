var _ = require('lodash-core');
var emailValidation = require('shared-business-rules').validations.contact.emailCaseInsensitive;

// @ngInject
function AdminBulkInviteController($scope, userService) {
  $scope.init = function() {
    $scope.errors = [];
    $scope.success = 0;
    $scope.progress = 0;
    $scope.processing = false;
  };

  $scope.init();

  $scope.$on('csvFileChange', $scope.init);

  $scope.invite = function() {
    $scope.processing = true;
    _.forEach($scope.csvFileImporter.data, function(row) {
      row = ['', '', ''].concat(row).slice(-3); // ensure 3 columns and consider last column as the email
      var firstName = row[0].trim(), lastName = row[1].trim(), email = row[2].trim();
      if (emailValidation(email).valid) {
        userService.invite(email, { firstName: firstName, lastName: lastName, skipEmail: false }).then(
          function(response) {
            if (response.alreadyExists) {
              var msg = 'User already ' + (response.invited ? 'invited but not redeemed.' : 'exists.');
              $scope.errors.unshift({
                firstName: firstName,
                lastName: lastName,
                email: email,
                message: msg
              });
            } else {
              $scope.success++;
            }
            $scope.progress = parseInt(
              100 * ($scope.success + $scope.errors.length) / $scope.csvFileImporter.data.length
            );
          });
      } else {
        $scope.errors.unshift({
          firstName: firstName,
          lastName: lastName,
          email: email,
          message: 'is not a valid email'
        });
      }
    });
  };

}

module.exports = AdminBulkInviteController;
