var _ = require('lodash-core');

// @ngInject
function ContentThumbnailDirective() {
  var checkList = [];
  var checkListInterval;

  return {
    restrict: 'E',
    templateUrl: '/views/partials/content-thumbnail.html',
    transclude: true,
    link: function($scope, element, attrs) {
      var width = parseInt(attrs.width || '50');
      var height = parseInt(attrs.height || '50');
      element = element.find('.content-thumbnail-container');
      element.css({
        display: 'inline-block',
        overflow: 'scroll',
        width: width,
        height: height
      });
      $scope.$watch(
        function() {
          return element.html();
        },
        function() {
          addToCheckList(
            {
              width: width,
              height: height,
              container: element
            }, $scope);
        }
      );
    }
  };

  //////// private

  function addToCheckList(params, $scope) {
    var css = getTransform(1);
    css.overflow = 'scroll';
    css.visibility = 'hidden';
    params.container.css(css);

    var position = checkList.length;
    checkList.push(function() {
      if (fitContent(params)) {
        checkList[position] = null;
      }
    });
    createInterval($scope);

    $scope.$on('$destroy', function() {
      checkList[position] = null;
    });
  }

  function createInterval() {
    if (angular.isDefined(checkListInterval)) {
      return;
    }
    checkListInterval = setInterval(function() {
      var compactList = _.compact(checkList);
      if (compactList.length) {
        _.forEach(compactList, function(checkFunc) {
          checkFunc();
        });
      } else {
        // destroy interval
        checkList = [];
        clearInterval(checkListInterval);
        checkListInterval = undefined;
      }
    }, 500);
  }

  function fitContent(params) {
    var container = params.container;
    var domContainer = container[0];

    var contentSize = {
      width: domContainer.scrollWidth,
      height: domContainer.scrollHeight
    };

    if (!contentSize.width || !contentSize.height) {
      return false;
    }

    var xRatio = params.width / contentSize.width;
    var yRatio = params.height / contentSize.height;
    xRatio = xRatio > 1 ? 1 : xRatio;
    yRatio = yRatio > 1 ? 1 : yRatio;
    var ratio = parseInt(Math.min(xRatio, yRatio) * 100) / 100;

    var css = getTransform(ratio);
    css.visibility = 'visible';
    css.overflow = 'visible';
    container.css(css);
    return true;
  }

  function getTransform(ratio, extra) {
    ratio = 'scale(' + ratio + ')' + (extra || '');
    return {
      'transform': ratio,
      '-webkit-transform': ratio,
      'transform-origin': '0 0',
      '-webkit-transform-origin': '0 0'
    };
  }
}

module.exports = ContentThumbnailDirective;
