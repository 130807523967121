// @ngInject
function FileUploaderService($http, $httpParamSerializer, config) {

  this.upload = function(name, data, type, bucket) {
    if (!data) {
      return;
    }

    var formData = new FormData();
    formData.append('file', data);
    formData.append('fileType', type);
    formData.append('bucket', bucket);
    formData.append('fileName', name);

    return $http({
      method: 'POST',
        url: config.apiBaseUrl + 'files',
        headers: {
          'Content-Type': undefined
        },
      transformRequest: angular.identity,
      data: formData
    }).then(function (response) {
      return response.data;
    });
  };

}

module.exports = FileUploaderService;
