var promises = require('../../../async/promises');

function CitconProvider(config, userService, $rootScope, $timeout, apiService) {
  this.config = config;
  this.userService = userService;
  this.$rootScope = $rootScope;
  this.$timeout = $timeout;
  this.apiService = apiService;
}

CitconProvider.prototype.createPayment = function(paymentRequest) {
  return promises.extend(
    this.apiService.post('/orders/citcon-payment', {
      body: JSON.stringify(paymentRequest)
    })
  );
};

CitconProvider.prototype.inquire = function(paymentReferenceId, authKey) {
  return promises.extend(
    this.apiService.get('/orders/citcon-payment/{reference}', {
      pathParams: {
        reference: paymentReferenceId
      },
      urlParams: {
        'auth-key': authKey
      }
    })
  );
};

CitconProvider.prototype.prepareOrder = function(order) {
  if (order.shipping.contact.email) {
    order.shipping.contact.email = order.shipping.contact.email.toLowerCase();
  }

  return promises.immediate(order);
};

module.exports = CitconProvider;
