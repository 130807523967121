// @ngInject
function DropDownFlexWidthDirective($timeout) {
  var linker = function($scope, element) {
    var widthMeasure = angular.element('<span style="display:none;"></span>');
    element.after(widthMeasure);
    element.on('change', setWidth);
    $timeout(setWidth, 100);

    function setWidth() {
      widthMeasure.html(element.find('option:selected').text());
      var width = widthMeasure.width();
      if (width) {
        element.width(width + 5);
      }
    }
  };
  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = DropDownFlexWidthDirective;