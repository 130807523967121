var _ = require('lodash-core');
// @ngInject
function FooterController($scope, $rootScope) {
  $scope.getFooterLinks = function() {
    _.forEach(window.orchardMileContent.footer, function(section){
      _.remove(section, function(item){
        return $rootScope.countrySelected() !== 'us' && item.international === false;
      });
    });
    return window.orchardMileContent.footer;
  };
}

module.exports = FooterController;
