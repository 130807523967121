var _ = require('lodash-core');

// @ngInject
function AdminColorsController($scope, $state, colorService) {

  $scope.create = function() {
    $state.go('admin.color', { id: 'create', cat: 'clothing' });
  };

  $scope.checkIfNormalized = function(color) {
    return $state.hideNormalizedColors && color && color.originalColor &&
      color.normalizedColor && color.normalizedColor.length > 0;
  };

  $scope.toggleHideNormalizedColors = function() {
    $state.hideNormalizedColors = !$state.hideNormalizedColors;
  };

  $scope.fetchNormalizedColors = function(normalized) {
    $scope.clothingColors = [];
    $scope.clothingColorsPromise = colorService.get({
      limit: 25,
      sort: 'category,originalColor',
      normalized: normalized,
      clothing: true
    });

    $scope.clothingColorsPromise.then(function(cols) {
      $scope.clothingColors = _.map(cols && cols.items, function(each) {
        each.encodedOriginalColor = encodeURIComponent(each.originalColor);
        each.encodedCategory = encodeURIComponent(each.category);

        return each;
      });
    });

    $scope.otherColors = [];
    $scope.nonClothingColorsPromise = colorService.get({
      limit: 25,
      sort: 'category,originalColor',
      normalized: normalized,
      clothing: false
    });

    $scope.nonClothingColorsPromise.then(function(cols) {
      $scope.otherColors = _.map(cols && cols.items, function(each) {
        each.encodedOriginalColor = encodeURIComponent(each.originalColor);
        each.encodedCategory = encodeURIComponent(each.category);

        return each;
      });
    });
  };

  $scope.fetchNormalizedColors(false);
}

module.exports = AdminColorsController;
