var _ = require('lodash-core');
var _s = require('underscore.string');

// @ngInject
function OrderPackagesInfoDirective($state, $parse, formatService, globalizationService) {

  function formatDate(date, options) {
    if (!date) {
      return '';
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    options = options || {};
    var str;

    if (options.isLocalTime) {
      str = formatService.monthNames[date.getUTCMonth()].slice(0, 3) +
        ' ' + date.getUTCDate() + formatService.nthSuffix(date.getUTCDate());
    } else {
      str = formatService.monthNames[date.getMonth()].slice(0, 3) +
        ' ' + date.getDate() + formatService.nthSuffix(date.getDate());
    }
    if (options.time) {
      if (options.isLocalTime) {
        str += ' at ' + formatService.timeAmPm(date.getUTCHours(), date.getUTCMinutes());
      } else {
        str += ' at ' + formatService.timeAmPm(date.getHours(), date.getMinutes());
      }
    }
    return str;
  }

  var linker = function($scope, element, attrs) {
    $scope.order = attrs.order ? $parse(attrs.order)($scope) : $scope.order;

    $scope.fulfillerId = attrs.fulfillerId ? $parse(attrs.fulfillerId)($scope) : $scope.fulfillerId;
    $scope.trackingCode = attrs.trackingCode ? $parse(attrs.trackingCode)($scope) : $scope.trackingCode;
    $scope.type = attrs.type ? attrs.type : 'account';

    $scope.expandedTrackingCodes = {};

    var fulfillerProcessingPackage;
    var refundedPackage;
    var canceledPackage;

    $scope.packages = function() {
      var list = ($scope.order && $scope.order.packagesInfo) || [];
      if ($scope.trackingCode) {
        list = list.filter(function(packageInfo) {
          return packageInfo.trackingCode === $scope.trackingCode;
        });
      } else if ($scope.fulfillerId) {
        list = list.filter(function(packageInfo) {
          return packageInfo.partnerId === $scope.fulfillerId;
        });

        if (list.length < 1) {
          if (!refundedPackage) {
            var refunded = _.find($scope.order.events, { type: 'refund' });
            if (refunded) {
              refundedPackage = {
                partnerId: $scope.fulfillerId,
                status: 'refunded',
                trackingCode: $scope.fulfillerId,
                reason: (refunded.details && refunded.details.reason) ? refunded.details.reason : ''
              };
            }
          }
          if (refundedPackage) {
            list.push(refundedPackage);
            return list;
          }

          if (!canceledPackage) {
            var canceled = !!_.find($scope.order.events, function(obj) {
              if (obj.details && obj.details.title) {
                return /cancel/i.test(obj.details.title) || obj.type === 'cancel';
              } else {
                return obj.type === 'cancel';
              }
            });

            if (canceled) {
              canceledPackage = {
                partnerId: $scope.fulfillerId,
                status: 'canceled',
                trackingCode: $scope.fulfillerId
              };
            }
          }
          if (canceledPackage) {
            list.push(canceledPackage);
            return list;
          }

          if (!fulfillerProcessingPackage) {
            var processed = !!_.find($scope.order.events, function(event) {
              return event.type === 'fulfiller-order' && event.provider === $scope.fulfillerId;
            });
            fulfillerProcessingPackage = {
              partnerId: $scope.fulfillerId,
              status: processed ? 'processed' : 'processing',
              trackingCode: $scope.fulfillerId
            };

          }
          list.push(fulfillerProcessingPackage);
        }
      }
      return list;
    };

    globalizationService.setExtraOrderCurrency($scope.order);

    var standardPackageStatuses = [
      {
        name: 'Processing'
      },
      {
        name: 'Shipped'
      },
      {
        name: 'In Transit'
      },
      {
        name: 'Delivered'
      }
    ];

    $scope.previousStatuses = function(pkg) {
      if (!pkg) {
        return [];
      }
      var status = (pkg.status || '').toLowerCase().replace(/[^a-z]/g, '');
      switch (status) {
        case 'processing':
        case 'processed':
          return standardPackageStatuses.slice(0, 0);
        case 'pretransit':
        case 'intransit':
        case 'outfordelivery':
          return standardPackageStatuses.slice(0, 2);
        case 'availableforpickup':
        case 'delivered':
        case 'failure':
        case 'refunded':
        case 'cancelled':
          return standardPackageStatuses.slice(0, 3);
        default:
          return standardPackageStatuses.slice(0, 1);
      }
    };

    $scope.nextStatuses = function(pkg) {
      if (!pkg) {
        return [];
      }
      var status = (pkg.status || '').toLowerCase().replace(/[^a-z]/g, '');
      switch (status) {
        case 'processing':
        case 'processed':
          return standardPackageStatuses.slice(1);
        case 'pretransit':
        case 'intransit':
        case 'outfordelivery':
          return standardPackageStatuses.slice(3);
        case 'availableforpickup':
        case 'delivered':
        case 'failure':
        case 'refunded':
        case 'cancelled':
          return [];
        default:
          return standardPackageStatuses.slice(2);
      }
    };

    $scope.packageTrackingCodeShort = function(pkg) {
      var code = pkg && pkg.trackingCode !== pkg.partnerId && pkg.trackingCode;
      if (!code || code.length <= 4) {
        return code || '';
      }
      return '...' + code.slice(code.length - 4);
    };

    $scope.openContent = function (content) {
      if (content.type.toLowerCase().includes('show')) {
        $state.go('admin.show', {id: content.id});
      }
      if (content.type.toLowerCase().includes('reel')) {
        $state.go('admin.reel', {id: content.id});
      }
    };

    $scope.packageStatusTitle = function(pkg) {
      var status = pkg.status || 'shipped';
      status = _s.titleize(status.replace(/[_\-]+/g, ' '));
      return status;
    };

    $scope.packageStatusSubtitle = function(pkg) {
      var lastDetail = pkg && pkg.trackingDetails &&
        pkg.trackingDetails[pkg.trackingDetails.length - 1];
      var date = (lastDetail && lastDetail.datetime) || pkg.updatedAt;
      if (!date && pkg.status === 'processed') {
        return 'waiting shipping confirmation';
      }

      if (!date && pkg.status === 'refunded') {
        return pkg.reason;
      }
      return formatDate(date);
    };

    $scope.calculateInternationalItemPrice = function(price) {
      if ($scope.order.totals.international) {
        var ratio = $scope.order.totals.international.totalPrice / $scope.order.totals.totalPrice;
        return ratio*price;
      } else {
        return price;
      }
    };

    $scope.getNextStatusTitle = function(pkg, status) {
      if (status.name === 'Delivered' && pkg.etaHuman) {
        return 'Arrives';
      }
      return status.title;
    };

    $scope.getNextStatusSubtitle = function(pkg, status) {
      if (status.name === 'Delivered' && pkg.etaHuman) {
        return pkg.etaHuman;
      }
      return status.subtitle;
    };

    $scope.packageStatusClass = function(pkg) {
      if (!pkg || !pkg.status) {
        return '';
      }
      return 'package-status-' + pkg.status.toLowerCase().replace(/[^a-z0-9]/gi, '-');
    };

    $scope.trackingDetailDateTime = function(trackingDetail) {
      var isLocalTime = trackingDetail.source !== 'FedEx';
      return formatDate(trackingDetail.datetime, {
        time: true,
        isLocalTime: isLocalTime
      });
    };

    $scope.trackingDetailLocation = function(trackingDetail) {
      var location = trackingDetail && trackingDetail.trackingLocation;
      if (!location) {
        return '';
      }
      return _.compact([
        location.city, location.state, location.country, location.zip
      ]).join(', ');
    };

    $scope.toggle = function(pkg) {
      if (!pkg || !pkg.trackingCode) {
        return;
      }
      if ($scope.expandedTrackingCodes[pkg.trackingCode]) {
        $scope.expandedTrackingCodes[pkg.trackingCode] = false;
        return;
      }
      $scope.expandedTrackingCodes[pkg.trackingCode] = true;
    };

  };

  return {
    link: linker,
    templateUrl: '/views/partials/order-packages-info.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = OrderPackagesInfoDirective;
