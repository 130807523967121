var promises = require('../../async/promises');
var _ = require('lodash-core');

// @ngInject
function TaxService(apiService) {
  this.getTaxRate = _.memoize(function(zip, state) {
    return promises.extend(apiService.get('/taxes/{state}/{zip}', {
      pathParams: {
        state: state,
        zip: zip
      }
    }));
  }, function(zip, state) {
    return zip + state;
  });

  this.getTaxRateForOrder = function(order) {
    return promises.extend(apiService.post('/taxes', { body: order }));
  };
}

module.exports = TaxService;
