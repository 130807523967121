var _ = require('lodash-core');

/* jshint maxparams: 13 */
// @ngInject
function UserPanelController($scope, $state, $uibModal, $log, userService, cartService, $rootScope,
  $location, config, headerLinksService, loyaltyExperienceService, platformService) {

  $scope.openSignup = function(size) {
    /* Close others modals before open */
    $rootScope.closeModals();
    var modalInstance = $uibModal.open({
      templateUrl: '/views/partials/signup.html',
      controller: 'SignupModalInstanceController',
      size: size,
      resolve: {
      }
    });

    $rootScope.addModalToStack();
    modalInstance.result.finally($rootScope.removeModalFromStack);

    modalInstance.result.then(function() {
    }, function() {
      $log.info('Modal dismissed at: ' + new Date());
    });
  };
  $scope.toggleDropdown = function($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    headerLinksService.toggleDropdown(name);
  };
  $scope.openLogin = function(size) {
    /* Close others modals before open */
    $rootScope.closeModals();
    var modalInstance = $uibModal.open({
      templateUrl: '/views/partials/login.html',
      controller: 'LoginModalInstanceController',
      size: size,
      resolve: {
      }
    });

    $rootScope.addModalToStack();
    modalInstance.result.finally($rootScope.removeModalFromStack);

    modalInstance.result.then(function() {
    }, function() {
      $log.info('Modal dismissed at: ' + new Date());
    });
  };

  $scope.isLoggedIn = userService.isLoggedIn;
  $scope.getUser = userService.getUser;
  $scope.user = $rootScope.getUser();
  $scope.logout = userService.logout;

  $scope.allowSignup = !config.privateAccess;
  var consolidateLinksActions = function() {
    if ($scope.getUser() && !$scope.getUser().anonymous) {
      if (!$rootScope.getLoyalty) {
        var loyaltyPromise = userService.getLoyalty();
        $rootScope.getLoyalty = true;
        loyaltyPromise.then(function(loyalty) {
          $rootScope.loyalty = loyalty;
          $scope.tier = loyaltyExperienceService.getTierName(loyalty.tier);
        });
      }
    }
  };
  consolidateLinksActions();

  //When to check points for the header
  userService.events.on('loggedIn', function() {
    consolidateLinksActions();
  });
  userService.events.on('redeemPoints', function() {
    $rootScope.getLoyalty = false;
    consolidateLinksActions();
  });
  userService.events.on('checkAwardPoints', function() {
    $rootScope.getLoyalty = false;
    consolidateLinksActions();
  });
  userService.events.on('loggedOut', function() {
    $scope.user = null;
    $rootScope.getLoyalty = false;
  });
  // classes is optional if u want to add classes from other place
  $rootScope.bodyClassesProviders.userClassesProvider = function() {
    var classes = [];
    var user = userService.getUser();
    if (config.privateAccess) {
      classes.push('private-access');
    }
    if (!user || user.anonymous) {
      classes.push('user-is-anonymous');
    } else if (user.guest) {
      classes.push('user-is-guest');
    } else if (user.guest) {
      classes.push('user-authenticated');
    }
    if (user && !user.anonymous) {
      classes.push('user-logged');
      classes = classes.concat(_.map(user && user.roles || [], function(role) {
        return 'user-has-role-' + role;
      }));
    }
    return classes;
  };
  $scope.cartData = cartService.getData();
  $scope.cartTotals = cartService.getTotals();
  var getNotForLaterItems = function() {
    return $scope.cartData.items.filter(function(item) {
      return !cartService.isForLater(item);
    });
  };
  $rootScope.getCartQty = function() {
    var qty = 0;
    getNotForLaterItems().forEach(function(item) {
      qty += item.quantity;
    });
    return qty;
  };

  var getForLaterItems = function() {
    return $scope.cartData.items.filter(function(item) {
      return cartService.isForLater(item);
    });
  };
  $scope.getForLaterQty = function() {
    var qty = 0;
    getForLaterItems().forEach(function(item) {
      qty += item.quantity;
    });
    return qty;
  };
  $scope.goToOrderHistory = function() {
    if ($state.current.name !== 'root.account.summary') {
      $state.go('root.account.summary');
      setTimeout(function() {
        $location.hash('order-history');
      }, 300);
    } else {
      $location.hash('order-history');
    }
  };
  $rootScope.openSearchInput = function(){
    if (!platformService.isMobile()) {
      $rootScope.searchInput = !$rootScope.searchInput;
      if ($rootScope.searchInput) {
        if (document.querySelector('input[role="search"]')) {
          setTimeout(function () {
            document.querySelector('input[role="search"]').focus();
          }, 100);
        }
      }
    } else {
      $rootScope.showSearchPreviewMobile();
    }
  };
}

module.exports = UserPanelController;
