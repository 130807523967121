var _ = require('lodash-core');
// @ngInject
function ProductSortSelectorMobileDirective($state, $stateParams) {
  var linker = function($scope, element, attrs) {
    $scope.options = [
      { id: 'price-asc', text: 'Price low to high' },
      { id: 'price-desc', text: 'Price high to low' },
      { id: 'newest', text: 'Newest' },
      { id: 'scores-bestselling', text: 'Best selling' },
      { id: 'scores-popular', text: 'Popularity' }
    ];
    $scope.defaultText = attrs.defaultText || 'Newest';
    $scope.sort.criteria = $stateParams.sort || '';
    $scope.isOpen = false;

    $scope.toggle = function() {
      $scope.isOpen = !$scope.isOpen;
    };

    $scope.selectSortCriteria = function(criteria) {
      $scope.sort.criteria = criteria;
      $scope.isOpen = false;
    };

    $scope.getSelectedCriteriaText = function() {
      var option = _.find($scope.options, { id: $scope.sort.criteria });
      return option && option.text || $scope.defaultText;
    };

  };
  return {
    link: linker,
    templateUrl: '/views/partials/product-sort-selector-mobile.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ProductSortSelectorMobileDirective;
