// @ngInject
function AdminStatusController($scope, config, statusService) {
  $scope.ready = false;
  $scope.config = config;

  statusService.getApiInfo().then(function(info) {
    $scope.apiInfo = info;
    $scope.ready = true;
  });

  $scope.throwJsError = function() {
    $scope.logError('---------------------------------------------------');
    $scope.logError('Throwing JS error... (look at the console)');
    // this triggers a javascript error to test error notifications
    function throwFromAnotherFunc(param) {
      // param will be undefined
      param.property = 1;
    }
    throwFromAnotherFunc();
    $scope.logError('This shouldn\'t be seen on the error log');
  };

  $scope.checkApiError = function() {
    $scope.logError('---------------------------------------------------');
    $scope.logError('Checking API error...');
    statusService.getError(500)
      .then(function() {
        $scope.logError('Server returned a successful response (this shouldn\'t happen)');
      }).catch(function(error) {
        $scope.logError('Server returned ' + error.status + ' ' + error.statusText);
        $scope.logError('Response:\n' + JSON.stringify(error.data.error, null, 4));
      });
  };

  $scope.logError = function(error) {
    $scope.error = $scope.error || '';
    $scope.error += error + '\n';
  };
}

module.exports = AdminStatusController;
