var _ = require('lodash-core');
var categories = require('shared-business-rules').categories.getArray();
var categoryRegex = '(?:' + ['whats-new'].concat(_.map(categories, 'key')).join('|') + ')';

function config(app) {

  // use brand list from the content repo
  var brands = window.orchardMileContent.brands;
  if (!Array.isArray(brands)) {
    brands = _.map(brands, 'id');
  }
  var brandRegex = '(?:' + brands.join('|') + ')';

  // @ngInject
  function configRoutes($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {

    // $urlMatcherFactory.caseInsensitive(true); didn't work
    $urlRouterProvider.rule(function($injector, $location) {
      //what this function returns will be set as the $location.url
      var path = $location.path(), normalized = path.toLowerCase();
      if (path !== normalized) {
        // instead of returning a new url string, I'll just change the $location.path directly so I don't have to worry
        // about constructing a new url string and so a new state change is not triggered
        $location.replace().path(normalized);
      }
      // because we've returned nothing, no state change occurs
    });

    $urlRouterProvider.otherwise('/');

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });


    function valToString(val) {
      return val !== null ? val.toString() : val;
    }
// 'occasions', 'edits', 'inspiration', 'mothers-day-gifts-2022',
// 'memorial-day-sales-2022','black-friday-sales-2022','holiday-gift-guide-2022'
    var staticPagesGroups = ['holiday', 'terms-of-use', 'terms-conditions',
      'accessibility', 'privacy-policy', 'the-mile-privacy-policy', 'holidaygiftguide2024',
      'cyber-week', 'faq'];

    $urlMatcherFactoryProvider.type('nonURIEncoded', {
      encode: valToString,
      decode: valToString,
      is: function(relativeUrl) {
        return staticPagesGroups.indexOf(relativeUrl.split('/')[0]) > -1;
      }
    });

    $stateProvider
      .state({
        name: 'root',
        abstract: true,
        url: '',
        templateUrl: '/views/root.html'
      })
      .state({
        name: 'root.home',
        url: '/?utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        templateUrl: '/views/home-container.html'
      })
      .state({
        name: 'root.varanaworld',
        url: '/varana-world',
        onEnter: function() {
          window.location = '/varana';
        }
      })
      .state({
        name: 'root.top-redirect',
        url: '/top',
        onEnter: function() {
          window.location = '/';
        }
      })
      .state({
        name: 'root.wishlist',
        url: '/wishlist',
        templateUrl: '/views/wishlist.html',
        controller: 'WishlistController'
      })
      // -- SEARCH --
      .state({
        name: 'root.search',
        url: '/search?term,page,sort,sale,utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        templateUrl: '/views/search.html'
      })
      .state({
        name: 'root.search.category1',
        url: '/{category1:' + categoryRegex + '}'
      })
      .state({
        name: 'root.search.category2',
        url: '/{category1:' + categoryRegex + '}/:category2'
      })
      .state({
        name: 'root.search.category3',
        url: '/{category1:' + categoryRegex + '}/:category2/:category3'
      })
      .state({
        name: 'root.invite',
        url: '/invite',
        templateUrl: '/views/invite.html'
      })
      .state({
        name: 'root.inviteRedeem',
        url: '/invite/redeem?email&code&referrer',
        templateUrl: '/views/invite-redeem.html'
      })
      .state({
        name: 'root.passwordReset',
        url: '/password-reset?email&code',
        templateUrl: '/views/password-reset.html'
      })
      .state({
        name: 'root.brands',
        url: '/designers?sale,utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        templateUrl: '/views/brands.html'
      })
      .state({
        name: 'root.occasions',
        url: '/occasions?utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        templateUrl: '/views/occasions.html'
      })
      .state({
        name: 'root.brands.group',
        url: '/:group'
      })
      .state({
        name: 'root.contact',
        url: '/contact',
        templateUrl: '/views/contact.html'
      })
      .state({
        name: 'root.style-guide',
        url: '/style-guide/:section',
        templateUrl: '/views/style-guide.html',
        params: {
          section: {value: null, squash: true}
        }
      })
      // -- ERROR PAGES --
      .state({
        name: 'root.error',
        url: '/error/:status?categories&oos-style-id&forbidden-country&coming-from',
        templateUrl: '/views/error-page.html'
      })
      // -- STORIES --
      .state({
        name: 'root.stories',
        url: '/editorial?utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        templateUrl: '/views/stories.html'
      })
      .state({
        name: 'root.stories.category',
        url: '/:category'
      })
      // -- FOR SEO Preserve links --
      .state({
        name: 'root.oldstories',
        url: '/stories?utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        templateUrl: '/views/stories.html',
        redirectTo: 'root.stories'
      })
      .state({
        name: 'root.oldstories.category',
        url: '/:category',
        redirectTo: 'root.stories.category'
      })
      // -- SHOP --
      .state({
        name: 'root.shop',
        url: '?page,sort,sale,sizes,occasion,min-price,max-price,inStore,error404,forbidden-country,oos-style-id' +
          ',utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID,coming-from',
        templateUrl: '/views/shop.html'
      })
      // -- FOR SEO Preserve links --
      .state({
        name: 'root.shop.whats-new',
        url: '/whats-new',
        trackView: true,
        redirectTo: 'root.shop.just-in'
      })
      .state({
        name: 'root.shop.just-in',
        url: '/just-in',
        params: {
          category1: 'whats-new',
          brands: []
        },
        trackView: true
      })
      .state({
        name: 'root.shop.category1',
        url: '/{category1:' + categoryRegex + '}',
        trackView: true
      })
      .state({
        name: 'root.shop.category2',
        url: '/{category1:' + categoryRegex + '}/:category2',
        trackView: true
      })
      .state({
        name: 'root.shop.category3',
        url: '/{category1:' + categoryRegex + '}/:category2/:category3',
        trackView: true
      })
      // -- SALE --
      .state({
        name: 'root.sale',
        url: '/sale?page,sort,sizes,discount,utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID,sc',
        templateUrl: '/views/sale.html'
      })
      .state({
        name: 'root.saleGrid',
        url: '/sales?utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        redirectTo: 'root.sale'
      })
      .state({
        name: 'root.sale.category1',
        url: '/{category1:' + categoryRegex + '}',
        trackView: true
      })
      .state({
        name: 'root.sale.category2',
        url: '/{category1:' + categoryRegex + '}/:category2',
        trackView: true
      })
      .state({
        name: 'root.sale.category3',
        url: '/{category1:' + categoryRegex + '}/:category2/:category3',
        trackView: true
      })
      // -- GIFT --
      .state({
        name: 'root.gifts',
        url: '/gifts?page,sort',
        templateUrl: '/views/gifts.html'
      })
      .state({
        name: 'root.gifts.group',
        url: '/{group}'
      })
      // -- EMAIL VALIDATION SECTION --
      .state({
        name: 'root.validate-email',
        url: '/validate-email?email,code',
        templateUrl: '/views/validate-email.html'
      })
      // -- OM REWARDS --
      .state({
        name: 'root.omRewards',
        url: '/om-rewards',
        templateUrl: '/views/loyalty.html'
      })
      // -- SWEEPSTAKES --
      .state({
        name: 'root.sweepstakes',
        url: '/sweepstakes?showterms',
        templateUrl: '/views/sweepstakes.html'
      })
      // -- INFLUENCERS --
      .state({
        name: 'root.influencers',
        url: '/sweepstakes',
        templateUrl: '/views/sweepstakes.html'
      })
      // -- ACCOUNT --
      .state({
        name: 'root.account',
        url: '/account',
        templateUrl: '/views/account.html'
      })
      .state({
        name: 'root.account.summary',
        url: '/summary',
        templateUrl: '/views/partials/account/summary.html'
      })
      .state({
        name: 'root.account.order',
        url: '/orders/:orderNumber?token',
        templateUrl: '/views/partials/account/order.html'
      })

      // -- BRAND PRODUCTS --
      .state({
        name: 'root.brand-products',
        url: '/{brand:' + brandRegex + '}?page,sort,sale,sizes,designers,min-price,inStore,' +
          'max-price,utm_medium,utm_source,utm_campaign,ranMID,ranEAID,ranSiteID',
        trackView: true,
        templateUrl: '/views/brand-products.html'
      })
      .state({
        name: 'root.brand-products.category1',
        url: '/{category1:' + categoryRegex + '}',
        trackView: true
      })
      .state({
        name: 'root.brand-products.category2',
        url: '/{category1:' + categoryRegex + '}/:category2',
        trackView: true
      })
      .state({
        name: 'root.brand-products.category3',
        url: '/{category1:' + categoryRegex + '}/:category2/:category3?',
        trackView: true
      })

      // -- PRODUCT DETAILS --
      .state({
        name: 'root.product',
        abstract: true,
        url: '/{brand:' + brandRegex + '}',
        templateUrl: '/views/product-details.html'
      })
      .state({
        name: 'root.product.variant',
        url: '/:productName-{id:[a-z0-9]{2,3}[a-f0-9]{8}}?color&size&shopnow&gclid&utm_medium&utm_source' +
          '&utm_campaign&glcountry&comingfrom&forceview&review',
        trackView: true
      })
      .state({
        name: 'root.product-permalink',
        url: '/p/{id:[a-z0-9]{2,3}[a-f0-9]{8}}',
        controller: 'ProductPermalinkController'
      })
      .state({
        name: 'root.product-by-source',
        url: '/products/by-source/{url:.+}',
        templateUrl: '/views/product-by-source.html'
      });

    // -- THE MILE --

    $stateProvider
      .state({
        name: 'root.the-mile',
        url: '/the-mile?show',
        templateUrl: '/views/the-mile.html',
        reloadOnSearch: false,
        params: {
          show: {
            value: null,
            squash: true
          }
        }
      })
      .state({
        name: 'root.influencer',
        url: '/the-mile/:influencer?show#top',
        templateUrl: '/views/influencer.html',
        reloadOnSearch: false,
        params: {
          show: {
            value: null,
            squash: true
          }
        }
      })
      .state({
        name: 'root.giveaway-rules',
        url: '/the-mile/giveaway-rules',
        templateUrl: '/views/giveaway-rules.html'
      })
      .state({
        name: 'root.christie-giveaway-rules',
        url: '/the-mile/christie-giveaway-rules',
        templateUrl: '/views/christie-giveaway-rules.html'
      });

    // -- SHOPRUNNER --
    $stateProvider
      .state({
        name: 'sr',
        url: '/shop-runner-login?srtoken',
        templateUrl: '/views/shop-runner-login.html',
        controller: 'ShopRunnerController'
      });

    // -- CHECKOUT --
    $stateProvider
      .state({
        name: 'root.checkout',
        url: '/checkout',
        templateUrl: '/views/checkout-base.html'
      })
      .state({
        name: 'root.checkout.shipping',
        url: '/shipping?pending-order-payment-intent,payment-provider'
      })
      .state({
        name: 'root.checkout.billing',
        url: '/billing?payment-provider'
      })
      .state({
        name: 'root.checkout.review',
        url: '/review?payment-provider'
      })
      .state({
        name: 'root.checkout.pre-confirmation',
        url: '/pre-confirmation?payment_intent,payment_intent_client_secret,payerid,token,provider'
      })
      .state({
        name: 'root.checkout.confirmation',
        url: '/confirmation'
      });
    // -- SHOP ON MODAL CART --
    $stateProvider
      .state({
        name: 'root.cart-page',
        url: '/cart-page',
        templateUrl: '/views/cart-page.html'
      });

    // -- ADMIN --
    $stateProvider
      .state({
        name: 'admin',
        url: '/admin',
        controller: 'AdminController',
        templateUrl: '/views/admin/root.html'
      })
      .state({
        name: 'admin.users',
        url: '/users?term,recency,frequency,monetary',
        controller: 'AdminUsersController',
        templateUrl: '/views/admin/users.html'
      })
      .state({
        name: 'admin.user',
        url: '/users/:id',
        controller: 'AdminUserController',
        templateUrl: '/views/admin/user.html'
      })
      .state({
        name: 'admin.promos',
        url: '/promos',
        controller: 'AdminPromosController',
        templateUrl: '/views/admin/promos.html'
      })
      .state({
        name: 'admin.promo',
        url: '/promos/:id',
        controller: 'AdminPromoController',
        templateUrl: '/views/admin/promo.html'
      })
      .state({
        name: 'admin.bulk-tag-products',
        url: '/bulk-tag-products',
        controller: 'AdminBulkTagProductsController',
        templateUrl: '/views/admin/bulk-tag-products.html'
      })
      .state({
        name: 'admin.experiences',
        url: '/experiences',
        controller: 'AdminExperiencesController',
        templateUrl: '/views/admin/experiences.html'
      })
      .state({
        name: 'admin.experience',
        url: '/experiences/:id',
        controller: 'AdminExperienceController',
        templateUrl: '/views/admin/experience.html'
      })
      .state({
        name: 'admin.loyalty',
        url: '/loyalty/:userId',
        controller: 'AdminLoyaltyUserController',
        templateUrl: '/views/admin/loyalty-user.html'
      })
      .state({
        name: 'admin.colors',
        url: '/colors',
        controller: 'AdminColorsController',
        templateUrl: '/views/admin/colors.html'
      })
      .state({
        name: 'admin.color',
        url: '/colors/:id/:cat',
        controller: 'AdminColorController',
        templateUrl: '/views/admin/color.html'
      })
      .state({
        name: 'admin.related-brands',
        url: '/related-brands',
        controller: 'AdminRelatedBrandsController',
        templateUrl: '/views/admin/related-brands.html'
      })
      .state({
        name: 'admin.related-brand',
        url: '/related-brand/:id/:name',
        controller: 'AdminRelatedBrandController',
        templateUrl: '/views/admin/related-brand.html'
      })
      .state({
        name: 'admin.product-rankings',
        url: '/product-rankings',
        controller: 'AdminProductRankingsController',
        templateUrl: '/views/admin/product-rankings.html'
      })
      .state({
        name: 'admin.product',
        url: '/products/:id?color',
        controller: 'AdminProductController',
        templateUrl: '/views/admin/product.html'
      })
      .state({
        name: 'admin.orders',
        url: '/orders?search',
        controller: 'AdminOrdersController',
        templateUrl: '/views/admin/orders.html'
      })
      .state({
        name: 'admin.influencers',
        url: '/influencers?term',
        controller: 'AdminInfluencersController',
        templateUrl: '/views/admin/influencers.html'
      })
      .state({
        name: 'admin.earnings',
        url: '/earnings?term,show,from,to,type',
        controller: 'AdminEarningsController',
        templateUrl: '/views/admin/earnings.html'
      })
      .state({
        name: 'admin.earning',
        url: '/earnings/:id?show,from,to,type',
        controller: 'AdminEarningsController',
        templateUrl: '/views/admin/earnings.html'
      })
      .state({
        name: 'admin.onboardings',
        url: '/onboardings?term',
        controller: 'AdminOnboardingsController',
        templateUrl: '/views/admin/onboardings.html'
      })
      .state({
        name: 'admin.onboarding',
        url: '/onboardings/:id',
        controller: 'AdminOnboardingsController',
        templateUrl: '/views/admin/onboarding.html'
      })
      .state({
        name: 'admin.shows',
        url: '/shows?term,status',
        controller: 'AdminShowsController',
        templateUrl: '/views/admin/shows.html'
      })
      .state({
        name: 'admin.show',
        url: '/shows/:id',
        controller: 'AdminShowController',
        templateUrl: '/views/admin/show.html'
      })
      .state({
        name: 'admin.reels',
        url: '/reels?term,status',
        controller: 'AdminReelsController',
        templateUrl: '/views/admin/reels.html'
      })
      .state({
        name: 'admin.reel',
        url: '/reels/:id',
        controller: 'AdminReelController',
        templateUrl: '/views/admin/reel.html'
      })
      .state({
        name: 'admin.dueDateOrders',
        url: '/orders?dueDate',
        controller: 'AdminDueDateOrdersController',
        templateUrl: '/views/admin/dueDateOrders.html'
      })
      .state({
        name: 'admin.order',
        url: '/orders/:id?refund',
        controller: 'AdminOrderController',
        templateUrl: '/views/admin/order.html'
      })
      .state({
        name: 'admin.order-return',
        url: '/orders/:id/returns/:returnId',
        controller: 'AdminReturnController',
        templateUrl: '/views/admin/return.html'
      })
      .state({
        name: 'admin.brands',
        url: '/designers/:id',
        controller: 'AdminBrandController',
        templateUrl: '/views/admin/brand.html'
      })
      .state({
        name: 'admin.fulfiller-orders',
        url: '/fulfillers/orders',
        controller: 'AdminFulfillerOrdersController',
        templateUrl: '/views/admin/fulfiller-orders.html'
      })
      .state({
        name: 'admin.returns',
        url: '/returns',
        controller: 'AdminReturnsController',
        templateUrl: '/views/admin/returns.html'
      })
      .state({
        name: 'admin.refunds',
        url: '/refunds',
        controller: 'AdminRefundsController',
        templateUrl: '/views/admin/refunds.html'
      })
      .state({
        name: 'admin.withdraws',
        url: '/withdraws?term',
        controller: 'AdminWithdrawController',
        templateUrl: '/views/admin/withdraws.html'
      })
      .state({
        name: 'admin.withdraw',
        url: '/withdraws/:userId/:id',
        controller: 'AdminWithdrawController',
        templateUrl: '/views/admin/withdraw.html'
      })
      .state({
        name: 'admin.unmatched-refunds',
        url: '/unmatched-refunds',
        controller: 'AdminUnmatchedRefundsController',
        templateUrl: '/views/admin/unmatched-refunds.html'
      })
      .state({
        name: 'admin.fulfiller-newsletter',
        url: '/fulfillers/newsletter',
        controller: 'AdminFulfillerNewsletterController',
        templateUrl: '/views/admin/fulfiller-newsletter.html'
      })
      .state({
        name: 'admin.invite',
        url: '/invite?email',
        controller: 'AdminInviteController',
        templateUrl: '/views/admin/invite.html'
      })
      .state({
        name: 'admin.bulk-invite',
        url: '/bulk-invite?email',
        controller: 'AdminBulkInviteController',
        templateUrl: '/views/admin/bulk-invite.html'
      })
      .state({
        name: 'admin.status',
        url: '/status',
        controller: 'AdminStatusController',
        templateUrl: '/views/admin/status.html'
      })
      .state({
        name: 'admin.partners',
        url: '/partners',
        controller: 'AdminPartnersController',
        templateUrl: '/views/admin/partners.html'
      })
      .state({
        name: 'admin.partners-commission',
        url: '/partners/:id',
        controller: 'AdminPartnersCommissionController',
        templateUrl: '/views/admin/partners-commission.html'
      })
      .state({
        name: 'admin.partner-pages',
        url: '/partner-pages',
        controller: 'AdminPartnerPagesController',
        templateUrl: '/views/admin/partner-pages.html'
      })
      .state({
        name: 'admin.partner-page',
        url: '/partner-pages/:id',
        controller: 'AdminPartnerPageController',
        templateUrl: '/views/admin/partner-page.html'
      })
      .state({
        name: 'admin.brand-page',
        url: '/partner-pages/:partnerId/:brandId',
        controller: 'AdminBrandPageController',
        templateUrl: '/views/admin/brand-page.html'
      })
      .state({
        name: 'admin.partners-nexus',
        url: '/partners-nexus',
        controller: 'AdminPartnersNexusController',
        templateUrl: '/views/admin/partners-nexus.html'
      })
      .state({
        name: 'admin.partner-nexus',
        url: '/partner-nexus/:id',
        controller: 'AdminPartnerNexusController',
        templateUrl: '/views/admin/partner-nexus.html'
      })
      .state({
        name: 'root.unsubscribe',
        url: '/unsubscribe?email&topic',
        templateUrl: '/views/unsubscribe.html'
      })
      // -- GIFT CARDS --
      .state({
        name: 'root.gift-card',
        url: '/gift-card',
        templateUrl: '/views/gift-card.html'
      })
      // -- SHIPPING & RETURNS --
      .state({
        name: 'root.shipping-and-returns',
        url: '/shipping-and-returns',
        templateUrl: '/views/shipping-and-returns.html'
      })
      // -- SHOWS --
      .state({
        name: 'root.show',
        url: '/shows/:showId',
        templateUrl: '/views/show.html'
      })
      // -- PAGES --
      .state({
        name: 'root.pages',
        url:
          '/{name:nonURIEncoded}?page&create&edit&popup&sizes' +
          '&colors&sort&sale&designers&category1&category2&category3' +
          '&utm_medium&utm_source&utm_campaign&_bta_tid&contact_eid&plp_id',
        templateUrl: '/views/static-page.html'
      });
  }

  app.config(configRoutes);
}

exports.config = config;
