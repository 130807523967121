// @ngInject
function ShopRunnerController($scope, $stateParams, apiService, $cookies) {

  if ($stateParams.srtoken) {
    apiService.post('/shop-runners/token', {
      urlParams: {
        'access_token': $stateParams.srtoken
      }
    }).then(function(result) {
      $scope.response = result;
      if (result.validationResult) {
        var expiresValue = new Date();
        expiresValue.setMinutes(expiresValue.getMinutes() + 60);
        /*jshint sub:true*/
        $cookies.put('sr_token', $stateParams.srtoken, { expires: expiresValue });
      } else {
        $cookies.remove('sr_token');
      }
    });
  }

}

module.exports = ShopRunnerController;
