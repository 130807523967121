
// @ngInject
function SearchController($scope, listContextService, metadataService) {
  listContextService.setScopeContext($scope);
  $scope.productListName = 'search';
  $scope.brandsFound = [];
  metadataService.setRobots('noindex');
  document.body.style.overflow = 'auto';

  $scope.$on('$destroy', function() {
    metadataService.setRobots('index,follow');
  });
}

module.exports = SearchController;
