// @ngInject
function SignupModalController($scope, userService, formsService, analyticsService) {

  $scope.forms = {};
  $scope.inputs = {};
  $scope.inputs.email = $scope.modal.instanceData.options.email || '';
  $scope.inputs.firstName = $scope.modal.instanceData.options.firstName || '';
  $scope.inputs.lastName = $scope.modal.instanceData.options.lastName || '';
  $scope.inputs.password = '';
  $scope.inputs.repassword = '';

  formsService.focusFirstField();

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.doSignUp = function() {

    if (!formPromise.validate($scope.forms.register)) {
      return;
    }

    formPromise.handle(userService.signup({
      email: $scope.inputs.email,
      password: $scope.inputs.password,
      firstName: $scope.inputs.firstName,
      lastName: $scope.inputs.lastName,
      transactional: $scope.inputs.transactional,
      trigger: $scope.modal.instanceData.options.trigger
    })).then(function() {
      if ($scope.modal.instanceData && $scope.modal.instanceData.options && $scope.modal.instanceData.options.trigger) {
        if ($scope.modal.instanceData.trigger.indexOf('authentication-modal') >= 0) {
          analyticsService.track('Signed in through Authentication Modal');
        } else if ($scope.modal.instanceData.trigger.indexOf('header') >= 0) {
          analyticsService.track('Signed in through Side Bar');
        }
      }

      $scope.modal.hide();
    });
  };

  $scope.sendInvite = function() {
    $scope.sendingInvite = true;
    userService.invite($scope.inputs.email).then(function() {
      $scope.sendingInvite = false;
      $scope.inviteSent = true;
    }, function() {
      $scope.sendingInvite = false;
    });
  };

  userService.events.on('loggedIn', function() {
    // this will trigger if user used the FB sign in
    if ($scope.modal) {
      $scope.modal.hide();
    }
  });
}

module.exports = SignupModalController;
