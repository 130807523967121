var format = require('shared-business-rules').format;

// @ngInject
function CurrencyCentsMicrodataFilter($sce, $rootScope) {

  var filter = function(input) {
    return $sce.trustAsHtml(format.formatCurrencyCents(input, {
      microData: true,
      country: $rootScope.country,
      currency: $rootScope.currency
    }));
  };

  filter.$stateful = true;

  return filter;
}

module.exports = CurrencyCentsMicrodataFilter;
