// @ngInject
function NormalizedColorFilterController($scope, normalizedColorService) {

  normalizedColorService.get({
    limit: 999
  }).then(function(res) {
    $scope.normalizedColors = res;
  });

}

module.exports = NormalizedColorFilterController;
