/* jshint maxparams: 12 */

// @ngInject
function ShowsCarouselDirective(showService, influencerService, $uibModal, $state, formatService, config,
                                modalShowService, platformService, translationService) {

  var titleTranslations = {
    results: 'Results',
    live: 'Live Now',
    trending: 'Trending Shows',
    related: 'Related Shows',
    whats: 'What\'s New',
    orchard: 'Style in Motion',
    future: 'Future Shows',
    'daily-looks': 'Daily Looks',
    'date-night-vibes': 'Date Night Vibes',
    'grwm': 'GRWM',
    'vacation-chic': 'Vacation Chic',
    'mane-styles': 'Mane Styles',
    'party-time': 'Party Time'
  };

  var linker = function ($scope, element, attrs) {
    $scope.isMobile = platformService.isMobile;
    $scope.title = attrs.title;
    $scope.name = attrs.name;

    if (titleTranslations[attrs.name]) {
      translationService.translate(titleTranslations[attrs.name]).then(function(text) {
        $scope.title = text;
      });
    }

    $scope.refreshing = false;
    var hideTestItems = config.environment === 'production';

    var sectionName = element[0].outerHTML.match(/name="(.*?)"/);
    if (sectionName && sectionName.length) {
      sectionName = sectionName[1];
    }

    element.on('click', function(e){
      if (e.target.id && /[1234567890abcdef]{24}/.test(e.target.id)) {
        e.preventDefault();
        modalShowService.openShow(e.target.id, $scope, sectionName);
        showService.incrementAmountOfViews($scope.items, e.target.id);
        safeUpdateView();
      }
    });

    if (attrs.refreshAfter) {
      setInterval(function () {
        loadShows();
      }, Number(attrs.refreshAfter) * 1000);

      $scope.$watch('items', function () {
        $scope.refreshing = true;
        setTimeout(function () {
          $scope.refreshing = false;
          $scope.$digest();
        }, 250);
      });
    }

    loadShows();

    function loadShows() {
      if (attrs.items) {
        $scope.items = JSON.parse(attrs.items);
      } else if (attrs.productId) {
        showService.getNormalizedShowsByProductId(attrs.productId, false, hideTestItems).then(function(shows) {
          if (shows && shows.length) {
            influencerService.addInfluencerToShows(shows).then(function() {
              $scope.items = shows;
              safeUpdateView();
            });
          }
        }).catch(function() {
          if (attrs.ids) {
            var ids = JSON.parse(attrs.ids);
            showService.getNormalizedAndOrderedByIds(ids, false).then(function(shows) {
              influencerService.addInfluencerToShows(shows).then(function() {
                $scope.items = shows;
                safeUpdateView();
              });
            });
            translationService.translate(titleTranslations.trending).then(function(text) {
              $scope.title = text;
            });
          }
        });
      } else if (attrs.ids) {
        var ids = JSON.parse(attrs.ids);
        showService.getNormalizedAndOrderedByIds(ids, false).then(function(shows) {
          influencerService.addInfluencerToShows(shows).then(function() {
            $scope.items = shows;
            safeUpdateView();
          });
        });
      } else if (attrs.term) {
        var term = JSON.parse(attrs.term);
        showService.getNormalizedShowsByTerm(term, false, hideTestItems).then(function (shows) {
          influencerService.addInfluencerToShows(shows).then(function() {
            $scope.items = shows;
            safeUpdateView();
          });
        });
        var popupMatch = /[\?&#]show=([^\?&#]+)/i.exec(document.location.search);
        if (popupMatch && popupMatch[1] && !$scope.modalShow) {
          modalShowService.openShow(popupMatch[1], $scope, sectionName);
        }
      }
    }

    function safeUpdateView() {
      if ($scope.$root) {
        if ($scope.$root.$$phase && ['$digest', '$apply'].includes($scope.$root.$$phase)) {
          setTimeout(function() {
            safeUpdateView();
          }, 500);
        } else {
          $scope.$digest();
        }
      }
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/shows-carousel.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ShowsCarouselDirective;
