var EventEmitter = require('events').EventEmitter;
var promises = require('../../async/promises');
var cartManipulation = require('./cart/data-manipulation');
var _ = require('lodash-core');

/* jshint maxparams: 9 */
// @ngInject
function PendingOrderService(apiService, orderService, userService, cartService, $timeout, $rootScope, paymentService,
                             $cookies) {
  this.events = new EventEmitter();

  this.create = function(pendingOrder, process) {
    this.preparePendingOrder(pendingOrder);
    console.log(JSON.stringify(pendingOrder, function(key, value) {
      if (key === '$$hashKey') {
        return undefined;
      }
      return value;
    }));
    return promises.extend(apiService.post('/pending-orders', {
      urlParams: {
        process: process
      },
      body: JSON.parse(JSON.stringify(pendingOrder, function(key, value) {
        if (key === '$$hashKey') {
          return undefined;
        }
        return value;
      }))
    }));
  };

  this.preparePendingOrder = function (pendingOrder) {
    var user = userService.getUser();
    pendingOrder.userId = pendingOrder.userId || (!user.guest && user.id) || 'guest';
    if (pendingOrder.shipping.address.country && pendingOrder.shipping.address.country.toLowerCase() !== 'us') {
      pendingOrder.isInternationalOrder = true;
      if ($rootScope.featureFlags.internationalShipping.enabled) {
        _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
          if (pendingOrder.shipping.address.country.toLowerCase() === country.code) {
            if (country.state === 'not-required') {
              pendingOrder.shipping.address.state = pendingOrder.shipping.address.city;
            }
          }
        });
      }
    }

    _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
      if (pendingOrder.billing.address.country.toLowerCase() === country.code) {
        if (country.state === 'not-required') {
          pendingOrder.billing.address.state = pendingOrder.billing.address.city;
        }
      }
    });

    pendingOrder.shipping.address.state = pendingOrder.shipping.address
      .state.replace(new RegExp(pendingOrder.shipping.address.country + '-', 'i'), '');
    pendingOrder.billing.address.state = pendingOrder.billing.address
      .state.replace(new RegExp(pendingOrder.billing.address.country + '-', 'i'), '');

    if (pendingOrder.userId === 'guest' && $rootScope.subscribeNewsletter) {
      userService.subscribeTo('newsletter', 'checkout');
    }
    var usingCart = !pendingOrder.items;
    if (usingCart) {
      pendingOrder.items = cartService.getData().items.filter(cartManipulation.isPurchasable);
      pendingOrder.fulfillers = cartService.getFulfillers();
    }
    if (!pendingOrder.shipping.contact.email) {
      pendingOrder.shipping.contact.email = user.email;
    }

    orderService._addOrderMetadata(pendingOrder);

    if ($cookies.get('sr_token')) {
      pendingOrder.srToken = $cookies.get('sr_token');
    }

    if (pendingOrder.userId === 'guest') {
      $rootScope.preparedOrderPromiseToSave = paymentService.prepareOrder(pendingOrder);
    }

    if (user.anonymous) {
      // this is to remove the checkout option from the potential user
      // checkout option is used to say that the potential user was attempting to checkout
      userService.savePotentialUser(pendingOrder.shipping.contact.email, {checkout: false});
    }
  };

  this.getByPaymentIntentId = function(paymentIntentId) {
    return promises.extend(apiService.get('/pending-orders/{paymentIntentId}', {
      pathParams: {
        paymentIntentId: paymentIntentId
      }
    }));
  };

  this.getOrderStatus = function(paymentIntentId) {
    return promises.extend(apiService.get('/pending-orders/{paymentIntentId}/status', {
      pathParams: {
        paymentIntentId: paymentIntentId
      }
    }));
  };

  this.deleteByPaymentIntentId = function(paymentIntentId) {
    return promises.extend(
      apiService.delete('/pending-orders/{paymentIntentId}', {
        pathParams: {
          paymentIntentId: paymentIntentId
        }
      })
    );
  };
}

module.exports = PendingOrderService;
