// @ngInject
function StickyHeaderDirective(platformService) {
  var linker = function($scope, element) {
    if (!platformService.isBot()) {
      angular.element(element).smartStickyHeader();
    }
  };
  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = StickyHeaderDirective;
