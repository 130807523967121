// @ngInject
function AdminPartnersCommissionController($scope, $state, $stateParams, fulfillerService, formsService) {

    $scope.form = {};

    var formPromise = $scope.formPromise = formsService.promiseHandler();

    function loadPartner() {
        var partnerPromise = fulfillerService.getByIdWithoutMemoize($stateParams.id);

        partnerPromise.then(function(data) {
            if (data.negociatedFee) {
                data.negociatedFee /= Math.round(100);
            }
            $scope.form.partner = data;
            $scope.rule = 1;
            if (data.negociatedFee || data.additionalFee) {
                $scope.fee = true;
            }
            if (data.commissionSale || data.commissionSale > 0 && data.commissionSale !== data.commissionRate) {
                $scope.rule = 2;
            }
            $scope.form.partner.additionalFee = Math.round(data.additionalFee * 100);
            $scope.form.partner.commissionRate = Math.round(data.commissionRate * 100);
            $scope.form.partner.commissionSale = Math.round(data.commissionSale * 100);
        });
    }
    loadPartner();


    $scope.save = function() {
        $scope.form.partner.id = $stateParams.id;
        if (!formPromise.validate($scope.form)) {
            return;
        }
        var dataToUpdate = {};

        dataToUpdate.id = $scope.form.partner.id;
        if ($scope.form.partner.commissionRate) {
            dataToUpdate.commissionRate = $scope.form.partner.commissionRate / 100;
        }
        if (!$scope.fee) {
            dataToUpdate.additionalFee = null;
            dataToUpdate.negociatedFee = null;
        } else {

            if ($scope.form.partner.negociatedFee) {
                dataToUpdate.negociatedFee = $scope.form.partner.negociatedFee * 100;
            }
            if ($scope.form.partner.additionalFee) {
                dataToUpdate.additionalFee = $scope.form.partner.additionalFee / 100;
            }
        }

        // this put a required field only if commissionSale (single) is selected
        if ($scope.rule === 2) {
            if ($scope.form.partner.commissionSale) {
                dataToUpdate.commissionSale = $scope.form.partner.commissionSale / 100;
            }
        } else {
            // prevent send a value if rule is changed
            dataToUpdate.commissionSale = null;
        }

        formPromise.handle(fulfillerService.update(dataToUpdate).then(function() {
            $state.reload();
        }));
    };

}

module.exports = AdminPartnersCommissionController;