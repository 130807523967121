// @ngInject
function KlaviyoService($location) {

  this.trackViewedProduct = function(product, variant, style) {
    if (window._learnq) {
      var imageUrl = '';

      if (style.images && style.images[product.primaryImageIndex]) {
        imageUrl = window.contentBaseUrl + '/' + style.images[product.primaryImageIndex].path;
      }

      var item = {
        ProductName: variant.name || style.name || product.name,
        ProductID: product.id,
        SKU: variant.sku || style.sku,
        Categories: product.tags,
        ImageURL: imageUrl,
        URL: $location.absUrl(),
        Brand: product.brand.name,
        Price: variant.price/100,
        CompareAtPrice: variant.listPrice/100
      };

      // Viewed product
      window._learnq.push(['track', 'Viewed Product', item]);

      // Recently Viewed Items
      window._learnq.push(['trackViewedItem', {
        Title: item.ProductName,
        ItemId: item.ProductID,
        Categories: item.Categories,
        ImageUrl: item.ImageURL,
        Url: item.URL,
        Metadata: {
          Brand: item.Brand,
          Price: item.Price,
          CompareAtPrice: item.CompareAtPrice
        }
      }]);
    }
  };

  this.trackProductAdded = function (item, cart, totals) {
    if (!item.product) {
      return;
    }
    var product = item.product();
    var style = item.style();
    var variant = item.variant();
    var imageUrl = '';
    var items = this.getProductsDetails(cart.items);
    var event = item.forLater ? 'Added to Wishlist' : 'Added to Cart';

    if (style.images && style.images[product.primaryImageIndex]) {
      imageUrl = window.contentBaseUrl + '/' + style.images[product.primaryImageIndex].path;
    }
    if (window._learnq) {
      window._learnq.push(['track', event, {
        $value: totals.totalPrice/100,
        AddedItemProductName: variant.name || style.name || product.name,
        AddedItemProductID: product.id,
        AddedItemSKU: variant.sku || style.sku,
        AddedItemCategories: product.tags,
        AddedItemImageURL: imageUrl,
        AddedItemURL: $location.absUrl(),
        AddedItemPrice: variant.price/100,
        AddedItemQuantity: 1,
        ItemNames: items.map(function (item) { return item.ProductName; }),
        CheckoutURL: 'https://www.orchardmile.com/checkout/shipping',
        Items: items
      }]);
    }
  };

  this.trackStartedCheckout = function (totals, cart) {
    if(window._learnq) {
      var items = this.getProductsDetails(cart.items.filter(function (item) { return !item.forLater; }));

      var categories = [];

      items.forEach(function(item) {
        item.ProductCategories.forEach(function(category) {
          if(!categories.includes(category)) {
            categories.push(category);
          }
        });
      });

      window._learnq.push(['track', 'Started Checkout', {
        $value: totals.totalPrice/100,
        ItemNames: items.map(function (item) { return item.ProductName; }),
        CheckoutURL: 'https://www.orchardmile.com/checkout',
        Categories: categories,
        Items: items
      }]);
    }
  };

  this.getProductsDetails = function (items) {
    var products = [];
    items.forEach(function(item) {
      var productItem = item.product();
      var variantItem = productItem.variants.find(function(variant) {
        return variant.hash === item.hash;
      }) || {};
      var styleItem = productItem.styles.find(function(sty) {
        return sty.id === item.styleId;
      }) || {};
      var imageUrlItem = '';
      var productUrlItem = '';

      if (styleItem && styleItem.images && styleItem.images[productItem.primaryImageIndex]) {
        imageUrlItem = window.contentBaseUrl + '/' + styleItem.images[productItem.primaryImageIndex].path;
      }

      if (styleItem && productItem) {
        productUrlItem = window.origin + '/' + productItem.brand.id + '/' + styleItem.slug + '-' + styleItem.id;
      }

      products.push({
        ProductName: variantItem.name || styleItem.name || productItem.name,
        ProductID: productItem.id,
        SKU: variantItem.sku || styleItem.sku,
        ProductCategories: productItem.tags,
        ImageURL: imageUrlItem,
        URL: productUrlItem,
        Price: variantItem.price/100,
        RowTotal: variantItem.price*item.quantity/100,
        Quantity: item.quantity,
        Brand: productItem.brand.name
      });
    });
    return products;
  };

}

module.exports = KlaviyoService;
