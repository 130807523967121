// @ngInject
function HomeBannerDirective(userService, translationService) {
  var linker = function($scope) {
    $scope.homeBanner = window.orchardMileContent.home.homeBanner;
    translationService.translate(window.orchardMileContent.home.homeBanner.loggedInText).then(function(translation) {
      window.orchardMileContent.home.homeBanner.loggedInText = translation;
    });
    translationService.translate(window.orchardMileContent.home.homeBanner.loggedOutText).then(function(translation) {
      window.orchardMileContent.home.homeBanner.loggedOutText = translation;
    });
    $scope.isLoggedIn = userService.isLoggedIn;
  };

  return {
    link: linker,
    templateUrl: '/views/partials/home-banner.html',
    restrict: 'E'
  };
}

module.exports = HomeBannerDirective;

