
function ConsoleAdapter() {
  this.prefix = '[ANALYTICS]';
}

ConsoleAdapter.prototype.attach = function(analyticsEmitter) {
  var self = this;
  var log = function(methodName){
    return function() {
      var logArgs = Array.prototype.slice.apply(arguments);
      logArgs.unshift(self.prefix + ' ' + methodName + ':');
      console.log.apply(console, logArgs);
    };
  };
  function attachLog(eventName) {
    analyticsEmitter.on(eventName, log(eventName));
  }
  attachLog('page');
  attachLog('track');
  attachLog('identify');
  attachLog('alias');
};

module.exports = new ConsoleAdapter();
