var _ = require('lodash-core');

/* jshint maxparams: 12 */
// @ngInject
function OrderDetailController($rootScope, $scope, $stateParams, orderService, $state,
  productService, errorService, $uibModal, userService, formsService, config, globalizationService) {

  var orderPromise = orderService.get($stateParams.orderNumber, $stateParams.token);

  $scope.brands = {};
  $scope.loading = true;
  $scope.featureFlags = config.featureFlags;
  $scope.existinUser = false;
  $scope.isGuest = false;

  $scope.forms = {};
  $scope.return = {};
  $scope.returnItemReasons = productService.returnItemReasons;

  $scope.loadOrder = function() {
    orderService.get($stateParams.orderNumber, $stateParams.token).then(function(order) {
      $scope.loading = false;
      if (!order) {
        errorService.notFound();
        return;
      }
      globalizationService.setExtraOrderCurrency(order);
      setUndeliverableQuantity(order);
      $scope.order = order;
      if ($scope.order.isInternationalOrder && $scope.order.totals.international) {
        $scope.order.totals = $scope.order.totals.international;
      }
      if ($scope.featureFlags.domesticShipmentSplit.enabled) {
        $scope.order.packagesInfo = orderService.getItemPackages($scope.order);
      }
      $scope.partners = getItemsByPartner(order);

      if ($stateParams.token && $scope.order.shipping.contact.email) {
        $scope.isGuest = true;
        userService.isEmailAvailable($scope.order.shipping.contact.email).then(function(result) {
          if (result.availability) {
            $scope.existinUser = false;
          } else {
            $scope.existinUser = true;
          }
        });
      }
    }, function() {
      errorService.notFound();
    });
  };

  $scope.loadOrder();

  $scope.orderPromise = orderPromise.lazyObject();

  function getItemsByPartner(order) {
    var fulfillers = [];
    order.items.forEach(function(item) {
      var partnerId = item.partner.id;
      var fulfiller = _.find(fulfillers, { id: partnerId });
      if (!fulfiller) {
        var orderFulfiller = _.find(order.fulfillers, { id: partnerId });
        fulfiller = {
          id: partnerId,
          name: item.partner.name,
          items: [],
          selectedShippingMethod: orderFulfiller && orderFulfiller.shippingMethod
        };
        fulfillers.push(fulfiller);
      }
      fulfiller.items.push(item);
    });
    return fulfillers;
  }

  function setUndeliverableQuantity(order) {
    var undeliverableFlagEvents = _.filter(order.events, function(event) {
      return ['flag-undeliverable', 'item-cancelled'].indexOf(event.type) >= 0;
    });
    _.forEach(undeliverableFlagEvents, function(event) {
      var flaggedItem = event.details.item;
      var item = _.find(order.items, function(item) {
        return item.productId === flaggedItem.productId &&
          item.style &&
          flaggedItem.style &&
          item.style.sku === flaggedItem.style.sku &&
          (!flaggedItem.hash || item.hash === flaggedItem.hash);
      });
      item.undeliverableQuantity = item.undeliverableQuantity || 0;
      item.undeliverableQuantity += event.details.quantity;
    });
  }

  $scope.getProductImage = function(imageUrl, options) {
    return productService.getImageUrl(imageUrl, options);
  };

  $scope.canRequestReturn = function(item) {
    return !item.orderReturn && !item.requestingReturn && !item.finalSale &&
      (!item.undeliverableQuantity || item.quantity > item.undeliverableQuantity);
  };

  $scope.formatMoneyByCurrencyCode = function(code, value) {
    if (code) {
      return code + value;
    } else {
      return value;
    }
  };

  $scope.getReturnRequestStatus = function(item) {
    if (item.orderReturn) {
      return 'Return requested<br />';
    } else if (item.requestingReturn) {
      return 'Requesting...';
    }
  };

  $scope.getUndeliverableMessage = function(item) {
    if (item.undeliverableQuantity === item.quantity) {
      return 'Product not available';
    }
    if (item.undeliverableQuantity > 0) {
      var availableValue = item.quantity - item.undeliverableQuantity;
      availableValue += (availableValue === 1 ? ' item' : ' items');
      return 'Only ' + availableValue + ' available';
    }
    return '';
  };

  $scope.getReturnText = function() {
    if ($scope.isGuest && $scope.existinUser) {
      return 'Login to <br />request return';
    } else if ($scope.isGuest && !$scope.existinUser) {
      return 'Create and account to <br />request return';
    } else {
      return 'Request Return';
    }
  };

  $scope.showRequestReturnModal = function(item) {

    $scope.return.item = item;
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/request-return-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });
    $scope.closeModal = function() {
      instance.dismiss();
    };
    $scope.submitModal = function() {
      if (!$scope.formPromise.validate($scope.forms.return)) {
        if (!$scope.return.reason || !$scope.return.reason.trim()) {
          $scope.formPromise.setError('reason must be specified');
        }
        return;
      }
      $scope.closeModal();
      var reason = $scope.return.reason;
      if (reason === 'Other' && $scope.return.otherReason) {
        reason += ': ' + $scope.return.otherReason.trim();
      }
      $scope.sendReturnRequest(item, reason);
    };
  };

  $scope.sendReturnRequest = function(item, returnReason) {
    if (item.orderReturn || item.requestingReturn) {
      return;
    }
    if (isAnonymous() && $scope.order.shipping.contact.email) {
      if (!$scope.existinUser) {
        $scope.modal.show('register', {
          email: $scope.order.shipping.contact.email,
          firstName: $scope.order.shipping.contact.firstName,
          lastName: $scope.order.shipping.contact.lastName,
          reason: 'Please register below to submit and track return requests.',
          trigger: 'order-detail-return-request'
        });
        return;
      } else {
        $scope.modal.show('login', {
          email: $scope.order.shipping.contact.email,
          trigger: 'order-detail-return-request'
        });
        return;
      }

    }
    var itemData = _.cloneDeep(item);

    orderService.returnItems($scope.order, itemData, returnReason, $scope.loadOrder, function(result) {
      item.requestingReturn = false;
      item.orderReturn = result.number;
      $scope.returnNumber = result.number;
      var instance = $uibModal.open({
        templateUrl: 'confirmation-modal',
        windowClass: 'confirmation-modal',
        size: 'md',
        scope: $scope
      });

      $rootScope.addModalToStack();
      instance.result.finally($rootScope.removeModalFromStack);

      $scope.showReturnMoreItems = function() {
        var finalSaleItems = _.filter($scope.order.items, function(item) {
          return item.finalSale;
        });
        return $scope.order.items.length - finalSaleItems.length > 1;
      };

      $scope.clickDone = function() {
        $scope.closeConfirmationModal();
        window.location.reload();
      };

      $scope.closeConfirmationModal = function() {
        instance.dismiss();
      };
    });
    item.requestingReturn = true;
  };

  $scope.orderDetail.visible = true;

  $scope.$watch(isAnonymous, function() {
    var parentBreadcrumb = [];
    if (!isAnonymous()) {
      parentBreadcrumb.push({
        name: 'account',
        link: $state.href('root.account.summary', {})
      });
      $scope.isGuest = false;
    } else {
      $scope.isGuest = true;
    }
    $scope.setBreadcrumbPath(parentBreadcrumb.concat([{ name: 'order details' }]));
    $scope.$on('$destroy', function() {
      $scope.setBreadcrumbPath(parentBreadcrumb);
      $scope.orderDetail.visible = false;
    });

    if (!isAnonymous() && $stateParams.token && $scope.order) {
      // claim order to user
      orderService.claimOrderForCurrentUser($scope.order.number, $stateParams.token).then(function(result) {
        if (result.success) {
          delete $stateParams.token;
          $state.go($state.$current.name, $stateParams, { notify: false, location: 'replace' });
        }
      });
    }
  });


  $scope.isAnonymous = isAnonymous;

  function isAnonymous() {
    return $scope.getUser().anonymous;
  }
}

module.exports = OrderDetailController;
