var _ = require('lodash-core');
var configurator = require('../config/configurator');
var routes = require('./routes');

function ApplicationFactory() {
}

ApplicationFactory.prototype.createNgApp = function(appOptions) {
  try {
    var app = require('./ng-loader').load('orchardmile', [
      'ui.router',
      'ui.bootstrap',
      'ngSanitize',
      'ngAnimate',
      'ngTagsInput',
      'ngTouch',
      'ngCookies',
      'ui.sortable',
      'vsGoogleAutocomplete',
      '720kb.socialshare',
      'angucomplete-alt',
      'slick',
      'dcbImgFallback',
      'angularLazyImg',
      'vcRecaptcha',
      'pascalprecht.translate'
    ]);
    if (app) {
      app.defaultOptions = appOptions;
      configurator.configureApp(app);
      if (process.browser) {
        var config = configurator.getConfiguration();
        if (window.orchardMileWebClient) {
          // make app config visible
          window.orchardMileWebClient.config = config;
        }
        routes.config(app, config);
      }
    }

    this.cookieConsent();

    return app;
  } catch (err) {
    // TODO: use centralized logging
    console.error('an error ocurred loading app');
    console.error(err);
    console.error(err.stack);
    throw err;
  }
};

ApplicationFactory.prototype.bootstrap = function(appOptions) {
  if (!this.checkAccess()) {
    return;
  }
  var app = this.createNgApp(appOptions);
  if (process.browser) {
    var angular = window.angular;
    angular.element(document).ready(function() {
      angular.bootstrap(document.documentElement, ['orchardmile.app']);
    });
  }
  return app;
};
ApplicationFactory.prototype.cookieConsent = function() {
  window.cookiePolicy = {};
  window.cookiePolicy.required = true;
  window.cookiePolicy.analytics = false;
  window.cookiePolicy.marketing = false;
  window.addEventListener('load', function() {
    if (window.cookieconsent) {
      window.cookieconsent.initialise({
        onInitialise: function() {
          if (this.hasConsented('required')) {
            window.cookiePolicy.required = true;
          }
          if (this.hasConsented('analytics')) {
            window.cookiePolicy.analytics = true;
          }
          if (this.hasConsented('marketing')) {
            window.cookiePolicy.marketing = true;
          }
        },
        onAllow: function(category) {
          window.cookiePolicy[category] = true;
          window.location.reload();
        },
        onRevoke: function(category) {
          window.cookiePolicy[category] = false;
          window.location.reload();
        }
      });
    }
  });
};
ApplicationFactory.prototype.checkAccess = function() {
  var config = configurator.getConfiguration();
  var isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
  var isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') >= 0;
  var isPhantomJS = navigator.userAgent.toLowerCase().indexOf('phantomjs') >= 0;

  if (!config.restrictedAccessPasswords || config.automatedBrowser || isPrerender || isPhantomJS || isBot) {
    return true;
  }
  var key = '_ak';
  function isValidPassword(pwd) {
    if (!pwd) {
      return false;
    }
    return _.some(config.restrictedAccessPasswords, function(p) {
      return p.split('').reverse().join('') === pwd;
    });
  }
  var urlKeyPasswordRegex = /[\?\&\#]_ak=([^&]+)/;
  var urlPasswordMatch = urlKeyPasswordRegex.exec(document.location.href);
  try {
    if (urlPasswordMatch || !isValidPassword(localStorage.getItem(key))) {
      var password = urlPasswordMatch ? urlPasswordMatch[1] : '';
      if (!password) {
        password = window.prompt('Enter your access password');
      }
      if (!isValidPassword(password)) {
        var img = $('<img alt="password required" src="/img/no.gif" />');
        $('body').prepend(img).removeAttr('ng-cloak');
        img.css({
          display: 'block',
          width: '50%',
          margin: 'auto'
        });
        $('body').prepend('<div style="text-align:center; margin: 40px;">the right password is required</div>');
        return false;
      }
      localStorage.setItem(key, password);
      if (urlPasswordMatch) {
        history.replaceState({}, document.title, window.location.href.replace(urlKeyPasswordRegex, ''));
      }
    }
  } catch (e) {
    return true;
  }
  return true;
};

module.exports = new ApplicationFactory();
