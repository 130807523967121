// @ngInject
function GuestCheckoutModalController($scope, $state, userService, formsService) {
  $scope.inputs = {};
  var user = userService.getUser();
  $scope.inputs.email = (user && user.email) || '';

  formsService.focusFirstField();

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.doGuestCheckout = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    formPromise.handle(
      userService.guestCheckout($scope.inputs.email)
    ).then(function() {
      $state.go('root.checkout.shipping');
      $scope.modal.hide();
    });

  };

}

module.exports = GuestCheckoutModalController;
