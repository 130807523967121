var promises = require('../../async/promises');
var EventEmitter = require('events').EventEmitter;

// @ngInject
function ReturnService(apiService) {

  var events = this.events = new EventEmitter();

  this.get = function(orderReturnNumber) {
    orderReturnNumber = orderReturnNumber.toUpperCase();
    return promises.extend(apiService.get('/returns/{orderReturnNumber}', {
      pathParams: {
        orderReturnNumber: orderReturnNumber
      }
    }));
  };

  this.addEvent = function(orderReturn, event) {
    var promise = promises.extend(apiService.post('/returns/{orderReturn}/events', {
      pathParams: {
        orderReturn: orderReturn.number
      },
      body: event
    }));
    promise.then(function() {
      events.emit(event.type.toLowerCase(), orderReturn, event);
    });
    return promise;
  };

  this.process = function(orderReturnNumber, event) {
    return promises.extend(apiService.post('/returns/{orderReturnNumber}/process', {
      pathParams: {
        orderReturnNumber: orderReturnNumber
      },
      body: event
    }));
  };

  this.getAllByUser = function(userId) {
    return promises.extend(apiService.get('/returns/by-user/{id}', {
      pathParams: {
        id: userId
      }
    }));
  };

  this.searchReturns = function(params) {
    var from;
    if (!params.term) {
      from = new Date();
      from.setMonth(from.getMonth() - 4);
    }
    return promises.extend(apiService.get('/returns/search', {
      urlParams: {
        hideTestOrders: params.hideTestOrders,
        status: params.status,
        term: params.term,
        from: from
      }
    }));
  };

  this.updateEvent = function(orderReturn, event) {
    return promises.extend(apiService.put('/returns/{orderReturnNumber}/events/{eventId}', {
        pathParams: {
          orderReturnNumber: orderReturn.number,
          eventId: event._id
        },
        body: event
      })
    );
  };
}

module.exports = ReturnService;
