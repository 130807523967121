
// @ngInject
function SaleFilterDirective() {
  var linker = function($scope) {
    $scope.saleEnabled = true;

    $scope.onContextReady(function() {
      $scope.$watch('facets.sale', function() {
        var facets = $scope.facets.discount;
        $scope.saleEnabled = facets.discount && facets.discount.max > 0;
      }, true);
    });
  };
  return {
    link: linker,
    templateUrl: '/views/partials/sale-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = SaleFilterDirective;
