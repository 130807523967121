// @ngInject
function CreditCardService(formatService) {

  this.validExpirationMonths = [];
  var monthNames = formatService.monthNames;
  for (var i = 1; i <= 12; i++) {
    this.validExpirationMonths.push({
      value: i,
      name: monthNames[i - 1]
    });
  }
  var currentYear = (new Date().getYear()) + 1900;
  this.validExpirationYears = [];
  for (i = 0; i < 20; i++) {
    this.validExpirationYears.push(currentYear + i);
  }

}

module.exports = CreditCardService;
