var _ = require('lodash-core');

// @ngInject
function ProductPaginationDirective(scrollService, $window) {
  var watchers = [];

  // bind to resize just one time
  angular.element($window).bind('resize', _.throttle(function() {
    _.forEach(watchers, function(watcher) {
      if (watcher) {
        watcher();
      }
    });
  }, 500));

  var linker = function($scope) {
    // TODO: support override values
    $scope.infiniteScroll = scrollService.isInfiniteScroll();
    $scope.havePreviousProducts = scrollService.havePreviousProducts();
    if (!$scope.pager) {
      $scope.pager = {
        page: 1,
        size: 60
      };
    }
    $scope.lock = false;
    $scope.loadMore = function() {
      $scope.currentPage++;
      $scope.lock = true;
    };

    $scope.currentPage = $scope.pager.page;

    setMaxPages();

    watchers.push(setMaxPages);

    $scope.$on('$destroy', function() {
      _.remove(watchers, function(watcher) {
        return watcher === setMaxPages;
      });
    });

    $scope.$watch('pager.page', function() {
      if ($scope.pager.page) {
        $scope.lock = false;
        $scope.currentPage = $scope.pager.page;
      }
      setPrevNextMeta();
    });

    var initialized = false;
    $scope.$watch('currentPage', function(curr, prev) {
      if (curr === 1 && !initialized) {
        initialized = true;
        $scope.currentPage = $scope.pager.page;
      } else if (curr && prev && curr !== prev) {
        $scope.pager.page = $scope.currentPage;
        $scope.lock = false;
      }
    });

    $scope.$watch('result.count', function() {
      $scope.paginatedItems = $scope.result.pages * $scope.pager.size;
      $scope.pageCount = $scope.result.pages;
      $scope.lock = false;
    });

    function setMaxPages() {
      var window = angular.element($window);
      var winWidth = window.innerWidth() || window.outerWidth();
      if (winWidth <= 767) {
        $scope.maxPages = 4;
      }
    }

    function setPrevNextMeta() {
      if (!$scope.urlMode) {
        return;
      }
      var currentPage = $scope.pager.page;
      var pageCount = $scope.pageCount;
      var baseUrl = document.location.origin + document.location.pathname;
      if (currentPage === 1 && pageCount > 1) {
        $scope.metadata.nextPage = baseUrl + '?page=' + (currentPage + 1);
        $scope.metadata.prevPage = null;
      } else if (currentPage > 1 && pageCount > 1) {
        $scope.metadata.nextPage = baseUrl + '?page=' + (currentPage + 1);
        $scope.metadata.prevPage = baseUrl + '?page=' + (currentPage - 1);
      } else if (currentPage > 1 && pageCount > 1 && currentPage < pageCount) {
        $scope.metadata.nextPage = null;
        $scope.metadata.prevPage = baseUrl + '?page=' + (currentPage - 1);
      }
    }

  };
  return {
    link: linker,
    templateUrl: '/views/partials/product-pagination.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ProductPaginationDirective;
