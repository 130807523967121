function SignifydAdapter() {
}

SignifydAdapter.prototype.attach = function(analyticsEmitter, sessionStorageService) {

  function load() {
    var sessionId = sessionStorageService.getSessionId();
    signifydBeaconLoad(sessionId);
  }

  function signifydBeaconLoad(sessionId) {
    var url = ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'cdn-scripts.signifyd.com/api/script-tag.js';
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    s.id = 'sig-api';
    s.setAttribute('data-order-session-id', sessionId);
    var scripts = document.getElementsByTagName('script');
    var last = scripts[scripts.length - 1];
    last.parentNode.insertBefore(s, last.nextSibling);
  }

  load();
};

module.exports = new SignifydAdapter();
