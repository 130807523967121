var _ = require('lodash-core');

// @ngInject
function OccasionFilterDirective(contentPageService) {
  var linker = function($scope) {

    $scope.status = {
      isOpen: false,
      isVisible: false
    };

    var filters = $scope.filters;
    var sources = $scope.sources;

    $scope.selectOccasion = function(occasion) {
      filters.occasion = occasion ? occasion.tag : '';
    };

    $scope.reset = function($event) {
      $scope.selectOccasion(null);
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
      $event.cancelBubble = true;
      $event.returnValue = false;
    };

    $scope.onContextReady(function() {

      sources.occasions = _.map(
        contentPageService.search({ filters: ['occasions/*'], limit: 15 }),
        function(page) {
          return {
            title: page.title,
            tag: page.productListTag,
            visible: !!page.productListTag
          };
        }
      ).sort(function(a, b) {
        if (/[a-z]/i.test(a.title[0]) && !/[a-z]/i.test(b.title[0])) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      $scope.status.isOpen = !!filters.occasion;

      $scope.$watch('facets.occasion', function() {
        var facets = $scope.facets.occasion;
        $scope.status.isVisible = false;
        _.forEach($scope.sources.occasions, function(occasion) {
          occasion.visible = occasion.tag && facets.tags && facets.tags['u.' + occasion.tag];
          $scope.status.isVisible = $scope.status.isVisible || occasion.visible;
        });
      }, true);

    });

  };
  return {
    link: linker,
    templateUrl: '/views/partials/occasion-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = OccasionFilterDirective;
