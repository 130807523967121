var _ = require('lodash-core');

/* jshint maxparams: 20 */
// @ngInject
function ReferFriendsController($scope, platformService, formsService, userService, analyticsService) {
  $scope.friendMail = '';
  $scope.invitedFriends = [];
  $scope.inputPlaceholder = 'Enter Your Friend’s Email Address';
  var formPromise = $scope.formPromise = formsService.promiseHandler();

  // TODO: review this logic
  $scope.getShareableLink = function() {
    return window.location.origin + '/invite/redeem?referrer=' + userService.getUser().id +
      '&utm_campaign=invited&utm_medium=link&utm_source=invited';
  };
  $scope.getMediaUrl = function() {
    return window.location.origin + '/img/social-media.jpg';
  };

  $scope.copyRefererLink = function() {
    var copyTextarea = document.querySelector('.share-url');
    if (platformService.isIOS()) {
      var range = document.createRange();
      range.selectNodeContents(copyTextarea);
      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      copyTextarea.setSelectionRange(0, 999999);
    } else {
      copyTextarea.select();
    }
    try {
      document.execCommand('copy');
    } catch (err) {
      console.log('Oops, unable to copy');
    }
  };

  $scope.inviteFriend = function() {
    var email = $scope.friendMail;
    if (email === '') {
      $scope.form.friendMail.$setValidity('required', false);
      return false;
    }
    if (_.find($scope.invitedFriends, { email: email })) {
      $scope.form.friendMail.$setValidity('uniqueEmail', false);
      formsService.focusFirstField();
      return;
    }

    $scope.form.friendMail.$setValidity('uniqueEmail', true);
    if (!formPromise.validate($scope.form)) {
      return;
    }
    var entry = {
      email: email
    };
    $scope.invitedFriends.push(entry);
    if ($scope.invitedFriends.length > 0) {
      $scope.inputPlaceholder = 'Enter another friend\'s email address';
    }

    formPromise.handle(userService.invite(email)
      .then(function(result) {
        entry.result = result;
        result.email = email;
        analyticsService.track('Referred a Friend', result);
        if (result.invited) {
          userService.events.emit('inviteRequested', {
            email: email
          });
        }
        return result;
      }, function(err) {
        entry.result = {
          error: err
        };
        throw err;
      })
    );

    $scope.friendMail = '';
    $scope.form.$setPristine();
    formsService.focusFirstField();
  };
}

module.exports = ReferFriendsController;
