// @ngInject
function FormatService() {

  function nthSuffix(number) {
    switch ((+number) % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  this.nthSuffix = nthSuffix;

  var monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  this.monthNames = monthNames;

  function timeAmPm(hours, minutes) {
    var min = minutes ? (':' + (minutes < 10 ? '0' : '') + minutes) : '';
    if (hours < 13) {
      return hours + min + (hours < 12 ? 'am' : 'pm');
    }
    return (hours - 12) + min + 'pm';
  }

  this.timeAmPm = timeAmPm;

  this.formatDateShort = function(date) {
    return monthNames[date.getMonth()].slice(0, 3) + ' ' +
      date.getDate() + nthSuffix(date.getDate());
  };

  this.formatDate = function(date) {
    return monthNames[date.getMonth()].slice(0, 3) + ' ' +
    date.getDate() + ',' + ' ' + timeAmPm(date.getHours(), date.getMinutes());
  };

}

module.exports = FormatService;
