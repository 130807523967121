// @ngInject
function FileDownloaderService() {

  this.guessMimeType = function(filename) {
    var ext = filename.slice(filename.lastIndexOf('.') + 1);
    switch (ext) {
      case 'csv':
        return 'text/csv';
      case 'html':
        return 'text/html';
      case 'txt':
        return 'text/plain';
      case 'json':
        return 'application/json';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
    }
  };

  this.download = function(filename, mimeType, content) {
    if (typeof content === 'undefined') {
      content = mimeType;
      mimeType = this.guessMimeType(filename);
      if (!mimeType) {
        throw new Error('unable to guess mime-type for ' + filename);
      }
    }
    var a = document.getElementById('download-file-link');
    if (!a) {
      a = document.createElement('a');
      a.id = 'download-file-link';
      a.setAttribute('class', 'hide');
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
    }
    var blob = new Blob([content], { type: mimeType });
    var url = URL.createObjectURL(blob);
    a.download = filename;
    a.href = url;
    a.textContent = 'Download ' + filename;
    a.click();
  };
}

module.exports = FileDownloaderService;
