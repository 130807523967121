
// @ngInject
function ValidateEmailController($scope, $state, $stateParams, userService) {
  $scope.isLoggedIn = userService.isLoggedIn;
  $scope.alreadyValidated = false;
  $scope.ready = false;

  $scope.openSignInModal = function() {
    $scope.modal.show('login', {
      trigger: 'validate-email'
    });
    $scope.$onEmitter(userService.events, 'loggedIn', function() {
      $state.go('root.account.summary');
    });
  };

  if (userService.isLoggedIn()) {

    var user = userService.getUser();

    if (user.email !== $stateParams.email) {
      // if logged user is other than the email in the url, then logout
      userService.logout();
    } else if (user.validated) {
      // if the url email is the current user email and that email was already validated
      $scope.alreadyValidated = true;
    }
  }

  if (!$scope.alreadyValidated) {
    userService.validateEmail($stateParams.email, $stateParams.code).then(
      function(response) {
        $scope.success = response.success;
        $scope.ready = true;
      },
      function() {
        // error
        $scope.ready = true;
      }
    );
  } else {
    $scope.ready = true;
  }

}


module.exports = ValidateEmailController;
