/* jshint maxparams: 15 */
// @ngInject
function SubscribeController($scope, formsService, userService, $timeout, $rootScope) {
  var formPromise = $scope.formPromise = formsService.promiseHandler();
  $scope.contactData = {};

  var user = userService.getUser();
  if (user && user.email) {
    $timeout(function() {
      $scope.form.email.$setViewValue(user.email);
      $scope.form.email.$render();
    }, 100);
  }
  if (user && user.firstName && user.lastName) {
    $timeout(function() {
      $scope.form.name.$setViewValue(user.firstName + ' ' + user.lastName);
      $scope.form.name.$render();
    }, 100);
  }
  if (user && user.instagram) {
    $timeout(function() {
      $scope.form.instagram.$setViewValue(user.instagram);
      $scope.form.name.$render();
    }, 100);
  }

  $scope.isLoggedIn = userService.isLoggedIn;

  $scope.sendSubscription = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var promise = userService.subscribeEmailTo($scope.contactData.email, 'newsletter', {
      transactional: true
    }).then(function(result) {
      $rootScope.anonymousSubscriptionResult = result;
      return result;
    });
    if ($scope.contactData.topic) {
      promise = promise.then(function() {
        return userService.subscribeEmailTo($scope.contactData.email, $scope.contactData.topic, {
          transactional: true,
          'extra-data': $scope.contactData
        });
      });
    }

    formPromise.handle(promise);
  };

  $scope.retry = function() {
    formPromise.clear();
    formsService.focusFirstField();
  };
}

module.exports = SubscribeController;
