// @ngInject
function HomeController($scope, $rootScope, metadataService, staticAssetService, userService, platformService, config) {

  $scope.user = userService.getUser();
  $scope.isLoaded = false;
  $scope.isMobile = platformService.isMobile;

  $rootScope.$on('$includeContentLoaded', function() {
    $scope.isLoaded = true;
  });

  metadataService.setPage({
    title: 'The Mile - Scroll down fashion\'s most luxurious mile',
    image: staticAssetService.url('/content/images/tiles/homepage-tree-tiles/main-05-25.jpg')
  });

  $scope.setCanonicalUrl({ whitelist: [] });

  $scope.userHandler = function() {
    $scope.user = userService.getUser();
  };

  ['loggedIn', 'loggedOut'].forEach(function(event) {
    userService.events.on(event, $scope.userHandler);
  });

  $scope.$on('$destroy', function() {
    userService.events.removeListener('loggedIn', $scope.userHandler);
    userService.events.removeListener('loggedOut', $scope.userHandler);
  });

  $scope.showSalesBanner = function(flags) {
    return flags.saleBanner.enabled && !$scope.showPhoneBanner();
  };

  $scope.showPhoneBanner = function() {
    return !$scope.user.anonymous && !$scope.user.phoneNumber;
  };

  $scope.showsTrendingIds= config.featureFlags.theMileInfo.trendingShows;

}

module.exports = HomeController;
