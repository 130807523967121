// @ngInject
function EndRepeatDirective($timeout) {
  return {
    restrict: 'A',
    link: function($scope) {
      if ($scope.$last === true) {
        $timeout(function() {
          $scope.$emit('ngRepeatFinished');
        });
      }
    }
  };
}

module.exports = EndRepeatDirective;