var _ = require('lodash-core');

// @ngInject
function AdminUserClosetController($scope, formsService, influencerService) {
  var addToClosetFormPromise = ($scope.formPromise = formsService.promiseHandler());

  function clear() {
    $scope.closetFormData = {
      newProducts: $scope.influencer.preOwnedProducts ? $scope.influencer.preOwnedProducts.toString() : '',
      newBrand: $scope.influencer.brandId,
    };

    addToClosetFormPromise.clear();
    formsService.focusFirstField();
  }

  clear();

  $scope.addToCloset = function() {
    if (!addToClosetFormPromise.validate($scope.form)) {
      return;
    }
    var closetFormData = _.cloneDeep($scope.closetFormData);
    var preOwnedProducts = closetFormData.newProducts.replace(/\s+$/, '').split(',');
    preOwnedProducts = preOwnedProducts.filter(function(product) {
      return !!product;
    });
    addToClosetFormPromise.handle(
      influencerService
        .updateInfluencerCloset($scope.influencer.id, preOwnedProducts, $scope.closetFormData.newBrand)
        .then(function () {
          $scope.$emit('closetUpdated');
        })
    );
  };
}

module.exports = AdminUserClosetController;
