var promises = require('../../async/promises');
var _ = require('lodash-core');

// @ngInject
function RelatedBrandService(apiService) {

  var self = this;

  this.get = function(params) {
    return promises.extend(apiService.get('/related-brands', {
      urlParams: params
    }));
  };

  this.getById = function(brandId) {
    return promises.extend(apiService.get('/related-brands/by-id', {
      urlParams: {
        'brand': brandId
      }
    }));
  };

  this.getByBatch = function(brandIds) {
    return promises.extend(apiService.get('/related-brands/by-batch', {
      urlParams: {
        'brands': brandIds.join(',')
      }
    }));
  };

  this.getCuratedBrandList = function(brandIds) {
    return self.getByBatch(brandIds).then(function(res) {
      if (res && res.brands && res.brands.length < 1) {
        return [];
      }

      var relBrands = _.map(res.brands, function(each) {
        return each.relatedBrands;
      });

      return _.map(looseInterleave.apply(null, _.uniq(_.compact(relBrands))), function(each) {
        return {
          'groups': [],
          'suggestion': false,
          'curated': true,
          'hidden': false,
          'brandId': each
        };
      });
    });
  };

  function looseInterleave() {
    var arrs = [].slice.call(arguments);
    var maxLength = Math.max.apply(Math, arrs.map(function(arr) {
      return arr.length;
    }));

    var result = [];

    /* jshint loopfunc:true */
    for (var i = 0; i < maxLength; ++i) {
      arrs.forEach(function(arr) {
        if (arr.length > i) {
          result.push(arr[i]);
        }
      });
    }

    return result;
  }

  this.save = function(relatedBrand) {
    return promises.extend(apiService.post('/related-brands', {
      body: relatedBrand
    }));
  };
}

module.exports = RelatedBrandService;
