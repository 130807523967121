var _ = require('lodash-core');

// @ngInject
function InfluencerProductsListDirective($parse, productService) {
  var linker = function ($scope, _element, attrs) {
    $scope.id = attrs.listId;

    $scope.filters = {};
    $scope.result = {};
    $scope.productListName = 'influencer-products-list';

    var styleIds = JSON.parse(attrs.items);

    var loading = true;

    $scope.ready = function () {
      return !loading;
    };
    
    $scope.pager = {};

    var loadProducts = _.memoize(function (params) {
      loading = true;
      productService
        .search({
          searchTerm: params.searchTerm,
          styleIds: styleIds,
          limit: params.limit || $scope.pager.size,
          page: params.page,
          sizes: params.sizes,
          colors: params.colors,
          tags: _.union(params.tags, params.userTags),
          brand: params.brand,
          sort: params.sort,
          sale: params.sale,
          discount: params.discount,
          gifts: params.gifts,
          price: params.price,
        })
        .then(function (data) {
          data.ready = true;
          setData(data);
        });
    });

    $scope.getSearchParams = function() {
      return {
        searchTerm: '',
        searchReady: $scope.search ? $scope.search.ready : true,
        page: 1,
        limit: 8,
      };
    };

    loadProducts($scope.getSearchParams());

    function setData(data) {
      $scope.result.items = data.items;
      $scope.result.count = data.count;
      $scope.result.facets = data.facets;
      $scope.result.ready = data.ready;
      $scope.result.index = data.index;

      
      $scope.products = _.compact(data.items);
      loading = false;
    }

  };
  return {
    link: linker,
    scope: true,
    templateUrl: '/views/partials/influencer-products-list.html',
    restrict: 'E',
  };
}

module.exports = InfluencerProductsListDirective;
