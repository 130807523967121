var _ = require('lodash-core');

// @ngInject
function CanonicalizerService($rootScope, $window, $location, platformService, metadataService) {

  // get a normalized version of the current URL
  this.getNormalizedCurrentUrl = function(options) {
    options = options || {};
    var canonicalUrl = $location.url();
    if (Object.keys($location.search()).length > 0) {
      // do not use $location.search() as it generates encoding issues
      var queryString = canonicalUrl.substr(canonicalUrl.indexOf('?') + 1);
      if (queryString.indexOf('#') > 0) {
        queryString = queryString.substr(0, queryString.indexOf('#'));
      }
      var nameValues = queryString.split('&');
      var newParams = [];
      for (var i = 0; i < nameValues.length; i++) {
        var nameValue = nameValues[i].split('=');
        var key = nameValue[0] = nameValue[0].toLocaleLowerCase();
        var allowParam = typeof options.whitelist === 'undefined' || options.whitelist === null;
        if (options.whitelist && _.indexOf(options.whitelist, key) >= 0) {
          allowParam = true;
        }
        if (options.blacklist && _.indexOf(options.blacklist, key) >= 0) {
          allowParam = false;
        }
        if (allowParam) {
          newParams.push(nameValue.join('='));
        }
      }

      canonicalUrl = ($location.path()).toLowerCase();
      if (newParams.length) {
        newParams.sort();
        canonicalUrl += '?' + newParams.join('&');
      }
      if ($location.hash()) {
        canonicalUrl += '#' + $location.hash();
      }
    }
    if ($location.path() && $location.path() !== '/') {
      // remove trailing slash
      canonicalUrl = canonicalUrl.replace(/\/([\?\#])/, function(match, value) {
        return value;
      }).replace(/\/$/, '');
    }

    return canonicalUrl;
  };

  // normalizes casing and removes trailing slashes
  this.normalizeCurrentUrl = function() {
    var canonicalUrl = this.getNormalizedCurrentUrl();
    if (canonicalUrl === $location.url()) {
      return;
    }
    if (platformService.isPrerender()) {
      metadataService.setRedirect($window.location.origin + canonicalUrl, 301);
      return;
    }
    $location.url(canonicalUrl);
  };

  // sets the canonical link tag
  this.setCanonicalUrl = function(options) {
    var canonicalUrl = $window.location.origin + this.getNormalizedCurrentUrl(options);
    metadataService.setLink('canonical', canonicalUrl);
    if (platformService.isPrerender()) {
      // use canonical HTTP header
      // Link: <http://www.example.com/downloads/white-paper.pdf>; rel="canonical"
      metadataService.setMeta('prerender-header', 'Link: <' + canonicalUrl + '>; rel="canonical"');
    }
  };
}

module.exports = CanonicalizerService;