// @ngInject
function StaticPageModalController($scope, $http, contentPageService, errorService) {

  function loadStaticPage(name) {
    if (!name) {
      $scope.page = {};
      return;
    }
    var promise = contentPageService.getPage(name);
    $scope.page = promise.lazyObject();
    promise.catch(function() {
      // due to S3 website redirect configuration a 404 will result in a redirect to /#/pages/{name}.html
      // but with a weird non-HTTPS request, so we treat all errors as 404 for now
      errorService.notFound();
    });
  }

  $scope.$watch('modal.instanceData.options.name', function() {
    if ($scope.modal && $scope.modal.instanceData &&
      $scope.modal.instanceData.options) {
      loadStaticPage($scope.modal.instanceData.options.name);
    }
  });

}

module.exports = StaticPageModalController;
