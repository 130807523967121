// @ngInject
function ErrorWrapperDirective() {
  var linker = function($scope, element) {
    $scope.$watch(
      function() {
        return element.find('li[style*=list-item]').length;
      },
      function(newValue) {
        if (newValue === 0) {
          element.addClass('has-no-errors').removeClass('has-errors');
        } else {
          element.addClass('has-errors').removeClass('has-no-errors');
        }
      }
    );
  };
  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = ErrorWrapperDirective;
