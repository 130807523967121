
// @ngInject
function EditorialLinkDirective(contentPageService, $parse) {

  var linker = function($scope, element, attrs) {
    element.hide();
    if (attrs.editorialLink) {
      var link = $parse(attrs.editorialLink)($scope);
      link = link.replace(/^\/+/, '');
      var page = contentPageService.search({ filters: [link], limit: 1 })[0];
      if (page) {
        element.attr('href', '/' + link);
        element.show();
      }
    }
  };

  return {
    link: linker,
    restrict: 'A'
  };
}

module.exports = EditorialLinkDirective;
