var sharedBusinessRules = require('shared-business-rules');
var creditCard = sharedBusinessRules.creditCard;
var _ = require('lodash-core');

/* globals $ */
/* jshint maxparams: 8 */

// @ngInject
function CheckoutBaseBillingController($scope, $rootScope, $uibModal, analyticsService, platformService, cartService) {
  $scope.editPaymentMethod = function() {
    if ($rootScope.placingOrder) {
      return;
    }
    $scope.billingModalShown = true;
    var instance = $uibModal.open({
      templateUrl: 'billing-modal',
      windowClass: 'billing-modal',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    platformService.fixiOSModalJumps(instance);
    $scope.closeBillingModal = function() {
      if ($scope.billingIsValid()) {
        $scope.billingModalShown = false;
        instance.dismiss();
      }
    };
  };

  $scope.isInternational = function() {
    return $scope.billing && $scope.billing.address &&
      $scope.billing.address.country && $scope.billing.address.country !== 'us';
  };

  $scope.getExpiration = function(paymentMethod) {
    var year = (paymentMethod.expirationYear < 2000 ? '20' : '') + paymentMethod.expirationYear;
    var month = '0' + paymentMethod.expirationMonth;
    return month.slice(-2) + '/' + year.slice(-2);
  };

  $scope.isCardExpired = function(paymentMethod) {
    var now = new Date();
    if (paymentMethod.expirationYear > now.getFullYear()) {
      return false;
    }
    if (paymentMethod.expirationYear < now.getFullYear()) {
      return true;
    }
    return paymentMethod.expirationMonth < (now.getMonth() + 1);
  };

  $scope.validateExpiration = function(value) { // gets $scope.creditCard.expiration
    var barPosition = value.indexOf('/');
    var month = parseInt(value.substring(0, barPosition));
    var year = value.length > barPosition + 1 ? parseInt(value.substring(barPosition + 1)) : 0;
    if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
      return {valid: false, error: 'required', errorTypes: ['required', 'expiration']};
    }
    if (year <= 99) {
      year = 2000 + year;
    }
    if (year < 2016) {
      return {valid: false, error: 'expiration', errorTypes: ['required', 'expiration']};
    }
    var expYearMonth = year.toString() + ('0' + month).slice(-2);
    var currentYearMonth = '' + (new Date()).getFullYear() + ('0' + ((new Date()).getMonth() + 1)).slice(-2);
    var isValid = expYearMonth >= currentYearMonth;
    return {valid: isValid, error: isValid ? '' : 'expiration', errorTypes: ['required', 'expiration']};
  };

  $scope.validateExpirationDate = function(value) {
    var valid = true;
    if (value) {
      var currentMonth = '0' + ((new Date()).getMonth() + 1);
      var current = '' + (new Date()).getFullYear() + currentMonth.substring(currentMonth.length - 2);
      valid = value >= current;
    }
    return {valid: valid, error: valid ? '' : 'expiration', errorTypes: ['expiration']};
  };

  $scope.getCreditCardClass = function(cardIssuer) {
    return $rootScope.billing.paymentMethod.cardIssuer && $rootScope.billing.paymentMethod.cardIssuer === cardIssuer ?
      'credit-card-logo-selected' : '';
  };

  function constructor() {
    if (!$scope.isPhone) {
      $scope.loadBillingOptions();
    }

    $scope.creditCardExpirationDate = ''; // full date for validation
    $scope.creditCard = {
      expiration: ''  // for MM/YY expiration format
    };

    if (!$scope.billingOptions.length) {
      $scope.$watch('validSteps.shipping', function() {
        if ($scope.validSteps.shipping) {
          $scope.initializeBillingAddress();
        }
      });
    }

    $scope.countries = sharedBusinessRules.countries.getArray();

    $scope.optionsAutocomplete = {};

    $scope.$watch('[billing.address.street, billing.address.number]', function(newValue, prevValue) {
      if (newValue === prevValue) {
        return;
      }
      if ($scope.billing.address) {
        if (typeof $scope.billing.address.street !== 'undefined') {
          analyticsService.track('Billing Address Autocompleted');

          if (typeof $scope.billing.address.number !== 'undefined') {
            $scope.billing.address.address1 = $scope.billing.address.number;
          }
          $scope.billing.address.address1 += ' ' + $scope.billing.address.street;
        }
      }
    });

    $scope.$watch('billing.address.countryCode', function(newValue, prevValue) {
      if (!newValue || newValue === prevValue) {
        return;
      }
      if ($scope.billing.address) {
        $scope.billing.address.country = newValue ? newValue.toLowerCase() : 'us';
      }
    });

    $scope.$watch('billing.address.state', function(newValue, oldValue) {
      if (newValue && newValue !== oldValue && $scope.billingOptions.selected < 0) {
        if ($scope.billing.address) {
          if ($scope.billing.address.country === 'us') {
            $scope.setCities($scope.billing.address.state, 'billing');
          }
        }
      }
    });

    $scope.$watch('billing.newState', function(newValue, oldValue) {
      if (newValue && newValue !== oldValue && $scope.billingOptions.selected < 0) {
        if ($scope.billing.address) {
          setTimeout(function() {
            $scope.billing.address.state = newValue;
            if ($scope.billing.address.country !== 'us') {
              if ($scope.billing.newCity) {
                $scope.billing.address.city = $scope.billing.newCity;
                $('form[name="forms.billing"] #city').trigger('valuesync');
              }
              $('form[name="forms.billing"] #state').trigger('valuesync');
              $scope.forms.billing.$setPristine(true);
            }
          }, 100);
        }
      }
    });

    $scope.$watch('billingOptions.selected', function(newValue, prevValue) {
        if (newValue === prevValue || cartService.getTotals().totalPrice === 0) {
          return;
        }
        if (typeof newValue === 'number' && $scope.canShowPaymentProvider('stripe')) {
          $scope.billingOptionChosen();
        } else {
          $scope.internationalBillingOptionChosen();
        }
      }
    );

    $scope.$watch(
      function() {
        return $scope.forms.billing ? $scope.forms.billing.$valid : false;
      },
      function() {
        if (typeof $scope.validSteps.billing !== 'boolean') {
          return;
        }
        $scope.validSteps.billing = $scope.forms.billing ? $scope.forms.billing.$valid : true;
      }
    );

    $scope.$watch('billing.paymentMethod.cardNumber', function(newValue) {
      if (newValue) {
        $rootScope.billing.paymentMethod.cardIssuer = creditCard.getIssuer(newValue);
        $rootScope.billing.paymentMethod.hiddenCardNumber = 'XXXXXXXXXXXX' + newValue.substr(newValue.length - 4);
      } else {
        $rootScope.billing.paymentMethod.cardIssuer = '';
        $rootScope.billing.paymentMethod.hiddenCardNumber = '';
      }
    });

    $scope.$watch('billing.address.country', function(newValue, oldValue) {
      if (newValue && oldValue && newValue.toLowerCase() !== oldValue.toLowerCase() &&
        (newValue === 'us' || oldValue === 'us')) {
        $scope.billing.address.state = '';
      }
    });

    $scope.$watch(
      function() {
        return (
          $scope.shippingBillingFlags &&
          $scope.shippingBillingFlags.useShippingAddressAsBillingAddress &&
          $scope.billingOptions.selected
        );
      },
      function(value) {
        if ($scope.billingOptions.selected > -1) {
          // if using a saved billing option, ignore the "address same as" flag
          $scope.billing.address = _.cloneDeep(
            $scope.billingOptions.options[$scope.billingOptions.selected]
              .address
          );
          if ($scope.billingOptions.options[$scope.billingOptions.selected].contact) {
            $scope.billing.contact = _.cloneDeep($scope.billingOptions.options[$scope.billingOptions.selected]
              .contact);
          }
          $scope.billing.address.country = $scope.billing.address.country ?
            $scope.billing.address.country.toLowerCase() : 'us';
          if ($scope.billing.address.address2 === 'undefined') {
            delete $scope.billing.address.address2;
          }
          return;
        }
        // entering a new billing option
        if (value) {
          delete $rootScope.billing.address;
        } else {
          $scope.initializeBillingAddress();
        }
      }
    );

    // sync expirationYear and expirationMonth
    $scope.$watch('creditCard.expiration', function(newValue) {
      var barPosition = newValue.indexOf('/');
      if (!isNaN(newValue) && newValue.length === 4) {
        var monthVal = newValue.substring(0, 2);
        var yearVal = newValue.substring(2, 4);
        $scope.creditCard.expiration = monthVal + '/' + yearVal;
      } else if (barPosition >= 0) {
        // setting $rootScope.billing.paymentMethod.expirationMonth and Year will
        // set the model values and trigger their own validations
        $rootScope.billing.paymentMethod.expirationMonth = parseInt(newValue.substring(0, barPosition));
        if (newValue.length > barPosition + 1) {
          var year = parseInt(newValue.substring(barPosition + 1));
          if (year < 2000) {
            year += 2000;
          }
          $rootScope.billing.paymentMethod.expirationYear = year;
        }
      }
    });

    $scope.$watch(function() {
        return [
          $rootScope.billing.paymentMethod.expirationMonth,
          $rootScope.billing.paymentMethod.expirationYear
        ].toString();
      },
      function() {
        var month = $rootScope.billing.paymentMethod.expirationMonth;
        var year = $rootScope.billing.paymentMethod.expirationYear;
        if (isNaN(month) || isNaN(year)) {
          $scope.creditCardExpirationDate = '';
        } else {
          year = (year < 2000 ? '20' : '') + year;
          month = '0' + month;
          $scope.creditCardExpirationDate = year.substring(year.length - 4) + month.substring(month.length - 2);
        }
      });
  }

  constructor();
}

module.exports = CheckoutBaseBillingController;
