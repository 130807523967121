var _ = require('lodash-core');
var promises = require('../../async/promises');

// @ngInject
function BrandService($http, apiService, errorService, platformService, staticAssetService) {
  var self = this;

  this.getAll = promises.memoize(function(sort, includeNotVisible) {
    sort = sort || 'id';
    if (!platformService.isSupported()) {
      return promises.immediate([]);
    }
    var query = {};
    if (includeNotVisible) {
      query.includeNotVisible = true;
    }
    return apiService.get('/brands', {
      urlParams: query
    })
      .then(function(brands) {
        if (!sort) {
          return brands;
        }

        if (sort.substring(sort.length - 3) === '(d)') {
          return _.sortBy(brands, sort.substring(0, sort.length - 3)).reverse();
        }

        // merge brand content metadata
        var contentBrands = window.orchardMileContent.brands || [];
        for (var i = 0; i < brands.length; i++) {
          var brand = brands[i];
          var contentBrand = contentBrands[brand.id];
          if (contentBrand) {
            brand = _.merge(brand, contentBrand);
            brand.hero = contentBrand.hero !== false;
          }
        }
        return _.sortBy(brands, sort);
      })
      .catch(errorService.pageError);
  }, function(sort, includeNotVisible) {
    return JSON.stringify(sort) + JSON.stringify(includeNotVisible);
  });

  this.update = function(brand) {
    return promises.extend(
      apiService.put('/brands/{id}', {
        pathParams: {
          id: brand.id
        },
        body: brand
      }));
  };

  this.getSizeGuide = function(brandId, tags) {
    if (!brandId || !tags || tags.length === 0) {
      return promises.extend(promises.immediate({data: ''}));
    }

    var topTags = _.intersection(tags, ['watches', 'clothing', 'shoes', 'jewelry', 'kids', 'belts', 'men']);
    if (topTags.length === 0) {
      return promises.extend(promises.immediate({data: ''}));
    }
    var tag = topTags[0];

    var url = staticAssetService.brand(brandId).url('size-' + tag + '.html');
    return promises.extend($http.get(url)
      .then(function(response) {
        if (response.data !== '') {
          return {
            data: response.data
          };
        }
        // fall back to default size info
        var url = staticAssetService.brand().url('size-' + tag + '.html');
        return promises.extend($http.get(url).then(function(response) {
          return {
            data: response.data
          };
        }));
      }));
  };

  this.getBrand = promises.memoize(function(brandId) {
    return self.getAll().then(function(items) {
      return _.find(items, {id: brandId});
    });
  });

  this.getByIdWithoutMemoize = function(id) {
    return apiService.get('/brands/{id}', {
      pathParams: {
        id: id
      }
    });
  };

  this.getBrandGroup = function(group) {
    return window.orchardMileContent.designers.groups[group] || [];
  };

  this.isFromGroup = function(brand, group) {
    if (typeof brand === 'object') {
      brand = brand.id;
    }
    return this.getBrandGroup(group).indexOf(brand) > -1;
  };

  this.getBrnadIdsInGroups = _.memoize(function() {
    return _(Object.keys(window.orchardMileContent.designers.groups))
      .map(function(key) {
        return window.orchardMileContent.designers.groups[key];
      })
      .flatten()
      .value();
  });

  // admin methods are not cached
  this.admin = {};

  this.admin.getShowcase = function(brandId) {
    return apiService.get('/brands/{id}/showcase', {
      pathParams: {
        id: brandId
      }
    });
  };

  this.admin.save = function(brandId, products) {
    return apiService.put('/brands/{id}/showcase', {
      pathParams: {
        id: brandId
      },
      body: {
        products: _.map(products,
          function(product) {
            return product.id;
          }
        )
      }
    });
  };

  this.update = function (brandId, brand) {
    return apiService.put('/brands/{id}', {
      pathParams: {
        id: brandId
      },
      body: brand
    });
  };

  // this.hasKidsCategory = promises.memoize(function(brandId) {
  this.hasKidsBrandAssociated = promises.memoize(function(brandId) {
    return self.getAll().then(function(items) {
      return _.find(items, function(item) {
        var brandRoot = brandId.substr(0, brandId.lastIndexOf('-'));
        return item.id === brandId + '-kids' || item.id === brandRoot + '-kids';
      });
    });
  });

  this.getBrandsForWhatsNew = promises.memoize(function() {
    return self.getAll().then(function(items) {
      return items.filter(function(item) {
        if (item.visibleInWhatsNew) {
          return item;
        }
      });
    });
  });

  this.getBrandsForSale = promises.memoize(function() {
    return self.getAll().then(function(items) {
      return items.filter(function(item) {
        if (item.visibleInSale) {
          return item;
        }
      });
    });
  });

  this.hasWomanBrandAssociated = promises.memoize(function(brandId) {
    return self.getAll().then(function(items) {
      return _.find(items, {id: brandId.replace('-kids', '')});
    });
  });

  // private functions

}

module.exports = BrandService;
