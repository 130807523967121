// @ngInject
function ForgotPasswordModalController($scope, $state, userService, formsService) {
  $scope.inputs = {
    email: $scope.modal.instanceData.options.email || ''
  };

  formsService.focusFirstField();

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.sendPasswordResetEmail = function() {

    if (!formPromise.validate($scope.form)) {
      return;
    }

    var email = $scope.inputs.email;
    $scope.emailSent = email;

    formPromise.handle(
      userService.sendPasswordResetEmail(email)
    );

  };

  $scope.reset = function() {
    $scope.inputs.email = '';
    formPromise.clear();
    $scope.$broadcast('unvisit');
    formsService.focusFirstField();
  };

}

module.exports = ForgotPasswordModalController;
