var _ = require('lodash-core');
var categoryHelper = require('shared-business-rules').categories;

// @ngInject
function QuickFiltersDirective($parse, $state) {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/quick-filters.html',
    scope: true,
    link: function($scope) {

      $scope.routeBaseName = $state.current.name.replace(/\.category[1-3]$/, '');

      var filters = $scope.filters;
      filters.countCat = 0;
      var sources = $scope.sources;
      $scope.categories = [];

      $scope.onContextReady(function() {
        $scope.$watch('facets.category', function() {
          sources.categories = pickQuickCategories($scope.facets, undefined, $scope.selectedCategory());
          $scope.categories = sortQuickCategories(sources.categories, 10);
        }, true);
      });

      $scope.selectedCategory = function () {
        if ($state.params.category3) {
          return {
            level: 3,
            cat1: $state.params.category1,
            cat2: $state.params.category2,
            cat3: $state.params.category3
          };
        }
        if ($state.params.category2) {
          return {
            level: 2,
            cat1: $state.params.category1,
            cat2: $state.params.category2
          };
        }
        if ($state.params.category1) {
          return {
            level: 1,
            cat1: $state.params.category1
          };
        }
      };

      $scope.isNothingSelected = function() {
        return !filters.category ||
          !filters.category.length ||
          !filters.category[0] ||
          filters.category[0] === 'whats-new';
      };

      $scope.isCategorySelected = function(category) {
        if ($scope.selectedCategory().level === 3) {
          return category.key1 === $state.params.category1 && category.key2 === $state.params.category2 &&
            category.key3 === $state.params.category3;
        } else if ($scope.selectedCategory().level === 2) {
          return category.key1 === $state.params.category1 && category.key2 === $state.params.category2 &&
            !category.key3;
        } else if ($scope.selectedCategory().level === 1) {
          return category.key1 === $state.params.category1 && !category.key2;
        }
      };

      function sortQuickCategories(categories, limit) {
        return _.take(_.orderBy(categories, ['score'], ['desc']), limit);
      }

      function pickQuickCategories(facets, list, selectedCategory) {
        var facetType = facets.category || facets.occasion || {};

        list = list || categoryHelper.getArray();
        if (selectedCategory) {
          var newList = [];
          _.forEach(list, function (cat1) {
            if (cat1.key === selectedCategory.cat1) {
              cat1.key1 = cat1.key;
              newList.push(cat1);
              if (cat1.children) {
                _.forEach(cat1.children, function (cat2) {
                  if (selectedCategory.level === 1) {
                    cat2.key1 = cat1.key;
                    cat2.key2 = cat2.key;
                    newList.push(cat2);
                  } else {
                    if (cat2.children && selectedCategory.level > 1 && cat2.key === selectedCategory.cat2) {
                      cat2.key1 = cat1.key;
                      cat2.key2 = cat2.key;
                      newList.push(cat2);
                      if (cat2.children && cat2.children.length) {
                        _.forEach(cat2.children, function (cat3) {
                          cat3.key1 = cat1.key;
                          cat3.key2 = cat2.key;
                          cat3.key3 = cat3.key;
                          newList.push(cat3);
                        });
                      } else {
                        cat2.key1 = cat1.key;
                        cat2.key2 = cat2.key;
                        newList.push(cat2);
                      }
                    }
                  }
                });
              }
            }
          });
          list = newList;
        }

        var facet = facetType.tags;

        if (!facet) {
          return _.remove(list, function(each) {
            return each.key !== 'kids';
          });
        }

        return _.chain(list).filter(function(node) {
          return facet && facet[node.key];
        }).forEach(function(node) {
          node.children = null;
          node.score = facet[node.key];
          node.name = node.name || node.key;
        }).value();
      }
    }
  };
}

module.exports = QuickFiltersDirective;
