var _ = require('lodash-core');
var EventEmitter = require('events').EventEmitter;
var promises = require('../../async/promises');
var sharedBusinessRules = require('shared-business-rules');
var productRules = sharedBusinessRules.product;
var images = require('shared-business-rules').images;

/* jshint maxparams: 15 */

// @ngInject
function ProductService($timeout, apiService, config, brandService, contentPageService, searchService,
                        relatedBrandService, $rootScope) {

  var self = this;
  this.events = new EventEmitter();

  this.getAll = function(params) {
    return promises.extend(apiService.get('/products', {
      urlParams: prepareTags(params)
    }));
  };

  this.getById = promises.memoize(function(id) {
    var productPromise = apiService.get('/products/{id}', {
      pathParams: {
        id: id
      }
    });

    productPromise.then(function(product) {
      // do it in next frame to wait for lazy objects
      $timeout(function() {
        self.events.emit('productLoaded', product);
      }, 1);
    });

    return productPromise;
  });

  this.getByOriginalColor = promises.memoize(function(color) {
    return apiService.get('/products/getByOriginalColor', {
      urlParams: {
        color: color
      }
    });
  });

  this.getByStyleId = promises.memoize(function(id) {
    var self = this;
    if (!id) {
      throw new Error('missing style id');
    }

    var productPromise = apiService.get('/products/by-style/{id}', {
      pathParams: {
        id: id
      }
    });

    productPromise.then(function(product) {
      // do it in next frame to wait for lazy objects
      $timeout(function() {
        self.events.emit('productLoaded', product);
      }, 1);

      var contentBrand = window.orchardMileContent.brands[product.brand.id];
      if (contentBrand) {
        _.merge(product.brand, contentBrand);
      }

      // flat product/styles/variants into product/variants
      flatProductVariants(product);
      return product;
    });

    return productPromise;
  });

  this.getByStyleIds = promises.memoize(function(styleIds) {
    var productPromise = apiService.get('/products', {
      urlParams: {
        'style-ids': styleIds.join(',')
      }
    });

    productPromise.then(function(products) {
      // flat product/styles/variants into product/variants
      _.forEach(products, flatProductVariants);
      return products;
    });
    return productPromise;
  });

  this.getBySourceUrl = promises.memoize(function(sourceUrl) {
    return apiService.get('/products/by-source/{sourceUrl}', {
      pathParams: {
        sourceUrl: sourceUrl
      }
    });
  });

  this.getShowcaseProductsByBrand = promises.memoize(function(brandIdsString) {
    return apiService.get('/products/all-brands/groups/showcase', {
      urlParams: {
        brands: brandIdsString
      }
    });
  });

  this.getShowcaseProductsByTag = promises.memoize(function(tags) {
    return apiService.get('/products/all-brands/groups/showcasetag', {
      urlParams: {
        tags: tags
      }
    });
  });

  this.getSourceUrl = function(product, variant) {
    return apiService.get('/products/{productId}/source', {
      pathParams: {
        productId: product && product.id
      }
    }).then(function(productSource) {
      if (!variant || !productSource.styles || !productSource.styles.length) {
        return productSource.sourceUrl;
      }

      var style = variant.getParentStyle();
      var styleSource = _.filter(productSource.styles, {id: style && style.id})[0];
      if (styleSource) {
        return styleSource.sourceUrl;
      }

      var variantSource = _.filter(styleSource.variants, {key: variant.key})[0];
      if (variantSource) {
        return variantSource.sourceUrl;
      }

      return productSource.sourceUrl;
    });
  };

  this.getSizeGuide = function(product) {
    return brandService.getSizeGuide(product.brand.id, product.tags);
  };

  this.getShippingAndReturns = function(def) {
    if (def) {
      return contentPageService.getTemplate('shipping-and-returns-default');
    } else {
      return contentPageService.getTemplate('shipping-and-returns');
    }
  };

  this.update = function(product) {
    if (product.id) {
      apiService.put('/products/{id}', {
        pathParams: {
          id: product.id
        },
        body: product
      });
    }
  };

  this.save = function(product, wearItWith, callback) {
    var self = this;

    function putWearWith(updatedProduct) {
      apiService.put('/products/{id}/wearwith', {
        pathParams: {
          id: updatedProduct.id
        },
        body: {
          suggestions: _.map(wearItWith, function(wearItWithProduct) {
            return wearItWithProduct.id;
          })
        }
      });
    }

    function flushCache() {
      self.getById.cache.clear();
      self.getByStyleId.cache.clear();
      self.getProductsGroupByBrand.cache.clear();
      self.getShowcaseProductsByBrand.cache.clear();
      self.getUserTags.cache.clear();
    }

    if (product.id) {
      apiService.put('/products/{id}', {
        pathParams: {
          id: product.id
        },
        body: product
      }).then(function(responseProduct) {
        putWearWith(responseProduct);
        flushCache();
        callback(responseProduct);
      });
    } else {
      apiService.post('/products', {
        body: product
      }).then(function(responseProduct) {
        putWearWith(responseProduct);
        flushCache();
        callback(responseProduct);
      });
    }
  };

  this.getPrivateData = function(styleIds) {
    return promises.extend(apiService.get('/products/private-data', {
      urlParams: {
        'style-ids': styleIds.join(','),
        'include-siblings': true
      }
    }));
  };

  this.getSimilarProducts = function(product, limit) {
    return promises.extend(apiService.get('/products/{id}/similar', {
      pathParams: {
        id: product.id
      },
      urlParams: {
        limit: limit
      }
    }));
  };

  this.search = function(params) {
    if (params.styleIds && params.styleIds.length) {
      // for specific style ids, use our api
      return this.getByStyleIds(params.styleIds);
    }
    return searchService.search(params);
  };

  this.getProductsFromSameBrand = function(product, limit) {
    var queries = [];
    var tags = (product.tags || []).slice(0, 3); // clone tag array
    while (tags.length) {
      queries.push({
        brand: [product.brand.id],
        sort: 'scores-popular',
        tags: product.tags.indexOf('men') > -1 && tags.indexOf('men') === -1 ? tags.concat('men') :
          product.tags.indexOf('men') === -1 ? tags.concat('-men') : tags,
        limit: 10 * tags.length, // get more products from deeper categories
        page: 1
      });
      tags.pop();
    }
    if (product.tags.indexOf('men') > -1 && tags.indexOf('men') === -1) {
      tags.push('men');
    } else {
      tags.push('-men');
    }
    queries.push({
      brand: [product.brand.id],
      sort: 'scores-popular',
      tags: tags,
      limit: 5,
      page: 1
    });
    return promises.extend(searchService.search(queries).then(function(results) {
      return _.sampleSize(
        _.filter(
          _.uniqBy(
            _.flatten(
              _.map(
                results,
                'items'
              )
            ),
            'id'
          ), function(i) {
            return i.id !== product.id;
          }),
        limit || 50
      );
    }));
  };

  this.getProductsToWearWith = function(product, limit) {
    return promises.extend(apiService.get('/products/{id}/wearwith', {
      urlParams: {
        limit: limit,
      },
      pathParams: {
        id: product.id,
      }
    }));
  };

  this.getPopularProducts = function(limit) {
    limit = limit || 4;
    return promises.extend(
      searchService.search({sort: 'scores-popular', limit: limit * 5, page: 1}).then(function(result) {
        return _.sampleSize(_.uniq(result.items), limit);
      }));
  };
  this.getProductsMayWeSuggest = function(product, limit) {
    limit = limit || 4;
    var categoryNames = this.getProductCategories(product);
    var categoryKeys = this.getProductCategoryKeys(categoryNames);
    var brandData = brandService.getAll().lazyArray();
    var tags = _.cloneDeep(categoryKeys);
    var self = this;
    return relatedBrandService.getCuratedBrandList([product.brand.id])
      .then(function(res) {
        var brandsRelated = [];
        _.each(res, function(each) {
          var brand = _.find(brandData, {id: each.brandId});
          if (brand && brand.visible) {
            brandsRelated.push(brand.id);
          }
        });
        if (product.tags.indexOf('men') > -1) {
          tags.push('men');
        } else {
          tags.push('-men');
        }
        return self.getProductsMayWeSuggestCall({
          brand: brandsRelated,
          excludeBrand: product.brand.id,
          tags: tags,
          sort: 'scores-popular',
          limit: limit * 5,
          page: 1
        }, limit).then(function(res) {
          if (res.length < limit) {
            categoryKeys.splice(-1);
            return self.getProductsMayWeSuggestCall({
              brand: brandsRelated,
              excludeBrand: product.brand.id,
              tags: tags,
              sort: 'scores-popular',
              limit: limit * 5,
              page: 1
            }, limit).then(function(res2) {
              res2.splice(-1 * res.length);
              return res.concat(res2);
            });
          } else {
            return res;
          }
        });
      });
  };

  this.getProductsMayWeSuggestCall = function(params, count) {
    return promises.extend(searchService.search(params).then(function(result) {
      return _.sampleSize(_.uniq(result.items), count);
    }));
  };

  this.getImages = function(product, selectedOptions) {
    return productRules.getProductImages(product, selectedOptions);
  };

  this.getImageUrl = function(relativeUrl, options) {
    if (!relativeUrl) {
      return;
    }
    if ($rootScope.isWEBPCapable) {
      if (!options) {
        options = {};
      }
      options.webp = true;
    }
    return images.getImageUrl(config, relativeUrl, options);
  };

  this.getBlankImageUrl = function() {
    return '/img/blank.webp';
  };

  var styleImagesCache = {};

  this.styleImageShown = function(image) {
    if (!image || !image.styleId) {
      return;
    }
    var cachedImage = styleImagesCache[image.styleId];
    if (cachedImage && cachedImage.width > image.width) {
      return;
    }
    styleImagesCache[image.styleId] = image;
  };

  this.getCachedStyleImage = function(styleId) {
    return styleImagesCache[styleId];
  };

  this.getProductsGroupByBrand = promises.memoize(function(tags, groupLimit) {
    var query = prepareTags({
      'group-limit': groupLimit,
      tags: tags
    });
    return apiService.get('/products/grouped/brand', {
      urlParams: query
    });
  });

  this.getProductCategories = function(product) { // return category names (not keys)
    if (!product || !product.tags) {
      return [];
    }
    return sharedBusinessRules.categories.getCategoriesFromTags(product.tags);
  };

  this.getProductCategoryKeys = function(categories) { // return category keys from names
    return sharedBusinessRules.categories.getCategoryKeysFromNames(categories);
  };

  this.getUserTags = promises.memoize(function() {
    return apiService.get('/user-tags');
  });

  this.saveUserTagsByStyle = function(styleIds, tagsToAdd, tagsToRemove) {
    return apiService.post('/products/user-tags', {
      body: {
        styleIds: styleIds,
        tagsToAdd: tagsToAdd,
        tagsToRemove: tagsToRemove
      }
    });
  };

  this.productListViewed = function(params) {
    var getProductCategories = this.getProductCategories;
    params.items.forEach(function(item) {
      item.categories = getProductCategories(item);
    });
    this.events.emit('view_item_list', params);
  };

  this.trackProductListItemClick = function(params) {
    params.item.categories = this.getProductCategories(params.item);
    this.events.emit('select_item', params);
  };

  this.productViewed = function(params) {
    params.style.categories = this.getProductCategories(params.style);
    this.events.emit('view_item', params);
  };

  // arbitrary numeric values for t-shirt sizes (used for sorting)
  var tShirtSizesValues = {
    xxxxxs: 0,
    xxxxs: 0.01,
    xxxs: 0.02,
    xxs: 0.03,
    xs: 0.04,
    s: 0.05,
    m: 0.06,
    l: 0.07,
    xl: 0.08,
    xxl: 0.09,
    xxxl: 0.10,
    xxxxl: 0.11,
    xxxxxl: 0.12
  };

  this.returnItemReasons = [
    'I did not like this style',
    'Product did not fit me as expected',
    'Product is no longer needed',
    'Incorrect product or size ordered',
    'Incorrect product or size shipped',
    'Product does not match description',
    'Other'
  ];

  this.parseNumber = function(sizeText) {
    var match = /(\d+(?:\.\d+)?)/.exec(sizeText);
    if (match) {
      // if the size has a number, use that
      return parseFloat(match[1]);
    }

    return null;
  };

  this.translateProduct = function(product, styleId) {
    var translateProductPromise = apiService.get('/products/by-style/{id}', {
      pathParams: {
        id: styleId
      },
      urlParams: {
        translate: true
      }
    });

    //load translations
    return translateProductPromise.then(function(productTranslated) {
      _.forEach(productTranslated.styles, function(translatedStyle) {
        _.forEach(product.styles, function(style) {
          if (style.id === translatedStyle.id) {
            style.description = translatedStyle.description;
            _.forEach(style.variants, function(variant) {
              variant.description = translatedStyle.description;
            });
          }
        });
      });
      return product;
    });
  };

  // convert a size string to a numeric value that can be sorted
  this.sizeNumericValue = _.memoize(function(sizeText) {

    if (sizeText.toLowerCase().indexOf('month') >= 0) {
      return (self.parseNumber(sizeText) || 0) / 100;
    }

    var match = self.parseNumber(sizeText);
    if (match || match === 0) {
      return match;
    }

    // look for t-shirt sizing
    if (sizeText.indexOf('medium') >= 0) {
      return tShirtSizesValues.m;
    }
    match = /(x{1,5}-?(?:s|l))/i.exec(sizeText);
    if (match) {
      return tShirtSizesValues[match[1].replace('-', '').toLowerCase()];
    }
    match = /(s|m|l)/i.exec(sizeText);
    if (match) {
      return tShirtSizesValues[match[1].toLowerCase()];
    }
    return 999999;
  });

  // sort an array of sizes (as strings) in place
  this.sortSizes = function(sizes) {
    if (!(sizes && sizes.length)) {
      return;
    }
    var self = this;
    sizes.sort(function(a, b) {
      var numA = self.sizeNumericValue(a);
      var numB = self.sizeNumericValue(b);
      if (numA === numB) {
        // fallback to alphabetical order
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      }
      return numA - numB;
    });
  };

  this.uploadBulkTagProductsCsvFile = function(file, tags, action, by, fulfillerIds) {
    return apiService.post('/products/bulk-tag', {
      body: {
        file: file,
        tags: tags,
        action: action,
        filterBy: by,
        fulfillerIds: fulfillerIds
      }
    });
  };

  // private

  function flatProductVariants(product) {
    if (!product) {
      return product;
    }
    product.variants = [];
    _.forEach(product.styles, function(style) {
      sortImages(style, product.primaryImageIndex);
      _.forEach(style.variants, function(v) {
        var variant = _.cloneDeep(v);
        _.assign(variant, variant.key);
        _.assign(variant, {
          images: style.images,
          slug: style.slug,
          name: style.name,
          description: style.description,
          styleId: style.id
        });
        product.variants.push(variant);
      });
    });
  }

  function sortImages(style, primaryIndex) {
    _.forEach(style.images, function(image, index) {
      image.originalIndex = index;
    });
    if (primaryIndex && primaryIndex < style.images.length) {
      var first = style.images.splice(primaryIndex, 1)[0];
      style.images.unshift(first);
    }
  }

  function prepareTags(params) {
    if (!params) {
      params = {};
    }
    if (params.category) {
      params.tags = (params.tags || []).concat(params.category);
      delete params.category;
    }
    if (params.tags && params.tags.length) {
      params.tags = params.tags.join(',');
    } else {
      delete params.tags;
    }
    return params;
  }

}

module.exports = ProductService;
