var _ = require('lodash-core');

/* jshint maxparams: 13 */
// @ngInject
function StoriesController($scope, $rootScope, $state, $stateParams, $timeout,
  staticAssetService, viewportService, listContextService) {

  $scope.selectableCategories = [
    {
      id: 'trend',
      name: 'Trend Stories'
    },
    {
      id: 'press',
      name: 'Press',
      absolute: true
    }
  ];

  listContextService.setScopeContext($scope);

  $scope.setBreadcrumbPath([{
    name: 'Inspiration'
  }]);

  $scope.setPageMetadata({
    title: 'Stories at THE MILE'
  });

  $scope.$watch(function() {
    return $stateParams.category;
  }, function() {
    $scope.category = $stateParams.category;
  });

  $scope.getContentPageFilters = function() {
    if ($stateParams.category) {
      // show only the selected category
      return '#' + $stateParams.category;
    }
    // include all selectable categories
    return this.selectableCategories.map(function(category) {
      return '#' + category.id;
    }).join(',');
  };

  $scope.getStoryUrl = function(story) {
    return '/' + story.name;
  };

  $scope.getStoryLinkText = function(story) {
    return story.linkText || 'Read More';
  };

  $scope.getStoryImageUrl = function(story) {
    var mobile = viewportService.is('<md');
    return staticAssetService.url('/content/images/tiles/' + story.image + (mobile ? '-mobile' : '') +
      ($rootScope.isWEBPCapable ? '.webp' : '.jpg'));
  };

  $scope.getStoryClasses = function(story) {
    if (story.tile && story.tile.classes) {
      return story.tile.classes;
    }
    return story.classes || '';
  };

  $scope.getTileLoadingClass = function(story) {
    var isMobile = viewportService.is('<md');
    var classes = $scope.getStoryClasses(story).split(' ');
    if (isMobile) {
      var mobileClass = _.find(classes, function(className) {
        return className.indexOf('tile-mobile') === 0;
      });
      return mobileClass || 'tile-mobile-one-across-tall';
    } else {
      return classes[0] || 'tile-one-across';
    }
  };


}

module.exports = StoriesController;
