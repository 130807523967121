var _ = require('lodash-core');

/*jshint maxparams: 16 */
// @ngInject
function AdminUserRewardsController($scope, config, $state, $stateParams, formsService,
  userService) {

  $scope.rewardTypes = [
    'special-discount',
    'magnises'
  ];

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  function clear() {
    var now = new Date();
    var defaultExpiration = new Date(now.getYear() + 1900, now.getMonth(), now.getDate());
    defaultExpiration.setDate(now.getDate() + 60);
    $scope.data = {
      type: 'special-discount',
      expiresAt: defaultExpiration,
      benefitType: 'default',
      sendNotification: true
    };

    formPromise.clear();
    formsService.focusFirstField();
  }

  clear();


  $scope.currentPage = 1;
  $scope.pageSize = '10';

  $scope.totalPages = function() {
    if ($scope.user && $scope.user.rewards) {
      return Math.ceil($scope.user.rewards.length / parseInt($scope.pageSize));
    }
    return 0;
  };

  $scope.updatePagination = function() {
    if ($scope.user && $scope.user.rewards) {
      var start = ($scope.currentPage - 1) * parseInt($scope.pageSize);
      var end = start + parseInt($scope.pageSize);
      $scope.paginatedRewards = $scope.user.rewards.slice(start, end);
    }
  };

  $scope.previousPage = function() {
    if ($scope.currentPage > 1) {
      $scope.currentPage--;
      $scope.updatePagination();
    }
  };

  $scope.nextPage = function() {
    if ($scope.currentPage < $scope.totalPages()) {
      $scope.currentPage++;
      $scope.updatePagination();
    }
  };

  $scope.$watch('user.rewards', function () {
    $scope.updatePagination();
  });

  $scope.add = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }

    var data = _.cloneDeep($scope.data);

    switch (data.benefitType) {
      case 'credit':
        data.credit = data.credit * 100;
        delete data.percentage;
        break;
      case 'percentage':
        delete data.credit;
        break;
      default:
        delete data.percentage;
        delete data.credit;
    }
    delete data.benefitType;

    if (!data.customizeEmail || !data.email) {
      delete data.email;
    } else {
      if (!data.email.longDescription) {
        delete data.longDescription;
      }
      if (!data.email.subject) {
        delete data.subject;
      }
      if (!data.email.also) {
        delete data.also;
      }
    }

    if (data.credit < 0 || data.percentage < 0) {
      formPromise.setError('cannot give a negative discount');
      return;
    }

    formPromise.handle(userService.giveReward($scope.user.id, data).then(function() {
      delete $scope.adding;
      $scope.$emit('rewardAdded');
    }));
  };

  $scope.beginAdd = function() {
    $scope.adding = true;
  };

  $scope.cancelAdd = function() {
    $scope.adding = false;
  };

  $scope.rewardIsExpired = function(reward) {
    if (reward.used || !reward.expiresAt) {
      return false;
    }
    var expiresAt = reward.expiresAt;
    if (typeof expiresAt === 'string') {
      expiresAt = new Date(expiresAt);
    }
    if (expiresAt < new Date()) {
      return true;
    }
    return false;
  };

  $scope.userTotalPercentageDiscount = function() {
    var percentage = 0;
    $scope.user.rewards.forEach(function(reward) {
      var expiresAt = reward.expiresAt;
      if (typeof expiresAt === 'string') {
        expiresAt = new Date(expiresAt);
      }
      if ((!reward.percentage) || (reward.used && reward.usagesLeft < 1) || (expiresAt < new Date())) {
        return;
      }
      percentage += reward.percentage;
    });
    return percentage;
  };

  $scope.remove = function(reward) {
    userService.removeReward($scope.user.id, reward.id).then(function() {
      $scope.$emit('rewardRemoved');
    });
  };

}

module.exports = AdminUserRewardsController;
