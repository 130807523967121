// @ngInject
function TrackFocusDirective() {

  return {
    restrict: 'A',
    link: function($scope, element) {
      var $elem = $(element[0]);
      $elem.on('blur', 'input,select,textarea', function() {
        element.addClass('visited');
        element.removeClass('has-focus');
      });
      $elem.on('focus', 'input,select,textarea', function() {
        element.addClass('has-focus');
      });
      $scope.$on('unvisit', function() {
        element.removeClass('visited');
      });
    }
  };
}

module.exports = TrackFocusDirective;
