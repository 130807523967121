var _ = require('lodash-core');

// @ngInject
function DiscountFilterDirective() {
  var linker = function($scope) {

    var filters = $scope.filters;
    var sources = $scope.sources;

    $scope.onContextReady(function() {

      $scope.$watch('facets.discount', function() {
        sources.discounts = _.filter([20, 30, 50, 70], function(discount) {
          return discount <= ($scope.facets.discount.discount || {}).max;
        });
      }, true);

    });

    $scope.selectDiscount = function(discount) {
      filters.discount = discount;
    };
  };
  return {
    link: linker,
    templateUrl: '/views/partials/discount-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = DiscountFilterDirective;
