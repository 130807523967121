// @ngInject
function ContentPopupController($scope, viewportService,
  contentPopupService, staticAssetService, userService) {
  $scope.hide = function() {
    contentPopupService.hide();
  };

  $scope.hideAndOpenLogin = function() {
    contentPopupService.hideAndOpenLogin();
  };

  $scope.hideThenOpenLogin = function(trigger) {
    contentPopupService.hideThenOpenLogin(trigger);
  };
  $scope.hideThenOpenRegistration = function(trigger) {
    contentPopupService.hideThenOpenRegistration(trigger);
  };

  $scope.userLogInHandler = function() {
    $scope.hide();
  };
  userService.events.on('loggedIn', $scope.userLogInHandler);
  $scope.$on('$destroy', function() {
    userService.events.removeListener('loggedIn', $scope.userLogInHandler);
  });

  $scope.getContentPopupStyle = function() {
    var style = {};
    var backgroundImage = staticAssetService.url($scope.contentPopup && $scope.contentPopup.backgroundImage ||
      contentPopupService.defaultBackgroundImage);
    if (viewportService.is('xs') && $scope.contentPopup && $scope.contentPopup.backgroundImageMobile) {
      backgroundImage = staticAssetService.url($scope.contentPopup.backgroundImageMobile);
    }
    if (backgroundImage) {
      style.background = 'url(' + backgroundImage + ') no-repeat';
    }
    return style;
  };
}

module.exports = ContentPopupController;
