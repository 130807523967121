/* jshint maxparams: 8 */
// @ngInject
function CheckoutPreConfirmationController($rootScope, $scope, $state, $stateParams, pendingOrderService,
                                           orderService, shoppingContextService, paymentService) {
  var iterationsLeft = 8;

  /*jshint camelcase: false */
  function pollForPaymentOrderStatus() {
    var paymentIntentId = $stateParams.token || $stateParams.payment_intent;
    if (paymentIntentId && $rootScope.placingOrder) {
      pendingOrderService.getOrderStatus(paymentIntentId).then(function (paymentStatus) {
        if (paymentStatus.status === 'authorized') {
          orderService
            .get(paymentStatus.order, paymentStatus.token)
            .then(function (placedOrder) {
              orderService.postPlacedOrderOpt(placedOrder);
              shoppingContextService.displayPrePlacedOrder(placedOrder);
            })
            .catch(function (e) {
              console.error(e);
              trowError('We had an issue processing your information, check your email');
            });
        }
        if (paymentStatus.status === 'invalid') {
          trowError('We had an issue processing your payment');
        }
        if (paymentStatus.status === 'failed') {
          trowError('Payment failed');
        }
        if (paymentStatus.status === 'internal-error') {
          trowError('Something unexpected happened');
        }
        if (paymentStatus.status === 'cancelled') {
          trowError('Payment cancelled');
        }
        if (!paymentStatus.status || paymentStatus.status === 'initiated') {
          retryPaymentPoll();
        }
        iterationsLeft--;
      }).catch(function () {
        iterationsLeft--;
        retryPaymentPoll();
      });
    } else if (!$rootScope.placingOrder) {
      trowError('Payment cancelled');
    }
  }

  function retryPaymentPoll() {
    if (iterationsLeft && $rootScope.placingOrder) {
      setTimeout(pollForPaymentOrderStatus, 1000);
    } else if ($rootScope.placingOrder) {
      trowError('We had an issue processing your payment');
    }
  }

  function safeUpdateView() {
    if ($scope.$root) {
      if ($scope.$root.$$phase && ['$digest', '$apply'].includes($scope.$root.$$phase)) {
        setTimeout(function () {
          safeUpdateView();
        }, 500);
      } else {
        $scope.$digest();
      }
    }
  }

  function trowError(reason) {
    $rootScope.placingOrder = false;
    $scope.placingOrderError = new Error(reason);
    safeUpdateView();
  }

  $scope.goBackToCheckout = function () {
    if (window.hj) {
      window.hj('event', 'payment-try-again-click');
    }
    /*jshint camelcase: false */
    var paymentIntent = $stateParams.payment_intent || $stateParams.token;
    $state.go('root.checkout.shipping', {
      'pending-order-payment-intent': paymentIntent,
    });
  };

  $scope.getPlacingOrderErrorMessage = function () {
    var err = $scope.placingOrderError || $rootScope.placingOrderError;
    if (!err) {
      return;
    }
    window.onbeforeunload = null;
    if (err.message) {
      return err.message;
    }
    return 'A network error occurred with your connection.';
  };

  window.onbeforeunload = function () {
    return 'We\'re processing your payment, please do not close your tab yet';
  };

  $rootScope.placingOrder = true;
  paymentService.loadProvider();
  pollForPaymentOrderStatus();
}

module.exports = CheckoutPreConfirmationController;
