// @ngInject
function TheMileCartDirective(cartService) {
  var linker = function ($scope) {
    cartService.setBagActive(true);
    $scope.steps = [
      'shipping',
      'billing',
      'review',
      'confirmation'
    ];
  };

  return {
    link: linker,
    templateUrl: '/views/partials/modals/cart.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = TheMileCartDirective;
