var _ = require('lodash-core');

// @ngInject
function AdminDefaultImageDirective(curationService, productService) {
  var linker = function($scope, element, attrs) {
    var state = $scope.$root.adminDefaultImageDirectiveState =
      $scope.$root.adminDefaultImageDirectiveState || { primary: null, secondary: null, visible: false };
    var styleId = $scope.style ? $scope.style.id : $scope.product.styleId;
    $scope.selecting = { primary: false, secondary: false };
    $scope.selectors = _.keys($scope.selecting);
    $scope.image = parseInt(attrs.image);
    if (!$scope.$index) {
      // initialization
      state.primary = parseInt(attrs.primary);
      state.secondary = parseInt(attrs.secondary);
      state.visible = false;
    } else {
      // if there are more than 1 image the selector get visible
      state.visible = true;
    }

    $scope.select = function(selector) {
      if ($scope.selecting.primary || $scope.selecting.secondary) {
        return;
      }
      $scope.selecting[selector] = true;
      var other = selector === 'primary' ? 'secondary' : 'primary';
      var otherImage = state[other];
      if (otherImage === $scope.image) {
        otherImage = $scope.image ? $scope.image - 1 : 1;
      }
      var curations = {};
      curations[getCurationAttributeName(selector)] = $scope.image + 1; // +1 because curations are in base 1
      curations[getCurationAttributeName(other)] = otherImage + 1;
      curationService.setStyleCurations(styleId, curations).then(function() {
        state[selector] = $scope.image;
        state[other] = otherImage;
        $scope.selecting[selector] = false;
      });
      productService.getByStyleId.cache.clear(); // clean the product service cache
    };

    function getCurationAttributeName(selector) {
      return selector === 'primary' ? 'imageIndex' : 'secondaryImageIndex';
    }
  };

  return {
    link: linker,
    templateUrl: '/views/partials/admin-default-image.html',
    restrict: 'E'
  };

}

module.exports = AdminDefaultImageDirective;
