var appFactory = require('./app/main');

require('./polyfill');

// global exports
exports.bootstrap = appFactory.bootstrap.bind(appFactory);

if (process.browser) {
  // exports only for browser bundle
  exports.version = '{{package-version}}';
  exports.homepage = '{{package-homepage}}';
  exports.versionHash = '{{git-version}}';
} else {
  // exports only for node.js
  var packageInfo = require('../package.json');
  exports.version = packageInfo.version;
  exports.homepage = packageInfo.homepage;
}
