var _ = require('lodash-core');
var categories = require('shared-business-rules').categories;
// @ngInject
function CategoryTreeSelectableDirective() {
  return {
    restrict: 'E',
    templateUrl: '/views/partials/category-tree-selectable.html',
    scope: {
      tags: '='
    },
    link: function($scope) {

      var lastSelection = [];
      $scope.categories = categories.getArray();
      $scope.tags = $scope.tags || [];

      $scope.selectionChanged = function(node) {
        if (node.isSelected) {
          lastSelection = _.uniq(lastSelection.concat(node.key));
        } else {
          lastSelection = remove(lastSelection, node.key);
          deselect(node.children);
        }

        var selectionString = getSelectionString();
        if (getTagsString() !== selectionString) {
          setTagsFromString(selectionString);
        }
      };

      $scope.$watch(getTagsString, function(value) {
        if (value !== getSelectionString()) {
          setSelectionFromString(value);
        }
      });


      // private methods

      function deselect(nodes) {
        _.forEach(nodes, function(node) {
          if (node.isSelected) {
            node.isSelected = false;
            lastSelection = remove(lastSelection, node.key);
            deselect(node.children);
          }
        });
      }

      function remove(keys, key) {
        return _.filter(keys, function(k) { return k !== key; });
      }

      function getTagsString() {
        return $scope.tags.toString();
      }

      function setTagsFromString(keys) {
        $scope.tags = keys ? keys.split(',') : [];
      }

      function getSelectionString() {
        return lastSelection.toString();
      }

      function setSelectionFromString(keys) {
        lastSelection = keys ? keys.split(',') : [];
        select($scope.categories);
        function select(nodes) {
          _.forEach(nodes, function(node) {
            node.isSelected = lastSelection.indexOf(node.key) > -1;
            if (node.isSelected) {
              select(node.children);
            }
          });
        }
      }


    }
  };
}

module.exports = CategoryTreeSelectableDirective;
