
// @ngInject
function SessionStorageService(platformService) {

  var storageSystem = {};
  try {
    storageSystem = platformService.isSessionStorageAvailable() && window.sessionStorage ||
      window.localStorage;
  } catch (e) {
    storageSystem = window.iframeStorage;
  }
  this.getSessionId = function() {
    var sessionId = this.getItem('sid');
    if (!sessionId) {
      sessionId = generateId();
      this.setItem('sid', sessionId);
    }
    return sessionId;
  };

  this.setItem = function(key, value) {
    if (typeof window === 'undefined') {
      return;
    }

    return storageSystem.setItem(key, value);
  };

  this.removeItem = function(key) {
    if (typeof window === 'undefined') {
      return;
    }
    return storageSystem.removeItem(key);
  };

  this.getItem = function(key) {
    if (typeof window === 'undefined') {
      return;
    }
    return storageSystem.getItem(key);
  };

  function generateId() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }
}

module.exports = SessionStorageService;
