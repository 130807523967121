// @ngInject
function AdminFulfillerNewsletterController($scope, $stateParams, $timeout,
  fulfillerService, formsService, fileDownloaderService) {

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  $scope.getFulfillers = function() {
    return fulfillerService.getAll().lazyArray();
  };

  $scope.get = function() {
    if (!formPromise.validate($scope.form)) {
      return;
    }
    fulfillerService.getById($scope.fulfiller.id).then(function(info) {
      $scope.selectedFulfiller = info;
      formPromise.handle(
        fulfillerService.getNewsletter($scope.fulfiller.id)
          .then(dataToCsv).then(download)
      );
    });
  };

  function dataToCsv(data) {
    var rows = [];
    // column headers
    rows.push(['Email']);
    data.forEach(function(user) {
      rows.push([user.email]);
    });

    return rows.map(function(row) {
      return row.join(',');
    }).join('\n');
  }

  function download(data) {
    return fileDownloaderService.download('fulfiller-' + $scope.fulfiller.id + '-newsletter.csv', data);
  }
}

module.exports = AdminFulfillerNewsletterController;
