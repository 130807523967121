var _ = require('lodash-core');

/* globals $ */
/* jshint maxparams: 8 */

// @ngInject
function CheckoutBaseShippingController($scope, $rootScope, $uibModal, userService, analyticsService, $timeout,
                                        platformService) {
  $scope.subscriptions = {};

  $scope.subscriptions = userService.getSubscriptionsByTopic().lazyObject();

  if ($rootScope.featureFlags.internationalShipping.enabled) {
    $scope.countries = $rootScope.featureFlags.internationalShipping.countries;
  } else {
    $scope.countries = [{
      code: 'us',
      name: 'United States of America',
      state: 'specific'
    }];
  }

  $rootScope.isInternationalShipping = function () {
    return $scope.shipping && $scope.shipping.address.country !== 'us' && $scope.shipping.address.country;
  };

  $scope.isSelectableCity = function () {
    return $scope.shipping && $scope.citiesOfState && Object.keys($scope.citiesOfState).length > 0;
  };

  $scope.countryRequireZip = function () {
    return !['ae', 'hk', 'pa', 'kw'].includes($scope.shipping.address.country);
  };

  $scope.$watch('shipping.address.country', function () {
    if (!$scope.countryRequireZip()) {
      $scope.shipping.address.zip = '000000';
    }
  });

  $scope.isSubscribed = function(fulfillerId) {
    if (!$scope.subscriptions.items) {
      return false;
    }
    return $scope.subscriptions.items.indexOf('fulfiller-' + fulfillerId) !== -1;
  };

  $scope.optionsAutocomplete = {};
  $scope.checkoutModal = false;

  $scope.$watch('shipping.address.country', function() {
    if (!$scope.shipping.address.country && $scope.shippingOptions.selected === -1) {
      $scope.shipping.address.country = 'us';
    }
    if ($scope.shipping.address.country === 'United States') {
      $scope.shipping.address.country = 'us';
    }
    if ($scope.shipping.address.country) {
      $scope.shipping.address.country = $scope.shipping.address.country.toLowerCase();
    }
    if ($scope.countryToShip() === 'kr' && $scope.shippingOptions.selected === -1 && $scope.shippingOptions.loaded) {
      delete $scope.shipping.address.state;
    }
  });

  $scope.$watch(function() {
      return $scope.shippingOptions.selected;
    },
    function(newValue, prevValue) {
      if (newValue === prevValue) {
        return;
      }

      $rootScope.shipping = _.cloneDeep($scope.shippingOptions.options[$scope.shippingOptions.selected]) ||
        {contact: {}, address: {}};
      $scope.shipping.address.fullAddress = '';
      if ($scope.checkoutModal) {
        $scope.shipping.contact.firstName = $scope.shippingOptions.options[prevValue].contact.firstName;
        $scope.shipping.contact.lastName = $scope.shippingOptions.options[prevValue].contact.lastName;
      }
      if (typeof $scope.shipping.address.address1 !== 'undefined' &&
        typeof $scope.shipping.address.city !== 'undefined' &&
        typeof $scope.shipping.address.state !== 'undefined' &&
        typeof $scope.shipping.address.zip !== 'undefined') {
        $scope.shipping.address.fullAddress = $scope.shipping.address.address1 + ', ' +
          $scope.shipping.address.city + ', ' +
          $scope.shipping.address.state + ' ' + $scope.shipping.address.zip;
      }
    });
  if ($scope.forms && $scope.forms.shipping) {
    $scope.$watch(
      function() {
        return $scope.forms.shipping.$valid;
      },
      function() {
        if (typeof $scope.validSteps.shipping !== 'boolean') {
          return;
        }
        $scope.validSteps.shipping = $scope.forms.shipping.$valid;
      }
    );
  }

  $scope.$watch('[shipping.address.street, shipping.address.number]', function(newValue, prevValue) {
    if (newValue === prevValue) {
      return;
    }
    if (typeof $scope.shipping.address.street !== 'undefined') {
      analyticsService.track('Shipping Address Autocompleted');
      if (typeof $scope.shipping.address.number !== 'undefined') {
        $scope.shipping.address.address1 = $scope.shipping.address.number;
      }
      $scope.shipping.address.address1 += ' ' + $scope.shipping.address.street;
      $scope.shipping.address.fullAddress = $scope.shipping.address.address1;
      if (typeof $scope.shipping.address.city !== 'undefined') {
        $scope.shipping.address.fullAddress += ', ' + $scope.shipping.address.city;
      }
      if (typeof $scope.shipping.address.state !== 'undefined') {
        $scope.shipping.address.fullAddress += ', ' + $scope.shipping.address.state;
      }
    }
  });

  $scope.$watch('shipping.contact.email', function(newValue) {
    if (!userService.isLoggedIn() && userService.getUser().email !== newValue) {
      userService.guestCheckout(newValue);
      $scope.subscriptions = userService.getSubscriptionsByTopic().lazyObject();
    }
  });

  $rootScope.$watch('shippingOptions.selected', function(newValue, oldValue) {
    if (newValue !== oldValue && newValue === -1) {
      $scope.validSteps.shipping = null;
      if ($scope.forms.shipping) {
        $scope.forms.shipping.$setPristine(true);
      }
    }
  });

  $scope.$watch('shipping.address.state', function(newValue, oldValue) {
    if (newValue !== oldValue && $scope.shippingOptions.selected === -1) {
      $scope.setCities($scope.shipping.address.state, 'shipping');
    }
  });

  $scope.selectAndScrollToForm = function(val, e) {
    e.preventDefault();
    $scope.shippingOptions.selected = val;
    setTimeout(function() {
      $('body').animate({scrollTop: $('.checkout-form').offset().top - 200}, 'slow');
    }, 5);
  };

  $scope.checkboxGuestOptIn = function() {
    analyticsService.track('Clicked Subscription Guest', {
      value: $rootScope.subscribeNewsletter
    });
  };

  $scope.addressValid = false;

  $scope.openShippingModal = function() {
    if ($scope.shippingOptions.options.length > 0) {
      $scope.confirmAddress(true);
      return true;
    }
  };

  $scope.confirmAddress = function(force) {
    $timeout(function() {
      if ($scope.forms.shipping.fullAddress.$invalid || force === true) {
        if ($rootScope.placingOrder) {
          return;
        }
        $scope.modalValid = {};
        $scope.modalValid.shipping = true;
        $scope.checkoutModal = true;
        var instance = $uibModal.open({
          templateUrl: 'address-modal',
          windowClass: 'address-modal',
          backdrop: 'static',
          keyboard: false,
          size: 'md',
          scope: $scope
        });

        $rootScope.addModalToStack();
        instance.result.finally($rootScope.removeModalFromStack);

        platformService.fixiOSModalJumps(instance);
        $scope.closeAddressModal = function() {
          $scope.checkoutModal = false;
          if ($scope.forms.modalShipping.$valid) {
            instance.dismiss();
            $scope.addressValid = false;
            $scope.$parent.shipping.address = $scope.shipping.address;
            $scope.$parent.shippingOptions.selected = $scope.shippingOptions.selected;
            $scope.$parent.shipping.address.fullAddress = $scope.shipping.address.address1 + ', ' +
              $scope.shipping.address.city + ', ' +
              $scope.shipping.address.state + ' ' + $scope.shipping.address.zip;
            $scope.$parent.forms.shipping.fullAddress.$invalid = false;
            $scope.$parent.forms.shipping.fullAddress.$setValidity('placeValid', true);
          } else {
            $scope.modalValid.shipping = false;
          }
        };
      } else {
        $scope.addressValid = true;
      }
    }, 20);
  };

  $scope.confirmDeleteShippingOption = function(optionToDelete) {
    $scope.shippingOptionToDelete = optionToDelete;

    var instance = $uibModal.open({
      templateUrl: 'confirmation-modal',
      windowClass: 'confirmation-modal',
      size: 'md',
      scope: $scope
    });
    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    $scope.closeConfirmationModal = function() {
      instance.dismiss();
    };
    $scope.deleteShippingOption = function() {
      userService.deleteShippingOption(optionToDelete).then(function() {
        var index = _.findIndex($scope.shippingOptions.options, function(option) {
          return option === optionToDelete;
        });
        $scope.shippingOptions.options.splice(index, 1);
        if ($scope.shippingOptions.selected === index) {
          $scope.shippingOptions.selected = $scope.shippingOptions.options.length ? 0 : -1;
        }
        instance.dismiss();
      });
      optionToDelete.deleting = true;
    };
  };

  $scope.$watch('step', function(step, previousStep) {
    setTimeout(function() {
      if (previousStep === 'shipping' && step === 'billing' && $scope.shipping.address.country !== 'us' &&
        !$scope.translatePopUpOpened) {
        $scope.confirmDutiesOption();
      }
    }, 1000);
  });

  $scope.$watch('translatePopUpOpened', function(newStatus, oldStatus) {
    setTimeout(function() {
      if (newStatus === false && oldStatus === true && $scope.shipping.address.country !== 'us' &&
        $scope.step === 'billing') {
        $scope.confirmDutiesOption();
      }
    }, 1000);
  });

  $scope.confirmDutiesOption = function() {
    if (!$scope.instanceDuties) {
      $scope.instanceDuties = $uibModal.open({
        templateUrl: 'duties-modal',
        windowClass: 'confirmation-modal',
        backdrop: 'static',
        size: 'md',
        scope: $scope
      });
      $rootScope.addModalToStack();
      $scope.instanceDuties.result.finally($rootScope.removeModalFromStack);

      $scope.closeDutiesModal = function() {
        $scope.instanceDuties.dismiss();
        $scope.instanceDuties = undefined;
      };
    }
  };
}

module.exports = CheckoutBaseShippingController;
