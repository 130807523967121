var _ = require('lodash-core');
var trackingUrl = require('tracking-url');

/* jshint maxparams: 12 */
// @ngInject
function AdminOrderEventsController($stateParams, $state, $scope, $rootScope, $uibModal,
  config, orderService, formsService, returnService, ticketService, paymentService, fulfillerService) {

  // NOTE: this controller depends on having the following in parent controller
  // $scope.order
  // $scope.orderPromise
  // $scope.loadOrder
  // $scope.orderType
  $scope.forms = {};
  $scope.featureFlags = config.featureFlags;
  $scope.cancelOrderReasons = [
    'OOS - Order processed - Brand unable to fulfill',
    'OOS - Order not processed - OOS in the brand page',
    'OOS - Order not processed - Checkout failed due OOS',
    'OOS - Order not processed - Unable to add to cart',
    'OOS - Not enough units',
    'Risk decline',
    'Customer request',
    'Duplicate',
    'Brand site limitation'
  ];

  $scope.cancelReturnReasons = [
    'Customer Request',
    'Customer did not reply',
    'Outside of the return period',
    'Not eligible for return or Final Sale',
    'Brand declined return request'
  ];
  $scope.rejectReturnReasons = [
    'Past Return Period',
    'Item Never Returned',
    'Item Not in Acceptable Condition'
  ];
  $scope.attachOrderReasons = [
    'Discount code not applied',
    'Duplicated processing',
    'Missing item',
    'New price',
    'Reprocessing per brand request/error',
    'Wrong address',
    'Wrong item/style',
    'Wrong quantity',
    'Wrong shipping option',
    'Wrong size'
  ];

  var checkGetParams = function() {
    if ($stateParams.refund === 'true' && $scope.canDo('refund')) {
      $state.go('.', { refund: 1 }, { notify: false });
      $scope.showEventModal('refund');
    }
  };
  prepareOrderEvents();
  var formatNumberOfEvent = function(number) {
    return Math.round(100 * parseFloat(number));
  };
  $scope.$watch('event.details.trackingCode', function(newValue) {
    if (newValue) {
      var info = trackingUrl(newValue);
      if (info) {
        $scope.event.details.carrier = info.name;
        $scope.event.details.trackingUrl = info.url;
      } else {
        $scope.event.details.carrier = 'not found';
      }
    }
  });

  $scope.$watch('event.details.carrier', function(newValue, oldValue) {
    if (newValue !== oldValue) {
      var carrier = _.find(trackingUrl.carriers, { name: newValue });
      if (carrier && carrier.name) {
        $scope.event.details.carrier = carrier.name;
        $scope.event.details.trackingUrl =
          carrier.url.replace(/\{trackingNumber\}/gi, $scope.event.details.trackingCode);
      }
    }
  });

  $scope.$watch('event.provider', function() {
    if (event && event.type === 'change' && $scope.event && $scope.event.details && $scope.event.details.items) {
      delete $scope.event.details.items;
      delete $scope.event.details.packedItems;
    }
  });
  $scope.$watch('order.loading', function() {
    checkGetParams();
  });

  $scope.$watch('order', function() {
    if ($scope.order && $scope.order.billing && $scope.order.billing.paymentMethod) {
      $scope.paymentInquire = $scope.order.billing.paymentMethod.paymentProvider === 'Citcon';
    }
  });

  $scope.checkFulfillerIds = function(id) {
    return _.filter($scope.order.events, function(e) {
      return (e.type === 'attached-order' || e.type === 'fulfiller-order') && e.details.id === id;
    });
  };
  $scope.isTicket = function() {
    return _.some($scope.order.events, { type: 'ticket' });
  };
  $scope.getTrackingUrl = function(trackingCode) {
    if (!trackingCode) {
      return;
    }
    return trackingUrl(trackingCode.trim());
  };

  $scope.canDo = function(action) {
    var order = $scope.order;
    if (!order || !order.events) {
      return false;
    }
    switch (action) {
      case 'fulfiller-order':
        return (_.find(order.events, { type: 'authorization' }) || isOrderBoughtWithGiftCard(order)) &&
        isOrderRiskApproved(order);
      case 'process':
        return canProcess(order);
      case 'capture':
        return isOrderRiskApproved(order) &&
          !_.find(order.events, { type: 'capture' });
      case 'complete':
        return !_.find(order.events, { type: 'completed' });
      case 'refund':
        return isOrderRiskApproved(order) &&
          _.find(order.events, { type: 'capture' });
      case 'shipping-status':
        return _.find(order.events, { type: 'fulfiller-order' }) &&
          $scope.getPartnersWithDeliverableItems().length > 0;
      case 'risk':
        return !isInternational() &&
          !_.find(order.events, { type: 'force-risk-analysis' }) &&
          !_.find(order.events, { type: 'risk-analysis' });
      case 'attached-order':
        //We can attach basically everything to an order
        return true;
      case 'order-cancelled':
        return !_.find(order.events, { type: 'order-cancelled' }) &&
          !_.find(order.events, { type: 'fulfiller-order' });
      case 'card-issued':
        return !_.find(order.events, { type: 'order-cancelled' }) &&
          !order.completed &&
          !_.find(order.events, { type: 'card-issued' }) &&
          !$scope.processing;
      case 'inquire':
        return _.find(order.events, { type: 'authorization' });
    }

    // INTERNATIONAL
    switch (action) {
      case 'international-shipping-status':
        return _.filter(order.events, { type: 'shipping-status' }).length &&
          _.filter(order.events, { type: 'shipping-status' }).length !==
          _.filter(order.events, { type: 'international-shipping-status' }).length &&
          !$scope.processing;
      case 'send-merchant-order':
        return !_.find(order.events, { type: 'send-order-to-merchant' }) &&
          !$scope.processing;
      case 'send-tracking-code':
        return _.filter(order.events, { type: 'shipping-status' }).length &&
          _.filter(order.events, { type: 'shipping-status' }).length !==
          _.filter(order.events, { type: 'update-tracking-code-to-merchant' }).length &&
          !$scope.processing;
      case 'send-cancel-items':
        return !_.find(order.events, { type: 'cancel-order-to-merchant' }) &&
          _.filter(order.events, { type: 'item-cancelled' }).length &&
          _.filter(order.events, { type: 'item-cancelled' }).length !==
          _.filter(order.events, { type: 'cancel-order-item-to-merchant' }).length &&
          !$scope.processing;
      case 'send-cancel-order':
        return !_.find(order.events, { type: 'cancel-order-to-merchant' }) &&
          _.find(order.events, { type: 'order-cancelled' }) &&
          !$scope.processing;
    }

    //RETURNS
    switch (action) {
      case 'reject-return':
        return !_.find(order.events, { type: 'reject-return' }) &&
          _.find(order.events, { type: 'return-process' });
      case 'return-process':
        return !_.find(order.events, { type: 'reject-return' }) &&
          !_.find(order.events, { type: 'return-process' }) &&
          !_.find(order.events, { type: 'cancel-return' });
      case 'cancel-return':
        return !_.find(order.events, { type: 'reject-return' }) &&
          !_.find(order.events, { type: 'cancel-return' });
      case 'refund-return':
        return _.find(order.events, { type: 'return-process' }) &&
          !_.find(order.events, { type: 'reject-return' }) &&
          !_.find(order.events, { type: 'cancel-return' });
    }

    return false;
  };

  $scope.canDoRisk = function(action, provider) {
    var order = $scope.order;
    if (!order || !order.events) {
      return false;
    }
    switch (action) {
      case 'risk':
        return !isInternationalPayment() &&
          !_.find(order.events, { type: 'risk-analysis', provider: provider });
    }

    return false;
  };

  $scope.canShow = function(action) {
    var order = $scope.order;
    if (!order || !order.events) {
      return false;
    }
    switch (action) {
      case 'risk':
        return !isInternationalPayment();
      case 'international-shipping-status':
      case 'send-merchant-order':
      case 'send-tracking-code':
      case 'send-cancel-items':
      case 'send-cancel-order':
        return isInternational();
      case 'inquire':
        return order.billing && order.billing.paymentMethod && order.billing.paymentMethod.paymentProvider === 'citcon';
      case 'capture':
        return order.totals.totalPrice > 0;
      case 'complete':
        return order.totals.totalPrice === 0;
      default:
        return true;
    }
  };

  $scope.canSubmit = function(action) {
    if (action.match(/shipping-status$/) && $scope.featureFlags.packedItems.enabled) {
      var itemCount = 0;
      if ($scope.event && $scope.event.details && ($scope.event.details.items || $scope.event.details.packedItems)) {
        if ($scope.event.details.packedItems) {
          itemCount = $scope.event.details.packedItems.length;
        } else {
          itemCount = Object.values($scope.event.details.items).reduce(function(a, b) {
            return a + b;
          });
        }
      }
      return itemCount > 0 && $scope.event.details.trackingCode;
    }
    return true;
  };

  $scope.showReturnEventModal = function(type, event) {
    var success = function() {
      $scope.closeModal();
      $scope.loadOrder();
    };
    if (!event) {
      $scope.event = {
        type: type,
        details: {}
      };
    } else {
      $scope.event = _.cloneDeep(event);
    }

    if (type === 'reject-return' || type === 'cancel-return') {
      $scope.submitModal = function() {
        if (!$scope.formPromise.validate($scope.forms.return)) {
          if (!$scope.forms.return.reason.$viewValue || !$scope.forms.return.reason.$viewValue.trim()) {
            $scope.formPromise.setError('Reason must be specified');
          }
          return;
        }
        $scope.event.details.reason = $scope.forms.return.reason.$viewValue || '';
        if (type === 'reject-return') {
          if ($scope.forms.return.otherReason && $scope.forms.return.otherReason.$viewValue) {
            $scope.event.details.otherReason = $scope.forms.return.otherReason.$viewValue;
          }
        }
        $scope.processing = true;
        var orderReturn = { 'number': $scope.orderReturn.number };
        returnService.addEvent(orderReturn, $scope.event).then(function() {
          $scope.processing = false;
          success();
        });
      };
    }
    if (type === 'return-process') {
      $scope.carriers = trackingUrl.carriers;
      $scope.submitModal = function() {
        $scope.processing = true;
        if ($scope.event.details.trackingCode) {
          var info = trackingUrl($scope.event.details.trackingCode);
          $scope.event.details.carrier = info ? info.name : 'not found';
          $scope.event.details.trackingUrl = info ? info.url : null;
        }
        $scope.event.details.returnId = $scope.orderReturn.number;
        returnService.process($scope.event.details.returnId, $scope.event).then(function() {
          $scope.processing = false;
          success();
        });
      };
    }
    if (type === 'return-process-update') {
      type = 'return-process';
      $scope.carriers = trackingUrl.carriers;
      $scope.submitModal = function() {
        $scope.processing = true;
        if ($scope.event.details.trackingCode) {
          var info = trackingUrl($scope.event.details.trackingCode);
          $scope.event.details.carrier = info ? info.name : 'not found';
          $scope.event.details.trackingUrl = info ? info.url : null;
        }
        returnService.updateEvent($scope.orderReturn, $scope.event).then(function() {
          $scope.processing = false;
          success();
        });
      };
    }

    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/' + type + '-event-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });
    $scope.closeModal = function() {
      instance.dismiss();
    };

  };
  $scope.showEventModal = function(type, event) {
    if (!event) {
      $scope.event = {
        type: type,
        details: {}
      };
    } else {
      $scope.event = _.cloneDeep(event);
    }
    $scope.isUpdate = false;

    var saved = false;
    var watcher = -1;
    if (type === 'capture') {
      $scope.event.details.amount = $scope.order.totals.totalPrice / 100;
    }
    if (type === 'refund') {
      $scope.refundableItems = $scope.getRefundableItems();
      $scope.refundableItems[0].show = true;
      $scope.showOrHideRefundableItem = function(index) {
        $scope.refundableItems[index].show = !$scope.refundableItems[index].show;
        if (!$scope.refundableItems[index].show) {
          $scope.event.details.refundReasons = _.toArray($scope.event.details.refundReasons)
            .filter(function(value, i) {
              return i !== index;
            });
          $scope.calculateTotalsRefund();
        } else {
          $scope.event.details.refundReasons[index] = {};
          $scope.event.details.refundReasons[index].product = $scope.refundableItems[index];
        }
      };
      $scope.isRefundReasonSelected = function(item) {
        return !!_.find(
          _.toArray($scope.event.details.refundReasons), function(reason) {
            return item === reason.product;
          });
      };
      $scope.calculateTotalsRefund = function() {
        if ($scope.event && $scope.event.details && $scope.event.details.refundReasons) {
          $scope.event.details.amount = 0;
          $scope.event.details.adjustment = 0;
          _.forEach($scope.event.details.refundReasons, function(reason) {
            if (reason.refundAmount) {
              $scope.event.details.amount += reason.refundAmount * 100;
            }
            if (reason.restockingFee) {
              $scope.event.details.amount -= reason.restockingFee * 100;
            }
            if (reason.adjustment) {
              $scope.event.details.adjustment += reason.adjustment * 100;
            }
          });
        }
        if ($scope.event.details.amount > 0) {
          $scope.event.details.amount = $scope.event.details.amount / 100;
        } else {
          $scope.event.details.amount = 0;
        }
        $scope.event.details.adjustment = $scope.event.details.adjustment / 100;
      };
      if (!$scope.returnFeeValues) {
        $scope.returnFeeValues = {
          total: 0,
          variable: false,
          fulfillers: []
        };
        $scope.returnFeeLoading = $scope.fulfillers.length;
        _.forEach($scope.fulfillers, function (fulfiller) {
          fulfillerService.getById(fulfiller.id).then(function (fulfiller) {
            var total = 0;
            var variable = false;
            if (fulfiller.shippingAndReturns.returnFee) {
              total += convertToNumber(fulfiller.shippingAndReturns.returnFee);
            } else if (fulfiller.shippingAndReturns.returnFeeVariable) {
              variable = true;
            } else if (fulfiller.shippingAndReturns.returnFeePercentage) {
              total += getFeeSubtotalPerItem(
                $scope.order.items,
                fulfiller.id,
                fulfiller.shippingAndReturns.returnFeePercentage
              );
            }
            $scope.returnFeeValues.total += total;
            $scope.returnFeeValues.fulfillers.push({
              id: fulfiller.id,
              total: total,
              variable: variable
            });
            $scope.returnFeeLoading--;
          });
        });

        $scope.$watch('returnFeeLoading', function (val) {
          if (val === 0) {
            $scope.calculateRestockingFee();
          }
        });

        $scope.calculateRestockingFee = function(reason, ix) {
          if (reason && reason.product && reason.product.partner) {
            var restockingFee = _.find($scope.returnFeeValues.fulfillers, function (ff) {
              return reason.product.partner.id === ff.id;
            });
            $scope.event.details.refundReasons[ix].restockingFee = restockingFee.total;
            $scope.restockingFeeVariable = restockingFee.variable;
          } else {
            if (!$scope.event.details.refundReasons) {
              $scope.event.details.refundReasons = [];
            }
            if ($scope.event.details.refundReasons.length === 0) {
              $scope.event.details.refundReasons.push({});
            }
            $scope.event.details.refundReasons[0].restockingFee = $scope.returnFeeValues.total;
            $scope.restockingFeeVariable = $scope.returnFeeValues.variable;
          }
        };

        $scope.$watch('event.details.refundReasons', function (refundReasons, oldValue) {
          if (refundReasons && refundReasons.length) {
            _.forEach(refundReasons, function (refundReason, ix) {
              if ((refundReason && refundReason.product && oldValue && oldValue[ix] && oldValue[ix].product &&
                oldValue[ix].product.id === refundReason.product.id) || (oldValue && oldValue[ix] &&
                !oldValue[ix].product && refundReason && !refundReason.product)) {
                return ;
              }
              $scope.calculateRestockingFee(refundReason, ix);
            });
          } else {
            $scope.calculateRestockingFee();
          }
        }, true);
      }
      $scope.cleanFields = function(index) {
        delete $scope.event.details.refundReasons[index].refundAmount;
        delete $scope.event.details.refundReasons[index].adjustment;
        delete $scope.event.details.refundReasons[index].reason;
        delete $scope.event.details.refundReasons[index].qty;
        delete $scope.event.details.refundReasons[index].partnerRefund;
        delete $scope.event.withoutProducts;
        if (!$scope.event.details.refundReasons[index].product) {
          delete $scope.event.details.refundReasons[index].product;
        }
        $scope.calculateTotalsRefund();
      };
    }
    if (type === 'shipping-status-update') {
      $scope.event.details.trackingCode = '';
    }
    if (type === 'fulfiller-order-update') {
      type = 'fulfiller-order';
      $scope.isUpdate = true;
      $scope.unTouched = true;
      $scope.event.details.dueDate = new Date(event.details.dueDate);
      $scope.event.details.subtotal /= 100;
      $scope.event.details.shippingCost /= 100;
      $scope.event.details.discount /= 100;
      $scope.event.details.tax /= 100;
    }
    if (type === 'attached-order') {
      $scope.event.details.discount = 0;
      $scope.event.details.tax = 0;
    }
    if (type === 'inquire') {
      $scope.event = _.find($scope.order.events, { type: 'authorization' });
      paymentService.getProviderByName('citcon').inquire($scope.event.details.referenceId).then(function(inquire) {
        $scope.inquire = inquire;
      });
    }
    if (type === 'attached-order-update') {
      type = 'attached-order';
      $scope.isUpdate = true;
      $scope.unTouched = true;
      $scope.event.details.subtotal /= 100;
      $scope.event.details.shippingCost /= 100;
      $scope.event.details.discount /= 100;
      $scope.event.details.tax /= 100;
      $scope.showWarningStatus = function() {
        if ($scope.isUpdate) {
          window.alert('This action may affect invoicing, please escalate for review');
        }
      };
    }

    $scope.setUntouched = function(status) {
      $scope.unTouched = status;
    };
    if (type === 'fulfiller-order') {
      $scope.calculateFulfillerTotals = function() {
        $scope.event.details.subtotal = 0;
        $scope.event.details.tax = 0;
        $scope.event.details.discount = 0;
        _.toArray($scope.event.details.items).forEach(function(item) {
          $scope.event.details.subtotal += item.subtotal;
          $scope.event.details.discount += item.discount;
          $scope.event.details.tax += item.tax;
        });
        return (($scope.event.details.shippingCost || 0) +
          $scope.event.details.subtotal +
          $scope.event.details.tax -
          $scope.event.details.discount) * 100;
      };
    }
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/' + type + '-event-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
      if (type === 'delete') {
        if (event.type === 'item-cancelled' &&
          [
            'Customer Request',
            'OOS - Order processed - Brand unable to fulfill',
            'OOS - Order not processed - OOS in the brand page',
            'OOS - Order not processed - Checkout failed due OOS',
            'OOS - Order not processed - Unable to add to cart',
            'OOS - Not enough units',
            'Risk decline',
            'Customer request',
            'Duplicate',
            'Brand site limitation'
          ].indexOf(event.details.reason) > -1) {
          $scope.alertMessage = 'An automatic email has already been sent to ' +
            'the user communicating this cancellation';
        }
      }
    });
    $scope.closeModal = function() {
      if ($scope.event.type === 'shipping-status' && !saved) {
        $scope.loadOrder();
      }
      instance.dismiss();
    };
    $scope.submitModal = function() {
      var success = function() {
        saved = true;
        $scope.closeModal();
        $scope.loadOrder();
      };
      var error = function(error) {
        console.log(error);
        if (error.data.error.message === 'Duplicated trackingCode') {
          $scope.duplicatedTrackingNumber = true;
        }
      };

      if (type === 'ticket') {
        if ($scope.forms.event.$invalid) {
          return;
        }
        var isExpedited = _.some($scope.order.fulfillers, function(fulfiller) {
          return fulfiller.shippingMethod && fulfiller.shippingMethod.cost !== 0;
        });
        var tags = _.map($scope.order.fulfillers, 'id');
        var dueAt = new Date($scope.order.createdAt);

        if (isExpedited) {
          tags.push('expedited');
          dueAt.setDate(dueAt.getDate() + 2);
        } else {
          tags.push('ground');
          dueAt.setDate(dueAt.getDate() + 7);
        }
        ticketService.create({
          number: $scope.order.number,
          expedited: isExpedited,
          tags: tags,
          comment: $scope.event.details.comments,
          dueAt: dueAt.getFullYear() + '-' +
            (parseInt(dueAt.getMonth()) + 1).toString().padStart(2, '0') +
            '-' + dueAt.getDate().toString().padStart(2, '0')
        }).then(function() {
          success();
        });
      }

      if ($scope.event.type === 'refund') {
        $scope.event.details.processed = false;

        if ($scope.event.details.refundReasons) {
          var duplicatedItems = _.filter($scope.event.details.refundReasons, function(reason) {
            var duplicates = _.filter($scope.event.details.refundReasons, function(reason2) {
              return reason2.product === reason.product;
            });
            return duplicates.length > 1;
          });
          if (duplicatedItems.length > 0) {
            $scope.formPromise.setError('You cannot generate two refunds for the same item');
            return;
          }
          if (!$scope.event.withoutProducts) {
            $scope.event.withoutProducts = !_.find(_.toArray($scope.event.details.refundReasons), function(reason) {
              return reason.product;
            });
            if ($scope.event.withoutProducts) {
              return;
            }
          }
        }
        if (_.toArray($scope.event.details.refundReasons).length === 0) {
          $scope.formPromise.setError('Reasons must be defined');
          return;
        }
        if (!$scope.formPromise.validate($scope.forms.event)) {
          _.forEach($scope.event.details.refundReasons, function(reason) {
            if (!reason.qty) {
              $scope.formPromise.setError('Quantity is invalid');
            }
          });
          return;
        }
        if (!$scope.formPromise.validate($scope.forms.event)) {
          return;
        }

      }
      if ($scope.event.type === 'shipping-status') {
        if ($scope.event.details.carrier === 'not found') {
          window.alert('You have to choose a Carrier for the tracking url');
          return false;
        }
      }
      if ($scope.event.type === 'attached-order' && !$scope.isUpdate) {
        if ($scope.forms.event.$invalid) {
          return;
        }
        if ($scope.checkFulfillerIds($scope.event.details.id).length > 0) {
          window.alert('Another order with the same id exists. Please review.');
          return;
        }

        success = function() {
          saved = true;
          if (watcher !== -1) {
            watcher();
          }
          $scope.closeModal();
          $scope.loadOrder();
        };
      }

      if (type === 'delete') {
        $scope.formPromise.handle(orderService.deleteEvent($scope.order, $scope.event)).then(success).catch(error);
        return;
      }
      var event = _.cloneDeep($scope.event);
      event.type = (event.type || 'default').toLowerCase();
      if (event.details.amount) {
        event.details.amount = formatNumberOfEvent(event.details.amount);
      }
      if (event.details.adjustment) {
        event.details.adjustment = formatNumberOfEvent(event.details.adjustment);
      }
      if (event.details.refundReasons) {
        event.details.refundReasons = _.toArray(event.details.refundReasons);
        _.forEach(event.details.refundReasons, function(reason) {
          if (reason.refundAmount) {
            reason.refundAmount = formatNumberOfEvent(reason.refundAmount);
          }
          if (reason.adjustment) {
            reason.adjustment = formatNumberOfEvent(reason.adjustment);
          }
        });
      }
      if (event.details.subtotal) {
        event.details.subtotal = formatNumberOfEvent(event.details.subtotal);
      }
      if (event.details.shippingCost) {
        event.details.shippingCost = formatNumberOfEvent(event.details.shippingCost);
      }
      if (event.details.discount) {
        event.details.discount = formatNumberOfEvent(event.details.discount);
      }
      if (event.details.tax) {
        event.details.tax = formatNumberOfEvent(event.details.tax);
      }
      if (!event.details.partnerId && type.match(/shipping\-status$/)) {
        event.details.partnerId = event.provider;
      }

      if ($scope.featureFlags.packedItems.enabled && type.match(/shipping\-status$/)) {
        var itemsToPack = [];
        for (var itemsToPackSku in event.details.items) {
          if (event.details.items[itemsToPackSku] === 0 || event.details.items[itemsToPackSku] === undefined) {
            delete event.details.items[itemsToPackSku];
          } else {
            var itemsToPackJson = JSON.parse(itemsToPackSku);
            itemsToPack.push({
              id: itemsToPackJson.id,
              productId: itemsToPackJson.productId,
              sku: itemsToPackJson.sku,
              styleId: itemsToPackJson.styleId,
              hash: itemsToPackJson.hash,
              quantity: event.details.items[itemsToPackSku]
            });
          }
        }
        event.details.packedItems = itemsToPack;
        delete event.details.items;
      }

      if (type.match(/fulfiller\-order$/)) {
        if ($scope.forms.event.$invalid) {
          return;
        }
        var itemsToOrder = [];
        for (var itemToOrderSku in event.details.items) {
          if (event.details.items[itemToOrderSku].quantity === 0 || event.details.items[itemToOrderSku] === undefined) {
            delete event.details.items[itemToOrderSku];
          } else {
            var itemToOrderJson = JSON.parse(itemToOrderSku);
            itemsToOrder.push({
              id: itemToOrderJson.id,
              productId: itemToOrderJson.productId,
              sku: itemToOrderJson.sku,
              styleId: itemToOrderJson.styleId,
              hash: itemToOrderJson.hash,
              quantity: event.details.items[itemToOrderSku].quantity,
              subtotal: event.details.items[itemToOrderSku].subtotal * 100,
              discount: event.details.items[itemToOrderSku].discount * 100,
              tax: event.details.items[itemToOrderSku].tax * 100
            });
          }
        }
        event.details.orderedItems = itemsToOrder;
        delete event.details.items;
      }

      if ($scope.orderReturn) {
        $scope.formPromise.handle(returnService.addEvent($scope.orderReturn, event)).then(success).catch(error);
      } else {
        if (event.type === 'refund' && $scope.order.user &&
          Math.floor(event.details.amount / 100) > $scope.order.user.loyalty.remainingPoints &&
          !$scope.loyaltyNegativePoints) {
          $scope.loyaltyNegativePoints = $scope.order.user.loyalty.remainingPoints -
            Math.floor(event.details.amount / 100);
        } else {
          if ($scope.isUpdate || type === 'shipping-status-update') {
            $scope.formPromise.handle(orderService.updateEvent($scope.order, event)).then(success).catch(error);
          } else {
            $scope.formPromise.handle(orderService.addEvent($scope.order, event)).then(success).catch(error);
          }
        }
      }
    };
  };
  $scope.loadingResend = false;
  $scope.reSendShippingStatus = function(event) {
    var reSendModal = window.confirm('Are you sure you want to re-send shipping status to event ' +
      event.provider + ' and ' + event.details.carrier.toUpperCase() +
      ' link?');
    if (reSendModal) {
      $scope.loadingResend = true;
      event.details.dontSendEmailNotification = false;
      window.alert('Sending the notification. Please wait.');
      orderService.updateEvent($scope.order, event).then(function() {
        window.alert('Notification sent.');
        $scope.loadingResend = false;
        $scope.loadOrder();
      }).catch(function(error) {
        window.alert(error);
        $scope.loadingResend = false;
      });
    }
  };

  $scope.process = function() {
    $scope.processing = true;
    orderService.process($scope.order.number);
  };

  $scope.forceAnalysis = function(provider) {
    $scope.processing = true;
    orderService.forceAnalysis($scope.order.number, provider).then(function() {
      $scope.processing = false;
      $scope.loadOrder();
    });
  };

  $scope.generateStripeCreditCard = function() {
    $scope.processing = true;
    orderService.generateStripeCreditCard($scope.order.number).then(function() {
      $scope.processing = false;
      setTimeout(function() {
        $scope.loadOrder();
      }, 500);
    }).catch(function(error) {
      window.alert(error.data.error);
      $scope.processing = false;
      setTimeout(function() {
        $scope.loadOrder();
      }, 500);
    });
  };

  $scope.completeOrder = function() {
    if ($scope.order.totals.totalPrice === 0) {
      var completeEvent = {
        createdAt: new Date(),
        type: 'completed',
        provider: 'giftCard',
        details: { amount: 0 }
      };
      $scope.processing = true;
      orderService.addEvent($scope.order, completeEvent).then(function () {
        setTimeout(function() {
          $scope.loadOrder();
        }, 500);
      }).catch(function (error) {
        window.alert(error.data.error);
      }).finally(function () {
        $scope.processing = false;
      });
    }
  };

  $scope.sendEventToMerchant = function(type) {
    $scope.processing = true;
    orderService.addEvent($scope.order, {
      type: type,
      details: {}
    }).then(function() {
      $scope.processing = false;
      setTimeout(function() {
        $scope.loadOrder();
      }, 500);
    }).catch(function(error) {
      window.alert(error.data.error);
      $scope.processing = false;
      setTimeout(function() {
        $scope.loadOrder();
      }, 500);
    });
  };

  $scope.showEventDetailsModal = function(event) {
    $scope.event = event;
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/event-details-modal.html',
      windowClass: 'admin-modal',
      scope: $scope
    });

    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    $scope.closeModal = function() {
      instance.dismiss();
    };
  };

  $scope.getDetailsToShow = function(event) {
    try {
      event.details = JSON.parse(event.details);
    } catch (err) {
    }
    return JSON.stringify(event.details, undefined, 2);
  };

  $scope.getFulfillerEventCount = function(provider, eventTypes) {
    if (!$scope.order || !$scope.order.events) {
      return 0;
    }
    return $scope.order.events.filter(function(event) {
      return event.provider === provider && _.indexOf(eventTypes, event.type) >= 0;
    }).length;
  };

  $scope.getFulfillerOrderCount = function(provider) {
    return $scope.getFulfillerEventCount(provider, ['fulfiller-order']);
  };

  $scope.getFulfillerInternationalShippingCount = function(provider) {
    return $scope.getFulfillerEventCount(provider, ['international-shipping-status']);
  };

  $scope.hasFulfillerOrder = function(provider) {
    return (provider || '') !== '' && $scope.getFulfillerOrderCount(provider) >= 1;
  };

  $scope.hasFulfillerInternationalShipping = function(provider) {
    return (provider || '') !== '' && $scope.getFulfillerInternationalShippingCount(provider) >= 1;
  };

  $scope.getOrderTotal = function(fulfillerOrderEvent, valuesInCents) {
    if (!fulfillerOrderEvent || !fulfillerOrderEvent.details) {
      return 0;
    }
    var total = (fulfillerOrderEvent.details.subtotal || 0) +
      (fulfillerOrderEvent.details.shippingCost || 0) -
      (fulfillerOrderEvent.details.discount || 0) +
      (fulfillerOrderEvent.details.tax || 0);
    return valuesInCents ? total : total * 100;
  };

  function prepareOrderEvents() {
    var promise = $scope.orderReturnPromise || $scope.orderPromise;
    promise.then(function(order) {
      $scope.events = _.sortBy(order.events.map(prepareOrderEvent), function(e) {
        return e.createdAt * -1;
      });
    });
  }

  $scope.getInternationalSupportedCarriers = function() {
    return ['fedex'];
  };

  $scope.getSupportedCarriers = function() {
    var carriers = _.map(trackingUrl.carriers, 'name');
    carriers.push('not found');
    return carriers;
  };

  $scope.getFormattedDate = function(stringDate) {
    function pad(number) {
      return number < 10 ? '0' + number : number;
    }
    var date = new Date(stringDate);
    return pad(date.getUTCMonth() + 1) +
      '/' + pad(date.getUTCDate()) +
      '/' + date.getUTCFullYear() +
      ' ' + pad(date.getUTCHours()) +
      ':' + pad(date.getUTCMinutes());
  };

  $scope.getItemsCompleteData = function(shippingStatusEvent) {

    // generate list of items if it wasn't generated before
    if (shippingStatusEvent.items === undefined) {
      var packedItems = shippingStatusEvent.details.packedItems;
      // has the packages on the order the specific list of items
      if (packedItems !== undefined) {
        // add only items data specified on the package from the order to the packageInfo
        shippingStatusEvent.items = _.map(packedItems, function(packedItem) {
          var item = _.find($scope.order.items, function(orderItem) {
            return packedItem.productId === orderItem.productId &&
              packedItem.hash === orderItem.hash &&
              packedItem.styleId === orderItem.style.id;
          });
          item = _.cloneDeep(item);
          item.quantity = packedItem.quantity;
          return item;
        });
      } else {
        // add all the items data of the fulfiller specified on the package from the order to the packageInfo
        shippingStatusEvent.items = _.filter($scope.order.items, function(orderItem) {
          return orderItem.partner.id === shippingStatusEvent.provider;
        });
      }
    }

    return shippingStatusEvent.items;

  };

  $scope.getItemsInFulfillerOrder = function(fulfillerOrderEvent) {
    // generate list of items if it wasn't generated before
    if (fulfillerOrderEvent.items === undefined) {
      var eventItems = fulfillerOrderEvent.details.orderedItems;
      // has the packages on the order the specific list of items
      if (eventItems !== undefined) {
        // add only items data specified on the package from the order to the packageInfo
        fulfillerOrderEvent.items = _.map(eventItems, function(eventItem) {
          var item = _.find($scope.order.items, function(orderItem) {
            return eventItem.productId === orderItem.productId &&
              eventItem.hash === orderItem.hash &&
              eventItem.styleId === orderItem.style.id;
          });
          item = _.cloneDeep(item);
          item.quantity = eventItem.quantity;
          return item;
        });
      } else {
        // add all the items data of the fulfiller specified on the package from the order to the packageInfo
        fulfillerOrderEvent.items = _.filter($scope.order.items, function(orderItem) {
          return orderItem.partner.id === fulfillerOrderEvent.provider;
        });
      }
    }
    return fulfillerOrderEvent.items;
  };

  $scope.getItemVariantData = function(item) {
    var values = item.variantKey ? Object.values(item.variantKey) : [];
    values.push(item.quantity);
    return values.join('; ');
  };

  $scope.getProvider = function(event) {
    return event.provider ||
      (event.details && event.details.item && event.details.item.brand && event.details.item.brand.name) ||
      (event.details && event.details.item && event.details.item.partner && event.details.item.partner.name);
  };

  function prepareOrderEvent(event) {
    var type = event.type;
    if (_.indexOf(['fulfiller-order'], type) >= 0) {
      type = 'order';
    }

    var title = [event.provider, type].join(' ');
    if (type === 'default') {
      title = event.details && event.details.title;
    }

    return {
      createdAt: event.createdAt,
      title: title,
      description: '',
      user: event.user,
      details: event.details
    };
  }

  function isOrderBoughtWithGiftCard(order) {
    return order.totals.totalPrice === 0 && (order.discount > order.totals.cost ||
      order.billing.paymentMethod.paymentProvider === 'giftCard');
  }

  function isOrderRiskApproved(order) {
    if (!order || !order.events) {
      return false;
    }

    if (config.orderProcessing && config.orderProcessing.blockOrderProcessingUntilRiskAnalysisResponse === true) {
      // find risk approved event
      return _.some(order.events, function(event) {
        return event.type === 'risk-analysis' &&
          event.provider === config.featureFlags.riskAnalysisToProcess.provider &&
          event.details && event.details.isOrderSafe;
      });
    }

    return true;
  }

  function isInternational() {
    return $scope.order && $scope.order.isInternationalOrder;
  }

  function isInternationalPayment() {
    return !$scope.order || !$scope.order.billing || !$scope.order.billing.paymentMethod ||
      !['stripe', 'paypal'].includes($scope.order.billing.paymentMethod.paymentProvider);
  }

  function convertToNumber(price) {
    return parseInt(price.replace(/[^0-9.-]+/g,''));
  }

  function getFeeSubtotalPerItem(items, fulfillerId, percentage) {
    var value = 0;
    _.forEach(items, function (item) {
      if (item.partnerId === fulfillerId || item.partner.id === fulfillerId) {
        value += percentage*(item.price/100);
      }
    });
    return value;
  }

  function canProcess(order) {
    return isOrderRiskApproved(order) && (!_.some(order.events, {
      type: 'fulfiller-order'
    }) || _.countBy(order.events, {
      type: 'fulfiller-order'
    }).true < $scope.fulfillers.length);
  }
}

module.exports = AdminOrderEventsController;
