var _ = require('lodash-core');

// @ngInject
function AdminShowsController($scope, $state, $stateParams, userService, showService, influencerService) {
  var usedParameters = {};

  $scope.statuses = ['drafted', 'scheduled', 'live', 'posted', 'any'];

  $scope.parameters = {
    term: $stateParams.term,
    status: $stateParams.status || 'any'
  };

  $scope.$watch(
    function() {
      return $stateParams;
    },
    function() {
      _.merge($scope.parameters, _.pick($stateParams, ['term', 'status']));
    }
  );

  $scope.$watch('shows', function () {
    if ($scope.shows && $scope.shows.length) {
      _.forEach($scope.shows, function (show) {
        var count = _.filter(show.showProducts, function (product) { return product.timeToShow; }).length;
        if (!show.schedule) {
          show.schedule = show.createdAt;
        }
        if (count === show.showProducts.length) {
          show.timeAssigned = '✓';
        } else if (count > 0) {
          show.timeAssigned = 'O';
        } else {
          show.timeAssigned = 'X';
        }
      });
    }
  });

  var search = function() {
    var parameters = $scope.parameters;
    if (_.isEqual(parameters, usedParameters) || !$scope.parameters.term) {
      return;
    }
    $scope.loading = true;
    usedParameters = _.cloneDeep(parameters);

    $state.go($state.current.name, parameters, { notify: false });

    if (!parameters.term) {
      delete $scope.shows;
      return;
    }

    var searchParams = {
      term: parameters.term
    };

    delete $scope.shows;
    $scope.shows = [];
    if (parameters.term && /[1234567890abcdef]{24}/.test(parameters.term)) {
      showService
        .getById(parameters.term)
        .then(function(show) {
          influencerService.getById(show.influencerId).then(function(influencer) {
            show.influencerName = influencer.preferredName;
            show.userId = influencer.userId;
            if (
              !['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status) ||
              show.status === $scope.parameters.status
            ) {
              $scope.shows.push(show);
            }
          });
        })
        .finally(function() {
          $scope.loading = false;
        });
      influencerService
        .getShowsById(parameters.term)
        .then(function(shows) {
          if (['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
            shows = shows.filter(function(show) {
              return show.status === $scope.parameters.status;
            });
          }
          if (shows.length) {
            influencerService.getById(parameters.term).then(function(influencer) {
              _.forEach(shows, function(show) {
                show.influencerName = influencer.preferredName;
                show.userId = influencer.userId;
              });
              $scope.shows = _.concat($scope.shows, shows);
            });
          }
        })
        .finally(function() {
          $scope.loading = false;
        });
    } else {
      userService
        .search(searchParams)
        .then(function(userSearchResponse) {
          $scope.loading = !!userSearchResponse.items.length;
          _.forEach(userSearchResponse.items, function(user) {
            influencerService.getByUserId(user.id).then(function(influencer) {
              influencerService.getShowsById(influencer.id).then(function(shows) {
                if (['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
                  shows = shows.filter(function(show) {
                    return show.status === $scope.parameters.status;
                  });
                }
                _.forEach(shows, function(show) {
                  show.influencerName = influencer.preferredName;
                  show.userId = influencer.userId;
                });
                $scope.shows = _.concat($scope.shows, shows);
              });
            });
          });
        })
        .catch(function() {
          $scope.loading = false;
        })
        .finally(function() {
          $scope.loading = false;
        });
    }
  };

  if ($stateParams.term && $stateParams.term !== '') {
    search();
  } else {
    getLastShows();
  }

  function getLastShows() {
    var parameters = $scope.parameters;
    $scope.loading = true;
    usedParameters = _.cloneDeep(parameters);
    $state.go($state.current.name, parameters, { notify: false });

    if (!$scope.parameters.term || $scope.parameters.term === '') {
      showService
        .query({
          status: ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status) ?
            $stateParams.status : 'scheduled,drafted,posted,live',
          limit: 300,
          sort: 'createdAt(d)',
          'hide-test-shows': true
        })
        .then(function(shows) {
          $scope.shows = shows.items.filter(function(show) {
            if (show.showProducts && show.showProducts.length) {
              influencerService.getById(show.influencerId).then(function(influencer) {
                show.influencerName = influencer.preferredName;
                show.userId = influencer.userId;
              });
              $scope.loading = false;
              return true;
            }
          });
        })
        .finally(function() {
          $scope.loading = false;
        });
    }
  }

  $scope.statusChanged = function() {
    $scope.loading = true;
    if ($scope.parameters.term && ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
      search();
    } else {
      getLastShows();
    }
  };

  $scope.sortByField = function (field, isAsc) {
    var order = [];
    if (isAsc) {
      order.push('asc');
    } else {
      order.push('desc');
    }
    $scope.shows = _.orderBy($scope.shows, [field], order);
  };

  $scope.keypress = function($event) {
    if ($event.which === 13) {
      if (!_.isEqual($scope.parameters, usedParameters)) {
        $scope.loading = true;
      }
      if ($scope.parameters.term && ['drafted', 'scheduled', 'live', 'posted'].includes($scope.parameters.status)) {
        search();
      } else {
        getLastShows();
      }
    }
  };
}

module.exports = AdminShowsController;
