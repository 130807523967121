// @ngInject
function ErrorExplodeService($timeout) {

  var enabled = false;
  var visible = false;

  this.isEnabled = function() {
    return enabled;
  };

  this.enable = function() {
    if (enabled) {
      return;
    }
    enabled = true;
    initialize();
  };

  var errors = [];

  this.show = show;
  this.clear = clear;
  this.hide = hide;
  this.log = log;

  function clear() {
    errors = [];
    var modal = document.querySelector('#error-explosion-modal');
    if (modal) {
      modal.remove();
    }
    visible = false;
  }

  function hide() {
    if (!visible) {
      return;
    }
    var modal = document.querySelector('#error-explosion-modal');
    if (!modal) {
      return;
    }
    modal.classList.add('error-explosion-modal-hidden');
    visible = false;
  }

  function show() {
    // create element if it doesn't exist already

    var modal = document.querySelector('#error-explosion-modal');
    var ol;
    if (!modal) {
      modal = document.createElement('div');
      modal.classList.add('error-explosion-modal-hidden');
      modal.setAttribute('id', 'error-explosion-modal');
      ol = document.createElement('ol');
      modal.appendChild(ol);
      document.body.appendChild(modal);
      var closeBtn = document.createElement('button');
      closeBtn.classList.add('close-btn');
      closeBtn.addEventListener('click', function() {
        hide();
      });
      modal.addEventListener('click', function(e) {
        if (e.target !== modal) {
          return;
        }
        hide();
      });
      modal.appendChild(closeBtn);
    } else {
      ol = modal.querySelector('ol');
    }

    // append errors to list element
    var li;
    for (var i = ol.childNodes.length; i < errors.length; i++) {
      li = document.createElement('li');
      var error = errors[i];
      var methodName = error[0];
      var methodArgs = error[1];
      li.classList.add('error-method-' + methodName);
      var nameElem = document.createElement('div');
      nameElem.classList.add('method-name');
      nameElem.textContent = error[0];
      li.appendChild(nameElem);

      var olArgs = document.createElement('ol');
      olArgs.classList.add('error-arguments');
      for (var argIndex = 0; argIndex < methodArgs.length; argIndex++) {
        var liArg = document.createElement('li');
        var arg = methodArgs[argIndex];
        if (typeof arg === 'object') {
          try {
            liArg.textContent = JSON.stringify(arg);
          } catch (err) {
            liArg.textContent = arg.toString();
          }
          liArg.classList.add('json-value');
        } else {
          liArg.textContent = arg.toString();
        }
        olArgs.appendChild(liArg);
      }
      li.appendChild(olArgs);

      ol.appendChild(li);
    }

    // scroll to bottom
    ol.scrollTop = ol.scrollHeight;

    // make it visible
    if (modal.classList.contains('error-explosion-modal-hidden')) {
      setTimeout(function() {
        modal.classList.remove('error-explosion-modal-hidden');
      }, 100);
    }
    visible = true;

    if (li) {
      setTimeout(function() {
        li.scrollIntoView();
      }, 300);
    }
  }

  function shouldIgnore(err) {
    if (Number.isInteger(err)) {
      return true;
    }
    if (angular.equals({}, err)) {
      return true;
    }
    if (!angular.isFunction(err.indexOf)) {
      return true;
    }
    if (err.indexOf('[Channels]') !== -1) {
      return true;
    }
    if (err.indexOf('[$rootScope:inprog]') !== -1) {
      return true;
    }
    if (err.indexOf('Google Maps API') !== -1) {
      return true;
    }
    if (err.indexOf('No reCAPTCHA clients exist') !== -1) {
      return true;
    }
    if (err.indexOf('{}') !== -1) {
      return true;
    }
    if (err.length <= 4) {
      return true;
    }
    if (err.indexOf('Script error.') !== -1) {
      return true;
    }

    if (err && err.status) {
      if ([0, 400, 401, 402, 403, 404, 429].indexOf(err.status) >= 0) {
        return true;
      }
    }
    return false;
  }

  function log(error) {
    if (!enabled) {
      return;
    }
    if (typeof error === 'string') {
      error = ['error', [error]];
    }
    if (shouldIgnore(error[1] && error[1][0])) {
      return;
    }
    errors.push(error);
    $timeout(show, 500);
  }

  function wrapMethods(obj, names) {
    function wrapMethod(obj, name) {
      var originalMethod = obj[name];
      obj[name] = function() {
        log([name, Array.prototype.slice.apply(arguments)]);
        if (!originalMethod) {
          return;
        }
        return originalMethod.apply(obj, arguments);
      };
    }
    for (var i = 0; i < names.length; i++) {
      var name = names[i];
      wrapMethod(obj, name);
    }
  }

  function initialize() {
    // wrap error methods
    wrapMethods(console, ['error']);
    wrapMethods(window, ['onerror']);

    window.addEventListener('keydown', function(e) {
      if (e.keyCode === 27) {
        hide();
      }
    });
  }

}

module.exports = ErrorExplodeService;
