var _ = require('lodash-core');

// @ngInject
function AdminUserAwardsController($scope, formsService, userService) {

  var awardFormPromise = $scope.formPromise = formsService.promiseHandler();

  function clear() {
    $scope.data = {
      points: 0,
      name: '',
      isOrderConditional: false,
      minimumAmount: 0
    };

    awardFormPromise.clear();
    formsService.focusFirstField();
  }

  clear();

  $scope.add = function() {
    if (!awardFormPromise.validate($scope.form)) {
      return;
    }

    var data = _.cloneDeep($scope.data);

    if (data.isOrderConditional !== true) {
      delete data.minimumAmount;
    } else {
      data.minimumAmount = Math.floor(data.minimumAmount * 100);
    }

    awardFormPromise.handle(userService.giveLoyaltyAward($scope.user.id, data).then(function() {
      delete $scope.adding;
      $scope.$emit('awardAdded');
      clear();
    }));

  };

  $scope.beginAdd = function() {
    $scope.adding = true;
  };

  $scope.cancelAdd = function() {
    $scope.adding = false;
  };

}

module.exports = AdminUserAwardsController;
