var _ = require('lodash-core');

/*jshint maxparams: 14 */

// @ngInject
function AdminReelController(
  $scope,
  $state,
  config,
  $stateParams,
  userService,
  showService,
  productService,
  reelService,
  searchService,
  $sce,
  influencerService,
  modalShowService,
  fileUploaderService,
  orderService
) {
  $scope.formPromise = {};
  $scope.isDragging = false;
  $scope.availableTags = ['daily-looks', 'date-night-vibes', 'grwm', 'mane-styles', 'party-time', 'vacation-chic'];
  var videoToSave, videoType, imageToSave, imageType;
  var parentShow = '';


  function loadReel() {
    // load reel
    var reelSalesPromise = reelService.getSales($stateParams.id);
    reelService.getById($stateParams.id).then(function (reel) {
      if (!$scope.reel || !_.isEqual($scope.reel, reel)) {
        $scope.reel = reel;
        influencerService.getById(reel.influencerId).then(function (influencer) {
          userService.getById(influencer.userId).then(function (user) {
            $scope.user = user;
          });
        });
        $scope.products = [];
        reelSalesPromise.then(function (reelSales) {
          _.forEach(reel.showProducts, function (product, ix) {
            productService.getByStyleId(product.styleId).then(function (productDetailsOrigin) {
              var productDetails = _.cloneDeep(productDetailsOrigin);
              productDetails.style = _.find(productDetails.styles, function (style) {
                return style.id === product.styleId;
              });
              productDetails.variant = _.find(productDetails.style.variants, function (variant) {
                return variant.hash === product.hash;
              });

              productDetails.stock = 0;
              _.forEach(productDetails.style.variants, function (variant) {
                if (variant && !variant.outOfStockAt && variant.stock !== 0) {
                  var stock = variant.stock > 0 ? variant.stock: 1;
                    productDetails.stock += stock;
                }
              });

              productDetails.isSimilar = product.isSimilar;
              productDetails.isSimilarTo = product.isSimilarTo;
              productDetails.fromParentShow = product.fromParentShow;
              productDetails.moreFromBrand = product.moreFromBrand;
              productDetails.brandId = productDetails.brand.id;

              productDetails.link = '/' + productDetails.brand.id;
              productDetails.link += '/' + productDetails.style.slug + '-' + productDetails.style.id;
              var productSales = reelSales.products.find(function (productSales) {
                return productSales.styleId === productDetails.style.id;
              });
              productDetails.unconfirmedSales = productSales.amountOfUnconfirmedSales;
              productDetails.confirmedSales = productSales.amountOfConfirmedSales;
              productDetails.timeToShow = product.timeToShow;
              productDetails.position = ix + 1;
              $scope.products[ix] = productDetails;
            });
          });
          $scope.orders = [];
          _.forEach(reelSales.orders, function (orderNumber) {
            var itemsCount = 0;
            orderService.get(orderNumber, $stateParams.token).then(function (order) {
              _.forEach(order.items, function (item) {
                itemsCount += item.quantity;
              });
              $scope.orders.push({
                number: order.number,
                totals: order.totals,
                items: itemsCount,
                completed: order.completed,
              });
            });
          });
          orderService.get($stateParams.orderNumber, $stateParams.token);
        });
        if (reel && reel.parentShow) {
          showService.getById(reel.parentShow).then(function (parentShow) {
            $scope.parentShowProducts = _.filter(parentShow.showProducts, function (showProduct) {
              showProduct.fromParentShow = true;
              return !_.some(reel.showProducts, function (reelProduct) {
                return reelProduct.productId === showProduct.productId && reelProduct.styleId === showProduct.styleId;
              });
            });
            console.log($scope.parentShowProducts);
          });
        }
      }
    });
  }

  $scope.moveProductToReel = function (product) {
    $scope.reel.showProducts.push(product);
    reelService
      .update($scope.reel.id, $scope.reel)
      .then(function () {
        window.location.reload();
      })
      .finally(function () {
        $scope.formPromise.sending = false;
      });
  };

  function loadFields() {
    if ($scope.reel.status) {
      $scope.reelEdit.status = $scope.reel.status;
    }
    if ($scope.reel.title) {
      $scope.reelEdit.title = $scope.reel.title;
    }
    if ($scope.reel.description) {
      $scope.reelEdit.description = $scope.reel.description;
    }
    if ($scope.reel.forDemo) {
      $scope.reelEdit.forDemo = $scope.reel.forDemo;
    }
    if ($scope.reel.isBrandContent) {
      $scope.reelEdit.isBrandContent = $scope.reel.isBrandContent;
    }
    if ($scope.reel.isCommunityContent) {
      $scope.reelEdit.isCommunityContent = $scope.reel.isCommunityContent;
    }
    if ($scope.reel.schedule) {
      $scope.reelEdit.schedule = new Date($scope.reel.schedule);
    }
    if ($scope.reel.s3VideoThumbnails && $scope.reel.s3VideoThumbnails.length) {
      $scope.reelEdit.thumbnail = $scope.reel.s3VideoThumbnails[0];
    }
    if ($scope.reel.tags && $scope.reel.tags.length) {
      $scope.reelEdit.editTags = $scope.reel.tags
        .map(function (tag) {
          return tag.replace('u.', '');
        })
        .filter(function (tag) {
          return $scope.availableTags.includes(tag);
        });
    }
  }

  $scope.onDrop = function (event) {
    event.preventDefault();
    $scope.isDragging = false;

    var file = event.dataTransfer.files[0];
    $scope.loadPreview(file);
  };

  $scope.openFileInput = function (accept) {
    var fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = accept;
    fileInput.onchange = function (event) {
      var file = event.target.files[0];
      $scope.loadPreview(file);
    };
    fileInput.click();
  };

  $scope.loadPreview = function (file) {
    var reader = new FileReader();
    var fileType = file.type;
    if (fileType.includes('video')) {
      reader.onload = function (event) {
        $scope.$apply(function () {
          $scope.loadingVideo = true;
          videoToSave = reader;
          videoType = fileType;
          $scope.videoPreview = $sce.trustAsResourceUrl(event.target.result);
        });
      };
      reader.readAsDataURL(file);
    } else if (fileType.includes('image')) {
      reader.onload = function (event) {
        $scope.$apply(function () {
          $scope.loadingImage = true;
          imageToSave = reader;

          imageType = fileType;
          $scope.imagePreview = $sce.trustAsResourceUrl(event.target.result);
        });
      };
      reader.readAsDataURL(file);
    }
  };

  function uploadFile(fileName, data, fileType, bucket) {
    var splitDataURI = data.split(',');
    var byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    var mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return fileUploaderService.upload(fileName, new Blob([ia], {type: mimeString}), fileType, bucket);
  }

  $scope.addSimilar = function (styleId) {
    $scope.productToAdd = styleId;
    $scope.isSimilar = true;
    $scope.isSimilarTo = styleId;
    $scope.addProduct();
  };

  $scope.addMoreFromBrand = function (styleId) {
    $scope.productToAdd = styleId;
    $scope.moreFromBrand = true;
    $scope.addProduct();
  };

  $scope.searchSimilarProducts = function (product) {
    var productId = product ? product.id : $scope.productSimilarId;
    productService.getSimilarProducts({id: productId }, 20).then(function (results) {
      $scope.similarProducts = _.chunk(results.items, 6);
      _.forEach(results.items, function (item) {
        item.imageUrl = productService.getImageUrl(item.image, {});
        item.link = '/' + item.brand.id;
        item.link += '/' + item.slug + '-' + item.styleId;
      });
    });
    if (product && product.brandId === 'chanel-beauty') {
      return;
    }
    $scope.searchSimilarByTags(productId, 1);
  };

  $scope.searchSimilarByTags = function (productId, page) {
    var productDetails = _.find($scope.products, function (product) {
      return product.id === productId && product.brandId !== 'chanel-beauty';
    });
    if (productDetails) {
      var searchParams = {
        tags: productDetails.tags,
        sort: 'scores-popular',
        limit: 30,
        page: page || 1
      };

      if (productDetails.variant && productDetails.variant.key) {
        searchParams.color = productDetails.variant.key.color;
      }

      if (productDetails.variant && productDetails.variant.price) {
        searchParams.price = {};
        searchParams.price.max = (productDetails.variant.price / 100) * 1.50;
        searchParams.price.min = (productDetails.variant.price / 100) * 0.9;
      }
      searchService.search(searchParams).then(function (results) {
        $scope.similarProductsPerTags = _.chunk(results.items, 6);
        _.forEach(results.items, function (item) {
          item.imageUrl = productService.getImageUrl(item.image.path, {});
          item.link = '/' + item.brand.id;
          item.link += '/' + item.slug + '-' + item.styleId;
        });
      });
    } else {
      delete $scope.similarProductsPerTags;
    }
  };

  $scope.loadRelatedShow = function () {
    if ($scope.reelEdit.parentShow && $scope.reelEdit.parentShow !== parentShow) {
      showService.getById($scope.reelEdit.parentShow).then(function (show) {
        parentShow = $scope.reelEdit.parentShow;
        $scope.showTitle = show.title;
        $scope.reelEdit.influencerId = show.influencerId;
        if (show.s3VideoThumbnails && show.s3VideoThumbnails.length) {
          $scope.imagePreview = show.s3VideoThumbnails[0];
        }
        var error = false;
        _.forEach(show.showProducts, function (showProduct) {
          productService
            .getByStyleId(showProduct.styleId)
            .then(function (productToAdd) {
              if (!productToAdd) {
                window.alert('This show contains invalid products with product: ' + showProduct.styleId);
                return;
              }
              addProductToTable(productToAdd, showProduct.styleId, showProduct.timeToShow);
            })
            .catch(function () {
              if (!error) {
                window.alert('This show contains product without stock that we cant add');
              }
              error = true;
            });
        });
        showService.getReelsByShowId(show.id).then(function (reels) {
          $scope.reelEdit.title = show.title + ' - reel ' + reels.count;
        }).catch(function () {
          $scope.reelEdit.title = show.title + ' - reel ' + Math.random().toString().substr(5);
        });

        $scope.reelEdit.description = show.description;
        $scope.reelEdit.tags = show.tags;
        $scope.loadInfluencer();
      }).catch(function () {
        $scope.showTitle = undefined;
      });
    }
  };

  $scope.loadInfluencer = function () {
    if ($scope.reelEdit.influencerId) {
      influencerService.getById($scope.reelEdit.influencerId).then(function (influencer) {
        $scope.influencerName = influencer.username || influencer.preferredName;
        userService.getById(influencer.userId).then(function (user) {
          $scope.user = user;
        });
      }).catch(function () {
        $scope.influencerName = undefined;
      });
    }
  };

  $scope.removeImage = function () {
    imageToSave = undefined;
    imageType = undefined;
    delete $scope.imagePreview;
    setTimeout(function () {
      loadDragAndDrop();
    }, 1000);
  };

  $scope.removeVideo = function () {
    delete $scope.videoPreview;
    setTimeout(function () {
      loadDragAndDrop();
    }, 1000);
  };

  $scope.curateProducts = function () {
    $scope.curating = true;
    showService.curate($stateParams.id).finally(function () {
      $scope.curating = false;
      window.location.reload();
    });
  };

  $scope.$watch('reel', function () {
    if ($scope.reel) {
      loadFields();
    }
  });

  $scope.save = function () {
    if ($scope.reelEdit.hidden) {
      var reSendModal = window.confirm(
        'If you hide this reel, the reel will also disappear from the administrator page. Do you want to proceed?'
      );
      if (!reSendModal) {
        return;
      }
    }
    $scope.formPromise.sending = true;
    if ($scope.reelEdit.thumbnail) {
      if ($scope.reel.s3VideoThumbnails && $scope.reel.s3VideoThumbnails.length) {
        if ($scope.reelEdit.thumbnail !== $scope.reel.s3VideoThumbnails[0]) {
          $scope.reelEdit.s3VideoThumbnails = _.concat([$scope.reelEdit.thumbnail], $scope.reel.s3VideoThumbnails);
        }
      } else {
        $scope.reelEdit.s3VideoThumbnails = [$scope.reelEdit.thumbnail];
      }
    }
    delete $scope.reelEdit.thumbnail;

    if ($scope.reelEdit.editTags && $scope.reelEdit.editTags.length) {
      $scope.reelEdit.tags = $scope.reelEdit.editTags.map(function (tag) {
        return 'u.' + tag.text;
      });
      delete $scope.reelEdit.editTags;
    }

    reelService
      .update($scope.reel.id, $scope.reelEdit)
      .then(function () {
        window.location.reload();
      })
      .finally(function () {
        $scope.formPromise.sending = false;
      });
  };

  $scope.remove = function () {
    var reSendModal = window.confirm('Are you sure you want to remove this reel?');
    $scope.formPromise.deleting = true;
    if (reSendModal) {
      reelService
        .delete($scope.reel.id)
        .then(function () {
          $state.go('admin.reels');
        })
        .finally(function () {
          $scope.formPromise.deleting = false;
        });
    }
  };

  $scope.getProperties = function (productProps) {
    var props = '';
    if (productProps) {
      if (productProps.isSimilar) {
        props += '|S| ';
      }
      if (productProps.fromParentShow) {
        props += '|FPS| ';
      }
      if (productProps.moreFromBrand) {
        props += '|MFB|';
      }
    }
    return props;
  };

  $scope.removeProduct = function (styleId) {
    if ($scope.reel.showProducts.length === 1) {
      window.alert(
        'You cannot delete this product because it is the only product in the reel, DELETE the entire reel instead'
      );
      return;
    }
    $scope.formPromise.sending = true;
    if ($scope.isNewReel()) {
      $scope.products = $scope.products.filter(function (showProduct) {
        return showProduct.styleId !== styleId;
      });
    } else {
      var reSendModal = window.confirm('Are you sure you want to remove this product?');
      var newProducts = $scope.reel.showProducts.filter(function (showProduct) {
        return showProduct.styleId !== styleId;
      });
      if (reSendModal) {
        reelService
          .update($scope.reel.id, {
            showProducts: newProducts,
          })
          .then(function () {
            $state.reload();
          })
          .finally(function () {
            $scope.formPromise.sending = false;
          });
      }
    }
  };

  $scope.saveTimeToShow = function (product) {
    _.forEach($scope.reel.showProducts, function (showProduct) {
      if (showProduct.styleId === product.style.id && showProduct.timeToShow !== product.timeToShow) {
        showProduct.timeToShow = product.timeToShow;
        reelService.update($scope.reel.id, {showProducts: $scope.reel.showProducts});
      }
    });
  };

  $scope.saveProductPositions = function (product, $index) {
    if ($scope.products.length >= Number(product.position)) {
      moveArrayElement($scope.products, $index, Number(product.position) - 1);
      moveArrayElement($scope.reel.showProducts, $index, Number(product.position) - 1);
      reelService.update($scope.reel.id, {showProducts: $scope.reel.showProducts});
    } else {
      product.position = $index + 1;
    }
  };

  function moveArrayElement(array, elementIndex, indexToMove) {
    if (array && array.length && elementIndex < array.length && indexToMove < array.length) {
      var elementToMove = array[elementIndex];

      if (elementIndex < indexToMove) {
        for (var l = elementIndex; l < indexToMove; l++) {
          array[l] = array[l + 1];
          if (array[l].hasOwnProperty('position')) {
            array[l].position = Number(array[l + 1].position) - 1;
          }
        }
      } else if (elementIndex > indexToMove) {
        for (var r = elementIndex; r > indexToMove; r--) {
          array[r] = array[r - 1];
          if (array[r].hasOwnProperty('position')) {
            array[r].position = Number(array[r - 1].position) + 1;
          }
        }
      }

      array[indexToMove] = elementToMove;
    }
  }

  function loadDragAndDrop() {
    _.forEach(Array.from(document.getElementsByClassName('drop-zone')), function (element) {
      element.addEventListener('drop', function (e) {
        e = e || event;
        e.preventDefault();
        $scope.onDrop(event);
      }, false);
      element.addEventListener('dragover', function (e) {
        e = e || event;
        e.preventDefault();
        $scope.isDragging = true;
        $scope.$apply();
      }, false);
      element.addEventListener('dragleave', function (e) {
        e = e || event;
        e.preventDefault();
        $scope.isDragging = false;
        $scope.$apply();
      }, false);
    });
  }

  $scope.createReel = function () {
    $scope.savingReel = true;
    if (!$scope.influencerName) {
      window.alert('Invalid influencer, please check influencer id');
      $scope.savingReel = false;
      return;
    }
    var newReel = _.clone($scope.reelEdit);
    _.forEach($scope.products, function (tableProduct) {
      var showProduct = _.cloneDeep(tableProduct);
      delete showProduct.variant;
      delete showProduct.id;
      delete showProduct.style;
      delete showProduct.tags;
      newReel.showProducts.push(showProduct);
    });
    if (newReel.tags) {
      newReel.tags = newReel.tags.replace(/ /g, '').split(',');
    }
    newReel.isReel = true;
    if ((!imageType || !imageToSave) && $scope.imagePreview) {
      newReel.s3VideoThumbnails = [$scope.imagePreview];
    }
    var reelId;
    newReel.status = 'drafted';
    newReel.testing = !(config.environment === 'production' && $scope.user &&
      !/orchardmile\+(.*)@gmail\.com/i.test($scope.user.email));
    reelService.create(newReel).then(function (reel) {
      var bucket;
      reelId = reel.id;
      if (config.environment === 'production') {
        bucket = 'orchard-mile-influencer/public/production';
      } else {
        bucket = 'orchard-mile-influencer/public/staging';
      }
      uploadFile('s3-video-' + reel.id, videoToSave.result, videoType, bucket + '/videos/' + reel.id).then(
        function (videoUploaded) {
          if (config.environment === 'production') {
            reel.s3Video = 'https://the-mile-assets.orchardmile.com/videos/';
          } else {
            reel.s3Video = 'https://the-mile-assets-staging.orchardmile.com/videos/';
          }
          reel.s3Video += reel.id + '/' + videoUploaded.Location.split('/')[7];
          reel.status = $scope.reelEdit.status;
          reelService.update(reel.id, reel).then(function () {
            if (imageToSave && imageType) {
              uploadFile('s3-image-' + reel.id, imageToSave.result, imageType, bucket + '/thumbnails/' + reel.id).then(
                function (imageUploaded) {
                  if (config.environment === 'production') {
                    reel.s3VideoThumbnails = ['https://the-mile-assets.orchardmile.com/thumbnails/'];
                  } else {
                    reel.s3VideoThumbnails = ['https://the-mile-assets-staging.orchardmile.com/thumbnails/'];
                  }
                  reel.s3VideoThumbnails[0] = reel.s3VideoThumbnails[0] + reel.id + '/' +
                    imageUploaded.Location.split('/')[7];
                  reelService.update(reel.id, reel).then(function () {
                    $state.go('admin.reel', {id: reel.id});
                  }).catch(function () {
                    if (reelId) {
                      reelService.delete(reelId);
                    }
                  });
                }).catch(function () {
                if (reelId) {
                  reelService.delete(reelId);
                }
              }).finally(function () {
                $scope.savingReel = false;
              });
            } else {
              $state.go('admin.reel', {id: reel.id});
            }
          }).catch(function () {
            if (reelId) {
              reelService.delete(reelId);
            }
          });
        }).catch(function () {
        if (reelId) {
          reelService.delete(reelId);
        }
      }).finally(function () {
        $scope.savingReel = false;
      });
    }).catch(function () {
      $scope.savingReel = false;
      if (reelId) {
        reelService.delete(reelId);
      }
    });
  };

  function addProductToTable(omProduct, styleId, timeToShow) {
    var newProduct = {
      productId: omProduct.id,
      id: omProduct.id,
      timeToShow: timeToShow,
      styleId: styleId,
      tags: omProduct.tags,
      isSimilar: $scope.isSimilar,
      isSimilarTo: $scope.isSimilarTo,
      moreFromBrand: $scope.moreFromBrand,
      hash: omProduct.styles
        .find(function (style) {
          return style.id === styleId;
        })
        .variants.find(function (variant) {
          return variant.stock !== 0;
        }).hash
    };
    newProduct.style = _.find(omProduct.styles, function (style) {
      return style.id === styleId;
    });
    newProduct.variant = omProduct.styles
      .find(function (style) {
        return style.id === styleId;
      })
      .variants.find(function (variant) {
        return variant.stock !== 0;
      });
    $scope.products.push(newProduct);
    refreshTags();
    var showProduct = _.cloneDeep(newProduct);
    delete showProduct.variant;
    delete showProduct.id;
    delete showProduct.style;
    delete showProduct.tags;
    return showProduct;
  }

  function refreshTags() {
    $scope.reelEdit.tags = '';
    _.forEach($scope.products, function (product) {
      _.forEach(product.tags, function (tag) {
        if (!$scope.reelEdit.tags.includes(tag)) {
          if ($scope.reelEdit.tags !== '') {
            $scope.reelEdit.tags += ', ';
          }
          $scope.reelEdit.tags += tag;
        }
      });
    });
  }

  $scope.addProduct = function () {
    if ($scope.reel.showProducts.find(function (showProduct) {
      return showProduct.styleId === $scope.productToAdd;
    }) || $scope.products.find(function (showProduct) {
      return showProduct.styleId === $scope.productToAdd;
    })
    ) {
      window.alert('This product is on the reel already');
      return;
    }
    productService
      .getByStyleId($scope.productToAdd)
      .then(function (productToAdd) {
        if (!productToAdd) {
          window.alert('This styleId does not exist, try another one');
          return;
        }
        var newProduct = addProductToTable(productToAdd, $scope.productToAdd);
        if (!$scope.isNewReel()) {
          $scope.formPromise.sending = true;
          $scope.reel.showProducts.push(newProduct);
          reelService
            .update($scope.reel.id, {
              showProducts: $scope.reel.showProducts,
            })
            .finally(function () {
              $scope.formPromise.sending = false;
              $state.reload();
            });
        }
      })
      .catch(function () {
        window.alert('This styleId does not exist, try another one');
      });
  };

  $scope.getUserClasses = function (user) {
    var classes = [];
    if (user.disabled) {
      classes.push('user-disabled');
    }
    if (user.invite) {
      classes.push('user-invite');
      if (!user.inviteRedeemedAt) {
        classes.push('user-invite-waiting');
      }
    }
    if (user.inviteRedeemedAt) {
      classes.push('user-invite-redeemed');
    }
    return classes.join(' ');
  };

  $scope.openReel = function () {
    modalShowService.openShow($scope.reel.id, $scope, 'admin');
  };

  $scope.isNewReel = function () {
    return $stateParams.id === 'new-reel';
  };

  if (!$scope.isNewReel()) {
    $scope.reelEdit = {};
    loadReel();
  } else {
    setTimeout(function () {
      loadDragAndDrop();
    }, 2000);

    $scope.reelEdit = {status: 'posted', showProducts: []};
    $scope.reel = {
      showProducts: []
    };
    $scope.products = [];
  }

}

module.exports = AdminReelController;
