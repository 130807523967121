var format = require('shared-business-rules').format;

// @ngInject
function CurrencyCentsExtraFilter($rootScope) {

  var filter = function(input) {
    return format.formatCurrencyCents(input, {
      currency: $rootScope.extraCurrency
    });
  };

  filter.$stateful = true;

  return filter;
}

module.exports = CurrencyCentsExtraFilter;

