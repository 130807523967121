var _S = require('underscore.string');

// @ngInject
function PageSummaryNavDirective() {

  var linker = function($scope) {
    var root = {
      level: 1,
      headersLength: 0
    };
    $scope.rootNode = function() {

      var headers = document.querySelectorAll('h2,h3,h4,h5,h6');
      if (root.headersLength === headers.length) {
        return root;
      }
      root.children = [];
      root.headersLength = headers.length;
      var current = root;

      var parentGetter = function(parent) {
        return function() {
          return parent;
        };
      };

      for (var i = 0; i < headers.length; i++) {
        var header = headers[i];
        var match = /^h(\d)$/i.exec(header.tagName);
        var level = parseInt(match[1], 10);
        var headerId = header.getAttribute('id');
        if (!headerId) {
          header.setAttribute('id', headerId = _S.slugify(header.textContent));
        }
        var child = {
          id: headerId,
          title: header.textContent,
          level: level,
          children: []
        };
        while (level < current.level && current.parent) {
          current = current.parent();
        }
        if (level > current.level) {
          child.parent = parentGetter(current);
          current.children.push(child);
          current = child;
        } else {
          child.parent = current.parent;
          current.parent().children.push(child);
          current = child;
        }
      }

      return root;

    };
  };

  return {
    link: linker,
    templateUrl: '/views/partials/page-summary-nav.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = PageSummaryNavDirective;
