// @ngInject
function CheckoutBaseSummaryController($scope, $uibModal, cartService, analyticsService) {
  $scope.cartData = cartService.getData();
  $scope.cartTotals = $scope.cartTotalsAll.purchasable;
  $scope.getDiscountReason = cartService.getDiscountReason;
  $scope.soldOutMessage = cartService.getSoldOutMessage;
  $scope.isForLater = cartService.isForLater;

  $scope.phoneStatus = {
    collapsed: true
  };

  $scope.togglePhoneStatus = function() {
    $scope.phoneStatus.collapsed = !$scope.phoneStatus.collapsed;

    if (!$scope.phoneStatus.collapsed) {
      analyticsService.track('Checkout Phone Summary Expand', {
        items: $scope.cartTotals.quantity,
        total: $scope.cartTotals.totalPrice
      });
    }
  };

  $scope.getCartItems = function() {
    return $scope.cartData.items;

    // return $scope.cartData.items.filter(function(item) {
    //   return !cartService.isSoldOut(item);
    // });
  };

  $scope.getSavedForLaterItems = function() {
    return $scope.cartData.items.filter(function(item) {
      return cartService.isForLater(item);
    });
  };
  $scope.getSavedForLaterItemsQty = function() {
    var qty = 0;
    $scope.getSavedForLaterItems().forEach(function(item) {
      qty += item.quantity;
    });
    return qty;
  };
  $scope.remove = function(item) {
    cartService.remove({
      styleId: item.styleId,
      variant: item,
      quantity: item.quantity,
      forLater: item.forLater,
      source: 'checkout',
      metadata: item.metadata
    });
  };
  $scope.quickView = function(styleId) {
    $scope.quickViewStyleId = styleId;
    $scope.quickViewTrigger = 'checkout';
    $scope.instance = $uibModal.open({
      templateUrl: '/views/partials/modals/product-quick-view.html',
      windowClass: 'product-quick-view-modal',
      size: 'lg',
      scope: $scope
    });
  };
}

module.exports = CheckoutBaseSummaryController;
