
// @ngInject
function SecretService(apiService) {

  this.get = function(key) {
    return apiService.get('/secrets/{key}', {
      pathParams: {
        key: key
      }
    }).then(function(secret) {
      return secret.value;
    });
  };

}
module.exports = SecretService;
