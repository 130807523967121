var _ = require('lodash-core');

// @ngInject
function HomepageCarouselDirective($parse, productService, config) {
  var linker = function($scope, element, attrs) {

    $scope.carousel = window.orchardMileContent.home.homepageCarousel;
    // Testing purpose show styles on carousel on staging
    if (config.environment !== 'production') {
      $scope.carousel.query.styleIds = ['ale4b70724b', 'ale5e268c8e', 'ale807a4f29', 'ale6ffb35fa', 'alec329ae10',
        'ale5c9e760b', 'ale14f72503', 'ale27c0585d'];
    }
    var carouselConfig = attrs.carouselconfig ? $parse(attrs.carouselconfig)($scope) : null;

    $scope.filters = {};
    $scope.result = {};
    $scope.responsive = carouselConfig.breakpoints;
    $scope.productListName = 'homepage-carousel';

    var styleIds = carouselConfig.query.styleIds ? carouselConfig.query.styleIds : null;
    var defaultSort = carouselConfig.query.defaultSort || '';

    var loading = true;

    $scope.ready = function() {
      return !loading;
    };

    var loadProducts = _.memoize(function(params) {

      loading = true;

      /*if (!params.searchReady) {
        return setData({items:[], count:0, facets:{}, ready:false});
      }*/

      // load products
      productService
        .search({
          searchTerm: params.searchTerm,
          styleIds: styleIds,
          limit: params.limit,
          page: params.page,
          sizes: params.sizes,
          colors: params.colors,
          tags: _.union(params.tags, params.userTags),
          brand: params.brand,
          sort: params.sort,
          sale: params.sale,
          discount: params.discount,
          gifts: params.gifts,
          price: params.price
        }).then(function(data) {

        data.ready = true;
        setData(data);
      });
    });

    function getParams() {
      var userTags = !carouselConfig.query.userTags ? [] : _.map(carouselConfig.query.userTags.split(','),
        function(tag) {
          return 'u.' + tag.replace(/^\s+/, '').replace(/\s+$/, '');
        });

      var params = {};
      return {
        tags: carouselConfig.query.tags,
        userTags: userTags,
        brand: carouselConfig.query.brand,
        sizes: carouselConfig.query.sizes,
        colors: carouselConfig.query.colors,
        searchTerm: carouselConfig.query.searchTerm || '',
        searchReady: $scope.search ? $scope.search.ready : true,
        sort: carouselConfig.query.sort || defaultSort,
        sale: carouselConfig.query.sale,
        discount: carouselConfig.query.discount,
        gifts: carouselConfig.query.gifts,
        page: carouselConfig.query.page || 1,
        limit: carouselConfig.query.limit || 8,
        price: getPrice(params.price)
      };
    }

    loadProducts(getParams());

    function setData(data) {

      $scope.result.items = data.items;
      $scope.result.count = data.count;
      $scope.result.facets = data.facets;
      $scope.result.ready = data.ready;
      $scope.result.index = data.index;

      if (carouselConfig && carouselConfig.productOrder && carouselConfig.productOrder.length) {
        var dbList = _.compact(data.items);
        var unorderedItems = _.difference(_.map(dbList, 'styleId'), carouselConfig.productOrder);
        var orderedList = carouselConfig.productOrder.concat(unorderedItems);

        dbList.sort(function(a, b) {
          return orderedList.indexOf(a.styleId) - orderedList.indexOf(b.styleId);
        });
        $scope.products = dbList;

      } else {
        $scope.products = _.compact(data.items);
      }
      loading = false;
    }

    function getPrice(price) {
      if (carouselConfig.query.minPrice) {
        price.min = $parse(carouselConfig.query.minPrice)($scope);
      }
      if (carouselConfig.query.maxPrice) {
        price.max = $parse(carouselConfig.query.maxPrice)($scope);
      }
      return price;
    }
  };
  return {
    link: linker, scope: true, templateUrl: '/views/partials/homepage-carousel.html', restrict: 'E'
  };
}

module.exports = HomepageCarouselDirective;
