var _ = require('lodash-core');
var sharedBusinessRules = require('shared-business-rules');

function prepareBasicCartData(data) {
  data.items = data.items || [];
  data.shipping = data.shipping || {};
  data.billing = data.billing || {};
}

function areSameVariant(item, item2) {
  return item.styleId === item2.styleId &&
    _.isEqual(item.variantKey, item2.variantKey) &&
    _.isEqual(item.metadata, item2.metadata);
}

function mergeDuplicates(data) {
  var items = data.items;
  var length = items.length;
  for (var i = 0; i < length; i++) {
    var item = items[i];
    for (var j = i + 1; j < length; j++) {
      var itemj = items[j];
      if (areSameVariant(item, itemj) && item.forLater === itemj.forLater) {
        item.quantity += itemj.quantity;
        items.splice(j, 1);
        length--;
      }
    }
    if (item.quantity < 1) {
      items.splice(i, 1);
      i--;
    }
  }
}

function groupByVariant(itemGroups) {
  var variants = [];
  for (var groupName in itemGroups) {
    var group = itemGroups[groupName];
    if (!group) {
      continue;
    }
    for (var i = 0; i < group.length; i++) {
      var groupItem = group[i];
      var variantFound = false;
      for (var j = 0; j < variants.length; j++) {
        if (areSameVariant(variants[j].item, groupItem)) {
          variantFound = true;
          if (variants[j][groupName]) {
            // duplicate, combine quantities
            variants[j][groupName].quantity += groupItem.quantity;
          } else {
            variants[j][groupName] = groupItem;
          }
          break;
        }
      }
      if (!variantFound) {
        var variant = {
          item: groupItem
        };
        variant[groupName] = groupItem;
        variants.push(variant);
      }
    }
  }
  return variants;
}

function threeWayMerge(originalJson, serverJson, localJson) {
  var original = JSON.parse(originalJson) || {};
  var server = JSON.parse(serverJson) || {};
  var local = JSON.parse(localJson) || {};

  // in general, local wins
  var result = _.merge(_.cloneDeep(server), _.cloneDeep(local));

  // but for items, merge changes
  var variantsByVersion = groupByVariant({
    original: original.items,
    server: server.items,
    local: local.items
  });

  var mergedItems = [];
  variantsByVersion.forEach(function(variant) {
    var quantity = 0;
    var item = variant.item;
    if (variant.server) {
      quantity = variant.server.quantity;
    }
    if (variant.local) {
      quantity += variant.local.quantity;
      // prefer local item data
      item = variant.local;
    }
    if (variant.original) {
      quantity -= variant.original.quantity;
    }
    item = _.cloneDeep(item);
    item.quantity = quantity;
    if (quantity > 0) {
      mergedItems.push(item);
    }
  });
  result.items = mergedItems;
  return result;
}

function isPurchasable(item) {
  return !isSoldOut(item) && isBrandVisible(item) && isBrandAvailable(item) && !isForLater(item);
}

function isOverMaxQty(item) {
  return item.getBrandItemMaxQty() < item.quantity || item.getMaxQty() < item.quantity;
}
function isForLater(item) {
  return item.forLater === true;
}
function isSoldOut(item) {
  if (item.stock === 0) {
    return true;
  }
  var variant = item && typeof item.variant === 'function' && item.variant();
  return variant && variant.stock === 0;
}

function isBrandVisible(item) {
  var brand = item && typeof item.getBrand === 'function' && item.getBrand();
  return brand && brand.visible;
}

function isBrandAvailable(item) {
  var brand = item && typeof item.getBrand === 'function' && item.getBrand();
  return brand && !brand.unavailable;
}

function calculateTotals(order) {

  // calculate totals with all items
  var totals = sharedBusinessRules.order.calculateTotals(order);

  // calculate totals for purchasable items
  var orderClone = _.cloneDeep(order);
  orderClone.items = _.filter(orderClone.items, isPurchasable);
  totals.purchasable = sharedBusinessRules.order.calculateTotals(orderClone);

  return totals;
}

exports.areSameVariant = areSameVariant;
exports.prepareBasicCartData = prepareBasicCartData;
exports.mergeDuplicates = mergeDuplicates;
exports.threeWayMerge = threeWayMerge;
exports.calculateTotals = calculateTotals;
exports.isPurchasable = isPurchasable;
exports.isOverMaxQty = isOverMaxQty;
exports.isSoldOut = isSoldOut;
exports.isForLater = isForLater;
exports.isBrandAvailable = isBrandAvailable;
exports.isBrandVisible = isBrandVisible;
