// @ngInject
function TrendStoriesDirective(staticAssetService) {
  var linker = function($scope) {
    $scope.getImageUrl = function(url) {
      return staticAssetService.url(url);
    };
    $scope.config = window.orchardMileContent.home.trendStories;
  };
  return {
    link: linker,
    templateUrl: '/views/partials/trend-stories.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = TrendStoriesDirective;
