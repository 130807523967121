var _ = require('lodash-core');

// @ngInject
function AttributionService(campaignService, localStorageService) {

  AttributionService.prototype.calculateItemAttribution = function (item) {
    var influencerId;
    var contentId;
    var reason;
    // 1 - get last campaign
    var campaign = getLastVisitedCampaign();
    if (campaign) {
      var watchedContentFromCampaign = findCampaignOnWatchedContent(campaign);
      influencerId = getInfluencerId(campaign, watchedContentFromCampaign);
      contentId = getShowId(campaign, watchedContentFromCampaign);
      reason = 'referral-' + getReasonType(watchedContentFromCampaign, campaign);
    } else {
      var watchedContent = findLastWatchedContent();
      var show;
      var store;
      // 2 - added from show/reel/store/pdp/plp/others attributed to last show
      if (!influencerId && watchedContent.lastWatchedContent === 'show') {
        show = watchedContent.lastWatchedShowWithItem;
        influencerId = show.influencerId;
        contentId = show.showId;
        reason = show.isReel ? 'reel' : 'show';
        if (show.status === 'live') {
          reason = 'live-' + reason;
        }
      }
      // 3 - added from show/reel/store/pdp/plp/others attributed to last store
      if (!influencerId && watchedContent.lastWatchedContent === 'store') {
        store = watchedContent.lastWatchedStoreWithItem;
        influencerId = store.id;
        contentId = store.id;
        reason = 'store';
      }
    }

    if (influencerId) {
      attributeItemTo(influencerId, item, reason, contentId);
    }
  };

  function getReasonType(watchedContent, campaign) {
    if (campaign && campaign.type) {
      return campaign.type;
    }
    if (watchedContent && watchedContent.type) {
      return watchedContent.type;
    }
    return 'unknown';
  }

  function getShowId(campaign, watchedContent) {
    if (campaign.idType === 'show') {
      return campaign.id;
    }
    if (campaign.contentType === 'show' && campaign.contentId) {
      return campaign.contentId;
    }
    if (watchedContent) {
      return watchedContent.showId;
    }
  }

  function getInfluencerId(campaign, watchedContent) {
    if (campaign.idType === 'influencer') {
      return campaign.id;
    }
    if (campaign.contentType === 'influencer') {
      return campaign.contentId;
    }
    if (watchedContent) {
      return watchedContent.influencerId;
    }
  }

  function findCampaignOnWatchedContent(campaign) {
    var content;
    var watchedShows = getSessionVariableArray('watched_shows');
    var watchedStore = getSessionVariableArray('watched_store');

    _.forEach(watchedShows, function (wShow) {
      if (wShow && isDateInLast24Hours(wShow.watchedAt) && !content) {
        if (campaign.id === wShow.showId || campaign.contentId === wShow.showId ||
          campaign.contentId === wShow.influencerId || campaign.id === wShow.influencerId) {
          content = {
            show: wShow,
            type: 'show'
          };
        }
      }
    });

    _.forEach(watchedStore, function (wStore) {
      if (wStore && isDateInLast24Hours(wStore.watchedAt) && !content) {
        if (campaign.id === wStore.id || campaign.contentId === wStore.id) {
          content = {
            store: wStore,
            type: 'store'
          };
        }
      }
    });

    return content;
  }

  function findLastWatchedContent() {
    var watchedShows = getSessionVariableArray('watched_shows');
    var watchedStore = getSessionVariableArray('watched_stores');
    var status = {
      watchedShowsWithItem: [],
      watchedStoresWithItem: [],
      lastWatchedShowWithItem: undefined,
      lastWatchedStoreWithItem: undefined,
      lastWatchedContent: undefined
    };
    _.forEach(watchedShows, function (wShow) {
      if (wShow.influencerId && isDateInLast24Hours(wShow.watchedAt)) {
        status.watchedShowsWithItem.push(wShow);
      }
    });

    if (status.watchedShowsWithItem.length) {
      status.lastWatchedShowWithItem = _.maxBy(status.watchedShowsWithItem, 'watchedAt');
      status.lastWatchedContent = 'show';
    }

    _.forEach(watchedStore, function (wStore) {
      if (wStore.id && isDateInLast24Hours(wStore.watchedAt)) {
        status.watchedStoresWithItem.push(wStore);
      }
    });

    if (status.watchedStoresWithItem.length) {
      status.lastWatchedStoreWithItem = _.maxBy(status.watchedStoresWithItem, 'watchedAt');
      if ((status.lastWatchedContent || status.lastWatchedContent === 'show') &&
        new Date(status.lastWatchedStoreWithItem.watchedAt) >
        new Date(status.lastWatchedShowWithItem.watchedAt)) {
          status.lastWatchedContent = 'store';
      }
    }

    return status;
  }

  function isDateInLast24Hours(date) {
    if (date) {
      var last24HoursDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      if (typeof date === 'string' || date instanceof String) {
        return new Date(date) > last24HoursDate;
      } else {
        return date > last24HoursDate;
      }
    }
  }

  function getLastVisitedCampaign() {
    var campaigns = campaignService.getUTMParametersLast24Hs();
    if (campaigns && campaigns.length) {
      return parseCampaign(campaigns.reverse()[0]);
    }
  }

  function parseCampaign(campaign) {
    if (campaign && campaign.utmCampaign) {
      var campaignObj = {};
      var match = campaign.utmCampaign.match(/-(\w*)(?:-|$)/);
      campaignObj.id = match ? match[1] : null;
      if (campaignObj.id) {
        if (campaign.utmCampaign.includes('show-')) {
          campaignObj.idType = 'show';
        } else if (campaign.utmCampaign.includes('reel-')) {
          campaignObj.idType = 'reel';
        } else if (campaign.utmCampaign.includes('product-')) {
          campaignObj.idType = 'product';
        } else if (campaign.utmCampaign.includes('influencer-')) {
          campaignObj.idType = 'influencer';
        }
        if (campaignObj.idType === 'influencer') {
          if (campaign.utmCampaign.includes('show')) {
            campaignObj.type = 'show';
          } else if (campaign.utmCampaign.includes('reel')) {
            campaignObj.type = 'reel';
          } else if (campaign.utmCampaign.includes('product')) {
            campaignObj.type = 'product';
          } else {
            campaignObj.type = 'store';
          }
        }
        if (campaign.utmContent) {
          var matchContent = campaign.utmContent.match(/-(\w*)(?:-|$)/);
          campaignObj.contentId = matchContent ? matchContent[1] : null;
          if (campaign.utmContent.includes('show')) {
            campaignObj.contentType = 'show';
          } else if (campaign.utmContent.includes('reel')) {
            campaignObj.contentType = 'reel';
          } else if (campaign.utmContent.includes('product')) {
            campaignObj.contentType = 'product';
          } else if (campaign.utmContent.includes('influencer')) {
            campaignObj.contentType = 'influencer';
          }
        } else {
          if (campaign.utmCampaign.includes('-show')) {
            campaignObj.contentType = 'show';
          } else if (campaign.utmCampaign.includes('-reel')) {
            campaignObj.contentType = 'reel';
          } else if (campaign.utmCampaign.includes('-product')) {
            campaignObj.contentType = 'product';
          } else if (campaign.utmCampaign.includes('-influencer')) {
            campaignObj.contentType = 'influencer';
          }
        }
        return campaignObj;
      }
    }
  }

  function getSessionVariableArray(name) {
    var watchedShows = localStorageService.getItem(name);
    if (watchedShows) {
      return JSON.parse(watchedShows);
    } else {
      return [];
    }
  }

  function attributeItemTo(influencerId, item, reason, contentId) {
    item.attributedTo = influencerId;
    item.attributionReason = {
      type: reason,
      id: contentId
    };
    item.attributedAmount = (item.variant ? item.variant.price: item.price) * 0.1 * item.quantity;
  }

}

module.exports = AttributionService;
