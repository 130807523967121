var AsYouType = require('libphonenumber-js').AsYouType;

// @ngInject
function PhoneNumberFormatDirective() {
  function link($scope, elem, attr, ctrl) {
    var formatter = new AsYouType('US');

    function removeFormat(value) {
      return value.replace(/(^\+1|[^\d])/g, '');
    }

    function applyMask(value) {
      return formatter.reset().input(removeFormat(value).slice(0, 10)).replace(/[^0-9]*$/, '');
    }

    ctrl.$parsers.push(function(value) {
      if (ctrl.$isEmpty(value)) {
        return value;
      }

      var formatted = applyMask(value);
      var clean = removeFormat(formatted);

      if (formatted !== ctrl.$viewValue) {
        ctrl.$setViewValue(formatted);
        ctrl.$render();
      }

      return clean;
    });

    ctrl.$formatters.push(function(value) {
      if (ctrl.$isEmpty(value)) {
        return value;
      }
      return applyMask(value);
    });
  }

  return {
    link: link,
    restrict: 'A',
    require: 'ngModel'
  };
}

module.exports = PhoneNumberFormatDirective;
