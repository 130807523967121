// @ngInject
function ValidateStateAndZipCodeDirective(cartService) {

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {
      function validate(viewValue) {
        var err = cartService.getTotals().taxCalculationError;
        ctrl.$setValidity('stateAndZipCode', !err);
        return viewValue;
      }
      ctrl.$parsers.unshift(validate);

      cartService.events.on('taxCalculationFailure', validate);
      cartService.events.on('taxCalculationSuccess', validate);

      scope.$on('$destroy', function() {
        cartService.events.removeListener('taxCalculationFailure', validate);
        cartService.events.removeListener('taxCalculationSuccess', validate);
      });
    }
  };
}

module.exports = ValidateStateAndZipCodeDirective;
