var _ = require('lodash-core');

// @ngInject
function ColorFilterDirective() {
  var linker = function($scope, element, attrs) {

    $scope.status = {};
    $scope.status.openColor = false;
    $scope.colors = JSON.parse(attrs.normalizedColors).items;
    $scope.where = attrs.where;

    var filters = $scope.filters;
    var sources = $scope.sources;

    sources.colors = $scope.colors;

    $scope.isVisible = isVisible;

    $scope.onContextReady(function() {

      $scope.$watch('facets.colors', function() {
        if (!$scope.facets || !$scope.facets.colors ||
          !$scope.facets.colors.colors || !$scope.facets.colors.colors) {
          return;
        }

        var facets = _.map(Object.keys($scope.facets.colors.colors), function(each) {
          return each && each.toLowerCase();
        });

        _.forEach(sources.colors, function(opt) {
          opt.disabled = !(facets && facets.indexOf(opt.normalizedColor) >= 0);
        });
      }, true);

      // watch first category level change
      $scope.$watch('filters.category[0]', loadSources);

      // watch selection for changes
      $scope.$watch('sources.colors', updateColorsFilter, true);
      $scope.$watch('filters.colors', updateColorsSource, true);
    });

    // private

    function isVisible() {
      var count = sources && sources.colors && sources.colors.filter(function(i) {
        return !i.disabled;
      }).length;
      return !!count;
    }

    function loadSources() {
      sources.colors = $scope.colors;

      updateColorsSource();
    }

    function updateColorsSource() {
      _.forEach(sources.colors, function(opt) {
        opt.selected = filters.colors.indexOf(opt.normalizedColor) > -1;
      });

      if (_.filter(sources.colors, { selected: true }).length) {
        $scope.status.openColor = true;
      }
    }

    function updateColorsFilter() {
      if (sources.colors.length) {
        var selectedColors = _.map(_.filter(sources.colors, { selected: true }), 'normalizedColor').join(',');
        if (filters.colors.join(',') !== selectedColors) {
          filters.colors = _.compact(selectedColors.split(','));
        }
      }
    }

  };
  return {
    link: linker,
    templateUrl: '/views/partials/color-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ColorFilterDirective;
