function ShareASaleAdapter() {}

ShareASaleAdapter.prototype.attach = function(analyticsEmitter) {
  /*jshint -W032 */
  function insertShareASaleScript() {
    $('#sasscript').remove();
    // Create an async script.
    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.id = 'sasscript';
    /* jshint ignore:start */
    script.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'www.dwin1.com/19038.js';
    script.setAttribute('defer', 'defer');
    // Insert our script next to the first script element.
    var scripts = document.head.getElementsByTagName('script');
    var last = scripts[scripts.length - 1];
    last.parentNode.insertBefore(script, last.nextSibling);
    /* jshint ignore:end */
  }
  function shareASaleConversion(params) {
    $('#sas-conversion-1').remove();

    var image1 = document.createElement('img');
    image1.id = 'sas-conversion-1';
    image1.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'www.shareasale.com/sale.cfm?merchantID=97758&transtype=sale&tracking=' +
      params.orderId +
      '&amount=' +
      params.value;

    image1.setAttribute('height', '1');
    image1.setAttribute('width', '1');
    image1.style.borderStyle = 'none';
    document.body.appendChild(image1);
  }
  insertShareASaleScript();

  analyticsEmitter.on('page', function() {
    insertShareASaleScript();
  });

  analyticsEmitter.on('track', function(name, parameters) {
    parameters = parameters || {};
    if (name === 'Completed Order') {
      shareASaleConversion({
        value: parseFloat(parameters.revenue).toFixed(2),
        orderId: parameters.orderId
      });
    }
  });
};

module.exports = new ShareASaleAdapter();
