// @ngInject
function ErrorService($state, $timeout, userService, $location) {

  this.pageError = function(err, status) {
    var errorStatus = typeof status === 'number' ? status : err.status;
    return this.showErrorPage({ status: errorStatus });
  }.bind(this);

  this.notFound = function(message, categories, styleId, comingFrom) {
    return this.showErrorPage({
      status: 404,
      message: message,
      categories: categories,
      'oos-style-id': styleId,
      'coming-from': comingFrom
    });
  }.bind(this);

  this.notAllowedForYourCountry = function (categories) {
    return this.showErrorPage({ status: 403, categories: categories, 'forbidden-country': true });
  };

  this.showErrorPage = function(params) {
    // TODO we should log these in GA
    return $state.go('root.error', params, { location: false }).catch(function(err) {
      // TODO this shouldn't happen, find a way to block or queue other state transitions
      // TODO log this with bug tracker
      // transitions might be superseded and that's a problem
      console.log('State transition failed for the first time', err);
      // try again in 100ms
      $timeout(function() {
        $state.go('root.error', params, { location: false })
          .catch(function(err) {
            console.log('State transition failed with the second try', err);
          });
      }, 100);
    });
  }.bind(this);

  userService.events.on('loggedIn', function() {
    if ($state.current.name === 'root.error') {
      setTimeout(function() {
        console.log('reloading with new credentials...');
        var state = JSON.parse(sessionStorage.getItem('prev.state.current'));
        var params = JSON.parse(sessionStorage.getItem('prev.state.params'));
        if ($location.hash()) {
          params['#'] = $location.hash();
        }
        $state.go(state, params, { reload: true, inherit: false });

        //$state.reload();
      }, 1000);
    }
  });

}

module.exports = ErrorService;
