var format = require('shared-business-rules').format;

// @ngInject
function CurrencyCentsValueFilter($rootScope) {

  var filter = function(input) {
    return format.formatCurrencyCents(input, {
      valueOnly: true,
      decimals: 2,
      decimalsSeparator: '.',
      thousandsSeparator: '',
      country: $rootScope.country,
      currency: $rootScope.currency
    });
  };

  filter.$stateful = true;

  return filter;
}

module.exports = CurrencyCentsValueFilter;
