var _ = require('lodash-core');

// @ngInject
function AdminExperiencesController($scope, $state, loyaltyExperienceService) {

  $scope.experiences = loyaltyExperienceService.get({
    includeInactive: true,
    includeDeleted: true,
    includeExhausted: true
  }).lazyArray();

  $scope.filteredExperiences = $scope.experiences;

  $scope.toggleActiveOnly = function() {
    if ($scope.activeOnly) {
      $scope.filteredExperiences = _.filter($scope.experiences, $scope.isActive);
    } else {
      $scope.filteredExperiences = $scope.experiences;
    }
  };

  $scope.create = function() {
    $state.go('admin.experience', { id: 'create' });
  };

  $scope.delete = function(id) {
    _.remove($scope.experiences, function(each) {
      return each.id === id;
    });

    $scope.toggleActiveOnly();
    loyaltyExperienceService.deleteById(id);
  };

  $scope.isExpired = function(experience) {
    if (!experience.expiresAt) {
      return false;
    }
    return new Date(experience.expiresAt) < new Date();
  };

  $scope.hasntStarted = function(experience) {
    if (!experience.beginsAt) {
      return false;
    }
    return new Date(experience.beginsAt) > new Date();
  };

  $scope.isActive = function(experience) {
    return !($scope.isExpired(experience) || $scope.hasntStarted(experience) || experience.active === false);
  };

  $scope.parseExpiration = function(experience) {
    return new Date(experience.expiresAt);
  };

  $scope.getActiveCodesCount = function(experience) {
    var codes = experience.codes || [];
    var activeCodes = codes.filter(function(code) {
      return code.deleted !== true && code.redeemed !== true;
    });
    return activeCodes.length;
  };

}

module.exports = AdminExperiencesController;
