// @ngInject
function AdminBrandPageController($scope, $state, $stateParams, brandService, fulfillerService, formsService) {

  $scope.form = {};

  var formPromise = $scope.formPromise = formsService.promiseHandler();

  function loadPartner() {
    var brandPromise = brandService.getByIdWithoutMemoize($stateParams.brandId);
    brandPromise.then(function(brand) {
      $scope.form.brand = brand;
      $scope.form.plpMessage = brand.plpMessage ? brand.plpMessage.replace(/<br>/g, '\n') : '';
      $scope.form.overrideFulfillerPlpMessage = brand.overrideFulfillerPlpMessage;
      $scope.form.additionalNotes = brand.additionalNotes ? brand.additionalNotes.replace(/<br>/g, '\n') : '';
      $scope.form.brandAvailable = !brand.unavailable;
    });
    var partnerPromise = fulfillerService.getByIdWithoutMemoize($stateParams.partnerId);
    partnerPromise.then(function(partner) {
      $scope.showOverride = partner.brands.length > 1;
    });
  }
  loadPartner();

  $scope.save = function() {
    $scope.form.brand.id = $stateParams.brandId;
    if (!formPromise.validate($scope.form)) {
      return;
    }
    var dataToUpdate = {};

    dataToUpdate.id = $scope.form.brand.id;
    dataToUpdate.unavailable = !$scope.form.brandAvailable;
    dataToUpdate.plpMessage = $scope.form.plpMessage ?
      $scope.form.plpMessage.replace(/\n/g, '<br>') : null;
    dataToUpdate.overrideFulfillerPlpMessage = $scope.form.overrideFulfillerPlpMessage;
    dataToUpdate.additionalNotes = $scope.form.additionalNotes ?
      $scope.form.additionalNotes.replace(/\n/g, '<br>') : null;
    formPromise.handle(brandService.update(dataToUpdate).then(
      function() {
        $state.go('admin.partner-pages');
      })
    );
  };

}

module.exports = AdminBrandPageController;
