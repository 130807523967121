var _ = require('lodash-core');
var sharedBusinessRules = require('shared-business-rules');

// This controller will serve with basic methods to both the desktop and phone versions of the checkout

/* globals $, angular, Promise */
/* jshint maxparams: 17 */

// @ngInject
function CheckoutBaseController($rootScope, $scope, $state, $transitions, cartService, config, platformService,
                                paymentService, userService, creditCardService, promoService, citiesService,
                                shoppingContextService, pendingOrderService, globalizationService, translationService) {

  var pendingOrder;
  cartService.setUsingGiftCard();
  $scope.paymentMethodNotReady = false;
  $scope.cartData = cartService.getData();

  $scope.cartTotals = cartService.getTotals();

  $scope.$watchCollection(function() {
    return $state.params;
  }, function() {
    /*jshint camelcase: false */
    if ($state.params['pending-order-payment-intent'] && $scope.shippingOptions.loaded) {
      $scope.loadShippingOptions();
    }
  });

  $scope.goToStep = function(step, options) {
    options = options || {};
    if (!canGoToStep(step)) {
      return;
    }
    if ($scope.cartTotals.totalPrice !== 0) {
      updateTotalPrice();
    }
    if (step === 'billing') {
      translateKoreanNameAndConfirm();
      $scope.paymentMethodNotReady = $scope.cartTotals.totalPrice !== 0;
      setTimeout(function() {
        $scope.$apply();
      }, 100);
      if ($scope.validSteps.shipping) {
        // preload the payment provider
        if (
          !window.stripeElements ||
          (
            !config.payment.allProviders.testMode &&
            (
              (
                window.stripeElements._keyMode !== 'test' && userService.userIsTester()
              ) ||
              (
                window.stripeElements._keyMode === 'test' && !userService.userIsTester()
              )
            )
          )
        ) {
          delete window.stripeElements;
          delete window.Stripe;
        }
        paymentService.loadProvider(!window.stripeElements).then(function() {
          if ($scope.cartTotals.totalPrice !== 0) {
            $scope.initializeInternationalPaymentMethods();
            if ($scope.canShowPaymentProvider('stripe')) {
              $scope.initializePaymentIntents().then(function() {
                $scope.billingOptionChosen();
              });
            } else {
              $scope.internationalBillingOptionChosen();
            }
          }
        });
      }
    }
    if (step === 'review') {
      if (cartService.getTotals().taxes === 0) {
        cartService.reCalculateTotals();
      }
      globalizationService.setCurrency(true);
    }
    if (!$rootScope.isShow()) {
      $state.go('root.checkout.' + step, {'payment-provider': $state.params['payment-provider']});
    } else {
      $scope.stepToValidate = step;
      updateCurrentStep();
    }
    if (!options.keepScroll) {
      try {
        $scope.scrollToTop();
      } catch (e) {
      }
    }
  };

  function translateKoreanNameAndConfirm() {
    var koreanRegex = /[\u3131-\uD79D]/ugi;
    var fullNameToTranslate = '';
    if ($scope.shipping.contact.firstName && koreanRegex.test($scope.shipping.contact.firstName)) {
      fullNameToTranslate += $scope.shipping.contact.firstName;
    }
    if ($scope.shipping.contact.lastName && koreanRegex.test($scope.shipping.contact.lastName)) {
      fullNameToTranslate += '|' + $scope.shipping.contact.lastName;
    }
    if (fullNameToTranslate) {
      $scope.translatePopUpOpened = true;
      translationService
        .translateName($scope.shipping.contact.firstName, $scope.shipping.contact.lastName, 'ko')
        .then(function(response) {
          $scope.shipping.contact.fullName = response.fullName;
          $scope.shipping.contact.firstName = response.fullName.split(' ')[0];
          $scope.shipping.contact.lastName = response.fullName.split(' ')[1];
          $('#english-name-confirmation').modal();
          // Hiding the modal if the user press back button
          window.addEventListener('popstate', function() {
            $scope.translatePopUpOpened = false;
            $('#english-name-confirmation').modal('hide');
          });
        });
    }
  }

  $scope.closeEnglishNameConfirmationModal = function() {
    $scope.translatePopUpOpened = false;
  };

  $scope.isNameValid = function () {
    return (
      /^[a-zA-Z][a-zA-Z.\-\s]+$/g.test($scope.shipping.contact.firstName) &&
      /^[a-zA-Z][a-zA-Z.\-\s]+$/g.test($scope.shipping.contact.lastName)
    );
  };

  $scope.$watch('shipping.address.state', function(newValue, oldValue) {
    if (newValue === oldValue) {
      return;
    }
    cartService.reCalculateTotals();
  });

  $scope.isConfirmation = function() {
    return $scope.step === 'confirmation' || $rootScope.confirmation;
  };

  function getPendingOrderDataByPaymentIntentId() {
    if ($state.params['pending-order-payment-intent']) {
      return pendingOrderService.getByPaymentIntentId($state.params['pending-order-payment-intent']);
    }
  }

  $scope.isPreConfirmation = function() {
    return $state.current.name === 'root.checkout.pre-confirmation' || $rootScope.preConfirmation;
  };

  $scope.mustShowEmptyCartMessage = function() {
    return !$rootScope.placingOrder && !$scope.isPreConfirmation() && !$scope.isConfirmation() &&
      getPurchasableItems().length < 1;
  };

  $rootScope.isInternationalShipping = function() {
    return $scope.shipping && $scope.shipping.address.country !== 'us' && $scope.shipping.address.country;
  };

  $scope.isStateInput = function() {
    if ($scope.shipping.address.country && $rootScope.featureFlags.internationalShipping.enabled) {
      var countrySelected = _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
        return $scope.shipping.address.country.toLowerCase() === country.code ;
      });
      return countrySelected && !['specific'].includes(countrySelected.state);
    }
    return false;
  };

  $scope.isStateInputForBilling = function() {
    if ($scope.billing.address.country && $rootScope.featureFlags.internationalShipping.enabled) {
      var countrySelected = _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
        return $scope.billing.address.country.toLowerCase() === country.code ;
      });
      return !countrySelected || (countrySelected && !['specific'].includes(countrySelected.state));
    }
    return false;
  };

  $scope.isStateRequired = function() {
    if ($scope.shipping.address.country && $rootScope.featureFlags.internationalShipping.enabled) {
      var countrySelected = _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
        return $scope.shipping.address.country.toLowerCase() === country.code ;
      });
      return countrySelected && ['required', 'specific'].includes(countrySelected.state);
    }
    return true;
  };

  $scope.isStateRequiredForBilling = function() {
    if ($scope.billing.address.country && $rootScope.featureFlags.internationalShipping.enabled) {
      var countrySelected = _.find($rootScope.featureFlags.internationalShipping.countries, function(country) {
        return $scope.billing.address.country.toLowerCase() === country.code ;
      });
      return !countrySelected || countrySelected && ['required', 'specific'].includes(countrySelected.state);
    }
    return true;
  };

  $scope.countryToShip = function() {
    return $scope.shipping && $scope.shipping.address.country;
  };

  $scope.isCheckoutBlocked = function() {
    return _.find($scope.cartData.items, function(item) {
      return !item.forLater && item.getBrandItemMaxQty() < item.quantity;
    });
  };

  /*jshint sub:true*/
  $scope.canShowPaymentProvider = function(type) {
    if (type === 'stripe') {
      return (['USD', 'KRW', 'AUD', 'CAD'].includes($rootScope.currencySelected()) ||
        !$rootScope.featureFlags.internationalPayments.enabled) && ($state.params['payment-provider'] !== 'citcon');
    }
    if (type === 'citcon') {
      /*jshint camelcase: false */
      return $rootScope.featureFlags.citcon.enabled && $rootScope.featureFlags.internationalPayments.enabled &&
        (!$rootScope.featureFlags.citcon.urlOnCheckout || $state.params['payment-provider'] === 'citcon');
    }
  };

  $scope.isPhone = platformService.isPhone();

  $scope.loadShippingOptions = function() {
    var user = userService.getUser();
    if (!user || user.anonymous) {
      if ($state.params['pending-order-payment-intent']) {
        fillShippingAddressByPaymentIntent([]);
      }
      $scope.shippingOptions.loaded = true;
      return;
    }
    /*jshint camelcase: false */
    if ($state.params['pending-order-payment-intent'] && pendingOrder === undefined) {
      $scope.shippingOptions.loaded = false;
      $scope.shippingOptions.shippingCityLoaded = false;
    }

    if (!$scope.shippingOptions.loaded) {
      var userId = user.id;
      userService.getShippingOptions().then(function(options) {
        user = userService.getUser();
        if (user.anonymous || user.id !== userId) {
          return;
        }
        options = options || [];
        if (!$rootScope.featureFlags.internationalShipping.enabled) {
          options = _.filter(options, function(option) {
            return !option.address.country || option.address.country.toLowerCase() === 'us';
          });
        }
        if ($scope.shippingOptions) {
          $scope.shippingOptions.options = options;
          if ($state.params['pending-order-payment-intent']) {
            fillShippingAddressByPaymentIntent(options);
          } else {
            $scope.shippingOptions.selected = options.length ? 0 : -1;
            $scope.shippingOptions.loaded = true;
          }
          if ($scope.shippingOptions.selected > -1) {
            $rootScope.shipping = _.cloneDeep($scope.shippingOptions.options[$scope.shippingOptions.selected]);
          }
        }
      });
    }
  };

  function preloadShippingAddress(shipping) {
    if ($state.params['pending-order-payment-intent']) {
      setTimeout(function() {
        $scope.shipping = _.merge($scope.shipping, _.clone(shipping));
        $scope.shipping.newCity = $scope.shipping.address.city;
        setTimeout(function() {
          $scope.shipping.address.state = shipping.address.state;
        }, 200);
      }, 500);
    }
  }

  function fillShippingAddressByPaymentIntent(options) {
    getPendingOrderDataByPaymentIntentId().then(function(pendingOrderSaved) {
      pendingOrder = pendingOrderSaved;
      if (pendingOrderSaved) {
        var optionSelected = -1;
        options.forEach(function(option, ix) {
          if (_.isEqual(pendingOrderSaved.shipping, _.omit(option, ['id']))) {
            optionSelected = ix;
          }
        });
        if (optionSelected > -1) {
          $scope.shippingOptions.selected = optionSelected;
        } else {
          preloadShippingAddress(pendingOrderSaved.shipping);
          $scope.shippingOptions.selected = -1;
        }
      } else {
        $scope.shippingOptions.selected = options.length ? 0 : -1;
      }
      $scope.shippingOptions.loaded = true;
    }).catch(function() {
      $scope.shippingOptions.selected = options.length ? 0 : -1;
      $scope.shippingOptions.loaded = true;
    });
  }

  $scope.loadBillingOptions = function() {
    var user = userService.getUser();
    if (!user || user.anonymous) {
      $scope.billingOptions.loaded = true;
      $rootScope.shippingBillingFlags.useShippingAddressAsBillingAddress = true;
      return;
    }
    if ($scope.billingOptions.loaded) {
      $scope.billingOptions.selected = -1;
    } else {
      var userId = user.id;
      userService.getBillingOptions().then(function(options) {
        user = userService.getUser();
        if (user.anonymous || user.id !== userId) {
          return;
        }
        options = options || [];
        if ($scope.billingOptions) {
          $scope.billingOptions.options = options;
          $scope.billingOptions.selected = getDefaultPaymentMethod(options);
          $scope.billingOptions.loaded = true;

          if ($scope.billingOptions.selected > -1) {
            $rootScope.billing = _.cloneDeep($scope.billingOptions.options[$scope.billingOptions.selected]);
          }
        }
        $rootScope.shippingBillingFlags.useShippingAddressAsBillingAddress = true;
      });
    }
  };

  $scope.shippingInternationally = function() {
    return $scope.shipping.address &&
      $scope.shipping.address.country &&
      $scope.shipping.address.country.toLowerCase() !== 'us';
  };

  $scope.shippingAllowedInternationally = function() {
    return $scope.shipping.address &&
      $scope.shipping.address.country &&
      _.some($rootScope.featureFlags.internationalShipping.countries, function(country) {
        return country.code.includes($scope.shipping.address.country.toLowerCase());
      });
  };

  $scope.shippingIsValid = function() {
    if ($scope.shipping && $scope.shipping.address) {
      // used address book from external provider (eg. amazon)
      // using a payment method from external provider
      if ($scope.shipping.addressBookProvider && $scope.shipping.addressBookProvider.ready === false) {
        // external address book is not ready
        return false;
      }
      // check country (international shipping is not supported)
      $scope.validSteps.shipping = !$scope.shippingInternationally() || $scope.shippingAllowedInternationally();
    }
    if ($scope.forms.shipping) {
      $scope.validSteps.shipping = $scope.forms.shipping.$valid;
    }
    return !!$scope.validSteps.shipping;
  };

  $scope.billingIsValid = function() {
    var paymentMethodError = document.getElementById('payment-method-errors');
    var newPaymentMethodIsOk = !paymentMethodError || !paymentMethodError.textContent;
    $scope.validSteps.billing = $scope.forms.billing ?
      ($scope.forms.billing.$valid && newPaymentMethodIsOk) : true;
    var errors = angular.element('.error-summary');
    if (!$scope.validSteps.billing) {
      errors
        .removeClass('has-no-errors')
        .addClass('has-errors')
        .css('display', 'block');
    } else {
      errors
        .removeClass('has-errors')
        .addClass('has-no-errors')
        .css('display', 'none');
    }
    return !!$scope.validSteps.billing;
  };

  $scope.giftCardAndPromoCodeAreValid = function() {
    var form = $scope.forms.giftCardPromoCode;
    $scope.validGiftCardPromoCode = form ? form.$valid : true;
    return $scope.validGiftCardPromoCode;
  };

  $scope.getPromoReward = function() {
    if (!$scope.promo.data || !$scope.promo.data.id || !$scope.cartData.rewards) {
      return;
    }
    var rewards = $scope.cartData.rewards.items;
    return _.find(rewards, function(reward) {
      return reward.promoId === $scope.promo.data.id;
    });
  };

  $scope.initializePaymentIntents = function() {
    var totals = shoppingContextService.getTotals();
    var cachedUser = userService.getUser();
    var userPromise = new Promise(function(resolve) {
      if (cachedUser.guest) {
        resolve(cachedUser);
      } else {
        resolve(userService.getById(cachedUser.id));
      }
    });
    return userPromise.then(function(user) {
      return initializePaymentIntent(user, totals);
    });
  };

  $scope.mountPaymentElement = function(paymentIntent) {
    if (paymentIntent) {
      $scope.paymentMethodNotReady = true;
      setTimeout(function() {
        $scope.$apply();
      }, 100);
      $rootScope.paymentIntent = paymentIntent;
      var externalPaymentMethodTypes = [];
      if ($rootScope.countrySelected() === 'us') {
        externalPaymentMethodTypes.push('external_paypal');
      }
      window.stripePaymentElement = window.stripeElements.elements({
        /*jshint camelcase: false */
        clientSecret: paymentIntent.client_secret,
        externalPaymentMethodTypes: externalPaymentMethodTypes,
        locale: $rootScope.country ? $rootScope.country.language : 'en',
        fonts: [
          {
            cssSrc: 'https://static-staging.orchardmile.com/styles/main.css'
          }
        ],
        appearance: {
          theme: 'stripe',
          variables: {
            fontFamily: '\'OMBR\',Arial,sans-serif',
            fontSizeBase: '15px',
            borderRadius: '0px'
          }
        }
      });
      window.paymentElement = window.stripePaymentElement.create('payment', {
          // paymentMethodOrder: ['card', 'external_paypal', 'afterpay_clearpay', 'affirm', 'klarna', 'cashapp'],
          fields: {
            billingDetails: 'never'
          },
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
          },
          wallets: {
            googlePay: 'never',
            applePay: 'never'
          }
        }
      );
      setTimeout(function() {
        window.paymentElement.mount('#payment-element');
        $scope.paymentMethodNotReady = false;
        var nameOnCard = $scope.billing.contact ?
          _.compact([$scope.billing.contact.firstName, $scope.billing.contact.lastName]).join(' ') :
          _.compact([$scope.shipping.contact.firstName, $scope.shipping.contact.lastName]).join(' ');
        setTimeout(function() {
          $scope.$apply();
          $scope.billing.paymentMethod.nameOnCard = nameOnCard;
        }, 100);
        window.paymentElement.on('change', function(event) {
          if ($scope.billingOptions.selected === -1) {
            $rootScope.paymentFormCompleted = !!event.complete;
          }
          var selectedPaymentMethod = event.value && event.value.type;
          if (selectedPaymentMethod === 'external_paypal') {
            $scope.payWithPayPal = true;
          }
          setTimeout(function() {
            $rootScope.$apply();
          }, 100);
        });
      }, 100);
    }
  };

  $rootScope.clearUserBillingData = function() {
    $rootScope.billing = {
      // no address means use shipping address
      // address: {},
      paymentMethod: {}
    };
  };

  $rootScope.clearUserCheckoutData = function() {
    // clear all user's shipping/billing from root scope (useful when logging out)
    $rootScope.shipping = {
      contact: {
        email: userService.getUser().email
      },
      address: {}
    };
    $rootScope.billing = {
      // no address means use shipping address
      // address: {},
      paymentMethod: {}
    };
    $rootScope.giftCard = {};
    $rootScope.shippingOptions = {
      options: [],
      selected: -1
    };
    $rootScope.billingOptions = {
      options: [],
      selected: -1
    };
    $rootScope.shippingBillingFlags = {
      useShippingAddressAsBillingAddress: true
    };
    paymentService.clearUserCheckoutData();
  };

  function getPurchasableItems() {
    return $scope.cartData.items.filter(cartService.isPurchasable);
  }

  function getCaptureConfig() {
    if (['kr'].includes($rootScope.countrySelected())) {
      return 'automatic';
    } else {
      return 'manual';
    }
  }

  function calculateTotalPrice(totalPrice) {
    if ($rootScope.country && $rootScope.country.currency !== 'USD' && $rootScope.currency) {
      return Math.round((totalPrice/100) * $rootScope.currency.conversionRate *
        Math.pow(10, $rootScope.currency.decimals));
    } else {
      return totalPrice;
    }
  }

  $scope.initializeInternationalPaymentMethods = function() {
    if (config.featureFlags.internationalPayments.enabled) {
      $scope.internationalPaymentOptions = [];
      _.forEach(config.featureFlags.internationalPayments.paymentMethods,
        function(paymentMethod) {
          if ($rootScope.country.currency === paymentMethod.currency &&
            $scope.canShowPaymentProvider(paymentMethod.provider)) {
            _.forEach(paymentMethod.methods, function(method) {
              $scope.internationalPaymentOptions.push({
                provider: paymentMethod.provider,
                id: method.id,
                logo: method.logo,
                data: method.data,
                authKey: method.authKey
              });
            });
          }
        });
    }
  };
  /*jshint camelcase: false */
  function initializePaymentIntent(user, totals) {
    var payment_method_types = ['card'];
    if ($rootScope.countrySelected() === 'kr') {
      payment_method_types.push('south_korea_market');
    } else if (!$rootScope.countrySelected() || $rootScope.countrySelected() === 'us') {
      payment_method_types.push('cashapp');
      if (totals.purchasable.totalPrice >= 1100 && totals.purchasable.totalPrice <= 200000) {
        payment_method_types.push('afterpay_clearpay');
      }
      if (totals.purchasable.totalPrice >= 5000 && totals.purchasable.totalPrice <= 3000000) {
        payment_method_types.push('affirm');
      }
      if (totals.purchasable.totalPrice >= 1000 && totals.purchasable.totalPrice <= 100000) {
        payment_method_types.push('klarna');
      }
    } else if ($rootScope.countrySelected() === 'au') {
    } else if ($rootScope.countrySelected() === 'ca') {
    }

    var items = _.filter(cartService.getData().items, function(item) { return !item.forLater; });
    items = _.map(items, function(item) {
      if (item.style && typeof item.style === 'function') {
        return item.quantity;
      }
    }).reduce(function(a, b){
      return a + b;
    });

    var paymentIntentRequest = {
      currency: $rootScope.country ? $rootScope.country.currency.toLowerCase() : 'usd',
      customer: user.stripeId,
      amount: calculateTotalPrice(totals.purchasable.totalPrice),
      /*jshint camelcase: false */
      payment_method_types: payment_method_types,
      statement_descriptor: items + ' Item' + (items > 0 ? 's': '') + ' The Mile',
      //receipt_email: $scope.shipping.contact.email,
      shipping: {
        address: {
          line1: $scope.shipping.address.address1,
          line2: $scope.shipping.address.address2,
          city: $scope.shipping.address.city,
          country: $scope.shipping.address.country || 'US',
          postal_code: $scope.shipping.address.zip,
          state: $scope.shipping.address.state
        },
        name: $scope.shipping.contact.firstName + ' ' + $scope.shipping.contact.lastName,
        phone: $scope.shipping.contact.phoneNumber
      },
      capture_method: getCaptureConfig(),
      metadata: {
        testing: userService.userIsTester().toString(),
        user_email: $scope.shipping.contact.email,
        messagingClientReferenceId: $rootScope.userIdStripe
      }
    };
    var stripeProvider = paymentService.getProviderByName('stripe');
    return stripeProvider.generateStripePaymentIntent(paymentIntentRequest).then(function(paymentIntent) {
      $rootScope.paymentIntentNew = paymentIntent;
    });
  }

  function updateCurrentStep() {
    var step;
    if ($rootScope.isShow() && $scope.stepToValidate) {
      step = $scope.stepToValidate;
    } else {
      var match = /checkout\.([\w]+)/.exec($state.current.name);
      if (!match) {
        $scope.goToStep('shipping');
        return;
      }
      step = match[1];
    }
    $scope.step = step;
    if (!canGoToStep(step)) {
      // go back to an allowed step
      var index = $scope.steps.indexOf($scope.step);
      while (index >= 0 && !canGoToStep(step)) {
        index--;
        step = $scope.steps[index];
      }
      $scope.goToStep(step);
    } else {
      setNewStep(step);
    }
  }

  function canGoToStep(step) {
    if (!step) {
      return false;
    }
    if (step !== 'shipping' && step !== 'confirmation' && !getPurchasableItems().length) {
      return false;
    }
    if (step === 'confirmation' && !$rootScope.placedOrderNumber) {
      return false;
    }
    if (step === 'review' && !($scope.shippingIsValid() && $scope.billingIsValid() && $scope.paymentFormCompleted)) {
      return false;
    }
    return !(step === 'billing' && !$scope.shippingIsValid());
  }

  function setNewStep(step) {
    cartService.checkoutStepEnter(step);
    var stepName = step.charAt(0).toUpperCase() + step.slice(1);
    $scope.setPageMetadata({title: stepName + ' | CHECKOUT'});

    // reset order placing results
    delete $rootScope.placingOrderError;
  }

  function getDefaultPaymentMethod(options) {
    // select a non expired payment method as default
    for (var i = 0; i < options.length; i++) {
      if (!isCardExpired(options[i].paymentMethod)) {
        return i;
      }
    }
    return -1;
  }

  function isCardExpired(paymentMethod) {
    var now = new Date();
    if (paymentMethod.expirationYear > now.getFullYear()) {
      return false;
    }
    if (paymentMethod.expirationYear < now.getFullYear()) {
      return true;
    }
    return paymentMethod.expirationMonth < (now.getMonth() + 1);
  }

  function updateTotalPrice(forceRestart) {
    if (!['shipping', 'pre'].includes($scope.step) && $rootScope.paymentIntent) {
      $scope.paymentMethodNotReady = true;
      setTimeout(function() {
        $scope.$apply();
      }, 100);
      if (!$rootScope.paymentIntent.payment_method) {
        if (forceRestart) {
          var stripeProvider = paymentService.getProviderByName('stripe');
          stripeProvider.cancelStripePaymentIntent($rootScope.paymentIntent).then(function() {
          });
          $scope.initializePaymentIntents().then(function() {
            $scope.mountPaymentElement($rootScope.paymentIntentNew);
            $scope.paymentMethodNotReady = false;
            if ($scope.step !== 'billing') {
              $scope.goToStep('billing');
            }
            setTimeout(function() {
              $scope.$apply();
            }, 100);
          });
        } else {
          $scope.paymentMethodNotReady = false;
        }
      } else {
        updateTotalPriceOnPaymentIntent($rootScope.paymentIntent).then(function(paymentIntent) {
          $rootScope.paymentIntent = paymentIntent;
          $scope.paymentMethodNotReady = false;
          setTimeout(function() {
            $scope.$apply();
          }, 100);
        });
      }
    }
  }

  function updateTotalPriceOnPaymentIntent(paymentIntentToBeUpdated) {
    if (
      paymentIntentToBeUpdated &&
      paymentIntentToBeUpdated.amount &&
      $scope.cartTotals.totalPrice !== paymentIntentToBeUpdated.amount
    ) {
      var paymentIntentToUpdate = {
        amount: calculateTotalPrice($scope.cartTotals.totalPrice) || 50,
        metadata: paymentIntentToBeUpdated.metadata,
      };
      return paymentService
        .getProviderByName('stripe')
        .updateStripePaymentIntent(paymentIntentToBeUpdated.id, paymentIntentToUpdate)
        .then(function(paymentIntent) {
          if ($rootScope.paymentIntent.id === paymentIntent.id) {
            $scope.mountPaymentElement($rootScope.paymentIntent);
          }
          return paymentIntent;
        });
    } else {
      return Promise.resolve(paymentIntentToBeUpdated);
    }
  }

  $scope.internationalBillingOptionChosen = function() {
    if ($scope.internationalPaymentOptions && $scope.internationalPaymentOptions.length &&
      !$scope.billingOptions.selected) {
      $scope.billingOptions.selected = $scope.internationalPaymentOptions[0].id;
    }
    if ($scope.internationalPaymentOptions && _.some($scope.internationalPaymentOptions, function(payment) {
      return $scope.billingOptions.selected === payment.id;
    })) {
      $scope.payWithStripe = false;
      $scope.payWithCitcon = true;
      $rootScope.paymentFormCompleted = true;
    } else {
      $scope.payWithCitcon = false;
    }
    $scope.paymentMethodNotReady = false;
  };

  $scope.billingOptionChosen = function() {
    var editedBillingAddress = $rootScope.billing;
    $rootScope.billing = _.cloneDeep($scope.billingOptions.options[$scope.billingOptions.selected]) ||
      {paymentMethod: {}, address: $scope.billing.address, contact: $scope.billing.contact};
    delete $scope.validSteps.billing;
    if ($scope.billingOptions.selected > -1) {
      $scope.paymentMethodNotReady = true;
      $scope.payWithStripe = true;
      setTimeout(function() {
        $scope.$apply();
      }, 100);
      $rootScope.billing.paymentMethod.cvv = '';
      if (!$rootScope.shippingBillingFlags.useShippingAddressAsBillingAddress && editedBillingAddress) {
        $rootScope.billing = editedBillingAddress;
      }
      var totals = shoppingContextService.getTotals();
      var cachedUser = userService.getUser();
      $rootScope.paymentFormCompleted = true;
      userService.getById(cachedUser.id).then(function(user) {
        var paymentIntentRequest = {
          currency: $rootScope.country ? $rootScope.country.currency.toLowerCase() : 'usd',
          customer: user.stripeId,
          amount: calculateTotalPrice(totals.purchasable.totalPrice),
          /*jshint camelcase: false */
          automatic_payment_methods: {
            enabled: true
          },
          payment_method: $scope.billingOptions.options[$scope.billingOptions.selected].paymentMethod.paymentProviderId,
          // Enable if we want to send receipts to the customers
          // receipt_email: $scope.shipping.contact.email,
          shipping: {
            address: {
              line1: $scope.shipping.address.address1,
              line2: $scope.shipping.address.address2,
              city: $scope.shipping.address.city,
              country: $scope.shipping.address.country || 'US',
              postal_code: $scope.shipping.address.zip,
              state: $scope.shipping.address.state
            },
            name: $scope.shipping.contact.firstName + ' ' + $scope.shipping.contact.lastName,
            phone: $scope.shipping.contact.phoneNumber
          },
          capture_method: getCaptureConfig(),
          metadata: {
            testing: userService.userIsTester().toString(),
            user_email: $scope.shipping.contact.email
          }
        };
        var stripeProvider = paymentService.getProviderByName('stripe');
        stripeProvider.generateStripePaymentIntent(paymentIntentRequest).then(function(paymentIntent) {
          if ($rootScope.paymentIntentSavedCC) {
            var paymentIntentToCancel = _.cloneDeep($rootScope.paymentIntentSavedCC);
            delete $rootScope.paymentIntent;
            stripeProvider.cancelStripePaymentIntent(paymentIntentToCancel);
          }
          $rootScope.paymentIntent = paymentIntent;
          $rootScope.paymentIntentSavedCC = paymentIntent;
          $scope.paymentMethodNotReady = false;
          setTimeout(function() {
            $scope.$apply();
          }, 100);
        });
      });
    } else if (_.map($scope.internationalPaymentOptions, function(internationalPayment) {
      return internationalPayment.id;
    }).includes($scope.billingOptions.selected)) {
      $scope.payWithCitcon = true;
      $scope.payWithStripe = false;
    } else {
      $scope.payWithStripe = true;
      $scope.payWithCitcon = false;
      $scope.mountPaymentElement($rootScope.paymentIntentNew);
    }
  };

  $scope.initializeBillingAddress = function() {
    if ($scope.billing && $scope.billing.address && $scope.billing.address.address1 && pendingOrder === false) {
      $scope.billing.newCity = $scope.billing.address.city;
      return;
    }
    if (!($scope.shipping && $scope.shipping.address)) {
      return;
    }

    if (pendingOrder && $scope.billingOptions.selected < 0 &&
      !_.isEqual(pendingOrder.billing.address, pendingOrder.shipping.address)) {
      $scope.billing.address = _.clone(pendingOrder.billing.address);
      $scope.billing.address = _.clone(pendingOrder.billing.contact);
      $scope.setCities($scope.billing.address.state, 'billing');
      $scope.shippingBillingFlags.useShippingAddressAsBillingAddress = false;
      pendingOrder = false;
    } else {
      $scope.billing.address = _.merge(_.cloneDeep($scope.shipping.address));
      $scope.billing.contact = _.cloneDeep($scope.shipping.contact);
    }

    $scope.billing.newCity = $scope.billing.address.city;
  };

  $scope.isInternationalShippingOptionSelected = function() {
    return $scope.internationalPaymentOptions && _.map($scope.internationalPaymentOptions,
      function(internationalPayment) {
        return internationalPayment.id;
      }).includes($scope.billingOptions.selected);
  };

  function constructor() {
    $scope.setNoRobots();
    $rootScope.noPageFooter = true;
    $scope.isLoggedIn = userService.isLoggedIn;
    $rootScope.cartData = cartService.getData();
    $scope.getDiscountReason = cartService.getDiscountReason;
    $scope.cartTotalsAll = cartService.getTotals();
    $scope.cartTotals = $scope.cartTotalsAll.purchasable;
    $scope.disappearReferFriends = true;
    $rootScope.subscribeNewsletter = true;
    $scope.steps = [
      'shipping',
      'billing',
      'review',
      'confirmation'
    ];
    $scope.step = $scope.steps[0];
    $scope.forms = {};
    $scope.validSteps = {};

    // save shipping/billing at root scope,
    // to preserve them across site navigation
    $rootScope.shipping = $rootScope.shipping || {
      contact: {
        email: userService.getUser().email
      },
      address: {}
    };
    $rootScope.billing = {
      // no address means use shipping address
      // address: {},
      paymentMethod: {}
    };
    $rootScope.giftCard = {};
    $rootScope.promo = {
      code: $scope.cartData.promoCode
    };
    $rootScope.fulfillers = [];
    $rootScope.shippingOptions = $rootScope.shippingOptions || {
      options: [],
      selected: -1
    };
    $rootScope.billingOptions = $rootScope.billingOptions || {
      options: [],
      selected: -1
    };
    $rootScope.shippingBillingFlags = $rootScope.shippingBillingFlags || {
      useShippingAddressAsBillingAddress: true
    };

    // preload shipping and billing options
    $scope.loadShippingOptions();
    $scope.loadBillingOptions();

    $scope.expirationMonths = creditCardService.validExpirationMonths;
    $scope.expirationYears = creditCardService.validExpirationYears;

    $scope.$watch('shipping.address.country', function() {
      if (!$scope.shipping.address.country) {
        $scope.shipping.address.country = $rootScope.countrySelected();
      }

      var newCountry = $rootScope.featureFlags.internationalShipping.countries.find(function(country) {
        return country.name === $scope.shipping.address.country || country.code === $scope.shipping.address.country;
      });
      if (newCountry) {
        $scope.shipping.address.country = newCountry.code;
      }
      $scope.citiesOfState = [];
      $scope.citiesOfStateBilling = [];
      $scope.countryStates = (function() {
        var list = [];
        var states = sharedBusinessRules.states;
        for (var code in states) {
          if ($scope.shipping.address.country &&
            $scope.shipping.address.country.toLowerCase() === states[code].country.toLowerCase()) {
            list.push({
              code: code,
              name: states[code].name
            });
          }
        }
        return list;
      })();
      if ($scope.shipping.address.state && !$scope.isStateInput() && $scope.countryStates.length) {
        $scope.shipping.address.state = $scope.countryStates.find(function(cState) {
          return cState.code === $scope.shipping.address.state || cState.code ===
            $scope.shipping.address.country.toUpperCase() + '-' + $scope.shipping.address.state;
        });
        if ($scope.shipping.address.state) {
          $scope.shipping.address.state = $scope.shipping.address.state.code;
        }
      }
    });

    $scope.$watch('billing.address.country', function() {
      if ($scope.billing && $scope.billing.address) {
        if (!$scope.billing.address.country) {
          $scope.billing.address.country = 'us';
        }
        var newCountry = $rootScope.featureFlags.internationalShipping.countries.find(function(country) {
          return country.name === $scope.billing.address.country || country.code === $scope.billing.address.country;
        });
        if (newCountry) {
          $scope.billing.address.country = newCountry.code;
        }
        $scope.billingCountryStates = (function() {
          var list = [];
          var states = sharedBusinessRules.states;
          for (var code in states) {
            if ($scope.billing.address.country &&
              $scope.billing.address.country.toLowerCase() === states[code].country.toLowerCase()) {
              list.push({
                code: code,
                name: states[code].name
              });
            }
          }
          return list;
        })();
      }
    });

    $scope.citiesOfState = {};
    $scope.citiesOfStateBilling = {};
    $scope.setCities = function(state, step) {
      if (!state) {
        return;
      }
      var citPromise = citiesService.get(state);
      citPromise.then(function(cit) {
        var uniq = [];
        var ret = [];
        Object.keys(cit).forEach(function(key) {
          if (uniq.indexOf(cit[key].name) === -1 && cit[key].name !== '') {
            uniq.push(cit[key].name);
            ret.push({
              id: cit[key].name,
              value: cit[key].name
            });
          }
        });
        if (step === 'billing') {
          $scope.citiesOfStateBilling = _.sortBy(ret, 'id', 'asc');
        } else {
          $scope.citiesOfState = _.sortBy(ret, 'id', 'asc');
          $scope.citiesOfStateBilling = _.sortBy(ret, 'id', 'asc');
        }

        setTimeout(function() {
          if (step === 'billing') {
            if ($scope.billing.newCity && $scope.billing.address) {
              $scope.billing.address.city = $scope.billing.newCity;
            }
            $('form[name="forms.billing"] #city').trigger('valuesync');
            $scope.forms.billing.$setPristine(true);
          } else {
            $scope.shipping.address.city = '';
            if ($scope.shipping.newCity) {
              $scope.shipping.address.city = $scope.shipping.newCity;
            }
            if (pendingOrder && !$scope.shippingCityLoaded) {
              $scope.shipping.address.city = pendingOrder.shipping.address.city;
              $scope.shippingCityLoaded = true;
            }
            $('form[name="forms.shipping"] #city').trigger('valuesync');
            $scope.forms.shipping.$setPristine(true);
          }
        }, 50);
      });
    };
    $scope.$watch('cartTotalsAll.taxCalculationError', function() {
      var err = $scope.cartTotalsAll.taxCalculationError;
      if (!err) {
        delete $scope.invalidStateOrZipCode;
        return;
      }
      $scope.invalidStateOrZipCode = err && err.status === 404 ? err : false;
    });
    $scope.$watch('cartTotals.totalPrice', function() {
      if ($scope.cartTotals.totalPrice === 0) {
        $rootScope.paymentFormCompleted = true;
      } else {
        updateTotalPrice(true);
      }
    });
    $scope.$watch('validSteps.billing', function() {
      if ($scope.validSteps.billing) {
        $scope.billingIsValid();
      }
    });

    // reload shipping and billing options if user logs in during checkout
    $scope.$onEmitter(userService.events, 'loggedIn', function() {
      $scope.shippingOptions.loaded = false;
      $scope.billingOptions.loaded = false;
      $scope.loadShippingOptions();
      $scope.loadBillingOptions();
      if ($scope.promo.error && $scope.promo.error.status === 403) {
        $scope.applyPromoCode();
      }
      //or window.location.reload();
    });
    $transitions.onStart({}, function(trans) {
      trans.promise.finally(function() {
        if (/^root\.checkout/.test(trans.to().name)) {
          updateCurrentStep();
        }
      });
    });
    $scope.$on('cartItemsChanged', updateCurrentStep);
    cartService.events.on('empty', updateCurrentStep);

    $scope.$on('$destroy', function() {
      cartService.events.removeListener('empty', updateCurrentStep);
      $rootScope.noPageFooter = false;
      if (!userService.isLoggedIn()) {
        $scope.clearUserCheckoutData();
      }
    });

    updateCurrentStep();
  }

  constructor();
}

module.exports = CheckoutBaseController;
