var _ = require('lodash-core');

/* jshint maxparams: 10 */
// @ngInject
function AdminWithdrawController($scope, $rootScope, loyaltyService, influencerService, userService, $uibModal) {

  $scope.withdraws = [];
  $scope.status = 'pending';

  $scope.findWithdraws = function () {
    var promise = loyaltyService.getLoyaltyByTypeAndStatus('withdraw', $scope.status);
    $scope.loading = true;
    $scope.withdraws = [];
    promise.then(function(response) {
      _.forEach(response, function (userActivities) {
        influencerService.getByUserId(userActivities.userId).then(function (influencer) {
          _.forEach(userActivities.activities, function (activity) {
            $scope.withdraws.push({
              userName: influencer.username,
              preferredName: influencer.preferredName,
              points: Math.abs(activity.points),
              status: activity.status,
              createdAt: activity.createdAt,
              total: Math.abs((activity.points*100)/20),
              userId: userActivities.userId,
              activityId: activity._id,
              loyaltyId: userActivities.id
            });
          });
        });
      });
    }).finally(function () {
      $scope.loading = false;
    });
  };

  function loadUser() {
    userService.getById($scope.withdraw.userId).then(function (user) {
      $scope.withdraw.fullName = user.firstName + ' ' + user.lastName;
      $scope.withdraw.email = user.email;
      $scope.withdraw.phoneNumber = user.phoneNumber;
    });
  }

  if ($scope.withdraw) {
    loadUser();
  } else {
    $scope.findWithdraws();
  }

  $scope.openWithdraw = function (withdraw) {
    $scope.withdraw = withdraw;
    $uibModal.open({
      templateUrl: '/views/admin/withdraw.html',
      windowClass: 'withdraw-quick-view-modal',
      scope: $scope
    });
  };

  $scope.copyToClipboard = function(text) {
    var tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    window.alert('copied text: ' + text);
  };

  $scope.changeStatus = function (withdraw, newStatus) {
    loyaltyService.patchWithdrawStatus(withdraw.userId, withdraw.activityId, newStatus).then(function () {
      window.location.reload();
    });
  };
}

module.exports = AdminWithdrawController;
