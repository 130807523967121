// @ngInject
function ProductReviewService(apiService) {

  this.getByStyleId = function(styleId, brandId) {
    return apiService.get('/product-reviews', {
      urlParams: {
        brand: brandId,
        style: styleId
      }
    });
  };

  this.getById = function(id) {
    return apiService.get('/product-reviews/{id}', {
      pathParams: {
        id: id
      }
    });
  };

  this.update = function(id, productReview) {
    return apiService.patch('/product-reviews/{id}', {
      pathParams: {
        id: id
      },
      body: productReview
    });
  };

}

module.exports = ProductReviewService;
