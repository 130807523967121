var _ = require('lodash-core');

// @ngInject
function CampaignService($rootScope, $location, localStorageService) {

  /* jshint camelcase: false */
  CampaignService.prototype.saveUTMParameters = function () {
    var params = this._getParamsAsJSON();

    if (params.utm_campaign) {
      var campaigns = this.getUTMParameters();
      var expiresIn24hoursDate = new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
      campaigns.push({
        utmCampaign: params.utm_campaign,
        utmMedium: params.utm_medium,
        utmSource: params.utm_source,
        utmContent: params.utm_content,
        expiredAt: expiresIn24hoursDate
      });
      localStorageService.setItem('utm_campaigns', JSON.stringify(campaigns));
    }
  };

  CampaignService.prototype.loadCampaignOnRoot = function () {
    var params = this._getParamsAsJSON();
    if (params.utm_medium && params.utm_medium !== '') {
      $rootScope.utmMedium = params.utm_medium;
    }

    if (params.utm_campaign && params.utm_campaign !== '') {
      $rootScope.utmCampaign = params.utm_campaign;
    }

    if (params.utm_source && params.utm_source !== '') {
      $rootScope.utmSource = params.utm_source;
    }

    if (params.utm_content && params.utm_content !== '') {
      $rootScope.utmContent = params.utm_content;
    }

    if (!$rootScope.utmCampaign) {
      var utmList = this.getUTMParametersLast24Hs();
      if (utmList.length) {
        // loading the last utm consulted
        var utm = utmList[utmList.length - 1];
        $rootScope.utmCampaign = utm.utmCampaign;
        $rootScope.utmSource = utm.utmSource;
        $rootScope.utmMedium = utm.utmMedium;
        $rootScope.utmContent = utm.utmContent;
      }
    }
  };

  CampaignService.prototype._getParamsAsJSON = function () {
    var pattern = /[?&](\w+)=([^&]+)/g;
    var paramsObj = {};
    var match;
    while ((match = pattern.exec($location.absUrl())) !== null) {
      paramsObj[match[1]] = match[2];
    }
    return paramsObj;
  };

  CampaignService.prototype.getUTMParameters = function () {
    var campaigns = localStorageService.getItem('utm_campaigns');
    if (campaigns) {
      return JSON.parse(campaigns);
    } else {
      return [];
    }
  };

  CampaignService.prototype.getUTMParametersLast24Hs = function () {
    var campaigns = this.getUTMParameters();
    var nowDate = new Date();
    return _.filter(campaigns, function (campaign) {
      return new Date(campaign.expiredAt) > nowDate && campaign.utmCampaign;
    });
  };

}

module.exports = CampaignService;
