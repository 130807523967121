var _ = require('lodash-core');

/* jshint maxparams: 10 */
// @ngInject
function AdminUnmatchedRefundsController($scope, orderService, $stateParams, $uibModal, $rootScope, formsService) {
  $scope.searchTerm = '';
  $scope.loading = false;
  $scope.search = _.debounce(function(term, prev, force) {
    if (!term) {
      term = '';
    }
    if (!force && term === prev) {
      return;
    }
    $scope.loading = true;
    var from;
    from = new Date();
    from.setMonth(from.getMonth() - 12);
    var params = {
      term: term,
      onlyCaptured: true,
      onlyRefundedOnCard: true,
      from: from
    };
    var promise = orderService.searchOrders(params);
    promise.then(function(response) {
      $scope.refundsOnCards = [];
      response.items.forEach(function(order) {
        var orderRefunds = _.filter(order.events, function(event) {
          if (event.type === 'refund-on-card') {
            return !event.details.matched && !event.details.dismissed;
          }
        });
        orderRefunds.forEach(function(refund) {
          refund.order = order;
        });
        $scope.refundsOnCards = $scope.refundsOnCards.concat(orderRefunds);
      });
      $scope.loading = false;
    });
  }, 1000);
  $scope.search($scope.searchTerm, '', true);
  $scope.keypress = function($event) {
    if ($event.which === 13) {
      $scope.search($scope.searchTerm, '', false);
    }
  };
  $scope.dismissRefundOnCard = function(refundOnCard) {
    var success = function() {
      setTimeout(function() {
        $scope.search($scope.searchTerm, '', true);
      }, 1000);
      $scope.closeModal();
    };
    var error = function(error) {
      console.log(error);
      $scope.search($scope.searchTerm, '', true);
    };
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/dismiss-refund-on-card-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });
    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);
    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });
    $scope.closeModal = function() {
      instance.dismiss();
    };
    $scope.submitModal = function() {
      refundOnCard.details.dismissed = true;
      var refundOnCardToUpdate = _.cloneDeep(refundOnCard);
      delete refundOnCardToUpdate.order;
      $scope.formPromise.handle(orderService.updateEvent(refundOnCard.order, refundOnCardToUpdate))
        .then(success)
        .catch(error);
    };
  };
}

module.exports = AdminUnmatchedRefundsController;
