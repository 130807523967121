
// @ngInject
function AdminBrandController($scope, $state, $stateParams, listContextService, brandService, errorService) {

  listContextService.setScopeContext($scope, { urlMode: false });

  $scope.brandProducts = [];
  $scope.showcaseProducts = [];
  $scope.successFeedback = false;

  $scope.filters.brands[0] = $scope.brandId = $stateParams.id;

  $scope.$watch(
    function() {
      return JSON.stringify($scope.showcaseProducts);
    },
    function() {
      $scope.successFeedback = false;
    }
  );

  brandService.getBrand($scope.brandId)
    .then(function(brand) {
      if (!brand) {
        $state.go('admin');
      } else {
        $scope.brand = brand;
      }
    }).catch(errorService.pageError);

  brandService.admin.getShowcase($scope.brandId).then(function(showcase) {
    $scope.showcaseProducts = showcase.products;
  });

  $scope.save = function() {
    brandService.admin.save($scope.brandId, $scope.showcaseProducts).then(function() {
      $scope.successFeedback = true;
    });
  };
}

module.exports = AdminBrandController;
