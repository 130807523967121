var _ = require('lodash-core');

// @ngInject
function ExperimentsService($rootScope, analyticsService, config, localStorageService, platformService,
  $location) {
  var self = this;

  this.initialize = function() {
    if ($location.search().experiments) {
      //Force an experiment
      var experiments = $location.search().experiments.split('-');
      for (var n in experiments) {
        var option = experiments[n].split(',');
        localStorageService.setItem('ex-' + option[0], option[1]);
      }
    }
    if (config.noExperiments || typeof window.cxApi === 'undefined') {
      this.experiments = {};
      this.experimentsNotOnOriginal = {};
      if (typeof window.cxApi !== 'undefined') {
        window.cxApi.experiments = {};
        window.cxApi.experimentsNotOnOriginal = {};
      }
    } else {
      this.experiments = window.cxApi.experiments || {};
      this.experimentsNotOnOriginal = window.cxApi.experimentsNotOnOriginal || {};
    }
    $rootScope.experiments = this.experiments;
    $rootScope.experimentsNotOnOriginal = this.experimentsNotOnOriginal;

    $rootScope.bodyClassesProviders.experiments = function() {
      var classes = [];
      for (var name in self.experiments) {
        var experiment = self.experiments[name];
        if (!experiment.id || typeof experiment.chosenVariation === 'undefined') {
          continue;
        }
        if (self.experimentsNotOnOriginal[name]) {
          classes.push('experiment-' + name + '-not-on-original');
        }
        if (experiment.chosenVariation > 0) {
          classes.push('experiment-' + name + '-chosen-variation-' + experiment.chosenVariation);
        }
      }
      return classes;
    };

    // track active variations in analytics
    for (var name in this.experiments) {
      var experiment = this.experiments[name];
      if (!experiment.id || typeof experiment.chosenVariation === 'undefined') {
        continue;
      }
      if (experiment.chosenVariation > 0) {
        analyticsService.track('Experiment ' + name + ' Variation', {
          chosenVariation: experiment.chosenVariation
        });
      }
    }

  };

  this.getVariation = function(name) {
    var experiment = config.experiments[name];
    if (!experiment || experiment.enabled !== true) {
      return;
    }
    if (!experiment.allowBots &&
      (platformService.isBot() ||
        platformService.isPrerender() ||
        platformService.isPhantomJS() ||
        platformService.isAutomatedBrowser())) {
      return;
    }
    var variationKey = localStorageService.getItem('ex-' + name);
    var variationNames = _.keys(experiment.variations);
    if (!variationKey || variationNames.indexOf(variationKey) === -1) {
      // not participating yet, get a variation
      variationKey = getVariation(experiment);
      localStorageService.setItem('ex-' + name, variationKey);
    }
    if (variationKey === 'none') {
      // already checked in but excluded from experiment
      return;
    }

    return variationKey;
  };

  function getVariation(experiment) {
    // if no audience defined then 100% participates
    if (experiment.audience) {
      var rnd = Math.random();
      if (rnd > experiment.audience) {
        // not participating
        return 'none';
      }
    }
    // now randomly select the variation
    var variationNames = _.keys(experiment.variations);
    var variationRnd = Math.floor(Math.random() * variationNames.length);
    return variationNames[variationRnd];
  }
}

module.exports = ExperimentsService;
