var _ = require('lodash-core');

/* jshint maxparams: 8 */
// @ngInject
function OccasionsController($scope, $state, brandService, productService,
  contentPageService, staticAssetService, platformService) {

  $scope.occasionList = contentPageService.search({ filters: ['occasions/*'] });
  $scope.productListName = 'occasions-list';

  $scope.setBreadcrumbPath([{
    name: 'event'
  }]);

  $scope.setPageMetadata({
    title: 'Event at THE MILE'
  });

  $scope.$on('viewportBreakpointChange', populateOccasionList);

  $scope.getPageUrl = function(page) {
    return '/' + (page && page.name);
  };

  $scope.numberOfProductsShown = function() {
    if (platformService.isMobile()) {
      return 2;
    } else {
      return 4;
    }
  };

  // -- end of initializer

  function populateOccasionList() {
    var chunk = [];
    var timeout = 0;
    _.forEach($scope.occasionList, function(tag) {
      if (!tag.products) {
        chunk.push(tag);
      }
      if (chunk.length === 5) {
        loadShowcases(chunk, timeout);
        chunk = [];
        timeout += 500;
      }
    });
    if (chunk.length) {
      loadShowcases(chunk, timeout);
    }
  }

  function loadShowcases(brands, timeout) {
    if (!$scope.occasionList || !$scope.occasionList.length) {
      return;
    }
    var tags = _.map(_.map($scope.occasionList, 'productListTag'), function(each) {
      return each + '-list';
    }).join(',');
    setTimeout(function() {
      productService.getShowcaseProductsByTag(tags).then(function(data) {
        _.forEach(data.tags, function(tag) {
          var targetTag = _.find($scope.occasionList, { productListTag: tag.tag.replace('-list', '') });
          if (targetTag) {
            targetTag.products = tag.products;
            targetTag.visible = tag.products && tag.products.length;
            targetTag.backgroundImage = staticAssetService.url('/content/images/holiday/' +
              targetTag.image.split('/')[1] + '-list.jpg');
          }
        });

        $scope.occasionList = _.sortBy($scope.occasionList, 'title');
      });
    }, timeout);
  }

  populateOccasionList();
}

module.exports = OccasionsController;
