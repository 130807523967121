var _ = require('lodash-core');

// @ngInject
function BrandFilterDirective($rootScope, brandService, platformService, $state) {
  var linker = function($scope, element, attrs) {

    $scope.isMobile = platformService.isMobile();
    // hack to solve a safari bug about the scroll get freeze
    element.on('mouseenter', function() {
      platformService.fixSafariScroll('ul');
    });

    $scope.status = { isOpen: false };
    $scope.getUser = function() {
      return $rootScope.getUser();
    };

    var filters = $scope.filters;
    var sources = $scope.sources;

    brandService.getAll().then(function(brands) {
      var multiBrandStores = _.uniq(_.compact(_.map(brands, 'store')));
      sources.brands = _.filter(_.cloneDeep(brands), function(brand) {
        if (attrs.brandId) {
          // if this is a multi-brand-store we only show the child brands
          return brand.store === attrs.brandId;
        }
        // hide multi-brand-stores
        return multiBrandStores.indexOf(brand.id) === -1;
      });

      updateBrandsSource();
    });

    $scope.isKidsCategory = function() {
      return $state.params.category1 === 'kids' ||
        !!($state.params && $state.params.brand && $state.params.brand.indexOf('kids') > -1);
    };

    $scope.isMenCategory = function() {
      return $state.params.category1 === 'men' ||
        !!($state.params && $state.params.brand && $state.params.brand.indexOf('men') > -1);
    };

    $scope.isBeautyCategory = function() {
      return $state.params.category1 === 'beauty';
    };

    $scope.shouldShowTheBrand = function(brand) {
      if (!($scope.getUser() || {}).isAdmin || $scope.isMobile) {
        if (!brand.disabled) {
          if ($scope.isJustInPage) {
            return !!brand.visibleInWhatsNew;
          }
          if ($scope.isSalePage) {
            return !!brand.visibleInSale;
          }
          if ($scope.isMenCategory()) {
            return !!brand.onlyMen;
          }
          if ($scope.isKidsCategory()) {
            return !!brand.onlyKids;
          }
          return true;
        }
        return false;
      } else {
        return true;
      }
    };

    $scope.onContextReady(function() {

      $scope.isJustInPage = $state.current.name.indexOf('just-in') >= 0;
      $scope.isSalePage = $state.current.name.indexOf('sale') >= 0;

      resetOccasionDesigners();

      updateBrandsSource();

      $scope.$watch('facets.brands', function() {
        var facets = $scope.facets.brands;
        _.forEach($scope.sources.brands, function(brand) {
          brand.disabled = !(facets.brandId && facets.brandId[brand.id]);
        });
      }, true);

      $scope.$watch('sources.brands', updateBrandsFilter, true);
      $scope.$watch('filters.brands', updateBrandsSource, true);

    });

    $scope.reset = function($event) {
      filters.brands = [];
      _.forEach(sources.brands, function(brand) {
        brand.selected = false;
      });

      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
      $event.cancelBubble = true;
      $event.returnValue = false;
    };

    $scope.isResetVisible = function() {
      return filters.brands && filters.brands.length && (!attrs.brandId || filters.brands[0] !== attrs.brandId) &&
        !$scope.isMobile;
    };

    $scope.selectBrand = function(brand) {
      brand.selected = !brand.selected;
      if (brand.selected) {
        $scope.brandsSelected++;
      } else {
        $scope.brandsSelected--;
      }
    };

    $scope.updateVisibility = function(brand) {
      if ($scope.isJustInPage) {
        brand.visibleInWhatsNew = brand.selectedAdmin;
      } else if ($scope.isSalePage) {
        brand.visibleInSale = brand.selectedAdmin;
      }
      brandService.update(brand);
    };

    // private

    function updateBrandsSource() {
      sources.brands = _.sortBy(sources.brands, function(each) {
        return /^[^a-z]+/i.test(each.name) ? 'zzz' + each.name.toLowerCase() : each.name.toLowerCase();
      });
      _.forEach(sources.brands, function(brand) {
        brand.selected = _.indexOf(filters.brands, brand.id) > -1;
      });
      if (($scope.getUser() || {}).isAdmin) {
        _.forEach(sources.brands, function(brand) {
          if ($scope.isJustInPage) {
            brand.selectedAdmin = brand.visibleInWhatsNew;
          } else if ($scope.isSalePage) {
            brand.selectedAdmin = brand.visibleInSale;
          }
        });
      }
    }

    function updateBrandsFilter() {
      if (sources.brands.length) {
        var selectedBrands = _.map(_.filter(sources.brands, { selected: true }), 'id').join(',');
        if (filters.brands && filters.brands.join(',') !== selectedBrands) {
          filters.brands = _.compact(selectedBrands.split(','));
          $scope.designersSelected = filters.brands.length;
        }
        if (attrs.brandId && !filters.brands.length) { // if this is a brand-store and no any brand in filter
          filters.brands = [attrs.brandId]; // we set the filter = the brand-store id
          $scope.designersSelected = 0;
        }
        if (!selectedBrands) {
          $scope.designersSelected = 0;
        }
      }
    }

    function resetOccasionDesigners() {
      if ($state.params.name && $state.params.name.match(/^occasions\//)) {
        $scope.filters.brands = [];
      }
    }

  };
  return {
    link: linker,
    templateUrl: '/views/partials/brand-filter.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = BrandFilterDirective;
