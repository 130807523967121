/* jshint maxparams: 12 */
// @ngInject
function ShowsInfluencerCarouselDirective(
  showService,
  modalShowService,
  productService,
  $uibModal
) {
  var linker = function ($scope, element, attrs) {
    $scope.influencerId = attrs.influencer;

    $scope.goToSection = function(section) {
      if ($scope.hash === section) {
        var anchor = document.getElementById(section);
        if (anchor) {
          anchor.scrollIntoView();
        }
      } else {
        setTimeout(function() {
          $(window).scrollTop($(window).scrollTop() + 1);
          setTimeout(function() {
            $(window).scrollTop($(window).scrollTop() - 1);
          }, 50);
        }, 100);
      }
      $scope.hash = section;
    };

    showService.getNormalizedShowsByInfluencerId(attrs.influencer, true).then(function(shows) {
      showService.orderShowsByDate(shows);
      $scope.items = shows;
    });

    $scope.getProductImage = function (imageUrl, options) {
      return productService.getImageUrl(imageUrl, options);
    };

    element.on('click', function(e){
      if (e.target.id && /[1234567890abcdef]{24}/.test(e.target.id)) {
        e.preventDefault();
        modalShowService.openShow(e.target.id, $scope);
      }
    });

    var popupMatch = /[\?&#]show=([^\?&#]+)/i.exec(document.location.search);
    if (popupMatch && popupMatch[1] && !$scope.instance) {
      modalShowService.openShow(popupMatch[1], $scope);
    }

    $scope.quickView = function (styleId) {
      $scope.quickViewStyleId = styleId;
      $scope.quickViewTrigger = 'show';
      if (window.hj) {
        window.hj('event', 'influencer-page-open-product-quick-view-click');
      }
      $scope.instance = $uibModal.open({
        templateUrl: '/views/partials/modals/product-quick-view.html',
        windowClass: 'product-quick-view-modal',
        size: 'lg',
        scope: $scope
      });
    };
  };

  return {
    link: linker,
    templateUrl: '/views/partials/the-mile/shows-influencer-carousel.html',
    restrict: 'E',
    scope: true
  };
}

module.exports = ShowsInfluencerCarouselDirective;
