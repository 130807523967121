var _ = require('lodash-core');
var categories = require('shared-business-rules').categories.getArray();

/*jshint camelcase: false */

// @ngInject
function PinterestService($location, productService) {
  this.setProductTag = function(product, style, variant, image) {
    window.pinterestTag = {
      product_id: product.id + '-' + style.id,
      product_name: style.name,
      product_category: this.getGoogleCategory(product),
      description: style.description,
      link: $location.absUrl(),
      price: (variant.price/100) + 'USD',
      availability: 'in stock',
      image_link: productService.getImageUrl(image.path)
    };
  };

  this.getGoogleCategory = function(product) {
    var categoryNames = productService.getProductCategories(product);
    var categoryKeys = productService.getProductCategoryKeys(categoryNames);

    var category = _.find(categories, function (cat) {
      return categoryKeys.includes(cat.key);
    });
    if (category && category.children) {
      var subCategory = _.find(category.children, function (cat) {
        return categoryKeys.includes(cat.key);
      });
      if (subCategory && subCategory.name) {
        return subCategory.name;
      }
    }
    if (category && category.name) {
      return category.name;
    }
    return 'clothing';
  };
}

module.exports = PinterestService;
