
// @ngInject
function LocalStorageService($rootScope) {

  function LocalStorageObserver() {
  }

  function addEventListener(el, eventName, handler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, handler, false);
    } else if (el.attachEvent) {
      el.attachEvent('on' + eventName, function() {
        handler.apply(el, arguments);
      });
    }
  }

  LocalStorageObserver.prototype.onKeyChange = function(key, handler) {
    if (typeof window === 'undefined') {
      return;
    }
    addEventListener(window, 'storage', function(e) {
      if (typeof key === 'string' ? e.key === key : key.test(e.key)) {
        setTimeout(function() {
          $rootScope.$apply(function() {
            handler(e);
          });
        }, 0);
      }
    });
  };
  this.isSupported = function() {
    try {
      if (window.localStorage) {
        return true;
      }
    } catch (e) {
      /* jshint ignore: start */

      window.iframeStorage = {
        _data: {},
        setItem: function(id, val) {
          return this._data[id] = String(val);
        },
        getItem: function(id) {
          return this._data.hasOwnProperty(id) ? this._data[id] : false;
        },
        removeItem: function(id) { return delete this._data[id]; },
        clear: function() { return this._data = {}; }
      };
      /* jshint ignore: end */

      return false;
    }
  };
  this.setItem = function(key, value) {
    if (typeof window === 'undefined') {
      return;
    }
    var localStorage = this.isSupported() ? window.localStorage : window.iframeStorage;
    return localStorage.setItem(key, value);
  };

  this.removeItem = function(key) {
    if (typeof window === 'undefined') {
      return;
    }
    var localStorage = this.isSupported() ? window.localStorage : window.iframeStorage;

    return localStorage.removeItem(key);
  };

  this.getItem = function(key) {
    if (typeof window === 'undefined') {
      return;
    }
    var localStorage = this.isSupported() ? window.localStorage : window.iframeStorage;

    return localStorage.getItem(key);
  };

  this.observer = new LocalStorageObserver();

  this.onKeyChange = function() {
    return this.observer.onKeyChange.apply(this.observer, arguments);
  };

}

module.exports = LocalStorageService;
