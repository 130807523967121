// @ngInject
function ShouldMatchDirective() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      shouldMatch: '='
    },
    link: function(scope, element, attrs, ctrl) {

      function validate(viewValue) {
        var origin = scope.shouldMatch;
        ctrl.$setValidity('shouldMatch', origin === viewValue);
        return viewValue;
      }

      ctrl.$parsers.unshift(validate);

      scope.$watch(function() {
        var combined;
        if (scope.shouldMatch || ctrl.$viewValue) {
          combined = scope.shouldMatch + '_' + ctrl.$viewValue;
        }
        return combined;
      }, function() {
        validate(ctrl.$viewValue);
      });

    }
  };
}

module.exports = ShouldMatchDirective;
