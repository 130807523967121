var _ = require('lodash-core');

/* jshint maxparams: 10 */
// @ngInject
function AdminRefundsController($scope, orderService, $stateParams, $uibModal,
  $rootScope, formsService, config) {
  $scope.featureFlags = config.featureFlags;
  $scope.searchTerm = '';
  $scope.hideTestOrders = true;
  $scope.loading = false;
  var search = _.debounce(function(term, prev, force) {
    if (!term) {
      term = '';
    }
    if (!force && term === prev) {
      return;
    }
    $scope.loading = true;
    var from;
    from = new Date();
    from.setMonth(from.getMonth() - 12);
    var params = {
      term: term,
      hideTestOrders: $scope.hideTestOrders,
      onlyCaptured: true,
      onlyRefunded: true,
      from: from
    };
    var promise = orderService.searchOrders(params);
    promise.then(function(response) {
      $scope.refunds = [];
      response.items.forEach(function(order) {
        var orderRefunds = _.filter(order.events, function(event) {
          if (event.type === 'refund') {
            if (event.details.processed === undefined) {
              event.details.processed = true;
            }
            return !$scope.pendingRefundsOnly || !event.details.processed;
          }
        });
        orderRefunds.forEach(function(refund) {
          refund.order = order;
        });
        $scope.refunds = $scope.refunds.concat(orderRefunds);
      });
      $scope.loading = false;
    });
  }, 1000);
  $scope.$watch('pendingRefundsOnly', function() {
    search($scope.searchTerm, '', true);
  });
  $scope.$watch('hideTestOrders', function() {
    search($scope.searchTerm, '', true);
  });
  $scope.$watch(function() { return JSON.stringify($stateParams); }, function() {
    $scope.searchTerm = $stateParams.search;
    $scope.pendingRefundsOnly = $stateParams.pendingRefundsOnly === undefined ||
      $stateParams.pendingRefundsOnly;
  });
  $scope.keypress = function($event) {
    if ($event.which === 13) {
      search($scope.searchTerm, '', false);
    }
  };
  $scope.getTotalToBeRefunded = function() {
    var total = 0;
    if ($scope.refunds) {
      $scope.refunds.forEach(function(refund) {
        if (refund.selected) {
          total += refund.details.amount;
        }
      });
    }
    return total;
  };
  $scope.deleteRefund = function(refund) {
    $scope.refundToDelete = refund;
    var success = function() {
      setTimeout(function() {
        search($scope.searchTerm, '', true);
      }, 1000);

      $scope.closeModal();
    };
    var error = function(error) {
      console.log(error);
      search($scope.searchTerm, '', true);
    };
    var instance = $uibModal.open({
      templateUrl: '/views/admin/partials/delete-refund-modal.html',
      windowClass: 'admin-modal admin-form',
      scope: $scope
    });
    $rootScope.addModalToStack();
    instance.result.finally($rootScope.removeModalFromStack);

    instance.opened.then(function() {
      $scope.formPromise = formsService.promiseHandler();
    });
    $scope.closeModal = function() {
      instance.dismiss();
    };
    $scope.submitModal = function() {
      $scope.formPromise.handle(orderService.deleteRefund($scope.refundToDelete.order.number,
        $scope.refundToDelete)).then(success).catch(error);
    };
  };


  $scope.$watch('selectAll', function() {
    if ($scope.refunds) {
      $scope.refunds.forEach(function(refund) {
        refund.selected = $scope.selectAll && !refund.details.processed;
      });
    }
  });
  $scope.process = function() {
    var promises = [];
    $scope.refunds.forEach(function(refund) {
      $scope.processing = true;
      if (refund.selected) {
        promises.push(orderService.processRefund(refund));
      }
      /* global Promise */
      Promise.all(promises).then(function() {
        $scope.processing = false;
      });
    });
  };
}

module.exports = AdminRefundsController;
