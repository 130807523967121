var _ = require('lodash-core');
var promises = require('../../async/promises');
var EventEmitter = require('events').EventEmitter;
var cartDataManipulation = require('./cart/data-manipulation');
var sharedBusinessRules = require('shared-business-rules');

/* jshint maxparams: 11 */
/*jshint camelcase: false */

// @ngInject
function CartService($rootScope, apiService, config, userService, resourceShadowService,
                     productService, taxService, brandService, shopModalService, klaviyoService, showService) {

  var self = this;
  var events = this.events = new EventEmitter();
  var cartStep;
  var cart;
  var itemsOnCartPrevState;
  var totals = {};
  var lastOrder = -1;
  var lastTaxResult = null;
  this.bagActive = true;

  function setProductAndVariantInfoGetters() {
    cart.data.items.forEach(function(item) {
      if (typeof item.product === 'function') {
        return;
      }
      var productPromise;
      var product;
      var productPromiseStyleId;
      var style;
      var variant;
      item.loadProduct = function() {
        if (product && (productPromiseStyleId === item.styleId ||
          _.some(product.styles, {id: item.styleId}))) {
          return productPromise;
        }
        productPromise = productService.getByStyleId(item.styleId);
        productPromiseStyleId = item.styleId;
        product = productPromise.lazyObject();
        style = null;
        variant = null;
        return productPromise;
      };
      item.product = function() {
        this.loadProduct();
        return product;
      };
      item.style = function() {
        this.loadProduct();
        if (style && style.id === item.styleId) {
          return style;
        }
        variant = null;
        if (product.styles) {
          style = product.styles.filter(function(productStyle) {
            return productStyle.id === item.styleId;
          })[0] || product.styles[0];
        }
        return style;
      };
      item.variant = function() {
        this.style();
        if (variant && _.isEqual(variant.key, item.variantKey)) {
          return variant;
        }
        if (style && style.variants) {
          variant = style.variants.filter(function(styleVariant) {
            return _.isEqual(styleVariant.key, item.variantKey);
          })[0] || style.variants[0];
        }
        return variant;
      };
      item.variantImages = function() {
        var variant = this.variant();
        return productService.getImages(this.product(), variant && variant.key);
      };
      item.getImageUrl = function(options) {
        options = options || {};
        var image = this.variantImages()[0];
        if (!image) {
          return;
        }
        options.availableSizes = image.availableSizes;
        return productService.getImageUrl(image.path, options);
      };
      item.getMaxQty = function() {
        var product = this.product();
        if (!product || !product.partner) {
          return;
        }
        var brand = brandService.getBrand(product.partner.id).lazyObject();
        return brand.maxQtyPerSku;
      };
      item.getBrandItemMaxQty = function() {
        var product = this.product();
        if (!product || !product.partner) {
          return;
        }
        var brand = brandService.getBrand(product.partner.id).lazyObject();
        var itemMaxQty = item.brandMaxQty = brand.maxQtyPerBrand;
        if (itemMaxQty) {
          cart.data.items.forEach(function(brandItem) {
            if (
              brandItem.getBrand() &&
              brandItem.getBrand().id === item.getBrand().id &&
              !brandItem.forLater &&
              (
                brandItem.styleId !== item.styleId ||
                brandItem.hash !== item.hash
              )
            ) {
              itemMaxQty -= brandItem.quantity;
            }
          });
        }
        return itemMaxQty;
      };
      item.getBrand = function() {
        var product = this.product();
        return product && product.brand;
      };
    });
  }

  function filterFulfillerWithItems(purchasableItems, allFulfillers) {
    var fulfillers = [];
    purchasableItems.forEach(function(item) {
      var product = typeof item.product === 'function' && item.product();
      var fulfiller = _.find(allFulfillers, {id: product.partner.id});
      var isAdded = _.find(fulfillers, {id: product.partner.id});
      if (!isAdded && fulfiller) {
        fulfillers.push(fulfiller);
      }
    });
    return fulfillers;
  }

  function calculateTotals(force) {
    var shipping = $rootScope.shipping;
    var purchasableItems = cart.data && cart.data.items.filter(cartDataManipulation.isPurchasable);
    var fulfillerWithItems = filterFulfillerWithItems(purchasableItems, $rootScope.fulfillers);
    if (
      !force &&
      self.previousItems &&
      !_.find(cart.data.items, function(cartItem) {
        return !!self.previousItems.find(function(previousItem) {
          return cartItem.styleId === previousItem.styleId &&
            _.isEqual(cartItem.variantKey, previousItem.variantKey) &&
            (
              cartItem.price !== previousItem.price ||
              cartItem.quantity !== previousItem.quantity
            );
        });
      }) &&
      _.isEqual(shipping && shipping.address, self.previousAddress) &&
      _.isEqual(fulfillerWithItems, self.previousFulfiller) &&
      _.isEqual($rootScope.giftCard, self.previousGiftCard) &&
      _.isEqual($rootScope.promo, self.previousPromo)
    ) {
      $rootScope.calculatingTotals = false;
      safeUpdateScope();
      return;
    }

    // do tax calculation again if necessary
    if (
      shipping &&
      shipping.address &&
      shipping.address.state &&
      shipping.address.zip &&
      shipping.address.city &&
      cart.data.items &&
      cart.data.items.length &&
      sharedBusinessRules.validations.address.state(shipping.address.state).valid &&
      sharedBusinessRules.validations.address.zip(shipping.address.zip).valid &&
      sharedBusinessRules.validations.address.city(shipping.address.city).valid
    ) {
      self.previousItems = _.cloneDeep(cart.data.items);
      self.previousAddress = _.clone(shipping.address);
      self.previousFulfiller = _.clone(fulfillerWithItems);
      self.previousGiftCard = _.clone($rootScope.giftCard);
      self.previousPromo = _.clone($rootScope.promo);

      // trigger tax calculation
      var zip = shipping.address.zip;
      var state = shipping.address.state;
      var productFxs = _.compact(_.map(cart.data.items, 'product'));

      var promoRestriction = config.featureFlags.myMilePromoLanguageRestriction;
      if (promoRestriction && promoRestriction.enabled) {
        cart.data.rewards = applyMyMilePromoPatch(promoRestriction.languages, promoRestriction.regex);
      }

      var rewards = _.clone(cart.data.rewards);

      if (config.featureFlags.taxJarRefactor.enabled && productFxs.length) {
        if (cart && cart.data && cart.data.rewards && cart.data.rewards.items) {
          //Sometimes the credit is float but we need to send it in cents
          rewards.items.forEach(function(reward) {
            if (reward.credit.toString().indexOf('.') !== -1) {
              reward.credit = Math.round(reward.credit);
            }
          });
        }
        var cartItems = self.getData().items.filter(cartDataManipulation.isPurchasable);
        if (cartItems.length === 0) {
          $rootScope.calculatingTotals = false;
          safeUpdateScope();
          return;
        }
        var taxOrder = {
          items: _.map(cartItems, function(each) {
            var product = each.product();
            if (!self.isSoldOut(product)) {
              each.brandId = product && product.brand && product.brand.id;
              each.partnerId = product && product.partner && product.partner.id;
              each.tags = product && product.tags;
              if (product && product.styles) {
                product.styles.forEach(function(style) {
                  if (style.tags) {
                    _.merge(each.tags, style.tags);
                  }
                });
              }
              return each;
            }
          }),
          shipping: {
            address: shipping && shipping.address
          },
          fulfillers: fulfillerWithItems || [],
          rewardsUsed: (rewards && rewards.items) || []
        };

        if (!force && JSON.stringify(taxOrder) === lastOrder && lastTaxResult !== -1) {
          delete totals.taxCalculationError;
          calculateTotalsWithTaxRate(null, (!isNaN(lastTaxResult.taxRate)) ? Math.round(lastTaxResult.taxRate) : null);
          events.emit('taxCalculationSuccess');
          $rootScope.calculatingTotals = false;
          safeUpdateScope();
          return;
        }

        taxService.getTaxRateForOrder(taxOrder).then(function(taxes) {
          lastOrder = JSON.stringify(taxOrder);
          lastTaxResult = taxes;
          if (shipping.address.zip !== zip || shipping.address.state !== state) {
            // address has changed, ignore this response
            lastOrder = -1;
            $rootScope.calculatingTotals = false;
            safeUpdateScope();
            return;
          }

          delete totals.taxCalculationError;
          calculateTotalsWithTaxRate(null, (!isNaN(taxes.taxRate)) ? Math.round(taxes.taxRate) : null);
          events.emit('taxCalculationSuccess');
          $rootScope.calculatingTotals = false;
          safeUpdateScope();
        }).catch(function(err) {
          totals.taxCalculationError = (err.data && err.data.error) || err;
          events.emit('taxCalculationFailure');
          $rootScope.calculatingTotals = false;
          safeUpdateScope();
          delete self.previousItems;
          delete self.previousAddress;
          delete self.previousFulfiller;
          delete self.previousGiftCard;
          delete self.previousPromo;
        });
      } else {
        taxService.getTaxRate(zip, state).then(function(taxes) {
          if (shipping.address.zip !== zip || shipping.address.state !== state) {
            // address has changed, ignore this response
            $rootScope.calculatingTotals = false;
            safeUpdateScope();
            return;
          }
          delete totals.taxCalculationError;
          calculateTotalsWithTaxRate(taxes.taxRate);
          events.emit('taxCalculationSuccess');
          $rootScope.calculatingTotals = false;
          safeUpdateScope();
        }).catch(function(err) {
          totals.taxCalculationError = (err.data && err.data.error) || err;
          events.emit('taxCalculationFailure');
          delete self.previousItems;
          delete self.previousAddress;
          delete self.previousFulfiller;
          delete self.previousGiftCard;
          delete self.previousPromo;
        });
      }
    } else {
      calculateTotalsWithTaxRate(null, 0);
    }
  }

  this.getBrandMaxQty = function(brandId) {
    var brand = brandService.getBrand(brandId).lazyObject();
    return brand.maxQtyPerBrand;
  };

  this.setBagActive = function(active) {
    this.bagActive = active;
  };

  /*** My Mile Promo Patch ***Begin***/
  function applyMyMilePromoPatch(languages, regex) {
    var rewards = cart.data.rewards;
    if (rewards && rewards.items) {
      rewards.items = rewards.items.filter(function(r) {
        var isMyMilePromo = r.type === 'promo' && r.description.match(regex);
        var isRestrictedLanguage = function(language) {
          return languages.indexOf(language) >= 0;
        };
        var language = (navigator.language || navigator.userLanguage).substring(0, 2);
        return !isMyMilePromo || !isRestrictedLanguage(language);
      });
    }
    return rewards;
  }

  /*** My Mile Promo Patch ***End*****/

  function calculateTotalsWithTaxRate(taxRate, totalTaxes) {
    if (!cart) {
      $rootScope.calculatingTotals = false;
      safeUpdateScope();
      return;
    }

    var previousTotals = {
      shipping: totals.shipping,
      cost: totals.cost,
      discount: totals.discount,
      taxes: totals.taxes,
      totalPrice: totals.totalPrice,
      internationalShipping: totals.internationalShipping
    };
    var shipping = $rootScope.shipping;
    var billing = $rootScope.billing;

    var giftCardBalance = ($rootScope.giftCard && $rootScope.giftCard.daysToExpiration > 0) ?
      $rootScope.giftCard.balance : 0;
    var giftCardMinimumOrderTotal = ($rootScope.giftCard && $rootScope.giftCard.minimumOrderTotal) || 0;

    var cartItems = self.getData().items.filter(cartDataManipulation.isPurchasable);
    var fulfillers = filterFulfillerWithItems(cartItems, self.getFulfillers());
    var promoRestriction = config.featureFlags.myMilePromoLanguageRestriction;
    if (promoRestriction && promoRestriction.enabled) {
      cart.data.rewards = applyMyMilePromoPatch(promoRestriction.languages, promoRestriction.regex);
    }

    var calculatedTotals = cartDataManipulation.calculateTotals({
      taxRate: taxRate,
      totalTaxes: totalTaxes,
      items: cartItems,
      rewards: cart.data.rewards,
      shipping: shipping,
      billing: billing,
      fulfillers: fulfillers,
      giftCardBalance: giftCardBalance,
      giftCardMinimumOrderTotal: giftCardMinimumOrderTotal
    });

    // this is to not override observed variable
    _.merge(totals, calculatedTotals);
    _.merge(totals.purchasable, calculatedTotals.purchasable);
    $rootScope.calculatingTotals = false;
    safeUpdateScope();

    if (totals.cost !== previousTotals.cost) {
      events.emit('totalCostChanged');
    }
    if (totals.discount !== previousTotals.discount) {
      events.emit('totalDiscountChanged');
    }
    if (totals.shipping !== previousTotals.shipping) {
      events.emit('totalShippingChanged');
    }
    if (totals.taxes !== previousTotals.taxes) {
      events.emit('totalTaxesChanged');
    }
    if (totals.totalPrice !== previousTotals.totalPrice) {
      events.emit('totalPriceChanged');
    }
  }

  $rootScope.$watch('shipping.address.country', calculateTotals, true);
  $rootScope.$watch('shipping.address.city', calculateTotals, true);
  $rootScope.$watch('shipping.address.zip', calculateTotals, true);
  $rootScope.$watch('fulfillers', calculateTotals, true);
  $rootScope.$watch('giftCard.balance', calculateTotals, true);

  var refreshStockRequested = false;

  function prepareCart() {
    cart = resourceShadowService.create('cart', {
      threeWayMerge: cartDataManipulation.threeWayMerge
    }, {
      url: function(url) {
        if (!refreshStockRequested) {
          return url;
        }
        refreshStockRequested = false;
        return url + (url.indexOf('?') >= 0 ? '&' : '?') + 'refresh-stock=true';
      }
    });
    cart.apply(cartDataManipulation.prepareBasicCartData);
    setProductAndVariantInfoGetters();
    calculateTotals();
    cart.on('change', function() {
      if (!itemsOnCartPrevState || !_.isEqual(cart.data, itemsOnCartPrevState)) {
        itemsOnCartPrevState = _.cloneDeep(cart.data);
        $rootScope.calculatingTotals = true;
        setTimeout(function() {
          calculateTotals();
        }, 1200);
      }
      setProductAndVariantInfoGetters();
      updateVariantStockAndPrices();
    });

    cart.on('loaded', function() {
      if (!cart.loadErrorCount) {
        return;
      }
      console.log('cart reconnected after ' + cart.loadErrorCount + ' attempts');
      delete cart.loadErrorCount;
    });

    cart.on('loaderror', function(err) {
      cart.loadErrorCount = (cart.loadErrorCount || 0) + 1;
      var user = userService.getUser();
      if (!user || user.guest || user.anonymous) {
        // user just logged out, no problem
        return;
      }
      if (err.status && [401, 403, 404].indexOf(err.status) >= 0) {
        /*
         possible reasons for this are:
          - the account was disabled or deleted
          - password has changed
          - token is expired
        */
        console.error('user cart is not available anymore (' +
          (err.status ? (err.status + ' ') : '') +
          (err.message ? err.message : '') +
          '), logging out');
        userService.logout();
      } else if (!err.status ||
        [408, 419, 420, 429, 440, 444, 503, 504].indexOf(err.status) >= 0) {
        /*
         possible reasons for this are:
          - server is busy
          - internet disconnected
          - device is sleeping
        */
        console.log('cart load failed, trying to reconnect...');
      } else {
        /*
         possible reasons for this are:
          - unexpected http or non-http errors
        */
        console.error('error loading user cart: ' + err.status + ' ' +
          ((err && err.message) || err || 'unknown').toString());
      }
    });

    productService.events.on('productLoaded', function(product) {
      if (!self.productIsInCart(product)) {
        return;
      }
      // product information about an item in the cart was loaded,
      // recalculate totals
      setProductAndVariantInfoGetters();
      calculateTotals();
      if (_.every(cart.data.items, function(item) {
        return item.variant();
      })) {
        $rootScope.$broadcast('cartProductsLoaded');
      }
    });

    // go online if user is already logged in
    var user = userService.getUser();
    if (user && !user.anonymous) {
      cart.goOnline('users/' + user.id + '/cart').load();
      startReloadInterval();
      showService.syncInteractionsContent();
    } else {
      anonymousCartRefresh();
    }
  }

  var reloadInterval;
  var lastReloadTime;

  function reload(refreshStock) {
    refreshStockRequested = refreshStockRequested || refreshStock;
    var user = userService.getUser();
    if (!user || user.anonymous || user.guest) {
      clearInterval(reloadInterval);
      anonymousCartRefresh();
      return;
    }
    if (lastReloadTime) {
      // don't reload more than once a second
      if ((new Date().getTime()) - lastReloadTime.getTime() < 1000) {
        return;
      }
    }
    lastReloadTime = new Date();
    cart.load();
  }

  function startReloadInterval() {
    if (reloadInterval) {
      clearInterval(reloadInterval);
    }
    setInterval(reload, config.cartReloadInterval || 30000);
  }

  function anonymousCartConnect() {
    var user = userService.getUser();
    if (user && !user.anonymous) {
      return false;
    }
    if (!cart.data.items || !cart.data.items.length) {
      return false;
    }

    if (!cart.online) {
      // refresh on anonymous cart changes (to get stock, prices, discounts)
      cart.goOnline('users/anonymous/cart', 'post');
    }
    return true;
  }

  function anonymousCartRefresh() {
    if (!anonymousCartConnect()) {
      return;
    }
    cart.apply(function(data) {
      // trigger an anonymous refresh
      data.v = (data.v || 0) + 1;
    });
  }

  this.load = function() {
    if (!cart) {
      prepareCart();
    }
  };

  this.reload = reload;

  userService.events.on('loggedIn', function(user, eventInfo) {
    cart.goOnline('users/' + user.id + '/cart');
    if (eventInfo && eventInfo.source === 'localStorage') {
      // user logged in another tab, cart with load automatically
    } else {
      // load user cart,
      // and rebase (add local cart contents in top of server's)
      cart.loadAndRebase();
    }
    startReloadInterval();
    $rootScope.$broadcast('cartItemsChanged');
  });

  userService.events.on('loggedOut', function() {
    cart.reset({
      shipping: {},
      billing: {},
      items: [],
      rewards: {}
    });
    clearInterval(reloadInterval);
  });

  this.getData = function() {
    return cart.data;
  };

  this.getStep = function () {
    return cartStep;
  };

  this.setStep = function (step) {
    cartStep = step;
  };

  this.getFulfillers = function() {
    return $rootScope.fulfillers;
  };

  this.getTotals = function() {
    return totals;
  };

  this.isEmpty = function() {
    return !(cart.data.items && cart.data.items.length > 0);
  };

  this.cleanCart = function(cleanCart, cleanSaveForLater) {
    cart.apply(function(data) {
      data.items = _.filter(data.items, function(item) {
        return (!cleanCart && !item.forLater) || (!cleanSaveForLater && item.forLater);
      });
      $rootScope.$broadcast('cartItemsChanged');
    });
  };

  this.productIsInCart = function(product) {
    return _.some(cart.data.items, function(item) {
      return _.some(product.styles, {id: item.styleId});
    });
  };

  this.canAddToCart = function(variant) {
    return variant && variant.stock !== 0;
  };

  this.isStockAvailable = function(variant) {
    if (variant && variant.stock > 0) {
      var style = variant.getParentStyle();
      var cartItem = cart.data.items.find(function(item) {
        return item.styleId === style.id && variant.hash === item.hash;
      });
      return cartItem && !cartItem.forLater && cartItem.quantity >= variant.stock;
    }
  };

  this.isMaxQty = function(variant) {
    var style = variant.getParentStyle();
    var cartItem = cart.data.items.find(function(item) {
      return item.styleId === style.id && variant.hash === item.hash;
    });
    return cartItem && !cartItem.forLater && cartItem.quantity >= cartItem.getMaxQty();
  };

  this.variantIsSavedForLater = function(variant) {
    if (variant) {
      var style = variant.getParentStyle();
      return !!cart.data.items.find(function(item) {
        return item.styleId === style.id && variant.hash === item.hash && item.forLater;
      });
    }
  };

  this.getVariantsSavedForLaterByStyle = function(styleId) {
    if (styleId) {
      return cart.data.items.filter(function(item) {
        return item.styleId === styleId && item.forLater;
      });
    }
    return [];
  };

  this.isMaxBrandQty = function(variant, brandId) {
    var cartItem = cart.data.items.find(function(item) {
      var style = variant.getParentStyle();
      return item.styleId === style.id && variant.hash === item.hash && !item.forLater;
    });
    if (!cartItem) {
      var anotherBrandItem = cart.data.items.find(function(item) {
        return item.getBrand() && item.getBrand().id === brandId && !item.forLater;
      });
      return anotherBrandItem && anotherBrandItem.quantity >= anotherBrandItem.getBrandItemMaxQty();
    } else {
      return cartItem && cartItem.quantity >= cartItem.getBrandItemMaxQty();
    }
  };

  this.setPromoCode = function(code) {
    cart.apply(function(data) {
      data.promoCode = code;
    });
    var self = this;
    setTimeout(function() {
      self.reCalculateTotals(true);
    }, 3000);
  };

  this.setUsingGiftCard = function(usingGiftCard) {
    cart.apply(function(data) {
      setProductAndVariantInfoGetters();
      data.usingGiftCard = !!usingGiftCard;
    });
  };

  this.setShippingMethods = function(shippingMethods) {
    if (!shippingMethods || !shippingMethods.length) {
      return;
    }

    cart.apply(function(data) {
      data.shippingMethods = shippingMethods || [];
    });
  };

  this.add = function(params) {
    var item = {
      styleId: params.styleId,
      sku: params.sku,
      variantKey: params.variant.key || params.variant.variantKey,
      attributedTo: params.attributedTo,
      attributionReason: params.attributionReason,
      attributedAmount: params.attributedAmount,
      comingFrom: params.comingFrom,
      forLater: params.forLater,
      metadata: params.metadata
    };
    var addedItem = null;
    var removedItem = null;
    var itemIndex = 0;
    var quantityChange = params.quantity || 1;
    anonymousCartConnect();
    cart.apply(function (data) {
      setProductAndVariantInfoGetters();
      var existingItem = _.findLast(data.items, function (dataItem) {
        return (
          dataItem.styleId === item.styleId &&
          _.isEqual(dataItem.variantKey, item.variantKey) &&
          _.isEqual(dataItem.metadata, item.metadata) &&
          dataItem.forLater === item.forLater
        );
      });
      var indexOfItemToBeRemoved;
      if (params.moving) {
        indexOfItemToBeRemoved = _.findIndex(data.items, function (dataItem) {
          return (
            dataItem.styleId === item.styleId &&
            _.isEqual(dataItem.variantKey, item.variantKey) &&
            _.isEqual(dataItem.metadata, item.metadata) &&
            dataItem.forLater !== item.forLater
          );
        });
        quantityChange = 1;
        data.items.splice(indexOfItemToBeRemoved, 1);
      }
      if (!existingItem) {
        item.quantity = item.forLater && quantityChange > 0 ? 1 : quantityChange;
        data.items.unshift(item);
        addedItem = item;
      } else {
        if (quantityChange) {
          existingItem.quantity =
            existingItem.forLater && quantityChange > 0 ? 1 : existingItem.quantity + quantityChange;
          if (existingItem.quantity > existingItem.getMaxQty()) {
            existingItem.quantity = existingItem.getMaxQty();
          }
        }
        itemIndex = data.items.findIndex(function(cartItem) {
          return cartItem.styleId === existingItem.styleId && cartItem.hash === existingItem.hash;
        });
        if (existingItem.quantity <= 0) {
          removedItem = data.items.splice(itemIndex, 1)[0];
          quantityChange += existingItem.quantity;
        } else {
          addedItem = existingItem;
        }
      }
      cartDataManipulation.mergeDuplicates(data);
    });

    if (addedItem) {
      if (!params.forLater) {
        klaviyoService.trackProductAdded(addedItem, this.getData(), this.getTotals());
      }
      self.events.emit(params.forLater ? 'add_to_wishlist' : 'add_to_cart', {
        product: addedItem.product(),
        style: addedItem.style(),
        variant: addedItem.variant(),
        quantity: addedItem.quantity,
        source: params.source,
        bagItem: addedItem,
        index: itemIndex
      });
    } else {
      self.events.emit(params.forLater ? 'remove_from_wishlist' : 'remove_from_cart', {
        product: removedItem.product(),
        style: removedItem.style(),
        variant: removedItem.variant(),
        quantity: quantityChange,
        source: params.source,
        bagItem: removedItem,
        index: itemIndex
      });
    }

    var notForLater = cart.data.items.filter(function(item) {
      return !self.isForLater(item);
    });
    if (notForLater.length === 0) {
      self.events.emit('empty');
    }
    if (addedItem || removedItem) {
      shopModalService.updateCart(cart.data.items);
      $rootScope.$broadcast('cartItemsChanged');
    }
  };

  this.remove = function(params) {
    var newParams = _.clone(params);
    newParams.quantity = -(newParams.quantity || 1);
    return this.add(newParams);
  };

  this.empty = function() {
    var self = this;
    cart.apply(function(data) {
      // We keep only the saved for later (Wishlist) items
      data.items = data.items.filter(function(item) {
        return self.isForLater(item);
      });

      delete data.promoCode;
      delete data.usingGiftCard;
      delete data.rewards;
    });

    $rootScope.$broadcast('cartItemsChanged');
  };

  var lastCheckoutStep;

  this.checkoutStepEnter = function(step) {
    if (!step || lastCheckoutStep === step) {
      return;
    }
    lastCheckoutStep = step;
    var purchasableItems = cart.data.items.filter(cartDataManipulation.isPurchasable).map(function(item) {
      return {
        product: item.product(),
        style: item.style(),
        variant: item.variant(),
        quantity: item.quantity,
      };
    });
    if (step === 'shipping') {
      this.events.emit('begin_checkout', {
        value: this.getTotals().purchasable.totalPrice,
        items: purchasableItems
      });
    }
    if (step === 'billing') {
      this.events.emit('add_shipping_info', {
        value: this.getTotals().purchasable.totalPrice,
        shippingMethod: this.getTotals().shipping ? 'Paid' : 'Free',
        items: purchasableItems
      });
    }
    if (step === 'review') {
      this.events.emit('add_payment_info', {
        value: this.getTotals().purchasable.totalPrice,
        promoCode: cart.data.promoCode,
        // TODO: Get the correct payment method
        paymentType: 'Credit Card',
        items: purchasableItems,
        cost: this.getTotals().purchasable.cost,
        discount: this.getTotals().purchasable.discount
      });
    }

    // reload cart on every checkout step
    this.reload(true);
  };

  this.get = function(id) {
    return promises.extend(apiService.get('/users/{id}/cart', {
      pathParams: {
        id: id
      }
    }));
  };

  this.isSoldOut = cartDataManipulation.isSoldOut;
  this.isForLater = cartDataManipulation.isForLater;
  this.isBrandVisible = cartDataManipulation.isBrandVisible;
  this.isPurchasable = cartDataManipulation.isPurchasable;
  this.isOverMaxQty = cartDataManipulation.isOverMaxQty;
  this.isBrandAvailable = cartDataManipulation.isBrandAvailable;

  this.getDiscountReason = function() {
    var rewards = cart.data && cart.data.rewards && cart.data.rewards.items;
    if (!(rewards && rewards.length > 0)) {
      return '';
    }
    var reasons = [];
    rewards.forEach(function(reward) {
      reasons.push(reward.description + ' ($' + reward.credit / 100 + ')');
    });
    return reasons.join('\n');
  };

  function updateVariantStockAndPrices() {
    var stockChanges = false;
    var priceChanges = false;
    cart.data.items.forEach(function(item) {
      if (typeof item.stock === 'undefined') {
        return;
      }
      var variant = item.variant();
      if (!variant) {
        return;
      }
      if ($rootScope.shipping && $rootScope.shipping.address && $rootScope.shipping.address.country &&
        $rootScope.shipping.address.country.toLowerCase() !== 'us') {
        item.finalSale = true;
      }
      if (variant.stock !== item.stock) {
        variant.stock = item.stock;
        stockChanges = true;
      }
      if (variant.finalSale !== item.finalSale) {
        variant.finalSale = item.finalSale;
        stockChanges = true;
      }
      if (variant.price !== item.price) {
        variant.price = item.price;
        priceChanges = true;
      }
      if (variant.listPrice !== item.listPrice) {
        variant.listPrice = item.listPrice;
        priceChanges = true;
      }
    });
    if (stockChanges) {
      self.events.emit('stockChanged');
      $rootScope.$broadcast('cartItemsStockChanged');
    }
    if (priceChanges) {
      self.events.emit('priceChanged');
      $rootScope.$broadcast('cartItemsPriceChanged');
    }
  }

  function safeUpdateScope() {
    if ($rootScope.$root) {
      if ($rootScope.$root.$$phase && ['$digest', '$apply'].includes($rootScope.$root.$$phase)) {
        setTimeout(function() {
          safeUpdateScope();
        }, 500);
      } else {
        $rootScope.$digest();
      }
    }
  }

  this.copyCartItems = function (cartItemsToCopy) {
    this.cleanCart(true, true);
    _.forEach(cartItemsToCopy, function (cartItem) {
      cart.apply(function (data) {
        data.items.push(cartItem);
        setProductAndVariantInfoGetters();
        shopModalService.updateCart(data.items);
        $rootScope.$broadcast('cartItemsChanged');
      });
    });
  };

  this.getSoldOutMessage = function() {
    var count = totals.quantity && totals.purchasable.quantity ?
      totals.quantity - totals.purchasable.quantity : undefined;
    if (!count) {
      return '';
    }
    if (!totals.purchasable.quantity) {
      return 'All the products in your cart are sold out.';
    }
    var numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    return 'There ' + (count > 1 ? 'are ' : 'is ') +
      (numbers[count - 1] || count) + ' sold out product' +
      (count > 1 ? 's' : '') + ' in your cart.';
  };

  this.reCalculateTotals = function() {
    return calculateTotals(true);
  };
}

module.exports = CartService;
