/*
* app configuration values for local env
*
*/
var _ = require('lodash-core');
var key = 'orchard-mile-local-config-override';

var localConfigOverride = {
  presets: {
    // apply on the browser console doing:
    //  localConfigOverride.presets.localApi.set();
    localApi: {
      config: {
        apiBaseUrl: 'https://localhost:8481/',
        analytics: null
      }
    },
    prodApi: {
      config: {
        apiBaseUrl: 'https://api.orchardmile.com/',
        analytics: null
      }
    },
    privateAccess: {
      config: {
        privateAccess: true
      }
    },
    publicAccess: {
      config: {
        privateAccess: false
      }
    },
    stripe: {
      config: {
        payment: {
          providerName: 'stripe'
        }
      }
    },
    automatedbrowser: {
      config: {
        automatedBrowser: true,
        noExperiments: true,
        analytics: null,
        debug: true,
        privateAccess: false,
        platformSupport: {
          mobile: true,
          userAgents: {
            allow: null,
            disallow: null
          }
        },
        payment: {
          allProviders: {
            testMode: true
          }
        },
        featureFlags: {
          exampleFlag: {
            // disable popups that can block automated scenarios
            enabled: false
          },
          globale: {
            enabled: false
          }
        }
      }
    }
  },
  clear: function() {
    try{
      if (typeof localStorage === 'undefined') {
        return;
      }
    }catch(e){ return;}
    localStorage.removeItem(key);
    localStorage.removeItem('minification');
    console.log('reloading to reflect changes');
    document.location.reload(true);
  },
  set: function(value) {
    try{
      if (typeof localStorage === 'undefined') {
        throw new Error('localStorage not supported');
      }
    }catch(e){ return;}
    var currentValue;
    try {
      currentValue = JSON.parse(localStorage.getItem(key));
    } catch (err) {
      currentValue = null;
    }
    var newValue = _.merge(currentValue || {}, value);
    localStorage.setItem(key, JSON.stringify(newValue));
    console.log('reloading to reflect changes');
    document.location.reload(true);
  },
  isSet: function() {
    return !!this.get();
  },
  get: function(logWarning) {
    try{
      if (typeof localStorage === 'undefined') {
        return;
      }
    }catch(e){ return;}

    function getConfigPreset(name) {
      var preset = localConfigOverride.presets[name];
      if (preset) {
        return preset;
      }
      var hasPrefixChar = !/[a-z0-9]/i.test(name.slice(0, 1));
      var key = _.find(Object.keys(localConfigOverride.presets), function(presetName) {
        var initials = (presetName.slice(0, hasPrefixChar ? 2 : 1) +
          presetName.slice(hasPrefixChar ? 2 : 1).replace(/[^A-Z]/g, '')).toLowerCase();
        return initials === name;
      });
      if (key) {
        return localConfigOverride.presets[key];
      }
    }

    var value;

    // find settings in the url
    var locationHashMatch = /[?&](?:local\-config\-override|lco)\=([^&]*)/i.exec(document.location.href);
    if (locationHashMatch) {
      try {
        var featureFlagToggles = [];
        var stringValue = decodeURIComponent(locationHashMatch[1]);
        if (/^[\!\d\w,]+$/.test(stringValue)) {
          if (stringValue=== 'clear') {
            localStorage.removeItem(key);
            value = null;
          } else {
            // use config presets
            stringValue.split(',').forEach(function(presetName) {
              if (presetName === 'clear') {
                value = {};
              } else {
                var preset = getConfigPreset(presetName);
                if (!preset) {
                  featureFlagToggles.push(presetName);
                } else {
                  value = _.merge(value || {}, preset.config);
                }
              }
            });
            if (featureFlagToggles.length) {
              // try to match other keys with feature feature feature flags
              value = _.merge(value || {}, {
                featureFlags: {
                  toggles: featureFlagToggles
                }
              });
            }
          }
        } else {
          value = JSON.parse(stringValue);
        }
        if (value) {
          localStorage.setItem(key, JSON.stringify(value));
          return value;
        }
      } catch (err) {
        value = null;
      }
    }

    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return;
    }

    // find overrides in the global namespace
    if (typeof window.__configOverrides === 'object') {
      value = _.merge(_.cloneDeep(window.__configOverrides), value);
    }

    if (value && logWarning) {
      console.log('WARNING: using config override', value);
    }
    return value;
  },
  minification: function(enable) {
    if (typeof enable === 'undefined') {
      return window.localStorage.getItem('minification') !== 'false';
    }
    if (enable === false) {
      window.localStorage.setItem('minification', false);
    } else {
      window.localStorage.setItem('minification', true);
    }
    console.log('reloading to reflect changes');
    document.location.reload(true);
  }
};

function presetSet() {
  localConfigOverride.set(this.config);
}
for (var presetName in localConfigOverride.presets) {
  localConfigOverride.presets[presetName].set = presetSet;
}

if (process.browser) {
  window.localConfigOverride = localConfigOverride;
}

module.exports = localConfigOverride;
