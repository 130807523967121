var _ = require('lodash-core');
var promises = require('../../../async/promises');

function NonePaymentProvider(config) {
  this.config = config;
}

NonePaymentProvider.prototype.load = _.memoize(function() {
  return promises.immediate();
});

NonePaymentProvider.prototype.prepareOrder = function(order) {
  return promises.immediate(_.cloneDeep(order));
};

NonePaymentProvider.prototype.prepareOrderToSaveOptions = function(order) {
  return this.prepareOrder(order);
};

module.exports = NonePaymentProvider;
