var _ = require('lodash-core');

// @ngInject
function AdminDueDateOrdersController($scope, orderService) {
  var promise = orderService.getOrdersWithoutShipping(new Date(), false);

  $scope.orders = [];
  promise.then(function(response) {
    _.forEach(response.items, function(order) {
      order.fulfillerOrders = _.join(
        _.compact(
          _.map(
            _.map(
              _.filter(
                order.events,
                { type: 'fulfiller-order' }
              ),
              'details'
            ),
            'id'
          )
        ),
        ', '
      );
    });
  });

  $scope.orders = promise.lazyObject();

  $scope.checkCancelled = function(order) {
    if (!order) {
      return true;
    }

    var cancelled = _.find(order.events, { type: 'order-cancelled' });

    return $scope.hideCancelled && cancelled;
  };

  $scope.checkRefounded = function(order) {
    if (!order) {
      return true;
    }

    var refunded = _.find(order.events, { type: 'refund' });

    return $scope.hideRefounded && refunded;
  };

  $scope.checkShipped = function(order) {
    if (!order) {
      return true;
    }

    var shipped = _.find(order.events, function(event) {
      return event.type === 'shipping-status' && event.trackingCode;
    });

    return $scope.showOnlyShipped && !shipped;
  };
}

module.exports = AdminDueDateOrdersController;
