var _ = require('lodash-core');

// @ngInject
function ScrollService($rootScope, $state, config, $stateParams) {
  this.isInfiniteScroll = function() {
    if (_.some(config.featureFlags.infiniteScroll.states, function(state) {
      return ($state.current.name.indexOf(state) !== -1);
    }) &&
      config.featureFlags.infiniteScroll.enabled) {
      if ($stateParams.sc === '1') {
        return true;
      }
    }
    return false;
  };

  //Function to show the "LOAD PREVIUS" if its needed
  this.havePreviousProducts = function() {
    if ($stateParams.page > 1) {
      if (($('.product-list-item').length * ($stateParams.page - 1) + 1) > $('.product-list-item').length) {
        return true;
      }
    }
    return false;
  };
}

module.exports = ScrollService;
